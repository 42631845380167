$sizes: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 24 30 40 50;
$weights: 1 2 3 4 5 6 7 8;
$durations: 1 2 3 4 5 6 7 8 10 15 20 25 30 35 40 45 50;
//$properties: (
//        margin-bottom: "margin-bottom",
//        margin-top: "margin-top",
//        margin-left: "margin-left",
//        margin-right: "margin-right",
//        border-width: "border-width",
//        margin-left: "",
//        margin-left: "",
//);
@each $size in $sizes {
  .mb#{$size} {
    margin-bottom: $size * 0.1rem !important;
  }
  .mt#{$size} {
    margin-top: $size * 0.1rem !important;
  }
  .ml#{$size} {
    margin-left: $size * 0.1rem !important;
  }
  .mr#{$size} {
    margin-right: $size * 0.1rem !important;
  }
  .has-#{$size}-font {
    font-size: ($size) * 0.1rem !important;
  }
  .has-#{$size}-border {
    border-width: ($size) * 0.1rem !important;
  }
  .has-#{$size}-border-top {
    border-top-width: ($size) * 0.1rem !important;
  }
  .has-#{$size}-border-right {
    border-right-width: ($size) * 0.1rem !important;
  }
  .has-#{$size}-border-bottom {
    border-bottom-width: ($size) * 0.1rem !important;
  }
  .has-#{$size}-border-left {
    border-left-width: ($size) * 0.1rem !important;
  }
  .has-#{$size}-font-size {
    font-size: ($size) * 0.1rem !important;
  }
  .has-#{$size}-margin {
    margin: ($size) * 0.1rem !important;
  }
  .has-#{$size}-margin-bottom {
    margin-bottom: ($size) * 0.1rem !important;
  }
  .has-#{$size}-margin-top {
    margin-top: ($size) * 0.1rem !important;
  }
  .has-#{$size}-margin-left {
    margin-left: ($size) * 0.1rem !important;
  }
  .has-#{$size}-margin-right {
    margin-right: ($size) * 0.1rem !important;
  }
  .has-#{$size}-padding {
    padding: ($size) * 0.1rem !important;
  }
  .has-#{$size}-padding-bottom {
    padding-bottom: ($size) * 0.1rem !important;
  }
  .has-#{$size}-padding-top {
    padding-top: ($size) * 0.1rem !important;
  }
  .has-#{$size}-padding-left {
    padding-left: ($size) * 0.1rem !important;
  }
  .has-#{$size}-padding-right {
    padding-right: ($size) * 0.1rem !important;
  }
  .has-#{$size}-font-size {
    font-size: ($size) * 0.1rem !important;
  }
}
@each $weight in $weights {
  .bold#{$weight} {
    font-weight: $weight * 100;
  }
}
@each $duration in $durations {
  .has-#{$duration}00-duration {
    animation-duration: $duration * 100ms !important;
  }
  .has-#{$duration}00-delay {
    animation-delay: $duration * 100ms !important;
  }
}
.is-uppercase {
  text-transform: uppercase;
}

.flex-grow {
  flex-grow: 1;
}
.flex-grow.is-2 {
  flex-grow: 2;
}
.flex-vertical {
  flex-direction: column;
}
.flex-horizontal {
  flex-direction: row;
}
.flex-horizontal.is-reverse {
  flex-direction: row-reverse;
}
.align-self-end {
  align-self: flex-end;
}
.align-self-center {
  align-self: center;
}
.is-fullheight {
  height: 100%;
}
.is-fullwidth:not(.divider):not(.column-divider) {
  width: 100%;
}
.scrollable {
  //overflow-y: hidden;
  overflow-y: auto;
  position: relative;
  height: $view-height !important;
}

.is-marginless-right {
  margin-right: 0 !important;
}
.is-marginless-left {
  margin-left: 0 !important;
}
.is-marginless-bottom {
  margin-bottom: 0 !important;
}
.is-marginless-top {
  margin-top: 0 !important;
}

.is-paddingless-right {
  padding-right: 0 !important;
}
.is-paddingless-left {
  padding-left: 0 !important;
}
.is-paddingless-bottom {
  padding-bottom: 0 !important;
}
.is-paddingless-top {
  padding-top: 0 !important;
}

.is-borderless-right {
  border-right: 0 !important;
}
.is-borderless-left {
  border-left: 0 !important;
}
.is-borderless-bottom {
  border-bottom: 0 !important;
}
.is-borderless-top {
  border-top: 0 !important;
}

.is-borderless {
  border: 0 !important;
}
.hidden {
  display: none;
}
.is-draggable {
  cursor: grab;
}
.is-clickable {
  cursor: pointer;
}
.is-not-clickable {
  cursor: not-allowed;
}
.t100 {
  animation-duration: 100ms;
}
.t300 {
  animation-duration: 300ms;
}
.t500 {
  animation-duration: 500ms;
}
.t1000 {
  animation-duration: 1000ms;
}
.bold500 {
  font-weight: 500;
}
.bold700 {
  font-weight: 700;
}
.is-overlay {
  z-index: 10;
  transition: background 2s ease;
  position: fixed;
  padding-top: $navbar-height;
}
.sortable-vertical {
  z-index: 100;
  &,
  *,
  .is-clickable {
    cursor: row-resize !important;
  }
}
.is-list,
.is-boxed {
  box-shadow: 3px 0 0 0 get-color(light-blue-grey-20), inset 1px 0 0 0 get-color(light-blue-grey-80),
    inset -1px 0 0 0 get-color(light-blue-grey-80);
}
.is-overlay.is-loader {
  cursor: progress;
}

@each $name, $colors in $app-colors {
  @keyframes #{$name}-border-pulsate {
    0% {
      border-color: get-color-with-alpha($name, 1);
    }
    50% {
      border-color: get-color-with-alpha(neutral-grey, 1);
    }
    100% {
      border-color: get-color-with-alpha($name, 1);
    }
  }
  .has-#{$name}-border-color {
    border-color: map-get($colors, base);
  }
  .has-#{$name}-border {
    border: solid 0.1rem map-get($colors, base);
  }
  .has-#{$name}-border-pulsate {
    animation: #{$name}-border-pulsate 2s infinite;
  }
  .has-#{$name}-border-left {
    border-left: solid 0.1rem map-get($colors, base);
  }
  .has-#{$name}-border-right {
    border-right: solid 0.1rem map-get($colors, base);
  }
  .has-#{$name}-border-top {
    border-top: solid 0.1rem map-get($colors, base);
  }
  .has-#{$name}-border-bottom {
    border-bottom: solid 0.1rem map-get($colors, base);
  }
  @each $size in $sizes {
    .has-#{$name}-#{$size}-border {
      border: solid ($size) * 0.1rem map-get($colors, base) !important;
    }
    .has-#{$name}-#{$size}-border-pulsate {
      border: solid ($size) * 0.1rem map-get($colors, base) !important;
      animation: #{$name}-border-pulsate 2s infinite;
    }
    .has-#{$name}-#{$size}-border-left {
      border-left: solid ($size) * 0.1rem map-get($colors, base) !important;
    }
    .has-#{$name}-#{$size}-border-right {
      border-right: solid ($size) * 0.1rem map-get($colors, base) !important;
    }
    .has-#{$name}-#{$size}-border-top {
      border-top: solid ($size) * 0.1rem map-get($colors, base) !important;
    }
    .has-#{$name}-#{$size}-border-bottom {
      border-bottom: solid ($size) * 0.1rem map-get($colors, base) !important;
    }
  }
}

.truncate {
  width: auto;
  overflow: hidden;
  position: relative;
  //text-overflow: ellipsis;
  //white-space: nowrap;
  & > * {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  //&>*:before {
  //  content:'';
  //  width:100%;
  //  height:100%;
  //  position:absolute;
  //  left:0;
  //  top:0;
  //  background:linear-gradient(to right, transparent 80%, white);
  //}
}
tr.is-active,
.columns.is-active {
  position: relative;
  background: linear-gradient(to right, get-color(pale-grey-two) 70%, transparent);
  //.truncate>*:before {
  //  background:linear-gradient(to right, transparent 80%, get-color(pale-grey-two));
  //}
}

@each $name, $colors in $app-colors {
  .bg-#{$name}.column.truncate > *:before {
    background: linear-gradient(to right, transparent 80%, map-get($colors, base));
  }
}
//.columns.is-active:before {
//  content:'';
//  width:100%;
//  height:100%;
//  position:absolute;
//  left:0;
//  top:0;
//  background:linear-gradient(to right, get-color(pale-grey-two) 80%, transparent);
//  .truncate>*:before {
//    background:linear-gradient(to right, transparent 80%, get-color(pale-grey-two));
//  }
//}

.blinking {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

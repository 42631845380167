.box.phase {
  box-sizing: border-box;
  padding: 1rem 1.2rem 1rem 1.6rem;
  margin-bottom: 1.2rem;
  min-height: 10rem;
  .title {
    margin-bottom: 2rem;
  }
  .meta {
    display: inline-flex;
  }
  .title {
    color: get-color('black');
  }
  .subtitle {
    color: get-color(bluish-grey);
  }

  &.is-temporary {
    border: 1px solid get-color(light-blue-grey-two);
  }

  &.is-detailed {
    $height: 12rem;
    $padding-vertical: 1rem;

    padding: $padding-vertical 1.2rem $padding-vertical 1.6rem;
    height: $height;
    & > div {
      height: calc(#{$height} - #{$padding-vertical * 2});
    }
    $transition-time: 150ms;
    border: 0.1rem solid get-color(light-blue-grey-80);
    border-top: 0.8rem solid get-color(light-blue-grey-80);

    @include active-shadow(transparent);

    transition: box-shadow $transition-time ease-in-out, border $transition-time ease-in-out,
      border-top $transition-time ease-in-out;

    background-color: get-color(light-grey);
    &:not([class^='has-phase-status-']) {
      border-top: 0.8rem solid get-color(light-blue-grey-80) !important;
    }

    &.has-phase-status-production {
      border-top: 0.8rem solid get-color(production-blue) !important;
    }
    &.has-phase-status-material {
      border-top: 0.8rem solid get-color(material-violet) !important;
    }
    &.has-phase-status-setup {
      border-top: 0.8rem solid get-color(setup-yellow) !important;
    }
    &.has-phase-status-error {
      border-top: 0.8rem solid get-color(error) !important;
    }
    &.has-phase-status-check {
      border-top: 0.8rem solid get-color(check-green) !important;
    }
    &.has-phase-status-pause {
      border-top: 0.8rem solid get-color(pause-azure) !important;
    }

    &.has-phase-status-new {
    }
    &.has-phase-status-interrupt {
    }
    &.has-phase-status-done {
    }

    &:focus,
    &:hover,
    &.is-active {
      //background-color: get-color(body-grey);
      @include active-shadow(get-color(light-blue-grey-80));
      &.has-phase-status-production {
        $color: get-color(production-blue);
        border-color: $color;
        @include active-shadow($color);
      }
      &.has-phase-status-material {
        $color: get-color(material-violet);
        border-color: $color;
        @include active-shadow($color);
      }
      &.has-phase-status-setup {
        $color: get-color(setup-yellow);
        border-color: $color;
        @include active-shadow($color);
      }
      &.has-phase-status-error {
        $color: get-color(error);
        border-color: $color;
        @include active-shadow($color);
      }
      &.has-phase-status-check {
        $color: get-color(check-green);
        border-color: $color;
        @include active-shadow($color);
      }
      &.has-phase-status-pause {
        $color: get-color(pause-azure);
        border-color: $color;
        @include active-shadow($color);
      }
      //
      //&.has-phase-status-new { }
      //&.has-phase-status-interrupt { }
      //&.has-phase-status-done { }

      //&.has-phase-status-new,
      //&.has-phase-status-interrupt,
      //&.has-phase-status-done {
      //  padding-top: .9rem;
      //}
    }
  }

  &.is-error {
  }
  &.is-ok {
  }
}

.phase-status {
  //margin: 0 -3rem 0;
  padding: 0.6rem 2.4rem;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.54em;
  color: white;
  [class^='icon-'] {
    margin-right: 0.6rem;
  }
}
.phase-log {
  .operators {
    .image {
      display: inline-block;
      margin-left: 0.6rem;
    }
  }
}
.phase-container {
  padding-right: 1rem;
  .phase.phase-short {
    color: get-color(slate);
    min-height: 5.5rem;
    display: flex;
    .meta {
      margin-top: 0.5rem;
      color: get-color(bluey-grey);
      .level-item {
        flex-grow: 0;
        justify-content: flex-start;
      }
      .progress-container {
        min-width: 12.8rem;
        max-width: 18rem;
      }
    }
  }
  .phase:not(:last-child) {
    border-bottom: 0.1rem solid get-color(light-blue-grey-two);
  }
  p small {
    font-size: 1.3rem;
    color: get-color(bluey-grey);
  }
}
.phase.is-detailed {
  min-height: 12rem;

  .level-item.time-container {
    min-width: 10rem;
    justify-content: flex-start;
  }
  .level-item.progress-container {
    //min-width: 6.5rem;
    justify-content: flex-start;
  }
  .level-item.percentage-container {
    min-width: 6rem;
    justify-content: flex-start;
  }
}

.has-phase-status-material {
}
.has-phase-status-setup {
}
.has-phase-status-production {
}
.has-phase-status-pause {
}
.has-phase-status-interrupt {
}
.has-phase-status-check {
}
.has-phase-status-done {
  .phase-status {
    background-color: get-color(production-blue);
  }
}
.has-phase-status-error {
  progress {
  }
  .phase-status {
    background-color: get-color(error);
  }
}
.has-phase-status-new {
  .phase-status {
    background-color: get-color(bluey-grey);
  }
}

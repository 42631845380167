@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-book-c.eot);
  src: url(../fonts/circular/lineto-circular-book-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-book-c.woff) format("woff"),url(../fonts/circular/lineto-circular-book-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-book-c.svg#LLCircularWeb-Book) format("svg");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-medium-c.eot);
  src: url(../fonts/circular/lineto-circular-medium-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-medium-c.woff) format("woff"),url(../fonts/circular/lineto-circular-medium-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-medium-c.svg#LLCircularWeb-Medium) format("svg");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-bold-c.eot);
  src: url(../fonts/circular/lineto-circular-bold-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-bold-c.woff) format("woff"),url(../fonts/circular/lineto-circular-bold-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-bold-c.svg#LLCircularWeb-Bold) format("svg");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-black-c.eot);
  src: url(../fonts/circular/lineto-circular-black-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-black-c.woff) format("woff"),url(../fonts/circular/lineto-circular-black-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-black-c.svg#LLCircularWeb-Black) format("svg");
  font-weight: 800;
  font-style: normal
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-bookitalic-c.eot);
  src: url(../fonts/circular/lineto-circular-bookitalic-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-bookitalic-c.woff) format("woff"),url(../fonts/circular/lineto-circular-bookitalic-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-bookitalic-c.svg#LLCircularWeb-Bookitalic) format("svg");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-mediumitalic-c.eot);
  src: url(../fonts/circular/lineto-circular-mediumitalic-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-mediumitalic-c.woff) format("woff"),url(../fonts/circular/lineto-circular-mediumitalic-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-mediumitalic-c.svg#LLCircularWeb-Mediumitalic) format("svg");
  font-weight: 500;
  font-style: italic
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-bolditalic-c.eot);
  src: url(../fonts/circular/lineto-circular-bolditalic-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-bolditalic-c.woff) format("woff"),url(../fonts/circular/lineto-circular-bolditalic-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-bolditalic-c.svg#LLCircularWeb-Bolditalic) format("svg");
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-blackitalic-c.eot);
  src: url(../fonts/circular/lineto-circular-blackitalic-c.eot?#iefix) format("embedded-opentype"),url(../fonts/circular/lineto-circular-blackitalic-c.woff) format("woff"),url(../fonts/circular/lineto-circular-blackitalic-c.ttf) format("truetype"),url(../fonts/circular/lineto-circular-blackitalic-c.svg#LLCircularWeb-Blackitalic) format("svg");
  font-weight: 800;
  font-style: italic
}

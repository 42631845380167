$column-header-height: 8rem;
$column-footer-height: 8rem;
$settings-tab-height: 3.8rem;
.column.is-list {
  box-shadow: 0.3rem 0 0 0 get-color-with-alpha(light-blue-grey, 0.2),
    inset -0.1rem 0 0 0 get-color-with-alpha(light-blue-grey, 0.8);
}
.column.is-list .column-content {
  display: flex;
  flex-direction: column;
  height: $view-height;
  padding: 3.6rem 2.7rem 2rem;
  .column-divider {
    height: 0.1rem;
    background-color: get-color(light-blue-grey-two);
    margin: 0.9rem 0 0.8rem;
    &.is-fullwidth {
      margin: 0.9rem -3rem 0.8rem;
    }
  }
}
//.column.is-paddingless .section-header,
//.column.is-paddingless .column-list-element {
//margin: 0 -3rem;
//}
/* otherwise overflow-x will appear :( */
.column.is-paddingless .section-header,
.column.is-paddingless .column-list-element {

  //margin: 0 -3.5rem;
  margin: 0 -3.2rem 0 -3.5rem;
}
.column .section-header {
  min-height: 4rem;
  padding: 1.3rem 1.6rem;
  border-top: 0.1rem solid get-color(light-blue-grey-two);
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.17rem;
  color: get-color(greyblue);
  background-color: get-color(dark-3);
}
.column .column-list-element {
  min-height: 7.4rem;
  padding: 1.6rem 3rem;
  &:not(:first-child) {
    border-top: 0.1rem solid get-color(pale-grey-three);
  }
  &:hover,
  &.selected {
    background-color: get-color(dark-sky-blue-10);
  }
}
.column-group {
  border-top: 1px solid get-color(light-blue-grey-two);
}
.column-group-head {
  margin: 0 -2.3rem 0 -2.4rem !important;
  padding: 0 3rem;
  min-height: 5.5rem;
}
.column {
  //.column-content {
  //  display: flex;
  //  flex-direction: column;
  //}
  .column-head {
    height: $column-header-height;
    h1 .icon {
      min-width: 4rem;
      justify-content: flex-start;
    }
    h1:last-child {
      line-height: 4.5rem;
    }
  }
  .column-body {
    .section:first-child {
      .title:first-child {
        margin-top: 2rem;
      }
    }
    flex-grow: 2;
  }
  .column-body {
    .scrollable.has-header {
      height: calc(#{$view-height} - #{$column-header-height}) !important;
    }
    .scrollable.has-footer {
      height: calc(#{$view-height} - #{$column-footer-height}) !important;
    }
    .scrollable.has-header.has-footer {
      height: calc(#{$view-height} - #{$column-footer-height} - #{$column-footer-height}) !important;
    }
    .scrollable.is-settings-main-table {
      height: calc(#{$view-height} - 9rem) !important;
      &.has-menu {
        height: calc(#{$view-height} - 9rem - 3.8rem) !important;
      }
    }
    .scrollable.has-tabs.is-settings {
      height: calc(#{$view-height} - #{$settings-tab-height} - 13rem) !important;
    }
  }
  .column-footer {
    height: 8rem;
    border-top: 0.1rem solid get-color(light-blue-grey-two);
  }
}
.column section {
  // margin-left: -3rem;
  // margin-right: -3rem;
  padding: 1.6rem 2.4rem 1.6rem;
  margin-bottom: 0;
  // &:first-child {
  // padding-top: 3.6rem;
  // }
  &:not(:last-child) {
    // margin-bottom: 2rem;
    border-bottom: 0.1rem solid get-color(light-blue-grey-two);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation-duration: 2500ms;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

$transition-time: 150ms;

.arrow-steps {
  position: relative;
  width: 100%;
  background: #fff;
  .step {
    font-size: 1.5rem;
    line-height: 1.6rem;
    text-align: center;
    margin: 0.5rem 0.3rem;
    padding: 1.5rem 1rem 1.5rem 2.8rem;
    width: 9rem;
    &.is-small {
      width: 5rem;
      padding: 1.5rem 0rem 1.5rem 1.8rem;
    }
    float: left;
    position: relative;
    user-select: none;
    transition: background-color $transition-time ease-in-out;
    color: get-color-contrast(neutral-grey);
    background-color: get-color(neutral-grey);
    &.is-1 {
      width: calc(8.33333% - 0.8rem);
    }
    &.is-2 {
      width: calc(16.66667% - 0.8rem);
    }
    &.is-3 {
      width: calc(25% - 0.8rem);
    }
    &.is-4 {
      width: calc(33.33333% - 0.8rem);
    }
    &.is-5 {
      width: calc(41.66667% - 0.8rem);
    }
    &.is-6 {
      width: calc(50% - 0.8rem);
    }
    &.is-7 {
      width: calc(58.33333% - 0.8rem);
    }
    &.is-8 {
      width: calc(66.66667% - 0.8rem);
    }
    &.is-9 {
      width: calc(75% - 0.8rem);
    }
    &.is-10 {
      width: calc(83.33333% - 0.8rem);
    }
    &.is-11 {
      width: calc(91.66667% - 0.8rem);
    }
    &.is-12 {
      width: calc(100% - 0.8rem);
    }
  }
  .step:after,
  .step:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: -1.7rem;
    width: 0;
    height: 0;
    border-top: 2.4rem solid transparent;
    border-bottom: 2.2rem solid transparent;
    z-index: 2;
    transition: border-color $transition-time ease-in-out;
    border-left: 1.7rem solid get-color('neutral-grey');
  }
  .step:before {
    right: auto;
    left: 0;
    border-left: 1.7rem solid get-color('white');
    z-index: 0;
  }

  .step:first-child:before {
    border: none;
  }

  .step:first-child {
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
  }
  .step .step-icon {
    position: absolute;
    right: 0.2rem;
    bottom: 1.5rem;
    z-index: 2;
  }
}
@each $name, $colors in $app-colors {
  .arrow-steps {
    .step.is-#{$name} {
      color: map-get($colors, font);
      background-color: map-get($colors, base);
      &:after,
      &:before {
        transition: border-left $transition-time ease-in-out;
        border-left: 1.7rem solid map-get($colors, base);
      }
    }
    &.bg-#{$name} .step:before {
      transition: border-left $transition-time ease-in-out;
      border-left: 1.7rem solid map-get($colors, base) !important;
    }
  }
}

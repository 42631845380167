$sidebar-background-color: get-color(charcoal-grey);

.aside {
  display: flex;
  position: fixed;
  background-color: $sidebar-background-color;
  width: $sidebar-width;
  height: $view-height;
  //margin-top: $navbar-height;
  padding: 1rem;
  .sidebar-nav {
    margin-top: 2rem;
    li a {
      display: block;
      margin: 0 auto;
      margin-bottom: 2rem;
      padding: 1rem;
      width: 4rem;
      height: 4rem;
      border-radius: 1rem;
      background-color: get-color-with-alpha('white', 0.2);
      &.active {
        background-color: get-color('dark-sky-blue');
      }
    }
    &.sidebar-bottom {
      li a {
        width: 3.2rem;
        height: 3.2rem;
        padding: 0;
        background-color: transparent;
        color: get-color('bluish-grey');
        &.active {
          color: get-color('white');
        }
      }
    }
  }
}
.sb-dashboard-icn {
  width: 20px;
  height: 18px;
  object-fit: contain;
  border-radius: 1px;
  background-color: get-color('white');
}
.sb-job-board-icn {
  width: 18px;
  height: 20px;
  object-fit: contain;
  background-color: get-color('white');
}
.sb-factory-icn {
  width: 20px;
  height: 16px;
  object-fit: contain;
  background-color: get-color('white');
}
.sb-accounting-icn {
  width: 16px;
  height: 24px;
  object-fit: contain;
  background-color: get-color('white');
}
.sb-default-avatar-icn {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
.sb-settings-icn {
  width: 24px;
  height: 24px;
  object-fit: contain;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
  background-color: rgb(96, 125, 139);
}

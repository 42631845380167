.progress {
  height: 0.6rem;
  -webkit-appearance: none;
  &::-webkit-progress-bar {
    background-color: get-color-with-alpha(production-blue, 0.15);
  }
  &::-webkit-progress-value {
    background-color: get-color(production-blue);
  }
  &::-moz-progress-bar {
    background-color: get-color(production-blue);
  }
}
//.has-job-status-error,
.has-phase-status-error {
  .progress {
    -webkit-appearance: none;
    &::-webkit-progress-bar {
      background-color: get-color-with-alpha(error, 0.15);
    }
    &::-webkit-progress-value {
      background-color: get-color(error);
    }
    &::-moz-progress-bar {
      background-color: get-color(error);
    }
  }
}
.has-phase-status-production {
  .progress {
    -webkit-appearance: none;
    &::-webkit-progress-bar {
      background-color: get-color-with-alpha(production-blue, 0.15);
    }
    &::-webkit-progress-value {
      background-color: get-color(production-blue);
    }
    &::-moz-progress-bar {
      background-color: get-color(production-blue);
    }
  }
}

@each $name, $colors in $app-colors {
  progress.is-#{$name} {
    -webkit-appearance: none;
    &::-webkit-progress-bar {
      background-color: get-color-with-alpha($name, 0.15);
    }
    &::-webkit-progress-value {
      background-color: get-color($name);
    }
    &::-moz-progress-bar {
      background-color: get-color($name);
    }
  }
}

.tag {
  height: 1.7rem;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  border-radius: 1.4rem;
}
.tag.is-large {
  font-size: 1.7rem;
  border-radius: 2rem;
  min-height: 4rem;
  min-width: 4rem;
  font-weight: 700;
}
.tag.is-medium {
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 1.5rem;
  min-height: 3rem;
  min-width: 3rem;
}
.tag.phase-status {
  margin-bottom: 1.6rem;
}
@each $name, $colors in $app-colors {
  .tag.is-#{$name}:not(.is-outlined) {
    background-color: map-get($colors, base);
    color: map-get($colors, font);
  }
  .tag.is-#{$name}.is-outlined {
    border: 1px solid map-get($colors, base);
    background-color: transparent;
    color: map-get($colors, base);
  }
}

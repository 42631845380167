.panel-block {
  font-size: 1.5rem;
  width: 22rem;
  padding: 0;
  &.is-not-clickable {
    cursor: initial;
    &:hover {
      background-color: white;
      font-weight: initial;
    }
  }
  .panel-item {
    padding: 0.7em 0.75em;
    width: 100%;
    height: 100%;
    border: none;
  }
  &:first-child {
    &:not(:last-child) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    border-top-right-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
  }
  &:last-child {
    &:not(:first-child) {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    border-bottom-right-radius: 0.6rem;
    border-bottom-left-radius: 0.6rem;
  }
  &:hover {
    color: get-color-contrast(dark-sky-blue-10);
    background-color: get-color(dark-sky-blue-10);
    font-weight: bold;
  }
  &:not(:last-child) {
    border-bottom: 1px solid get-color(pale-grey-three);
  }
}
.panel-dropdown {
  z-index: 20;
  box-shadow: 0 6px 12px 0 get-color(dark-grey-blue-16);
  border-radius: 0.6rem;
  background-color: white;

  .panel-block {
    width: 22rem;
  }
  .menu-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}

.depends-on-container {
  .panel-block {
    width: 40rem;
  }
}

.level {
  overflow: hidden;
}

//@mixin machine-status($background, $color) {
//  border-color: $background;
//  border-bottom: 1px solid $background;
//  &.is-active {
//    @include active-shadow($background);
//  }
//  .box-header {
//    min-height: 4rem;
//    background-color: $background;
//    color: $color;
//  }
//}

.level-body {
  margin-left: 1.3rem;
}
.box {
  $transition-time: 150ms;
  color: get-color(bluey-grey);
  transition: box-shadow $transition-time ease-in-out, height $transition-time ease;
  position: relative;
  background-color: get-color('white');
  box-shadow: none;
  border: solid 1px get-color(light-blue-grey-80);
  border-radius: 6px;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 1rem 1.6rem;
  // Box divider, full width or partial
  & > .divider:not(.is-vertical),
  .divider:not(.is-vertical) {
    height: 0.1rem;
    background-color: get-color(light-blue-grey-two);
    margin: 0.9rem 0 0.8rem;
    &.is-fullwidth {
      margin: 0.9rem -1.6rem 0rem;
    }
  }
  &.is-active {
    @include active-shadow(get-color(light-blue-grey-80));
  }
  .divider.is-vertical {
    width: 0.1rem;
    display: inline-block;
    background-color: get-color(light-blue-grey-two);
    margin: -1rem 0rem;
  }
  //.box-header {
  //  margin: -1rem -1.6rem 0.9rem;
  //  padding: 0.2rem 1.6rem;
  //  border-bottom: 1px solid get-color(light-blue-grey-two);
  //  border-top-right-radius: 0.4rem;
  //  border-top-left-radius: 0.4rem;
  //  align-items: stretch;
  //  display: flex;
  //}
  &.is-notes {
    white-space: pre-line;
    min-height: 8rem;
    font-size: 1.8rem;
    line-height: 2.1rem;
    overflow-wrap: break-word;
  }
  //@each $name, $colors in $app-colors {
  //  &.has-machine-status-#{$name} {
  //    @include machine-status(map-get($colors, base), map-get($colors, font));
  //  }
  //}
  .box-footer {
    margin: 0;
    padding: 0 0.9rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    //border-top: 1px solid get-color(light-blue-grey-two);
    align-items: stretch;
    display: flex;

    .box-footer-item {
      //justify-content: center;
      align-items: center;
      display: flex;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      padding: 0.75rem;
    }
  }

  .level-body {
    flex-grow: 1;
    margin-left: 1.3rem;
    justify-content: flex-start;
  }
  //.meta {
  //  display: inline-flex;
  //  width: 100%;
  //  progress {
  //    width: auto;
  //    min-width:6rem;
  //  }
  //  * {
  //    justify-content: flex-start;
  //  }
  //  > * {
  //    //color: get-color(bluey-grey);
  //    //font-size:1.3rem;
  //
  //    &:first-child {
  //      flex-grow: 1;
  //    }
  //    .progress-container {
  //      flex-grow: 1;
  //      //max-width: 80%;
  //      > * {
  //        justify-content: center;
  //        flex-grow: 1;
  //      }
  //    }
  //    .percentage  {
  //      min-width:6rem;
  //    }
  //    .timer {
  //      margin-left:.8rem;
  //    }
  //    .timer,
  //    .pieces {
  //      min-width:10rem;
  //    }
  //    .unit {
  //      min-width:20rem;
  //      justify-content: flex-end;
  //    }
  //    .more {
  //      min-width:4.5rem;
  //      justify-content: flex-end;
  //    }
  //  }
  //}
}

.is-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 2rem 2rem;
}
.box.main-box {
  min-height: calc(100vh - #{$navbar-height} - 3.6rem);
  &.has-actions {
    min-height: calc(100vh - #{$navbar-height} - 3.6rem - 4.5rem);
  }
  &.has-tabs {
    min-height: calc(100vh - #{$navbar-height} - 3.6rem - 3.8rem);
  }
  margin: 3.6rem 3.6rem 0;
  border-radius: 0.4rem;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: get-color('white');
  padding: 1.2rem 2rem 2rem 2.2rem;
  & > .divider.is-fullwidth {
    margin: 1.2rem -2rem 3.2rem -2.2rem;
  }
  .main-box-footer {
    color: get-color('dark');
    background-color: darken(#fafafa, 3.5%);
    padding: 2rem 2rem 2.2rem;
    border-bottom: 1px solid #d8e5ef;
    border-top: 1px solid #d8e5ef;
    //position: fixed;
    //bottom: 0;
    //width: 100%;
    margin-bottom: auto;
  }
}
.settings .box.main-box {
  min-height: calc(100vh - #{$navbar-height} - 3.6rem - 5.1rem);
}
//.box,
//.box.is-closed {
//  display: flex;
//}
.box {
  .head-actions {
    margin: -1.2rem 0 -1.2rem -2rem;
    padding: 1rem;
    .is-main-button,
    .is-main-button:active,
    .is-main-button:hover {
      margin: -1.2rem;
      height: 6rem;
      min-width: 6rem;
      margin-right: 10px;
      border-right: 1px solid get-color(light-blue-grey-two) !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: transparent;
      box-shadow: none;
      //&:hover,
      //&:active {
      //  background-color:transparent;;
      //  border-right: 1px solid get-color(light-blue-grey-two);
      //}
    }
  }
  //&.main-box .head-actions {
  //  margin: -1.2rem 0 -.9rem;
  //}
}
.box.main-box,
.box.is-opened {
  display: block;
}
.box.is-small {
  padding: 0.6rem 0.7rem 0.7rem 0.9rem;
}
.box.has-status {
  border-left-width: 0.6rem !important;
  padding: 1rem 1.6rem 1rem 1rem !important;
}
//.box.is-clickable:focus,
//.box.is-clickable:hover {
//  @include active-shadow(get-color(light-blue-grey-two));
//}
@each $name, $colors in $app-colors {
  .box.has-#{$name}-border {
    border: solid 1px rgba(map-get($colors, base), 0.5);
  }
  .box.is-#{$name} {
    background-color: get-color($name);
  }
  .box.main-box .info-box.is-#{$name} {
    background-color: get-color($name);
    color: get-color-contrast($name);
  }
}

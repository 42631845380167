.Select-control,
.Select.is-focused:not(.is-open) > .Select-control,
.input:not(.is-rounded),
.textarea,
select {
  box-shadow: none;
  border-radius: 0.6rem;
  border-color: get-color(light-blue-grey-three);
  outline: none;
}

.Select-control,
.input,
.select,
.radio,
.textarea {
  font-size: 1.7rem;

  &.is-small {
    font-size: 1.45rem;
  }
}

.Select-arrow {
  border: 1px solid get-color(light-blue-grey);
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 1;
}

.textarea.is-large[rows='3'] {
  min-height: 12.2rem;
}

.textarea.is-large,
.input.is-large {
  font-size: 3.2rem;
  height: 7rem;
}

.select.is-large,
.select.is-large select {
  height: 5.1rem;
}

.Select-arrow-zone {
  padding-left: 3rem;
}

.Select-input,
.Select-value,
.Select-placeholder {
  padding: 0.6rem 2rem 0.6rem 1.5rem !important;
  font-size: 1.7rem;
}

.Select-value-label {
  padding: 0.5rem !important;
  font-size: 1.7rem;
}

.Select-option {
  padding: 1rem 2rem;
  font-size: 1.3rem;
}

.Select-control,
.select.is-medium,
.textarea.is-medium,
.input.is-medium {
  font-size: 1.7rem;
  height: 4.8rem;
}

input.is-large[type='checkbox'] {
  width: 2rem;
  height: 1.8rem;
}

.input.is-active,
.input.is-focused,
.input.is-hovered,
.input:active,
.input:focus,
.input:hover,
.textarea.is-active,
.textarea.is-focused,
.textarea.is-hovered,
.textarea:active,
.textarea:focus,
.textarea:hover {
  border-color: get-color(light-blue-grey-three);
}

.input.is-rounded {
  //border-radius: 290486px;
  //padding-left: 1em;
  //padding-right: 1em;
  font-size: 1.5rem;
}

.has-icons-left {
  .input.is-rounded {
    padding-left: 2em;
  }

  .icon.is-left {
    margin-top: 0.6em;
    margin-left: 0.9em;
  }

  .icon.is-right {
    margin-top: 0.6em;
    margin-right: 0.9em;
  }
}

.box.has-input {
  margin-bottom: 0;
}

.box.has-input .is-bottom {
  select {
    padding: 0 2rem;
    border-top: 1px solid get-color(light-blue-grey-two);
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
}

.select:not(.is-multiple)::after {
  border: 1px solid get-color(light-blue-grey);
  border-right: 0;
  border-top: 0;
  content: ' ';
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 0.5em;
  margin-top: -0.375em;
  right: 1.125em;
  top: 50%;
  z-index: 1;
}

.help {
  //margin-top: .6rem;
  padding: 0.6rem;
  font-size: 1.3rem;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}

.inputfile + label * {
  pointer-events: none;
}

.CalendarDay__selected_span {
  background: get-color-with-alpha('azure', 0.6);
  border: 1px solid white;
}

input[type='checkbox']:not(.is-standard) {
  position: relative;
  appearance: none;
  outline: none;
  width: 5rem !important;
  height: 3rem !important;
  background-color: #ffffff;
  border: 0.1rem solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -2rem 0 0 0 #ffffff;
  transition-duration: 200ms;

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 2.6rem;
    height: 2.6rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 0.2rem 0.4rem 0.6rem rgba(0, 0, 0, 0.2);
  }

  &:checked {
    border-color: get-color(production);
    box-shadow: inset 2rem 0 0 0 get-color(production);

    &:after {
      left: 2rem;
      box-shadow: -0.2rem 0.4rem 0.3rem rgba(0, 0, 0, 0.05);
    }
  }
}

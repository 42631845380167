.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

//.background-enter {
//  transform: translate(100%);
//}
//.background-enter.background-enter-active {
//  transform: translate(0%);
//  transition: transform 500ms ease-in-out;
//}
//.background-leave {
//  transform: translate(0%);
//}
//.background-leave.background-leave-active {
//  transform: translate(-100%);
//  transition: transform 500ms ease-in-out;
//}

.slide-from-right-appear {
  transform: translateX(100%);
}

.slide-from-right-appear.slide-from-right-appear-active {
  transform: translateX(0);
  transition: transform 300ms linear;
}
.slide-from-right-enter {
  transform: translateX(100%);
}

.slide-from-right-enter.slide-from-right-enter-active {
  transform: translateX(0);
  transition: transform 300ms linear;
}

.slide-from-right-exit {
  transform: translateX(0);
}

.slide-from-right-exit.slide-from-right-exit-active {
  transform: translateX(100%);
  transition: transform 300ms linear;
}


.SlideIn-appear {
  transform: translateX(30px);
  opacity: 0;
}
.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateX(0);;
  transition: all 0.6s linear;
}
.SlideIn-enter {
    opacity: 0;
    transform: translateX(30px);
}
.SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.2s linear 0.4s;
}
.SlideIn-leave {
    opacity: 1.0;
    transform: translateX(0);
}
.SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-30px);
    transition: all 0.2s linear;
}
$navbar-height: 6rem;
$sidebar-width: 6rem;
$view-height: calc(100vh - #{$navbar-height});
$font-name: LLCircularWeb, 'Helvetica Neue', Helvetica, sans-serif;

////@import '_bourbon/bourbon';
@import '_mq/mq';
@import 'libs/colors';
@import 'libs/mixins';
@import 'libs/functions';
@import 'libs/transitions';
@import 'libs/override';
@import 'libs/fonts';
@import 'libs/animations';

@import 'components/colors';
@import '../scss/components/button';
@import '../components/helpers/Loader';
@import 'libs/helpers';

html.home-layout {
  @import 'layouts/home';
}
html.default-layout {
  @import 'layouts/default';
  @import 'components/form';
  @import 'components/image';
  @import 'components/table';
  @import 'components/tabs';
  @import 'components/datepicker';
  // @deprecated
  @import 'components/steps';
  @import '../components/layout/Column';
  @import '../scss/components/navbar';
  @import '../scss/components/sidebar';
  @import '../scss/components/box';
  @import '../scss/components/tag';
  @import '../scss/components/title';
  @import '../scss/components/progress';
  @import '../scss/components/dropdown';
  @import '../scss/components/timeline';
  @import '../components/application/job/Job';
  @import '../components/application/phase/Phase';
  @import '../components/application/doc/Doc';
}

body {
  //font-family: $font-name;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
select,
textarea {
  font-family: $font-name;
}
.doc-modal {
  .modal-content {
    width: calc(100vw - 10%);
    height: calc(100vh - 10%);
  }
}

.swal2-popup {
  font-size: 1.6rem !important;
  font-family: $font-name !important;
}

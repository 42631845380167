& {
  overflow-y: hidden;
  padding-top: 60px;
  font-size: 62.5%; /* 1rem = 10px */
  background-color: get-color('body-grey');
  color: get-color-contrast('body-grey');
}
body {
  font-size: 1.5rem;
  height: calc(100vh - 6rem);
}

.main {
  display: flex;
  padding-left: $sidebar-width;
  & > * {
    width: 100%;
    height: $view-height;
  }
}

.hero.is-fullheight {
  min-height: $view-height;
}

.subtitle:not(:last-child) {
  margin-bottom: 0.8rem;
}

.level-left > .level-item > .level-image {
  margin-left: 1.3rem;
}

//a.navbar-item:hover,
//.a.navbar-item.is-active,
//.navbar-link:hover,
//.navbar-link.is-active {
//  background-color: transparent;
//  font-weight: initial;
//}

.side-info-box {
  .column {
    padding-top: 1rem;
  }
  h4 {
    color: get-color('dark');
    margin-bottom: 0;
  }
  p:not(.subtitle) {
    color: get-color('dark-grey-blue');
    font-size: 3.5rem;
    line-height: 1.29;
    margin-top: 2rem;
    font-weight: 500;
    text-align: center;
    &:last-child {
      margin-bottom: 1.7rem;
    }
  }
  .is-footer-info {
    .columns {
      margin-bottom: 0;
      .column {
        padding-bottom: 0;
        padding-right: 0;
      }
    }
    p.subtitle {
      color: get-color(bluish-grey);
      text-align: right;
    }
  }
}

.column-list-item {
  margin-right: -2.2rem;
  padding: 0 2.2rem 0rem 0;
  &:not(:last-child) {
    border-bottom: 0.1rem solid get-color(light-blue-grey-two);
  }
}

.box.is-doc {
  padding: 0.8rem 0.4rem 0.4rem;
  position: relative;
  max-width: 12rem;
  min-height: 8.8rem;
  display: flex;
  flex-direction: column;
  border: none;
  &.is-editable {
    border: solid 1px get-color(light-blue-grey-90);
    .remove {
      position: absolute;
      top: -1rem;
      right: -0.6rem;
      padding: 0.1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      color: white;
      .delete {
        background-color: get-color(error);
        &:hover {
          background-color: lighten(get-color(error), 10);
        }
      }
    }
  }
  color: get-color(slate);
  font-size: 1.3rem;
  justify-content: center;
  img {
    margin: 0 auto 0.4rem;
  }
  .fa {
    font-size: 1.6rem;
  }
}
.level.is-doc {
  margin-bottom: 0;
  padding: 1.5rem 0;
  &:not(:last-child) {
    border-bottom: 0.1rem solid get-color(light-blue-grey-two);
  }
}

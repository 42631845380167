/**
 * Returns a color
 */
/**c
 * Returns a color contrast
 */
/**
 * Returns a color with an alpha
 */
.fade-enter {
  opacity: 0.01; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }

.slide-from-right-appear {
  transform: translateX(100%); }

.slide-from-right-appear.slide-from-right-appear-active {
  transform: translateX(0);
  transition: transform 300ms linear; }

.slide-from-right-enter {
  transform: translateX(100%); }

.slide-from-right-enter.slide-from-right-enter-active {
  transform: translateX(0);
  transition: transform 300ms linear; }

.slide-from-right-exit {
  transform: translateX(0); }

.slide-from-right-exit.slide-from-right-exit-active {
  transform: translateX(100%);
  transition: transform 300ms linear; }

.SlideIn-appear {
  transform: translateX(30px);
  opacity: 0; }

.SlideIn-appear.SlideIn-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.6s linear; }

.SlideIn-enter {
  opacity: 0;
  transform: translateX(30px); }

.SlideIn-enter.SlideIn-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.2s linear 0.4s; }

.SlideIn-leave {
  opacity: 1.0;
  transform: translateX(0); }

.SlideIn-leave.SlideIn-leave-active {
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translateX(-30px);
  transition: all 0.2s linear; }

.icon.is-small .fa,
.fa {
  font-size: 1.5rem; }

.image.is-15x15 {
  height: 15px;
  width: 15px; }

.image.is-20x20 {
  height: 20px;
  width: 20px; }

.image.is-26x26 {
  height: 26px;
  width: 26px; }

.image.is-36x36 {
  height: 36px;
  width: 36px; }

a {
  color: rgba(38, 50, 56, 0.9); }

a[disabled],
button[disabled] {
  cursor: not-allowed;
  pointer-events: none; }

.title em,
.title span,
.subtitle em,
.subtitle span {
  font-weight: inherit; }

[class^='icon-'] {
  font-size: 1.6rem; }

.icon.is-small,
.icon.is-small [class^='icon-'] {
  font-size: 2rem; }

.icon.is-medium,
.icon.is-medium [class^='icon-'] {
  font-size: 2.5rem; }

.icon.is-large,
.icon.is-large [class^='icon-'] {
  font-size: 3rem; }

.select select:focus,
.select select.is-focused,
.select select:active,
.select select.is-active {
  /* border-color: #00d1b2; */
  box-shadow: none; }

.tabs li a {
  color: #455a64; }

.tabs li a.is-active {
  border-bottom-color: #2979ff;
  color: #2979ff; }

.swal2-container {
  z-index: 1000000; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-book-c.eot);
  src: url(../fonts/circular/lineto-circular-book-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-book-c.woff) format("woff"), url(../fonts/circular/lineto-circular-book-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-book-c.svg#LLCircularWeb-Book) format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-medium-c.eot);
  src: url(../fonts/circular/lineto-circular-medium-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-medium-c.woff) format("woff"), url(../fonts/circular/lineto-circular-medium-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-medium-c.svg#LLCircularWeb-Medium) format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-bold-c.eot);
  src: url(../fonts/circular/lineto-circular-bold-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-bold-c.woff) format("woff"), url(../fonts/circular/lineto-circular-bold-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-bold-c.svg#LLCircularWeb-Bold) format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-black-c.eot);
  src: url(../fonts/circular/lineto-circular-black-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-black-c.woff) format("woff"), url(../fonts/circular/lineto-circular-black-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-black-c.svg#LLCircularWeb-Black) format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-bookitalic-c.eot);
  src: url(../fonts/circular/lineto-circular-bookitalic-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-bookitalic-c.woff) format("woff"), url(../fonts/circular/lineto-circular-bookitalic-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-bookitalic-c.svg#LLCircularWeb-Bookitalic) format("svg");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-mediumitalic-c.eot);
  src: url(../fonts/circular/lineto-circular-mediumitalic-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-mediumitalic-c.woff) format("woff"), url(../fonts/circular/lineto-circular-mediumitalic-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-mediumitalic-c.svg#LLCircularWeb-Mediumitalic) format("svg");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-bolditalic-c.eot);
  src: url(../fonts/circular/lineto-circular-bolditalic-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-bolditalic-c.woff) format("woff"), url(../fonts/circular/lineto-circular-bolditalic-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-bolditalic-c.svg#LLCircularWeb-Bolditalic) format("svg");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: LLCircularWeb;
  src: url(../fonts/circular/lineto-circular-blackitalic-c.eot);
  src: url(../fonts/circular/lineto-circular-blackitalic-c.eot?#iefix) format("embedded-opentype"), url(../fonts/circular/lineto-circular-blackitalic-c.woff) format("woff"), url(../fonts/circular/lineto-circular-blackitalic-c.ttf) format("truetype"), url(../fonts/circular/lineto-circular-blackitalic-c.svg#LLCircularWeb-Blackitalic) format("svg");
  font-weight: 800;
  font-style: italic; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.rotate {
  animation-duration: 2500ms;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

.bg-transparent {
  background: rgba(0, 0, 0, 0) !important;
  transition: background 150ms ease-in-out; }

.bg-transparent-opacity {
  background: rgba(0, 0, 0, 0.8) !important; }

.bg-hover-transparent:hover {
  background: rgba(0, 0, 0, 0) !important; }

.fg-transparent {
  color: rgba(0, 0, 0, 0) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-transparent:hover {
  color: rgba(0, 0, 0, 0) !important; }

.bg-font-transparent {
  color: #263238 !important; }

.border-transparent {
  border-color: rgba(0, 0, 0, 0) !important; }

.border-top-transparent {
  border-top-color: rgba(0, 0, 0, 0) !important; }

.border-bottom-transparent {
  border-bottom-color: rgba(0, 0, 0, 0) !important; }

.border-left-transparent {
  border-left-color: rgba(0, 0, 0, 0) !important; }

.border-right-transparent {
  border-right-color: rgba(0, 0, 0, 0) !important; }

.bg-before-transparent:before,
.bg-after-transparent:after {
  background: rgba(0, 0, 0, 0) !important; }

.border-before-transparent:before,
.border-after-transparent:after {
  border-color: rgba(0, 0, 0, 0) !important; }

.border-top-before-transparent:before,
.border-top-after-transparent:after {
  border-top-color: rgba(0, 0, 0, 0) !important; }

.border-bottom-before-transparent:before,
.border-bottom-after-transparent:after {
  border-bottom-color: rgba(0, 0, 0, 0) !important; }

.border-left-before-transparent:before,
.border-left-after-transparent:after {
  border-left-color: rgba(0, 0, 0, 0) !important; }

.border-right-before-transparent:before,
.border-right-after-transparent:after {
  border-right-color: rgba(0, 0, 0, 0) !important; }

.bg-default {
  background: #263238 !important;
  transition: background 150ms ease-in-out; }

.bg-default-opacity {
  background: rgba(38, 50, 56, 0.8) !important; }

.bg-hover-default:hover {
  background: #263238 !important; }

.fg-default {
  color: #263238 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-default:hover {
  color: #263238 !important; }

.bg-font-default {
  color: #fff !important; }

.border-default {
  border-color: #263238 !important; }

.border-top-default {
  border-top-color: #263238 !important; }

.border-bottom-default {
  border-bottom-color: #263238 !important; }

.border-left-default {
  border-left-color: #263238 !important; }

.border-right-default {
  border-right-color: #263238 !important; }

.bg-before-default:before,
.bg-after-default:after {
  background: #263238 !important; }

.border-before-default:before,
.border-after-default:after {
  border-color: #263238 !important; }

.border-top-before-default:before,
.border-top-after-default:after {
  border-top-color: #263238 !important; }

.border-bottom-before-default:before,
.border-bottom-after-default:after {
  border-bottom-color: #263238 !important; }

.border-left-before-default:before,
.border-left-after-default:after {
  border-left-color: #263238 !important; }

.border-right-before-default:before,
.border-right-after-default:after {
  border-right-color: #263238 !important; }

.bg-white {
  background: #fff !important;
  transition: background 150ms ease-in-out; }

.bg-white-opacity {
  background: rgba(255, 255, 255, 0.8) !important; }

.bg-hover-white:hover {
  background: #fff !important; }

.fg-white {
  color: #fff !important;
  transition: color 150ms ease-in-out; }

.fg-hover-white:hover {
  color: #fff !important; }

.bg-font-white {
  color: #263238 !important; }

.border-white {
  border-color: #fff !important; }

.border-top-white {
  border-top-color: #fff !important; }

.border-bottom-white {
  border-bottom-color: #fff !important; }

.border-left-white {
  border-left-color: #fff !important; }

.border-right-white {
  border-right-color: #fff !important; }

.bg-before-white:before,
.bg-after-white:after {
  background: #fff !important; }

.border-before-white:before,
.border-after-white:after {
  border-color: #fff !important; }

.border-top-before-white:before,
.border-top-after-white:after {
  border-top-color: #fff !important; }

.border-bottom-before-white:before,
.border-bottom-after-white:after {
  border-bottom-color: #fff !important; }

.border-left-before-white:before,
.border-left-after-white:after {
  border-left-color: #fff !important; }

.border-right-before-white:before,
.border-right-after-white:after {
  border-right-color: #fff !important; }

.bg-black {
  background: #263238 !important;
  transition: background 150ms ease-in-out; }

.bg-black-opacity {
  background: rgba(38, 50, 56, 0.8) !important; }

.bg-hover-black:hover {
  background: #263238 !important; }

.fg-black {
  color: #263238 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-black:hover {
  color: #263238 !important; }

.bg-font-black {
  color: #fff !important; }

.border-black {
  border-color: #263238 !important; }

.border-top-black {
  border-top-color: #263238 !important; }

.border-bottom-black {
  border-bottom-color: #263238 !important; }

.border-left-black {
  border-left-color: #263238 !important; }

.border-right-black {
  border-right-color: #263238 !important; }

.bg-before-black:before,
.bg-after-black:after {
  background: #263238 !important; }

.border-before-black:before,
.border-after-black:after {
  border-color: #263238 !important; }

.border-top-before-black:before,
.border-top-after-black:after {
  border-top-color: #263238 !important; }

.border-bottom-before-black:before,
.border-bottom-after-black:after {
  border-bottom-color: #263238 !important; }

.border-left-before-black:before,
.border-left-after-black:after {
  border-left-color: #263238 !important; }

.border-right-before-black:before,
.border-right-after-black:after {
  border-right-color: #263238 !important; }

.bg-dark {
  background: #263238 !important;
  transition: background 150ms ease-in-out; }

.bg-dark-opacity {
  background: rgba(38, 50, 56, 0.8) !important; }

.bg-hover-dark:hover {
  background: #263238 !important; }

.fg-dark {
  color: #263238 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark:hover {
  color: #263238 !important; }

.bg-font-dark {
  color: #fff !important; }

.border-dark {
  border-color: #263238 !important; }

.border-top-dark {
  border-top-color: #263238 !important; }

.border-bottom-dark {
  border-bottom-color: #263238 !important; }

.border-left-dark {
  border-left-color: #263238 !important; }

.border-right-dark {
  border-right-color: #263238 !important; }

.bg-before-dark:before,
.bg-after-dark:after {
  background: #263238 !important; }

.border-before-dark:before,
.border-after-dark:after {
  border-color: #263238 !important; }

.border-top-before-dark:before,
.border-top-after-dark:after {
  border-top-color: #263238 !important; }

.border-bottom-before-dark:before,
.border-bottom-after-dark:after {
  border-bottom-color: #263238 !important; }

.border-left-before-dark:before,
.border-left-after-dark:after {
  border-left-color: #263238 !important; }

.border-right-before-dark:before,
.border-right-after-dark:after {
  border-right-color: #263238 !important; }

.bg-slate {
  background: #455a64 !important;
  transition: background 150ms ease-in-out; }

.bg-slate-opacity {
  background: rgba(69, 90, 100, 0.8) !important; }

.bg-hover-slate:hover {
  background: #455a64 !important; }

.fg-slate {
  color: #455a64 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-slate:hover {
  color: #455a64 !important; }

.bg-font-slate {
  color: #fff !important; }

.border-slate {
  border-color: #455a64 !important; }

.border-top-slate {
  border-top-color: #455a64 !important; }

.border-bottom-slate {
  border-bottom-color: #455a64 !important; }

.border-left-slate {
  border-left-color: #455a64 !important; }

.border-right-slate {
  border-right-color: #455a64 !important; }

.bg-before-slate:before,
.bg-after-slate:after {
  background: #455a64 !important; }

.border-before-slate:before,
.border-after-slate:after {
  border-color: #455a64 !important; }

.border-top-before-slate:before,
.border-top-after-slate:after {
  border-top-color: #455a64 !important; }

.border-bottom-before-slate:before,
.border-bottom-after-slate:after {
  border-bottom-color: #455a64 !important; }

.border-left-before-slate:before,
.border-left-after-slate:after {
  border-left-color: #455a64 !important; }

.border-right-before-slate:before,
.border-right-after-slate:after {
  border-right-color: #455a64 !important; }

.bg-body-grey {
  background: #f2f6f9 !important;
  transition: background 150ms ease-in-out; }

.bg-body-grey-opacity {
  background: rgba(242, 246, 249, 0.8) !important; }

.bg-hover-body-grey:hover {
  background: #f2f6f9 !important; }

.fg-body-grey {
  color: #f2f6f9 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-body-grey:hover {
  color: #f2f6f9 !important; }

.bg-font-body-grey {
  color: #263238 !important; }

.border-body-grey {
  border-color: #f2f6f9 !important; }

.border-top-body-grey {
  border-top-color: #f2f6f9 !important; }

.border-bottom-body-grey {
  border-bottom-color: #f2f6f9 !important; }

.border-left-body-grey {
  border-left-color: #f2f6f9 !important; }

.border-right-body-grey {
  border-right-color: #f2f6f9 !important; }

.bg-before-body-grey:before,
.bg-after-body-grey:after {
  background: #f2f6f9 !important; }

.border-before-body-grey:before,
.border-after-body-grey:after {
  border-color: #f2f6f9 !important; }

.border-top-before-body-grey:before,
.border-top-after-body-grey:after {
  border-top-color: #f2f6f9 !important; }

.border-bottom-before-body-grey:before,
.border-bottom-after-body-grey:after {
  border-bottom-color: #f2f6f9 !important; }

.border-left-before-body-grey:before,
.border-left-after-body-grey:after {
  border-left-color: #f2f6f9 !important; }

.border-right-before-body-grey:before,
.border-right-after-body-grey:after {
  border-right-color: #f2f6f9 !important; }

.bg-light-blue-grey {
  background: #a8c6df !important;
  transition: background 150ms ease-in-out; }

.bg-light-blue-grey-opacity {
  background: rgba(168, 198, 223, 0.8) !important; }

.bg-hover-light-blue-grey:hover {
  background: #a8c6df !important; }

.fg-light-blue-grey {
  color: #a8c6df !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-blue-grey:hover {
  color: #a8c6df !important; }

.bg-font-light-blue-grey {
  color: #263238 !important; }

.border-light-blue-grey {
  border-color: #a8c6df !important; }

.border-top-light-blue-grey {
  border-top-color: #a8c6df !important; }

.border-bottom-light-blue-grey {
  border-bottom-color: #a8c6df !important; }

.border-left-light-blue-grey {
  border-left-color: #a8c6df !important; }

.border-right-light-blue-grey {
  border-right-color: #a8c6df !important; }

.bg-before-light-blue-grey:before,
.bg-after-light-blue-grey:after {
  background: #a8c6df !important; }

.border-before-light-blue-grey:before,
.border-after-light-blue-grey:after {
  border-color: #a8c6df !important; }

.border-top-before-light-blue-grey:before,
.border-top-after-light-blue-grey:after {
  border-top-color: #a8c6df !important; }

.border-bottom-before-light-blue-grey:before,
.border-bottom-after-light-blue-grey:after {
  border-bottom-color: #a8c6df !important; }

.border-left-before-light-blue-grey:before,
.border-left-after-light-blue-grey:after {
  border-left-color: #a8c6df !important; }

.border-right-before-light-blue-grey:before,
.border-right-after-light-blue-grey:after {
  border-right-color: #a8c6df !important; }

.bg-light-blue-grey-20 {
  background: rgba(168, 198, 223, 0.2) !important;
  transition: background 150ms ease-in-out; }

.bg-light-blue-grey-20-opacity {
  background: rgba(168, 198, 223, 0.8) !important; }

.bg-hover-light-blue-grey-20:hover {
  background: rgba(168, 198, 223, 0.2) !important; }

.fg-light-blue-grey-20 {
  color: rgba(168, 198, 223, 0.2) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-blue-grey-20:hover {
  color: rgba(168, 198, 223, 0.2) !important; }

.bg-font-light-blue-grey-20 {
  color: #263238 !important; }

.border-light-blue-grey-20 {
  border-color: rgba(168, 198, 223, 0.2) !important; }

.border-top-light-blue-grey-20 {
  border-top-color: rgba(168, 198, 223, 0.2) !important; }

.border-bottom-light-blue-grey-20 {
  border-bottom-color: rgba(168, 198, 223, 0.2) !important; }

.border-left-light-blue-grey-20 {
  border-left-color: rgba(168, 198, 223, 0.2) !important; }

.border-right-light-blue-grey-20 {
  border-right-color: rgba(168, 198, 223, 0.2) !important; }

.bg-before-light-blue-grey-20:before,
.bg-after-light-blue-grey-20:after {
  background: rgba(168, 198, 223, 0.2) !important; }

.border-before-light-blue-grey-20:before,
.border-after-light-blue-grey-20:after {
  border-color: rgba(168, 198, 223, 0.2) !important; }

.border-top-before-light-blue-grey-20:before,
.border-top-after-light-blue-grey-20:after {
  border-top-color: rgba(168, 198, 223, 0.2) !important; }

.border-bottom-before-light-blue-grey-20:before,
.border-bottom-after-light-blue-grey-20:after {
  border-bottom-color: rgba(168, 198, 223, 0.2) !important; }

.border-left-before-light-blue-grey-20:before,
.border-left-after-light-blue-grey-20:after {
  border-left-color: rgba(168, 198, 223, 0.2) !important; }

.border-right-before-light-blue-grey-20:before,
.border-right-after-light-blue-grey-20:after {
  border-right-color: rgba(168, 198, 223, 0.2) !important; }

.bg-light-blue-grey-80 {
  background: rgba(168, 198, 223, 0.8) !important;
  transition: background 150ms ease-in-out; }

.bg-light-blue-grey-80-opacity {
  background: rgba(168, 198, 223, 0.8) !important; }

.bg-hover-light-blue-grey-80:hover {
  background: rgba(168, 198, 223, 0.8) !important; }

.fg-light-blue-grey-80 {
  color: rgba(168, 198, 223, 0.8) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-blue-grey-80:hover {
  color: rgba(168, 198, 223, 0.8) !important; }

.bg-font-light-blue-grey-80 {
  color: #263238 !important; }

.border-light-blue-grey-80 {
  border-color: rgba(168, 198, 223, 0.8) !important; }

.border-top-light-blue-grey-80 {
  border-top-color: rgba(168, 198, 223, 0.8) !important; }

.border-bottom-light-blue-grey-80 {
  border-bottom-color: rgba(168, 198, 223, 0.8) !important; }

.border-left-light-blue-grey-80 {
  border-left-color: rgba(168, 198, 223, 0.8) !important; }

.border-right-light-blue-grey-80 {
  border-right-color: rgba(168, 198, 223, 0.8) !important; }

.bg-before-light-blue-grey-80:before,
.bg-after-light-blue-grey-80:after {
  background: rgba(168, 198, 223, 0.8) !important; }

.border-before-light-blue-grey-80:before,
.border-after-light-blue-grey-80:after {
  border-color: rgba(168, 198, 223, 0.8) !important; }

.border-top-before-light-blue-grey-80:before,
.border-top-after-light-blue-grey-80:after {
  border-top-color: rgba(168, 198, 223, 0.8) !important; }

.border-bottom-before-light-blue-grey-80:before,
.border-bottom-after-light-blue-grey-80:after {
  border-bottom-color: rgba(168, 198, 223, 0.8) !important; }

.border-left-before-light-blue-grey-80:before,
.border-left-after-light-blue-grey-80:after {
  border-left-color: rgba(168, 198, 223, 0.8) !important; }

.border-right-before-light-blue-grey-80:before,
.border-right-after-light-blue-grey-80:after {
  border-right-color: rgba(168, 198, 223, 0.8) !important; }

.bg-light-blue-grey-90 {
  background: rgba(183, 210, 229, 0.9) !important;
  transition: background 150ms ease-in-out; }

.bg-light-blue-grey-90-opacity {
  background: rgba(183, 210, 229, 0.8) !important; }

.bg-hover-light-blue-grey-90:hover {
  background: rgba(183, 210, 229, 0.9) !important; }

.fg-light-blue-grey-90 {
  color: rgba(183, 210, 229, 0.9) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-blue-grey-90:hover {
  color: rgba(183, 210, 229, 0.9) !important; }

.bg-font-light-blue-grey-90 {
  color: #263238 !important; }

.border-light-blue-grey-90 {
  border-color: rgba(183, 210, 229, 0.9) !important; }

.border-top-light-blue-grey-90 {
  border-top-color: rgba(183, 210, 229, 0.9) !important; }

.border-bottom-light-blue-grey-90 {
  border-bottom-color: rgba(183, 210, 229, 0.9) !important; }

.border-left-light-blue-grey-90 {
  border-left-color: rgba(183, 210, 229, 0.9) !important; }

.border-right-light-blue-grey-90 {
  border-right-color: rgba(183, 210, 229, 0.9) !important; }

.bg-before-light-blue-grey-90:before,
.bg-after-light-blue-grey-90:after {
  background: rgba(183, 210, 229, 0.9) !important; }

.border-before-light-blue-grey-90:before,
.border-after-light-blue-grey-90:after {
  border-color: rgba(183, 210, 229, 0.9) !important; }

.border-top-before-light-blue-grey-90:before,
.border-top-after-light-blue-grey-90:after {
  border-top-color: rgba(183, 210, 229, 0.9) !important; }

.border-bottom-before-light-blue-grey-90:before,
.border-bottom-after-light-blue-grey-90:after {
  border-bottom-color: rgba(183, 210, 229, 0.9) !important; }

.border-left-before-light-blue-grey-90:before,
.border-left-after-light-blue-grey-90:after {
  border-left-color: rgba(183, 210, 229, 0.9) !important; }

.border-right-before-light-blue-grey-90:before,
.border-right-after-light-blue-grey-90:after {
  border-right-color: rgba(183, 210, 229, 0.9) !important; }

.bg-bluish-grey {
  background: #78909c !important;
  transition: background 150ms ease-in-out; }

.bg-bluish-grey-opacity {
  background: rgba(120, 144, 156, 0.8) !important; }

.bg-hover-bluish-grey:hover {
  background: #78909c !important; }

.fg-bluish-grey {
  color: #78909c !important;
  transition: color 150ms ease-in-out; }

.fg-hover-bluish-grey:hover {
  color: #78909c !important; }

.bg-font-bluish-grey {
  color: #fff !important; }

.border-bluish-grey {
  border-color: #78909c !important; }

.border-top-bluish-grey {
  border-top-color: #78909c !important; }

.border-bottom-bluish-grey {
  border-bottom-color: #78909c !important; }

.border-left-bluish-grey {
  border-left-color: #78909c !important; }

.border-right-bluish-grey {
  border-right-color: #78909c !important; }

.bg-before-bluish-grey:before,
.bg-after-bluish-grey:after {
  background: #78909c !important; }

.border-before-bluish-grey:before,
.border-after-bluish-grey:after {
  border-color: #78909c !important; }

.border-top-before-bluish-grey:before,
.border-top-after-bluish-grey:after {
  border-top-color: #78909c !important; }

.border-bottom-before-bluish-grey:before,
.border-bottom-after-bluish-grey:after {
  border-bottom-color: #78909c !important; }

.border-left-before-bluish-grey:before,
.border-left-after-bluish-grey:after {
  border-left-color: #78909c !important; }

.border-right-before-bluish-grey:before,
.border-right-after-bluish-grey:after {
  border-right-color: #78909c !important; }

.bg-light-grey {
  background: #f8f9f9 !important;
  transition: background 150ms ease-in-out; }

.bg-light-grey-opacity {
  background: rgba(248, 249, 249, 0.8) !important; }

.bg-hover-light-grey:hover {
  background: #f8f9f9 !important; }

.fg-light-grey {
  color: #f8f9f9 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-grey:hover {
  color: #f8f9f9 !important; }

.bg-font-light-grey {
  color: #263238 !important; }

.border-light-grey {
  border-color: #f8f9f9 !important; }

.border-top-light-grey {
  border-top-color: #f8f9f9 !important; }

.border-bottom-light-grey {
  border-bottom-color: #f8f9f9 !important; }

.border-left-light-grey {
  border-left-color: #f8f9f9 !important; }

.border-right-light-grey {
  border-right-color: #f8f9f9 !important; }

.bg-before-light-grey:before,
.bg-after-light-grey:after {
  background: #f8f9f9 !important; }

.border-before-light-grey:before,
.border-after-light-grey:after {
  border-color: #f8f9f9 !important; }

.border-top-before-light-grey:before,
.border-top-after-light-grey:after {
  border-top-color: #f8f9f9 !important; }

.border-bottom-before-light-grey:before,
.border-bottom-after-light-grey:after {
  border-bottom-color: #f8f9f9 !important; }

.border-left-before-light-grey:before,
.border-left-after-light-grey:after {
  border-left-color: #f8f9f9 !important; }

.border-right-before-light-grey:before,
.border-right-after-light-grey:after {
  border-right-color: #f8f9f9 !important; }

.bg-dark-sky-blue-10 {
  background: rgba(30, 136, 229, 0.1) !important;
  transition: background 150ms ease-in-out; }

.bg-dark-sky-blue-10-opacity {
  background: rgba(30, 136, 229, 0.8) !important; }

.bg-hover-dark-sky-blue-10:hover {
  background: rgba(30, 136, 229, 0.1) !important; }

.fg-dark-sky-blue-10 {
  color: rgba(30, 136, 229, 0.1) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark-sky-blue-10:hover {
  color: rgba(30, 136, 229, 0.1) !important; }

.bg-font-dark-sky-blue-10 {
  color: #455a64 !important; }

.border-dark-sky-blue-10 {
  border-color: rgba(30, 136, 229, 0.1) !important; }

.border-top-dark-sky-blue-10 {
  border-top-color: rgba(30, 136, 229, 0.1) !important; }

.border-bottom-dark-sky-blue-10 {
  border-bottom-color: rgba(30, 136, 229, 0.1) !important; }

.border-left-dark-sky-blue-10 {
  border-left-color: rgba(30, 136, 229, 0.1) !important; }

.border-right-dark-sky-blue-10 {
  border-right-color: rgba(30, 136, 229, 0.1) !important; }

.bg-before-dark-sky-blue-10:before,
.bg-after-dark-sky-blue-10:after {
  background: rgba(30, 136, 229, 0.1) !important; }

.border-before-dark-sky-blue-10:before,
.border-after-dark-sky-blue-10:after {
  border-color: rgba(30, 136, 229, 0.1) !important; }

.border-top-before-dark-sky-blue-10:before,
.border-top-after-dark-sky-blue-10:after {
  border-top-color: rgba(30, 136, 229, 0.1) !important; }

.border-bottom-before-dark-sky-blue-10:before,
.border-bottom-after-dark-sky-blue-10:after {
  border-bottom-color: rgba(30, 136, 229, 0.1) !important; }

.border-left-before-dark-sky-blue-10:before,
.border-left-after-dark-sky-blue-10:after {
  border-left-color: rgba(30, 136, 229, 0.1) !important; }

.border-right-before-dark-sky-blue-10:before,
.border-right-after-dark-sky-blue-10:after {
  border-right-color: rgba(30, 136, 229, 0.1) !important; }

.bg-light-blue-grey-two {
  background: #d8e5ef !important;
  transition: background 150ms ease-in-out; }

.bg-light-blue-grey-two-opacity {
  background: rgba(216, 229, 239, 0.8) !important; }

.bg-hover-light-blue-grey-two:hover {
  background: #d8e5ef !important; }

.fg-light-blue-grey-two {
  color: #d8e5ef !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-blue-grey-two:hover {
  color: #d8e5ef !important; }

.bg-font-light-blue-grey-two {
  color: #263238 !important; }

.border-light-blue-grey-two {
  border-color: #d8e5ef !important; }

.border-top-light-blue-grey-two {
  border-top-color: #d8e5ef !important; }

.border-bottom-light-blue-grey-two {
  border-bottom-color: #d8e5ef !important; }

.border-left-light-blue-grey-two {
  border-left-color: #d8e5ef !important; }

.border-right-light-blue-grey-two {
  border-right-color: #d8e5ef !important; }

.bg-before-light-blue-grey-two:before,
.bg-after-light-blue-grey-two:after {
  background: #d8e5ef !important; }

.border-before-light-blue-grey-two:before,
.border-after-light-blue-grey-two:after {
  border-color: #d8e5ef !important; }

.border-top-before-light-blue-grey-two:before,
.border-top-after-light-blue-grey-two:after {
  border-top-color: #d8e5ef !important; }

.border-bottom-before-light-blue-grey-two:before,
.border-bottom-after-light-blue-grey-two:after {
  border-bottom-color: #d8e5ef !important; }

.border-left-before-light-blue-grey-two:before,
.border-left-after-light-blue-grey-two:after {
  border-left-color: #d8e5ef !important; }

.border-right-before-light-blue-grey-two:before,
.border-right-after-light-blue-grey-two:after {
  border-right-color: #d8e5ef !important; }

.bg-dark-grey-blue {
  background: #2c4059 !important;
  transition: background 150ms ease-in-out; }

.bg-dark-grey-blue-opacity {
  background: rgba(44, 64, 89, 0.8) !important; }

.bg-hover-dark-grey-blue:hover {
  background: #2c4059 !important; }

.fg-dark-grey-blue {
  color: #2c4059 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark-grey-blue:hover {
  color: #2c4059 !important; }

.bg-font-dark-grey-blue {
  color: #fff !important; }

.border-dark-grey-blue {
  border-color: #2c4059 !important; }

.border-top-dark-grey-blue {
  border-top-color: #2c4059 !important; }

.border-bottom-dark-grey-blue {
  border-bottom-color: #2c4059 !important; }

.border-left-dark-grey-blue {
  border-left-color: #2c4059 !important; }

.border-right-dark-grey-blue {
  border-right-color: #2c4059 !important; }

.bg-before-dark-grey-blue:before,
.bg-after-dark-grey-blue:after {
  background: #2c4059 !important; }

.border-before-dark-grey-blue:before,
.border-after-dark-grey-blue:after {
  border-color: #2c4059 !important; }

.border-top-before-dark-grey-blue:before,
.border-top-after-dark-grey-blue:after {
  border-top-color: #2c4059 !important; }

.border-bottom-before-dark-grey-blue:before,
.border-bottom-after-dark-grey-blue:after {
  border-bottom-color: #2c4059 !important; }

.border-left-before-dark-grey-blue:before,
.border-left-after-dark-grey-blue:after {
  border-left-color: #2c4059 !important; }

.border-right-before-dark-grey-blue:before,
.border-right-after-dark-grey-blue:after {
  border-right-color: #2c4059 !important; }

.bg-dark-grey-blue-16 {
  background: rgba(44, 64, 90, 0.16) !important;
  transition: background 150ms ease-in-out; }

.bg-dark-grey-blue-16-opacity {
  background: rgba(44, 64, 90, 0.8) !important; }

.bg-hover-dark-grey-blue-16:hover {
  background: rgba(44, 64, 90, 0.16) !important; }

.fg-dark-grey-blue-16 {
  color: rgba(44, 64, 90, 0.16) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark-grey-blue-16:hover {
  color: rgba(44, 64, 90, 0.16) !important; }

.bg-font-dark-grey-blue-16 {
  color: #263238 !important; }

.border-dark-grey-blue-16 {
  border-color: rgba(44, 64, 90, 0.16) !important; }

.border-top-dark-grey-blue-16 {
  border-top-color: rgba(44, 64, 90, 0.16) !important; }

.border-bottom-dark-grey-blue-16 {
  border-bottom-color: rgba(44, 64, 90, 0.16) !important; }

.border-left-dark-grey-blue-16 {
  border-left-color: rgba(44, 64, 90, 0.16) !important; }

.border-right-dark-grey-blue-16 {
  border-right-color: rgba(44, 64, 90, 0.16) !important; }

.bg-before-dark-grey-blue-16:before,
.bg-after-dark-grey-blue-16:after {
  background: rgba(44, 64, 90, 0.16) !important; }

.border-before-dark-grey-blue-16:before,
.border-after-dark-grey-blue-16:after {
  border-color: rgba(44, 64, 90, 0.16) !important; }

.border-top-before-dark-grey-blue-16:before,
.border-top-after-dark-grey-blue-16:after {
  border-top-color: rgba(44, 64, 90, 0.16) !important; }

.border-bottom-before-dark-grey-blue-16:before,
.border-bottom-after-dark-grey-blue-16:after {
  border-bottom-color: rgba(44, 64, 90, 0.16) !important; }

.border-left-before-dark-grey-blue-16:before,
.border-left-after-dark-grey-blue-16:after {
  border-left-color: rgba(44, 64, 90, 0.16) !important; }

.border-right-before-dark-grey-blue-16:before,
.border-right-after-dark-grey-blue-16:after {
  border-right-color: rgba(44, 64, 90, 0.16) !important; }

.bg-dark-2 {
  background: rgba(38, 50, 56, 0.02) !important;
  transition: background 150ms ease-in-out; }

.bg-dark-2-opacity {
  background: rgba(38, 50, 56, 0.8) !important; }

.bg-hover-dark-2:hover {
  background: rgba(38, 50, 56, 0.02) !important; }

.fg-dark-2 {
  color: rgba(38, 50, 56, 0.02) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark-2:hover {
  color: rgba(38, 50, 56, 0.02) !important; }

.bg-font-dark-2 {
  color: #263238 !important; }

.border-dark-2 {
  border-color: rgba(38, 50, 56, 0.02) !important; }

.border-top-dark-2 {
  border-top-color: rgba(38, 50, 56, 0.02) !important; }

.border-bottom-dark-2 {
  border-bottom-color: rgba(38, 50, 56, 0.02) !important; }

.border-left-dark-2 {
  border-left-color: rgba(38, 50, 56, 0.02) !important; }

.border-right-dark-2 {
  border-right-color: rgba(38, 50, 56, 0.02) !important; }

.bg-before-dark-2:before,
.bg-after-dark-2:after {
  background: rgba(38, 50, 56, 0.02) !important; }

.border-before-dark-2:before,
.border-after-dark-2:after {
  border-color: rgba(38, 50, 56, 0.02) !important; }

.border-top-before-dark-2:before,
.border-top-after-dark-2:after {
  border-top-color: rgba(38, 50, 56, 0.02) !important; }

.border-bottom-before-dark-2:before,
.border-bottom-after-dark-2:after {
  border-bottom-color: rgba(38, 50, 56, 0.02) !important; }

.border-left-before-dark-2:before,
.border-left-after-dark-2:after {
  border-left-color: rgba(38, 50, 56, 0.02) !important; }

.border-right-before-dark-2:before,
.border-right-after-dark-2:after {
  border-right-color: rgba(38, 50, 56, 0.02) !important; }

.bg-dark-3 {
  background: rgba(38, 50, 56, 0.03) !important;
  transition: background 150ms ease-in-out; }

.bg-dark-3-opacity {
  background: rgba(38, 50, 56, 0.8) !important; }

.bg-hover-dark-3:hover {
  background: rgba(38, 50, 56, 0.03) !important; }

.fg-dark-3 {
  color: rgba(38, 50, 56, 0.03) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark-3:hover {
  color: rgba(38, 50, 56, 0.03) !important; }

.bg-font-dark-3 {
  color: #263238 !important; }

.border-dark-3 {
  border-color: rgba(38, 50, 56, 0.03) !important; }

.border-top-dark-3 {
  border-top-color: rgba(38, 50, 56, 0.03) !important; }

.border-bottom-dark-3 {
  border-bottom-color: rgba(38, 50, 56, 0.03) !important; }

.border-left-dark-3 {
  border-left-color: rgba(38, 50, 56, 0.03) !important; }

.border-right-dark-3 {
  border-right-color: rgba(38, 50, 56, 0.03) !important; }

.bg-before-dark-3:before,
.bg-after-dark-3:after {
  background: rgba(38, 50, 56, 0.03) !important; }

.border-before-dark-3:before,
.border-after-dark-3:after {
  border-color: rgba(38, 50, 56, 0.03) !important; }

.border-top-before-dark-3:before,
.border-top-after-dark-3:after {
  border-top-color: rgba(38, 50, 56, 0.03) !important; }

.border-bottom-before-dark-3:before,
.border-bottom-after-dark-3:after {
  border-bottom-color: rgba(38, 50, 56, 0.03) !important; }

.border-left-before-dark-3:before,
.border-left-after-dark-3:after {
  border-left-color: rgba(38, 50, 56, 0.03) !important; }

.border-right-before-dark-3:before,
.border-right-after-dark-3:after {
  border-right-color: rgba(38, 50, 56, 0.03) !important; }

.bg-charcoal-grey {
  background: #37474f !important;
  transition: background 150ms ease-in-out; }

.bg-charcoal-grey-opacity {
  background: rgba(55, 71, 79, 0.8) !important; }

.bg-hover-charcoal-grey:hover {
  background: #37474f !important; }

.fg-charcoal-grey {
  color: #37474f !important;
  transition: color 150ms ease-in-out; }

.fg-hover-charcoal-grey:hover {
  color: #37474f !important; }

.bg-font-charcoal-grey {
  color: #fff !important; }

.border-charcoal-grey {
  border-color: #37474f !important; }

.border-top-charcoal-grey {
  border-top-color: #37474f !important; }

.border-bottom-charcoal-grey {
  border-bottom-color: #37474f !important; }

.border-left-charcoal-grey {
  border-left-color: #37474f !important; }

.border-right-charcoal-grey {
  border-right-color: #37474f !important; }

.bg-before-charcoal-grey:before,
.bg-after-charcoal-grey:after {
  background: #37474f !important; }

.border-before-charcoal-grey:before,
.border-after-charcoal-grey:after {
  border-color: #37474f !important; }

.border-top-before-charcoal-grey:before,
.border-top-after-charcoal-grey:after {
  border-top-color: #37474f !important; }

.border-bottom-before-charcoal-grey:before,
.border-bottom-after-charcoal-grey:after {
  border-bottom-color: #37474f !important; }

.border-left-before-charcoal-grey:before,
.border-left-after-charcoal-grey:after {
  border-left-color: #37474f !important; }

.border-right-before-charcoal-grey:before,
.border-right-after-charcoal-grey:after {
  border-right-color: #37474f !important; }

.bg-pale-grey {
  background: #fafbfc !important;
  transition: background 150ms ease-in-out; }

.bg-pale-grey-opacity {
  background: rgba(250, 251, 252, 0.8) !important; }

.bg-hover-pale-grey:hover {
  background: #fafbfc !important; }

.fg-pale-grey {
  color: #fafbfc !important;
  transition: color 150ms ease-in-out; }

.fg-hover-pale-grey:hover {
  color: #fafbfc !important; }

.bg-font-pale-grey {
  color: #263238 !important; }

.border-pale-grey {
  border-color: #fafbfc !important; }

.border-top-pale-grey {
  border-top-color: #fafbfc !important; }

.border-bottom-pale-grey {
  border-bottom-color: #fafbfc !important; }

.border-left-pale-grey {
  border-left-color: #fafbfc !important; }

.border-right-pale-grey {
  border-right-color: #fafbfc !important; }

.bg-before-pale-grey:before,
.bg-after-pale-grey:after {
  background: #fafbfc !important; }

.border-before-pale-grey:before,
.border-after-pale-grey:after {
  border-color: #fafbfc !important; }

.border-top-before-pale-grey:before,
.border-top-after-pale-grey:after {
  border-top-color: #fafbfc !important; }

.border-bottom-before-pale-grey:before,
.border-bottom-after-pale-grey:after {
  border-bottom-color: #fafbfc !important; }

.border-left-before-pale-grey:before,
.border-left-after-pale-grey:after {
  border-left-color: #fafbfc !important; }

.border-right-before-pale-grey:before,
.border-right-after-pale-grey:after {
  border-right-color: #fafbfc !important; }

.bg-greyblue {
  background: #8dabc4 !important;
  transition: background 150ms ease-in-out; }

.bg-greyblue-opacity {
  background: rgba(141, 171, 196, 0.8) !important; }

.bg-hover-greyblue:hover {
  background: #8dabc4 !important; }

.fg-greyblue {
  color: #8dabc4 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-greyblue:hover {
  color: #8dabc4 !important; }

.bg-font-greyblue {
  color: #fff !important; }

.border-greyblue {
  border-color: #8dabc4 !important; }

.border-top-greyblue {
  border-top-color: #8dabc4 !important; }

.border-bottom-greyblue {
  border-bottom-color: #8dabc4 !important; }

.border-left-greyblue {
  border-left-color: #8dabc4 !important; }

.border-right-greyblue {
  border-right-color: #8dabc4 !important; }

.bg-before-greyblue:before,
.bg-after-greyblue:after {
  background: #8dabc4 !important; }

.border-before-greyblue:before,
.border-after-greyblue:after {
  border-color: #8dabc4 !important; }

.border-top-before-greyblue:before,
.border-top-after-greyblue:after {
  border-top-color: #8dabc4 !important; }

.border-bottom-before-greyblue:before,
.border-bottom-after-greyblue:after {
  border-bottom-color: #8dabc4 !important; }

.border-left-before-greyblue:before,
.border-left-after-greyblue:after {
  border-left-color: #8dabc4 !important; }

.border-right-before-greyblue:before,
.border-right-after-greyblue:after {
  border-right-color: #8dabc4 !important; }

.bg-bluey-grey {
  background: #90a4ae !important;
  transition: background 150ms ease-in-out; }

.bg-bluey-grey-opacity {
  background: rgba(144, 164, 174, 0.8) !important; }

.bg-hover-bluey-grey:hover {
  background: #90a4ae !important; }

.fg-bluey-grey {
  color: #90a4ae !important;
  transition: color 150ms ease-in-out; }

.fg-hover-bluey-grey:hover {
  color: #90a4ae !important; }

.bg-font-bluey-grey {
  color: #fff !important; }

.border-bluey-grey {
  border-color: #90a4ae !important; }

.border-top-bluey-grey {
  border-top-color: #90a4ae !important; }

.border-bottom-bluey-grey {
  border-bottom-color: #90a4ae !important; }

.border-left-bluey-grey {
  border-left-color: #90a4ae !important; }

.border-right-bluey-grey {
  border-right-color: #90a4ae !important; }

.bg-before-bluey-grey:before,
.bg-after-bluey-grey:after {
  background: #90a4ae !important; }

.border-before-bluey-grey:before,
.border-after-bluey-grey:after {
  border-color: #90a4ae !important; }

.border-top-before-bluey-grey:before,
.border-top-after-bluey-grey:after {
  border-top-color: #90a4ae !important; }

.border-bottom-before-bluey-grey:before,
.border-bottom-after-bluey-grey:after {
  border-bottom-color: #90a4ae !important; }

.border-left-before-bluey-grey:before,
.border-left-after-bluey-grey:after {
  border-left-color: #90a4ae !important; }

.border-right-before-bluey-grey:before,
.border-right-after-bluey-grey:after {
  border-right-color: #90a4ae !important; }

.bg-pale-grey-two {
  background: #f2f6f9 !important;
  transition: background 150ms ease-in-out; }

.bg-pale-grey-two-opacity {
  background: rgba(242, 246, 249, 0.8) !important; }

.bg-hover-pale-grey-two:hover {
  background: #f2f6f9 !important; }

.fg-pale-grey-two {
  color: #f2f6f9 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-pale-grey-two:hover {
  color: #f2f6f9 !important; }

.bg-font-pale-grey-two {
  color: #263238 !important; }

.border-pale-grey-two {
  border-color: #f2f6f9 !important; }

.border-top-pale-grey-two {
  border-top-color: #f2f6f9 !important; }

.border-bottom-pale-grey-two {
  border-bottom-color: #f2f6f9 !important; }

.border-left-pale-grey-two {
  border-left-color: #f2f6f9 !important; }

.border-right-pale-grey-two {
  border-right-color: #f2f6f9 !important; }

.bg-before-pale-grey-two:before,
.bg-after-pale-grey-two:after {
  background: #f2f6f9 !important; }

.border-before-pale-grey-two:before,
.border-after-pale-grey-two:after {
  border-color: #f2f6f9 !important; }

.border-top-before-pale-grey-two:before,
.border-top-after-pale-grey-two:after {
  border-top-color: #f2f6f9 !important; }

.border-bottom-before-pale-grey-two:before,
.border-bottom-after-pale-grey-two:after {
  border-bottom-color: #f2f6f9 !important; }

.border-left-before-pale-grey-two:before,
.border-left-after-pale-grey-two:after {
  border-left-color: #f2f6f9 !important; }

.border-right-before-pale-grey-two:before,
.border-right-after-pale-grey-two:after {
  border-right-color: #f2f6f9 !important; }

.bg-pale-grey-three {
  background: #e5eef5 !important;
  transition: background 150ms ease-in-out; }

.bg-pale-grey-three-opacity {
  background: rgba(229, 238, 245, 0.8) !important; }

.bg-hover-pale-grey-three:hover {
  background: #e5eef5 !important; }

.fg-pale-grey-three {
  color: #e5eef5 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-pale-grey-three:hover {
  color: #e5eef5 !important; }

.bg-font-pale-grey-three {
  color: #263238 !important; }

.border-pale-grey-three {
  border-color: #e5eef5 !important; }

.border-top-pale-grey-three {
  border-top-color: #e5eef5 !important; }

.border-bottom-pale-grey-three {
  border-bottom-color: #e5eef5 !important; }

.border-left-pale-grey-three {
  border-left-color: #e5eef5 !important; }

.border-right-pale-grey-three {
  border-right-color: #e5eef5 !important; }

.bg-before-pale-grey-three:before,
.bg-after-pale-grey-three:after {
  background: #e5eef5 !important; }

.border-before-pale-grey-three:before,
.border-after-pale-grey-three:after {
  border-color: #e5eef5 !important; }

.border-top-before-pale-grey-three:before,
.border-top-after-pale-grey-three:after {
  border-top-color: #e5eef5 !important; }

.border-bottom-before-pale-grey-three:before,
.border-bottom-after-pale-grey-three:after {
  border-bottom-color: #e5eef5 !important; }

.border-left-before-pale-grey-three:before,
.border-left-after-pale-grey-three:after {
  border-left-color: #e5eef5 !important; }

.border-right-before-pale-grey-three:before,
.border-right-after-pale-grey-three:after {
  border-right-color: #e5eef5 !important; }

.bg-light-blue-grey-three {
  background: #c5d9e8 !important;
  transition: background 150ms ease-in-out; }

.bg-light-blue-grey-three-opacity {
  background: rgba(197, 217, 232, 0.8) !important; }

.bg-hover-light-blue-grey-three:hover {
  background: #c5d9e8 !important; }

.fg-light-blue-grey-three {
  color: #c5d9e8 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-light-blue-grey-three:hover {
  color: #c5d9e8 !important; }

.bg-font-light-blue-grey-three {
  color: #263238 !important; }

.border-light-blue-grey-three {
  border-color: #c5d9e8 !important; }

.border-top-light-blue-grey-three {
  border-top-color: #c5d9e8 !important; }

.border-bottom-light-blue-grey-three {
  border-bottom-color: #c5d9e8 !important; }

.border-left-light-blue-grey-three {
  border-left-color: #c5d9e8 !important; }

.border-right-light-blue-grey-three {
  border-right-color: #c5d9e8 !important; }

.bg-before-light-blue-grey-three:before,
.bg-after-light-blue-grey-three:after {
  background: #c5d9e8 !important; }

.border-before-light-blue-grey-three:before,
.border-after-light-blue-grey-three:after {
  border-color: #c5d9e8 !important; }

.border-top-before-light-blue-grey-three:before,
.border-top-after-light-blue-grey-three:after {
  border-top-color: #c5d9e8 !important; }

.border-bottom-before-light-blue-grey-three:before,
.border-bottom-after-light-blue-grey-three:after {
  border-bottom-color: #c5d9e8 !important; }

.border-left-before-light-blue-grey-three:before,
.border-left-after-light-blue-grey-three:after {
  border-left-color: #c5d9e8 !important; }

.border-right-before-light-blue-grey-three:before,
.border-right-after-light-blue-grey-three:after {
  border-right-color: #c5d9e8 !important; }

.bg-charcoal-grey-60 {
  background: rgba(55, 71, 79, 0.6) !important;
  transition: background 150ms ease-in-out; }

.bg-charcoal-grey-60-opacity {
  background: rgba(55, 71, 79, 0.8) !important; }

.bg-hover-charcoal-grey-60:hover {
  background: rgba(55, 71, 79, 0.6) !important; }

.fg-charcoal-grey-60 {
  color: rgba(55, 71, 79, 0.6) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-charcoal-grey-60:hover {
  color: rgba(55, 71, 79, 0.6) !important; }

.bg-font-charcoal-grey-60 {
  color: #fff !important; }

.border-charcoal-grey-60 {
  border-color: rgba(55, 71, 79, 0.6) !important; }

.border-top-charcoal-grey-60 {
  border-top-color: rgba(55, 71, 79, 0.6) !important; }

.border-bottom-charcoal-grey-60 {
  border-bottom-color: rgba(55, 71, 79, 0.6) !important; }

.border-left-charcoal-grey-60 {
  border-left-color: rgba(55, 71, 79, 0.6) !important; }

.border-right-charcoal-grey-60 {
  border-right-color: rgba(55, 71, 79, 0.6) !important; }

.bg-before-charcoal-grey-60:before,
.bg-after-charcoal-grey-60:after {
  background: rgba(55, 71, 79, 0.6) !important; }

.border-before-charcoal-grey-60:before,
.border-after-charcoal-grey-60:after {
  border-color: rgba(55, 71, 79, 0.6) !important; }

.border-top-before-charcoal-grey-60:before,
.border-top-after-charcoal-grey-60:after {
  border-top-color: rgba(55, 71, 79, 0.6) !important; }

.border-bottom-before-charcoal-grey-60:before,
.border-bottom-after-charcoal-grey-60:after {
  border-bottom-color: rgba(55, 71, 79, 0.6) !important; }

.border-left-before-charcoal-grey-60:before,
.border-left-after-charcoal-grey-60:after {
  border-left-color: rgba(55, 71, 79, 0.6) !important; }

.border-right-before-charcoal-grey-60:before,
.border-right-after-charcoal-grey-60:after {
  border-right-color: rgba(55, 71, 79, 0.6) !important; }

.bg-charcoal-grey-80 {
  background: rgba(55, 71, 79, 0.8) !important;
  transition: background 150ms ease-in-out; }

.bg-charcoal-grey-80-opacity {
  background: rgba(55, 71, 79, 0.8) !important; }

.bg-hover-charcoal-grey-80:hover {
  background: rgba(55, 71, 79, 0.8) !important; }

.fg-charcoal-grey-80 {
  color: rgba(55, 71, 79, 0.8) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-charcoal-grey-80:hover {
  color: rgba(55, 71, 79, 0.8) !important; }

.bg-font-charcoal-grey-80 {
  color: #fff !important; }

.border-charcoal-grey-80 {
  border-color: rgba(55, 71, 79, 0.8) !important; }

.border-top-charcoal-grey-80 {
  border-top-color: rgba(55, 71, 79, 0.8) !important; }

.border-bottom-charcoal-grey-80 {
  border-bottom-color: rgba(55, 71, 79, 0.8) !important; }

.border-left-charcoal-grey-80 {
  border-left-color: rgba(55, 71, 79, 0.8) !important; }

.border-right-charcoal-grey-80 {
  border-right-color: rgba(55, 71, 79, 0.8) !important; }

.bg-before-charcoal-grey-80:before,
.bg-after-charcoal-grey-80:after {
  background: rgba(55, 71, 79, 0.8) !important; }

.border-before-charcoal-grey-80:before,
.border-after-charcoal-grey-80:after {
  border-color: rgba(55, 71, 79, 0.8) !important; }

.border-top-before-charcoal-grey-80:before,
.border-top-after-charcoal-grey-80:after {
  border-top-color: rgba(55, 71, 79, 0.8) !important; }

.border-bottom-before-charcoal-grey-80:before,
.border-bottom-after-charcoal-grey-80:after {
  border-bottom-color: rgba(55, 71, 79, 0.8) !important; }

.border-left-before-charcoal-grey-80:before,
.border-left-after-charcoal-grey-80:after {
  border-left-color: rgba(55, 71, 79, 0.8) !important; }

.border-right-before-charcoal-grey-80:before,
.border-right-after-charcoal-grey-80:after {
  border-right-color: rgba(55, 71, 79, 0.8) !important; }

.bg-azure {
  background: #0093ee !important;
  transition: background 150ms ease-in-out; }

.bg-azure-opacity {
  background: rgba(0, 147, 238, 0.8) !important; }

.bg-hover-azure:hover {
  background: #0093ee !important; }

.fg-azure {
  color: #0093ee !important;
  transition: color 150ms ease-in-out; }

.fg-hover-azure:hover {
  color: #0093ee !important; }

.bg-font-azure {
  color: #fff !important; }

.border-azure {
  border-color: #0093ee !important; }

.border-top-azure {
  border-top-color: #0093ee !important; }

.border-bottom-azure {
  border-bottom-color: #0093ee !important; }

.border-left-azure {
  border-left-color: #0093ee !important; }

.border-right-azure {
  border-right-color: #0093ee !important; }

.bg-before-azure:before,
.bg-after-azure:after {
  background: #0093ee !important; }

.border-before-azure:before,
.border-after-azure:after {
  border-color: #0093ee !important; }

.border-top-before-azure:before,
.border-top-after-azure:after {
  border-top-color: #0093ee !important; }

.border-bottom-before-azure:before,
.border-bottom-after-azure:after {
  border-bottom-color: #0093ee !important; }

.border-left-before-azure:before,
.border-left-after-azure:after {
  border-left-color: #0093ee !important; }

.border-right-before-azure:before,
.border-right-after-azure:after {
  border-right-color: #0093ee !important; }

.bg-dark-sky-blue {
  background: #1e88e5 !important;
  transition: background 150ms ease-in-out; }

.bg-dark-sky-blue-opacity {
  background: rgba(30, 136, 229, 0.8) !important; }

.bg-hover-dark-sky-blue:hover {
  background: #1e88e5 !important; }

.fg-dark-sky-blue {
  color: #1e88e5 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-dark-sky-blue:hover {
  color: #1e88e5 !important; }

.bg-font-dark-sky-blue {
  color: #fff !important; }

.border-dark-sky-blue {
  border-color: #1e88e5 !important; }

.border-top-dark-sky-blue {
  border-top-color: #1e88e5 !important; }

.border-bottom-dark-sky-blue {
  border-bottom-color: #1e88e5 !important; }

.border-left-dark-sky-blue {
  border-left-color: #1e88e5 !important; }

.border-right-dark-sky-blue {
  border-right-color: #1e88e5 !important; }

.bg-before-dark-sky-blue:before,
.bg-after-dark-sky-blue:after {
  background: #1e88e5 !important; }

.border-before-dark-sky-blue:before,
.border-after-dark-sky-blue:after {
  border-color: #1e88e5 !important; }

.border-top-before-dark-sky-blue:before,
.border-top-after-dark-sky-blue:after {
  border-top-color: #1e88e5 !important; }

.border-bottom-before-dark-sky-blue:before,
.border-bottom-after-dark-sky-blue:after {
  border-bottom-color: #1e88e5 !important; }

.border-left-before-dark-sky-blue:before,
.border-left-after-dark-sky-blue:after {
  border-left-color: #1e88e5 !important; }

.border-right-before-dark-sky-blue:before,
.border-right-after-dark-sky-blue:after {
  border-right-color: #1e88e5 !important; }

.bg-apple-green {
  background: #81c926 !important;
  transition: background 150ms ease-in-out; }

.bg-apple-green-opacity {
  background: rgba(129, 201, 38, 0.8) !important; }

.bg-hover-apple-green:hover {
  background: #81c926 !important; }

.fg-apple-green {
  color: #81c926 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-apple-green:hover {
  color: #81c926 !important; }

.bg-font-apple-green {
  color: #fff !important; }

.border-apple-green {
  border-color: #81c926 !important; }

.border-top-apple-green {
  border-top-color: #81c926 !important; }

.border-bottom-apple-green {
  border-bottom-color: #81c926 !important; }

.border-left-apple-green {
  border-left-color: #81c926 !important; }

.border-right-apple-green {
  border-right-color: #81c926 !important; }

.bg-before-apple-green:before,
.bg-after-apple-green:after {
  background: #81c926 !important; }

.border-before-apple-green:before,
.border-after-apple-green:after {
  border-color: #81c926 !important; }

.border-top-before-apple-green:before,
.border-top-after-apple-green:after {
  border-top-color: #81c926 !important; }

.border-bottom-before-apple-green:before,
.border-bottom-after-apple-green:after {
  border-bottom-color: #81c926 !important; }

.border-left-before-apple-green:before,
.border-left-after-apple-green:after {
  border-left-color: #81c926 !important; }

.border-right-before-apple-green:before,
.border-right-after-apple-green:after {
  border-right-color: #81c926 !important; }

.bg-pause-azure {
  background: #03a9f4 !important;
  transition: background 150ms ease-in-out; }

.bg-pause-azure-opacity {
  background: rgba(3, 169, 244, 0.8) !important; }

.bg-hover-pause-azure:hover {
  background: #03a9f4 !important; }

.fg-pause-azure {
  color: #03a9f4 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-pause-azure:hover {
  color: #03a9f4 !important; }

.bg-font-pause-azure {
  color: #fff !important; }

.border-pause-azure {
  border-color: #03a9f4 !important; }

.border-top-pause-azure {
  border-top-color: #03a9f4 !important; }

.border-bottom-pause-azure {
  border-bottom-color: #03a9f4 !important; }

.border-left-pause-azure {
  border-left-color: #03a9f4 !important; }

.border-right-pause-azure {
  border-right-color: #03a9f4 !important; }

.bg-before-pause-azure:before,
.bg-after-pause-azure:after {
  background: #03a9f4 !important; }

.border-before-pause-azure:before,
.border-after-pause-azure:after {
  border-color: #03a9f4 !important; }

.border-top-before-pause-azure:before,
.border-top-after-pause-azure:after {
  border-top-color: #03a9f4 !important; }

.border-bottom-before-pause-azure:before,
.border-bottom-after-pause-azure:after {
  border-bottom-color: #03a9f4 !important; }

.border-left-before-pause-azure:before,
.border-left-after-pause-azure:after {
  border-left-color: #03a9f4 !important; }

.border-right-before-pause-azure:before,
.border-right-after-pause-azure:after {
  border-right-color: #03a9f4 !important; }

.bg-production-blue {
  background: #2979ff !important;
  transition: background 150ms ease-in-out; }

.bg-production-blue-opacity {
  background: rgba(41, 121, 255, 0.8) !important; }

.bg-hover-production-blue:hover {
  background: #2979ff !important; }

.fg-production-blue {
  color: #2979ff !important;
  transition: color 150ms ease-in-out; }

.fg-hover-production-blue:hover {
  color: #2979ff !important; }

.bg-font-production-blue {
  color: #fff !important; }

.border-production-blue {
  border-color: #2979ff !important; }

.border-top-production-blue {
  border-top-color: #2979ff !important; }

.border-bottom-production-blue {
  border-bottom-color: #2979ff !important; }

.border-left-production-blue {
  border-left-color: #2979ff !important; }

.border-right-production-blue {
  border-right-color: #2979ff !important; }

.bg-before-production-blue:before,
.bg-after-production-blue:after {
  background: #2979ff !important; }

.border-before-production-blue:before,
.border-after-production-blue:after {
  border-color: #2979ff !important; }

.border-top-before-production-blue:before,
.border-top-after-production-blue:after {
  border-top-color: #2979ff !important; }

.border-bottom-before-production-blue:before,
.border-bottom-after-production-blue:after {
  border-bottom-color: #2979ff !important; }

.border-left-before-production-blue:before,
.border-left-after-production-blue:after {
  border-left-color: #2979ff !important; }

.border-right-before-production-blue:before,
.border-right-after-production-blue:after {
  border-right-color: #2979ff !important; }

.bg-production-blue-20 {
  background: rgba(41, 121, 255, 0.2) !important;
  transition: background 150ms ease-in-out; }

.bg-production-blue-20-opacity {
  background: rgba(41, 121, 255, 0.8) !important; }

.bg-hover-production-blue-20:hover {
  background: rgba(41, 121, 255, 0.2) !important; }

.fg-production-blue-20 {
  color: rgba(41, 121, 255, 0.2) !important;
  transition: color 150ms ease-in-out; }

.fg-hover-production-blue-20:hover {
  color: rgba(41, 121, 255, 0.2) !important; }

.bg-font-production-blue-20 {
  color: #1e88e5 !important; }

.border-production-blue-20 {
  border-color: rgba(41, 121, 255, 0.2) !important; }

.border-top-production-blue-20 {
  border-top-color: rgba(41, 121, 255, 0.2) !important; }

.border-bottom-production-blue-20 {
  border-bottom-color: rgba(41, 121, 255, 0.2) !important; }

.border-left-production-blue-20 {
  border-left-color: rgba(41, 121, 255, 0.2) !important; }

.border-right-production-blue-20 {
  border-right-color: rgba(41, 121, 255, 0.2) !important; }

.bg-before-production-blue-20:before,
.bg-after-production-blue-20:after {
  background: rgba(41, 121, 255, 0.2) !important; }

.border-before-production-blue-20:before,
.border-after-production-blue-20:after {
  border-color: rgba(41, 121, 255, 0.2) !important; }

.border-top-before-production-blue-20:before,
.border-top-after-production-blue-20:after {
  border-top-color: rgba(41, 121, 255, 0.2) !important; }

.border-bottom-before-production-blue-20:before,
.border-bottom-after-production-blue-20:after {
  border-bottom-color: rgba(41, 121, 255, 0.2) !important; }

.border-left-before-production-blue-20:before,
.border-left-after-production-blue-20:after {
  border-left-color: rgba(41, 121, 255, 0.2) !important; }

.border-right-before-production-blue-20:before,
.border-right-after-production-blue-20:after {
  border-right-color: rgba(41, 121, 255, 0.2) !important; }

.bg-material-violet {
  background: #aa00ff !important;
  transition: background 150ms ease-in-out; }

.bg-material-violet-opacity {
  background: rgba(170, 0, 255, 0.8) !important; }

.bg-hover-material-violet:hover {
  background: #aa00ff !important; }

.fg-material-violet {
  color: #aa00ff !important;
  transition: color 150ms ease-in-out; }

.fg-hover-material-violet:hover {
  color: #aa00ff !important; }

.bg-font-material-violet {
  color: #fff !important; }

.border-material-violet {
  border-color: #aa00ff !important; }

.border-top-material-violet {
  border-top-color: #aa00ff !important; }

.border-bottom-material-violet {
  border-bottom-color: #aa00ff !important; }

.border-left-material-violet {
  border-left-color: #aa00ff !important; }

.border-right-material-violet {
  border-right-color: #aa00ff !important; }

.bg-before-material-violet:before,
.bg-after-material-violet:after {
  background: #aa00ff !important; }

.border-before-material-violet:before,
.border-after-material-violet:after {
  border-color: #aa00ff !important; }

.border-top-before-material-violet:before,
.border-top-after-material-violet:after {
  border-top-color: #aa00ff !important; }

.border-bottom-before-material-violet:before,
.border-bottom-after-material-violet:after {
  border-bottom-color: #aa00ff !important; }

.border-left-before-material-violet:before,
.border-left-after-material-violet:after {
  border-left-color: #aa00ff !important; }

.border-right-before-material-violet:before,
.border-right-after-material-violet:after {
  border-right-color: #aa00ff !important; }

.bg-setup-yellow {
  background: #ffab00 !important;
  transition: background 150ms ease-in-out; }

.bg-setup-yellow-opacity {
  background: rgba(255, 171, 0, 0.8) !important; }

.bg-hover-setup-yellow:hover {
  background: #ffab00 !important; }

.fg-setup-yellow {
  color: #ffab00 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-setup-yellow:hover {
  color: #ffab00 !important; }

.bg-font-setup-yellow {
  color: #fff !important; }

.border-setup-yellow {
  border-color: #ffab00 !important; }

.border-top-setup-yellow {
  border-top-color: #ffab00 !important; }

.border-bottom-setup-yellow {
  border-bottom-color: #ffab00 !important; }

.border-left-setup-yellow {
  border-left-color: #ffab00 !important; }

.border-right-setup-yellow {
  border-right-color: #ffab00 !important; }

.bg-before-setup-yellow:before,
.bg-after-setup-yellow:after {
  background: #ffab00 !important; }

.border-before-setup-yellow:before,
.border-after-setup-yellow:after {
  border-color: #ffab00 !important; }

.border-top-before-setup-yellow:before,
.border-top-after-setup-yellow:after {
  border-top-color: #ffab00 !important; }

.border-bottom-before-setup-yellow:before,
.border-bottom-after-setup-yellow:after {
  border-bottom-color: #ffab00 !important; }

.border-left-before-setup-yellow:before,
.border-left-after-setup-yellow:after {
  border-left-color: #ffab00 !important; }

.border-right-before-setup-yellow:before,
.border-right-after-setup-yellow:after {
  border-right-color: #ffab00 !important; }

.bg-check-green {
  background: #00c853 !important;
  transition: background 150ms ease-in-out; }

.bg-check-green-opacity {
  background: rgba(0, 200, 83, 0.8) !important; }

.bg-hover-check-green:hover {
  background: #00c853 !important; }

.fg-check-green {
  color: #00c853 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-check-green:hover {
  color: #00c853 !important; }

.bg-font-check-green {
  color: #fff !important; }

.border-check-green {
  border-color: #00c853 !important; }

.border-top-check-green {
  border-top-color: #00c853 !important; }

.border-bottom-check-green {
  border-bottom-color: #00c853 !important; }

.border-left-check-green {
  border-left-color: #00c853 !important; }

.border-right-check-green {
  border-right-color: #00c853 !important; }

.bg-before-check-green:before,
.bg-after-check-green:after {
  background: #00c853 !important; }

.border-before-check-green:before,
.border-after-check-green:after {
  border-color: #00c853 !important; }

.border-top-before-check-green:before,
.border-top-after-check-green:after {
  border-top-color: #00c853 !important; }

.border-bottom-before-check-green:before,
.border-bottom-after-check-green:after {
  border-bottom-color: #00c853 !important; }

.border-left-before-check-green:before,
.border-left-after-check-green:after {
  border-left-color: #00c853 !important; }

.border-right-before-check-green:before,
.border-right-after-check-green:after {
  border-right-color: #00c853 !important; }

.bg-neutral-grey {
  background: #b0bec5 !important;
  transition: background 150ms ease-in-out; }

.bg-neutral-grey-opacity {
  background: rgba(176, 190, 197, 0.8) !important; }

.bg-hover-neutral-grey:hover {
  background: #b0bec5 !important; }

.fg-neutral-grey {
  color: #b0bec5 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-neutral-grey:hover {
  color: #b0bec5 !important; }

.bg-font-neutral-grey {
  color: #fff !important; }

.border-neutral-grey {
  border-color: #b0bec5 !important; }

.border-top-neutral-grey {
  border-top-color: #b0bec5 !important; }

.border-bottom-neutral-grey {
  border-bottom-color: #b0bec5 !important; }

.border-left-neutral-grey {
  border-left-color: #b0bec5 !important; }

.border-right-neutral-grey {
  border-right-color: #b0bec5 !important; }

.bg-before-neutral-grey:before,
.bg-after-neutral-grey:after {
  background: #b0bec5 !important; }

.border-before-neutral-grey:before,
.border-after-neutral-grey:after {
  border-color: #b0bec5 !important; }

.border-top-before-neutral-grey:before,
.border-top-after-neutral-grey:after {
  border-top-color: #b0bec5 !important; }

.border-bottom-before-neutral-grey:before,
.border-bottom-after-neutral-grey:after {
  border-bottom-color: #b0bec5 !important; }

.border-left-before-neutral-grey:before,
.border-left-after-neutral-grey:after {
  border-left-color: #b0bec5 !important; }

.border-right-before-neutral-grey:before,
.border-right-after-neutral-grey:after {
  border-right-color: #b0bec5 !important; }

.bg-done-grey {
  background: #ebeef0 !important;
  transition: background 150ms ease-in-out; }

.bg-done-grey-opacity {
  background: rgba(235, 238, 240, 0.8) !important; }

.bg-hover-done-grey:hover {
  background: #ebeef0 !important; }

.fg-done-grey {
  color: #ebeef0 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-done-grey:hover {
  color: #ebeef0 !important; }

.bg-font-done-grey {
  color: #a8c6df !important; }

.border-done-grey {
  border-color: #ebeef0 !important; }

.border-top-done-grey {
  border-top-color: #ebeef0 !important; }

.border-bottom-done-grey {
  border-bottom-color: #ebeef0 !important; }

.border-left-done-grey {
  border-left-color: #ebeef0 !important; }

.border-right-done-grey {
  border-right-color: #ebeef0 !important; }

.bg-before-done-grey:before,
.bg-after-done-grey:after {
  background: #ebeef0 !important; }

.border-before-done-grey:before,
.border-after-done-grey:after {
  border-color: #ebeef0 !important; }

.border-top-before-done-grey:before,
.border-top-after-done-grey:after {
  border-top-color: #ebeef0 !important; }

.border-bottom-before-done-grey:before,
.border-bottom-after-done-grey:after {
  border-bottom-color: #ebeef0 !important; }

.border-left-before-done-grey:before,
.border-left-after-done-grey:after {
  border-left-color: #ebeef0 !important; }

.border-right-before-done-grey:before,
.border-right-after-done-grey:after {
  border-right-color: #ebeef0 !important; }

.bg-stressed {
  background: #aa00ff !important;
  transition: background 150ms ease-in-out; }

.bg-stressed-opacity {
  background: rgba(170, 0, 255, 0.8) !important; }

.bg-hover-stressed:hover {
  background: #aa00ff !important; }

.fg-stressed {
  color: #aa00ff !important;
  transition: color 150ms ease-in-out; }

.fg-hover-stressed:hover {
  color: #aa00ff !important; }

.bg-font-stressed {
  color: #fff !important; }

.border-stressed {
  border-color: #aa00ff !important; }

.border-top-stressed {
  border-top-color: #aa00ff !important; }

.border-bottom-stressed {
  border-bottom-color: #aa00ff !important; }

.border-left-stressed {
  border-left-color: #aa00ff !important; }

.border-right-stressed {
  border-right-color: #aa00ff !important; }

.bg-before-stressed:before,
.bg-after-stressed:after {
  background: #aa00ff !important; }

.border-before-stressed:before,
.border-after-stressed:after {
  border-color: #aa00ff !important; }

.border-top-before-stressed:before,
.border-top-after-stressed:after {
  border-top-color: #aa00ff !important; }

.border-bottom-before-stressed:before,
.border-bottom-after-stressed:after {
  border-bottom-color: #aa00ff !important; }

.border-left-before-stressed:before,
.border-left-after-stressed:after {
  border-left-color: #aa00ff !important; }

.border-right-before-stressed:before,
.border-right-after-stressed:after {
  border-right-color: #aa00ff !important; }

.bg-error {
  background: #ff1744 !important;
  transition: background 150ms ease-in-out; }

.bg-error-opacity {
  background: rgba(255, 23, 68, 0.8) !important; }

.bg-hover-error:hover {
  background: #ff1744 !important; }

.fg-error {
  color: #ff1744 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-error:hover {
  color: #ff1744 !important; }

.bg-font-error {
  color: #fff !important; }

.border-error {
  border-color: #ff1744 !important; }

.border-top-error {
  border-top-color: #ff1744 !important; }

.border-bottom-error {
  border-bottom-color: #ff1744 !important; }

.border-left-error {
  border-left-color: #ff1744 !important; }

.border-right-error {
  border-right-color: #ff1744 !important; }

.bg-before-error:before,
.bg-after-error:after {
  background: #ff1744 !important; }

.border-before-error:before,
.border-after-error:after {
  border-color: #ff1744 !important; }

.border-top-before-error:before,
.border-top-after-error:after {
  border-top-color: #ff1744 !important; }

.border-bottom-before-error:before,
.border-bottom-after-error:after {
  border-bottom-color: #ff1744 !important; }

.border-left-before-error:before,
.border-left-after-error:after {
  border-left-color: #ff1744 !important; }

.border-right-before-error:before,
.border-right-after-error:after {
  border-right-color: #ff1744 !important; }

.bg-production {
  background: #2979ff !important;
  transition: background 150ms ease-in-out; }

.bg-production-opacity {
  background: rgba(41, 121, 255, 0.8) !important; }

.bg-hover-production:hover {
  background: #2979ff !important; }

.fg-production {
  color: #2979ff !important;
  transition: color 150ms ease-in-out; }

.fg-hover-production:hover {
  color: #2979ff !important; }

.bg-font-production {
  color: #fff !important; }

.border-production {
  border-color: #2979ff !important; }

.border-top-production {
  border-top-color: #2979ff !important; }

.border-bottom-production {
  border-bottom-color: #2979ff !important; }

.border-left-production {
  border-left-color: #2979ff !important; }

.border-right-production {
  border-right-color: #2979ff !important; }

.bg-before-production:before,
.bg-after-production:after {
  background: #2979ff !important; }

.border-before-production:before,
.border-after-production:after {
  border-color: #2979ff !important; }

.border-top-before-production:before,
.border-top-after-production:after {
  border-top-color: #2979ff !important; }

.border-bottom-before-production:before,
.border-bottom-after-production:after {
  border-bottom-color: #2979ff !important; }

.border-left-before-production:before,
.border-left-after-production:after {
  border-left-color: #2979ff !important; }

.border-right-before-production:before,
.border-right-after-production:after {
  border-right-color: #2979ff !important; }

.bg-interrupted {
  background: #b0bec5 !important;
  transition: background 150ms ease-in-out; }

.bg-interrupted-opacity {
  background: rgba(176, 190, 197, 0.8) !important; }

.bg-hover-interrupted:hover {
  background: #b0bec5 !important; }

.fg-interrupted {
  color: #b0bec5 !important;
  transition: color 150ms ease-in-out; }

.fg-hover-interrupted:hover {
  color: #b0bec5 !important; }

.bg-font-interrupted {
  color: #fff !important; }

.border-interrupted {
  border-color: #b0bec5 !important; }

.border-top-interrupted {
  border-top-color: #b0bec5 !important; }

.border-bottom-interrupted {
  border-bottom-color: #b0bec5 !important; }

.border-left-interrupted {
  border-left-color: #b0bec5 !important; }

.border-right-interrupted {
  border-right-color: #b0bec5 !important; }

.bg-before-interrupted:before,
.bg-after-interrupted:after {
  background: #b0bec5 !important; }

.border-before-interrupted:before,
.border-after-interrupted:after {
  border-color: #b0bec5 !important; }

.border-top-before-interrupted:before,
.border-top-after-interrupted:after {
  border-top-color: #b0bec5 !important; }

.border-bottom-before-interrupted:before,
.border-bottom-after-interrupted:after {
  border-bottom-color: #b0bec5 !important; }

.border-left-before-interrupted:before,
.border-left-after-interrupted:after {
  border-left-color: #b0bec5 !important; }

.border-right-before-interrupted:before,
.border-right-after-interrupted:after {
  border-right-color: #b0bec5 !important; }

.button.is-transparent:not(.is-outlined) {
  color: #263238;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0); }
  .button.is-transparent:not(.is-outlined):focus, .button.is-transparent:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(0, 0, 0, 0.85);
    border-color: rgba(0, 0, 0, 0.85); }
  .button.is-transparent:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(0, 0, 0, 0.85);
    border-color: rgba(0, 0, 0, 0.85); }
  .button.is-transparent:not(.is-outlined):active, .button.is-transparent:not(.is-outlined).active,
  .open > .button.is-transparent:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(0, 0, 0, 0.85);
    border-color: rgba(0, 0, 0, 0.85); }
    .button.is-transparent:not(.is-outlined):active:hover, .button.is-transparent:not(.is-outlined):active:focus, .button.is-transparent:not(.is-outlined):active.focus, .button.is-transparent:not(.is-outlined).active:hover, .button.is-transparent:not(.is-outlined).active:focus, .button.is-transparent:not(.is-outlined).active.focus,
    .open > .button.is-transparent:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-transparent:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-transparent:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(0, 0, 0, 0.75);
      border-color: rgba(0, 0, 0, 0.75); }
  .button.is-transparent:not(.is-outlined):active, .button.is-transparent:not(.is-outlined).active,
  .open > .button.is-transparent:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-transparent:not(.is-outlined).disabled:hover, .button.is-transparent:not(.is-outlined).disabled:focus, .button.is-transparent:not(.is-outlined).disabled.focus, .button.is-transparent:not(.is-outlined)[disabled]:hover, .button.is-transparent:not(.is-outlined)[disabled]:focus, .button.is-transparent:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-transparent:not(.is-outlined):hover,
  fieldset[disabled] .button.is-transparent:not(.is-outlined):focus,
  fieldset[disabled] .button.is-transparent:not(.is-outlined).focus {
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
  .button.is-transparent:not(.is-outlined) .badge {
    color: rgba(0, 0, 0, 0);
    background-color: #263238; }

.button.is-inverted.is-transparent:not(.is-outlined) {
  color: rgba(0, 0, 0, 0);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-transparent:not(.is-outlined):focus, .button.is-inverted.is-transparent:not(.is-outlined).focus {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-transparent:not(.is-outlined):hover {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-transparent:not(.is-outlined):active, .button.is-inverted.is-transparent:not(.is-outlined).active,
  .open > .button.is-inverted.is-transparent:not(.is-outlined).dropdown-toggle {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-transparent:not(.is-outlined):active:hover, .button.is-inverted.is-transparent:not(.is-outlined):active:focus, .button.is-inverted.is-transparent:not(.is-outlined):active.focus, .button.is-inverted.is-transparent:not(.is-outlined).active:hover, .button.is-inverted.is-transparent:not(.is-outlined).active:focus, .button.is-inverted.is-transparent:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-transparent:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-transparent:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-transparent:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(0, 0, 0, 0);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-transparent:not(.is-outlined):active, .button.is-inverted.is-transparent:not(.is-outlined).active,
  .open > .button.is-inverted.is-transparent:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-transparent:not(.is-outlined).disabled:hover, .button.is-inverted.is-transparent:not(.is-outlined).disabled:focus, .button.is-inverted.is-transparent:not(.is-outlined).disabled.focus, .button.is-inverted.is-transparent:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-transparent:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-transparent:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-transparent:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-transparent:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-transparent:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-transparent:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(0, 0, 0, 0); }

.button.is-outlined.is-transparent {
  border-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0);
  background: none; }
  .button.is-outlined.is-transparent:hover, .button.is-outlined.is-transparent:active, .button.is-outlined.is-transparent:active:hover, .button.is-outlined.is-transparent:active:focus, .button.is-outlined.is-transparent:focus, .button.is-outlined.is-transparent.active {
    border-color: rgba(0, 0, 0, 0);
    color: #263238;
    background-color: rgba(0, 0, 0, 0); }

.button.is-transparent.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-default:not(.is-outlined) {
  color: #fff;
  background-color: #263238;
  border-color: #263238; }
  .button.is-default:not(.is-outlined):focus, .button.is-default:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-default:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-default:not(.is-outlined):active, .button.is-default:not(.is-outlined).active,
  .open > .button.is-default:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-default:not(.is-outlined):active:hover, .button.is-default:not(.is-outlined):active:focus, .button.is-default:not(.is-outlined):active.focus, .button.is-default:not(.is-outlined).active:hover, .button.is-default:not(.is-outlined).active:focus, .button.is-default:not(.is-outlined).active.focus,
    .open > .button.is-default:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-default:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-default:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-default:not(.is-outlined):active, .button.is-default:not(.is-outlined).active,
  .open > .button.is-default:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-default:not(.is-outlined).disabled:hover, .button.is-default:not(.is-outlined).disabled:focus, .button.is-default:not(.is-outlined).disabled.focus, .button.is-default:not(.is-outlined)[disabled]:hover, .button.is-default:not(.is-outlined)[disabled]:focus, .button.is-default:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-default:not(.is-outlined):hover,
  fieldset[disabled] .button.is-default:not(.is-outlined):focus,
  fieldset[disabled] .button.is-default:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-default:not(.is-outlined) .badge {
    color: #263238;
    background-color: #fff; }

.button.is-inverted.is-default:not(.is-outlined) {
  color: #263238;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-default:not(.is-outlined):focus, .button.is-inverted.is-default:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-default:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-default:not(.is-outlined):active, .button.is-inverted.is-default:not(.is-outlined).active,
  .open > .button.is-inverted.is-default:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-default:not(.is-outlined):active:hover, .button.is-inverted.is-default:not(.is-outlined):active:focus, .button.is-inverted.is-default:not(.is-outlined):active.focus, .button.is-inverted.is-default:not(.is-outlined).active:hover, .button.is-inverted.is-default:not(.is-outlined).active:focus, .button.is-inverted.is-default:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-default:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-default:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-default:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-default:not(.is-outlined):active, .button.is-inverted.is-default:not(.is-outlined).active,
  .open > .button.is-inverted.is-default:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-default:not(.is-outlined).disabled:hover, .button.is-inverted.is-default:not(.is-outlined).disabled:focus, .button.is-inverted.is-default:not(.is-outlined).disabled.focus, .button.is-inverted.is-default:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-default:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-default:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-default:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-default:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-default:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-default:not(.is-outlined) .badge {
    color: #fff;
    background-color: #263238; }

.button.is-outlined.is-default {
  border-color: #263238;
  color: #263238;
  background: none; }
  .button.is-outlined.is-default:hover, .button.is-outlined.is-default:active, .button.is-outlined.is-default:active:hover, .button.is-outlined.is-default:active:focus, .button.is-outlined.is-default:focus, .button.is-outlined.is-default.active {
    border-color: #263238;
    color: #fff;
    background-color: #263238; }

.button.is-default.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-white:not(.is-outlined) {
  color: #263238;
  background-color: #fff;
  border-color: #fff; }
  .button.is-white:not(.is-outlined):focus, .button.is-white:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-white:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-white:not(.is-outlined):active, .button.is-white:not(.is-outlined).active,
  .open > .button.is-white:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-white:not(.is-outlined):active:hover, .button.is-white:not(.is-outlined):active:focus, .button.is-white:not(.is-outlined):active.focus, .button.is-white:not(.is-outlined).active:hover, .button.is-white:not(.is-outlined).active:focus, .button.is-white:not(.is-outlined).active.focus,
    .open > .button.is-white:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-white:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-white:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-white:not(.is-outlined):active, .button.is-white:not(.is-outlined).active,
  .open > .button.is-white:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-white:not(.is-outlined).disabled:hover, .button.is-white:not(.is-outlined).disabled:focus, .button.is-white:not(.is-outlined).disabled.focus, .button.is-white:not(.is-outlined)[disabled]:hover, .button.is-white:not(.is-outlined)[disabled]:focus, .button.is-white:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-white:not(.is-outlined):hover,
  fieldset[disabled] .button.is-white:not(.is-outlined):focus,
  fieldset[disabled] .button.is-white:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-white:not(.is-outlined) .badge {
    color: #fff;
    background-color: #263238; }

.button.is-inverted.is-white:not(.is-outlined) {
  color: #fff;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-white:not(.is-outlined):focus, .button.is-inverted.is-white:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-white:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-white:not(.is-outlined):active, .button.is-inverted.is-white:not(.is-outlined).active,
  .open > .button.is-inverted.is-white:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-white:not(.is-outlined):active:hover, .button.is-inverted.is-white:not(.is-outlined):active:focus, .button.is-inverted.is-white:not(.is-outlined):active.focus, .button.is-inverted.is-white:not(.is-outlined).active:hover, .button.is-inverted.is-white:not(.is-outlined).active:focus, .button.is-inverted.is-white:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-white:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-white:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-white:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-white:not(.is-outlined):active, .button.is-inverted.is-white:not(.is-outlined).active,
  .open > .button.is-inverted.is-white:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-white:not(.is-outlined).disabled:hover, .button.is-inverted.is-white:not(.is-outlined).disabled:focus, .button.is-inverted.is-white:not(.is-outlined).disabled.focus, .button.is-inverted.is-white:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-white:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-white:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-white:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-white:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-white:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-white:not(.is-outlined) .badge {
    color: #263238;
    background-color: #fff; }

.button.is-outlined.is-white {
  border-color: #fff;
  color: #fff;
  background: none; }
  .button.is-outlined.is-white:hover, .button.is-outlined.is-white:active, .button.is-outlined.is-white:active:hover, .button.is-outlined.is-white:active:focus, .button.is-outlined.is-white:focus, .button.is-outlined.is-white.active {
    border-color: #fff;
    color: #263238;
    background-color: #fff; }

.button.is-white.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-black:not(.is-outlined) {
  color: #fff;
  background-color: #263238;
  border-color: #263238; }
  .button.is-black:not(.is-outlined):focus, .button.is-black:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-black:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-black:not(.is-outlined):active, .button.is-black:not(.is-outlined).active,
  .open > .button.is-black:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-black:not(.is-outlined):active:hover, .button.is-black:not(.is-outlined):active:focus, .button.is-black:not(.is-outlined):active.focus, .button.is-black:not(.is-outlined).active:hover, .button.is-black:not(.is-outlined).active:focus, .button.is-black:not(.is-outlined).active.focus,
    .open > .button.is-black:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-black:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-black:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-black:not(.is-outlined):active, .button.is-black:not(.is-outlined).active,
  .open > .button.is-black:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-black:not(.is-outlined).disabled:hover, .button.is-black:not(.is-outlined).disabled:focus, .button.is-black:not(.is-outlined).disabled.focus, .button.is-black:not(.is-outlined)[disabled]:hover, .button.is-black:not(.is-outlined)[disabled]:focus, .button.is-black:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-black:not(.is-outlined):hover,
  fieldset[disabled] .button.is-black:not(.is-outlined):focus,
  fieldset[disabled] .button.is-black:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-black:not(.is-outlined) .badge {
    color: #263238;
    background-color: #fff; }

.button.is-inverted.is-black:not(.is-outlined) {
  color: #263238;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-black:not(.is-outlined):focus, .button.is-inverted.is-black:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-black:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-black:not(.is-outlined):active, .button.is-inverted.is-black:not(.is-outlined).active,
  .open > .button.is-inverted.is-black:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-black:not(.is-outlined):active:hover, .button.is-inverted.is-black:not(.is-outlined):active:focus, .button.is-inverted.is-black:not(.is-outlined):active.focus, .button.is-inverted.is-black:not(.is-outlined).active:hover, .button.is-inverted.is-black:not(.is-outlined).active:focus, .button.is-inverted.is-black:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-black:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-black:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-black:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-black:not(.is-outlined):active, .button.is-inverted.is-black:not(.is-outlined).active,
  .open > .button.is-inverted.is-black:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-black:not(.is-outlined).disabled:hover, .button.is-inverted.is-black:not(.is-outlined).disabled:focus, .button.is-inverted.is-black:not(.is-outlined).disabled.focus, .button.is-inverted.is-black:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-black:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-black:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-black:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-black:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-black:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-black:not(.is-outlined) .badge {
    color: #fff;
    background-color: #263238; }

.button.is-outlined.is-black {
  border-color: #263238;
  color: #263238;
  background: none; }
  .button.is-outlined.is-black:hover, .button.is-outlined.is-black:active, .button.is-outlined.is-black:active:hover, .button.is-outlined.is-black:active:focus, .button.is-outlined.is-black:focus, .button.is-outlined.is-black.active {
    border-color: #263238;
    color: #fff;
    background-color: #263238; }

.button.is-black.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark:not(.is-outlined) {
  color: #fff;
  background-color: #263238;
  border-color: #263238; }
  .button.is-dark:not(.is-outlined):focus, .button.is-dark:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-dark:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-dark:not(.is-outlined):active, .button.is-dark:not(.is-outlined).active,
  .open > .button.is-dark:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-dark:not(.is-outlined):active:hover, .button.is-dark:not(.is-outlined):active:focus, .button.is-dark:not(.is-outlined):active.focus, .button.is-dark:not(.is-outlined).active:hover, .button.is-dark:not(.is-outlined).active:focus, .button.is-dark:not(.is-outlined).active.focus,
    .open > .button.is-dark:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-dark:not(.is-outlined):active, .button.is-dark:not(.is-outlined).active,
  .open > .button.is-dark:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark:not(.is-outlined).disabled:hover, .button.is-dark:not(.is-outlined).disabled:focus, .button.is-dark:not(.is-outlined).disabled.focus, .button.is-dark:not(.is-outlined)[disabled]:hover, .button.is-dark:not(.is-outlined)[disabled]:focus, .button.is-dark:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-dark:not(.is-outlined) .badge {
    color: #263238;
    background-color: #fff; }

.button.is-inverted.is-dark:not(.is-outlined) {
  color: #263238;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-dark:not(.is-outlined):focus, .button.is-inverted.is-dark:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-dark:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-dark:not(.is-outlined):active, .button.is-inverted.is-dark:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-dark:not(.is-outlined):active:hover, .button.is-inverted.is-dark:not(.is-outlined):active:focus, .button.is-inverted.is-dark:not(.is-outlined):active.focus, .button.is-inverted.is-dark:not(.is-outlined).active:hover, .button.is-inverted.is-dark:not(.is-outlined).active:focus, .button.is-inverted.is-dark:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-dark:not(.is-outlined):active, .button.is-inverted.is-dark:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-dark:not(.is-outlined) .badge {
    color: #fff;
    background-color: #263238; }

.button.is-outlined.is-dark {
  border-color: #263238;
  color: #263238;
  background: none; }
  .button.is-outlined.is-dark:hover, .button.is-outlined.is-dark:active, .button.is-outlined.is-dark:active:hover, .button.is-outlined.is-dark:active:focus, .button.is-outlined.is-dark:focus, .button.is-outlined.is-dark.active {
    border-color: #263238;
    color: #fff;
    background-color: #263238; }

.button.is-dark.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-slate:not(.is-outlined) {
  color: #fff;
  background-color: #455a64;
  border-color: #455a64; }
  .button.is-slate:not(.is-outlined):focus, .button.is-slate:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(69, 90, 100, 0.85);
    border-color: rgba(69, 90, 100, 0.85); }
  .button.is-slate:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(69, 90, 100, 0.85);
    border-color: rgba(69, 90, 100, 0.85); }
  .button.is-slate:not(.is-outlined):active, .button.is-slate:not(.is-outlined).active,
  .open > .button.is-slate:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(69, 90, 100, 0.85);
    border-color: rgba(69, 90, 100, 0.85); }
    .button.is-slate:not(.is-outlined):active:hover, .button.is-slate:not(.is-outlined):active:focus, .button.is-slate:not(.is-outlined):active.focus, .button.is-slate:not(.is-outlined).active:hover, .button.is-slate:not(.is-outlined).active:focus, .button.is-slate:not(.is-outlined).active.focus,
    .open > .button.is-slate:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-slate:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-slate:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(69, 90, 100, 0.75);
      border-color: rgba(69, 90, 100, 0.75); }
  .button.is-slate:not(.is-outlined):active, .button.is-slate:not(.is-outlined).active,
  .open > .button.is-slate:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-slate:not(.is-outlined).disabled:hover, .button.is-slate:not(.is-outlined).disabled:focus, .button.is-slate:not(.is-outlined).disabled.focus, .button.is-slate:not(.is-outlined)[disabled]:hover, .button.is-slate:not(.is-outlined)[disabled]:focus, .button.is-slate:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-slate:not(.is-outlined):hover,
  fieldset[disabled] .button.is-slate:not(.is-outlined):focus,
  fieldset[disabled] .button.is-slate:not(.is-outlined).focus {
    background-color: #455a64;
    border-color: #455a64; }
  .button.is-slate:not(.is-outlined) .badge {
    color: #455a64;
    background-color: #fff; }

.button.is-inverted.is-slate:not(.is-outlined) {
  color: #455a64;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-slate:not(.is-outlined):focus, .button.is-inverted.is-slate:not(.is-outlined).focus {
    color: #455a64;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-slate:not(.is-outlined):hover {
    color: #455a64;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-slate:not(.is-outlined):active, .button.is-inverted.is-slate:not(.is-outlined).active,
  .open > .button.is-inverted.is-slate:not(.is-outlined).dropdown-toggle {
    color: #455a64;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-slate:not(.is-outlined):active:hover, .button.is-inverted.is-slate:not(.is-outlined):active:focus, .button.is-inverted.is-slate:not(.is-outlined):active.focus, .button.is-inverted.is-slate:not(.is-outlined).active:hover, .button.is-inverted.is-slate:not(.is-outlined).active:focus, .button.is-inverted.is-slate:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-slate:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-slate:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-slate:not(.is-outlined).dropdown-toggle.focus {
      color: #455a64;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-slate:not(.is-outlined):active, .button.is-inverted.is-slate:not(.is-outlined).active,
  .open > .button.is-inverted.is-slate:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-slate:not(.is-outlined).disabled:hover, .button.is-inverted.is-slate:not(.is-outlined).disabled:focus, .button.is-inverted.is-slate:not(.is-outlined).disabled.focus, .button.is-inverted.is-slate:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-slate:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-slate:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-slate:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-slate:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-slate:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-slate:not(.is-outlined) .badge {
    color: #fff;
    background-color: #455a64; }

.button.is-outlined.is-slate {
  border-color: #455a64;
  color: #455a64;
  background: none; }
  .button.is-outlined.is-slate:hover, .button.is-outlined.is-slate:active, .button.is-outlined.is-slate:active:hover, .button.is-outlined.is-slate:active:focus, .button.is-outlined.is-slate:focus, .button.is-outlined.is-slate.active {
    border-color: #455a64;
    color: #fff;
    background-color: #455a64; }

.button.is-slate.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-body-grey:not(.is-outlined) {
  color: #263238;
  background-color: #f2f6f9;
  border-color: #f2f6f9; }
  .button.is-body-grey:not(.is-outlined):focus, .button.is-body-grey:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(242, 246, 249, 0.85);
    border-color: rgba(242, 246, 249, 0.85); }
  .button.is-body-grey:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(242, 246, 249, 0.85);
    border-color: rgba(242, 246, 249, 0.85); }
  .button.is-body-grey:not(.is-outlined):active, .button.is-body-grey:not(.is-outlined).active,
  .open > .button.is-body-grey:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(242, 246, 249, 0.85);
    border-color: rgba(242, 246, 249, 0.85); }
    .button.is-body-grey:not(.is-outlined):active:hover, .button.is-body-grey:not(.is-outlined):active:focus, .button.is-body-grey:not(.is-outlined):active.focus, .button.is-body-grey:not(.is-outlined).active:hover, .button.is-body-grey:not(.is-outlined).active:focus, .button.is-body-grey:not(.is-outlined).active.focus,
    .open > .button.is-body-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-body-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-body-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(242, 246, 249, 0.75);
      border-color: rgba(242, 246, 249, 0.75); }
  .button.is-body-grey:not(.is-outlined):active, .button.is-body-grey:not(.is-outlined).active,
  .open > .button.is-body-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-body-grey:not(.is-outlined).disabled:hover, .button.is-body-grey:not(.is-outlined).disabled:focus, .button.is-body-grey:not(.is-outlined).disabled.focus, .button.is-body-grey:not(.is-outlined)[disabled]:hover, .button.is-body-grey:not(.is-outlined)[disabled]:focus, .button.is-body-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-body-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-body-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-body-grey:not(.is-outlined).focus {
    background-color: #f2f6f9;
    border-color: #f2f6f9; }
  .button.is-body-grey:not(.is-outlined) .badge {
    color: #f2f6f9;
    background-color: #263238; }

.button.is-inverted.is-body-grey:not(.is-outlined) {
  color: #f2f6f9;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-body-grey:not(.is-outlined):focus, .button.is-inverted.is-body-grey:not(.is-outlined).focus {
    color: #f2f6f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-body-grey:not(.is-outlined):hover {
    color: #f2f6f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-body-grey:not(.is-outlined):active, .button.is-inverted.is-body-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-body-grey:not(.is-outlined).dropdown-toggle {
    color: #f2f6f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-body-grey:not(.is-outlined):active:hover, .button.is-inverted.is-body-grey:not(.is-outlined):active:focus, .button.is-inverted.is-body-grey:not(.is-outlined):active.focus, .button.is-inverted.is-body-grey:not(.is-outlined).active:hover, .button.is-inverted.is-body-grey:not(.is-outlined).active:focus, .button.is-inverted.is-body-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-body-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-body-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-body-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #f2f6f9;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-body-grey:not(.is-outlined):active, .button.is-inverted.is-body-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-body-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-body-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-body-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-body-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-body-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-body-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-body-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-body-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-body-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-body-grey:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-body-grey:not(.is-outlined) .badge {
    color: #263238;
    background-color: #f2f6f9; }

.button.is-outlined.is-body-grey {
  border-color: #f2f6f9;
  color: #f2f6f9;
  background: none; }
  .button.is-outlined.is-body-grey:hover, .button.is-outlined.is-body-grey:active, .button.is-outlined.is-body-grey:active:hover, .button.is-outlined.is-body-grey:active:focus, .button.is-outlined.is-body-grey:focus, .button.is-outlined.is-body-grey.active {
    border-color: #f2f6f9;
    color: #263238;
    background-color: #f2f6f9; }

.button.is-body-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-blue-grey:not(.is-outlined) {
  color: #263238;
  background-color: #a8c6df;
  border-color: #a8c6df; }
  .button.is-light-blue-grey:not(.is-outlined):focus, .button.is-light-blue-grey:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-light-blue-grey:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-light-blue-grey:not(.is-outlined):active, .button.is-light-blue-grey:not(.is-outlined).active,
  .open > .button.is-light-blue-grey:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
    .button.is-light-blue-grey:not(.is-outlined):active:hover, .button.is-light-blue-grey:not(.is-outlined):active:focus, .button.is-light-blue-grey:not(.is-outlined):active.focus, .button.is-light-blue-grey:not(.is-outlined).active:hover, .button.is-light-blue-grey:not(.is-outlined).active:focus, .button.is-light-blue-grey:not(.is-outlined).active.focus,
    .open > .button.is-light-blue-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-blue-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-blue-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(168, 198, 223, 0.75);
      border-color: rgba(168, 198, 223, 0.75); }
  .button.is-light-blue-grey:not(.is-outlined):active, .button.is-light-blue-grey:not(.is-outlined).active,
  .open > .button.is-light-blue-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-blue-grey:not(.is-outlined).disabled:hover, .button.is-light-blue-grey:not(.is-outlined).disabled:focus, .button.is-light-blue-grey:not(.is-outlined).disabled.focus, .button.is-light-blue-grey:not(.is-outlined)[disabled]:hover, .button.is-light-blue-grey:not(.is-outlined)[disabled]:focus, .button.is-light-blue-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-blue-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-blue-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-blue-grey:not(.is-outlined).focus {
    background-color: #a8c6df;
    border-color: #a8c6df; }
  .button.is-light-blue-grey:not(.is-outlined) .badge {
    color: #a8c6df;
    background-color: #263238; }

.button.is-inverted.is-light-blue-grey:not(.is-outlined) {
  color: #a8c6df;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-blue-grey:not(.is-outlined):focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined).focus {
    color: #a8c6df;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey:not(.is-outlined):hover {
    color: #a8c6df;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey:not(.is-outlined).dropdown-toggle {
    color: #a8c6df;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-blue-grey:not(.is-outlined):active:hover, .button.is-inverted.is-light-blue-grey:not(.is-outlined):active:focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined):active.focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined).active:hover, .button.is-inverted.is-light-blue-grey:not(.is-outlined).active:focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-blue-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-blue-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-blue-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #a8c6df;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-blue-grey:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-blue-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-blue-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-blue-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-blue-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-blue-grey:not(.is-outlined) .badge {
    color: #263238;
    background-color: #a8c6df; }

.button.is-outlined.is-light-blue-grey {
  border-color: #a8c6df;
  color: #a8c6df;
  background: none; }
  .button.is-outlined.is-light-blue-grey:hover, .button.is-outlined.is-light-blue-grey:active, .button.is-outlined.is-light-blue-grey:active:hover, .button.is-outlined.is-light-blue-grey:active:focus, .button.is-outlined.is-light-blue-grey:focus, .button.is-outlined.is-light-blue-grey.active {
    border-color: #a8c6df;
    color: #263238;
    background-color: #a8c6df; }

.button.is-light-blue-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-blue-grey-20:not(.is-outlined) {
  color: #263238;
  background-color: rgba(168, 198, 223, 0.2);
  border-color: rgba(168, 198, 223, 0.2); }
  .button.is-light-blue-grey-20:not(.is-outlined):focus, .button.is-light-blue-grey-20:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-light-blue-grey-20:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-light-blue-grey-20:not(.is-outlined):active, .button.is-light-blue-grey-20:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
    .button.is-light-blue-grey-20:not(.is-outlined):active:hover, .button.is-light-blue-grey-20:not(.is-outlined):active:focus, .button.is-light-blue-grey-20:not(.is-outlined):active.focus, .button.is-light-blue-grey-20:not(.is-outlined).active:hover, .button.is-light-blue-grey-20:not(.is-outlined).active:focus, .button.is-light-blue-grey-20:not(.is-outlined).active.focus,
    .open > .button.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(168, 198, 223, 0.75);
      border-color: rgba(168, 198, 223, 0.75); }
  .button.is-light-blue-grey-20:not(.is-outlined):active, .button.is-light-blue-grey-20:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-blue-grey-20:not(.is-outlined).disabled:hover, .button.is-light-blue-grey-20:not(.is-outlined).disabled:focus, .button.is-light-blue-grey-20:not(.is-outlined).disabled.focus, .button.is-light-blue-grey-20:not(.is-outlined)[disabled]:hover, .button.is-light-blue-grey-20:not(.is-outlined)[disabled]:focus, .button.is-light-blue-grey-20:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-blue-grey-20:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-blue-grey-20:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-blue-grey-20:not(.is-outlined).focus {
    background-color: rgba(168, 198, 223, 0.2);
    border-color: rgba(168, 198, 223, 0.2); }
  .button.is-light-blue-grey-20:not(.is-outlined) .badge {
    color: rgba(168, 198, 223, 0.2);
    background-color: #263238; }

.button.is-inverted.is-light-blue-grey-20:not(.is-outlined) {
  color: rgba(168, 198, 223, 0.2);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).focus {
    color: rgba(168, 198, 223, 0.2);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):hover {
    color: rgba(168, 198, 223, 0.2);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle {
    color: rgba(168, 198, 223, 0.2);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):active:hover, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):active:focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):active.focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).active:hover, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).active:focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(168, 198, 223, 0.2);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-blue-grey-20:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-20:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-20:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-20:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.2); }

.button.is-outlined.is-light-blue-grey-20 {
  border-color: rgba(168, 198, 223, 0.2);
  color: rgba(168, 198, 223, 0.2);
  background: none; }
  .button.is-outlined.is-light-blue-grey-20:hover, .button.is-outlined.is-light-blue-grey-20:active, .button.is-outlined.is-light-blue-grey-20:active:hover, .button.is-outlined.is-light-blue-grey-20:active:focus, .button.is-outlined.is-light-blue-grey-20:focus, .button.is-outlined.is-light-blue-grey-20.active {
    border-color: rgba(168, 198, 223, 0.2);
    color: #263238;
    background-color: rgba(168, 198, 223, 0.2); }

.button.is-light-blue-grey-20.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-blue-grey-80:not(.is-outlined) {
  color: #263238;
  background-color: rgba(168, 198, 223, 0.8);
  border-color: rgba(168, 198, 223, 0.8); }
  .button.is-light-blue-grey-80:not(.is-outlined):focus, .button.is-light-blue-grey-80:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-light-blue-grey-80:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-light-blue-grey-80:not(.is-outlined):active, .button.is-light-blue-grey-80:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
    .button.is-light-blue-grey-80:not(.is-outlined):active:hover, .button.is-light-blue-grey-80:not(.is-outlined):active:focus, .button.is-light-blue-grey-80:not(.is-outlined):active.focus, .button.is-light-blue-grey-80:not(.is-outlined).active:hover, .button.is-light-blue-grey-80:not(.is-outlined).active:focus, .button.is-light-blue-grey-80:not(.is-outlined).active.focus,
    .open > .button.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(168, 198, 223, 0.75);
      border-color: rgba(168, 198, 223, 0.75); }
  .button.is-light-blue-grey-80:not(.is-outlined):active, .button.is-light-blue-grey-80:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-blue-grey-80:not(.is-outlined).disabled:hover, .button.is-light-blue-grey-80:not(.is-outlined).disabled:focus, .button.is-light-blue-grey-80:not(.is-outlined).disabled.focus, .button.is-light-blue-grey-80:not(.is-outlined)[disabled]:hover, .button.is-light-blue-grey-80:not(.is-outlined)[disabled]:focus, .button.is-light-blue-grey-80:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-blue-grey-80:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-blue-grey-80:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-blue-grey-80:not(.is-outlined).focus {
    background-color: rgba(168, 198, 223, 0.8);
    border-color: rgba(168, 198, 223, 0.8); }
  .button.is-light-blue-grey-80:not(.is-outlined) .badge {
    color: rgba(168, 198, 223, 0.8);
    background-color: #263238; }

.button.is-inverted.is-light-blue-grey-80:not(.is-outlined) {
  color: rgba(168, 198, 223, 0.8);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).focus {
    color: rgba(168, 198, 223, 0.8);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):hover {
    color: rgba(168, 198, 223, 0.8);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle {
    color: rgba(168, 198, 223, 0.8);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):active:hover, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):active:focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):active.focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).active:hover, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).active:focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(168, 198, 223, 0.8);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-blue-grey-80:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-80:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-80:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-80:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.8); }

.button.is-outlined.is-light-blue-grey-80 {
  border-color: rgba(168, 198, 223, 0.8);
  color: rgba(168, 198, 223, 0.8);
  background: none; }
  .button.is-outlined.is-light-blue-grey-80:hover, .button.is-outlined.is-light-blue-grey-80:active, .button.is-outlined.is-light-blue-grey-80:active:hover, .button.is-outlined.is-light-blue-grey-80:active:focus, .button.is-outlined.is-light-blue-grey-80:focus, .button.is-outlined.is-light-blue-grey-80.active {
    border-color: rgba(168, 198, 223, 0.8);
    color: #263238;
    background-color: rgba(168, 198, 223, 0.8); }

.button.is-light-blue-grey-80.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-blue-grey-90:not(.is-outlined) {
  color: #263238;
  background-color: rgba(183, 210, 229, 0.9);
  border-color: rgba(183, 210, 229, 0.9); }
  .button.is-light-blue-grey-90:not(.is-outlined):focus, .button.is-light-blue-grey-90:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(183, 210, 229, 0.85);
    border-color: rgba(183, 210, 229, 0.85); }
  .button.is-light-blue-grey-90:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(183, 210, 229, 0.85);
    border-color: rgba(183, 210, 229, 0.85); }
  .button.is-light-blue-grey-90:not(.is-outlined):active, .button.is-light-blue-grey-90:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(183, 210, 229, 0.85);
    border-color: rgba(183, 210, 229, 0.85); }
    .button.is-light-blue-grey-90:not(.is-outlined):active:hover, .button.is-light-blue-grey-90:not(.is-outlined):active:focus, .button.is-light-blue-grey-90:not(.is-outlined):active.focus, .button.is-light-blue-grey-90:not(.is-outlined).active:hover, .button.is-light-blue-grey-90:not(.is-outlined).active:focus, .button.is-light-blue-grey-90:not(.is-outlined).active.focus,
    .open > .button.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(183, 210, 229, 0.75);
      border-color: rgba(183, 210, 229, 0.75); }
  .button.is-light-blue-grey-90:not(.is-outlined):active, .button.is-light-blue-grey-90:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-blue-grey-90:not(.is-outlined).disabled:hover, .button.is-light-blue-grey-90:not(.is-outlined).disabled:focus, .button.is-light-blue-grey-90:not(.is-outlined).disabled.focus, .button.is-light-blue-grey-90:not(.is-outlined)[disabled]:hover, .button.is-light-blue-grey-90:not(.is-outlined)[disabled]:focus, .button.is-light-blue-grey-90:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-blue-grey-90:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-blue-grey-90:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-blue-grey-90:not(.is-outlined).focus {
    background-color: rgba(183, 210, 229, 0.9);
    border-color: rgba(183, 210, 229, 0.9); }
  .button.is-light-blue-grey-90:not(.is-outlined) .badge {
    color: rgba(183, 210, 229, 0.9);
    background-color: #263238; }

.button.is-inverted.is-light-blue-grey-90:not(.is-outlined) {
  color: rgba(183, 210, 229, 0.9);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).focus {
    color: rgba(183, 210, 229, 0.9);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):hover {
    color: rgba(183, 210, 229, 0.9);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle {
    color: rgba(183, 210, 229, 0.9);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):active:hover, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):active:focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):active.focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).active:hover, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).active:focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(183, 210, 229, 0.9);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-blue-grey-90:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-90:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-90:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-90:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(183, 210, 229, 0.9); }

.button.is-outlined.is-light-blue-grey-90 {
  border-color: rgba(183, 210, 229, 0.9);
  color: rgba(183, 210, 229, 0.9);
  background: none; }
  .button.is-outlined.is-light-blue-grey-90:hover, .button.is-outlined.is-light-blue-grey-90:active, .button.is-outlined.is-light-blue-grey-90:active:hover, .button.is-outlined.is-light-blue-grey-90:active:focus, .button.is-outlined.is-light-blue-grey-90:focus, .button.is-outlined.is-light-blue-grey-90.active {
    border-color: rgba(183, 210, 229, 0.9);
    color: #263238;
    background-color: rgba(183, 210, 229, 0.9); }

.button.is-light-blue-grey-90.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-bluish-grey:not(.is-outlined) {
  color: #fff;
  background-color: #78909c;
  border-color: #78909c; }
  .button.is-bluish-grey:not(.is-outlined):focus, .button.is-bluish-grey:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(120, 144, 156, 0.85);
    border-color: rgba(120, 144, 156, 0.85); }
  .button.is-bluish-grey:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(120, 144, 156, 0.85);
    border-color: rgba(120, 144, 156, 0.85); }
  .button.is-bluish-grey:not(.is-outlined):active, .button.is-bluish-grey:not(.is-outlined).active,
  .open > .button.is-bluish-grey:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(120, 144, 156, 0.85);
    border-color: rgba(120, 144, 156, 0.85); }
    .button.is-bluish-grey:not(.is-outlined):active:hover, .button.is-bluish-grey:not(.is-outlined):active:focus, .button.is-bluish-grey:not(.is-outlined):active.focus, .button.is-bluish-grey:not(.is-outlined).active:hover, .button.is-bluish-grey:not(.is-outlined).active:focus, .button.is-bluish-grey:not(.is-outlined).active.focus,
    .open > .button.is-bluish-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-bluish-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-bluish-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(120, 144, 156, 0.75);
      border-color: rgba(120, 144, 156, 0.75); }
  .button.is-bluish-grey:not(.is-outlined):active, .button.is-bluish-grey:not(.is-outlined).active,
  .open > .button.is-bluish-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-bluish-grey:not(.is-outlined).disabled:hover, .button.is-bluish-grey:not(.is-outlined).disabled:focus, .button.is-bluish-grey:not(.is-outlined).disabled.focus, .button.is-bluish-grey:not(.is-outlined)[disabled]:hover, .button.is-bluish-grey:not(.is-outlined)[disabled]:focus, .button.is-bluish-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-bluish-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-bluish-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-bluish-grey:not(.is-outlined).focus {
    background-color: #78909c;
    border-color: #78909c; }
  .button.is-bluish-grey:not(.is-outlined) .badge {
    color: #78909c;
    background-color: #fff; }

.button.is-inverted.is-bluish-grey:not(.is-outlined) {
  color: #78909c;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-bluish-grey:not(.is-outlined):focus, .button.is-inverted.is-bluish-grey:not(.is-outlined).focus {
    color: #78909c;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-bluish-grey:not(.is-outlined):hover {
    color: #78909c;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-bluish-grey:not(.is-outlined):active, .button.is-inverted.is-bluish-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-bluish-grey:not(.is-outlined).dropdown-toggle {
    color: #78909c;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-bluish-grey:not(.is-outlined):active:hover, .button.is-inverted.is-bluish-grey:not(.is-outlined):active:focus, .button.is-inverted.is-bluish-grey:not(.is-outlined):active.focus, .button.is-inverted.is-bluish-grey:not(.is-outlined).active:hover, .button.is-inverted.is-bluish-grey:not(.is-outlined).active:focus, .button.is-inverted.is-bluish-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-bluish-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-bluish-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-bluish-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #78909c;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-bluish-grey:not(.is-outlined):active, .button.is-inverted.is-bluish-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-bluish-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-bluish-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-bluish-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-bluish-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-bluish-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-bluish-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-bluish-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-bluish-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-bluish-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-bluish-grey:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-bluish-grey:not(.is-outlined) .badge {
    color: #fff;
    background-color: #78909c; }

.button.is-outlined.is-bluish-grey {
  border-color: #78909c;
  color: #78909c;
  background: none; }
  .button.is-outlined.is-bluish-grey:hover, .button.is-outlined.is-bluish-grey:active, .button.is-outlined.is-bluish-grey:active:hover, .button.is-outlined.is-bluish-grey:active:focus, .button.is-outlined.is-bluish-grey:focus, .button.is-outlined.is-bluish-grey.active {
    border-color: #78909c;
    color: #fff;
    background-color: #78909c; }

.button.is-bluish-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-grey:not(.is-outlined) {
  color: #263238;
  background-color: #f8f9f9;
  border-color: #f8f9f9; }
  .button.is-light-grey:not(.is-outlined):focus, .button.is-light-grey:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(248, 249, 249, 0.85);
    border-color: rgba(248, 249, 249, 0.85); }
  .button.is-light-grey:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(248, 249, 249, 0.85);
    border-color: rgba(248, 249, 249, 0.85); }
  .button.is-light-grey:not(.is-outlined):active, .button.is-light-grey:not(.is-outlined).active,
  .open > .button.is-light-grey:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(248, 249, 249, 0.85);
    border-color: rgba(248, 249, 249, 0.85); }
    .button.is-light-grey:not(.is-outlined):active:hover, .button.is-light-grey:not(.is-outlined):active:focus, .button.is-light-grey:not(.is-outlined):active.focus, .button.is-light-grey:not(.is-outlined).active:hover, .button.is-light-grey:not(.is-outlined).active:focus, .button.is-light-grey:not(.is-outlined).active.focus,
    .open > .button.is-light-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(248, 249, 249, 0.75);
      border-color: rgba(248, 249, 249, 0.75); }
  .button.is-light-grey:not(.is-outlined):active, .button.is-light-grey:not(.is-outlined).active,
  .open > .button.is-light-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-grey:not(.is-outlined).disabled:hover, .button.is-light-grey:not(.is-outlined).disabled:focus, .button.is-light-grey:not(.is-outlined).disabled.focus, .button.is-light-grey:not(.is-outlined)[disabled]:hover, .button.is-light-grey:not(.is-outlined)[disabled]:focus, .button.is-light-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-grey:not(.is-outlined).focus {
    background-color: #f8f9f9;
    border-color: #f8f9f9; }
  .button.is-light-grey:not(.is-outlined) .badge {
    color: #f8f9f9;
    background-color: #263238; }

.button.is-inverted.is-light-grey:not(.is-outlined) {
  color: #f8f9f9;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-grey:not(.is-outlined):focus, .button.is-inverted.is-light-grey:not(.is-outlined).focus {
    color: #f8f9f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-grey:not(.is-outlined):hover {
    color: #f8f9f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-grey:not(.is-outlined):active, .button.is-inverted.is-light-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-grey:not(.is-outlined).dropdown-toggle {
    color: #f8f9f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-grey:not(.is-outlined):active:hover, .button.is-inverted.is-light-grey:not(.is-outlined):active:focus, .button.is-inverted.is-light-grey:not(.is-outlined):active.focus, .button.is-inverted.is-light-grey:not(.is-outlined).active:hover, .button.is-inverted.is-light-grey:not(.is-outlined).active:focus, .button.is-inverted.is-light-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #f8f9f9;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-grey:not(.is-outlined):active, .button.is-inverted.is-light-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-grey:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-grey:not(.is-outlined) .badge {
    color: #263238;
    background-color: #f8f9f9; }

.button.is-outlined.is-light-grey {
  border-color: #f8f9f9;
  color: #f8f9f9;
  background: none; }
  .button.is-outlined.is-light-grey:hover, .button.is-outlined.is-light-grey:active, .button.is-outlined.is-light-grey:active:hover, .button.is-outlined.is-light-grey:active:focus, .button.is-outlined.is-light-grey:focus, .button.is-outlined.is-light-grey.active {
    border-color: #f8f9f9;
    color: #263238;
    background-color: #f8f9f9; }

.button.is-light-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark-sky-blue-10:not(.is-outlined) {
  color: #455a64;
  background-color: rgba(30, 136, 229, 0.1);
  border-color: rgba(30, 136, 229, 0.1); }
  .button.is-dark-sky-blue-10:not(.is-outlined):focus, .button.is-dark-sky-blue-10:not(.is-outlined).focus {
    color: #455a64;
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
  .button.is-dark-sky-blue-10:not(.is-outlined):hover {
    color: #455a64;
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
  .button.is-dark-sky-blue-10:not(.is-outlined):active, .button.is-dark-sky-blue-10:not(.is-outlined).active,
  .open > .button.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle {
    color: #455a64;
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
    .button.is-dark-sky-blue-10:not(.is-outlined):active:hover, .button.is-dark-sky-blue-10:not(.is-outlined):active:focus, .button.is-dark-sky-blue-10:not(.is-outlined):active.focus, .button.is-dark-sky-blue-10:not(.is-outlined).active:hover, .button.is-dark-sky-blue-10:not(.is-outlined).active:focus, .button.is-dark-sky-blue-10:not(.is-outlined).active.focus,
    .open > .button.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle.focus {
      color: #455a64;
      background-color: rgba(30, 136, 229, 0.75);
      border-color: rgba(30, 136, 229, 0.75); }
  .button.is-dark-sky-blue-10:not(.is-outlined):active, .button.is-dark-sky-blue-10:not(.is-outlined).active,
  .open > .button.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark-sky-blue-10:not(.is-outlined).disabled:hover, .button.is-dark-sky-blue-10:not(.is-outlined).disabled:focus, .button.is-dark-sky-blue-10:not(.is-outlined).disabled.focus, .button.is-dark-sky-blue-10:not(.is-outlined)[disabled]:hover, .button.is-dark-sky-blue-10:not(.is-outlined)[disabled]:focus, .button.is-dark-sky-blue-10:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark-sky-blue-10:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark-sky-blue-10:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark-sky-blue-10:not(.is-outlined).focus {
    background-color: rgba(30, 136, 229, 0.1);
    border-color: rgba(30, 136, 229, 0.1); }
  .button.is-dark-sky-blue-10:not(.is-outlined) .badge {
    color: rgba(30, 136, 229, 0.1);
    background-color: #455a64; }

.button.is-inverted.is-dark-sky-blue-10:not(.is-outlined) {
  color: rgba(30, 136, 229, 0.1);
  background-color: #455a64;
  border-color: #455a64; }
  .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).focus {
    color: rgba(30, 136, 229, 0.1);
    background-color: rgba(69, 90, 100, 0.85);
    border-color: rgba(69, 90, 100, 0.85); }
  .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):hover {
    color: rgba(30, 136, 229, 0.1);
    background-color: rgba(69, 90, 100, 0.85);
    border-color: rgba(69, 90, 100, 0.85); }
  .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):active, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle {
    color: rgba(30, 136, 229, 0.1);
    background-color: rgba(69, 90, 100, 0.85);
    border-color: rgba(69, 90, 100, 0.85); }
    .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):active:hover, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):active:focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):active.focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).active:hover, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).active:focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(30, 136, 229, 0.1);
      background-color: rgba(69, 90, 100, 0.75);
      border-color: rgba(69, 90, 100, 0.75); }
  .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):active, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined).focus {
    background-color: #455a64;
    border-color: #455a64; }
  .button.is-inverted.is-dark-sky-blue-10:not(.is-outlined) .badge {
    color: #455a64;
    background-color: rgba(30, 136, 229, 0.1); }

.button.is-outlined.is-dark-sky-blue-10 {
  border-color: rgba(30, 136, 229, 0.1);
  color: rgba(30, 136, 229, 0.1);
  background: none; }
  .button.is-outlined.is-dark-sky-blue-10:hover, .button.is-outlined.is-dark-sky-blue-10:active, .button.is-outlined.is-dark-sky-blue-10:active:hover, .button.is-outlined.is-dark-sky-blue-10:active:focus, .button.is-outlined.is-dark-sky-blue-10:focus, .button.is-outlined.is-dark-sky-blue-10.active {
    border-color: rgba(30, 136, 229, 0.1);
    color: #455a64;
    background-color: rgba(30, 136, 229, 0.1); }

.button.is-dark-sky-blue-10.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-blue-grey-two:not(.is-outlined) {
  color: #263238;
  background-color: #d8e5ef;
  border-color: #d8e5ef; }
  .button.is-light-blue-grey-two:not(.is-outlined):focus, .button.is-light-blue-grey-two:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(216, 229, 239, 0.85);
    border-color: rgba(216, 229, 239, 0.85); }
  .button.is-light-blue-grey-two:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(216, 229, 239, 0.85);
    border-color: rgba(216, 229, 239, 0.85); }
  .button.is-light-blue-grey-two:not(.is-outlined):active, .button.is-light-blue-grey-two:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(216, 229, 239, 0.85);
    border-color: rgba(216, 229, 239, 0.85); }
    .button.is-light-blue-grey-two:not(.is-outlined):active:hover, .button.is-light-blue-grey-two:not(.is-outlined):active:focus, .button.is-light-blue-grey-two:not(.is-outlined):active.focus, .button.is-light-blue-grey-two:not(.is-outlined).active:hover, .button.is-light-blue-grey-two:not(.is-outlined).active:focus, .button.is-light-blue-grey-two:not(.is-outlined).active.focus,
    .open > .button.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(216, 229, 239, 0.75);
      border-color: rgba(216, 229, 239, 0.75); }
  .button.is-light-blue-grey-two:not(.is-outlined):active, .button.is-light-blue-grey-two:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-blue-grey-two:not(.is-outlined).disabled:hover, .button.is-light-blue-grey-two:not(.is-outlined).disabled:focus, .button.is-light-blue-grey-two:not(.is-outlined).disabled.focus, .button.is-light-blue-grey-two:not(.is-outlined)[disabled]:hover, .button.is-light-blue-grey-two:not(.is-outlined)[disabled]:focus, .button.is-light-blue-grey-two:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-blue-grey-two:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-blue-grey-two:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-blue-grey-two:not(.is-outlined).focus {
    background-color: #d8e5ef;
    border-color: #d8e5ef; }
  .button.is-light-blue-grey-two:not(.is-outlined) .badge {
    color: #d8e5ef;
    background-color: #263238; }

.button.is-inverted.is-light-blue-grey-two:not(.is-outlined) {
  color: #d8e5ef;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).focus {
    color: #d8e5ef;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):hover {
    color: #d8e5ef;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle {
    color: #d8e5ef;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):active:hover, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):active:focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):active.focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).active:hover, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).active:focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle.focus {
      color: #d8e5ef;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-blue-grey-two:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-two:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-two:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-two:not(.is-outlined) .badge {
    color: #263238;
    background-color: #d8e5ef; }

.button.is-outlined.is-light-blue-grey-two {
  border-color: #d8e5ef;
  color: #d8e5ef;
  background: none; }
  .button.is-outlined.is-light-blue-grey-two:hover, .button.is-outlined.is-light-blue-grey-two:active, .button.is-outlined.is-light-blue-grey-two:active:hover, .button.is-outlined.is-light-blue-grey-two:active:focus, .button.is-outlined.is-light-blue-grey-two:focus, .button.is-outlined.is-light-blue-grey-two.active {
    border-color: #d8e5ef;
    color: #263238;
    background-color: #d8e5ef; }

.button.is-light-blue-grey-two.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark-grey-blue:not(.is-outlined) {
  color: #fff;
  background-color: #2c4059;
  border-color: #2c4059; }
  .button.is-dark-grey-blue:not(.is-outlined):focus, .button.is-dark-grey-blue:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(44, 64, 89, 0.85);
    border-color: rgba(44, 64, 89, 0.85); }
  .button.is-dark-grey-blue:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(44, 64, 89, 0.85);
    border-color: rgba(44, 64, 89, 0.85); }
  .button.is-dark-grey-blue:not(.is-outlined):active, .button.is-dark-grey-blue:not(.is-outlined).active,
  .open > .button.is-dark-grey-blue:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(44, 64, 89, 0.85);
    border-color: rgba(44, 64, 89, 0.85); }
    .button.is-dark-grey-blue:not(.is-outlined):active:hover, .button.is-dark-grey-blue:not(.is-outlined):active:focus, .button.is-dark-grey-blue:not(.is-outlined):active.focus, .button.is-dark-grey-blue:not(.is-outlined).active:hover, .button.is-dark-grey-blue:not(.is-outlined).active:focus, .button.is-dark-grey-blue:not(.is-outlined).active.focus,
    .open > .button.is-dark-grey-blue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark-grey-blue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark-grey-blue:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(44, 64, 89, 0.75);
      border-color: rgba(44, 64, 89, 0.75); }
  .button.is-dark-grey-blue:not(.is-outlined):active, .button.is-dark-grey-blue:not(.is-outlined).active,
  .open > .button.is-dark-grey-blue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark-grey-blue:not(.is-outlined).disabled:hover, .button.is-dark-grey-blue:not(.is-outlined).disabled:focus, .button.is-dark-grey-blue:not(.is-outlined).disabled.focus, .button.is-dark-grey-blue:not(.is-outlined)[disabled]:hover, .button.is-dark-grey-blue:not(.is-outlined)[disabled]:focus, .button.is-dark-grey-blue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark-grey-blue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark-grey-blue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark-grey-blue:not(.is-outlined).focus {
    background-color: #2c4059;
    border-color: #2c4059; }
  .button.is-dark-grey-blue:not(.is-outlined) .badge {
    color: #2c4059;
    background-color: #fff; }

.button.is-inverted.is-dark-grey-blue:not(.is-outlined) {
  color: #2c4059;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-dark-grey-blue:not(.is-outlined):focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).focus {
    color: #2c4059;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-dark-grey-blue:not(.is-outlined):hover {
    color: #2c4059;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-dark-grey-blue:not(.is-outlined):active, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-grey-blue:not(.is-outlined).dropdown-toggle {
    color: #2c4059;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-dark-grey-blue:not(.is-outlined):active:hover, .button.is-inverted.is-dark-grey-blue:not(.is-outlined):active:focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined):active.focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).active:hover, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).active:focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark-grey-blue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark-grey-blue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark-grey-blue:not(.is-outlined).dropdown-toggle.focus {
      color: #2c4059;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-dark-grey-blue:not(.is-outlined):active, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-grey-blue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark-grey-blue:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark-grey-blue:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark-grey-blue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark-grey-blue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark-grey-blue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark-grey-blue:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-dark-grey-blue:not(.is-outlined) .badge {
    color: #fff;
    background-color: #2c4059; }

.button.is-outlined.is-dark-grey-blue {
  border-color: #2c4059;
  color: #2c4059;
  background: none; }
  .button.is-outlined.is-dark-grey-blue:hover, .button.is-outlined.is-dark-grey-blue:active, .button.is-outlined.is-dark-grey-blue:active:hover, .button.is-outlined.is-dark-grey-blue:active:focus, .button.is-outlined.is-dark-grey-blue:focus, .button.is-outlined.is-dark-grey-blue.active {
    border-color: #2c4059;
    color: #fff;
    background-color: #2c4059; }

.button.is-dark-grey-blue.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark-grey-blue-16:not(.is-outlined) {
  color: #263238;
  background-color: rgba(44, 64, 90, 0.16);
  border-color: rgba(44, 64, 90, 0.16); }
  .button.is-dark-grey-blue-16:not(.is-outlined):focus, .button.is-dark-grey-blue-16:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(44, 64, 90, 0.85);
    border-color: rgba(44, 64, 90, 0.85); }
  .button.is-dark-grey-blue-16:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(44, 64, 90, 0.85);
    border-color: rgba(44, 64, 90, 0.85); }
  .button.is-dark-grey-blue-16:not(.is-outlined):active, .button.is-dark-grey-blue-16:not(.is-outlined).active,
  .open > .button.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(44, 64, 90, 0.85);
    border-color: rgba(44, 64, 90, 0.85); }
    .button.is-dark-grey-blue-16:not(.is-outlined):active:hover, .button.is-dark-grey-blue-16:not(.is-outlined):active:focus, .button.is-dark-grey-blue-16:not(.is-outlined):active.focus, .button.is-dark-grey-blue-16:not(.is-outlined).active:hover, .button.is-dark-grey-blue-16:not(.is-outlined).active:focus, .button.is-dark-grey-blue-16:not(.is-outlined).active.focus,
    .open > .button.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(44, 64, 90, 0.75);
      border-color: rgba(44, 64, 90, 0.75); }
  .button.is-dark-grey-blue-16:not(.is-outlined):active, .button.is-dark-grey-blue-16:not(.is-outlined).active,
  .open > .button.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark-grey-blue-16:not(.is-outlined).disabled:hover, .button.is-dark-grey-blue-16:not(.is-outlined).disabled:focus, .button.is-dark-grey-blue-16:not(.is-outlined).disabled.focus, .button.is-dark-grey-blue-16:not(.is-outlined)[disabled]:hover, .button.is-dark-grey-blue-16:not(.is-outlined)[disabled]:focus, .button.is-dark-grey-blue-16:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark-grey-blue-16:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark-grey-blue-16:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark-grey-blue-16:not(.is-outlined).focus {
    background-color: rgba(44, 64, 90, 0.16);
    border-color: rgba(44, 64, 90, 0.16); }
  .button.is-dark-grey-blue-16:not(.is-outlined) .badge {
    color: rgba(44, 64, 90, 0.16);
    background-color: #263238; }

.button.is-inverted.is-dark-grey-blue-16:not(.is-outlined) {
  color: rgba(44, 64, 90, 0.16);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).focus {
    color: rgba(44, 64, 90, 0.16);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):hover {
    color: rgba(44, 64, 90, 0.16);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):active, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle {
    color: rgba(44, 64, 90, 0.16);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):active:hover, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):active:focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):active.focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).active:hover, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).active:focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(44, 64, 90, 0.16);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):active, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-dark-grey-blue-16:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(44, 64, 90, 0.16); }

.button.is-outlined.is-dark-grey-blue-16 {
  border-color: rgba(44, 64, 90, 0.16);
  color: rgba(44, 64, 90, 0.16);
  background: none; }
  .button.is-outlined.is-dark-grey-blue-16:hover, .button.is-outlined.is-dark-grey-blue-16:active, .button.is-outlined.is-dark-grey-blue-16:active:hover, .button.is-outlined.is-dark-grey-blue-16:active:focus, .button.is-outlined.is-dark-grey-blue-16:focus, .button.is-outlined.is-dark-grey-blue-16.active {
    border-color: rgba(44, 64, 90, 0.16);
    color: #263238;
    background-color: rgba(44, 64, 90, 0.16); }

.button.is-dark-grey-blue-16.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark-2:not(.is-outlined) {
  color: #263238;
  background-color: rgba(38, 50, 56, 0.02);
  border-color: rgba(38, 50, 56, 0.02); }
  .button.is-dark-2:not(.is-outlined):focus, .button.is-dark-2:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-dark-2:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-dark-2:not(.is-outlined):active, .button.is-dark-2:not(.is-outlined).active,
  .open > .button.is-dark-2:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-dark-2:not(.is-outlined):active:hover, .button.is-dark-2:not(.is-outlined):active:focus, .button.is-dark-2:not(.is-outlined):active.focus, .button.is-dark-2:not(.is-outlined).active:hover, .button.is-dark-2:not(.is-outlined).active:focus, .button.is-dark-2:not(.is-outlined).active.focus,
    .open > .button.is-dark-2:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark-2:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark-2:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-dark-2:not(.is-outlined):active, .button.is-dark-2:not(.is-outlined).active,
  .open > .button.is-dark-2:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark-2:not(.is-outlined).disabled:hover, .button.is-dark-2:not(.is-outlined).disabled:focus, .button.is-dark-2:not(.is-outlined).disabled.focus, .button.is-dark-2:not(.is-outlined)[disabled]:hover, .button.is-dark-2:not(.is-outlined)[disabled]:focus, .button.is-dark-2:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark-2:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark-2:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark-2:not(.is-outlined).focus {
    background-color: rgba(38, 50, 56, 0.02);
    border-color: rgba(38, 50, 56, 0.02); }
  .button.is-dark-2:not(.is-outlined) .badge {
    color: rgba(38, 50, 56, 0.02);
    background-color: #263238; }

.button.is-inverted.is-dark-2:not(.is-outlined) {
  color: rgba(38, 50, 56, 0.02);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-dark-2:not(.is-outlined):focus, .button.is-inverted.is-dark-2:not(.is-outlined).focus {
    color: rgba(38, 50, 56, 0.02);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-dark-2:not(.is-outlined):hover {
    color: rgba(38, 50, 56, 0.02);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-dark-2:not(.is-outlined):active, .button.is-inverted.is-dark-2:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-2:not(.is-outlined).dropdown-toggle {
    color: rgba(38, 50, 56, 0.02);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-dark-2:not(.is-outlined):active:hover, .button.is-inverted.is-dark-2:not(.is-outlined):active:focus, .button.is-inverted.is-dark-2:not(.is-outlined):active.focus, .button.is-inverted.is-dark-2:not(.is-outlined).active:hover, .button.is-inverted.is-dark-2:not(.is-outlined).active:focus, .button.is-inverted.is-dark-2:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark-2:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark-2:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark-2:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(38, 50, 56, 0.02);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-dark-2:not(.is-outlined):active, .button.is-inverted.is-dark-2:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-2:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark-2:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark-2:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark-2:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark-2:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark-2:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark-2:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark-2:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark-2:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark-2:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-dark-2:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.02); }

.button.is-outlined.is-dark-2 {
  border-color: rgba(38, 50, 56, 0.02);
  color: rgba(38, 50, 56, 0.02);
  background: none; }
  .button.is-outlined.is-dark-2:hover, .button.is-outlined.is-dark-2:active, .button.is-outlined.is-dark-2:active:hover, .button.is-outlined.is-dark-2:active:focus, .button.is-outlined.is-dark-2:focus, .button.is-outlined.is-dark-2.active {
    border-color: rgba(38, 50, 56, 0.02);
    color: #263238;
    background-color: rgba(38, 50, 56, 0.02); }

.button.is-dark-2.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark-3:not(.is-outlined) {
  color: #263238;
  background-color: rgba(38, 50, 56, 0.03);
  border-color: rgba(38, 50, 56, 0.03); }
  .button.is-dark-3:not(.is-outlined):focus, .button.is-dark-3:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-dark-3:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-dark-3:not(.is-outlined):active, .button.is-dark-3:not(.is-outlined).active,
  .open > .button.is-dark-3:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-dark-3:not(.is-outlined):active:hover, .button.is-dark-3:not(.is-outlined):active:focus, .button.is-dark-3:not(.is-outlined):active.focus, .button.is-dark-3:not(.is-outlined).active:hover, .button.is-dark-3:not(.is-outlined).active:focus, .button.is-dark-3:not(.is-outlined).active.focus,
    .open > .button.is-dark-3:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark-3:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark-3:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-dark-3:not(.is-outlined):active, .button.is-dark-3:not(.is-outlined).active,
  .open > .button.is-dark-3:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark-3:not(.is-outlined).disabled:hover, .button.is-dark-3:not(.is-outlined).disabled:focus, .button.is-dark-3:not(.is-outlined).disabled.focus, .button.is-dark-3:not(.is-outlined)[disabled]:hover, .button.is-dark-3:not(.is-outlined)[disabled]:focus, .button.is-dark-3:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark-3:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark-3:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark-3:not(.is-outlined).focus {
    background-color: rgba(38, 50, 56, 0.03);
    border-color: rgba(38, 50, 56, 0.03); }
  .button.is-dark-3:not(.is-outlined) .badge {
    color: rgba(38, 50, 56, 0.03);
    background-color: #263238; }

.button.is-inverted.is-dark-3:not(.is-outlined) {
  color: rgba(38, 50, 56, 0.03);
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-dark-3:not(.is-outlined):focus, .button.is-inverted.is-dark-3:not(.is-outlined).focus {
    color: rgba(38, 50, 56, 0.03);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-dark-3:not(.is-outlined):hover {
    color: rgba(38, 50, 56, 0.03);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-dark-3:not(.is-outlined):active, .button.is-inverted.is-dark-3:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-3:not(.is-outlined).dropdown-toggle {
    color: rgba(38, 50, 56, 0.03);
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-dark-3:not(.is-outlined):active:hover, .button.is-inverted.is-dark-3:not(.is-outlined):active:focus, .button.is-inverted.is-dark-3:not(.is-outlined):active.focus, .button.is-inverted.is-dark-3:not(.is-outlined).active:hover, .button.is-inverted.is-dark-3:not(.is-outlined).active:focus, .button.is-inverted.is-dark-3:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark-3:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark-3:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark-3:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(38, 50, 56, 0.03);
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-dark-3:not(.is-outlined):active, .button.is-inverted.is-dark-3:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-3:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark-3:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark-3:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark-3:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark-3:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark-3:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark-3:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark-3:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark-3:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark-3:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-dark-3:not(.is-outlined) .badge {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.03); }

.button.is-outlined.is-dark-3 {
  border-color: rgba(38, 50, 56, 0.03);
  color: rgba(38, 50, 56, 0.03);
  background: none; }
  .button.is-outlined.is-dark-3:hover, .button.is-outlined.is-dark-3:active, .button.is-outlined.is-dark-3:active:hover, .button.is-outlined.is-dark-3:active:focus, .button.is-outlined.is-dark-3:focus, .button.is-outlined.is-dark-3.active {
    border-color: rgba(38, 50, 56, 0.03);
    color: #263238;
    background-color: rgba(38, 50, 56, 0.03); }

.button.is-dark-3.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-charcoal-grey:not(.is-outlined) {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f; }
  .button.is-charcoal-grey:not(.is-outlined):focus, .button.is-charcoal-grey:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
  .button.is-charcoal-grey:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
  .button.is-charcoal-grey:not(.is-outlined):active, .button.is-charcoal-grey:not(.is-outlined).active,
  .open > .button.is-charcoal-grey:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
    .button.is-charcoal-grey:not(.is-outlined):active:hover, .button.is-charcoal-grey:not(.is-outlined):active:focus, .button.is-charcoal-grey:not(.is-outlined):active.focus, .button.is-charcoal-grey:not(.is-outlined).active:hover, .button.is-charcoal-grey:not(.is-outlined).active:focus, .button.is-charcoal-grey:not(.is-outlined).active.focus,
    .open > .button.is-charcoal-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-charcoal-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-charcoal-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(55, 71, 79, 0.75);
      border-color: rgba(55, 71, 79, 0.75); }
  .button.is-charcoal-grey:not(.is-outlined):active, .button.is-charcoal-grey:not(.is-outlined).active,
  .open > .button.is-charcoal-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-charcoal-grey:not(.is-outlined).disabled:hover, .button.is-charcoal-grey:not(.is-outlined).disabled:focus, .button.is-charcoal-grey:not(.is-outlined).disabled.focus, .button.is-charcoal-grey:not(.is-outlined)[disabled]:hover, .button.is-charcoal-grey:not(.is-outlined)[disabled]:focus, .button.is-charcoal-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-charcoal-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-charcoal-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-charcoal-grey:not(.is-outlined).focus {
    background-color: #37474f;
    border-color: #37474f; }
  .button.is-charcoal-grey:not(.is-outlined) .badge {
    color: #37474f;
    background-color: #fff; }

.button.is-inverted.is-charcoal-grey:not(.is-outlined) {
  color: #37474f;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-charcoal-grey:not(.is-outlined):focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined).focus {
    color: #37474f;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-charcoal-grey:not(.is-outlined):hover {
    color: #37474f;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-charcoal-grey:not(.is-outlined):active, .button.is-inverted.is-charcoal-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-charcoal-grey:not(.is-outlined).dropdown-toggle {
    color: #37474f;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-charcoal-grey:not(.is-outlined):active:hover, .button.is-inverted.is-charcoal-grey:not(.is-outlined):active:focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined):active.focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined).active:hover, .button.is-inverted.is-charcoal-grey:not(.is-outlined).active:focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-charcoal-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-charcoal-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-charcoal-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #37474f;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-charcoal-grey:not(.is-outlined):active, .button.is-inverted.is-charcoal-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-charcoal-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-charcoal-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-charcoal-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-charcoal-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-charcoal-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-charcoal-grey:not(.is-outlined) .badge {
    color: #fff;
    background-color: #37474f; }

.button.is-outlined.is-charcoal-grey {
  border-color: #37474f;
  color: #37474f;
  background: none; }
  .button.is-outlined.is-charcoal-grey:hover, .button.is-outlined.is-charcoal-grey:active, .button.is-outlined.is-charcoal-grey:active:hover, .button.is-outlined.is-charcoal-grey:active:focus, .button.is-outlined.is-charcoal-grey:focus, .button.is-outlined.is-charcoal-grey.active {
    border-color: #37474f;
    color: #fff;
    background-color: #37474f; }

.button.is-charcoal-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-pale-grey:not(.is-outlined) {
  color: #263238;
  background-color: #fafbfc;
  border-color: #fafbfc; }
  .button.is-pale-grey:not(.is-outlined):focus, .button.is-pale-grey:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(250, 251, 252, 0.85);
    border-color: rgba(250, 251, 252, 0.85); }
  .button.is-pale-grey:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(250, 251, 252, 0.85);
    border-color: rgba(250, 251, 252, 0.85); }
  .button.is-pale-grey:not(.is-outlined):active, .button.is-pale-grey:not(.is-outlined).active,
  .open > .button.is-pale-grey:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(250, 251, 252, 0.85);
    border-color: rgba(250, 251, 252, 0.85); }
    .button.is-pale-grey:not(.is-outlined):active:hover, .button.is-pale-grey:not(.is-outlined):active:focus, .button.is-pale-grey:not(.is-outlined):active.focus, .button.is-pale-grey:not(.is-outlined).active:hover, .button.is-pale-grey:not(.is-outlined).active:focus, .button.is-pale-grey:not(.is-outlined).active.focus,
    .open > .button.is-pale-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-pale-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-pale-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(250, 251, 252, 0.75);
      border-color: rgba(250, 251, 252, 0.75); }
  .button.is-pale-grey:not(.is-outlined):active, .button.is-pale-grey:not(.is-outlined).active,
  .open > .button.is-pale-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-pale-grey:not(.is-outlined).disabled:hover, .button.is-pale-grey:not(.is-outlined).disabled:focus, .button.is-pale-grey:not(.is-outlined).disabled.focus, .button.is-pale-grey:not(.is-outlined)[disabled]:hover, .button.is-pale-grey:not(.is-outlined)[disabled]:focus, .button.is-pale-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-pale-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-pale-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-pale-grey:not(.is-outlined).focus {
    background-color: #fafbfc;
    border-color: #fafbfc; }
  .button.is-pale-grey:not(.is-outlined) .badge {
    color: #fafbfc;
    background-color: #263238; }

.button.is-inverted.is-pale-grey:not(.is-outlined) {
  color: #fafbfc;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-pale-grey:not(.is-outlined):focus, .button.is-inverted.is-pale-grey:not(.is-outlined).focus {
    color: #fafbfc;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-pale-grey:not(.is-outlined):hover {
    color: #fafbfc;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-pale-grey:not(.is-outlined):active, .button.is-inverted.is-pale-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-pale-grey:not(.is-outlined).dropdown-toggle {
    color: #fafbfc;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-pale-grey:not(.is-outlined):active:hover, .button.is-inverted.is-pale-grey:not(.is-outlined):active:focus, .button.is-inverted.is-pale-grey:not(.is-outlined):active.focus, .button.is-inverted.is-pale-grey:not(.is-outlined).active:hover, .button.is-inverted.is-pale-grey:not(.is-outlined).active:focus, .button.is-inverted.is-pale-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-pale-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-pale-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-pale-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #fafbfc;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-pale-grey:not(.is-outlined):active, .button.is-inverted.is-pale-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-pale-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-pale-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-pale-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-pale-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-pale-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-pale-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-pale-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-pale-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-pale-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-pale-grey:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-pale-grey:not(.is-outlined) .badge {
    color: #263238;
    background-color: #fafbfc; }

.button.is-outlined.is-pale-grey {
  border-color: #fafbfc;
  color: #fafbfc;
  background: none; }
  .button.is-outlined.is-pale-grey:hover, .button.is-outlined.is-pale-grey:active, .button.is-outlined.is-pale-grey:active:hover, .button.is-outlined.is-pale-grey:active:focus, .button.is-outlined.is-pale-grey:focus, .button.is-outlined.is-pale-grey.active {
    border-color: #fafbfc;
    color: #263238;
    background-color: #fafbfc; }

.button.is-pale-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-greyblue:not(.is-outlined) {
  color: #fff;
  background-color: #8dabc4;
  border-color: #8dabc4; }
  .button.is-greyblue:not(.is-outlined):focus, .button.is-greyblue:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(141, 171, 196, 0.85);
    border-color: rgba(141, 171, 196, 0.85); }
  .button.is-greyblue:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(141, 171, 196, 0.85);
    border-color: rgba(141, 171, 196, 0.85); }
  .button.is-greyblue:not(.is-outlined):active, .button.is-greyblue:not(.is-outlined).active,
  .open > .button.is-greyblue:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(141, 171, 196, 0.85);
    border-color: rgba(141, 171, 196, 0.85); }
    .button.is-greyblue:not(.is-outlined):active:hover, .button.is-greyblue:not(.is-outlined):active:focus, .button.is-greyblue:not(.is-outlined):active.focus, .button.is-greyblue:not(.is-outlined).active:hover, .button.is-greyblue:not(.is-outlined).active:focus, .button.is-greyblue:not(.is-outlined).active.focus,
    .open > .button.is-greyblue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-greyblue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-greyblue:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(141, 171, 196, 0.75);
      border-color: rgba(141, 171, 196, 0.75); }
  .button.is-greyblue:not(.is-outlined):active, .button.is-greyblue:not(.is-outlined).active,
  .open > .button.is-greyblue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-greyblue:not(.is-outlined).disabled:hover, .button.is-greyblue:not(.is-outlined).disabled:focus, .button.is-greyblue:not(.is-outlined).disabled.focus, .button.is-greyblue:not(.is-outlined)[disabled]:hover, .button.is-greyblue:not(.is-outlined)[disabled]:focus, .button.is-greyblue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-greyblue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-greyblue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-greyblue:not(.is-outlined).focus {
    background-color: #8dabc4;
    border-color: #8dabc4; }
  .button.is-greyblue:not(.is-outlined) .badge {
    color: #8dabc4;
    background-color: #fff; }

.button.is-inverted.is-greyblue:not(.is-outlined) {
  color: #8dabc4;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-greyblue:not(.is-outlined):focus, .button.is-inverted.is-greyblue:not(.is-outlined).focus {
    color: #8dabc4;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-greyblue:not(.is-outlined):hover {
    color: #8dabc4;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-greyblue:not(.is-outlined):active, .button.is-inverted.is-greyblue:not(.is-outlined).active,
  .open > .button.is-inverted.is-greyblue:not(.is-outlined).dropdown-toggle {
    color: #8dabc4;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-greyblue:not(.is-outlined):active:hover, .button.is-inverted.is-greyblue:not(.is-outlined):active:focus, .button.is-inverted.is-greyblue:not(.is-outlined):active.focus, .button.is-inverted.is-greyblue:not(.is-outlined).active:hover, .button.is-inverted.is-greyblue:not(.is-outlined).active:focus, .button.is-inverted.is-greyblue:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-greyblue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-greyblue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-greyblue:not(.is-outlined).dropdown-toggle.focus {
      color: #8dabc4;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-greyblue:not(.is-outlined):active, .button.is-inverted.is-greyblue:not(.is-outlined).active,
  .open > .button.is-inverted.is-greyblue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-greyblue:not(.is-outlined).disabled:hover, .button.is-inverted.is-greyblue:not(.is-outlined).disabled:focus, .button.is-inverted.is-greyblue:not(.is-outlined).disabled.focus, .button.is-inverted.is-greyblue:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-greyblue:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-greyblue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-greyblue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-greyblue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-greyblue:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-greyblue:not(.is-outlined) .badge {
    color: #fff;
    background-color: #8dabc4; }

.button.is-outlined.is-greyblue {
  border-color: #8dabc4;
  color: #8dabc4;
  background: none; }
  .button.is-outlined.is-greyblue:hover, .button.is-outlined.is-greyblue:active, .button.is-outlined.is-greyblue:active:hover, .button.is-outlined.is-greyblue:active:focus, .button.is-outlined.is-greyblue:focus, .button.is-outlined.is-greyblue.active {
    border-color: #8dabc4;
    color: #fff;
    background-color: #8dabc4; }

.button.is-greyblue.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-bluey-grey:not(.is-outlined) {
  color: #fff;
  background-color: #90a4ae;
  border-color: #90a4ae; }
  .button.is-bluey-grey:not(.is-outlined):focus, .button.is-bluey-grey:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(144, 164, 174, 0.85);
    border-color: rgba(144, 164, 174, 0.85); }
  .button.is-bluey-grey:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(144, 164, 174, 0.85);
    border-color: rgba(144, 164, 174, 0.85); }
  .button.is-bluey-grey:not(.is-outlined):active, .button.is-bluey-grey:not(.is-outlined).active,
  .open > .button.is-bluey-grey:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(144, 164, 174, 0.85);
    border-color: rgba(144, 164, 174, 0.85); }
    .button.is-bluey-grey:not(.is-outlined):active:hover, .button.is-bluey-grey:not(.is-outlined):active:focus, .button.is-bluey-grey:not(.is-outlined):active.focus, .button.is-bluey-grey:not(.is-outlined).active:hover, .button.is-bluey-grey:not(.is-outlined).active:focus, .button.is-bluey-grey:not(.is-outlined).active.focus,
    .open > .button.is-bluey-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-bluey-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-bluey-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(144, 164, 174, 0.75);
      border-color: rgba(144, 164, 174, 0.75); }
  .button.is-bluey-grey:not(.is-outlined):active, .button.is-bluey-grey:not(.is-outlined).active,
  .open > .button.is-bluey-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-bluey-grey:not(.is-outlined).disabled:hover, .button.is-bluey-grey:not(.is-outlined).disabled:focus, .button.is-bluey-grey:not(.is-outlined).disabled.focus, .button.is-bluey-grey:not(.is-outlined)[disabled]:hover, .button.is-bluey-grey:not(.is-outlined)[disabled]:focus, .button.is-bluey-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-bluey-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-bluey-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-bluey-grey:not(.is-outlined).focus {
    background-color: #90a4ae;
    border-color: #90a4ae; }
  .button.is-bluey-grey:not(.is-outlined) .badge {
    color: #90a4ae;
    background-color: #fff; }

.button.is-inverted.is-bluey-grey:not(.is-outlined) {
  color: #90a4ae;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-bluey-grey:not(.is-outlined):focus, .button.is-inverted.is-bluey-grey:not(.is-outlined).focus {
    color: #90a4ae;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-bluey-grey:not(.is-outlined):hover {
    color: #90a4ae;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-bluey-grey:not(.is-outlined):active, .button.is-inverted.is-bluey-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-bluey-grey:not(.is-outlined).dropdown-toggle {
    color: #90a4ae;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-bluey-grey:not(.is-outlined):active:hover, .button.is-inverted.is-bluey-grey:not(.is-outlined):active:focus, .button.is-inverted.is-bluey-grey:not(.is-outlined):active.focus, .button.is-inverted.is-bluey-grey:not(.is-outlined).active:hover, .button.is-inverted.is-bluey-grey:not(.is-outlined).active:focus, .button.is-inverted.is-bluey-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-bluey-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-bluey-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-bluey-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #90a4ae;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-bluey-grey:not(.is-outlined):active, .button.is-inverted.is-bluey-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-bluey-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-bluey-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-bluey-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-bluey-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-bluey-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-bluey-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-bluey-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-bluey-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-bluey-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-bluey-grey:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-bluey-grey:not(.is-outlined) .badge {
    color: #fff;
    background-color: #90a4ae; }

.button.is-outlined.is-bluey-grey {
  border-color: #90a4ae;
  color: #90a4ae;
  background: none; }
  .button.is-outlined.is-bluey-grey:hover, .button.is-outlined.is-bluey-grey:active, .button.is-outlined.is-bluey-grey:active:hover, .button.is-outlined.is-bluey-grey:active:focus, .button.is-outlined.is-bluey-grey:focus, .button.is-outlined.is-bluey-grey.active {
    border-color: #90a4ae;
    color: #fff;
    background-color: #90a4ae; }

.button.is-bluey-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-pale-grey-two:not(.is-outlined) {
  color: #263238;
  background-color: #f2f6f9;
  border-color: #f2f6f9; }
  .button.is-pale-grey-two:not(.is-outlined):focus, .button.is-pale-grey-two:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(242, 246, 249, 0.85);
    border-color: rgba(242, 246, 249, 0.85); }
  .button.is-pale-grey-two:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(242, 246, 249, 0.85);
    border-color: rgba(242, 246, 249, 0.85); }
  .button.is-pale-grey-two:not(.is-outlined):active, .button.is-pale-grey-two:not(.is-outlined).active,
  .open > .button.is-pale-grey-two:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(242, 246, 249, 0.85);
    border-color: rgba(242, 246, 249, 0.85); }
    .button.is-pale-grey-two:not(.is-outlined):active:hover, .button.is-pale-grey-two:not(.is-outlined):active:focus, .button.is-pale-grey-two:not(.is-outlined):active.focus, .button.is-pale-grey-two:not(.is-outlined).active:hover, .button.is-pale-grey-two:not(.is-outlined).active:focus, .button.is-pale-grey-two:not(.is-outlined).active.focus,
    .open > .button.is-pale-grey-two:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-pale-grey-two:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-pale-grey-two:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(242, 246, 249, 0.75);
      border-color: rgba(242, 246, 249, 0.75); }
  .button.is-pale-grey-two:not(.is-outlined):active, .button.is-pale-grey-two:not(.is-outlined).active,
  .open > .button.is-pale-grey-two:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-pale-grey-two:not(.is-outlined).disabled:hover, .button.is-pale-grey-two:not(.is-outlined).disabled:focus, .button.is-pale-grey-two:not(.is-outlined).disabled.focus, .button.is-pale-grey-two:not(.is-outlined)[disabled]:hover, .button.is-pale-grey-two:not(.is-outlined)[disabled]:focus, .button.is-pale-grey-two:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-pale-grey-two:not(.is-outlined):hover,
  fieldset[disabled] .button.is-pale-grey-two:not(.is-outlined):focus,
  fieldset[disabled] .button.is-pale-grey-two:not(.is-outlined).focus {
    background-color: #f2f6f9;
    border-color: #f2f6f9; }
  .button.is-pale-grey-two:not(.is-outlined) .badge {
    color: #f2f6f9;
    background-color: #263238; }

.button.is-inverted.is-pale-grey-two:not(.is-outlined) {
  color: #f2f6f9;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-pale-grey-two:not(.is-outlined):focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined).focus {
    color: #f2f6f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-pale-grey-two:not(.is-outlined):hover {
    color: #f2f6f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-pale-grey-two:not(.is-outlined):active, .button.is-inverted.is-pale-grey-two:not(.is-outlined).active,
  .open > .button.is-inverted.is-pale-grey-two:not(.is-outlined).dropdown-toggle {
    color: #f2f6f9;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-pale-grey-two:not(.is-outlined):active:hover, .button.is-inverted.is-pale-grey-two:not(.is-outlined):active:focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined):active.focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined).active:hover, .button.is-inverted.is-pale-grey-two:not(.is-outlined).active:focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-pale-grey-two:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-pale-grey-two:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-pale-grey-two:not(.is-outlined).dropdown-toggle.focus {
      color: #f2f6f9;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-pale-grey-two:not(.is-outlined):active, .button.is-inverted.is-pale-grey-two:not(.is-outlined).active,
  .open > .button.is-inverted.is-pale-grey-two:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-pale-grey-two:not(.is-outlined).disabled:hover, .button.is-inverted.is-pale-grey-two:not(.is-outlined).disabled:focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined).disabled.focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-pale-grey-two:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-pale-grey-two:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-pale-grey-two:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-pale-grey-two:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-pale-grey-two:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-pale-grey-two:not(.is-outlined) .badge {
    color: #263238;
    background-color: #f2f6f9; }

.button.is-outlined.is-pale-grey-two {
  border-color: #f2f6f9;
  color: #f2f6f9;
  background: none; }
  .button.is-outlined.is-pale-grey-two:hover, .button.is-outlined.is-pale-grey-two:active, .button.is-outlined.is-pale-grey-two:active:hover, .button.is-outlined.is-pale-grey-two:active:focus, .button.is-outlined.is-pale-grey-two:focus, .button.is-outlined.is-pale-grey-two.active {
    border-color: #f2f6f9;
    color: #263238;
    background-color: #f2f6f9; }

.button.is-pale-grey-two.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-pale-grey-three:not(.is-outlined) {
  color: #263238;
  background-color: #e5eef5;
  border-color: #e5eef5; }
  .button.is-pale-grey-three:not(.is-outlined):focus, .button.is-pale-grey-three:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(229, 238, 245, 0.85);
    border-color: rgba(229, 238, 245, 0.85); }
  .button.is-pale-grey-three:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(229, 238, 245, 0.85);
    border-color: rgba(229, 238, 245, 0.85); }
  .button.is-pale-grey-three:not(.is-outlined):active, .button.is-pale-grey-three:not(.is-outlined).active,
  .open > .button.is-pale-grey-three:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(229, 238, 245, 0.85);
    border-color: rgba(229, 238, 245, 0.85); }
    .button.is-pale-grey-three:not(.is-outlined):active:hover, .button.is-pale-grey-three:not(.is-outlined):active:focus, .button.is-pale-grey-three:not(.is-outlined):active.focus, .button.is-pale-grey-three:not(.is-outlined).active:hover, .button.is-pale-grey-three:not(.is-outlined).active:focus, .button.is-pale-grey-three:not(.is-outlined).active.focus,
    .open > .button.is-pale-grey-three:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-pale-grey-three:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-pale-grey-three:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(229, 238, 245, 0.75);
      border-color: rgba(229, 238, 245, 0.75); }
  .button.is-pale-grey-three:not(.is-outlined):active, .button.is-pale-grey-three:not(.is-outlined).active,
  .open > .button.is-pale-grey-three:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-pale-grey-three:not(.is-outlined).disabled:hover, .button.is-pale-grey-three:not(.is-outlined).disabled:focus, .button.is-pale-grey-three:not(.is-outlined).disabled.focus, .button.is-pale-grey-three:not(.is-outlined)[disabled]:hover, .button.is-pale-grey-three:not(.is-outlined)[disabled]:focus, .button.is-pale-grey-three:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-pale-grey-three:not(.is-outlined):hover,
  fieldset[disabled] .button.is-pale-grey-three:not(.is-outlined):focus,
  fieldset[disabled] .button.is-pale-grey-three:not(.is-outlined).focus {
    background-color: #e5eef5;
    border-color: #e5eef5; }
  .button.is-pale-grey-three:not(.is-outlined) .badge {
    color: #e5eef5;
    background-color: #263238; }

.button.is-inverted.is-pale-grey-three:not(.is-outlined) {
  color: #e5eef5;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-pale-grey-three:not(.is-outlined):focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined).focus {
    color: #e5eef5;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-pale-grey-three:not(.is-outlined):hover {
    color: #e5eef5;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-pale-grey-three:not(.is-outlined):active, .button.is-inverted.is-pale-grey-three:not(.is-outlined).active,
  .open > .button.is-inverted.is-pale-grey-three:not(.is-outlined).dropdown-toggle {
    color: #e5eef5;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-pale-grey-three:not(.is-outlined):active:hover, .button.is-inverted.is-pale-grey-three:not(.is-outlined):active:focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined):active.focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined).active:hover, .button.is-inverted.is-pale-grey-three:not(.is-outlined).active:focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-pale-grey-three:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-pale-grey-three:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-pale-grey-three:not(.is-outlined).dropdown-toggle.focus {
      color: #e5eef5;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-pale-grey-three:not(.is-outlined):active, .button.is-inverted.is-pale-grey-three:not(.is-outlined).active,
  .open > .button.is-inverted.is-pale-grey-three:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-pale-grey-three:not(.is-outlined).disabled:hover, .button.is-inverted.is-pale-grey-three:not(.is-outlined).disabled:focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined).disabled.focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-pale-grey-three:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-pale-grey-three:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-pale-grey-three:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-pale-grey-three:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-pale-grey-three:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-pale-grey-three:not(.is-outlined) .badge {
    color: #263238;
    background-color: #e5eef5; }

.button.is-outlined.is-pale-grey-three {
  border-color: #e5eef5;
  color: #e5eef5;
  background: none; }
  .button.is-outlined.is-pale-grey-three:hover, .button.is-outlined.is-pale-grey-three:active, .button.is-outlined.is-pale-grey-three:active:hover, .button.is-outlined.is-pale-grey-three:active:focus, .button.is-outlined.is-pale-grey-three:focus, .button.is-outlined.is-pale-grey-three.active {
    border-color: #e5eef5;
    color: #263238;
    background-color: #e5eef5; }

.button.is-pale-grey-three.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-light-blue-grey-three:not(.is-outlined) {
  color: #263238;
  background-color: #c5d9e8;
  border-color: #c5d9e8; }
  .button.is-light-blue-grey-three:not(.is-outlined):focus, .button.is-light-blue-grey-three:not(.is-outlined).focus {
    color: #263238;
    background-color: rgba(197, 217, 232, 0.85);
    border-color: rgba(197, 217, 232, 0.85); }
  .button.is-light-blue-grey-three:not(.is-outlined):hover {
    color: #263238;
    background-color: rgba(197, 217, 232, 0.85);
    border-color: rgba(197, 217, 232, 0.85); }
  .button.is-light-blue-grey-three:not(.is-outlined):active, .button.is-light-blue-grey-three:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle {
    color: #263238;
    background-color: rgba(197, 217, 232, 0.85);
    border-color: rgba(197, 217, 232, 0.85); }
    .button.is-light-blue-grey-three:not(.is-outlined):active:hover, .button.is-light-blue-grey-three:not(.is-outlined):active:focus, .button.is-light-blue-grey-three:not(.is-outlined):active.focus, .button.is-light-blue-grey-three:not(.is-outlined).active:hover, .button.is-light-blue-grey-three:not(.is-outlined).active:focus, .button.is-light-blue-grey-three:not(.is-outlined).active.focus,
    .open > .button.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle.focus {
      color: #263238;
      background-color: rgba(197, 217, 232, 0.75);
      border-color: rgba(197, 217, 232, 0.75); }
  .button.is-light-blue-grey-three:not(.is-outlined):active, .button.is-light-blue-grey-three:not(.is-outlined).active,
  .open > .button.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-light-blue-grey-three:not(.is-outlined).disabled:hover, .button.is-light-blue-grey-three:not(.is-outlined).disabled:focus, .button.is-light-blue-grey-three:not(.is-outlined).disabled.focus, .button.is-light-blue-grey-three:not(.is-outlined)[disabled]:hover, .button.is-light-blue-grey-three:not(.is-outlined)[disabled]:focus, .button.is-light-blue-grey-three:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-light-blue-grey-three:not(.is-outlined):hover,
  fieldset[disabled] .button.is-light-blue-grey-three:not(.is-outlined):focus,
  fieldset[disabled] .button.is-light-blue-grey-three:not(.is-outlined).focus {
    background-color: #c5d9e8;
    border-color: #c5d9e8; }
  .button.is-light-blue-grey-three:not(.is-outlined) .badge {
    color: #c5d9e8;
    background-color: #263238; }

.button.is-inverted.is-light-blue-grey-three:not(.is-outlined) {
  color: #c5d9e8;
  background-color: #263238;
  border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).focus {
    color: #c5d9e8;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):hover {
    color: #c5d9e8;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
  .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle {
    color: #c5d9e8;
    background-color: rgba(38, 50, 56, 0.85);
    border-color: rgba(38, 50, 56, 0.85); }
    .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):active:hover, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):active:focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):active.focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).active:hover, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).active:focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle.focus {
      color: #c5d9e8;
      background-color: rgba(38, 50, 56, 0.75);
      border-color: rgba(38, 50, 56, 0.75); }
  .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):active, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).active,
  .open > .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).disabled:hover, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).disabled:focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).disabled.focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-light-blue-grey-three:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-three:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-light-blue-grey-three:not(.is-outlined).focus {
    background-color: #263238;
    border-color: #263238; }
  .button.is-inverted.is-light-blue-grey-three:not(.is-outlined) .badge {
    color: #263238;
    background-color: #c5d9e8; }

.button.is-outlined.is-light-blue-grey-three {
  border-color: #c5d9e8;
  color: #c5d9e8;
  background: none; }
  .button.is-outlined.is-light-blue-grey-three:hover, .button.is-outlined.is-light-blue-grey-three:active, .button.is-outlined.is-light-blue-grey-three:active:hover, .button.is-outlined.is-light-blue-grey-three:active:focus, .button.is-outlined.is-light-blue-grey-three:focus, .button.is-outlined.is-light-blue-grey-three.active {
    border-color: #c5d9e8;
    color: #263238;
    background-color: #c5d9e8; }

.button.is-light-blue-grey-three.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-charcoal-grey-60:not(.is-outlined) {
  color: #fff;
  background-color: rgba(55, 71, 79, 0.6);
  border-color: rgba(55, 71, 79, 0.6); }
  .button.is-charcoal-grey-60:not(.is-outlined):focus, .button.is-charcoal-grey-60:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
  .button.is-charcoal-grey-60:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
  .button.is-charcoal-grey-60:not(.is-outlined):active, .button.is-charcoal-grey-60:not(.is-outlined).active,
  .open > .button.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
    .button.is-charcoal-grey-60:not(.is-outlined):active:hover, .button.is-charcoal-grey-60:not(.is-outlined):active:focus, .button.is-charcoal-grey-60:not(.is-outlined):active.focus, .button.is-charcoal-grey-60:not(.is-outlined).active:hover, .button.is-charcoal-grey-60:not(.is-outlined).active:focus, .button.is-charcoal-grey-60:not(.is-outlined).active.focus,
    .open > .button.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(55, 71, 79, 0.75);
      border-color: rgba(55, 71, 79, 0.75); }
  .button.is-charcoal-grey-60:not(.is-outlined):active, .button.is-charcoal-grey-60:not(.is-outlined).active,
  .open > .button.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-charcoal-grey-60:not(.is-outlined).disabled:hover, .button.is-charcoal-grey-60:not(.is-outlined).disabled:focus, .button.is-charcoal-grey-60:not(.is-outlined).disabled.focus, .button.is-charcoal-grey-60:not(.is-outlined)[disabled]:hover, .button.is-charcoal-grey-60:not(.is-outlined)[disabled]:focus, .button.is-charcoal-grey-60:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-charcoal-grey-60:not(.is-outlined):hover,
  fieldset[disabled] .button.is-charcoal-grey-60:not(.is-outlined):focus,
  fieldset[disabled] .button.is-charcoal-grey-60:not(.is-outlined).focus {
    background-color: rgba(55, 71, 79, 0.6);
    border-color: rgba(55, 71, 79, 0.6); }
  .button.is-charcoal-grey-60:not(.is-outlined) .badge {
    color: rgba(55, 71, 79, 0.6);
    background-color: #fff; }

.button.is-inverted.is-charcoal-grey-60:not(.is-outlined) {
  color: rgba(55, 71, 79, 0.6);
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).focus {
    color: rgba(55, 71, 79, 0.6);
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):hover {
    color: rgba(55, 71, 79, 0.6);
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):active, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).active,
  .open > .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle {
    color: rgba(55, 71, 79, 0.6);
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):active:hover, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):active:focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):active.focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).active:hover, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).active:focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(55, 71, 79, 0.6);
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):active, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).active,
  .open > .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).disabled:hover, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).disabled:focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).disabled.focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-charcoal-grey-60:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey-60:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey-60:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-charcoal-grey-60:not(.is-outlined) .badge {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.6); }

.button.is-outlined.is-charcoal-grey-60 {
  border-color: rgba(55, 71, 79, 0.6);
  color: rgba(55, 71, 79, 0.6);
  background: none; }
  .button.is-outlined.is-charcoal-grey-60:hover, .button.is-outlined.is-charcoal-grey-60:active, .button.is-outlined.is-charcoal-grey-60:active:hover, .button.is-outlined.is-charcoal-grey-60:active:focus, .button.is-outlined.is-charcoal-grey-60:focus, .button.is-outlined.is-charcoal-grey-60.active {
    border-color: rgba(55, 71, 79, 0.6);
    color: #fff;
    background-color: rgba(55, 71, 79, 0.6); }

.button.is-charcoal-grey-60.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-charcoal-grey-80:not(.is-outlined) {
  color: #fff;
  background-color: rgba(55, 71, 79, 0.8);
  border-color: rgba(55, 71, 79, 0.8); }
  .button.is-charcoal-grey-80:not(.is-outlined):focus, .button.is-charcoal-grey-80:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
  .button.is-charcoal-grey-80:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
  .button.is-charcoal-grey-80:not(.is-outlined):active, .button.is-charcoal-grey-80:not(.is-outlined).active,
  .open > .button.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.85);
    border-color: rgba(55, 71, 79, 0.85); }
    .button.is-charcoal-grey-80:not(.is-outlined):active:hover, .button.is-charcoal-grey-80:not(.is-outlined):active:focus, .button.is-charcoal-grey-80:not(.is-outlined):active.focus, .button.is-charcoal-grey-80:not(.is-outlined).active:hover, .button.is-charcoal-grey-80:not(.is-outlined).active:focus, .button.is-charcoal-grey-80:not(.is-outlined).active.focus,
    .open > .button.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(55, 71, 79, 0.75);
      border-color: rgba(55, 71, 79, 0.75); }
  .button.is-charcoal-grey-80:not(.is-outlined):active, .button.is-charcoal-grey-80:not(.is-outlined).active,
  .open > .button.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-charcoal-grey-80:not(.is-outlined).disabled:hover, .button.is-charcoal-grey-80:not(.is-outlined).disabled:focus, .button.is-charcoal-grey-80:not(.is-outlined).disabled.focus, .button.is-charcoal-grey-80:not(.is-outlined)[disabled]:hover, .button.is-charcoal-grey-80:not(.is-outlined)[disabled]:focus, .button.is-charcoal-grey-80:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-charcoal-grey-80:not(.is-outlined):hover,
  fieldset[disabled] .button.is-charcoal-grey-80:not(.is-outlined):focus,
  fieldset[disabled] .button.is-charcoal-grey-80:not(.is-outlined).focus {
    background-color: rgba(55, 71, 79, 0.8);
    border-color: rgba(55, 71, 79, 0.8); }
  .button.is-charcoal-grey-80:not(.is-outlined) .badge {
    color: rgba(55, 71, 79, 0.8);
    background-color: #fff; }

.button.is-inverted.is-charcoal-grey-80:not(.is-outlined) {
  color: rgba(55, 71, 79, 0.8);
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).focus {
    color: rgba(55, 71, 79, 0.8);
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):hover {
    color: rgba(55, 71, 79, 0.8);
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):active, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).active,
  .open > .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle {
    color: rgba(55, 71, 79, 0.8);
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):active:hover, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):active:focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):active.focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).active:hover, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).active:focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(55, 71, 79, 0.8);
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):active, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).active,
  .open > .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).disabled:hover, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).disabled:focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).disabled.focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-charcoal-grey-80:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey-80:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-charcoal-grey-80:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-charcoal-grey-80:not(.is-outlined) .badge {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.8); }

.button.is-outlined.is-charcoal-grey-80 {
  border-color: rgba(55, 71, 79, 0.8);
  color: rgba(55, 71, 79, 0.8);
  background: none; }
  .button.is-outlined.is-charcoal-grey-80:hover, .button.is-outlined.is-charcoal-grey-80:active, .button.is-outlined.is-charcoal-grey-80:active:hover, .button.is-outlined.is-charcoal-grey-80:active:focus, .button.is-outlined.is-charcoal-grey-80:focus, .button.is-outlined.is-charcoal-grey-80.active {
    border-color: rgba(55, 71, 79, 0.8);
    color: #fff;
    background-color: rgba(55, 71, 79, 0.8); }

.button.is-charcoal-grey-80.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-azure:not(.is-outlined) {
  color: #fff;
  background-color: #0093ee;
  border-color: #0093ee; }
  .button.is-azure:not(.is-outlined):focus, .button.is-azure:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(0, 147, 238, 0.85);
    border-color: rgba(0, 147, 238, 0.85); }
  .button.is-azure:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(0, 147, 238, 0.85);
    border-color: rgba(0, 147, 238, 0.85); }
  .button.is-azure:not(.is-outlined):active, .button.is-azure:not(.is-outlined).active,
  .open > .button.is-azure:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 147, 238, 0.85);
    border-color: rgba(0, 147, 238, 0.85); }
    .button.is-azure:not(.is-outlined):active:hover, .button.is-azure:not(.is-outlined):active:focus, .button.is-azure:not(.is-outlined):active.focus, .button.is-azure:not(.is-outlined).active:hover, .button.is-azure:not(.is-outlined).active:focus, .button.is-azure:not(.is-outlined).active.focus,
    .open > .button.is-azure:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-azure:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-azure:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(0, 147, 238, 0.75);
      border-color: rgba(0, 147, 238, 0.75); }
  .button.is-azure:not(.is-outlined):active, .button.is-azure:not(.is-outlined).active,
  .open > .button.is-azure:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-azure:not(.is-outlined).disabled:hover, .button.is-azure:not(.is-outlined).disabled:focus, .button.is-azure:not(.is-outlined).disabled.focus, .button.is-azure:not(.is-outlined)[disabled]:hover, .button.is-azure:not(.is-outlined)[disabled]:focus, .button.is-azure:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-azure:not(.is-outlined):hover,
  fieldset[disabled] .button.is-azure:not(.is-outlined):focus,
  fieldset[disabled] .button.is-azure:not(.is-outlined).focus {
    background-color: #0093ee;
    border-color: #0093ee; }
  .button.is-azure:not(.is-outlined) .badge {
    color: #0093ee;
    background-color: #fff; }

.button.is-inverted.is-azure:not(.is-outlined) {
  color: #0093ee;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-azure:not(.is-outlined):focus, .button.is-inverted.is-azure:not(.is-outlined).focus {
    color: #0093ee;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-azure:not(.is-outlined):hover {
    color: #0093ee;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-azure:not(.is-outlined):active, .button.is-inverted.is-azure:not(.is-outlined).active,
  .open > .button.is-inverted.is-azure:not(.is-outlined).dropdown-toggle {
    color: #0093ee;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-azure:not(.is-outlined):active:hover, .button.is-inverted.is-azure:not(.is-outlined):active:focus, .button.is-inverted.is-azure:not(.is-outlined):active.focus, .button.is-inverted.is-azure:not(.is-outlined).active:hover, .button.is-inverted.is-azure:not(.is-outlined).active:focus, .button.is-inverted.is-azure:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-azure:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-azure:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-azure:not(.is-outlined).dropdown-toggle.focus {
      color: #0093ee;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-azure:not(.is-outlined):active, .button.is-inverted.is-azure:not(.is-outlined).active,
  .open > .button.is-inverted.is-azure:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-azure:not(.is-outlined).disabled:hover, .button.is-inverted.is-azure:not(.is-outlined).disabled:focus, .button.is-inverted.is-azure:not(.is-outlined).disabled.focus, .button.is-inverted.is-azure:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-azure:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-azure:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-azure:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-azure:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-azure:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-azure:not(.is-outlined) .badge {
    color: #fff;
    background-color: #0093ee; }

.button.is-outlined.is-azure {
  border-color: #0093ee;
  color: #0093ee;
  background: none; }
  .button.is-outlined.is-azure:hover, .button.is-outlined.is-azure:active, .button.is-outlined.is-azure:active:hover, .button.is-outlined.is-azure:active:focus, .button.is-outlined.is-azure:focus, .button.is-outlined.is-azure.active {
    border-color: #0093ee;
    color: #fff;
    background-color: #0093ee; }

.button.is-azure.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-dark-sky-blue:not(.is-outlined) {
  color: #fff;
  background-color: #1e88e5;
  border-color: #1e88e5; }
  .button.is-dark-sky-blue:not(.is-outlined):focus, .button.is-dark-sky-blue:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
  .button.is-dark-sky-blue:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
  .button.is-dark-sky-blue:not(.is-outlined):active, .button.is-dark-sky-blue:not(.is-outlined).active,
  .open > .button.is-dark-sky-blue:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
    .button.is-dark-sky-blue:not(.is-outlined):active:hover, .button.is-dark-sky-blue:not(.is-outlined):active:focus, .button.is-dark-sky-blue:not(.is-outlined):active.focus, .button.is-dark-sky-blue:not(.is-outlined).active:hover, .button.is-dark-sky-blue:not(.is-outlined).active:focus, .button.is-dark-sky-blue:not(.is-outlined).active.focus,
    .open > .button.is-dark-sky-blue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-dark-sky-blue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-dark-sky-blue:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(30, 136, 229, 0.75);
      border-color: rgba(30, 136, 229, 0.75); }
  .button.is-dark-sky-blue:not(.is-outlined):active, .button.is-dark-sky-blue:not(.is-outlined).active,
  .open > .button.is-dark-sky-blue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-dark-sky-blue:not(.is-outlined).disabled:hover, .button.is-dark-sky-blue:not(.is-outlined).disabled:focus, .button.is-dark-sky-blue:not(.is-outlined).disabled.focus, .button.is-dark-sky-blue:not(.is-outlined)[disabled]:hover, .button.is-dark-sky-blue:not(.is-outlined)[disabled]:focus, .button.is-dark-sky-blue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-dark-sky-blue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-dark-sky-blue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-dark-sky-blue:not(.is-outlined).focus {
    background-color: #1e88e5;
    border-color: #1e88e5; }
  .button.is-dark-sky-blue:not(.is-outlined) .badge {
    color: #1e88e5;
    background-color: #fff; }

.button.is-inverted.is-dark-sky-blue:not(.is-outlined) {
  color: #1e88e5;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-dark-sky-blue:not(.is-outlined):focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).focus {
    color: #1e88e5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-dark-sky-blue:not(.is-outlined):hover {
    color: #1e88e5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-dark-sky-blue:not(.is-outlined):active, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-sky-blue:not(.is-outlined).dropdown-toggle {
    color: #1e88e5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-dark-sky-blue:not(.is-outlined):active:hover, .button.is-inverted.is-dark-sky-blue:not(.is-outlined):active:focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined):active.focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).active:hover, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).active:focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-dark-sky-blue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-dark-sky-blue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-dark-sky-blue:not(.is-outlined).dropdown-toggle.focus {
      color: #1e88e5;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-dark-sky-blue:not(.is-outlined):active, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).active,
  .open > .button.is-inverted.is-dark-sky-blue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-dark-sky-blue:not(.is-outlined).disabled:hover, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).disabled:focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined).disabled.focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-dark-sky-blue:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-dark-sky-blue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-dark-sky-blue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-dark-sky-blue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-dark-sky-blue:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-dark-sky-blue:not(.is-outlined) .badge {
    color: #fff;
    background-color: #1e88e5; }

.button.is-outlined.is-dark-sky-blue {
  border-color: #1e88e5;
  color: #1e88e5;
  background: none; }
  .button.is-outlined.is-dark-sky-blue:hover, .button.is-outlined.is-dark-sky-blue:active, .button.is-outlined.is-dark-sky-blue:active:hover, .button.is-outlined.is-dark-sky-blue:active:focus, .button.is-outlined.is-dark-sky-blue:focus, .button.is-outlined.is-dark-sky-blue.active {
    border-color: #1e88e5;
    color: #fff;
    background-color: #1e88e5; }

.button.is-dark-sky-blue.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-apple-green:not(.is-outlined) {
  color: #fff;
  background-color: #81c926;
  border-color: #81c926; }
  .button.is-apple-green:not(.is-outlined):focus, .button.is-apple-green:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(129, 201, 38, 0.85);
    border-color: rgba(129, 201, 38, 0.85); }
  .button.is-apple-green:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(129, 201, 38, 0.85);
    border-color: rgba(129, 201, 38, 0.85); }
  .button.is-apple-green:not(.is-outlined):active, .button.is-apple-green:not(.is-outlined).active,
  .open > .button.is-apple-green:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(129, 201, 38, 0.85);
    border-color: rgba(129, 201, 38, 0.85); }
    .button.is-apple-green:not(.is-outlined):active:hover, .button.is-apple-green:not(.is-outlined):active:focus, .button.is-apple-green:not(.is-outlined):active.focus, .button.is-apple-green:not(.is-outlined).active:hover, .button.is-apple-green:not(.is-outlined).active:focus, .button.is-apple-green:not(.is-outlined).active.focus,
    .open > .button.is-apple-green:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-apple-green:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-apple-green:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(129, 201, 38, 0.75);
      border-color: rgba(129, 201, 38, 0.75); }
  .button.is-apple-green:not(.is-outlined):active, .button.is-apple-green:not(.is-outlined).active,
  .open > .button.is-apple-green:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-apple-green:not(.is-outlined).disabled:hover, .button.is-apple-green:not(.is-outlined).disabled:focus, .button.is-apple-green:not(.is-outlined).disabled.focus, .button.is-apple-green:not(.is-outlined)[disabled]:hover, .button.is-apple-green:not(.is-outlined)[disabled]:focus, .button.is-apple-green:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-apple-green:not(.is-outlined):hover,
  fieldset[disabled] .button.is-apple-green:not(.is-outlined):focus,
  fieldset[disabled] .button.is-apple-green:not(.is-outlined).focus {
    background-color: #81c926;
    border-color: #81c926; }
  .button.is-apple-green:not(.is-outlined) .badge {
    color: #81c926;
    background-color: #fff; }

.button.is-inverted.is-apple-green:not(.is-outlined) {
  color: #81c926;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-apple-green:not(.is-outlined):focus, .button.is-inverted.is-apple-green:not(.is-outlined).focus {
    color: #81c926;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-apple-green:not(.is-outlined):hover {
    color: #81c926;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-apple-green:not(.is-outlined):active, .button.is-inverted.is-apple-green:not(.is-outlined).active,
  .open > .button.is-inverted.is-apple-green:not(.is-outlined).dropdown-toggle {
    color: #81c926;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-apple-green:not(.is-outlined):active:hover, .button.is-inverted.is-apple-green:not(.is-outlined):active:focus, .button.is-inverted.is-apple-green:not(.is-outlined):active.focus, .button.is-inverted.is-apple-green:not(.is-outlined).active:hover, .button.is-inverted.is-apple-green:not(.is-outlined).active:focus, .button.is-inverted.is-apple-green:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-apple-green:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-apple-green:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-apple-green:not(.is-outlined).dropdown-toggle.focus {
      color: #81c926;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-apple-green:not(.is-outlined):active, .button.is-inverted.is-apple-green:not(.is-outlined).active,
  .open > .button.is-inverted.is-apple-green:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-apple-green:not(.is-outlined).disabled:hover, .button.is-inverted.is-apple-green:not(.is-outlined).disabled:focus, .button.is-inverted.is-apple-green:not(.is-outlined).disabled.focus, .button.is-inverted.is-apple-green:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-apple-green:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-apple-green:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-apple-green:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-apple-green:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-apple-green:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-apple-green:not(.is-outlined) .badge {
    color: #fff;
    background-color: #81c926; }

.button.is-outlined.is-apple-green {
  border-color: #81c926;
  color: #81c926;
  background: none; }
  .button.is-outlined.is-apple-green:hover, .button.is-outlined.is-apple-green:active, .button.is-outlined.is-apple-green:active:hover, .button.is-outlined.is-apple-green:active:focus, .button.is-outlined.is-apple-green:focus, .button.is-outlined.is-apple-green.active {
    border-color: #81c926;
    color: #fff;
    background-color: #81c926; }

.button.is-apple-green.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-pause-azure:not(.is-outlined) {
  color: #fff;
  background-color: #03a9f4;
  border-color: #03a9f4; }
  .button.is-pause-azure:not(.is-outlined):focus, .button.is-pause-azure:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(3, 169, 244, 0.85);
    border-color: rgba(3, 169, 244, 0.85); }
  .button.is-pause-azure:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(3, 169, 244, 0.85);
    border-color: rgba(3, 169, 244, 0.85); }
  .button.is-pause-azure:not(.is-outlined):active, .button.is-pause-azure:not(.is-outlined).active,
  .open > .button.is-pause-azure:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(3, 169, 244, 0.85);
    border-color: rgba(3, 169, 244, 0.85); }
    .button.is-pause-azure:not(.is-outlined):active:hover, .button.is-pause-azure:not(.is-outlined):active:focus, .button.is-pause-azure:not(.is-outlined):active.focus, .button.is-pause-azure:not(.is-outlined).active:hover, .button.is-pause-azure:not(.is-outlined).active:focus, .button.is-pause-azure:not(.is-outlined).active.focus,
    .open > .button.is-pause-azure:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-pause-azure:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-pause-azure:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(3, 169, 244, 0.75);
      border-color: rgba(3, 169, 244, 0.75); }
  .button.is-pause-azure:not(.is-outlined):active, .button.is-pause-azure:not(.is-outlined).active,
  .open > .button.is-pause-azure:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-pause-azure:not(.is-outlined).disabled:hover, .button.is-pause-azure:not(.is-outlined).disabled:focus, .button.is-pause-azure:not(.is-outlined).disabled.focus, .button.is-pause-azure:not(.is-outlined)[disabled]:hover, .button.is-pause-azure:not(.is-outlined)[disabled]:focus, .button.is-pause-azure:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-pause-azure:not(.is-outlined):hover,
  fieldset[disabled] .button.is-pause-azure:not(.is-outlined):focus,
  fieldset[disabled] .button.is-pause-azure:not(.is-outlined).focus {
    background-color: #03a9f4;
    border-color: #03a9f4; }
  .button.is-pause-azure:not(.is-outlined) .badge {
    color: #03a9f4;
    background-color: #fff; }

.button.is-inverted.is-pause-azure:not(.is-outlined) {
  color: #03a9f4;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-pause-azure:not(.is-outlined):focus, .button.is-inverted.is-pause-azure:not(.is-outlined).focus {
    color: #03a9f4;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-pause-azure:not(.is-outlined):hover {
    color: #03a9f4;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-pause-azure:not(.is-outlined):active, .button.is-inverted.is-pause-azure:not(.is-outlined).active,
  .open > .button.is-inverted.is-pause-azure:not(.is-outlined).dropdown-toggle {
    color: #03a9f4;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-pause-azure:not(.is-outlined):active:hover, .button.is-inverted.is-pause-azure:not(.is-outlined):active:focus, .button.is-inverted.is-pause-azure:not(.is-outlined):active.focus, .button.is-inverted.is-pause-azure:not(.is-outlined).active:hover, .button.is-inverted.is-pause-azure:not(.is-outlined).active:focus, .button.is-inverted.is-pause-azure:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-pause-azure:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-pause-azure:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-pause-azure:not(.is-outlined).dropdown-toggle.focus {
      color: #03a9f4;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-pause-azure:not(.is-outlined):active, .button.is-inverted.is-pause-azure:not(.is-outlined).active,
  .open > .button.is-inverted.is-pause-azure:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-pause-azure:not(.is-outlined).disabled:hover, .button.is-inverted.is-pause-azure:not(.is-outlined).disabled:focus, .button.is-inverted.is-pause-azure:not(.is-outlined).disabled.focus, .button.is-inverted.is-pause-azure:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-pause-azure:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-pause-azure:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-pause-azure:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-pause-azure:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-pause-azure:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-pause-azure:not(.is-outlined) .badge {
    color: #fff;
    background-color: #03a9f4; }

.button.is-outlined.is-pause-azure {
  border-color: #03a9f4;
  color: #03a9f4;
  background: none; }
  .button.is-outlined.is-pause-azure:hover, .button.is-outlined.is-pause-azure:active, .button.is-outlined.is-pause-azure:active:hover, .button.is-outlined.is-pause-azure:active:focus, .button.is-outlined.is-pause-azure:focus, .button.is-outlined.is-pause-azure.active {
    border-color: #03a9f4;
    color: #fff;
    background-color: #03a9f4; }

.button.is-pause-azure.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-production-blue:not(.is-outlined) {
  color: #fff;
  background-color: #2979ff;
  border-color: #2979ff; }
  .button.is-production-blue:not(.is-outlined):focus, .button.is-production-blue:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
  .button.is-production-blue:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
  .button.is-production-blue:not(.is-outlined):active, .button.is-production-blue:not(.is-outlined).active,
  .open > .button.is-production-blue:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
    .button.is-production-blue:not(.is-outlined):active:hover, .button.is-production-blue:not(.is-outlined):active:focus, .button.is-production-blue:not(.is-outlined):active.focus, .button.is-production-blue:not(.is-outlined).active:hover, .button.is-production-blue:not(.is-outlined).active:focus, .button.is-production-blue:not(.is-outlined).active.focus,
    .open > .button.is-production-blue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-production-blue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-production-blue:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(41, 121, 255, 0.75);
      border-color: rgba(41, 121, 255, 0.75); }
  .button.is-production-blue:not(.is-outlined):active, .button.is-production-blue:not(.is-outlined).active,
  .open > .button.is-production-blue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-production-blue:not(.is-outlined).disabled:hover, .button.is-production-blue:not(.is-outlined).disabled:focus, .button.is-production-blue:not(.is-outlined).disabled.focus, .button.is-production-blue:not(.is-outlined)[disabled]:hover, .button.is-production-blue:not(.is-outlined)[disabled]:focus, .button.is-production-blue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-production-blue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-production-blue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-production-blue:not(.is-outlined).focus {
    background-color: #2979ff;
    border-color: #2979ff; }
  .button.is-production-blue:not(.is-outlined) .badge {
    color: #2979ff;
    background-color: #fff; }

.button.is-inverted.is-production-blue:not(.is-outlined) {
  color: #2979ff;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-production-blue:not(.is-outlined):focus, .button.is-inverted.is-production-blue:not(.is-outlined).focus {
    color: #2979ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-production-blue:not(.is-outlined):hover {
    color: #2979ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-production-blue:not(.is-outlined):active, .button.is-inverted.is-production-blue:not(.is-outlined).active,
  .open > .button.is-inverted.is-production-blue:not(.is-outlined).dropdown-toggle {
    color: #2979ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-production-blue:not(.is-outlined):active:hover, .button.is-inverted.is-production-blue:not(.is-outlined):active:focus, .button.is-inverted.is-production-blue:not(.is-outlined):active.focus, .button.is-inverted.is-production-blue:not(.is-outlined).active:hover, .button.is-inverted.is-production-blue:not(.is-outlined).active:focus, .button.is-inverted.is-production-blue:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-production-blue:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-production-blue:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-production-blue:not(.is-outlined).dropdown-toggle.focus {
      color: #2979ff;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-production-blue:not(.is-outlined):active, .button.is-inverted.is-production-blue:not(.is-outlined).active,
  .open > .button.is-inverted.is-production-blue:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-production-blue:not(.is-outlined).disabled:hover, .button.is-inverted.is-production-blue:not(.is-outlined).disabled:focus, .button.is-inverted.is-production-blue:not(.is-outlined).disabled.focus, .button.is-inverted.is-production-blue:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-production-blue:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-production-blue:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-production-blue:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-production-blue:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-production-blue:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-production-blue:not(.is-outlined) .badge {
    color: #fff;
    background-color: #2979ff; }

.button.is-outlined.is-production-blue {
  border-color: #2979ff;
  color: #2979ff;
  background: none; }
  .button.is-outlined.is-production-blue:hover, .button.is-outlined.is-production-blue:active, .button.is-outlined.is-production-blue:active:hover, .button.is-outlined.is-production-blue:active:focus, .button.is-outlined.is-production-blue:focus, .button.is-outlined.is-production-blue.active {
    border-color: #2979ff;
    color: #fff;
    background-color: #2979ff; }

.button.is-production-blue.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-production-blue-20:not(.is-outlined) {
  color: #1e88e5;
  background-color: rgba(41, 121, 255, 0.2);
  border-color: rgba(41, 121, 255, 0.2); }
  .button.is-production-blue-20:not(.is-outlined):focus, .button.is-production-blue-20:not(.is-outlined).focus {
    color: #1e88e5;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
  .button.is-production-blue-20:not(.is-outlined):hover {
    color: #1e88e5;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
  .button.is-production-blue-20:not(.is-outlined):active, .button.is-production-blue-20:not(.is-outlined).active,
  .open > .button.is-production-blue-20:not(.is-outlined).dropdown-toggle {
    color: #1e88e5;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
    .button.is-production-blue-20:not(.is-outlined):active:hover, .button.is-production-blue-20:not(.is-outlined):active:focus, .button.is-production-blue-20:not(.is-outlined):active.focus, .button.is-production-blue-20:not(.is-outlined).active:hover, .button.is-production-blue-20:not(.is-outlined).active:focus, .button.is-production-blue-20:not(.is-outlined).active.focus,
    .open > .button.is-production-blue-20:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-production-blue-20:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-production-blue-20:not(.is-outlined).dropdown-toggle.focus {
      color: #1e88e5;
      background-color: rgba(41, 121, 255, 0.75);
      border-color: rgba(41, 121, 255, 0.75); }
  .button.is-production-blue-20:not(.is-outlined):active, .button.is-production-blue-20:not(.is-outlined).active,
  .open > .button.is-production-blue-20:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-production-blue-20:not(.is-outlined).disabled:hover, .button.is-production-blue-20:not(.is-outlined).disabled:focus, .button.is-production-blue-20:not(.is-outlined).disabled.focus, .button.is-production-blue-20:not(.is-outlined)[disabled]:hover, .button.is-production-blue-20:not(.is-outlined)[disabled]:focus, .button.is-production-blue-20:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-production-blue-20:not(.is-outlined):hover,
  fieldset[disabled] .button.is-production-blue-20:not(.is-outlined):focus,
  fieldset[disabled] .button.is-production-blue-20:not(.is-outlined).focus {
    background-color: rgba(41, 121, 255, 0.2);
    border-color: rgba(41, 121, 255, 0.2); }
  .button.is-production-blue-20:not(.is-outlined) .badge {
    color: rgba(41, 121, 255, 0.2);
    background-color: #1e88e5; }

.button.is-inverted.is-production-blue-20:not(.is-outlined) {
  color: rgba(41, 121, 255, 0.2);
  background-color: #1e88e5;
  border-color: #1e88e5; }
  .button.is-inverted.is-production-blue-20:not(.is-outlined):focus, .button.is-inverted.is-production-blue-20:not(.is-outlined).focus {
    color: rgba(41, 121, 255, 0.2);
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
  .button.is-inverted.is-production-blue-20:not(.is-outlined):hover {
    color: rgba(41, 121, 255, 0.2);
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
  .button.is-inverted.is-production-blue-20:not(.is-outlined):active, .button.is-inverted.is-production-blue-20:not(.is-outlined).active,
  .open > .button.is-inverted.is-production-blue-20:not(.is-outlined).dropdown-toggle {
    color: rgba(41, 121, 255, 0.2);
    background-color: rgba(30, 136, 229, 0.85);
    border-color: rgba(30, 136, 229, 0.85); }
    .button.is-inverted.is-production-blue-20:not(.is-outlined):active:hover, .button.is-inverted.is-production-blue-20:not(.is-outlined):active:focus, .button.is-inverted.is-production-blue-20:not(.is-outlined):active.focus, .button.is-inverted.is-production-blue-20:not(.is-outlined).active:hover, .button.is-inverted.is-production-blue-20:not(.is-outlined).active:focus, .button.is-inverted.is-production-blue-20:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-production-blue-20:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-production-blue-20:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-production-blue-20:not(.is-outlined).dropdown-toggle.focus {
      color: rgba(41, 121, 255, 0.2);
      background-color: rgba(30, 136, 229, 0.75);
      border-color: rgba(30, 136, 229, 0.75); }
  .button.is-inverted.is-production-blue-20:not(.is-outlined):active, .button.is-inverted.is-production-blue-20:not(.is-outlined).active,
  .open > .button.is-inverted.is-production-blue-20:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-production-blue-20:not(.is-outlined).disabled:hover, .button.is-inverted.is-production-blue-20:not(.is-outlined).disabled:focus, .button.is-inverted.is-production-blue-20:not(.is-outlined).disabled.focus, .button.is-inverted.is-production-blue-20:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-production-blue-20:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-production-blue-20:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-production-blue-20:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-production-blue-20:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-production-blue-20:not(.is-outlined).focus {
    background-color: #1e88e5;
    border-color: #1e88e5; }
  .button.is-inverted.is-production-blue-20:not(.is-outlined) .badge {
    color: #1e88e5;
    background-color: rgba(41, 121, 255, 0.2); }

.button.is-outlined.is-production-blue-20 {
  border-color: rgba(41, 121, 255, 0.2);
  color: rgba(41, 121, 255, 0.2);
  background: none; }
  .button.is-outlined.is-production-blue-20:hover, .button.is-outlined.is-production-blue-20:active, .button.is-outlined.is-production-blue-20:active:hover, .button.is-outlined.is-production-blue-20:active:focus, .button.is-outlined.is-production-blue-20:focus, .button.is-outlined.is-production-blue-20.active {
    border-color: rgba(41, 121, 255, 0.2);
    color: #1e88e5;
    background-color: rgba(41, 121, 255, 0.2); }

.button.is-production-blue-20.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-material-violet:not(.is-outlined) {
  color: #fff;
  background-color: #aa00ff;
  border-color: #aa00ff; }
  .button.is-material-violet:not(.is-outlined):focus, .button.is-material-violet:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(170, 0, 255, 0.85);
    border-color: rgba(170, 0, 255, 0.85); }
  .button.is-material-violet:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(170, 0, 255, 0.85);
    border-color: rgba(170, 0, 255, 0.85); }
  .button.is-material-violet:not(.is-outlined):active, .button.is-material-violet:not(.is-outlined).active,
  .open > .button.is-material-violet:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(170, 0, 255, 0.85);
    border-color: rgba(170, 0, 255, 0.85); }
    .button.is-material-violet:not(.is-outlined):active:hover, .button.is-material-violet:not(.is-outlined):active:focus, .button.is-material-violet:not(.is-outlined):active.focus, .button.is-material-violet:not(.is-outlined).active:hover, .button.is-material-violet:not(.is-outlined).active:focus, .button.is-material-violet:not(.is-outlined).active.focus,
    .open > .button.is-material-violet:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-material-violet:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-material-violet:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(170, 0, 255, 0.75);
      border-color: rgba(170, 0, 255, 0.75); }
  .button.is-material-violet:not(.is-outlined):active, .button.is-material-violet:not(.is-outlined).active,
  .open > .button.is-material-violet:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-material-violet:not(.is-outlined).disabled:hover, .button.is-material-violet:not(.is-outlined).disabled:focus, .button.is-material-violet:not(.is-outlined).disabled.focus, .button.is-material-violet:not(.is-outlined)[disabled]:hover, .button.is-material-violet:not(.is-outlined)[disabled]:focus, .button.is-material-violet:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-material-violet:not(.is-outlined):hover,
  fieldset[disabled] .button.is-material-violet:not(.is-outlined):focus,
  fieldset[disabled] .button.is-material-violet:not(.is-outlined).focus {
    background-color: #aa00ff;
    border-color: #aa00ff; }
  .button.is-material-violet:not(.is-outlined) .badge {
    color: #aa00ff;
    background-color: #fff; }

.button.is-inverted.is-material-violet:not(.is-outlined) {
  color: #aa00ff;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-material-violet:not(.is-outlined):focus, .button.is-inverted.is-material-violet:not(.is-outlined).focus {
    color: #aa00ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-material-violet:not(.is-outlined):hover {
    color: #aa00ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-material-violet:not(.is-outlined):active, .button.is-inverted.is-material-violet:not(.is-outlined).active,
  .open > .button.is-inverted.is-material-violet:not(.is-outlined).dropdown-toggle {
    color: #aa00ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-material-violet:not(.is-outlined):active:hover, .button.is-inverted.is-material-violet:not(.is-outlined):active:focus, .button.is-inverted.is-material-violet:not(.is-outlined):active.focus, .button.is-inverted.is-material-violet:not(.is-outlined).active:hover, .button.is-inverted.is-material-violet:not(.is-outlined).active:focus, .button.is-inverted.is-material-violet:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-material-violet:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-material-violet:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-material-violet:not(.is-outlined).dropdown-toggle.focus {
      color: #aa00ff;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-material-violet:not(.is-outlined):active, .button.is-inverted.is-material-violet:not(.is-outlined).active,
  .open > .button.is-inverted.is-material-violet:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-material-violet:not(.is-outlined).disabled:hover, .button.is-inverted.is-material-violet:not(.is-outlined).disabled:focus, .button.is-inverted.is-material-violet:not(.is-outlined).disabled.focus, .button.is-inverted.is-material-violet:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-material-violet:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-material-violet:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-material-violet:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-material-violet:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-material-violet:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-material-violet:not(.is-outlined) .badge {
    color: #fff;
    background-color: #aa00ff; }

.button.is-outlined.is-material-violet {
  border-color: #aa00ff;
  color: #aa00ff;
  background: none; }
  .button.is-outlined.is-material-violet:hover, .button.is-outlined.is-material-violet:active, .button.is-outlined.is-material-violet:active:hover, .button.is-outlined.is-material-violet:active:focus, .button.is-outlined.is-material-violet:focus, .button.is-outlined.is-material-violet.active {
    border-color: #aa00ff;
    color: #fff;
    background-color: #aa00ff; }

.button.is-material-violet.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-setup-yellow:not(.is-outlined) {
  color: #fff;
  background-color: #ffab00;
  border-color: #ffab00; }
  .button.is-setup-yellow:not(.is-outlined):focus, .button.is-setup-yellow:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(255, 171, 0, 0.85);
    border-color: rgba(255, 171, 0, 0.85); }
  .button.is-setup-yellow:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(255, 171, 0, 0.85);
    border-color: rgba(255, 171, 0, 0.85); }
  .button.is-setup-yellow:not(.is-outlined):active, .button.is-setup-yellow:not(.is-outlined).active,
  .open > .button.is-setup-yellow:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(255, 171, 0, 0.85);
    border-color: rgba(255, 171, 0, 0.85); }
    .button.is-setup-yellow:not(.is-outlined):active:hover, .button.is-setup-yellow:not(.is-outlined):active:focus, .button.is-setup-yellow:not(.is-outlined):active.focus, .button.is-setup-yellow:not(.is-outlined).active:hover, .button.is-setup-yellow:not(.is-outlined).active:focus, .button.is-setup-yellow:not(.is-outlined).active.focus,
    .open > .button.is-setup-yellow:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-setup-yellow:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-setup-yellow:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(255, 171, 0, 0.75);
      border-color: rgba(255, 171, 0, 0.75); }
  .button.is-setup-yellow:not(.is-outlined):active, .button.is-setup-yellow:not(.is-outlined).active,
  .open > .button.is-setup-yellow:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-setup-yellow:not(.is-outlined).disabled:hover, .button.is-setup-yellow:not(.is-outlined).disabled:focus, .button.is-setup-yellow:not(.is-outlined).disabled.focus, .button.is-setup-yellow:not(.is-outlined)[disabled]:hover, .button.is-setup-yellow:not(.is-outlined)[disabled]:focus, .button.is-setup-yellow:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-setup-yellow:not(.is-outlined):hover,
  fieldset[disabled] .button.is-setup-yellow:not(.is-outlined):focus,
  fieldset[disabled] .button.is-setup-yellow:not(.is-outlined).focus {
    background-color: #ffab00;
    border-color: #ffab00; }
  .button.is-setup-yellow:not(.is-outlined) .badge {
    color: #ffab00;
    background-color: #fff; }

.button.is-inverted.is-setup-yellow:not(.is-outlined) {
  color: #ffab00;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-setup-yellow:not(.is-outlined):focus, .button.is-inverted.is-setup-yellow:not(.is-outlined).focus {
    color: #ffab00;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-setup-yellow:not(.is-outlined):hover {
    color: #ffab00;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-setup-yellow:not(.is-outlined):active, .button.is-inverted.is-setup-yellow:not(.is-outlined).active,
  .open > .button.is-inverted.is-setup-yellow:not(.is-outlined).dropdown-toggle {
    color: #ffab00;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-setup-yellow:not(.is-outlined):active:hover, .button.is-inverted.is-setup-yellow:not(.is-outlined):active:focus, .button.is-inverted.is-setup-yellow:not(.is-outlined):active.focus, .button.is-inverted.is-setup-yellow:not(.is-outlined).active:hover, .button.is-inverted.is-setup-yellow:not(.is-outlined).active:focus, .button.is-inverted.is-setup-yellow:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-setup-yellow:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-setup-yellow:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-setup-yellow:not(.is-outlined).dropdown-toggle.focus {
      color: #ffab00;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-setup-yellow:not(.is-outlined):active, .button.is-inverted.is-setup-yellow:not(.is-outlined).active,
  .open > .button.is-inverted.is-setup-yellow:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-setup-yellow:not(.is-outlined).disabled:hover, .button.is-inverted.is-setup-yellow:not(.is-outlined).disabled:focus, .button.is-inverted.is-setup-yellow:not(.is-outlined).disabled.focus, .button.is-inverted.is-setup-yellow:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-setup-yellow:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-setup-yellow:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-setup-yellow:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-setup-yellow:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-setup-yellow:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-setup-yellow:not(.is-outlined) .badge {
    color: #fff;
    background-color: #ffab00; }

.button.is-outlined.is-setup-yellow {
  border-color: #ffab00;
  color: #ffab00;
  background: none; }
  .button.is-outlined.is-setup-yellow:hover, .button.is-outlined.is-setup-yellow:active, .button.is-outlined.is-setup-yellow:active:hover, .button.is-outlined.is-setup-yellow:active:focus, .button.is-outlined.is-setup-yellow:focus, .button.is-outlined.is-setup-yellow.active {
    border-color: #ffab00;
    color: #fff;
    background-color: #ffab00; }

.button.is-setup-yellow.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-check-green:not(.is-outlined) {
  color: #fff;
  background-color: #00c853;
  border-color: #00c853; }
  .button.is-check-green:not(.is-outlined):focus, .button.is-check-green:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(0, 200, 83, 0.85);
    border-color: rgba(0, 200, 83, 0.85); }
  .button.is-check-green:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(0, 200, 83, 0.85);
    border-color: rgba(0, 200, 83, 0.85); }
  .button.is-check-green:not(.is-outlined):active, .button.is-check-green:not(.is-outlined).active,
  .open > .button.is-check-green:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 200, 83, 0.85);
    border-color: rgba(0, 200, 83, 0.85); }
    .button.is-check-green:not(.is-outlined):active:hover, .button.is-check-green:not(.is-outlined):active:focus, .button.is-check-green:not(.is-outlined):active.focus, .button.is-check-green:not(.is-outlined).active:hover, .button.is-check-green:not(.is-outlined).active:focus, .button.is-check-green:not(.is-outlined).active.focus,
    .open > .button.is-check-green:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-check-green:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-check-green:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(0, 200, 83, 0.75);
      border-color: rgba(0, 200, 83, 0.75); }
  .button.is-check-green:not(.is-outlined):active, .button.is-check-green:not(.is-outlined).active,
  .open > .button.is-check-green:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-check-green:not(.is-outlined).disabled:hover, .button.is-check-green:not(.is-outlined).disabled:focus, .button.is-check-green:not(.is-outlined).disabled.focus, .button.is-check-green:not(.is-outlined)[disabled]:hover, .button.is-check-green:not(.is-outlined)[disabled]:focus, .button.is-check-green:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-check-green:not(.is-outlined):hover,
  fieldset[disabled] .button.is-check-green:not(.is-outlined):focus,
  fieldset[disabled] .button.is-check-green:not(.is-outlined).focus {
    background-color: #00c853;
    border-color: #00c853; }
  .button.is-check-green:not(.is-outlined) .badge {
    color: #00c853;
    background-color: #fff; }

.button.is-inverted.is-check-green:not(.is-outlined) {
  color: #00c853;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-check-green:not(.is-outlined):focus, .button.is-inverted.is-check-green:not(.is-outlined).focus {
    color: #00c853;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-check-green:not(.is-outlined):hover {
    color: #00c853;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-check-green:not(.is-outlined):active, .button.is-inverted.is-check-green:not(.is-outlined).active,
  .open > .button.is-inverted.is-check-green:not(.is-outlined).dropdown-toggle {
    color: #00c853;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-check-green:not(.is-outlined):active:hover, .button.is-inverted.is-check-green:not(.is-outlined):active:focus, .button.is-inverted.is-check-green:not(.is-outlined):active.focus, .button.is-inverted.is-check-green:not(.is-outlined).active:hover, .button.is-inverted.is-check-green:not(.is-outlined).active:focus, .button.is-inverted.is-check-green:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-check-green:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-check-green:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-check-green:not(.is-outlined).dropdown-toggle.focus {
      color: #00c853;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-check-green:not(.is-outlined):active, .button.is-inverted.is-check-green:not(.is-outlined).active,
  .open > .button.is-inverted.is-check-green:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-check-green:not(.is-outlined).disabled:hover, .button.is-inverted.is-check-green:not(.is-outlined).disabled:focus, .button.is-inverted.is-check-green:not(.is-outlined).disabled.focus, .button.is-inverted.is-check-green:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-check-green:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-check-green:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-check-green:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-check-green:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-check-green:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-check-green:not(.is-outlined) .badge {
    color: #fff;
    background-color: #00c853; }

.button.is-outlined.is-check-green {
  border-color: #00c853;
  color: #00c853;
  background: none; }
  .button.is-outlined.is-check-green:hover, .button.is-outlined.is-check-green:active, .button.is-outlined.is-check-green:active:hover, .button.is-outlined.is-check-green:active:focus, .button.is-outlined.is-check-green:focus, .button.is-outlined.is-check-green.active {
    border-color: #00c853;
    color: #fff;
    background-color: #00c853; }

.button.is-check-green.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-neutral-grey:not(.is-outlined) {
  color: #fff;
  background-color: #b0bec5;
  border-color: #b0bec5; }
  .button.is-neutral-grey:not(.is-outlined):focus, .button.is-neutral-grey:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(176, 190, 197, 0.85);
    border-color: rgba(176, 190, 197, 0.85); }
  .button.is-neutral-grey:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(176, 190, 197, 0.85);
    border-color: rgba(176, 190, 197, 0.85); }
  .button.is-neutral-grey:not(.is-outlined):active, .button.is-neutral-grey:not(.is-outlined).active,
  .open > .button.is-neutral-grey:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(176, 190, 197, 0.85);
    border-color: rgba(176, 190, 197, 0.85); }
    .button.is-neutral-grey:not(.is-outlined):active:hover, .button.is-neutral-grey:not(.is-outlined):active:focus, .button.is-neutral-grey:not(.is-outlined):active.focus, .button.is-neutral-grey:not(.is-outlined).active:hover, .button.is-neutral-grey:not(.is-outlined).active:focus, .button.is-neutral-grey:not(.is-outlined).active.focus,
    .open > .button.is-neutral-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-neutral-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-neutral-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(176, 190, 197, 0.75);
      border-color: rgba(176, 190, 197, 0.75); }
  .button.is-neutral-grey:not(.is-outlined):active, .button.is-neutral-grey:not(.is-outlined).active,
  .open > .button.is-neutral-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-neutral-grey:not(.is-outlined).disabled:hover, .button.is-neutral-grey:not(.is-outlined).disabled:focus, .button.is-neutral-grey:not(.is-outlined).disabled.focus, .button.is-neutral-grey:not(.is-outlined)[disabled]:hover, .button.is-neutral-grey:not(.is-outlined)[disabled]:focus, .button.is-neutral-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-neutral-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-neutral-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-neutral-grey:not(.is-outlined).focus {
    background-color: #b0bec5;
    border-color: #b0bec5; }
  .button.is-neutral-grey:not(.is-outlined) .badge {
    color: #b0bec5;
    background-color: #fff; }

.button.is-inverted.is-neutral-grey:not(.is-outlined) {
  color: #b0bec5;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-neutral-grey:not(.is-outlined):focus, .button.is-inverted.is-neutral-grey:not(.is-outlined).focus {
    color: #b0bec5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-neutral-grey:not(.is-outlined):hover {
    color: #b0bec5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-neutral-grey:not(.is-outlined):active, .button.is-inverted.is-neutral-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-neutral-grey:not(.is-outlined).dropdown-toggle {
    color: #b0bec5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-neutral-grey:not(.is-outlined):active:hover, .button.is-inverted.is-neutral-grey:not(.is-outlined):active:focus, .button.is-inverted.is-neutral-grey:not(.is-outlined):active.focus, .button.is-inverted.is-neutral-grey:not(.is-outlined).active:hover, .button.is-inverted.is-neutral-grey:not(.is-outlined).active:focus, .button.is-inverted.is-neutral-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-neutral-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-neutral-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-neutral-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #b0bec5;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-neutral-grey:not(.is-outlined):active, .button.is-inverted.is-neutral-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-neutral-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-neutral-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-neutral-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-neutral-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-neutral-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-neutral-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-neutral-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-neutral-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-neutral-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-neutral-grey:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-neutral-grey:not(.is-outlined) .badge {
    color: #fff;
    background-color: #b0bec5; }

.button.is-outlined.is-neutral-grey {
  border-color: #b0bec5;
  color: #b0bec5;
  background: none; }
  .button.is-outlined.is-neutral-grey:hover, .button.is-outlined.is-neutral-grey:active, .button.is-outlined.is-neutral-grey:active:hover, .button.is-outlined.is-neutral-grey:active:focus, .button.is-outlined.is-neutral-grey:focus, .button.is-outlined.is-neutral-grey.active {
    border-color: #b0bec5;
    color: #fff;
    background-color: #b0bec5; }

.button.is-neutral-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-done-grey:not(.is-outlined) {
  color: #a8c6df;
  background-color: #ebeef0;
  border-color: #ebeef0; }
  .button.is-done-grey:not(.is-outlined):focus, .button.is-done-grey:not(.is-outlined).focus {
    color: #a8c6df;
    background-color: rgba(235, 238, 240, 0.85);
    border-color: rgba(235, 238, 240, 0.85); }
  .button.is-done-grey:not(.is-outlined):hover {
    color: #a8c6df;
    background-color: rgba(235, 238, 240, 0.85);
    border-color: rgba(235, 238, 240, 0.85); }
  .button.is-done-grey:not(.is-outlined):active, .button.is-done-grey:not(.is-outlined).active,
  .open > .button.is-done-grey:not(.is-outlined).dropdown-toggle {
    color: #a8c6df;
    background-color: rgba(235, 238, 240, 0.85);
    border-color: rgba(235, 238, 240, 0.85); }
    .button.is-done-grey:not(.is-outlined):active:hover, .button.is-done-grey:not(.is-outlined):active:focus, .button.is-done-grey:not(.is-outlined):active.focus, .button.is-done-grey:not(.is-outlined).active:hover, .button.is-done-grey:not(.is-outlined).active:focus, .button.is-done-grey:not(.is-outlined).active.focus,
    .open > .button.is-done-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-done-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-done-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #a8c6df;
      background-color: rgba(235, 238, 240, 0.75);
      border-color: rgba(235, 238, 240, 0.75); }
  .button.is-done-grey:not(.is-outlined):active, .button.is-done-grey:not(.is-outlined).active,
  .open > .button.is-done-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-done-grey:not(.is-outlined).disabled:hover, .button.is-done-grey:not(.is-outlined).disabled:focus, .button.is-done-grey:not(.is-outlined).disabled.focus, .button.is-done-grey:not(.is-outlined)[disabled]:hover, .button.is-done-grey:not(.is-outlined)[disabled]:focus, .button.is-done-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-done-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-done-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-done-grey:not(.is-outlined).focus {
    background-color: #ebeef0;
    border-color: #ebeef0; }
  .button.is-done-grey:not(.is-outlined) .badge {
    color: #ebeef0;
    background-color: #a8c6df; }

.button.is-inverted.is-done-grey:not(.is-outlined) {
  color: #ebeef0;
  background-color: #a8c6df;
  border-color: #a8c6df; }
  .button.is-inverted.is-done-grey:not(.is-outlined):focus, .button.is-inverted.is-done-grey:not(.is-outlined).focus {
    color: #ebeef0;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-inverted.is-done-grey:not(.is-outlined):hover {
    color: #ebeef0;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
  .button.is-inverted.is-done-grey:not(.is-outlined):active, .button.is-inverted.is-done-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-done-grey:not(.is-outlined).dropdown-toggle {
    color: #ebeef0;
    background-color: rgba(168, 198, 223, 0.85);
    border-color: rgba(168, 198, 223, 0.85); }
    .button.is-inverted.is-done-grey:not(.is-outlined):active:hover, .button.is-inverted.is-done-grey:not(.is-outlined):active:focus, .button.is-inverted.is-done-grey:not(.is-outlined):active.focus, .button.is-inverted.is-done-grey:not(.is-outlined).active:hover, .button.is-inverted.is-done-grey:not(.is-outlined).active:focus, .button.is-inverted.is-done-grey:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-done-grey:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-done-grey:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-done-grey:not(.is-outlined).dropdown-toggle.focus {
      color: #ebeef0;
      background-color: rgba(168, 198, 223, 0.75);
      border-color: rgba(168, 198, 223, 0.75); }
  .button.is-inverted.is-done-grey:not(.is-outlined):active, .button.is-inverted.is-done-grey:not(.is-outlined).active,
  .open > .button.is-inverted.is-done-grey:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-done-grey:not(.is-outlined).disabled:hover, .button.is-inverted.is-done-grey:not(.is-outlined).disabled:focus, .button.is-inverted.is-done-grey:not(.is-outlined).disabled.focus, .button.is-inverted.is-done-grey:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-done-grey:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-done-grey:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-done-grey:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-done-grey:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-done-grey:not(.is-outlined).focus {
    background-color: #a8c6df;
    border-color: #a8c6df; }
  .button.is-inverted.is-done-grey:not(.is-outlined) .badge {
    color: #a8c6df;
    background-color: #ebeef0; }

.button.is-outlined.is-done-grey {
  border-color: #ebeef0;
  color: #ebeef0;
  background: none; }
  .button.is-outlined.is-done-grey:hover, .button.is-outlined.is-done-grey:active, .button.is-outlined.is-done-grey:active:hover, .button.is-outlined.is-done-grey:active:focus, .button.is-outlined.is-done-grey:focus, .button.is-outlined.is-done-grey.active {
    border-color: #ebeef0;
    color: #a8c6df;
    background-color: #ebeef0; }

.button.is-done-grey.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-stressed:not(.is-outlined) {
  color: #fff;
  background-color: #aa00ff;
  border-color: #aa00ff; }
  .button.is-stressed:not(.is-outlined):focus, .button.is-stressed:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(170, 0, 255, 0.85);
    border-color: rgba(170, 0, 255, 0.85); }
  .button.is-stressed:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(170, 0, 255, 0.85);
    border-color: rgba(170, 0, 255, 0.85); }
  .button.is-stressed:not(.is-outlined):active, .button.is-stressed:not(.is-outlined).active,
  .open > .button.is-stressed:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(170, 0, 255, 0.85);
    border-color: rgba(170, 0, 255, 0.85); }
    .button.is-stressed:not(.is-outlined):active:hover, .button.is-stressed:not(.is-outlined):active:focus, .button.is-stressed:not(.is-outlined):active.focus, .button.is-stressed:not(.is-outlined).active:hover, .button.is-stressed:not(.is-outlined).active:focus, .button.is-stressed:not(.is-outlined).active.focus,
    .open > .button.is-stressed:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-stressed:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-stressed:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(170, 0, 255, 0.75);
      border-color: rgba(170, 0, 255, 0.75); }
  .button.is-stressed:not(.is-outlined):active, .button.is-stressed:not(.is-outlined).active,
  .open > .button.is-stressed:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-stressed:not(.is-outlined).disabled:hover, .button.is-stressed:not(.is-outlined).disabled:focus, .button.is-stressed:not(.is-outlined).disabled.focus, .button.is-stressed:not(.is-outlined)[disabled]:hover, .button.is-stressed:not(.is-outlined)[disabled]:focus, .button.is-stressed:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-stressed:not(.is-outlined):hover,
  fieldset[disabled] .button.is-stressed:not(.is-outlined):focus,
  fieldset[disabled] .button.is-stressed:not(.is-outlined).focus {
    background-color: #aa00ff;
    border-color: #aa00ff; }
  .button.is-stressed:not(.is-outlined) .badge {
    color: #aa00ff;
    background-color: #fff; }

.button.is-inverted.is-stressed:not(.is-outlined) {
  color: #aa00ff;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-stressed:not(.is-outlined):focus, .button.is-inverted.is-stressed:not(.is-outlined).focus {
    color: #aa00ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-stressed:not(.is-outlined):hover {
    color: #aa00ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-stressed:not(.is-outlined):active, .button.is-inverted.is-stressed:not(.is-outlined).active,
  .open > .button.is-inverted.is-stressed:not(.is-outlined).dropdown-toggle {
    color: #aa00ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-stressed:not(.is-outlined):active:hover, .button.is-inverted.is-stressed:not(.is-outlined):active:focus, .button.is-inverted.is-stressed:not(.is-outlined):active.focus, .button.is-inverted.is-stressed:not(.is-outlined).active:hover, .button.is-inverted.is-stressed:not(.is-outlined).active:focus, .button.is-inverted.is-stressed:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-stressed:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-stressed:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-stressed:not(.is-outlined).dropdown-toggle.focus {
      color: #aa00ff;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-stressed:not(.is-outlined):active, .button.is-inverted.is-stressed:not(.is-outlined).active,
  .open > .button.is-inverted.is-stressed:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-stressed:not(.is-outlined).disabled:hover, .button.is-inverted.is-stressed:not(.is-outlined).disabled:focus, .button.is-inverted.is-stressed:not(.is-outlined).disabled.focus, .button.is-inverted.is-stressed:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-stressed:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-stressed:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-stressed:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-stressed:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-stressed:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-stressed:not(.is-outlined) .badge {
    color: #fff;
    background-color: #aa00ff; }

.button.is-outlined.is-stressed {
  border-color: #aa00ff;
  color: #aa00ff;
  background: none; }
  .button.is-outlined.is-stressed:hover, .button.is-outlined.is-stressed:active, .button.is-outlined.is-stressed:active:hover, .button.is-outlined.is-stressed:active:focus, .button.is-outlined.is-stressed:focus, .button.is-outlined.is-stressed.active {
    border-color: #aa00ff;
    color: #fff;
    background-color: #aa00ff; }

.button.is-stressed.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-error:not(.is-outlined) {
  color: #fff;
  background-color: #ff1744;
  border-color: #ff1744; }
  .button.is-error:not(.is-outlined):focus, .button.is-error:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(255, 23, 68, 0.85);
    border-color: rgba(255, 23, 68, 0.85); }
  .button.is-error:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(255, 23, 68, 0.85);
    border-color: rgba(255, 23, 68, 0.85); }
  .button.is-error:not(.is-outlined):active, .button.is-error:not(.is-outlined).active,
  .open > .button.is-error:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(255, 23, 68, 0.85);
    border-color: rgba(255, 23, 68, 0.85); }
    .button.is-error:not(.is-outlined):active:hover, .button.is-error:not(.is-outlined):active:focus, .button.is-error:not(.is-outlined):active.focus, .button.is-error:not(.is-outlined).active:hover, .button.is-error:not(.is-outlined).active:focus, .button.is-error:not(.is-outlined).active.focus,
    .open > .button.is-error:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-error:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-error:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(255, 23, 68, 0.75);
      border-color: rgba(255, 23, 68, 0.75); }
  .button.is-error:not(.is-outlined):active, .button.is-error:not(.is-outlined).active,
  .open > .button.is-error:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-error:not(.is-outlined).disabled:hover, .button.is-error:not(.is-outlined).disabled:focus, .button.is-error:not(.is-outlined).disabled.focus, .button.is-error:not(.is-outlined)[disabled]:hover, .button.is-error:not(.is-outlined)[disabled]:focus, .button.is-error:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-error:not(.is-outlined):hover,
  fieldset[disabled] .button.is-error:not(.is-outlined):focus,
  fieldset[disabled] .button.is-error:not(.is-outlined).focus {
    background-color: #ff1744;
    border-color: #ff1744; }
  .button.is-error:not(.is-outlined) .badge {
    color: #ff1744;
    background-color: #fff; }

.button.is-inverted.is-error:not(.is-outlined) {
  color: #ff1744;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-error:not(.is-outlined):focus, .button.is-inverted.is-error:not(.is-outlined).focus {
    color: #ff1744;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-error:not(.is-outlined):hover {
    color: #ff1744;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-error:not(.is-outlined):active, .button.is-inverted.is-error:not(.is-outlined).active,
  .open > .button.is-inverted.is-error:not(.is-outlined).dropdown-toggle {
    color: #ff1744;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-error:not(.is-outlined):active:hover, .button.is-inverted.is-error:not(.is-outlined):active:focus, .button.is-inverted.is-error:not(.is-outlined):active.focus, .button.is-inverted.is-error:not(.is-outlined).active:hover, .button.is-inverted.is-error:not(.is-outlined).active:focus, .button.is-inverted.is-error:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-error:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-error:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-error:not(.is-outlined).dropdown-toggle.focus {
      color: #ff1744;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-error:not(.is-outlined):active, .button.is-inverted.is-error:not(.is-outlined).active,
  .open > .button.is-inverted.is-error:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-error:not(.is-outlined).disabled:hover, .button.is-inverted.is-error:not(.is-outlined).disabled:focus, .button.is-inverted.is-error:not(.is-outlined).disabled.focus, .button.is-inverted.is-error:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-error:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-error:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-error:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-error:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-error:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-error:not(.is-outlined) .badge {
    color: #fff;
    background-color: #ff1744; }

.button.is-outlined.is-error {
  border-color: #ff1744;
  color: #ff1744;
  background: none; }
  .button.is-outlined.is-error:hover, .button.is-outlined.is-error:active, .button.is-outlined.is-error:active:hover, .button.is-outlined.is-error:active:focus, .button.is-outlined.is-error:focus, .button.is-outlined.is-error.active {
    border-color: #ff1744;
    color: #fff;
    background-color: #ff1744; }

.button.is-error.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-production:not(.is-outlined) {
  color: #fff;
  background-color: #2979ff;
  border-color: #2979ff; }
  .button.is-production:not(.is-outlined):focus, .button.is-production:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
  .button.is-production:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
  .button.is-production:not(.is-outlined):active, .button.is-production:not(.is-outlined).active,
  .open > .button.is-production:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(41, 121, 255, 0.85);
    border-color: rgba(41, 121, 255, 0.85); }
    .button.is-production:not(.is-outlined):active:hover, .button.is-production:not(.is-outlined):active:focus, .button.is-production:not(.is-outlined):active.focus, .button.is-production:not(.is-outlined).active:hover, .button.is-production:not(.is-outlined).active:focus, .button.is-production:not(.is-outlined).active.focus,
    .open > .button.is-production:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-production:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-production:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(41, 121, 255, 0.75);
      border-color: rgba(41, 121, 255, 0.75); }
  .button.is-production:not(.is-outlined):active, .button.is-production:not(.is-outlined).active,
  .open > .button.is-production:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-production:not(.is-outlined).disabled:hover, .button.is-production:not(.is-outlined).disabled:focus, .button.is-production:not(.is-outlined).disabled.focus, .button.is-production:not(.is-outlined)[disabled]:hover, .button.is-production:not(.is-outlined)[disabled]:focus, .button.is-production:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-production:not(.is-outlined):hover,
  fieldset[disabled] .button.is-production:not(.is-outlined):focus,
  fieldset[disabled] .button.is-production:not(.is-outlined).focus {
    background-color: #2979ff;
    border-color: #2979ff; }
  .button.is-production:not(.is-outlined) .badge {
    color: #2979ff;
    background-color: #fff; }

.button.is-inverted.is-production:not(.is-outlined) {
  color: #2979ff;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-production:not(.is-outlined):focus, .button.is-inverted.is-production:not(.is-outlined).focus {
    color: #2979ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-production:not(.is-outlined):hover {
    color: #2979ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-production:not(.is-outlined):active, .button.is-inverted.is-production:not(.is-outlined).active,
  .open > .button.is-inverted.is-production:not(.is-outlined).dropdown-toggle {
    color: #2979ff;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-production:not(.is-outlined):active:hover, .button.is-inverted.is-production:not(.is-outlined):active:focus, .button.is-inverted.is-production:not(.is-outlined):active.focus, .button.is-inverted.is-production:not(.is-outlined).active:hover, .button.is-inverted.is-production:not(.is-outlined).active:focus, .button.is-inverted.is-production:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-production:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-production:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-production:not(.is-outlined).dropdown-toggle.focus {
      color: #2979ff;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-production:not(.is-outlined):active, .button.is-inverted.is-production:not(.is-outlined).active,
  .open > .button.is-inverted.is-production:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-production:not(.is-outlined).disabled:hover, .button.is-inverted.is-production:not(.is-outlined).disabled:focus, .button.is-inverted.is-production:not(.is-outlined).disabled.focus, .button.is-inverted.is-production:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-production:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-production:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-production:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-production:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-production:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-production:not(.is-outlined) .badge {
    color: #fff;
    background-color: #2979ff; }

.button.is-outlined.is-production {
  border-color: #2979ff;
  color: #2979ff;
  background: none; }
  .button.is-outlined.is-production:hover, .button.is-outlined.is-production:active, .button.is-outlined.is-production:active:hover, .button.is-outlined.is-production:active:focus, .button.is-outlined.is-production:focus, .button.is-outlined.is-production.active {
    border-color: #2979ff;
    color: #fff;
    background-color: #2979ff; }

.button.is-production.btn-no-border:not(.active) {
  border-color: transparent; }

.button.is-interrupted:not(.is-outlined) {
  color: #fff;
  background-color: #b0bec5;
  border-color: #b0bec5; }
  .button.is-interrupted:not(.is-outlined):focus, .button.is-interrupted:not(.is-outlined).focus {
    color: #fff;
    background-color: rgba(176, 190, 197, 0.85);
    border-color: rgba(176, 190, 197, 0.85); }
  .button.is-interrupted:not(.is-outlined):hover {
    color: #fff;
    background-color: rgba(176, 190, 197, 0.85);
    border-color: rgba(176, 190, 197, 0.85); }
  .button.is-interrupted:not(.is-outlined):active, .button.is-interrupted:not(.is-outlined).active,
  .open > .button.is-interrupted:not(.is-outlined).dropdown-toggle {
    color: #fff;
    background-color: rgba(176, 190, 197, 0.85);
    border-color: rgba(176, 190, 197, 0.85); }
    .button.is-interrupted:not(.is-outlined):active:hover, .button.is-interrupted:not(.is-outlined):active:focus, .button.is-interrupted:not(.is-outlined):active.focus, .button.is-interrupted:not(.is-outlined).active:hover, .button.is-interrupted:not(.is-outlined).active:focus, .button.is-interrupted:not(.is-outlined).active.focus,
    .open > .button.is-interrupted:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-interrupted:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-interrupted:not(.is-outlined).dropdown-toggle.focus {
      color: #fff;
      background-color: rgba(176, 190, 197, 0.75);
      border-color: rgba(176, 190, 197, 0.75); }
  .button.is-interrupted:not(.is-outlined):active, .button.is-interrupted:not(.is-outlined).active,
  .open > .button.is-interrupted:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-interrupted:not(.is-outlined).disabled:hover, .button.is-interrupted:not(.is-outlined).disabled:focus, .button.is-interrupted:not(.is-outlined).disabled.focus, .button.is-interrupted:not(.is-outlined)[disabled]:hover, .button.is-interrupted:not(.is-outlined)[disabled]:focus, .button.is-interrupted:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-interrupted:not(.is-outlined):hover,
  fieldset[disabled] .button.is-interrupted:not(.is-outlined):focus,
  fieldset[disabled] .button.is-interrupted:not(.is-outlined).focus {
    background-color: #b0bec5;
    border-color: #b0bec5; }
  .button.is-interrupted:not(.is-outlined) .badge {
    color: #b0bec5;
    background-color: #fff; }

.button.is-inverted.is-interrupted:not(.is-outlined) {
  color: #b0bec5;
  background-color: #fff;
  border-color: #fff; }
  .button.is-inverted.is-interrupted:not(.is-outlined):focus, .button.is-inverted.is-interrupted:not(.is-outlined).focus {
    color: #b0bec5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-interrupted:not(.is-outlined):hover {
    color: #b0bec5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
  .button.is-inverted.is-interrupted:not(.is-outlined):active, .button.is-inverted.is-interrupted:not(.is-outlined).active,
  .open > .button.is-inverted.is-interrupted:not(.is-outlined).dropdown-toggle {
    color: #b0bec5;
    background-color: rgba(255, 255, 255, 0.85);
    border-color: rgba(255, 255, 255, 0.85); }
    .button.is-inverted.is-interrupted:not(.is-outlined):active:hover, .button.is-inverted.is-interrupted:not(.is-outlined):active:focus, .button.is-inverted.is-interrupted:not(.is-outlined):active.focus, .button.is-inverted.is-interrupted:not(.is-outlined).active:hover, .button.is-inverted.is-interrupted:not(.is-outlined).active:focus, .button.is-inverted.is-interrupted:not(.is-outlined).active.focus,
    .open > .button.is-inverted.is-interrupted:not(.is-outlined).dropdown-toggle:hover,
    .open > .button.is-inverted.is-interrupted:not(.is-outlined).dropdown-toggle:focus,
    .open > .button.is-inverted.is-interrupted:not(.is-outlined).dropdown-toggle.focus {
      color: #b0bec5;
      background-color: rgba(255, 255, 255, 0.75);
      border-color: rgba(255, 255, 255, 0.75); }
  .button.is-inverted.is-interrupted:not(.is-outlined):active, .button.is-inverted.is-interrupted:not(.is-outlined).active,
  .open > .button.is-inverted.is-interrupted:not(.is-outlined).dropdown-toggle {
    background-image: none; }
  .button.is-inverted.is-interrupted:not(.is-outlined).disabled:hover, .button.is-inverted.is-interrupted:not(.is-outlined).disabled:focus, .button.is-inverted.is-interrupted:not(.is-outlined).disabled.focus, .button.is-inverted.is-interrupted:not(.is-outlined)[disabled]:hover, .button.is-inverted.is-interrupted:not(.is-outlined)[disabled]:focus, .button.is-inverted.is-interrupted:not(.is-outlined)[disabled].focus,
  fieldset[disabled] .button.is-inverted.is-interrupted:not(.is-outlined):hover,
  fieldset[disabled] .button.is-inverted.is-interrupted:not(.is-outlined):focus,
  fieldset[disabled] .button.is-inverted.is-interrupted:not(.is-outlined).focus {
    background-color: #fff;
    border-color: #fff; }
  .button.is-inverted.is-interrupted:not(.is-outlined) .badge {
    color: #fff;
    background-color: #b0bec5; }

.button.is-outlined.is-interrupted {
  border-color: #b0bec5;
  color: #b0bec5;
  background: none; }
  .button.is-outlined.is-interrupted:hover, .button.is-outlined.is-interrupted:active, .button.is-outlined.is-interrupted:active:hover, .button.is-outlined.is-interrupted:active:focus, .button.is-outlined.is-interrupted:focus, .button.is-outlined.is-interrupted.active {
    border-color: #b0bec5;
    color: #fff;
    background-color: #b0bec5; }

.button.is-interrupted.btn-no-border:not(.active) {
  border-color: transparent; }

.button {
  font-weight: 500;
  border-radius: 4px;
  color: #78909c; }

.button.is-large {
  height: 3.2rem;
  min-width: 4rem;
  font-size: 1.4rem; }

.button.is-huge {
  height: 4.8rem;
  font-size: 1.7rem; }

.button.is-link {
  text-decoration: none; }
  .button.is-link:active, .button.is-link.is-active {
    box-shadow: none; }
  .button.is-link[disabled] {
    background-color: transparent; }

.button.is-link,
.button.is-link:hover,
.button.is-link.is-hovered,
.button.is-link:focus,
.button.is-link.is-focused,
.button.is-link:active,
.button.is-link.is-active {
  background-color: transparent; }

.button.is-link:focus:not(:active),
.button.is-link.is-focused:not(:active) {
  box-shadow: unset; }

.sk-circle {
  margin: 0 .5rem;
  width: 25px;
  height: 25px;
  position: relative; }
  .sk-circle.is-large {
    width: 40px;
    height: 40px; }
  .sk-circle.is-small {
    width: 15px;
    height: 15px; }

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #455a64;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both; }

.sk-circle.is-transparent .sk-child:before {
  background-color: rgba(0, 0, 0, 0); }

.sk-circle.is-default .sk-child:before {
  background-color: #263238; }

.sk-circle.is-white .sk-child:before {
  background-color: #fff; }

.sk-circle.is-black .sk-child:before {
  background-color: #263238; }

.sk-circle.is-dark .sk-child:before {
  background-color: #263238; }

.sk-circle.is-slate .sk-child:before {
  background-color: #455a64; }

.sk-circle.is-body-grey .sk-child:before {
  background-color: #f2f6f9; }

.sk-circle.is-light-blue-grey .sk-child:before {
  background-color: #a8c6df; }

.sk-circle.is-light-blue-grey-20 .sk-child:before {
  background-color: rgba(168, 198, 223, 0.2); }

.sk-circle.is-light-blue-grey-80 .sk-child:before {
  background-color: rgba(168, 198, 223, 0.8); }

.sk-circle.is-light-blue-grey-90 .sk-child:before {
  background-color: rgba(183, 210, 229, 0.9); }

.sk-circle.is-bluish-grey .sk-child:before {
  background-color: #78909c; }

.sk-circle.is-light-grey .sk-child:before {
  background-color: #f8f9f9; }

.sk-circle.is-dark-sky-blue-10 .sk-child:before {
  background-color: rgba(30, 136, 229, 0.1); }

.sk-circle.is-light-blue-grey-two .sk-child:before {
  background-color: #d8e5ef; }

.sk-circle.is-dark-grey-blue .sk-child:before {
  background-color: #2c4059; }

.sk-circle.is-dark-grey-blue-16 .sk-child:before {
  background-color: rgba(44, 64, 90, 0.16); }

.sk-circle.is-dark-2 .sk-child:before {
  background-color: rgba(38, 50, 56, 0.02); }

.sk-circle.is-dark-3 .sk-child:before {
  background-color: rgba(38, 50, 56, 0.03); }

.sk-circle.is-charcoal-grey .sk-child:before {
  background-color: #37474f; }

.sk-circle.is-pale-grey .sk-child:before {
  background-color: #fafbfc; }

.sk-circle.is-greyblue .sk-child:before {
  background-color: #8dabc4; }

.sk-circle.is-bluey-grey .sk-child:before {
  background-color: #90a4ae; }

.sk-circle.is-pale-grey-two .sk-child:before {
  background-color: #f2f6f9; }

.sk-circle.is-pale-grey-three .sk-child:before {
  background-color: #e5eef5; }

.sk-circle.is-light-blue-grey-three .sk-child:before {
  background-color: #c5d9e8; }

.sk-circle.is-charcoal-grey-60 .sk-child:before {
  background-color: rgba(55, 71, 79, 0.6); }

.sk-circle.is-charcoal-grey-80 .sk-child:before {
  background-color: rgba(55, 71, 79, 0.8); }

.sk-circle.is-azure .sk-child:before {
  background-color: #0093ee; }

.sk-circle.is-dark-sky-blue .sk-child:before {
  background-color: #1e88e5; }

.sk-circle.is-apple-green .sk-child:before {
  background-color: #81c926; }

.sk-circle.is-pause-azure .sk-child:before {
  background-color: #03a9f4; }

.sk-circle.is-production-blue .sk-child:before {
  background-color: #2979ff; }

.sk-circle.is-production-blue-20 .sk-child:before {
  background-color: rgba(41, 121, 255, 0.2); }

.sk-circle.is-material-violet .sk-child:before {
  background-color: #aa00ff; }

.sk-circle.is-setup-yellow .sk-child:before {
  background-color: #ffab00; }

.sk-circle.is-check-green .sk-child:before {
  background-color: #00c853; }

.sk-circle.is-neutral-grey .sk-child:before {
  background-color: #b0bec5; }

.sk-circle.is-done-grey .sk-child:before {
  background-color: #ebeef0; }

.sk-circle.is-stressed .sk-child:before {
  background-color: #aa00ff; }

.sk-circle.is-error .sk-child:before {
  background-color: #ff1744; }

.sk-circle.is-production .sk-child:before {
  background-color: #2979ff; }

.sk-circle.is-interrupted .sk-child:before {
  background-color: #b0bec5; }

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.mb1 {
  margin-bottom: 0.1rem !important; }

.mt1 {
  margin-top: 0.1rem !important; }

.ml1 {
  margin-left: 0.1rem !important; }

.mr1 {
  margin-right: 0.1rem !important; }

.has-1-font {
  font-size: 0.1rem !important; }

.has-1-border {
  border-width: 0.1rem !important; }

.has-1-border-top {
  border-top-width: 0.1rem !important; }

.has-1-border-right {
  border-right-width: 0.1rem !important; }

.has-1-border-bottom {
  border-bottom-width: 0.1rem !important; }

.has-1-border-left {
  border-left-width: 0.1rem !important; }

.has-1-font-size {
  font-size: 0.1rem !important; }

.has-1-margin {
  margin: 0.1rem !important; }

.has-1-margin-bottom {
  margin-bottom: 0.1rem !important; }

.has-1-margin-top {
  margin-top: 0.1rem !important; }

.has-1-margin-left {
  margin-left: 0.1rem !important; }

.has-1-margin-right {
  margin-right: 0.1rem !important; }

.has-1-padding {
  padding: 0.1rem !important; }

.has-1-padding-bottom {
  padding-bottom: 0.1rem !important; }

.has-1-padding-top {
  padding-top: 0.1rem !important; }

.has-1-padding-left {
  padding-left: 0.1rem !important; }

.has-1-padding-right {
  padding-right: 0.1rem !important; }

.has-1-font-size {
  font-size: 0.1rem !important; }

.mb2 {
  margin-bottom: 0.2rem !important; }

.mt2 {
  margin-top: 0.2rem !important; }

.ml2 {
  margin-left: 0.2rem !important; }

.mr2 {
  margin-right: 0.2rem !important; }

.has-2-font {
  font-size: 0.2rem !important; }

.has-2-border {
  border-width: 0.2rem !important; }

.has-2-border-top {
  border-top-width: 0.2rem !important; }

.has-2-border-right {
  border-right-width: 0.2rem !important; }

.has-2-border-bottom {
  border-bottom-width: 0.2rem !important; }

.has-2-border-left {
  border-left-width: 0.2rem !important; }

.has-2-font-size {
  font-size: 0.2rem !important; }

.has-2-margin {
  margin: 0.2rem !important; }

.has-2-margin-bottom {
  margin-bottom: 0.2rem !important; }

.has-2-margin-top {
  margin-top: 0.2rem !important; }

.has-2-margin-left {
  margin-left: 0.2rem !important; }

.has-2-margin-right {
  margin-right: 0.2rem !important; }

.has-2-padding {
  padding: 0.2rem !important; }

.has-2-padding-bottom {
  padding-bottom: 0.2rem !important; }

.has-2-padding-top {
  padding-top: 0.2rem !important; }

.has-2-padding-left {
  padding-left: 0.2rem !important; }

.has-2-padding-right {
  padding-right: 0.2rem !important; }

.has-2-font-size {
  font-size: 0.2rem !important; }

.mb3 {
  margin-bottom: 0.3rem !important; }

.mt3 {
  margin-top: 0.3rem !important; }

.ml3 {
  margin-left: 0.3rem !important; }

.mr3 {
  margin-right: 0.3rem !important; }

.has-3-font {
  font-size: 0.3rem !important; }

.has-3-border {
  border-width: 0.3rem !important; }

.has-3-border-top {
  border-top-width: 0.3rem !important; }

.has-3-border-right {
  border-right-width: 0.3rem !important; }

.has-3-border-bottom {
  border-bottom-width: 0.3rem !important; }

.has-3-border-left {
  border-left-width: 0.3rem !important; }

.has-3-font-size {
  font-size: 0.3rem !important; }

.has-3-margin {
  margin: 0.3rem !important; }

.has-3-margin-bottom {
  margin-bottom: 0.3rem !important; }

.has-3-margin-top {
  margin-top: 0.3rem !important; }

.has-3-margin-left {
  margin-left: 0.3rem !important; }

.has-3-margin-right {
  margin-right: 0.3rem !important; }

.has-3-padding {
  padding: 0.3rem !important; }

.has-3-padding-bottom {
  padding-bottom: 0.3rem !important; }

.has-3-padding-top {
  padding-top: 0.3rem !important; }

.has-3-padding-left {
  padding-left: 0.3rem !important; }

.has-3-padding-right {
  padding-right: 0.3rem !important; }

.has-3-font-size {
  font-size: 0.3rem !important; }

.mb4 {
  margin-bottom: 0.4rem !important; }

.mt4 {
  margin-top: 0.4rem !important; }

.ml4 {
  margin-left: 0.4rem !important; }

.mr4 {
  margin-right: 0.4rem !important; }

.has-4-font {
  font-size: 0.4rem !important; }

.has-4-border {
  border-width: 0.4rem !important; }

.has-4-border-top {
  border-top-width: 0.4rem !important; }

.has-4-border-right {
  border-right-width: 0.4rem !important; }

.has-4-border-bottom {
  border-bottom-width: 0.4rem !important; }

.has-4-border-left {
  border-left-width: 0.4rem !important; }

.has-4-font-size {
  font-size: 0.4rem !important; }

.has-4-margin {
  margin: 0.4rem !important; }

.has-4-margin-bottom {
  margin-bottom: 0.4rem !important; }

.has-4-margin-top {
  margin-top: 0.4rem !important; }

.has-4-margin-left {
  margin-left: 0.4rem !important; }

.has-4-margin-right {
  margin-right: 0.4rem !important; }

.has-4-padding {
  padding: 0.4rem !important; }

.has-4-padding-bottom {
  padding-bottom: 0.4rem !important; }

.has-4-padding-top {
  padding-top: 0.4rem !important; }

.has-4-padding-left {
  padding-left: 0.4rem !important; }

.has-4-padding-right {
  padding-right: 0.4rem !important; }

.has-4-font-size {
  font-size: 0.4rem !important; }

.mb5 {
  margin-bottom: 0.5rem !important; }

.mt5 {
  margin-top: 0.5rem !important; }

.ml5 {
  margin-left: 0.5rem !important; }

.mr5 {
  margin-right: 0.5rem !important; }

.has-5-font {
  font-size: 0.5rem !important; }

.has-5-border {
  border-width: 0.5rem !important; }

.has-5-border-top {
  border-top-width: 0.5rem !important; }

.has-5-border-right {
  border-right-width: 0.5rem !important; }

.has-5-border-bottom {
  border-bottom-width: 0.5rem !important; }

.has-5-border-left {
  border-left-width: 0.5rem !important; }

.has-5-font-size {
  font-size: 0.5rem !important; }

.has-5-margin {
  margin: 0.5rem !important; }

.has-5-margin-bottom {
  margin-bottom: 0.5rem !important; }

.has-5-margin-top {
  margin-top: 0.5rem !important; }

.has-5-margin-left {
  margin-left: 0.5rem !important; }

.has-5-margin-right {
  margin-right: 0.5rem !important; }

.has-5-padding {
  padding: 0.5rem !important; }

.has-5-padding-bottom {
  padding-bottom: 0.5rem !important; }

.has-5-padding-top {
  padding-top: 0.5rem !important; }

.has-5-padding-left {
  padding-left: 0.5rem !important; }

.has-5-padding-right {
  padding-right: 0.5rem !important; }

.has-5-font-size {
  font-size: 0.5rem !important; }

.mb6 {
  margin-bottom: 0.6rem !important; }

.mt6 {
  margin-top: 0.6rem !important; }

.ml6 {
  margin-left: 0.6rem !important; }

.mr6 {
  margin-right: 0.6rem !important; }

.has-6-font {
  font-size: 0.6rem !important; }

.has-6-border {
  border-width: 0.6rem !important; }

.has-6-border-top {
  border-top-width: 0.6rem !important; }

.has-6-border-right {
  border-right-width: 0.6rem !important; }

.has-6-border-bottom {
  border-bottom-width: 0.6rem !important; }

.has-6-border-left {
  border-left-width: 0.6rem !important; }

.has-6-font-size {
  font-size: 0.6rem !important; }

.has-6-margin {
  margin: 0.6rem !important; }

.has-6-margin-bottom {
  margin-bottom: 0.6rem !important; }

.has-6-margin-top {
  margin-top: 0.6rem !important; }

.has-6-margin-left {
  margin-left: 0.6rem !important; }

.has-6-margin-right {
  margin-right: 0.6rem !important; }

.has-6-padding {
  padding: 0.6rem !important; }

.has-6-padding-bottom {
  padding-bottom: 0.6rem !important; }

.has-6-padding-top {
  padding-top: 0.6rem !important; }

.has-6-padding-left {
  padding-left: 0.6rem !important; }

.has-6-padding-right {
  padding-right: 0.6rem !important; }

.has-6-font-size {
  font-size: 0.6rem !important; }

.mb7 {
  margin-bottom: 0.7rem !important; }

.mt7 {
  margin-top: 0.7rem !important; }

.ml7 {
  margin-left: 0.7rem !important; }

.mr7 {
  margin-right: 0.7rem !important; }

.has-7-font {
  font-size: 0.7rem !important; }

.has-7-border {
  border-width: 0.7rem !important; }

.has-7-border-top {
  border-top-width: 0.7rem !important; }

.has-7-border-right {
  border-right-width: 0.7rem !important; }

.has-7-border-bottom {
  border-bottom-width: 0.7rem !important; }

.has-7-border-left {
  border-left-width: 0.7rem !important; }

.has-7-font-size {
  font-size: 0.7rem !important; }

.has-7-margin {
  margin: 0.7rem !important; }

.has-7-margin-bottom {
  margin-bottom: 0.7rem !important; }

.has-7-margin-top {
  margin-top: 0.7rem !important; }

.has-7-margin-left {
  margin-left: 0.7rem !important; }

.has-7-margin-right {
  margin-right: 0.7rem !important; }

.has-7-padding {
  padding: 0.7rem !important; }

.has-7-padding-bottom {
  padding-bottom: 0.7rem !important; }

.has-7-padding-top {
  padding-top: 0.7rem !important; }

.has-7-padding-left {
  padding-left: 0.7rem !important; }

.has-7-padding-right {
  padding-right: 0.7rem !important; }

.has-7-font-size {
  font-size: 0.7rem !important; }

.mb8 {
  margin-bottom: 0.8rem !important; }

.mt8 {
  margin-top: 0.8rem !important; }

.ml8 {
  margin-left: 0.8rem !important; }

.mr8 {
  margin-right: 0.8rem !important; }

.has-8-font {
  font-size: 0.8rem !important; }

.has-8-border {
  border-width: 0.8rem !important; }

.has-8-border-top {
  border-top-width: 0.8rem !important; }

.has-8-border-right {
  border-right-width: 0.8rem !important; }

.has-8-border-bottom {
  border-bottom-width: 0.8rem !important; }

.has-8-border-left {
  border-left-width: 0.8rem !important; }

.has-8-font-size {
  font-size: 0.8rem !important; }

.has-8-margin {
  margin: 0.8rem !important; }

.has-8-margin-bottom {
  margin-bottom: 0.8rem !important; }

.has-8-margin-top {
  margin-top: 0.8rem !important; }

.has-8-margin-left {
  margin-left: 0.8rem !important; }

.has-8-margin-right {
  margin-right: 0.8rem !important; }

.has-8-padding {
  padding: 0.8rem !important; }

.has-8-padding-bottom {
  padding-bottom: 0.8rem !important; }

.has-8-padding-top {
  padding-top: 0.8rem !important; }

.has-8-padding-left {
  padding-left: 0.8rem !important; }

.has-8-padding-right {
  padding-right: 0.8rem !important; }

.has-8-font-size {
  font-size: 0.8rem !important; }

.mb9 {
  margin-bottom: 0.9rem !important; }

.mt9 {
  margin-top: 0.9rem !important; }

.ml9 {
  margin-left: 0.9rem !important; }

.mr9 {
  margin-right: 0.9rem !important; }

.has-9-font {
  font-size: 0.9rem !important; }

.has-9-border {
  border-width: 0.9rem !important; }

.has-9-border-top {
  border-top-width: 0.9rem !important; }

.has-9-border-right {
  border-right-width: 0.9rem !important; }

.has-9-border-bottom {
  border-bottom-width: 0.9rem !important; }

.has-9-border-left {
  border-left-width: 0.9rem !important; }

.has-9-font-size {
  font-size: 0.9rem !important; }

.has-9-margin {
  margin: 0.9rem !important; }

.has-9-margin-bottom {
  margin-bottom: 0.9rem !important; }

.has-9-margin-top {
  margin-top: 0.9rem !important; }

.has-9-margin-left {
  margin-left: 0.9rem !important; }

.has-9-margin-right {
  margin-right: 0.9rem !important; }

.has-9-padding {
  padding: 0.9rem !important; }

.has-9-padding-bottom {
  padding-bottom: 0.9rem !important; }

.has-9-padding-top {
  padding-top: 0.9rem !important; }

.has-9-padding-left {
  padding-left: 0.9rem !important; }

.has-9-padding-right {
  padding-right: 0.9rem !important; }

.has-9-font-size {
  font-size: 0.9rem !important; }

.mb10 {
  margin-bottom: 1rem !important; }

.mt10 {
  margin-top: 1rem !important; }

.ml10 {
  margin-left: 1rem !important; }

.mr10 {
  margin-right: 1rem !important; }

.has-10-font {
  font-size: 1rem !important; }

.has-10-border {
  border-width: 1rem !important; }

.has-10-border-top {
  border-top-width: 1rem !important; }

.has-10-border-right {
  border-right-width: 1rem !important; }

.has-10-border-bottom {
  border-bottom-width: 1rem !important; }

.has-10-border-left {
  border-left-width: 1rem !important; }

.has-10-font-size {
  font-size: 1rem !important; }

.has-10-margin {
  margin: 1rem !important; }

.has-10-margin-bottom {
  margin-bottom: 1rem !important; }

.has-10-margin-top {
  margin-top: 1rem !important; }

.has-10-margin-left {
  margin-left: 1rem !important; }

.has-10-margin-right {
  margin-right: 1rem !important; }

.has-10-padding {
  padding: 1rem !important; }

.has-10-padding-bottom {
  padding-bottom: 1rem !important; }

.has-10-padding-top {
  padding-top: 1rem !important; }

.has-10-padding-left {
  padding-left: 1rem !important; }

.has-10-padding-right {
  padding-right: 1rem !important; }

.has-10-font-size {
  font-size: 1rem !important; }

.mb11 {
  margin-bottom: 1.1rem !important; }

.mt11 {
  margin-top: 1.1rem !important; }

.ml11 {
  margin-left: 1.1rem !important; }

.mr11 {
  margin-right: 1.1rem !important; }

.has-11-font {
  font-size: 1.1rem !important; }

.has-11-border {
  border-width: 1.1rem !important; }

.has-11-border-top {
  border-top-width: 1.1rem !important; }

.has-11-border-right {
  border-right-width: 1.1rem !important; }

.has-11-border-bottom {
  border-bottom-width: 1.1rem !important; }

.has-11-border-left {
  border-left-width: 1.1rem !important; }

.has-11-font-size {
  font-size: 1.1rem !important; }

.has-11-margin {
  margin: 1.1rem !important; }

.has-11-margin-bottom {
  margin-bottom: 1.1rem !important; }

.has-11-margin-top {
  margin-top: 1.1rem !important; }

.has-11-margin-left {
  margin-left: 1.1rem !important; }

.has-11-margin-right {
  margin-right: 1.1rem !important; }

.has-11-padding {
  padding: 1.1rem !important; }

.has-11-padding-bottom {
  padding-bottom: 1.1rem !important; }

.has-11-padding-top {
  padding-top: 1.1rem !important; }

.has-11-padding-left {
  padding-left: 1.1rem !important; }

.has-11-padding-right {
  padding-right: 1.1rem !important; }

.has-11-font-size {
  font-size: 1.1rem !important; }

.mb12 {
  margin-bottom: 1.2rem !important; }

.mt12 {
  margin-top: 1.2rem !important; }

.ml12 {
  margin-left: 1.2rem !important; }

.mr12 {
  margin-right: 1.2rem !important; }

.has-12-font {
  font-size: 1.2rem !important; }

.has-12-border {
  border-width: 1.2rem !important; }

.has-12-border-top {
  border-top-width: 1.2rem !important; }

.has-12-border-right {
  border-right-width: 1.2rem !important; }

.has-12-border-bottom {
  border-bottom-width: 1.2rem !important; }

.has-12-border-left {
  border-left-width: 1.2rem !important; }

.has-12-font-size {
  font-size: 1.2rem !important; }

.has-12-margin {
  margin: 1.2rem !important; }

.has-12-margin-bottom {
  margin-bottom: 1.2rem !important; }

.has-12-margin-top {
  margin-top: 1.2rem !important; }

.has-12-margin-left {
  margin-left: 1.2rem !important; }

.has-12-margin-right {
  margin-right: 1.2rem !important; }

.has-12-padding {
  padding: 1.2rem !important; }

.has-12-padding-bottom {
  padding-bottom: 1.2rem !important; }

.has-12-padding-top {
  padding-top: 1.2rem !important; }

.has-12-padding-left {
  padding-left: 1.2rem !important; }

.has-12-padding-right {
  padding-right: 1.2rem !important; }

.has-12-font-size {
  font-size: 1.2rem !important; }

.mb13 {
  margin-bottom: 1.3rem !important; }

.mt13 {
  margin-top: 1.3rem !important; }

.ml13 {
  margin-left: 1.3rem !important; }

.mr13 {
  margin-right: 1.3rem !important; }

.has-13-font {
  font-size: 1.3rem !important; }

.has-13-border {
  border-width: 1.3rem !important; }

.has-13-border-top {
  border-top-width: 1.3rem !important; }

.has-13-border-right {
  border-right-width: 1.3rem !important; }

.has-13-border-bottom {
  border-bottom-width: 1.3rem !important; }

.has-13-border-left {
  border-left-width: 1.3rem !important; }

.has-13-font-size {
  font-size: 1.3rem !important; }

.has-13-margin {
  margin: 1.3rem !important; }

.has-13-margin-bottom {
  margin-bottom: 1.3rem !important; }

.has-13-margin-top {
  margin-top: 1.3rem !important; }

.has-13-margin-left {
  margin-left: 1.3rem !important; }

.has-13-margin-right {
  margin-right: 1.3rem !important; }

.has-13-padding {
  padding: 1.3rem !important; }

.has-13-padding-bottom {
  padding-bottom: 1.3rem !important; }

.has-13-padding-top {
  padding-top: 1.3rem !important; }

.has-13-padding-left {
  padding-left: 1.3rem !important; }

.has-13-padding-right {
  padding-right: 1.3rem !important; }

.has-13-font-size {
  font-size: 1.3rem !important; }

.mb14 {
  margin-bottom: 1.4rem !important; }

.mt14 {
  margin-top: 1.4rem !important; }

.ml14 {
  margin-left: 1.4rem !important; }

.mr14 {
  margin-right: 1.4rem !important; }

.has-14-font {
  font-size: 1.4rem !important; }

.has-14-border {
  border-width: 1.4rem !important; }

.has-14-border-top {
  border-top-width: 1.4rem !important; }

.has-14-border-right {
  border-right-width: 1.4rem !important; }

.has-14-border-bottom {
  border-bottom-width: 1.4rem !important; }

.has-14-border-left {
  border-left-width: 1.4rem !important; }

.has-14-font-size {
  font-size: 1.4rem !important; }

.has-14-margin {
  margin: 1.4rem !important; }

.has-14-margin-bottom {
  margin-bottom: 1.4rem !important; }

.has-14-margin-top {
  margin-top: 1.4rem !important; }

.has-14-margin-left {
  margin-left: 1.4rem !important; }

.has-14-margin-right {
  margin-right: 1.4rem !important; }

.has-14-padding {
  padding: 1.4rem !important; }

.has-14-padding-bottom {
  padding-bottom: 1.4rem !important; }

.has-14-padding-top {
  padding-top: 1.4rem !important; }

.has-14-padding-left {
  padding-left: 1.4rem !important; }

.has-14-padding-right {
  padding-right: 1.4rem !important; }

.has-14-font-size {
  font-size: 1.4rem !important; }

.mb15 {
  margin-bottom: 1.5rem !important; }

.mt15 {
  margin-top: 1.5rem !important; }

.ml15 {
  margin-left: 1.5rem !important; }

.mr15 {
  margin-right: 1.5rem !important; }

.has-15-font {
  font-size: 1.5rem !important; }

.has-15-border {
  border-width: 1.5rem !important; }

.has-15-border-top {
  border-top-width: 1.5rem !important; }

.has-15-border-right {
  border-right-width: 1.5rem !important; }

.has-15-border-bottom {
  border-bottom-width: 1.5rem !important; }

.has-15-border-left {
  border-left-width: 1.5rem !important; }

.has-15-font-size {
  font-size: 1.5rem !important; }

.has-15-margin {
  margin: 1.5rem !important; }

.has-15-margin-bottom {
  margin-bottom: 1.5rem !important; }

.has-15-margin-top {
  margin-top: 1.5rem !important; }

.has-15-margin-left {
  margin-left: 1.5rem !important; }

.has-15-margin-right {
  margin-right: 1.5rem !important; }

.has-15-padding {
  padding: 1.5rem !important; }

.has-15-padding-bottom {
  padding-bottom: 1.5rem !important; }

.has-15-padding-top {
  padding-top: 1.5rem !important; }

.has-15-padding-left {
  padding-left: 1.5rem !important; }

.has-15-padding-right {
  padding-right: 1.5rem !important; }

.has-15-font-size {
  font-size: 1.5rem !important; }

.mb16 {
  margin-bottom: 1.6rem !important; }

.mt16 {
  margin-top: 1.6rem !important; }

.ml16 {
  margin-left: 1.6rem !important; }

.mr16 {
  margin-right: 1.6rem !important; }

.has-16-font {
  font-size: 1.6rem !important; }

.has-16-border {
  border-width: 1.6rem !important; }

.has-16-border-top {
  border-top-width: 1.6rem !important; }

.has-16-border-right {
  border-right-width: 1.6rem !important; }

.has-16-border-bottom {
  border-bottom-width: 1.6rem !important; }

.has-16-border-left {
  border-left-width: 1.6rem !important; }

.has-16-font-size {
  font-size: 1.6rem !important; }

.has-16-margin {
  margin: 1.6rem !important; }

.has-16-margin-bottom {
  margin-bottom: 1.6rem !important; }

.has-16-margin-top {
  margin-top: 1.6rem !important; }

.has-16-margin-left {
  margin-left: 1.6rem !important; }

.has-16-margin-right {
  margin-right: 1.6rem !important; }

.has-16-padding {
  padding: 1.6rem !important; }

.has-16-padding-bottom {
  padding-bottom: 1.6rem !important; }

.has-16-padding-top {
  padding-top: 1.6rem !important; }

.has-16-padding-left {
  padding-left: 1.6rem !important; }

.has-16-padding-right {
  padding-right: 1.6rem !important; }

.has-16-font-size {
  font-size: 1.6rem !important; }

.mb17 {
  margin-bottom: 1.7rem !important; }

.mt17 {
  margin-top: 1.7rem !important; }

.ml17 {
  margin-left: 1.7rem !important; }

.mr17 {
  margin-right: 1.7rem !important; }

.has-17-font {
  font-size: 1.7rem !important; }

.has-17-border {
  border-width: 1.7rem !important; }

.has-17-border-top {
  border-top-width: 1.7rem !important; }

.has-17-border-right {
  border-right-width: 1.7rem !important; }

.has-17-border-bottom {
  border-bottom-width: 1.7rem !important; }

.has-17-border-left {
  border-left-width: 1.7rem !important; }

.has-17-font-size {
  font-size: 1.7rem !important; }

.has-17-margin {
  margin: 1.7rem !important; }

.has-17-margin-bottom {
  margin-bottom: 1.7rem !important; }

.has-17-margin-top {
  margin-top: 1.7rem !important; }

.has-17-margin-left {
  margin-left: 1.7rem !important; }

.has-17-margin-right {
  margin-right: 1.7rem !important; }

.has-17-padding {
  padding: 1.7rem !important; }

.has-17-padding-bottom {
  padding-bottom: 1.7rem !important; }

.has-17-padding-top {
  padding-top: 1.7rem !important; }

.has-17-padding-left {
  padding-left: 1.7rem !important; }

.has-17-padding-right {
  padding-right: 1.7rem !important; }

.has-17-font-size {
  font-size: 1.7rem !important; }

.mb18 {
  margin-bottom: 1.8rem !important; }

.mt18 {
  margin-top: 1.8rem !important; }

.ml18 {
  margin-left: 1.8rem !important; }

.mr18 {
  margin-right: 1.8rem !important; }

.has-18-font {
  font-size: 1.8rem !important; }

.has-18-border {
  border-width: 1.8rem !important; }

.has-18-border-top {
  border-top-width: 1.8rem !important; }

.has-18-border-right {
  border-right-width: 1.8rem !important; }

.has-18-border-bottom {
  border-bottom-width: 1.8rem !important; }

.has-18-border-left {
  border-left-width: 1.8rem !important; }

.has-18-font-size {
  font-size: 1.8rem !important; }

.has-18-margin {
  margin: 1.8rem !important; }

.has-18-margin-bottom {
  margin-bottom: 1.8rem !important; }

.has-18-margin-top {
  margin-top: 1.8rem !important; }

.has-18-margin-left {
  margin-left: 1.8rem !important; }

.has-18-margin-right {
  margin-right: 1.8rem !important; }

.has-18-padding {
  padding: 1.8rem !important; }

.has-18-padding-bottom {
  padding-bottom: 1.8rem !important; }

.has-18-padding-top {
  padding-top: 1.8rem !important; }

.has-18-padding-left {
  padding-left: 1.8rem !important; }

.has-18-padding-right {
  padding-right: 1.8rem !important; }

.has-18-font-size {
  font-size: 1.8rem !important; }

.mb19 {
  margin-bottom: 1.9rem !important; }

.mt19 {
  margin-top: 1.9rem !important; }

.ml19 {
  margin-left: 1.9rem !important; }

.mr19 {
  margin-right: 1.9rem !important; }

.has-19-font {
  font-size: 1.9rem !important; }

.has-19-border {
  border-width: 1.9rem !important; }

.has-19-border-top {
  border-top-width: 1.9rem !important; }

.has-19-border-right {
  border-right-width: 1.9rem !important; }

.has-19-border-bottom {
  border-bottom-width: 1.9rem !important; }

.has-19-border-left {
  border-left-width: 1.9rem !important; }

.has-19-font-size {
  font-size: 1.9rem !important; }

.has-19-margin {
  margin: 1.9rem !important; }

.has-19-margin-bottom {
  margin-bottom: 1.9rem !important; }

.has-19-margin-top {
  margin-top: 1.9rem !important; }

.has-19-margin-left {
  margin-left: 1.9rem !important; }

.has-19-margin-right {
  margin-right: 1.9rem !important; }

.has-19-padding {
  padding: 1.9rem !important; }

.has-19-padding-bottom {
  padding-bottom: 1.9rem !important; }

.has-19-padding-top {
  padding-top: 1.9rem !important; }

.has-19-padding-left {
  padding-left: 1.9rem !important; }

.has-19-padding-right {
  padding-right: 1.9rem !important; }

.has-19-font-size {
  font-size: 1.9rem !important; }

.mb20 {
  margin-bottom: 2rem !important; }

.mt20 {
  margin-top: 2rem !important; }

.ml20 {
  margin-left: 2rem !important; }

.mr20 {
  margin-right: 2rem !important; }

.has-20-font {
  font-size: 2rem !important; }

.has-20-border {
  border-width: 2rem !important; }

.has-20-border-top {
  border-top-width: 2rem !important; }

.has-20-border-right {
  border-right-width: 2rem !important; }

.has-20-border-bottom {
  border-bottom-width: 2rem !important; }

.has-20-border-left {
  border-left-width: 2rem !important; }

.has-20-font-size {
  font-size: 2rem !important; }

.has-20-margin {
  margin: 2rem !important; }

.has-20-margin-bottom {
  margin-bottom: 2rem !important; }

.has-20-margin-top {
  margin-top: 2rem !important; }

.has-20-margin-left {
  margin-left: 2rem !important; }

.has-20-margin-right {
  margin-right: 2rem !important; }

.has-20-padding {
  padding: 2rem !important; }

.has-20-padding-bottom {
  padding-bottom: 2rem !important; }

.has-20-padding-top {
  padding-top: 2rem !important; }

.has-20-padding-left {
  padding-left: 2rem !important; }

.has-20-padding-right {
  padding-right: 2rem !important; }

.has-20-font-size {
  font-size: 2rem !important; }

.mb21 {
  margin-bottom: 2.1rem !important; }

.mt21 {
  margin-top: 2.1rem !important; }

.ml21 {
  margin-left: 2.1rem !important; }

.mr21 {
  margin-right: 2.1rem !important; }

.has-21-font {
  font-size: 2.1rem !important; }

.has-21-border {
  border-width: 2.1rem !important; }

.has-21-border-top {
  border-top-width: 2.1rem !important; }

.has-21-border-right {
  border-right-width: 2.1rem !important; }

.has-21-border-bottom {
  border-bottom-width: 2.1rem !important; }

.has-21-border-left {
  border-left-width: 2.1rem !important; }

.has-21-font-size {
  font-size: 2.1rem !important; }

.has-21-margin {
  margin: 2.1rem !important; }

.has-21-margin-bottom {
  margin-bottom: 2.1rem !important; }

.has-21-margin-top {
  margin-top: 2.1rem !important; }

.has-21-margin-left {
  margin-left: 2.1rem !important; }

.has-21-margin-right {
  margin-right: 2.1rem !important; }

.has-21-padding {
  padding: 2.1rem !important; }

.has-21-padding-bottom {
  padding-bottom: 2.1rem !important; }

.has-21-padding-top {
  padding-top: 2.1rem !important; }

.has-21-padding-left {
  padding-left: 2.1rem !important; }

.has-21-padding-right {
  padding-right: 2.1rem !important; }

.has-21-font-size {
  font-size: 2.1rem !important; }

.mb24 {
  margin-bottom: 2.4rem !important; }

.mt24 {
  margin-top: 2.4rem !important; }

.ml24 {
  margin-left: 2.4rem !important; }

.mr24 {
  margin-right: 2.4rem !important; }

.has-24-font {
  font-size: 2.4rem !important; }

.has-24-border {
  border-width: 2.4rem !important; }

.has-24-border-top {
  border-top-width: 2.4rem !important; }

.has-24-border-right {
  border-right-width: 2.4rem !important; }

.has-24-border-bottom {
  border-bottom-width: 2.4rem !important; }

.has-24-border-left {
  border-left-width: 2.4rem !important; }

.has-24-font-size {
  font-size: 2.4rem !important; }

.has-24-margin {
  margin: 2.4rem !important; }

.has-24-margin-bottom {
  margin-bottom: 2.4rem !important; }

.has-24-margin-top {
  margin-top: 2.4rem !important; }

.has-24-margin-left {
  margin-left: 2.4rem !important; }

.has-24-margin-right {
  margin-right: 2.4rem !important; }

.has-24-padding {
  padding: 2.4rem !important; }

.has-24-padding-bottom {
  padding-bottom: 2.4rem !important; }

.has-24-padding-top {
  padding-top: 2.4rem !important; }

.has-24-padding-left {
  padding-left: 2.4rem !important; }

.has-24-padding-right {
  padding-right: 2.4rem !important; }

.has-24-font-size {
  font-size: 2.4rem !important; }

.mb30 {
  margin-bottom: 3rem !important; }

.mt30 {
  margin-top: 3rem !important; }

.ml30 {
  margin-left: 3rem !important; }

.mr30 {
  margin-right: 3rem !important; }

.has-30-font {
  font-size: 3rem !important; }

.has-30-border {
  border-width: 3rem !important; }

.has-30-border-top {
  border-top-width: 3rem !important; }

.has-30-border-right {
  border-right-width: 3rem !important; }

.has-30-border-bottom {
  border-bottom-width: 3rem !important; }

.has-30-border-left {
  border-left-width: 3rem !important; }

.has-30-font-size {
  font-size: 3rem !important; }

.has-30-margin {
  margin: 3rem !important; }

.has-30-margin-bottom {
  margin-bottom: 3rem !important; }

.has-30-margin-top {
  margin-top: 3rem !important; }

.has-30-margin-left {
  margin-left: 3rem !important; }

.has-30-margin-right {
  margin-right: 3rem !important; }

.has-30-padding {
  padding: 3rem !important; }

.has-30-padding-bottom {
  padding-bottom: 3rem !important; }

.has-30-padding-top {
  padding-top: 3rem !important; }

.has-30-padding-left {
  padding-left: 3rem !important; }

.has-30-padding-right {
  padding-right: 3rem !important; }

.has-30-font-size {
  font-size: 3rem !important; }

.mb40 {
  margin-bottom: 4rem !important; }

.mt40 {
  margin-top: 4rem !important; }

.ml40 {
  margin-left: 4rem !important; }

.mr40 {
  margin-right: 4rem !important; }

.has-40-font {
  font-size: 4rem !important; }

.has-40-border {
  border-width: 4rem !important; }

.has-40-border-top {
  border-top-width: 4rem !important; }

.has-40-border-right {
  border-right-width: 4rem !important; }

.has-40-border-bottom {
  border-bottom-width: 4rem !important; }

.has-40-border-left {
  border-left-width: 4rem !important; }

.has-40-font-size {
  font-size: 4rem !important; }

.has-40-margin {
  margin: 4rem !important; }

.has-40-margin-bottom {
  margin-bottom: 4rem !important; }

.has-40-margin-top {
  margin-top: 4rem !important; }

.has-40-margin-left {
  margin-left: 4rem !important; }

.has-40-margin-right {
  margin-right: 4rem !important; }

.has-40-padding {
  padding: 4rem !important; }

.has-40-padding-bottom {
  padding-bottom: 4rem !important; }

.has-40-padding-top {
  padding-top: 4rem !important; }

.has-40-padding-left {
  padding-left: 4rem !important; }

.has-40-padding-right {
  padding-right: 4rem !important; }

.has-40-font-size {
  font-size: 4rem !important; }

.mb50 {
  margin-bottom: 5rem !important; }

.mt50 {
  margin-top: 5rem !important; }

.ml50 {
  margin-left: 5rem !important; }

.mr50 {
  margin-right: 5rem !important; }

.has-50-font {
  font-size: 5rem !important; }

.has-50-border {
  border-width: 5rem !important; }

.has-50-border-top {
  border-top-width: 5rem !important; }

.has-50-border-right {
  border-right-width: 5rem !important; }

.has-50-border-bottom {
  border-bottom-width: 5rem !important; }

.has-50-border-left {
  border-left-width: 5rem !important; }

.has-50-font-size {
  font-size: 5rem !important; }

.has-50-margin {
  margin: 5rem !important; }

.has-50-margin-bottom {
  margin-bottom: 5rem !important; }

.has-50-margin-top {
  margin-top: 5rem !important; }

.has-50-margin-left {
  margin-left: 5rem !important; }

.has-50-margin-right {
  margin-right: 5rem !important; }

.has-50-padding {
  padding: 5rem !important; }

.has-50-padding-bottom {
  padding-bottom: 5rem !important; }

.has-50-padding-top {
  padding-top: 5rem !important; }

.has-50-padding-left {
  padding-left: 5rem !important; }

.has-50-padding-right {
  padding-right: 5rem !important; }

.has-50-font-size {
  font-size: 5rem !important; }

.bold1 {
  font-weight: 100; }

.bold2 {
  font-weight: 200; }

.bold3 {
  font-weight: 300; }

.bold4 {
  font-weight: 400; }

.bold5 {
  font-weight: 500; }

.bold6 {
  font-weight: 600; }

.bold7 {
  font-weight: 700; }

.bold8 {
  font-weight: 800; }

.has-100-duration {
  animation-duration: 100ms !important; }

.has-100-delay {
  animation-delay: 100ms !important; }

.has-200-duration {
  animation-duration: 200ms !important; }

.has-200-delay {
  animation-delay: 200ms !important; }

.has-300-duration {
  animation-duration: 300ms !important; }

.has-300-delay {
  animation-delay: 300ms !important; }

.has-400-duration {
  animation-duration: 400ms !important; }

.has-400-delay {
  animation-delay: 400ms !important; }

.has-500-duration {
  animation-duration: 500ms !important; }

.has-500-delay {
  animation-delay: 500ms !important; }

.has-600-duration {
  animation-duration: 600ms !important; }

.has-600-delay {
  animation-delay: 600ms !important; }

.has-700-duration {
  animation-duration: 700ms !important; }

.has-700-delay {
  animation-delay: 700ms !important; }

.has-800-duration {
  animation-duration: 800ms !important; }

.has-800-delay {
  animation-delay: 800ms !important; }

.has-1000-duration {
  animation-duration: 1000ms !important; }

.has-1000-delay {
  animation-delay: 1000ms !important; }

.has-1500-duration {
  animation-duration: 1500ms !important; }

.has-1500-delay {
  animation-delay: 1500ms !important; }

.has-2000-duration {
  animation-duration: 2000ms !important; }

.has-2000-delay {
  animation-delay: 2000ms !important; }

.has-2500-duration {
  animation-duration: 2500ms !important; }

.has-2500-delay {
  animation-delay: 2500ms !important; }

.has-3000-duration {
  animation-duration: 3000ms !important; }

.has-3000-delay {
  animation-delay: 3000ms !important; }

.has-3500-duration {
  animation-duration: 3500ms !important; }

.has-3500-delay {
  animation-delay: 3500ms !important; }

.has-4000-duration {
  animation-duration: 4000ms !important; }

.has-4000-delay {
  animation-delay: 4000ms !important; }

.has-4500-duration {
  animation-duration: 4500ms !important; }

.has-4500-delay {
  animation-delay: 4500ms !important; }

.has-5000-duration {
  animation-duration: 5000ms !important; }

.has-5000-delay {
  animation-delay: 5000ms !important; }

.is-uppercase {
  text-transform: uppercase; }

.flex-grow {
  flex-grow: 1; }

.flex-grow.is-2 {
  flex-grow: 2; }

.flex-vertical {
  flex-direction: column; }

.flex-horizontal {
  flex-direction: row; }

.flex-horizontal.is-reverse {
  flex-direction: row-reverse; }

.align-self-end {
  align-self: flex-end; }

.align-self-center {
  align-self: center; }

.is-fullheight {
  height: 100%; }

.is-fullwidth:not(.divider):not(.column-divider) {
  width: 100%; }

.scrollable {
  overflow-y: auto;
  position: relative;
  height: calc(100vh - 6rem) !important; }

.is-marginless-right {
  margin-right: 0 !important; }

.is-marginless-left {
  margin-left: 0 !important; }

.is-marginless-bottom {
  margin-bottom: 0 !important; }

.is-marginless-top {
  margin-top: 0 !important; }

.is-paddingless-right {
  padding-right: 0 !important; }

.is-paddingless-left {
  padding-left: 0 !important; }

.is-paddingless-bottom {
  padding-bottom: 0 !important; }

.is-paddingless-top {
  padding-top: 0 !important; }

.is-borderless-right {
  border-right: 0 !important; }

.is-borderless-left {
  border-left: 0 !important; }

.is-borderless-bottom {
  border-bottom: 0 !important; }

.is-borderless-top {
  border-top: 0 !important; }

.is-borderless {
  border: 0 !important; }

.hidden {
  display: none; }

.is-draggable {
  cursor: grab; }

.is-clickable {
  cursor: pointer; }

.is-not-clickable {
  cursor: not-allowed; }

.t100 {
  animation-duration: 100ms; }

.t300 {
  animation-duration: 300ms; }

.t500 {
  animation-duration: 500ms; }

.t1000 {
  animation-duration: 1000ms; }

.bold500 {
  font-weight: 500; }

.bold700 {
  font-weight: 700; }

.is-overlay {
  z-index: 10;
  transition: background 2s ease;
  position: fixed;
  padding-top: 6rem; }

.sortable-vertical {
  z-index: 100; }
  .sortable-vertical,
  .sortable-vertical *,
  .sortable-vertical .is-clickable {
    cursor: row-resize !important; }

.is-list,
.is-boxed {
  box-shadow: 3px 0 0 0 rgba(168, 198, 223, 0.2), inset 1px 0 0 0 rgba(168, 198, 223, 0.8), inset -1px 0 0 0 rgba(168, 198, 223, 0.8); }

.is-overlay.is-loader {
  cursor: progress; }

@keyframes transparent-border-pulsate {
  0% {
    border-color: black; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: black; } }

.has-transparent-border-color {
  border-color: rgba(0, 0, 0, 0); }

.has-transparent-border {
  border: solid 0.1rem rgba(0, 0, 0, 0); }

.has-transparent-border-pulsate {
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-border-left {
  border-left: solid 0.1rem rgba(0, 0, 0, 0); }

.has-transparent-border-right {
  border-right: solid 0.1rem rgba(0, 0, 0, 0); }

.has-transparent-border-top {
  border-top: solid 0.1rem rgba(0, 0, 0, 0); }

.has-transparent-border-bottom {
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0); }

.has-transparent-1-border {
  border: solid 0.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-1-border-pulsate {
  border: solid 0.1rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-1-border-left {
  border-left: solid 0.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-1-border-right {
  border-right: solid 0.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-1-border-top {
  border-top: solid 0.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-1-border-bottom {
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-2-border {
  border: solid 0.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-2-border-pulsate {
  border: solid 0.2rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-2-border-left {
  border-left: solid 0.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-2-border-right {
  border-right: solid 0.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-2-border-top {
  border-top: solid 0.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-2-border-bottom {
  border-bottom: solid 0.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-3-border {
  border: solid 0.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-3-border-pulsate {
  border: solid 0.3rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-3-border-left {
  border-left: solid 0.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-3-border-right {
  border-right: solid 0.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-3-border-top {
  border-top: solid 0.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-3-border-bottom {
  border-bottom: solid 0.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-4-border {
  border: solid 0.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-4-border-pulsate {
  border: solid 0.4rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-4-border-left {
  border-left: solid 0.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-4-border-right {
  border-right: solid 0.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-4-border-top {
  border-top: solid 0.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-4-border-bottom {
  border-bottom: solid 0.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-5-border {
  border: solid 0.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-5-border-pulsate {
  border: solid 0.5rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-5-border-left {
  border-left: solid 0.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-5-border-right {
  border-right: solid 0.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-5-border-top {
  border-top: solid 0.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-5-border-bottom {
  border-bottom: solid 0.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-6-border {
  border: solid 0.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-6-border-pulsate {
  border: solid 0.6rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-6-border-left {
  border-left: solid 0.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-6-border-right {
  border-right: solid 0.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-6-border-top {
  border-top: solid 0.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-6-border-bottom {
  border-bottom: solid 0.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-7-border {
  border: solid 0.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-7-border-pulsate {
  border: solid 0.7rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-7-border-left {
  border-left: solid 0.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-7-border-right {
  border-right: solid 0.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-7-border-top {
  border-top: solid 0.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-7-border-bottom {
  border-bottom: solid 0.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-8-border {
  border: solid 0.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-8-border-pulsate {
  border: solid 0.8rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-8-border-left {
  border-left: solid 0.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-8-border-right {
  border-right: solid 0.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-8-border-top {
  border-top: solid 0.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-8-border-bottom {
  border-bottom: solid 0.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-9-border {
  border: solid 0.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-9-border-pulsate {
  border: solid 0.9rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-9-border-left {
  border-left: solid 0.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-9-border-right {
  border-right: solid 0.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-9-border-top {
  border-top: solid 0.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-9-border-bottom {
  border-bottom: solid 0.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-10-border {
  border: solid 1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-10-border-pulsate {
  border: solid 1rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-10-border-left {
  border-left: solid 1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-10-border-right {
  border-right: solid 1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-10-border-top {
  border-top: solid 1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-10-border-bottom {
  border-bottom: solid 1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-11-border {
  border: solid 1.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-11-border-pulsate {
  border: solid 1.1rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-11-border-left {
  border-left: solid 1.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-11-border-right {
  border-right: solid 1.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-11-border-top {
  border-top: solid 1.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-11-border-bottom {
  border-bottom: solid 1.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-12-border {
  border: solid 1.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-12-border-pulsate {
  border: solid 1.2rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-12-border-left {
  border-left: solid 1.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-12-border-right {
  border-right: solid 1.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-12-border-top {
  border-top: solid 1.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-12-border-bottom {
  border-bottom: solid 1.2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-13-border {
  border: solid 1.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-13-border-pulsate {
  border: solid 1.3rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-13-border-left {
  border-left: solid 1.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-13-border-right {
  border-right: solid 1.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-13-border-top {
  border-top: solid 1.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-13-border-bottom {
  border-bottom: solid 1.3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-14-border {
  border: solid 1.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-14-border-pulsate {
  border: solid 1.4rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-14-border-left {
  border-left: solid 1.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-14-border-right {
  border-right: solid 1.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-14-border-top {
  border-top: solid 1.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-14-border-bottom {
  border-bottom: solid 1.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-15-border {
  border: solid 1.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-15-border-pulsate {
  border: solid 1.5rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-15-border-left {
  border-left: solid 1.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-15-border-right {
  border-right: solid 1.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-15-border-top {
  border-top: solid 1.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-15-border-bottom {
  border-bottom: solid 1.5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-16-border {
  border: solid 1.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-16-border-pulsate {
  border: solid 1.6rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-16-border-left {
  border-left: solid 1.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-16-border-right {
  border-right: solid 1.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-16-border-top {
  border-top: solid 1.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-16-border-bottom {
  border-bottom: solid 1.6rem rgba(0, 0, 0, 0) !important; }

.has-transparent-17-border {
  border: solid 1.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-17-border-pulsate {
  border: solid 1.7rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-17-border-left {
  border-left: solid 1.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-17-border-right {
  border-right: solid 1.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-17-border-top {
  border-top: solid 1.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-17-border-bottom {
  border-bottom: solid 1.7rem rgba(0, 0, 0, 0) !important; }

.has-transparent-18-border {
  border: solid 1.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-18-border-pulsate {
  border: solid 1.8rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-18-border-left {
  border-left: solid 1.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-18-border-right {
  border-right: solid 1.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-18-border-top {
  border-top: solid 1.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-18-border-bottom {
  border-bottom: solid 1.8rem rgba(0, 0, 0, 0) !important; }

.has-transparent-19-border {
  border: solid 1.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-19-border-pulsate {
  border: solid 1.9rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-19-border-left {
  border-left: solid 1.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-19-border-right {
  border-right: solid 1.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-19-border-top {
  border-top: solid 1.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-19-border-bottom {
  border-bottom: solid 1.9rem rgba(0, 0, 0, 0) !important; }

.has-transparent-20-border {
  border: solid 2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-20-border-pulsate {
  border: solid 2rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-20-border-left {
  border-left: solid 2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-20-border-right {
  border-right: solid 2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-20-border-top {
  border-top: solid 2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-20-border-bottom {
  border-bottom: solid 2rem rgba(0, 0, 0, 0) !important; }

.has-transparent-21-border {
  border: solid 2.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-21-border-pulsate {
  border: solid 2.1rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-21-border-left {
  border-left: solid 2.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-21-border-right {
  border-right: solid 2.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-21-border-top {
  border-top: solid 2.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-21-border-bottom {
  border-bottom: solid 2.1rem rgba(0, 0, 0, 0) !important; }

.has-transparent-24-border {
  border: solid 2.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-24-border-pulsate {
  border: solid 2.4rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-24-border-left {
  border-left: solid 2.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-24-border-right {
  border-right: solid 2.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-24-border-top {
  border-top: solid 2.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-24-border-bottom {
  border-bottom: solid 2.4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-30-border {
  border: solid 3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-30-border-pulsate {
  border: solid 3rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-30-border-left {
  border-left: solid 3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-30-border-right {
  border-right: solid 3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-30-border-top {
  border-top: solid 3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-30-border-bottom {
  border-bottom: solid 3rem rgba(0, 0, 0, 0) !important; }

.has-transparent-40-border {
  border: solid 4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-40-border-pulsate {
  border: solid 4rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-40-border-left {
  border-left: solid 4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-40-border-right {
  border-right: solid 4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-40-border-top {
  border-top: solid 4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-40-border-bottom {
  border-bottom: solid 4rem rgba(0, 0, 0, 0) !important; }

.has-transparent-50-border {
  border: solid 5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-50-border-pulsate {
  border: solid 5rem rgba(0, 0, 0, 0) !important;
  animation: transparent-border-pulsate 2s infinite; }

.has-transparent-50-border-left {
  border-left: solid 5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-50-border-right {
  border-right: solid 5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-50-border-top {
  border-top: solid 5rem rgba(0, 0, 0, 0) !important; }

.has-transparent-50-border-bottom {
  border-bottom: solid 5rem rgba(0, 0, 0, 0) !important; }

@keyframes default-border-pulsate {
  0% {
    border-color: #263238; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #263238; } }

.has-default-border-color {
  border-color: #263238; }

.has-default-border {
  border: solid 0.1rem #263238; }

.has-default-border-pulsate {
  animation: default-border-pulsate 2s infinite; }

.has-default-border-left {
  border-left: solid 0.1rem #263238; }

.has-default-border-right {
  border-right: solid 0.1rem #263238; }

.has-default-border-top {
  border-top: solid 0.1rem #263238; }

.has-default-border-bottom {
  border-bottom: solid 0.1rem #263238; }

.has-default-1-border {
  border: solid 0.1rem #263238 !important; }

.has-default-1-border-pulsate {
  border: solid 0.1rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-1-border-left {
  border-left: solid 0.1rem #263238 !important; }

.has-default-1-border-right {
  border-right: solid 0.1rem #263238 !important; }

.has-default-1-border-top {
  border-top: solid 0.1rem #263238 !important; }

.has-default-1-border-bottom {
  border-bottom: solid 0.1rem #263238 !important; }

.has-default-2-border {
  border: solid 0.2rem #263238 !important; }

.has-default-2-border-pulsate {
  border: solid 0.2rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-2-border-left {
  border-left: solid 0.2rem #263238 !important; }

.has-default-2-border-right {
  border-right: solid 0.2rem #263238 !important; }

.has-default-2-border-top {
  border-top: solid 0.2rem #263238 !important; }

.has-default-2-border-bottom {
  border-bottom: solid 0.2rem #263238 !important; }

.has-default-3-border {
  border: solid 0.3rem #263238 !important; }

.has-default-3-border-pulsate {
  border: solid 0.3rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-3-border-left {
  border-left: solid 0.3rem #263238 !important; }

.has-default-3-border-right {
  border-right: solid 0.3rem #263238 !important; }

.has-default-3-border-top {
  border-top: solid 0.3rem #263238 !important; }

.has-default-3-border-bottom {
  border-bottom: solid 0.3rem #263238 !important; }

.has-default-4-border {
  border: solid 0.4rem #263238 !important; }

.has-default-4-border-pulsate {
  border: solid 0.4rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-4-border-left {
  border-left: solid 0.4rem #263238 !important; }

.has-default-4-border-right {
  border-right: solid 0.4rem #263238 !important; }

.has-default-4-border-top {
  border-top: solid 0.4rem #263238 !important; }

.has-default-4-border-bottom {
  border-bottom: solid 0.4rem #263238 !important; }

.has-default-5-border {
  border: solid 0.5rem #263238 !important; }

.has-default-5-border-pulsate {
  border: solid 0.5rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-5-border-left {
  border-left: solid 0.5rem #263238 !important; }

.has-default-5-border-right {
  border-right: solid 0.5rem #263238 !important; }

.has-default-5-border-top {
  border-top: solid 0.5rem #263238 !important; }

.has-default-5-border-bottom {
  border-bottom: solid 0.5rem #263238 !important; }

.has-default-6-border {
  border: solid 0.6rem #263238 !important; }

.has-default-6-border-pulsate {
  border: solid 0.6rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-6-border-left {
  border-left: solid 0.6rem #263238 !important; }

.has-default-6-border-right {
  border-right: solid 0.6rem #263238 !important; }

.has-default-6-border-top {
  border-top: solid 0.6rem #263238 !important; }

.has-default-6-border-bottom {
  border-bottom: solid 0.6rem #263238 !important; }

.has-default-7-border {
  border: solid 0.7rem #263238 !important; }

.has-default-7-border-pulsate {
  border: solid 0.7rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-7-border-left {
  border-left: solid 0.7rem #263238 !important; }

.has-default-7-border-right {
  border-right: solid 0.7rem #263238 !important; }

.has-default-7-border-top {
  border-top: solid 0.7rem #263238 !important; }

.has-default-7-border-bottom {
  border-bottom: solid 0.7rem #263238 !important; }

.has-default-8-border {
  border: solid 0.8rem #263238 !important; }

.has-default-8-border-pulsate {
  border: solid 0.8rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-8-border-left {
  border-left: solid 0.8rem #263238 !important; }

.has-default-8-border-right {
  border-right: solid 0.8rem #263238 !important; }

.has-default-8-border-top {
  border-top: solid 0.8rem #263238 !important; }

.has-default-8-border-bottom {
  border-bottom: solid 0.8rem #263238 !important; }

.has-default-9-border {
  border: solid 0.9rem #263238 !important; }

.has-default-9-border-pulsate {
  border: solid 0.9rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-9-border-left {
  border-left: solid 0.9rem #263238 !important; }

.has-default-9-border-right {
  border-right: solid 0.9rem #263238 !important; }

.has-default-9-border-top {
  border-top: solid 0.9rem #263238 !important; }

.has-default-9-border-bottom {
  border-bottom: solid 0.9rem #263238 !important; }

.has-default-10-border {
  border: solid 1rem #263238 !important; }

.has-default-10-border-pulsate {
  border: solid 1rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-10-border-left {
  border-left: solid 1rem #263238 !important; }

.has-default-10-border-right {
  border-right: solid 1rem #263238 !important; }

.has-default-10-border-top {
  border-top: solid 1rem #263238 !important; }

.has-default-10-border-bottom {
  border-bottom: solid 1rem #263238 !important; }

.has-default-11-border {
  border: solid 1.1rem #263238 !important; }

.has-default-11-border-pulsate {
  border: solid 1.1rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-11-border-left {
  border-left: solid 1.1rem #263238 !important; }

.has-default-11-border-right {
  border-right: solid 1.1rem #263238 !important; }

.has-default-11-border-top {
  border-top: solid 1.1rem #263238 !important; }

.has-default-11-border-bottom {
  border-bottom: solid 1.1rem #263238 !important; }

.has-default-12-border {
  border: solid 1.2rem #263238 !important; }

.has-default-12-border-pulsate {
  border: solid 1.2rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-12-border-left {
  border-left: solid 1.2rem #263238 !important; }

.has-default-12-border-right {
  border-right: solid 1.2rem #263238 !important; }

.has-default-12-border-top {
  border-top: solid 1.2rem #263238 !important; }

.has-default-12-border-bottom {
  border-bottom: solid 1.2rem #263238 !important; }

.has-default-13-border {
  border: solid 1.3rem #263238 !important; }

.has-default-13-border-pulsate {
  border: solid 1.3rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-13-border-left {
  border-left: solid 1.3rem #263238 !important; }

.has-default-13-border-right {
  border-right: solid 1.3rem #263238 !important; }

.has-default-13-border-top {
  border-top: solid 1.3rem #263238 !important; }

.has-default-13-border-bottom {
  border-bottom: solid 1.3rem #263238 !important; }

.has-default-14-border {
  border: solid 1.4rem #263238 !important; }

.has-default-14-border-pulsate {
  border: solid 1.4rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-14-border-left {
  border-left: solid 1.4rem #263238 !important; }

.has-default-14-border-right {
  border-right: solid 1.4rem #263238 !important; }

.has-default-14-border-top {
  border-top: solid 1.4rem #263238 !important; }

.has-default-14-border-bottom {
  border-bottom: solid 1.4rem #263238 !important; }

.has-default-15-border {
  border: solid 1.5rem #263238 !important; }

.has-default-15-border-pulsate {
  border: solid 1.5rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-15-border-left {
  border-left: solid 1.5rem #263238 !important; }

.has-default-15-border-right {
  border-right: solid 1.5rem #263238 !important; }

.has-default-15-border-top {
  border-top: solid 1.5rem #263238 !important; }

.has-default-15-border-bottom {
  border-bottom: solid 1.5rem #263238 !important; }

.has-default-16-border {
  border: solid 1.6rem #263238 !important; }

.has-default-16-border-pulsate {
  border: solid 1.6rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-16-border-left {
  border-left: solid 1.6rem #263238 !important; }

.has-default-16-border-right {
  border-right: solid 1.6rem #263238 !important; }

.has-default-16-border-top {
  border-top: solid 1.6rem #263238 !important; }

.has-default-16-border-bottom {
  border-bottom: solid 1.6rem #263238 !important; }

.has-default-17-border {
  border: solid 1.7rem #263238 !important; }

.has-default-17-border-pulsate {
  border: solid 1.7rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-17-border-left {
  border-left: solid 1.7rem #263238 !important; }

.has-default-17-border-right {
  border-right: solid 1.7rem #263238 !important; }

.has-default-17-border-top {
  border-top: solid 1.7rem #263238 !important; }

.has-default-17-border-bottom {
  border-bottom: solid 1.7rem #263238 !important; }

.has-default-18-border {
  border: solid 1.8rem #263238 !important; }

.has-default-18-border-pulsate {
  border: solid 1.8rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-18-border-left {
  border-left: solid 1.8rem #263238 !important; }

.has-default-18-border-right {
  border-right: solid 1.8rem #263238 !important; }

.has-default-18-border-top {
  border-top: solid 1.8rem #263238 !important; }

.has-default-18-border-bottom {
  border-bottom: solid 1.8rem #263238 !important; }

.has-default-19-border {
  border: solid 1.9rem #263238 !important; }

.has-default-19-border-pulsate {
  border: solid 1.9rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-19-border-left {
  border-left: solid 1.9rem #263238 !important; }

.has-default-19-border-right {
  border-right: solid 1.9rem #263238 !important; }

.has-default-19-border-top {
  border-top: solid 1.9rem #263238 !important; }

.has-default-19-border-bottom {
  border-bottom: solid 1.9rem #263238 !important; }

.has-default-20-border {
  border: solid 2rem #263238 !important; }

.has-default-20-border-pulsate {
  border: solid 2rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-20-border-left {
  border-left: solid 2rem #263238 !important; }

.has-default-20-border-right {
  border-right: solid 2rem #263238 !important; }

.has-default-20-border-top {
  border-top: solid 2rem #263238 !important; }

.has-default-20-border-bottom {
  border-bottom: solid 2rem #263238 !important; }

.has-default-21-border {
  border: solid 2.1rem #263238 !important; }

.has-default-21-border-pulsate {
  border: solid 2.1rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-21-border-left {
  border-left: solid 2.1rem #263238 !important; }

.has-default-21-border-right {
  border-right: solid 2.1rem #263238 !important; }

.has-default-21-border-top {
  border-top: solid 2.1rem #263238 !important; }

.has-default-21-border-bottom {
  border-bottom: solid 2.1rem #263238 !important; }

.has-default-24-border {
  border: solid 2.4rem #263238 !important; }

.has-default-24-border-pulsate {
  border: solid 2.4rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-24-border-left {
  border-left: solid 2.4rem #263238 !important; }

.has-default-24-border-right {
  border-right: solid 2.4rem #263238 !important; }

.has-default-24-border-top {
  border-top: solid 2.4rem #263238 !important; }

.has-default-24-border-bottom {
  border-bottom: solid 2.4rem #263238 !important; }

.has-default-30-border {
  border: solid 3rem #263238 !important; }

.has-default-30-border-pulsate {
  border: solid 3rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-30-border-left {
  border-left: solid 3rem #263238 !important; }

.has-default-30-border-right {
  border-right: solid 3rem #263238 !important; }

.has-default-30-border-top {
  border-top: solid 3rem #263238 !important; }

.has-default-30-border-bottom {
  border-bottom: solid 3rem #263238 !important; }

.has-default-40-border {
  border: solid 4rem #263238 !important; }

.has-default-40-border-pulsate {
  border: solid 4rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-40-border-left {
  border-left: solid 4rem #263238 !important; }

.has-default-40-border-right {
  border-right: solid 4rem #263238 !important; }

.has-default-40-border-top {
  border-top: solid 4rem #263238 !important; }

.has-default-40-border-bottom {
  border-bottom: solid 4rem #263238 !important; }

.has-default-50-border {
  border: solid 5rem #263238 !important; }

.has-default-50-border-pulsate {
  border: solid 5rem #263238 !important;
  animation: default-border-pulsate 2s infinite; }

.has-default-50-border-left {
  border-left: solid 5rem #263238 !important; }

.has-default-50-border-right {
  border-right: solid 5rem #263238 !important; }

.has-default-50-border-top {
  border-top: solid 5rem #263238 !important; }

.has-default-50-border-bottom {
  border-bottom: solid 5rem #263238 !important; }

@keyframes white-border-pulsate {
  0% {
    border-color: white; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: white; } }

.has-white-border-color {
  border-color: #fff; }

.has-white-border {
  border: solid 0.1rem #fff; }

.has-white-border-pulsate {
  animation: white-border-pulsate 2s infinite; }

.has-white-border-left {
  border-left: solid 0.1rem #fff; }

.has-white-border-right {
  border-right: solid 0.1rem #fff; }

.has-white-border-top {
  border-top: solid 0.1rem #fff; }

.has-white-border-bottom {
  border-bottom: solid 0.1rem #fff; }

.has-white-1-border {
  border: solid 0.1rem #fff !important; }

.has-white-1-border-pulsate {
  border: solid 0.1rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-1-border-left {
  border-left: solid 0.1rem #fff !important; }

.has-white-1-border-right {
  border-right: solid 0.1rem #fff !important; }

.has-white-1-border-top {
  border-top: solid 0.1rem #fff !important; }

.has-white-1-border-bottom {
  border-bottom: solid 0.1rem #fff !important; }

.has-white-2-border {
  border: solid 0.2rem #fff !important; }

.has-white-2-border-pulsate {
  border: solid 0.2rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-2-border-left {
  border-left: solid 0.2rem #fff !important; }

.has-white-2-border-right {
  border-right: solid 0.2rem #fff !important; }

.has-white-2-border-top {
  border-top: solid 0.2rem #fff !important; }

.has-white-2-border-bottom {
  border-bottom: solid 0.2rem #fff !important; }

.has-white-3-border {
  border: solid 0.3rem #fff !important; }

.has-white-3-border-pulsate {
  border: solid 0.3rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-3-border-left {
  border-left: solid 0.3rem #fff !important; }

.has-white-3-border-right {
  border-right: solid 0.3rem #fff !important; }

.has-white-3-border-top {
  border-top: solid 0.3rem #fff !important; }

.has-white-3-border-bottom {
  border-bottom: solid 0.3rem #fff !important; }

.has-white-4-border {
  border: solid 0.4rem #fff !important; }

.has-white-4-border-pulsate {
  border: solid 0.4rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-4-border-left {
  border-left: solid 0.4rem #fff !important; }

.has-white-4-border-right {
  border-right: solid 0.4rem #fff !important; }

.has-white-4-border-top {
  border-top: solid 0.4rem #fff !important; }

.has-white-4-border-bottom {
  border-bottom: solid 0.4rem #fff !important; }

.has-white-5-border {
  border: solid 0.5rem #fff !important; }

.has-white-5-border-pulsate {
  border: solid 0.5rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-5-border-left {
  border-left: solid 0.5rem #fff !important; }

.has-white-5-border-right {
  border-right: solid 0.5rem #fff !important; }

.has-white-5-border-top {
  border-top: solid 0.5rem #fff !important; }

.has-white-5-border-bottom {
  border-bottom: solid 0.5rem #fff !important; }

.has-white-6-border {
  border: solid 0.6rem #fff !important; }

.has-white-6-border-pulsate {
  border: solid 0.6rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-6-border-left {
  border-left: solid 0.6rem #fff !important; }

.has-white-6-border-right {
  border-right: solid 0.6rem #fff !important; }

.has-white-6-border-top {
  border-top: solid 0.6rem #fff !important; }

.has-white-6-border-bottom {
  border-bottom: solid 0.6rem #fff !important; }

.has-white-7-border {
  border: solid 0.7rem #fff !important; }

.has-white-7-border-pulsate {
  border: solid 0.7rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-7-border-left {
  border-left: solid 0.7rem #fff !important; }

.has-white-7-border-right {
  border-right: solid 0.7rem #fff !important; }

.has-white-7-border-top {
  border-top: solid 0.7rem #fff !important; }

.has-white-7-border-bottom {
  border-bottom: solid 0.7rem #fff !important; }

.has-white-8-border {
  border: solid 0.8rem #fff !important; }

.has-white-8-border-pulsate {
  border: solid 0.8rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-8-border-left {
  border-left: solid 0.8rem #fff !important; }

.has-white-8-border-right {
  border-right: solid 0.8rem #fff !important; }

.has-white-8-border-top {
  border-top: solid 0.8rem #fff !important; }

.has-white-8-border-bottom {
  border-bottom: solid 0.8rem #fff !important; }

.has-white-9-border {
  border: solid 0.9rem #fff !important; }

.has-white-9-border-pulsate {
  border: solid 0.9rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-9-border-left {
  border-left: solid 0.9rem #fff !important; }

.has-white-9-border-right {
  border-right: solid 0.9rem #fff !important; }

.has-white-9-border-top {
  border-top: solid 0.9rem #fff !important; }

.has-white-9-border-bottom {
  border-bottom: solid 0.9rem #fff !important; }

.has-white-10-border {
  border: solid 1rem #fff !important; }

.has-white-10-border-pulsate {
  border: solid 1rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-10-border-left {
  border-left: solid 1rem #fff !important; }

.has-white-10-border-right {
  border-right: solid 1rem #fff !important; }

.has-white-10-border-top {
  border-top: solid 1rem #fff !important; }

.has-white-10-border-bottom {
  border-bottom: solid 1rem #fff !important; }

.has-white-11-border {
  border: solid 1.1rem #fff !important; }

.has-white-11-border-pulsate {
  border: solid 1.1rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-11-border-left {
  border-left: solid 1.1rem #fff !important; }

.has-white-11-border-right {
  border-right: solid 1.1rem #fff !important; }

.has-white-11-border-top {
  border-top: solid 1.1rem #fff !important; }

.has-white-11-border-bottom {
  border-bottom: solid 1.1rem #fff !important; }

.has-white-12-border {
  border: solid 1.2rem #fff !important; }

.has-white-12-border-pulsate {
  border: solid 1.2rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-12-border-left {
  border-left: solid 1.2rem #fff !important; }

.has-white-12-border-right {
  border-right: solid 1.2rem #fff !important; }

.has-white-12-border-top {
  border-top: solid 1.2rem #fff !important; }

.has-white-12-border-bottom {
  border-bottom: solid 1.2rem #fff !important; }

.has-white-13-border {
  border: solid 1.3rem #fff !important; }

.has-white-13-border-pulsate {
  border: solid 1.3rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-13-border-left {
  border-left: solid 1.3rem #fff !important; }

.has-white-13-border-right {
  border-right: solid 1.3rem #fff !important; }

.has-white-13-border-top {
  border-top: solid 1.3rem #fff !important; }

.has-white-13-border-bottom {
  border-bottom: solid 1.3rem #fff !important; }

.has-white-14-border {
  border: solid 1.4rem #fff !important; }

.has-white-14-border-pulsate {
  border: solid 1.4rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-14-border-left {
  border-left: solid 1.4rem #fff !important; }

.has-white-14-border-right {
  border-right: solid 1.4rem #fff !important; }

.has-white-14-border-top {
  border-top: solid 1.4rem #fff !important; }

.has-white-14-border-bottom {
  border-bottom: solid 1.4rem #fff !important; }

.has-white-15-border {
  border: solid 1.5rem #fff !important; }

.has-white-15-border-pulsate {
  border: solid 1.5rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-15-border-left {
  border-left: solid 1.5rem #fff !important; }

.has-white-15-border-right {
  border-right: solid 1.5rem #fff !important; }

.has-white-15-border-top {
  border-top: solid 1.5rem #fff !important; }

.has-white-15-border-bottom {
  border-bottom: solid 1.5rem #fff !important; }

.has-white-16-border {
  border: solid 1.6rem #fff !important; }

.has-white-16-border-pulsate {
  border: solid 1.6rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-16-border-left {
  border-left: solid 1.6rem #fff !important; }

.has-white-16-border-right {
  border-right: solid 1.6rem #fff !important; }

.has-white-16-border-top {
  border-top: solid 1.6rem #fff !important; }

.has-white-16-border-bottom {
  border-bottom: solid 1.6rem #fff !important; }

.has-white-17-border {
  border: solid 1.7rem #fff !important; }

.has-white-17-border-pulsate {
  border: solid 1.7rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-17-border-left {
  border-left: solid 1.7rem #fff !important; }

.has-white-17-border-right {
  border-right: solid 1.7rem #fff !important; }

.has-white-17-border-top {
  border-top: solid 1.7rem #fff !important; }

.has-white-17-border-bottom {
  border-bottom: solid 1.7rem #fff !important; }

.has-white-18-border {
  border: solid 1.8rem #fff !important; }

.has-white-18-border-pulsate {
  border: solid 1.8rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-18-border-left {
  border-left: solid 1.8rem #fff !important; }

.has-white-18-border-right {
  border-right: solid 1.8rem #fff !important; }

.has-white-18-border-top {
  border-top: solid 1.8rem #fff !important; }

.has-white-18-border-bottom {
  border-bottom: solid 1.8rem #fff !important; }

.has-white-19-border {
  border: solid 1.9rem #fff !important; }

.has-white-19-border-pulsate {
  border: solid 1.9rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-19-border-left {
  border-left: solid 1.9rem #fff !important; }

.has-white-19-border-right {
  border-right: solid 1.9rem #fff !important; }

.has-white-19-border-top {
  border-top: solid 1.9rem #fff !important; }

.has-white-19-border-bottom {
  border-bottom: solid 1.9rem #fff !important; }

.has-white-20-border {
  border: solid 2rem #fff !important; }

.has-white-20-border-pulsate {
  border: solid 2rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-20-border-left {
  border-left: solid 2rem #fff !important; }

.has-white-20-border-right {
  border-right: solid 2rem #fff !important; }

.has-white-20-border-top {
  border-top: solid 2rem #fff !important; }

.has-white-20-border-bottom {
  border-bottom: solid 2rem #fff !important; }

.has-white-21-border {
  border: solid 2.1rem #fff !important; }

.has-white-21-border-pulsate {
  border: solid 2.1rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-21-border-left {
  border-left: solid 2.1rem #fff !important; }

.has-white-21-border-right {
  border-right: solid 2.1rem #fff !important; }

.has-white-21-border-top {
  border-top: solid 2.1rem #fff !important; }

.has-white-21-border-bottom {
  border-bottom: solid 2.1rem #fff !important; }

.has-white-24-border {
  border: solid 2.4rem #fff !important; }

.has-white-24-border-pulsate {
  border: solid 2.4rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-24-border-left {
  border-left: solid 2.4rem #fff !important; }

.has-white-24-border-right {
  border-right: solid 2.4rem #fff !important; }

.has-white-24-border-top {
  border-top: solid 2.4rem #fff !important; }

.has-white-24-border-bottom {
  border-bottom: solid 2.4rem #fff !important; }

.has-white-30-border {
  border: solid 3rem #fff !important; }

.has-white-30-border-pulsate {
  border: solid 3rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-30-border-left {
  border-left: solid 3rem #fff !important; }

.has-white-30-border-right {
  border-right: solid 3rem #fff !important; }

.has-white-30-border-top {
  border-top: solid 3rem #fff !important; }

.has-white-30-border-bottom {
  border-bottom: solid 3rem #fff !important; }

.has-white-40-border {
  border: solid 4rem #fff !important; }

.has-white-40-border-pulsate {
  border: solid 4rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-40-border-left {
  border-left: solid 4rem #fff !important; }

.has-white-40-border-right {
  border-right: solid 4rem #fff !important; }

.has-white-40-border-top {
  border-top: solid 4rem #fff !important; }

.has-white-40-border-bottom {
  border-bottom: solid 4rem #fff !important; }

.has-white-50-border {
  border: solid 5rem #fff !important; }

.has-white-50-border-pulsate {
  border: solid 5rem #fff !important;
  animation: white-border-pulsate 2s infinite; }

.has-white-50-border-left {
  border-left: solid 5rem #fff !important; }

.has-white-50-border-right {
  border-right: solid 5rem #fff !important; }

.has-white-50-border-top {
  border-top: solid 5rem #fff !important; }

.has-white-50-border-bottom {
  border-bottom: solid 5rem #fff !important; }

@keyframes black-border-pulsate {
  0% {
    border-color: #263238; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #263238; } }

.has-black-border-color {
  border-color: #263238; }

.has-black-border {
  border: solid 0.1rem #263238; }

.has-black-border-pulsate {
  animation: black-border-pulsate 2s infinite; }

.has-black-border-left {
  border-left: solid 0.1rem #263238; }

.has-black-border-right {
  border-right: solid 0.1rem #263238; }

.has-black-border-top {
  border-top: solid 0.1rem #263238; }

.has-black-border-bottom {
  border-bottom: solid 0.1rem #263238; }

.has-black-1-border {
  border: solid 0.1rem #263238 !important; }

.has-black-1-border-pulsate {
  border: solid 0.1rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-1-border-left {
  border-left: solid 0.1rem #263238 !important; }

.has-black-1-border-right {
  border-right: solid 0.1rem #263238 !important; }

.has-black-1-border-top {
  border-top: solid 0.1rem #263238 !important; }

.has-black-1-border-bottom {
  border-bottom: solid 0.1rem #263238 !important; }

.has-black-2-border {
  border: solid 0.2rem #263238 !important; }

.has-black-2-border-pulsate {
  border: solid 0.2rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-2-border-left {
  border-left: solid 0.2rem #263238 !important; }

.has-black-2-border-right {
  border-right: solid 0.2rem #263238 !important; }

.has-black-2-border-top {
  border-top: solid 0.2rem #263238 !important; }

.has-black-2-border-bottom {
  border-bottom: solid 0.2rem #263238 !important; }

.has-black-3-border {
  border: solid 0.3rem #263238 !important; }

.has-black-3-border-pulsate {
  border: solid 0.3rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-3-border-left {
  border-left: solid 0.3rem #263238 !important; }

.has-black-3-border-right {
  border-right: solid 0.3rem #263238 !important; }

.has-black-3-border-top {
  border-top: solid 0.3rem #263238 !important; }

.has-black-3-border-bottom {
  border-bottom: solid 0.3rem #263238 !important; }

.has-black-4-border {
  border: solid 0.4rem #263238 !important; }

.has-black-4-border-pulsate {
  border: solid 0.4rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-4-border-left {
  border-left: solid 0.4rem #263238 !important; }

.has-black-4-border-right {
  border-right: solid 0.4rem #263238 !important; }

.has-black-4-border-top {
  border-top: solid 0.4rem #263238 !important; }

.has-black-4-border-bottom {
  border-bottom: solid 0.4rem #263238 !important; }

.has-black-5-border {
  border: solid 0.5rem #263238 !important; }

.has-black-5-border-pulsate {
  border: solid 0.5rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-5-border-left {
  border-left: solid 0.5rem #263238 !important; }

.has-black-5-border-right {
  border-right: solid 0.5rem #263238 !important; }

.has-black-5-border-top {
  border-top: solid 0.5rem #263238 !important; }

.has-black-5-border-bottom {
  border-bottom: solid 0.5rem #263238 !important; }

.has-black-6-border {
  border: solid 0.6rem #263238 !important; }

.has-black-6-border-pulsate {
  border: solid 0.6rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-6-border-left {
  border-left: solid 0.6rem #263238 !important; }

.has-black-6-border-right {
  border-right: solid 0.6rem #263238 !important; }

.has-black-6-border-top {
  border-top: solid 0.6rem #263238 !important; }

.has-black-6-border-bottom {
  border-bottom: solid 0.6rem #263238 !important; }

.has-black-7-border {
  border: solid 0.7rem #263238 !important; }

.has-black-7-border-pulsate {
  border: solid 0.7rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-7-border-left {
  border-left: solid 0.7rem #263238 !important; }

.has-black-7-border-right {
  border-right: solid 0.7rem #263238 !important; }

.has-black-7-border-top {
  border-top: solid 0.7rem #263238 !important; }

.has-black-7-border-bottom {
  border-bottom: solid 0.7rem #263238 !important; }

.has-black-8-border {
  border: solid 0.8rem #263238 !important; }

.has-black-8-border-pulsate {
  border: solid 0.8rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-8-border-left {
  border-left: solid 0.8rem #263238 !important; }

.has-black-8-border-right {
  border-right: solid 0.8rem #263238 !important; }

.has-black-8-border-top {
  border-top: solid 0.8rem #263238 !important; }

.has-black-8-border-bottom {
  border-bottom: solid 0.8rem #263238 !important; }

.has-black-9-border {
  border: solid 0.9rem #263238 !important; }

.has-black-9-border-pulsate {
  border: solid 0.9rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-9-border-left {
  border-left: solid 0.9rem #263238 !important; }

.has-black-9-border-right {
  border-right: solid 0.9rem #263238 !important; }

.has-black-9-border-top {
  border-top: solid 0.9rem #263238 !important; }

.has-black-9-border-bottom {
  border-bottom: solid 0.9rem #263238 !important; }

.has-black-10-border {
  border: solid 1rem #263238 !important; }

.has-black-10-border-pulsate {
  border: solid 1rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-10-border-left {
  border-left: solid 1rem #263238 !important; }

.has-black-10-border-right {
  border-right: solid 1rem #263238 !important; }

.has-black-10-border-top {
  border-top: solid 1rem #263238 !important; }

.has-black-10-border-bottom {
  border-bottom: solid 1rem #263238 !important; }

.has-black-11-border {
  border: solid 1.1rem #263238 !important; }

.has-black-11-border-pulsate {
  border: solid 1.1rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-11-border-left {
  border-left: solid 1.1rem #263238 !important; }

.has-black-11-border-right {
  border-right: solid 1.1rem #263238 !important; }

.has-black-11-border-top {
  border-top: solid 1.1rem #263238 !important; }

.has-black-11-border-bottom {
  border-bottom: solid 1.1rem #263238 !important; }

.has-black-12-border {
  border: solid 1.2rem #263238 !important; }

.has-black-12-border-pulsate {
  border: solid 1.2rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-12-border-left {
  border-left: solid 1.2rem #263238 !important; }

.has-black-12-border-right {
  border-right: solid 1.2rem #263238 !important; }

.has-black-12-border-top {
  border-top: solid 1.2rem #263238 !important; }

.has-black-12-border-bottom {
  border-bottom: solid 1.2rem #263238 !important; }

.has-black-13-border {
  border: solid 1.3rem #263238 !important; }

.has-black-13-border-pulsate {
  border: solid 1.3rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-13-border-left {
  border-left: solid 1.3rem #263238 !important; }

.has-black-13-border-right {
  border-right: solid 1.3rem #263238 !important; }

.has-black-13-border-top {
  border-top: solid 1.3rem #263238 !important; }

.has-black-13-border-bottom {
  border-bottom: solid 1.3rem #263238 !important; }

.has-black-14-border {
  border: solid 1.4rem #263238 !important; }

.has-black-14-border-pulsate {
  border: solid 1.4rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-14-border-left {
  border-left: solid 1.4rem #263238 !important; }

.has-black-14-border-right {
  border-right: solid 1.4rem #263238 !important; }

.has-black-14-border-top {
  border-top: solid 1.4rem #263238 !important; }

.has-black-14-border-bottom {
  border-bottom: solid 1.4rem #263238 !important; }

.has-black-15-border {
  border: solid 1.5rem #263238 !important; }

.has-black-15-border-pulsate {
  border: solid 1.5rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-15-border-left {
  border-left: solid 1.5rem #263238 !important; }

.has-black-15-border-right {
  border-right: solid 1.5rem #263238 !important; }

.has-black-15-border-top {
  border-top: solid 1.5rem #263238 !important; }

.has-black-15-border-bottom {
  border-bottom: solid 1.5rem #263238 !important; }

.has-black-16-border {
  border: solid 1.6rem #263238 !important; }

.has-black-16-border-pulsate {
  border: solid 1.6rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-16-border-left {
  border-left: solid 1.6rem #263238 !important; }

.has-black-16-border-right {
  border-right: solid 1.6rem #263238 !important; }

.has-black-16-border-top {
  border-top: solid 1.6rem #263238 !important; }

.has-black-16-border-bottom {
  border-bottom: solid 1.6rem #263238 !important; }

.has-black-17-border {
  border: solid 1.7rem #263238 !important; }

.has-black-17-border-pulsate {
  border: solid 1.7rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-17-border-left {
  border-left: solid 1.7rem #263238 !important; }

.has-black-17-border-right {
  border-right: solid 1.7rem #263238 !important; }

.has-black-17-border-top {
  border-top: solid 1.7rem #263238 !important; }

.has-black-17-border-bottom {
  border-bottom: solid 1.7rem #263238 !important; }

.has-black-18-border {
  border: solid 1.8rem #263238 !important; }

.has-black-18-border-pulsate {
  border: solid 1.8rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-18-border-left {
  border-left: solid 1.8rem #263238 !important; }

.has-black-18-border-right {
  border-right: solid 1.8rem #263238 !important; }

.has-black-18-border-top {
  border-top: solid 1.8rem #263238 !important; }

.has-black-18-border-bottom {
  border-bottom: solid 1.8rem #263238 !important; }

.has-black-19-border {
  border: solid 1.9rem #263238 !important; }

.has-black-19-border-pulsate {
  border: solid 1.9rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-19-border-left {
  border-left: solid 1.9rem #263238 !important; }

.has-black-19-border-right {
  border-right: solid 1.9rem #263238 !important; }

.has-black-19-border-top {
  border-top: solid 1.9rem #263238 !important; }

.has-black-19-border-bottom {
  border-bottom: solid 1.9rem #263238 !important; }

.has-black-20-border {
  border: solid 2rem #263238 !important; }

.has-black-20-border-pulsate {
  border: solid 2rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-20-border-left {
  border-left: solid 2rem #263238 !important; }

.has-black-20-border-right {
  border-right: solid 2rem #263238 !important; }

.has-black-20-border-top {
  border-top: solid 2rem #263238 !important; }

.has-black-20-border-bottom {
  border-bottom: solid 2rem #263238 !important; }

.has-black-21-border {
  border: solid 2.1rem #263238 !important; }

.has-black-21-border-pulsate {
  border: solid 2.1rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-21-border-left {
  border-left: solid 2.1rem #263238 !important; }

.has-black-21-border-right {
  border-right: solid 2.1rem #263238 !important; }

.has-black-21-border-top {
  border-top: solid 2.1rem #263238 !important; }

.has-black-21-border-bottom {
  border-bottom: solid 2.1rem #263238 !important; }

.has-black-24-border {
  border: solid 2.4rem #263238 !important; }

.has-black-24-border-pulsate {
  border: solid 2.4rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-24-border-left {
  border-left: solid 2.4rem #263238 !important; }

.has-black-24-border-right {
  border-right: solid 2.4rem #263238 !important; }

.has-black-24-border-top {
  border-top: solid 2.4rem #263238 !important; }

.has-black-24-border-bottom {
  border-bottom: solid 2.4rem #263238 !important; }

.has-black-30-border {
  border: solid 3rem #263238 !important; }

.has-black-30-border-pulsate {
  border: solid 3rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-30-border-left {
  border-left: solid 3rem #263238 !important; }

.has-black-30-border-right {
  border-right: solid 3rem #263238 !important; }

.has-black-30-border-top {
  border-top: solid 3rem #263238 !important; }

.has-black-30-border-bottom {
  border-bottom: solid 3rem #263238 !important; }

.has-black-40-border {
  border: solid 4rem #263238 !important; }

.has-black-40-border-pulsate {
  border: solid 4rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-40-border-left {
  border-left: solid 4rem #263238 !important; }

.has-black-40-border-right {
  border-right: solid 4rem #263238 !important; }

.has-black-40-border-top {
  border-top: solid 4rem #263238 !important; }

.has-black-40-border-bottom {
  border-bottom: solid 4rem #263238 !important; }

.has-black-50-border {
  border: solid 5rem #263238 !important; }

.has-black-50-border-pulsate {
  border: solid 5rem #263238 !important;
  animation: black-border-pulsate 2s infinite; }

.has-black-50-border-left {
  border-left: solid 5rem #263238 !important; }

.has-black-50-border-right {
  border-right: solid 5rem #263238 !important; }

.has-black-50-border-top {
  border-top: solid 5rem #263238 !important; }

.has-black-50-border-bottom {
  border-bottom: solid 5rem #263238 !important; }

@keyframes dark-border-pulsate {
  0% {
    border-color: #263238; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #263238; } }

.has-dark-border-color {
  border-color: #263238; }

.has-dark-border {
  border: solid 0.1rem #263238; }

.has-dark-border-pulsate {
  animation: dark-border-pulsate 2s infinite; }

.has-dark-border-left {
  border-left: solid 0.1rem #263238; }

.has-dark-border-right {
  border-right: solid 0.1rem #263238; }

.has-dark-border-top {
  border-top: solid 0.1rem #263238; }

.has-dark-border-bottom {
  border-bottom: solid 0.1rem #263238; }

.has-dark-1-border {
  border: solid 0.1rem #263238 !important; }

.has-dark-1-border-pulsate {
  border: solid 0.1rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-1-border-left {
  border-left: solid 0.1rem #263238 !important; }

.has-dark-1-border-right {
  border-right: solid 0.1rem #263238 !important; }

.has-dark-1-border-top {
  border-top: solid 0.1rem #263238 !important; }

.has-dark-1-border-bottom {
  border-bottom: solid 0.1rem #263238 !important; }

.has-dark-2-border {
  border: solid 0.2rem #263238 !important; }

.has-dark-2-border-pulsate {
  border: solid 0.2rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-2-border-left {
  border-left: solid 0.2rem #263238 !important; }

.has-dark-2-border-right {
  border-right: solid 0.2rem #263238 !important; }

.has-dark-2-border-top {
  border-top: solid 0.2rem #263238 !important; }

.has-dark-2-border-bottom {
  border-bottom: solid 0.2rem #263238 !important; }

.has-dark-3-border {
  border: solid 0.3rem #263238 !important; }

.has-dark-3-border-pulsate {
  border: solid 0.3rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-3-border-left {
  border-left: solid 0.3rem #263238 !important; }

.has-dark-3-border-right {
  border-right: solid 0.3rem #263238 !important; }

.has-dark-3-border-top {
  border-top: solid 0.3rem #263238 !important; }

.has-dark-3-border-bottom {
  border-bottom: solid 0.3rem #263238 !important; }

.has-dark-4-border {
  border: solid 0.4rem #263238 !important; }

.has-dark-4-border-pulsate {
  border: solid 0.4rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-4-border-left {
  border-left: solid 0.4rem #263238 !important; }

.has-dark-4-border-right {
  border-right: solid 0.4rem #263238 !important; }

.has-dark-4-border-top {
  border-top: solid 0.4rem #263238 !important; }

.has-dark-4-border-bottom {
  border-bottom: solid 0.4rem #263238 !important; }

.has-dark-5-border {
  border: solid 0.5rem #263238 !important; }

.has-dark-5-border-pulsate {
  border: solid 0.5rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-5-border-left {
  border-left: solid 0.5rem #263238 !important; }

.has-dark-5-border-right {
  border-right: solid 0.5rem #263238 !important; }

.has-dark-5-border-top {
  border-top: solid 0.5rem #263238 !important; }

.has-dark-5-border-bottom {
  border-bottom: solid 0.5rem #263238 !important; }

.has-dark-6-border {
  border: solid 0.6rem #263238 !important; }

.has-dark-6-border-pulsate {
  border: solid 0.6rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-6-border-left {
  border-left: solid 0.6rem #263238 !important; }

.has-dark-6-border-right {
  border-right: solid 0.6rem #263238 !important; }

.has-dark-6-border-top {
  border-top: solid 0.6rem #263238 !important; }

.has-dark-6-border-bottom {
  border-bottom: solid 0.6rem #263238 !important; }

.has-dark-7-border {
  border: solid 0.7rem #263238 !important; }

.has-dark-7-border-pulsate {
  border: solid 0.7rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-7-border-left {
  border-left: solid 0.7rem #263238 !important; }

.has-dark-7-border-right {
  border-right: solid 0.7rem #263238 !important; }

.has-dark-7-border-top {
  border-top: solid 0.7rem #263238 !important; }

.has-dark-7-border-bottom {
  border-bottom: solid 0.7rem #263238 !important; }

.has-dark-8-border {
  border: solid 0.8rem #263238 !important; }

.has-dark-8-border-pulsate {
  border: solid 0.8rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-8-border-left {
  border-left: solid 0.8rem #263238 !important; }

.has-dark-8-border-right {
  border-right: solid 0.8rem #263238 !important; }

.has-dark-8-border-top {
  border-top: solid 0.8rem #263238 !important; }

.has-dark-8-border-bottom {
  border-bottom: solid 0.8rem #263238 !important; }

.has-dark-9-border {
  border: solid 0.9rem #263238 !important; }

.has-dark-9-border-pulsate {
  border: solid 0.9rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-9-border-left {
  border-left: solid 0.9rem #263238 !important; }

.has-dark-9-border-right {
  border-right: solid 0.9rem #263238 !important; }

.has-dark-9-border-top {
  border-top: solid 0.9rem #263238 !important; }

.has-dark-9-border-bottom {
  border-bottom: solid 0.9rem #263238 !important; }

.has-dark-10-border {
  border: solid 1rem #263238 !important; }

.has-dark-10-border-pulsate {
  border: solid 1rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-10-border-left {
  border-left: solid 1rem #263238 !important; }

.has-dark-10-border-right {
  border-right: solid 1rem #263238 !important; }

.has-dark-10-border-top {
  border-top: solid 1rem #263238 !important; }

.has-dark-10-border-bottom {
  border-bottom: solid 1rem #263238 !important; }

.has-dark-11-border {
  border: solid 1.1rem #263238 !important; }

.has-dark-11-border-pulsate {
  border: solid 1.1rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-11-border-left {
  border-left: solid 1.1rem #263238 !important; }

.has-dark-11-border-right {
  border-right: solid 1.1rem #263238 !important; }

.has-dark-11-border-top {
  border-top: solid 1.1rem #263238 !important; }

.has-dark-11-border-bottom {
  border-bottom: solid 1.1rem #263238 !important; }

.has-dark-12-border {
  border: solid 1.2rem #263238 !important; }

.has-dark-12-border-pulsate {
  border: solid 1.2rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-12-border-left {
  border-left: solid 1.2rem #263238 !important; }

.has-dark-12-border-right {
  border-right: solid 1.2rem #263238 !important; }

.has-dark-12-border-top {
  border-top: solid 1.2rem #263238 !important; }

.has-dark-12-border-bottom {
  border-bottom: solid 1.2rem #263238 !important; }

.has-dark-13-border {
  border: solid 1.3rem #263238 !important; }

.has-dark-13-border-pulsate {
  border: solid 1.3rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-13-border-left {
  border-left: solid 1.3rem #263238 !important; }

.has-dark-13-border-right {
  border-right: solid 1.3rem #263238 !important; }

.has-dark-13-border-top {
  border-top: solid 1.3rem #263238 !important; }

.has-dark-13-border-bottom {
  border-bottom: solid 1.3rem #263238 !important; }

.has-dark-14-border {
  border: solid 1.4rem #263238 !important; }

.has-dark-14-border-pulsate {
  border: solid 1.4rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-14-border-left {
  border-left: solid 1.4rem #263238 !important; }

.has-dark-14-border-right {
  border-right: solid 1.4rem #263238 !important; }

.has-dark-14-border-top {
  border-top: solid 1.4rem #263238 !important; }

.has-dark-14-border-bottom {
  border-bottom: solid 1.4rem #263238 !important; }

.has-dark-15-border {
  border: solid 1.5rem #263238 !important; }

.has-dark-15-border-pulsate {
  border: solid 1.5rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-15-border-left {
  border-left: solid 1.5rem #263238 !important; }

.has-dark-15-border-right {
  border-right: solid 1.5rem #263238 !important; }

.has-dark-15-border-top {
  border-top: solid 1.5rem #263238 !important; }

.has-dark-15-border-bottom {
  border-bottom: solid 1.5rem #263238 !important; }

.has-dark-16-border {
  border: solid 1.6rem #263238 !important; }

.has-dark-16-border-pulsate {
  border: solid 1.6rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-16-border-left {
  border-left: solid 1.6rem #263238 !important; }

.has-dark-16-border-right {
  border-right: solid 1.6rem #263238 !important; }

.has-dark-16-border-top {
  border-top: solid 1.6rem #263238 !important; }

.has-dark-16-border-bottom {
  border-bottom: solid 1.6rem #263238 !important; }

.has-dark-17-border {
  border: solid 1.7rem #263238 !important; }

.has-dark-17-border-pulsate {
  border: solid 1.7rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-17-border-left {
  border-left: solid 1.7rem #263238 !important; }

.has-dark-17-border-right {
  border-right: solid 1.7rem #263238 !important; }

.has-dark-17-border-top {
  border-top: solid 1.7rem #263238 !important; }

.has-dark-17-border-bottom {
  border-bottom: solid 1.7rem #263238 !important; }

.has-dark-18-border {
  border: solid 1.8rem #263238 !important; }

.has-dark-18-border-pulsate {
  border: solid 1.8rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-18-border-left {
  border-left: solid 1.8rem #263238 !important; }

.has-dark-18-border-right {
  border-right: solid 1.8rem #263238 !important; }

.has-dark-18-border-top {
  border-top: solid 1.8rem #263238 !important; }

.has-dark-18-border-bottom {
  border-bottom: solid 1.8rem #263238 !important; }

.has-dark-19-border {
  border: solid 1.9rem #263238 !important; }

.has-dark-19-border-pulsate {
  border: solid 1.9rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-19-border-left {
  border-left: solid 1.9rem #263238 !important; }

.has-dark-19-border-right {
  border-right: solid 1.9rem #263238 !important; }

.has-dark-19-border-top {
  border-top: solid 1.9rem #263238 !important; }

.has-dark-19-border-bottom {
  border-bottom: solid 1.9rem #263238 !important; }

.has-dark-20-border {
  border: solid 2rem #263238 !important; }

.has-dark-20-border-pulsate {
  border: solid 2rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-20-border-left {
  border-left: solid 2rem #263238 !important; }

.has-dark-20-border-right {
  border-right: solid 2rem #263238 !important; }

.has-dark-20-border-top {
  border-top: solid 2rem #263238 !important; }

.has-dark-20-border-bottom {
  border-bottom: solid 2rem #263238 !important; }

.has-dark-21-border {
  border: solid 2.1rem #263238 !important; }

.has-dark-21-border-pulsate {
  border: solid 2.1rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-21-border-left {
  border-left: solid 2.1rem #263238 !important; }

.has-dark-21-border-right {
  border-right: solid 2.1rem #263238 !important; }

.has-dark-21-border-top {
  border-top: solid 2.1rem #263238 !important; }

.has-dark-21-border-bottom {
  border-bottom: solid 2.1rem #263238 !important; }

.has-dark-24-border {
  border: solid 2.4rem #263238 !important; }

.has-dark-24-border-pulsate {
  border: solid 2.4rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-24-border-left {
  border-left: solid 2.4rem #263238 !important; }

.has-dark-24-border-right {
  border-right: solid 2.4rem #263238 !important; }

.has-dark-24-border-top {
  border-top: solid 2.4rem #263238 !important; }

.has-dark-24-border-bottom {
  border-bottom: solid 2.4rem #263238 !important; }

.has-dark-30-border {
  border: solid 3rem #263238 !important; }

.has-dark-30-border-pulsate {
  border: solid 3rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-30-border-left {
  border-left: solid 3rem #263238 !important; }

.has-dark-30-border-right {
  border-right: solid 3rem #263238 !important; }

.has-dark-30-border-top {
  border-top: solid 3rem #263238 !important; }

.has-dark-30-border-bottom {
  border-bottom: solid 3rem #263238 !important; }

.has-dark-40-border {
  border: solid 4rem #263238 !important; }

.has-dark-40-border-pulsate {
  border: solid 4rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-40-border-left {
  border-left: solid 4rem #263238 !important; }

.has-dark-40-border-right {
  border-right: solid 4rem #263238 !important; }

.has-dark-40-border-top {
  border-top: solid 4rem #263238 !important; }

.has-dark-40-border-bottom {
  border-bottom: solid 4rem #263238 !important; }

.has-dark-50-border {
  border: solid 5rem #263238 !important; }

.has-dark-50-border-pulsate {
  border: solid 5rem #263238 !important;
  animation: dark-border-pulsate 2s infinite; }

.has-dark-50-border-left {
  border-left: solid 5rem #263238 !important; }

.has-dark-50-border-right {
  border-right: solid 5rem #263238 !important; }

.has-dark-50-border-top {
  border-top: solid 5rem #263238 !important; }

.has-dark-50-border-bottom {
  border-bottom: solid 5rem #263238 !important; }

@keyframes slate-border-pulsate {
  0% {
    border-color: #455a64; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #455a64; } }

.has-slate-border-color {
  border-color: #455a64; }

.has-slate-border {
  border: solid 0.1rem #455a64; }

.has-slate-border-pulsate {
  animation: slate-border-pulsate 2s infinite; }

.has-slate-border-left {
  border-left: solid 0.1rem #455a64; }

.has-slate-border-right {
  border-right: solid 0.1rem #455a64; }

.has-slate-border-top {
  border-top: solid 0.1rem #455a64; }

.has-slate-border-bottom {
  border-bottom: solid 0.1rem #455a64; }

.has-slate-1-border {
  border: solid 0.1rem #455a64 !important; }

.has-slate-1-border-pulsate {
  border: solid 0.1rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-1-border-left {
  border-left: solid 0.1rem #455a64 !important; }

.has-slate-1-border-right {
  border-right: solid 0.1rem #455a64 !important; }

.has-slate-1-border-top {
  border-top: solid 0.1rem #455a64 !important; }

.has-slate-1-border-bottom {
  border-bottom: solid 0.1rem #455a64 !important; }

.has-slate-2-border {
  border: solid 0.2rem #455a64 !important; }

.has-slate-2-border-pulsate {
  border: solid 0.2rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-2-border-left {
  border-left: solid 0.2rem #455a64 !important; }

.has-slate-2-border-right {
  border-right: solid 0.2rem #455a64 !important; }

.has-slate-2-border-top {
  border-top: solid 0.2rem #455a64 !important; }

.has-slate-2-border-bottom {
  border-bottom: solid 0.2rem #455a64 !important; }

.has-slate-3-border {
  border: solid 0.3rem #455a64 !important; }

.has-slate-3-border-pulsate {
  border: solid 0.3rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-3-border-left {
  border-left: solid 0.3rem #455a64 !important; }

.has-slate-3-border-right {
  border-right: solid 0.3rem #455a64 !important; }

.has-slate-3-border-top {
  border-top: solid 0.3rem #455a64 !important; }

.has-slate-3-border-bottom {
  border-bottom: solid 0.3rem #455a64 !important; }

.has-slate-4-border {
  border: solid 0.4rem #455a64 !important; }

.has-slate-4-border-pulsate {
  border: solid 0.4rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-4-border-left {
  border-left: solid 0.4rem #455a64 !important; }

.has-slate-4-border-right {
  border-right: solid 0.4rem #455a64 !important; }

.has-slate-4-border-top {
  border-top: solid 0.4rem #455a64 !important; }

.has-slate-4-border-bottom {
  border-bottom: solid 0.4rem #455a64 !important; }

.has-slate-5-border {
  border: solid 0.5rem #455a64 !important; }

.has-slate-5-border-pulsate {
  border: solid 0.5rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-5-border-left {
  border-left: solid 0.5rem #455a64 !important; }

.has-slate-5-border-right {
  border-right: solid 0.5rem #455a64 !important; }

.has-slate-5-border-top {
  border-top: solid 0.5rem #455a64 !important; }

.has-slate-5-border-bottom {
  border-bottom: solid 0.5rem #455a64 !important; }

.has-slate-6-border {
  border: solid 0.6rem #455a64 !important; }

.has-slate-6-border-pulsate {
  border: solid 0.6rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-6-border-left {
  border-left: solid 0.6rem #455a64 !important; }

.has-slate-6-border-right {
  border-right: solid 0.6rem #455a64 !important; }

.has-slate-6-border-top {
  border-top: solid 0.6rem #455a64 !important; }

.has-slate-6-border-bottom {
  border-bottom: solid 0.6rem #455a64 !important; }

.has-slate-7-border {
  border: solid 0.7rem #455a64 !important; }

.has-slate-7-border-pulsate {
  border: solid 0.7rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-7-border-left {
  border-left: solid 0.7rem #455a64 !important; }

.has-slate-7-border-right {
  border-right: solid 0.7rem #455a64 !important; }

.has-slate-7-border-top {
  border-top: solid 0.7rem #455a64 !important; }

.has-slate-7-border-bottom {
  border-bottom: solid 0.7rem #455a64 !important; }

.has-slate-8-border {
  border: solid 0.8rem #455a64 !important; }

.has-slate-8-border-pulsate {
  border: solid 0.8rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-8-border-left {
  border-left: solid 0.8rem #455a64 !important; }

.has-slate-8-border-right {
  border-right: solid 0.8rem #455a64 !important; }

.has-slate-8-border-top {
  border-top: solid 0.8rem #455a64 !important; }

.has-slate-8-border-bottom {
  border-bottom: solid 0.8rem #455a64 !important; }

.has-slate-9-border {
  border: solid 0.9rem #455a64 !important; }

.has-slate-9-border-pulsate {
  border: solid 0.9rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-9-border-left {
  border-left: solid 0.9rem #455a64 !important; }

.has-slate-9-border-right {
  border-right: solid 0.9rem #455a64 !important; }

.has-slate-9-border-top {
  border-top: solid 0.9rem #455a64 !important; }

.has-slate-9-border-bottom {
  border-bottom: solid 0.9rem #455a64 !important; }

.has-slate-10-border {
  border: solid 1rem #455a64 !important; }

.has-slate-10-border-pulsate {
  border: solid 1rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-10-border-left {
  border-left: solid 1rem #455a64 !important; }

.has-slate-10-border-right {
  border-right: solid 1rem #455a64 !important; }

.has-slate-10-border-top {
  border-top: solid 1rem #455a64 !important; }

.has-slate-10-border-bottom {
  border-bottom: solid 1rem #455a64 !important; }

.has-slate-11-border {
  border: solid 1.1rem #455a64 !important; }

.has-slate-11-border-pulsate {
  border: solid 1.1rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-11-border-left {
  border-left: solid 1.1rem #455a64 !important; }

.has-slate-11-border-right {
  border-right: solid 1.1rem #455a64 !important; }

.has-slate-11-border-top {
  border-top: solid 1.1rem #455a64 !important; }

.has-slate-11-border-bottom {
  border-bottom: solid 1.1rem #455a64 !important; }

.has-slate-12-border {
  border: solid 1.2rem #455a64 !important; }

.has-slate-12-border-pulsate {
  border: solid 1.2rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-12-border-left {
  border-left: solid 1.2rem #455a64 !important; }

.has-slate-12-border-right {
  border-right: solid 1.2rem #455a64 !important; }

.has-slate-12-border-top {
  border-top: solid 1.2rem #455a64 !important; }

.has-slate-12-border-bottom {
  border-bottom: solid 1.2rem #455a64 !important; }

.has-slate-13-border {
  border: solid 1.3rem #455a64 !important; }

.has-slate-13-border-pulsate {
  border: solid 1.3rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-13-border-left {
  border-left: solid 1.3rem #455a64 !important; }

.has-slate-13-border-right {
  border-right: solid 1.3rem #455a64 !important; }

.has-slate-13-border-top {
  border-top: solid 1.3rem #455a64 !important; }

.has-slate-13-border-bottom {
  border-bottom: solid 1.3rem #455a64 !important; }

.has-slate-14-border {
  border: solid 1.4rem #455a64 !important; }

.has-slate-14-border-pulsate {
  border: solid 1.4rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-14-border-left {
  border-left: solid 1.4rem #455a64 !important; }

.has-slate-14-border-right {
  border-right: solid 1.4rem #455a64 !important; }

.has-slate-14-border-top {
  border-top: solid 1.4rem #455a64 !important; }

.has-slate-14-border-bottom {
  border-bottom: solid 1.4rem #455a64 !important; }

.has-slate-15-border {
  border: solid 1.5rem #455a64 !important; }

.has-slate-15-border-pulsate {
  border: solid 1.5rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-15-border-left {
  border-left: solid 1.5rem #455a64 !important; }

.has-slate-15-border-right {
  border-right: solid 1.5rem #455a64 !important; }

.has-slate-15-border-top {
  border-top: solid 1.5rem #455a64 !important; }

.has-slate-15-border-bottom {
  border-bottom: solid 1.5rem #455a64 !important; }

.has-slate-16-border {
  border: solid 1.6rem #455a64 !important; }

.has-slate-16-border-pulsate {
  border: solid 1.6rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-16-border-left {
  border-left: solid 1.6rem #455a64 !important; }

.has-slate-16-border-right {
  border-right: solid 1.6rem #455a64 !important; }

.has-slate-16-border-top {
  border-top: solid 1.6rem #455a64 !important; }

.has-slate-16-border-bottom {
  border-bottom: solid 1.6rem #455a64 !important; }

.has-slate-17-border {
  border: solid 1.7rem #455a64 !important; }

.has-slate-17-border-pulsate {
  border: solid 1.7rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-17-border-left {
  border-left: solid 1.7rem #455a64 !important; }

.has-slate-17-border-right {
  border-right: solid 1.7rem #455a64 !important; }

.has-slate-17-border-top {
  border-top: solid 1.7rem #455a64 !important; }

.has-slate-17-border-bottom {
  border-bottom: solid 1.7rem #455a64 !important; }

.has-slate-18-border {
  border: solid 1.8rem #455a64 !important; }

.has-slate-18-border-pulsate {
  border: solid 1.8rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-18-border-left {
  border-left: solid 1.8rem #455a64 !important; }

.has-slate-18-border-right {
  border-right: solid 1.8rem #455a64 !important; }

.has-slate-18-border-top {
  border-top: solid 1.8rem #455a64 !important; }

.has-slate-18-border-bottom {
  border-bottom: solid 1.8rem #455a64 !important; }

.has-slate-19-border {
  border: solid 1.9rem #455a64 !important; }

.has-slate-19-border-pulsate {
  border: solid 1.9rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-19-border-left {
  border-left: solid 1.9rem #455a64 !important; }

.has-slate-19-border-right {
  border-right: solid 1.9rem #455a64 !important; }

.has-slate-19-border-top {
  border-top: solid 1.9rem #455a64 !important; }

.has-slate-19-border-bottom {
  border-bottom: solid 1.9rem #455a64 !important; }

.has-slate-20-border {
  border: solid 2rem #455a64 !important; }

.has-slate-20-border-pulsate {
  border: solid 2rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-20-border-left {
  border-left: solid 2rem #455a64 !important; }

.has-slate-20-border-right {
  border-right: solid 2rem #455a64 !important; }

.has-slate-20-border-top {
  border-top: solid 2rem #455a64 !important; }

.has-slate-20-border-bottom {
  border-bottom: solid 2rem #455a64 !important; }

.has-slate-21-border {
  border: solid 2.1rem #455a64 !important; }

.has-slate-21-border-pulsate {
  border: solid 2.1rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-21-border-left {
  border-left: solid 2.1rem #455a64 !important; }

.has-slate-21-border-right {
  border-right: solid 2.1rem #455a64 !important; }

.has-slate-21-border-top {
  border-top: solid 2.1rem #455a64 !important; }

.has-slate-21-border-bottom {
  border-bottom: solid 2.1rem #455a64 !important; }

.has-slate-24-border {
  border: solid 2.4rem #455a64 !important; }

.has-slate-24-border-pulsate {
  border: solid 2.4rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-24-border-left {
  border-left: solid 2.4rem #455a64 !important; }

.has-slate-24-border-right {
  border-right: solid 2.4rem #455a64 !important; }

.has-slate-24-border-top {
  border-top: solid 2.4rem #455a64 !important; }

.has-slate-24-border-bottom {
  border-bottom: solid 2.4rem #455a64 !important; }

.has-slate-30-border {
  border: solid 3rem #455a64 !important; }

.has-slate-30-border-pulsate {
  border: solid 3rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-30-border-left {
  border-left: solid 3rem #455a64 !important; }

.has-slate-30-border-right {
  border-right: solid 3rem #455a64 !important; }

.has-slate-30-border-top {
  border-top: solid 3rem #455a64 !important; }

.has-slate-30-border-bottom {
  border-bottom: solid 3rem #455a64 !important; }

.has-slate-40-border {
  border: solid 4rem #455a64 !important; }

.has-slate-40-border-pulsate {
  border: solid 4rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-40-border-left {
  border-left: solid 4rem #455a64 !important; }

.has-slate-40-border-right {
  border-right: solid 4rem #455a64 !important; }

.has-slate-40-border-top {
  border-top: solid 4rem #455a64 !important; }

.has-slate-40-border-bottom {
  border-bottom: solid 4rem #455a64 !important; }

.has-slate-50-border {
  border: solid 5rem #455a64 !important; }

.has-slate-50-border-pulsate {
  border: solid 5rem #455a64 !important;
  animation: slate-border-pulsate 2s infinite; }

.has-slate-50-border-left {
  border-left: solid 5rem #455a64 !important; }

.has-slate-50-border-right {
  border-right: solid 5rem #455a64 !important; }

.has-slate-50-border-top {
  border-top: solid 5rem #455a64 !important; }

.has-slate-50-border-bottom {
  border-bottom: solid 5rem #455a64 !important; }

@keyframes body-grey-border-pulsate {
  0% {
    border-color: #f2f6f9; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #f2f6f9; } }

.has-body-grey-border-color {
  border-color: #f2f6f9; }

.has-body-grey-border {
  border: solid 0.1rem #f2f6f9; }

.has-body-grey-border-pulsate {
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-border-left {
  border-left: solid 0.1rem #f2f6f9; }

.has-body-grey-border-right {
  border-right: solid 0.1rem #f2f6f9; }

.has-body-grey-border-top {
  border-top: solid 0.1rem #f2f6f9; }

.has-body-grey-border-bottom {
  border-bottom: solid 0.1rem #f2f6f9; }

.has-body-grey-1-border {
  border: solid 0.1rem #f2f6f9 !important; }

.has-body-grey-1-border-pulsate {
  border: solid 0.1rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-1-border-left {
  border-left: solid 0.1rem #f2f6f9 !important; }

.has-body-grey-1-border-right {
  border-right: solid 0.1rem #f2f6f9 !important; }

.has-body-grey-1-border-top {
  border-top: solid 0.1rem #f2f6f9 !important; }

.has-body-grey-1-border-bottom {
  border-bottom: solid 0.1rem #f2f6f9 !important; }

.has-body-grey-2-border {
  border: solid 0.2rem #f2f6f9 !important; }

.has-body-grey-2-border-pulsate {
  border: solid 0.2rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-2-border-left {
  border-left: solid 0.2rem #f2f6f9 !important; }

.has-body-grey-2-border-right {
  border-right: solid 0.2rem #f2f6f9 !important; }

.has-body-grey-2-border-top {
  border-top: solid 0.2rem #f2f6f9 !important; }

.has-body-grey-2-border-bottom {
  border-bottom: solid 0.2rem #f2f6f9 !important; }

.has-body-grey-3-border {
  border: solid 0.3rem #f2f6f9 !important; }

.has-body-grey-3-border-pulsate {
  border: solid 0.3rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-3-border-left {
  border-left: solid 0.3rem #f2f6f9 !important; }

.has-body-grey-3-border-right {
  border-right: solid 0.3rem #f2f6f9 !important; }

.has-body-grey-3-border-top {
  border-top: solid 0.3rem #f2f6f9 !important; }

.has-body-grey-3-border-bottom {
  border-bottom: solid 0.3rem #f2f6f9 !important; }

.has-body-grey-4-border {
  border: solid 0.4rem #f2f6f9 !important; }

.has-body-grey-4-border-pulsate {
  border: solid 0.4rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-4-border-left {
  border-left: solid 0.4rem #f2f6f9 !important; }

.has-body-grey-4-border-right {
  border-right: solid 0.4rem #f2f6f9 !important; }

.has-body-grey-4-border-top {
  border-top: solid 0.4rem #f2f6f9 !important; }

.has-body-grey-4-border-bottom {
  border-bottom: solid 0.4rem #f2f6f9 !important; }

.has-body-grey-5-border {
  border: solid 0.5rem #f2f6f9 !important; }

.has-body-grey-5-border-pulsate {
  border: solid 0.5rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-5-border-left {
  border-left: solid 0.5rem #f2f6f9 !important; }

.has-body-grey-5-border-right {
  border-right: solid 0.5rem #f2f6f9 !important; }

.has-body-grey-5-border-top {
  border-top: solid 0.5rem #f2f6f9 !important; }

.has-body-grey-5-border-bottom {
  border-bottom: solid 0.5rem #f2f6f9 !important; }

.has-body-grey-6-border {
  border: solid 0.6rem #f2f6f9 !important; }

.has-body-grey-6-border-pulsate {
  border: solid 0.6rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-6-border-left {
  border-left: solid 0.6rem #f2f6f9 !important; }

.has-body-grey-6-border-right {
  border-right: solid 0.6rem #f2f6f9 !important; }

.has-body-grey-6-border-top {
  border-top: solid 0.6rem #f2f6f9 !important; }

.has-body-grey-6-border-bottom {
  border-bottom: solid 0.6rem #f2f6f9 !important; }

.has-body-grey-7-border {
  border: solid 0.7rem #f2f6f9 !important; }

.has-body-grey-7-border-pulsate {
  border: solid 0.7rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-7-border-left {
  border-left: solid 0.7rem #f2f6f9 !important; }

.has-body-grey-7-border-right {
  border-right: solid 0.7rem #f2f6f9 !important; }

.has-body-grey-7-border-top {
  border-top: solid 0.7rem #f2f6f9 !important; }

.has-body-grey-7-border-bottom {
  border-bottom: solid 0.7rem #f2f6f9 !important; }

.has-body-grey-8-border {
  border: solid 0.8rem #f2f6f9 !important; }

.has-body-grey-8-border-pulsate {
  border: solid 0.8rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-8-border-left {
  border-left: solid 0.8rem #f2f6f9 !important; }

.has-body-grey-8-border-right {
  border-right: solid 0.8rem #f2f6f9 !important; }

.has-body-grey-8-border-top {
  border-top: solid 0.8rem #f2f6f9 !important; }

.has-body-grey-8-border-bottom {
  border-bottom: solid 0.8rem #f2f6f9 !important; }

.has-body-grey-9-border {
  border: solid 0.9rem #f2f6f9 !important; }

.has-body-grey-9-border-pulsate {
  border: solid 0.9rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-9-border-left {
  border-left: solid 0.9rem #f2f6f9 !important; }

.has-body-grey-9-border-right {
  border-right: solid 0.9rem #f2f6f9 !important; }

.has-body-grey-9-border-top {
  border-top: solid 0.9rem #f2f6f9 !important; }

.has-body-grey-9-border-bottom {
  border-bottom: solid 0.9rem #f2f6f9 !important; }

.has-body-grey-10-border {
  border: solid 1rem #f2f6f9 !important; }

.has-body-grey-10-border-pulsate {
  border: solid 1rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-10-border-left {
  border-left: solid 1rem #f2f6f9 !important; }

.has-body-grey-10-border-right {
  border-right: solid 1rem #f2f6f9 !important; }

.has-body-grey-10-border-top {
  border-top: solid 1rem #f2f6f9 !important; }

.has-body-grey-10-border-bottom {
  border-bottom: solid 1rem #f2f6f9 !important; }

.has-body-grey-11-border {
  border: solid 1.1rem #f2f6f9 !important; }

.has-body-grey-11-border-pulsate {
  border: solid 1.1rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-11-border-left {
  border-left: solid 1.1rem #f2f6f9 !important; }

.has-body-grey-11-border-right {
  border-right: solid 1.1rem #f2f6f9 !important; }

.has-body-grey-11-border-top {
  border-top: solid 1.1rem #f2f6f9 !important; }

.has-body-grey-11-border-bottom {
  border-bottom: solid 1.1rem #f2f6f9 !important; }

.has-body-grey-12-border {
  border: solid 1.2rem #f2f6f9 !important; }

.has-body-grey-12-border-pulsate {
  border: solid 1.2rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-12-border-left {
  border-left: solid 1.2rem #f2f6f9 !important; }

.has-body-grey-12-border-right {
  border-right: solid 1.2rem #f2f6f9 !important; }

.has-body-grey-12-border-top {
  border-top: solid 1.2rem #f2f6f9 !important; }

.has-body-grey-12-border-bottom {
  border-bottom: solid 1.2rem #f2f6f9 !important; }

.has-body-grey-13-border {
  border: solid 1.3rem #f2f6f9 !important; }

.has-body-grey-13-border-pulsate {
  border: solid 1.3rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-13-border-left {
  border-left: solid 1.3rem #f2f6f9 !important; }

.has-body-grey-13-border-right {
  border-right: solid 1.3rem #f2f6f9 !important; }

.has-body-grey-13-border-top {
  border-top: solid 1.3rem #f2f6f9 !important; }

.has-body-grey-13-border-bottom {
  border-bottom: solid 1.3rem #f2f6f9 !important; }

.has-body-grey-14-border {
  border: solid 1.4rem #f2f6f9 !important; }

.has-body-grey-14-border-pulsate {
  border: solid 1.4rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-14-border-left {
  border-left: solid 1.4rem #f2f6f9 !important; }

.has-body-grey-14-border-right {
  border-right: solid 1.4rem #f2f6f9 !important; }

.has-body-grey-14-border-top {
  border-top: solid 1.4rem #f2f6f9 !important; }

.has-body-grey-14-border-bottom {
  border-bottom: solid 1.4rem #f2f6f9 !important; }

.has-body-grey-15-border {
  border: solid 1.5rem #f2f6f9 !important; }

.has-body-grey-15-border-pulsate {
  border: solid 1.5rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-15-border-left {
  border-left: solid 1.5rem #f2f6f9 !important; }

.has-body-grey-15-border-right {
  border-right: solid 1.5rem #f2f6f9 !important; }

.has-body-grey-15-border-top {
  border-top: solid 1.5rem #f2f6f9 !important; }

.has-body-grey-15-border-bottom {
  border-bottom: solid 1.5rem #f2f6f9 !important; }

.has-body-grey-16-border {
  border: solid 1.6rem #f2f6f9 !important; }

.has-body-grey-16-border-pulsate {
  border: solid 1.6rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-16-border-left {
  border-left: solid 1.6rem #f2f6f9 !important; }

.has-body-grey-16-border-right {
  border-right: solid 1.6rem #f2f6f9 !important; }

.has-body-grey-16-border-top {
  border-top: solid 1.6rem #f2f6f9 !important; }

.has-body-grey-16-border-bottom {
  border-bottom: solid 1.6rem #f2f6f9 !important; }

.has-body-grey-17-border {
  border: solid 1.7rem #f2f6f9 !important; }

.has-body-grey-17-border-pulsate {
  border: solid 1.7rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-17-border-left {
  border-left: solid 1.7rem #f2f6f9 !important; }

.has-body-grey-17-border-right {
  border-right: solid 1.7rem #f2f6f9 !important; }

.has-body-grey-17-border-top {
  border-top: solid 1.7rem #f2f6f9 !important; }

.has-body-grey-17-border-bottom {
  border-bottom: solid 1.7rem #f2f6f9 !important; }

.has-body-grey-18-border {
  border: solid 1.8rem #f2f6f9 !important; }

.has-body-grey-18-border-pulsate {
  border: solid 1.8rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-18-border-left {
  border-left: solid 1.8rem #f2f6f9 !important; }

.has-body-grey-18-border-right {
  border-right: solid 1.8rem #f2f6f9 !important; }

.has-body-grey-18-border-top {
  border-top: solid 1.8rem #f2f6f9 !important; }

.has-body-grey-18-border-bottom {
  border-bottom: solid 1.8rem #f2f6f9 !important; }

.has-body-grey-19-border {
  border: solid 1.9rem #f2f6f9 !important; }

.has-body-grey-19-border-pulsate {
  border: solid 1.9rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-19-border-left {
  border-left: solid 1.9rem #f2f6f9 !important; }

.has-body-grey-19-border-right {
  border-right: solid 1.9rem #f2f6f9 !important; }

.has-body-grey-19-border-top {
  border-top: solid 1.9rem #f2f6f9 !important; }

.has-body-grey-19-border-bottom {
  border-bottom: solid 1.9rem #f2f6f9 !important; }

.has-body-grey-20-border {
  border: solid 2rem #f2f6f9 !important; }

.has-body-grey-20-border-pulsate {
  border: solid 2rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-20-border-left {
  border-left: solid 2rem #f2f6f9 !important; }

.has-body-grey-20-border-right {
  border-right: solid 2rem #f2f6f9 !important; }

.has-body-grey-20-border-top {
  border-top: solid 2rem #f2f6f9 !important; }

.has-body-grey-20-border-bottom {
  border-bottom: solid 2rem #f2f6f9 !important; }

.has-body-grey-21-border {
  border: solid 2.1rem #f2f6f9 !important; }

.has-body-grey-21-border-pulsate {
  border: solid 2.1rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-21-border-left {
  border-left: solid 2.1rem #f2f6f9 !important; }

.has-body-grey-21-border-right {
  border-right: solid 2.1rem #f2f6f9 !important; }

.has-body-grey-21-border-top {
  border-top: solid 2.1rem #f2f6f9 !important; }

.has-body-grey-21-border-bottom {
  border-bottom: solid 2.1rem #f2f6f9 !important; }

.has-body-grey-24-border {
  border: solid 2.4rem #f2f6f9 !important; }

.has-body-grey-24-border-pulsate {
  border: solid 2.4rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-24-border-left {
  border-left: solid 2.4rem #f2f6f9 !important; }

.has-body-grey-24-border-right {
  border-right: solid 2.4rem #f2f6f9 !important; }

.has-body-grey-24-border-top {
  border-top: solid 2.4rem #f2f6f9 !important; }

.has-body-grey-24-border-bottom {
  border-bottom: solid 2.4rem #f2f6f9 !important; }

.has-body-grey-30-border {
  border: solid 3rem #f2f6f9 !important; }

.has-body-grey-30-border-pulsate {
  border: solid 3rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-30-border-left {
  border-left: solid 3rem #f2f6f9 !important; }

.has-body-grey-30-border-right {
  border-right: solid 3rem #f2f6f9 !important; }

.has-body-grey-30-border-top {
  border-top: solid 3rem #f2f6f9 !important; }

.has-body-grey-30-border-bottom {
  border-bottom: solid 3rem #f2f6f9 !important; }

.has-body-grey-40-border {
  border: solid 4rem #f2f6f9 !important; }

.has-body-grey-40-border-pulsate {
  border: solid 4rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-40-border-left {
  border-left: solid 4rem #f2f6f9 !important; }

.has-body-grey-40-border-right {
  border-right: solid 4rem #f2f6f9 !important; }

.has-body-grey-40-border-top {
  border-top: solid 4rem #f2f6f9 !important; }

.has-body-grey-40-border-bottom {
  border-bottom: solid 4rem #f2f6f9 !important; }

.has-body-grey-50-border {
  border: solid 5rem #f2f6f9 !important; }

.has-body-grey-50-border-pulsate {
  border: solid 5rem #f2f6f9 !important;
  animation: body-grey-border-pulsate 2s infinite; }

.has-body-grey-50-border-left {
  border-left: solid 5rem #f2f6f9 !important; }

.has-body-grey-50-border-right {
  border-right: solid 5rem #f2f6f9 !important; }

.has-body-grey-50-border-top {
  border-top: solid 5rem #f2f6f9 !important; }

.has-body-grey-50-border-bottom {
  border-bottom: solid 5rem #f2f6f9 !important; }

@keyframes light-blue-grey-border-pulsate {
  0% {
    border-color: #a8c6df; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #a8c6df; } }

.has-light-blue-grey-border-color {
  border-color: #a8c6df; }

.has-light-blue-grey-border {
  border: solid 0.1rem #a8c6df; }

.has-light-blue-grey-border-pulsate {
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-border-left {
  border-left: solid 0.1rem #a8c6df; }

.has-light-blue-grey-border-right {
  border-right: solid 0.1rem #a8c6df; }

.has-light-blue-grey-border-top {
  border-top: solid 0.1rem #a8c6df; }

.has-light-blue-grey-border-bottom {
  border-bottom: solid 0.1rem #a8c6df; }

.has-light-blue-grey-1-border {
  border: solid 0.1rem #a8c6df !important; }

.has-light-blue-grey-1-border-pulsate {
  border: solid 0.1rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-1-border-left {
  border-left: solid 0.1rem #a8c6df !important; }

.has-light-blue-grey-1-border-right {
  border-right: solid 0.1rem #a8c6df !important; }

.has-light-blue-grey-1-border-top {
  border-top: solid 0.1rem #a8c6df !important; }

.has-light-blue-grey-1-border-bottom {
  border-bottom: solid 0.1rem #a8c6df !important; }

.has-light-blue-grey-2-border {
  border: solid 0.2rem #a8c6df !important; }

.has-light-blue-grey-2-border-pulsate {
  border: solid 0.2rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-2-border-left {
  border-left: solid 0.2rem #a8c6df !important; }

.has-light-blue-grey-2-border-right {
  border-right: solid 0.2rem #a8c6df !important; }

.has-light-blue-grey-2-border-top {
  border-top: solid 0.2rem #a8c6df !important; }

.has-light-blue-grey-2-border-bottom {
  border-bottom: solid 0.2rem #a8c6df !important; }

.has-light-blue-grey-3-border {
  border: solid 0.3rem #a8c6df !important; }

.has-light-blue-grey-3-border-pulsate {
  border: solid 0.3rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-3-border-left {
  border-left: solid 0.3rem #a8c6df !important; }

.has-light-blue-grey-3-border-right {
  border-right: solid 0.3rem #a8c6df !important; }

.has-light-blue-grey-3-border-top {
  border-top: solid 0.3rem #a8c6df !important; }

.has-light-blue-grey-3-border-bottom {
  border-bottom: solid 0.3rem #a8c6df !important; }

.has-light-blue-grey-4-border {
  border: solid 0.4rem #a8c6df !important; }

.has-light-blue-grey-4-border-pulsate {
  border: solid 0.4rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-4-border-left {
  border-left: solid 0.4rem #a8c6df !important; }

.has-light-blue-grey-4-border-right {
  border-right: solid 0.4rem #a8c6df !important; }

.has-light-blue-grey-4-border-top {
  border-top: solid 0.4rem #a8c6df !important; }

.has-light-blue-grey-4-border-bottom {
  border-bottom: solid 0.4rem #a8c6df !important; }

.has-light-blue-grey-5-border {
  border: solid 0.5rem #a8c6df !important; }

.has-light-blue-grey-5-border-pulsate {
  border: solid 0.5rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-5-border-left {
  border-left: solid 0.5rem #a8c6df !important; }

.has-light-blue-grey-5-border-right {
  border-right: solid 0.5rem #a8c6df !important; }

.has-light-blue-grey-5-border-top {
  border-top: solid 0.5rem #a8c6df !important; }

.has-light-blue-grey-5-border-bottom {
  border-bottom: solid 0.5rem #a8c6df !important; }

.has-light-blue-grey-6-border {
  border: solid 0.6rem #a8c6df !important; }

.has-light-blue-grey-6-border-pulsate {
  border: solid 0.6rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-6-border-left {
  border-left: solid 0.6rem #a8c6df !important; }

.has-light-blue-grey-6-border-right {
  border-right: solid 0.6rem #a8c6df !important; }

.has-light-blue-grey-6-border-top {
  border-top: solid 0.6rem #a8c6df !important; }

.has-light-blue-grey-6-border-bottom {
  border-bottom: solid 0.6rem #a8c6df !important; }

.has-light-blue-grey-7-border {
  border: solid 0.7rem #a8c6df !important; }

.has-light-blue-grey-7-border-pulsate {
  border: solid 0.7rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-7-border-left {
  border-left: solid 0.7rem #a8c6df !important; }

.has-light-blue-grey-7-border-right {
  border-right: solid 0.7rem #a8c6df !important; }

.has-light-blue-grey-7-border-top {
  border-top: solid 0.7rem #a8c6df !important; }

.has-light-blue-grey-7-border-bottom {
  border-bottom: solid 0.7rem #a8c6df !important; }

.has-light-blue-grey-8-border {
  border: solid 0.8rem #a8c6df !important; }

.has-light-blue-grey-8-border-pulsate {
  border: solid 0.8rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-8-border-left {
  border-left: solid 0.8rem #a8c6df !important; }

.has-light-blue-grey-8-border-right {
  border-right: solid 0.8rem #a8c6df !important; }

.has-light-blue-grey-8-border-top {
  border-top: solid 0.8rem #a8c6df !important; }

.has-light-blue-grey-8-border-bottom {
  border-bottom: solid 0.8rem #a8c6df !important; }

.has-light-blue-grey-9-border {
  border: solid 0.9rem #a8c6df !important; }

.has-light-blue-grey-9-border-pulsate {
  border: solid 0.9rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-9-border-left {
  border-left: solid 0.9rem #a8c6df !important; }

.has-light-blue-grey-9-border-right {
  border-right: solid 0.9rem #a8c6df !important; }

.has-light-blue-grey-9-border-top {
  border-top: solid 0.9rem #a8c6df !important; }

.has-light-blue-grey-9-border-bottom {
  border-bottom: solid 0.9rem #a8c6df !important; }

.has-light-blue-grey-10-border {
  border: solid 1rem #a8c6df !important; }

.has-light-blue-grey-10-border-pulsate {
  border: solid 1rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-10-border-left {
  border-left: solid 1rem #a8c6df !important; }

.has-light-blue-grey-10-border-right {
  border-right: solid 1rem #a8c6df !important; }

.has-light-blue-grey-10-border-top {
  border-top: solid 1rem #a8c6df !important; }

.has-light-blue-grey-10-border-bottom {
  border-bottom: solid 1rem #a8c6df !important; }

.has-light-blue-grey-11-border {
  border: solid 1.1rem #a8c6df !important; }

.has-light-blue-grey-11-border-pulsate {
  border: solid 1.1rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-11-border-left {
  border-left: solid 1.1rem #a8c6df !important; }

.has-light-blue-grey-11-border-right {
  border-right: solid 1.1rem #a8c6df !important; }

.has-light-blue-grey-11-border-top {
  border-top: solid 1.1rem #a8c6df !important; }

.has-light-blue-grey-11-border-bottom {
  border-bottom: solid 1.1rem #a8c6df !important; }

.has-light-blue-grey-12-border {
  border: solid 1.2rem #a8c6df !important; }

.has-light-blue-grey-12-border-pulsate {
  border: solid 1.2rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-12-border-left {
  border-left: solid 1.2rem #a8c6df !important; }

.has-light-blue-grey-12-border-right {
  border-right: solid 1.2rem #a8c6df !important; }

.has-light-blue-grey-12-border-top {
  border-top: solid 1.2rem #a8c6df !important; }

.has-light-blue-grey-12-border-bottom {
  border-bottom: solid 1.2rem #a8c6df !important; }

.has-light-blue-grey-13-border {
  border: solid 1.3rem #a8c6df !important; }

.has-light-blue-grey-13-border-pulsate {
  border: solid 1.3rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-13-border-left {
  border-left: solid 1.3rem #a8c6df !important; }

.has-light-blue-grey-13-border-right {
  border-right: solid 1.3rem #a8c6df !important; }

.has-light-blue-grey-13-border-top {
  border-top: solid 1.3rem #a8c6df !important; }

.has-light-blue-grey-13-border-bottom {
  border-bottom: solid 1.3rem #a8c6df !important; }

.has-light-blue-grey-14-border {
  border: solid 1.4rem #a8c6df !important; }

.has-light-blue-grey-14-border-pulsate {
  border: solid 1.4rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-14-border-left {
  border-left: solid 1.4rem #a8c6df !important; }

.has-light-blue-grey-14-border-right {
  border-right: solid 1.4rem #a8c6df !important; }

.has-light-blue-grey-14-border-top {
  border-top: solid 1.4rem #a8c6df !important; }

.has-light-blue-grey-14-border-bottom {
  border-bottom: solid 1.4rem #a8c6df !important; }

.has-light-blue-grey-15-border {
  border: solid 1.5rem #a8c6df !important; }

.has-light-blue-grey-15-border-pulsate {
  border: solid 1.5rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-15-border-left {
  border-left: solid 1.5rem #a8c6df !important; }

.has-light-blue-grey-15-border-right {
  border-right: solid 1.5rem #a8c6df !important; }

.has-light-blue-grey-15-border-top {
  border-top: solid 1.5rem #a8c6df !important; }

.has-light-blue-grey-15-border-bottom {
  border-bottom: solid 1.5rem #a8c6df !important; }

.has-light-blue-grey-16-border {
  border: solid 1.6rem #a8c6df !important; }

.has-light-blue-grey-16-border-pulsate {
  border: solid 1.6rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-16-border-left {
  border-left: solid 1.6rem #a8c6df !important; }

.has-light-blue-grey-16-border-right {
  border-right: solid 1.6rem #a8c6df !important; }

.has-light-blue-grey-16-border-top {
  border-top: solid 1.6rem #a8c6df !important; }

.has-light-blue-grey-16-border-bottom {
  border-bottom: solid 1.6rem #a8c6df !important; }

.has-light-blue-grey-17-border {
  border: solid 1.7rem #a8c6df !important; }

.has-light-blue-grey-17-border-pulsate {
  border: solid 1.7rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-17-border-left {
  border-left: solid 1.7rem #a8c6df !important; }

.has-light-blue-grey-17-border-right {
  border-right: solid 1.7rem #a8c6df !important; }

.has-light-blue-grey-17-border-top {
  border-top: solid 1.7rem #a8c6df !important; }

.has-light-blue-grey-17-border-bottom {
  border-bottom: solid 1.7rem #a8c6df !important; }

.has-light-blue-grey-18-border {
  border: solid 1.8rem #a8c6df !important; }

.has-light-blue-grey-18-border-pulsate {
  border: solid 1.8rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-18-border-left {
  border-left: solid 1.8rem #a8c6df !important; }

.has-light-blue-grey-18-border-right {
  border-right: solid 1.8rem #a8c6df !important; }

.has-light-blue-grey-18-border-top {
  border-top: solid 1.8rem #a8c6df !important; }

.has-light-blue-grey-18-border-bottom {
  border-bottom: solid 1.8rem #a8c6df !important; }

.has-light-blue-grey-19-border {
  border: solid 1.9rem #a8c6df !important; }

.has-light-blue-grey-19-border-pulsate {
  border: solid 1.9rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-19-border-left {
  border-left: solid 1.9rem #a8c6df !important; }

.has-light-blue-grey-19-border-right {
  border-right: solid 1.9rem #a8c6df !important; }

.has-light-blue-grey-19-border-top {
  border-top: solid 1.9rem #a8c6df !important; }

.has-light-blue-grey-19-border-bottom {
  border-bottom: solid 1.9rem #a8c6df !important; }

.has-light-blue-grey-20-border {
  border: solid 2rem #a8c6df !important; }

.has-light-blue-grey-20-border-pulsate {
  border: solid 2rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-20-border-left {
  border-left: solid 2rem #a8c6df !important; }

.has-light-blue-grey-20-border-right {
  border-right: solid 2rem #a8c6df !important; }

.has-light-blue-grey-20-border-top {
  border-top: solid 2rem #a8c6df !important; }

.has-light-blue-grey-20-border-bottom {
  border-bottom: solid 2rem #a8c6df !important; }

.has-light-blue-grey-21-border {
  border: solid 2.1rem #a8c6df !important; }

.has-light-blue-grey-21-border-pulsate {
  border: solid 2.1rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-21-border-left {
  border-left: solid 2.1rem #a8c6df !important; }

.has-light-blue-grey-21-border-right {
  border-right: solid 2.1rem #a8c6df !important; }

.has-light-blue-grey-21-border-top {
  border-top: solid 2.1rem #a8c6df !important; }

.has-light-blue-grey-21-border-bottom {
  border-bottom: solid 2.1rem #a8c6df !important; }

.has-light-blue-grey-24-border {
  border: solid 2.4rem #a8c6df !important; }

.has-light-blue-grey-24-border-pulsate {
  border: solid 2.4rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-24-border-left {
  border-left: solid 2.4rem #a8c6df !important; }

.has-light-blue-grey-24-border-right {
  border-right: solid 2.4rem #a8c6df !important; }

.has-light-blue-grey-24-border-top {
  border-top: solid 2.4rem #a8c6df !important; }

.has-light-blue-grey-24-border-bottom {
  border-bottom: solid 2.4rem #a8c6df !important; }

.has-light-blue-grey-30-border {
  border: solid 3rem #a8c6df !important; }

.has-light-blue-grey-30-border-pulsate {
  border: solid 3rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-30-border-left {
  border-left: solid 3rem #a8c6df !important; }

.has-light-blue-grey-30-border-right {
  border-right: solid 3rem #a8c6df !important; }

.has-light-blue-grey-30-border-top {
  border-top: solid 3rem #a8c6df !important; }

.has-light-blue-grey-30-border-bottom {
  border-bottom: solid 3rem #a8c6df !important; }

.has-light-blue-grey-40-border {
  border: solid 4rem #a8c6df !important; }

.has-light-blue-grey-40-border-pulsate {
  border: solid 4rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-40-border-left {
  border-left: solid 4rem #a8c6df !important; }

.has-light-blue-grey-40-border-right {
  border-right: solid 4rem #a8c6df !important; }

.has-light-blue-grey-40-border-top {
  border-top: solid 4rem #a8c6df !important; }

.has-light-blue-grey-40-border-bottom {
  border-bottom: solid 4rem #a8c6df !important; }

.has-light-blue-grey-50-border {
  border: solid 5rem #a8c6df !important; }

.has-light-blue-grey-50-border-pulsate {
  border: solid 5rem #a8c6df !important;
  animation: light-blue-grey-border-pulsate 2s infinite; }

.has-light-blue-grey-50-border-left {
  border-left: solid 5rem #a8c6df !important; }

.has-light-blue-grey-50-border-right {
  border-right: solid 5rem #a8c6df !important; }

.has-light-blue-grey-50-border-top {
  border-top: solid 5rem #a8c6df !important; }

.has-light-blue-grey-50-border-bottom {
  border-bottom: solid 5rem #a8c6df !important; }

@keyframes light-blue-grey-20-border-pulsate {
  0% {
    border-color: #a8c6df; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #a8c6df; } }

.has-light-blue-grey-20-border-color {
  border-color: rgba(168, 198, 223, 0.2); }

.has-light-blue-grey-20-border {
  border: solid 0.1rem rgba(168, 198, 223, 0.2); }

.has-light-blue-grey-20-border-pulsate {
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-border-left {
  border-left: solid 0.1rem rgba(168, 198, 223, 0.2); }

.has-light-blue-grey-20-border-right {
  border-right: solid 0.1rem rgba(168, 198, 223, 0.2); }

.has-light-blue-grey-20-border-top {
  border-top: solid 0.1rem rgba(168, 198, 223, 0.2); }

.has-light-blue-grey-20-border-bottom {
  border-bottom: solid 0.1rem rgba(168, 198, 223, 0.2); }

.has-light-blue-grey-20-1-border {
  border: solid 0.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-1-border-pulsate {
  border: solid 0.1rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-1-border-left {
  border-left: solid 0.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-1-border-right {
  border-right: solid 0.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-1-border-top {
  border-top: solid 0.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-1-border-bottom {
  border-bottom: solid 0.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-2-border {
  border: solid 0.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-2-border-pulsate {
  border: solid 0.2rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-2-border-left {
  border-left: solid 0.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-2-border-right {
  border-right: solid 0.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-2-border-top {
  border-top: solid 0.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-2-border-bottom {
  border-bottom: solid 0.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-3-border {
  border: solid 0.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-3-border-pulsate {
  border: solid 0.3rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-3-border-left {
  border-left: solid 0.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-3-border-right {
  border-right: solid 0.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-3-border-top {
  border-top: solid 0.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-3-border-bottom {
  border-bottom: solid 0.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-4-border {
  border: solid 0.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-4-border-pulsate {
  border: solid 0.4rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-4-border-left {
  border-left: solid 0.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-4-border-right {
  border-right: solid 0.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-4-border-top {
  border-top: solid 0.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-4-border-bottom {
  border-bottom: solid 0.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-5-border {
  border: solid 0.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-5-border-pulsate {
  border: solid 0.5rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-5-border-left {
  border-left: solid 0.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-5-border-right {
  border-right: solid 0.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-5-border-top {
  border-top: solid 0.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-5-border-bottom {
  border-bottom: solid 0.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-6-border {
  border: solid 0.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-6-border-pulsate {
  border: solid 0.6rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-6-border-left {
  border-left: solid 0.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-6-border-right {
  border-right: solid 0.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-6-border-top {
  border-top: solid 0.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-6-border-bottom {
  border-bottom: solid 0.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-7-border {
  border: solid 0.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-7-border-pulsate {
  border: solid 0.7rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-7-border-left {
  border-left: solid 0.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-7-border-right {
  border-right: solid 0.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-7-border-top {
  border-top: solid 0.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-7-border-bottom {
  border-bottom: solid 0.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-8-border {
  border: solid 0.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-8-border-pulsate {
  border: solid 0.8rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-8-border-left {
  border-left: solid 0.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-8-border-right {
  border-right: solid 0.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-8-border-top {
  border-top: solid 0.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-8-border-bottom {
  border-bottom: solid 0.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-9-border {
  border: solid 0.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-9-border-pulsate {
  border: solid 0.9rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-9-border-left {
  border-left: solid 0.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-9-border-right {
  border-right: solid 0.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-9-border-top {
  border-top: solid 0.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-9-border-bottom {
  border-bottom: solid 0.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-10-border {
  border: solid 1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-10-border-pulsate {
  border: solid 1rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-10-border-left {
  border-left: solid 1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-10-border-right {
  border-right: solid 1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-10-border-top {
  border-top: solid 1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-10-border-bottom {
  border-bottom: solid 1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-11-border {
  border: solid 1.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-11-border-pulsate {
  border: solid 1.1rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-11-border-left {
  border-left: solid 1.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-11-border-right {
  border-right: solid 1.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-11-border-top {
  border-top: solid 1.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-11-border-bottom {
  border-bottom: solid 1.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-12-border {
  border: solid 1.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-12-border-pulsate {
  border: solid 1.2rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-12-border-left {
  border-left: solid 1.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-12-border-right {
  border-right: solid 1.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-12-border-top {
  border-top: solid 1.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-12-border-bottom {
  border-bottom: solid 1.2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-13-border {
  border: solid 1.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-13-border-pulsate {
  border: solid 1.3rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-13-border-left {
  border-left: solid 1.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-13-border-right {
  border-right: solid 1.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-13-border-top {
  border-top: solid 1.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-13-border-bottom {
  border-bottom: solid 1.3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-14-border {
  border: solid 1.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-14-border-pulsate {
  border: solid 1.4rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-14-border-left {
  border-left: solid 1.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-14-border-right {
  border-right: solid 1.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-14-border-top {
  border-top: solid 1.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-14-border-bottom {
  border-bottom: solid 1.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-15-border {
  border: solid 1.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-15-border-pulsate {
  border: solid 1.5rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-15-border-left {
  border-left: solid 1.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-15-border-right {
  border-right: solid 1.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-15-border-top {
  border-top: solid 1.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-15-border-bottom {
  border-bottom: solid 1.5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-16-border {
  border: solid 1.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-16-border-pulsate {
  border: solid 1.6rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-16-border-left {
  border-left: solid 1.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-16-border-right {
  border-right: solid 1.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-16-border-top {
  border-top: solid 1.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-16-border-bottom {
  border-bottom: solid 1.6rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-17-border {
  border: solid 1.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-17-border-pulsate {
  border: solid 1.7rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-17-border-left {
  border-left: solid 1.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-17-border-right {
  border-right: solid 1.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-17-border-top {
  border-top: solid 1.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-17-border-bottom {
  border-bottom: solid 1.7rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-18-border {
  border: solid 1.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-18-border-pulsate {
  border: solid 1.8rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-18-border-left {
  border-left: solid 1.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-18-border-right {
  border-right: solid 1.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-18-border-top {
  border-top: solid 1.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-18-border-bottom {
  border-bottom: solid 1.8rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-19-border {
  border: solid 1.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-19-border-pulsate {
  border: solid 1.9rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-19-border-left {
  border-left: solid 1.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-19-border-right {
  border-right: solid 1.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-19-border-top {
  border-top: solid 1.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-19-border-bottom {
  border-bottom: solid 1.9rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-20-border {
  border: solid 2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-20-border-pulsate {
  border: solid 2rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-20-border-left {
  border-left: solid 2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-20-border-right {
  border-right: solid 2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-20-border-top {
  border-top: solid 2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-20-border-bottom {
  border-bottom: solid 2rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-21-border {
  border: solid 2.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-21-border-pulsate {
  border: solid 2.1rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-21-border-left {
  border-left: solid 2.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-21-border-right {
  border-right: solid 2.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-21-border-top {
  border-top: solid 2.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-21-border-bottom {
  border-bottom: solid 2.1rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-24-border {
  border: solid 2.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-24-border-pulsate {
  border: solid 2.4rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-24-border-left {
  border-left: solid 2.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-24-border-right {
  border-right: solid 2.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-24-border-top {
  border-top: solid 2.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-24-border-bottom {
  border-bottom: solid 2.4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-30-border {
  border: solid 3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-30-border-pulsate {
  border: solid 3rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-30-border-left {
  border-left: solid 3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-30-border-right {
  border-right: solid 3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-30-border-top {
  border-top: solid 3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-30-border-bottom {
  border-bottom: solid 3rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-40-border {
  border: solid 4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-40-border-pulsate {
  border: solid 4rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-40-border-left {
  border-left: solid 4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-40-border-right {
  border-right: solid 4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-40-border-top {
  border-top: solid 4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-40-border-bottom {
  border-bottom: solid 4rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-50-border {
  border: solid 5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-50-border-pulsate {
  border: solid 5rem rgba(168, 198, 223, 0.2) !important;
  animation: light-blue-grey-20-border-pulsate 2s infinite; }

.has-light-blue-grey-20-50-border-left {
  border-left: solid 5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-50-border-right {
  border-right: solid 5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-50-border-top {
  border-top: solid 5rem rgba(168, 198, 223, 0.2) !important; }

.has-light-blue-grey-20-50-border-bottom {
  border-bottom: solid 5rem rgba(168, 198, 223, 0.2) !important; }

@keyframes light-blue-grey-80-border-pulsate {
  0% {
    border-color: #a8c6df; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #a8c6df; } }

.has-light-blue-grey-80-border-color {
  border-color: rgba(168, 198, 223, 0.8); }

.has-light-blue-grey-80-border {
  border: solid 0.1rem rgba(168, 198, 223, 0.8); }

.has-light-blue-grey-80-border-pulsate {
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-border-left {
  border-left: solid 0.1rem rgba(168, 198, 223, 0.8); }

.has-light-blue-grey-80-border-right {
  border-right: solid 0.1rem rgba(168, 198, 223, 0.8); }

.has-light-blue-grey-80-border-top {
  border-top: solid 0.1rem rgba(168, 198, 223, 0.8); }

.has-light-blue-grey-80-border-bottom {
  border-bottom: solid 0.1rem rgba(168, 198, 223, 0.8); }

.has-light-blue-grey-80-1-border {
  border: solid 0.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-1-border-pulsate {
  border: solid 0.1rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-1-border-left {
  border-left: solid 0.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-1-border-right {
  border-right: solid 0.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-1-border-top {
  border-top: solid 0.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-1-border-bottom {
  border-bottom: solid 0.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-2-border {
  border: solid 0.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-2-border-pulsate {
  border: solid 0.2rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-2-border-left {
  border-left: solid 0.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-2-border-right {
  border-right: solid 0.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-2-border-top {
  border-top: solid 0.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-2-border-bottom {
  border-bottom: solid 0.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-3-border {
  border: solid 0.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-3-border-pulsate {
  border: solid 0.3rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-3-border-left {
  border-left: solid 0.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-3-border-right {
  border-right: solid 0.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-3-border-top {
  border-top: solid 0.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-3-border-bottom {
  border-bottom: solid 0.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-4-border {
  border: solid 0.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-4-border-pulsate {
  border: solid 0.4rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-4-border-left {
  border-left: solid 0.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-4-border-right {
  border-right: solid 0.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-4-border-top {
  border-top: solid 0.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-4-border-bottom {
  border-bottom: solid 0.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-5-border {
  border: solid 0.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-5-border-pulsate {
  border: solid 0.5rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-5-border-left {
  border-left: solid 0.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-5-border-right {
  border-right: solid 0.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-5-border-top {
  border-top: solid 0.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-5-border-bottom {
  border-bottom: solid 0.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-6-border {
  border: solid 0.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-6-border-pulsate {
  border: solid 0.6rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-6-border-left {
  border-left: solid 0.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-6-border-right {
  border-right: solid 0.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-6-border-top {
  border-top: solid 0.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-6-border-bottom {
  border-bottom: solid 0.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-7-border {
  border: solid 0.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-7-border-pulsate {
  border: solid 0.7rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-7-border-left {
  border-left: solid 0.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-7-border-right {
  border-right: solid 0.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-7-border-top {
  border-top: solid 0.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-7-border-bottom {
  border-bottom: solid 0.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-8-border {
  border: solid 0.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-8-border-pulsate {
  border: solid 0.8rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-8-border-left {
  border-left: solid 0.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-8-border-right {
  border-right: solid 0.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-8-border-top {
  border-top: solid 0.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-8-border-bottom {
  border-bottom: solid 0.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-9-border {
  border: solid 0.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-9-border-pulsate {
  border: solid 0.9rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-9-border-left {
  border-left: solid 0.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-9-border-right {
  border-right: solid 0.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-9-border-top {
  border-top: solid 0.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-9-border-bottom {
  border-bottom: solid 0.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-10-border {
  border: solid 1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-10-border-pulsate {
  border: solid 1rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-10-border-left {
  border-left: solid 1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-10-border-right {
  border-right: solid 1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-10-border-top {
  border-top: solid 1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-10-border-bottom {
  border-bottom: solid 1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-11-border {
  border: solid 1.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-11-border-pulsate {
  border: solid 1.1rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-11-border-left {
  border-left: solid 1.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-11-border-right {
  border-right: solid 1.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-11-border-top {
  border-top: solid 1.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-11-border-bottom {
  border-bottom: solid 1.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-12-border {
  border: solid 1.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-12-border-pulsate {
  border: solid 1.2rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-12-border-left {
  border-left: solid 1.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-12-border-right {
  border-right: solid 1.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-12-border-top {
  border-top: solid 1.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-12-border-bottom {
  border-bottom: solid 1.2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-13-border {
  border: solid 1.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-13-border-pulsate {
  border: solid 1.3rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-13-border-left {
  border-left: solid 1.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-13-border-right {
  border-right: solid 1.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-13-border-top {
  border-top: solid 1.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-13-border-bottom {
  border-bottom: solid 1.3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-14-border {
  border: solid 1.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-14-border-pulsate {
  border: solid 1.4rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-14-border-left {
  border-left: solid 1.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-14-border-right {
  border-right: solid 1.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-14-border-top {
  border-top: solid 1.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-14-border-bottom {
  border-bottom: solid 1.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-15-border {
  border: solid 1.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-15-border-pulsate {
  border: solid 1.5rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-15-border-left {
  border-left: solid 1.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-15-border-right {
  border-right: solid 1.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-15-border-top {
  border-top: solid 1.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-15-border-bottom {
  border-bottom: solid 1.5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-16-border {
  border: solid 1.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-16-border-pulsate {
  border: solid 1.6rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-16-border-left {
  border-left: solid 1.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-16-border-right {
  border-right: solid 1.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-16-border-top {
  border-top: solid 1.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-16-border-bottom {
  border-bottom: solid 1.6rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-17-border {
  border: solid 1.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-17-border-pulsate {
  border: solid 1.7rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-17-border-left {
  border-left: solid 1.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-17-border-right {
  border-right: solid 1.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-17-border-top {
  border-top: solid 1.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-17-border-bottom {
  border-bottom: solid 1.7rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-18-border {
  border: solid 1.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-18-border-pulsate {
  border: solid 1.8rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-18-border-left {
  border-left: solid 1.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-18-border-right {
  border-right: solid 1.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-18-border-top {
  border-top: solid 1.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-18-border-bottom {
  border-bottom: solid 1.8rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-19-border {
  border: solid 1.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-19-border-pulsate {
  border: solid 1.9rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-19-border-left {
  border-left: solid 1.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-19-border-right {
  border-right: solid 1.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-19-border-top {
  border-top: solid 1.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-19-border-bottom {
  border-bottom: solid 1.9rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-20-border {
  border: solid 2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-20-border-pulsate {
  border: solid 2rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-20-border-left {
  border-left: solid 2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-20-border-right {
  border-right: solid 2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-20-border-top {
  border-top: solid 2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-20-border-bottom {
  border-bottom: solid 2rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-21-border {
  border: solid 2.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-21-border-pulsate {
  border: solid 2.1rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-21-border-left {
  border-left: solid 2.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-21-border-right {
  border-right: solid 2.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-21-border-top {
  border-top: solid 2.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-21-border-bottom {
  border-bottom: solid 2.1rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-24-border {
  border: solid 2.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-24-border-pulsate {
  border: solid 2.4rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-24-border-left {
  border-left: solid 2.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-24-border-right {
  border-right: solid 2.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-24-border-top {
  border-top: solid 2.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-24-border-bottom {
  border-bottom: solid 2.4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-30-border {
  border: solid 3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-30-border-pulsate {
  border: solid 3rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-30-border-left {
  border-left: solid 3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-30-border-right {
  border-right: solid 3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-30-border-top {
  border-top: solid 3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-30-border-bottom {
  border-bottom: solid 3rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-40-border {
  border: solid 4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-40-border-pulsate {
  border: solid 4rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-40-border-left {
  border-left: solid 4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-40-border-right {
  border-right: solid 4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-40-border-top {
  border-top: solid 4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-40-border-bottom {
  border-bottom: solid 4rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-50-border {
  border: solid 5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-50-border-pulsate {
  border: solid 5rem rgba(168, 198, 223, 0.8) !important;
  animation: light-blue-grey-80-border-pulsate 2s infinite; }

.has-light-blue-grey-80-50-border-left {
  border-left: solid 5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-50-border-right {
  border-right: solid 5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-50-border-top {
  border-top: solid 5rem rgba(168, 198, 223, 0.8) !important; }

.has-light-blue-grey-80-50-border-bottom {
  border-bottom: solid 5rem rgba(168, 198, 223, 0.8) !important; }

@keyframes light-blue-grey-90-border-pulsate {
  0% {
    border-color: #b7d2e5; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #b7d2e5; } }

.has-light-blue-grey-90-border-color {
  border-color: rgba(183, 210, 229, 0.9); }

.has-light-blue-grey-90-border {
  border: solid 0.1rem rgba(183, 210, 229, 0.9); }

.has-light-blue-grey-90-border-pulsate {
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-border-left {
  border-left: solid 0.1rem rgba(183, 210, 229, 0.9); }

.has-light-blue-grey-90-border-right {
  border-right: solid 0.1rem rgba(183, 210, 229, 0.9); }

.has-light-blue-grey-90-border-top {
  border-top: solid 0.1rem rgba(183, 210, 229, 0.9); }

.has-light-blue-grey-90-border-bottom {
  border-bottom: solid 0.1rem rgba(183, 210, 229, 0.9); }

.has-light-blue-grey-90-1-border {
  border: solid 0.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-1-border-pulsate {
  border: solid 0.1rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-1-border-left {
  border-left: solid 0.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-1-border-right {
  border-right: solid 0.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-1-border-top {
  border-top: solid 0.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-1-border-bottom {
  border-bottom: solid 0.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-2-border {
  border: solid 0.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-2-border-pulsate {
  border: solid 0.2rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-2-border-left {
  border-left: solid 0.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-2-border-right {
  border-right: solid 0.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-2-border-top {
  border-top: solid 0.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-2-border-bottom {
  border-bottom: solid 0.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-3-border {
  border: solid 0.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-3-border-pulsate {
  border: solid 0.3rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-3-border-left {
  border-left: solid 0.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-3-border-right {
  border-right: solid 0.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-3-border-top {
  border-top: solid 0.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-3-border-bottom {
  border-bottom: solid 0.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-4-border {
  border: solid 0.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-4-border-pulsate {
  border: solid 0.4rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-4-border-left {
  border-left: solid 0.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-4-border-right {
  border-right: solid 0.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-4-border-top {
  border-top: solid 0.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-4-border-bottom {
  border-bottom: solid 0.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-5-border {
  border: solid 0.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-5-border-pulsate {
  border: solid 0.5rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-5-border-left {
  border-left: solid 0.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-5-border-right {
  border-right: solid 0.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-5-border-top {
  border-top: solid 0.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-5-border-bottom {
  border-bottom: solid 0.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-6-border {
  border: solid 0.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-6-border-pulsate {
  border: solid 0.6rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-6-border-left {
  border-left: solid 0.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-6-border-right {
  border-right: solid 0.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-6-border-top {
  border-top: solid 0.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-6-border-bottom {
  border-bottom: solid 0.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-7-border {
  border: solid 0.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-7-border-pulsate {
  border: solid 0.7rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-7-border-left {
  border-left: solid 0.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-7-border-right {
  border-right: solid 0.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-7-border-top {
  border-top: solid 0.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-7-border-bottom {
  border-bottom: solid 0.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-8-border {
  border: solid 0.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-8-border-pulsate {
  border: solid 0.8rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-8-border-left {
  border-left: solid 0.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-8-border-right {
  border-right: solid 0.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-8-border-top {
  border-top: solid 0.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-8-border-bottom {
  border-bottom: solid 0.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-9-border {
  border: solid 0.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-9-border-pulsate {
  border: solid 0.9rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-9-border-left {
  border-left: solid 0.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-9-border-right {
  border-right: solid 0.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-9-border-top {
  border-top: solid 0.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-9-border-bottom {
  border-bottom: solid 0.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-10-border {
  border: solid 1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-10-border-pulsate {
  border: solid 1rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-10-border-left {
  border-left: solid 1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-10-border-right {
  border-right: solid 1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-10-border-top {
  border-top: solid 1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-10-border-bottom {
  border-bottom: solid 1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-11-border {
  border: solid 1.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-11-border-pulsate {
  border: solid 1.1rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-11-border-left {
  border-left: solid 1.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-11-border-right {
  border-right: solid 1.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-11-border-top {
  border-top: solid 1.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-11-border-bottom {
  border-bottom: solid 1.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-12-border {
  border: solid 1.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-12-border-pulsate {
  border: solid 1.2rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-12-border-left {
  border-left: solid 1.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-12-border-right {
  border-right: solid 1.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-12-border-top {
  border-top: solid 1.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-12-border-bottom {
  border-bottom: solid 1.2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-13-border {
  border: solid 1.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-13-border-pulsate {
  border: solid 1.3rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-13-border-left {
  border-left: solid 1.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-13-border-right {
  border-right: solid 1.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-13-border-top {
  border-top: solid 1.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-13-border-bottom {
  border-bottom: solid 1.3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-14-border {
  border: solid 1.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-14-border-pulsate {
  border: solid 1.4rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-14-border-left {
  border-left: solid 1.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-14-border-right {
  border-right: solid 1.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-14-border-top {
  border-top: solid 1.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-14-border-bottom {
  border-bottom: solid 1.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-15-border {
  border: solid 1.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-15-border-pulsate {
  border: solid 1.5rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-15-border-left {
  border-left: solid 1.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-15-border-right {
  border-right: solid 1.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-15-border-top {
  border-top: solid 1.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-15-border-bottom {
  border-bottom: solid 1.5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-16-border {
  border: solid 1.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-16-border-pulsate {
  border: solid 1.6rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-16-border-left {
  border-left: solid 1.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-16-border-right {
  border-right: solid 1.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-16-border-top {
  border-top: solid 1.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-16-border-bottom {
  border-bottom: solid 1.6rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-17-border {
  border: solid 1.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-17-border-pulsate {
  border: solid 1.7rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-17-border-left {
  border-left: solid 1.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-17-border-right {
  border-right: solid 1.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-17-border-top {
  border-top: solid 1.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-17-border-bottom {
  border-bottom: solid 1.7rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-18-border {
  border: solid 1.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-18-border-pulsate {
  border: solid 1.8rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-18-border-left {
  border-left: solid 1.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-18-border-right {
  border-right: solid 1.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-18-border-top {
  border-top: solid 1.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-18-border-bottom {
  border-bottom: solid 1.8rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-19-border {
  border: solid 1.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-19-border-pulsate {
  border: solid 1.9rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-19-border-left {
  border-left: solid 1.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-19-border-right {
  border-right: solid 1.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-19-border-top {
  border-top: solid 1.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-19-border-bottom {
  border-bottom: solid 1.9rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-20-border {
  border: solid 2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-20-border-pulsate {
  border: solid 2rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-20-border-left {
  border-left: solid 2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-20-border-right {
  border-right: solid 2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-20-border-top {
  border-top: solid 2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-20-border-bottom {
  border-bottom: solid 2rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-21-border {
  border: solid 2.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-21-border-pulsate {
  border: solid 2.1rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-21-border-left {
  border-left: solid 2.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-21-border-right {
  border-right: solid 2.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-21-border-top {
  border-top: solid 2.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-21-border-bottom {
  border-bottom: solid 2.1rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-24-border {
  border: solid 2.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-24-border-pulsate {
  border: solid 2.4rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-24-border-left {
  border-left: solid 2.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-24-border-right {
  border-right: solid 2.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-24-border-top {
  border-top: solid 2.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-24-border-bottom {
  border-bottom: solid 2.4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-30-border {
  border: solid 3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-30-border-pulsate {
  border: solid 3rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-30-border-left {
  border-left: solid 3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-30-border-right {
  border-right: solid 3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-30-border-top {
  border-top: solid 3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-30-border-bottom {
  border-bottom: solid 3rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-40-border {
  border: solid 4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-40-border-pulsate {
  border: solid 4rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-40-border-left {
  border-left: solid 4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-40-border-right {
  border-right: solid 4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-40-border-top {
  border-top: solid 4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-40-border-bottom {
  border-bottom: solid 4rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-50-border {
  border: solid 5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-50-border-pulsate {
  border: solid 5rem rgba(183, 210, 229, 0.9) !important;
  animation: light-blue-grey-90-border-pulsate 2s infinite; }

.has-light-blue-grey-90-50-border-left {
  border-left: solid 5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-50-border-right {
  border-right: solid 5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-50-border-top {
  border-top: solid 5rem rgba(183, 210, 229, 0.9) !important; }

.has-light-blue-grey-90-50-border-bottom {
  border-bottom: solid 5rem rgba(183, 210, 229, 0.9) !important; }

@keyframes bluish-grey-border-pulsate {
  0% {
    border-color: #78909c; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #78909c; } }

.has-bluish-grey-border-color {
  border-color: #78909c; }

.has-bluish-grey-border {
  border: solid 0.1rem #78909c; }

.has-bluish-grey-border-pulsate {
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-border-left {
  border-left: solid 0.1rem #78909c; }

.has-bluish-grey-border-right {
  border-right: solid 0.1rem #78909c; }

.has-bluish-grey-border-top {
  border-top: solid 0.1rem #78909c; }

.has-bluish-grey-border-bottom {
  border-bottom: solid 0.1rem #78909c; }

.has-bluish-grey-1-border {
  border: solid 0.1rem #78909c !important; }

.has-bluish-grey-1-border-pulsate {
  border: solid 0.1rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-1-border-left {
  border-left: solid 0.1rem #78909c !important; }

.has-bluish-grey-1-border-right {
  border-right: solid 0.1rem #78909c !important; }

.has-bluish-grey-1-border-top {
  border-top: solid 0.1rem #78909c !important; }

.has-bluish-grey-1-border-bottom {
  border-bottom: solid 0.1rem #78909c !important; }

.has-bluish-grey-2-border {
  border: solid 0.2rem #78909c !important; }

.has-bluish-grey-2-border-pulsate {
  border: solid 0.2rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-2-border-left {
  border-left: solid 0.2rem #78909c !important; }

.has-bluish-grey-2-border-right {
  border-right: solid 0.2rem #78909c !important; }

.has-bluish-grey-2-border-top {
  border-top: solid 0.2rem #78909c !important; }

.has-bluish-grey-2-border-bottom {
  border-bottom: solid 0.2rem #78909c !important; }

.has-bluish-grey-3-border {
  border: solid 0.3rem #78909c !important; }

.has-bluish-grey-3-border-pulsate {
  border: solid 0.3rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-3-border-left {
  border-left: solid 0.3rem #78909c !important; }

.has-bluish-grey-3-border-right {
  border-right: solid 0.3rem #78909c !important; }

.has-bluish-grey-3-border-top {
  border-top: solid 0.3rem #78909c !important; }

.has-bluish-grey-3-border-bottom {
  border-bottom: solid 0.3rem #78909c !important; }

.has-bluish-grey-4-border {
  border: solid 0.4rem #78909c !important; }

.has-bluish-grey-4-border-pulsate {
  border: solid 0.4rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-4-border-left {
  border-left: solid 0.4rem #78909c !important; }

.has-bluish-grey-4-border-right {
  border-right: solid 0.4rem #78909c !important; }

.has-bluish-grey-4-border-top {
  border-top: solid 0.4rem #78909c !important; }

.has-bluish-grey-4-border-bottom {
  border-bottom: solid 0.4rem #78909c !important; }

.has-bluish-grey-5-border {
  border: solid 0.5rem #78909c !important; }

.has-bluish-grey-5-border-pulsate {
  border: solid 0.5rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-5-border-left {
  border-left: solid 0.5rem #78909c !important; }

.has-bluish-grey-5-border-right {
  border-right: solid 0.5rem #78909c !important; }

.has-bluish-grey-5-border-top {
  border-top: solid 0.5rem #78909c !important; }

.has-bluish-grey-5-border-bottom {
  border-bottom: solid 0.5rem #78909c !important; }

.has-bluish-grey-6-border {
  border: solid 0.6rem #78909c !important; }

.has-bluish-grey-6-border-pulsate {
  border: solid 0.6rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-6-border-left {
  border-left: solid 0.6rem #78909c !important; }

.has-bluish-grey-6-border-right {
  border-right: solid 0.6rem #78909c !important; }

.has-bluish-grey-6-border-top {
  border-top: solid 0.6rem #78909c !important; }

.has-bluish-grey-6-border-bottom {
  border-bottom: solid 0.6rem #78909c !important; }

.has-bluish-grey-7-border {
  border: solid 0.7rem #78909c !important; }

.has-bluish-grey-7-border-pulsate {
  border: solid 0.7rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-7-border-left {
  border-left: solid 0.7rem #78909c !important; }

.has-bluish-grey-7-border-right {
  border-right: solid 0.7rem #78909c !important; }

.has-bluish-grey-7-border-top {
  border-top: solid 0.7rem #78909c !important; }

.has-bluish-grey-7-border-bottom {
  border-bottom: solid 0.7rem #78909c !important; }

.has-bluish-grey-8-border {
  border: solid 0.8rem #78909c !important; }

.has-bluish-grey-8-border-pulsate {
  border: solid 0.8rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-8-border-left {
  border-left: solid 0.8rem #78909c !important; }

.has-bluish-grey-8-border-right {
  border-right: solid 0.8rem #78909c !important; }

.has-bluish-grey-8-border-top {
  border-top: solid 0.8rem #78909c !important; }

.has-bluish-grey-8-border-bottom {
  border-bottom: solid 0.8rem #78909c !important; }

.has-bluish-grey-9-border {
  border: solid 0.9rem #78909c !important; }

.has-bluish-grey-9-border-pulsate {
  border: solid 0.9rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-9-border-left {
  border-left: solid 0.9rem #78909c !important; }

.has-bluish-grey-9-border-right {
  border-right: solid 0.9rem #78909c !important; }

.has-bluish-grey-9-border-top {
  border-top: solid 0.9rem #78909c !important; }

.has-bluish-grey-9-border-bottom {
  border-bottom: solid 0.9rem #78909c !important; }

.has-bluish-grey-10-border {
  border: solid 1rem #78909c !important; }

.has-bluish-grey-10-border-pulsate {
  border: solid 1rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-10-border-left {
  border-left: solid 1rem #78909c !important; }

.has-bluish-grey-10-border-right {
  border-right: solid 1rem #78909c !important; }

.has-bluish-grey-10-border-top {
  border-top: solid 1rem #78909c !important; }

.has-bluish-grey-10-border-bottom {
  border-bottom: solid 1rem #78909c !important; }

.has-bluish-grey-11-border {
  border: solid 1.1rem #78909c !important; }

.has-bluish-grey-11-border-pulsate {
  border: solid 1.1rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-11-border-left {
  border-left: solid 1.1rem #78909c !important; }

.has-bluish-grey-11-border-right {
  border-right: solid 1.1rem #78909c !important; }

.has-bluish-grey-11-border-top {
  border-top: solid 1.1rem #78909c !important; }

.has-bluish-grey-11-border-bottom {
  border-bottom: solid 1.1rem #78909c !important; }

.has-bluish-grey-12-border {
  border: solid 1.2rem #78909c !important; }

.has-bluish-grey-12-border-pulsate {
  border: solid 1.2rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-12-border-left {
  border-left: solid 1.2rem #78909c !important; }

.has-bluish-grey-12-border-right {
  border-right: solid 1.2rem #78909c !important; }

.has-bluish-grey-12-border-top {
  border-top: solid 1.2rem #78909c !important; }

.has-bluish-grey-12-border-bottom {
  border-bottom: solid 1.2rem #78909c !important; }

.has-bluish-grey-13-border {
  border: solid 1.3rem #78909c !important; }

.has-bluish-grey-13-border-pulsate {
  border: solid 1.3rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-13-border-left {
  border-left: solid 1.3rem #78909c !important; }

.has-bluish-grey-13-border-right {
  border-right: solid 1.3rem #78909c !important; }

.has-bluish-grey-13-border-top {
  border-top: solid 1.3rem #78909c !important; }

.has-bluish-grey-13-border-bottom {
  border-bottom: solid 1.3rem #78909c !important; }

.has-bluish-grey-14-border {
  border: solid 1.4rem #78909c !important; }

.has-bluish-grey-14-border-pulsate {
  border: solid 1.4rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-14-border-left {
  border-left: solid 1.4rem #78909c !important; }

.has-bluish-grey-14-border-right {
  border-right: solid 1.4rem #78909c !important; }

.has-bluish-grey-14-border-top {
  border-top: solid 1.4rem #78909c !important; }

.has-bluish-grey-14-border-bottom {
  border-bottom: solid 1.4rem #78909c !important; }

.has-bluish-grey-15-border {
  border: solid 1.5rem #78909c !important; }

.has-bluish-grey-15-border-pulsate {
  border: solid 1.5rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-15-border-left {
  border-left: solid 1.5rem #78909c !important; }

.has-bluish-grey-15-border-right {
  border-right: solid 1.5rem #78909c !important; }

.has-bluish-grey-15-border-top {
  border-top: solid 1.5rem #78909c !important; }

.has-bluish-grey-15-border-bottom {
  border-bottom: solid 1.5rem #78909c !important; }

.has-bluish-grey-16-border {
  border: solid 1.6rem #78909c !important; }

.has-bluish-grey-16-border-pulsate {
  border: solid 1.6rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-16-border-left {
  border-left: solid 1.6rem #78909c !important; }

.has-bluish-grey-16-border-right {
  border-right: solid 1.6rem #78909c !important; }

.has-bluish-grey-16-border-top {
  border-top: solid 1.6rem #78909c !important; }

.has-bluish-grey-16-border-bottom {
  border-bottom: solid 1.6rem #78909c !important; }

.has-bluish-grey-17-border {
  border: solid 1.7rem #78909c !important; }

.has-bluish-grey-17-border-pulsate {
  border: solid 1.7rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-17-border-left {
  border-left: solid 1.7rem #78909c !important; }

.has-bluish-grey-17-border-right {
  border-right: solid 1.7rem #78909c !important; }

.has-bluish-grey-17-border-top {
  border-top: solid 1.7rem #78909c !important; }

.has-bluish-grey-17-border-bottom {
  border-bottom: solid 1.7rem #78909c !important; }

.has-bluish-grey-18-border {
  border: solid 1.8rem #78909c !important; }

.has-bluish-grey-18-border-pulsate {
  border: solid 1.8rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-18-border-left {
  border-left: solid 1.8rem #78909c !important; }

.has-bluish-grey-18-border-right {
  border-right: solid 1.8rem #78909c !important; }

.has-bluish-grey-18-border-top {
  border-top: solid 1.8rem #78909c !important; }

.has-bluish-grey-18-border-bottom {
  border-bottom: solid 1.8rem #78909c !important; }

.has-bluish-grey-19-border {
  border: solid 1.9rem #78909c !important; }

.has-bluish-grey-19-border-pulsate {
  border: solid 1.9rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-19-border-left {
  border-left: solid 1.9rem #78909c !important; }

.has-bluish-grey-19-border-right {
  border-right: solid 1.9rem #78909c !important; }

.has-bluish-grey-19-border-top {
  border-top: solid 1.9rem #78909c !important; }

.has-bluish-grey-19-border-bottom {
  border-bottom: solid 1.9rem #78909c !important; }

.has-bluish-grey-20-border {
  border: solid 2rem #78909c !important; }

.has-bluish-grey-20-border-pulsate {
  border: solid 2rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-20-border-left {
  border-left: solid 2rem #78909c !important; }

.has-bluish-grey-20-border-right {
  border-right: solid 2rem #78909c !important; }

.has-bluish-grey-20-border-top {
  border-top: solid 2rem #78909c !important; }

.has-bluish-grey-20-border-bottom {
  border-bottom: solid 2rem #78909c !important; }

.has-bluish-grey-21-border {
  border: solid 2.1rem #78909c !important; }

.has-bluish-grey-21-border-pulsate {
  border: solid 2.1rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-21-border-left {
  border-left: solid 2.1rem #78909c !important; }

.has-bluish-grey-21-border-right {
  border-right: solid 2.1rem #78909c !important; }

.has-bluish-grey-21-border-top {
  border-top: solid 2.1rem #78909c !important; }

.has-bluish-grey-21-border-bottom {
  border-bottom: solid 2.1rem #78909c !important; }

.has-bluish-grey-24-border {
  border: solid 2.4rem #78909c !important; }

.has-bluish-grey-24-border-pulsate {
  border: solid 2.4rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-24-border-left {
  border-left: solid 2.4rem #78909c !important; }

.has-bluish-grey-24-border-right {
  border-right: solid 2.4rem #78909c !important; }

.has-bluish-grey-24-border-top {
  border-top: solid 2.4rem #78909c !important; }

.has-bluish-grey-24-border-bottom {
  border-bottom: solid 2.4rem #78909c !important; }

.has-bluish-grey-30-border {
  border: solid 3rem #78909c !important; }

.has-bluish-grey-30-border-pulsate {
  border: solid 3rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-30-border-left {
  border-left: solid 3rem #78909c !important; }

.has-bluish-grey-30-border-right {
  border-right: solid 3rem #78909c !important; }

.has-bluish-grey-30-border-top {
  border-top: solid 3rem #78909c !important; }

.has-bluish-grey-30-border-bottom {
  border-bottom: solid 3rem #78909c !important; }

.has-bluish-grey-40-border {
  border: solid 4rem #78909c !important; }

.has-bluish-grey-40-border-pulsate {
  border: solid 4rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-40-border-left {
  border-left: solid 4rem #78909c !important; }

.has-bluish-grey-40-border-right {
  border-right: solid 4rem #78909c !important; }

.has-bluish-grey-40-border-top {
  border-top: solid 4rem #78909c !important; }

.has-bluish-grey-40-border-bottom {
  border-bottom: solid 4rem #78909c !important; }

.has-bluish-grey-50-border {
  border: solid 5rem #78909c !important; }

.has-bluish-grey-50-border-pulsate {
  border: solid 5rem #78909c !important;
  animation: bluish-grey-border-pulsate 2s infinite; }

.has-bluish-grey-50-border-left {
  border-left: solid 5rem #78909c !important; }

.has-bluish-grey-50-border-right {
  border-right: solid 5rem #78909c !important; }

.has-bluish-grey-50-border-top {
  border-top: solid 5rem #78909c !important; }

.has-bluish-grey-50-border-bottom {
  border-bottom: solid 5rem #78909c !important; }

@keyframes light-grey-border-pulsate {
  0% {
    border-color: #f8f9f9; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #f8f9f9; } }

.has-light-grey-border-color {
  border-color: #f8f9f9; }

.has-light-grey-border {
  border: solid 0.1rem #f8f9f9; }

.has-light-grey-border-pulsate {
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-border-left {
  border-left: solid 0.1rem #f8f9f9; }

.has-light-grey-border-right {
  border-right: solid 0.1rem #f8f9f9; }

.has-light-grey-border-top {
  border-top: solid 0.1rem #f8f9f9; }

.has-light-grey-border-bottom {
  border-bottom: solid 0.1rem #f8f9f9; }

.has-light-grey-1-border {
  border: solid 0.1rem #f8f9f9 !important; }

.has-light-grey-1-border-pulsate {
  border: solid 0.1rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-1-border-left {
  border-left: solid 0.1rem #f8f9f9 !important; }

.has-light-grey-1-border-right {
  border-right: solid 0.1rem #f8f9f9 !important; }

.has-light-grey-1-border-top {
  border-top: solid 0.1rem #f8f9f9 !important; }

.has-light-grey-1-border-bottom {
  border-bottom: solid 0.1rem #f8f9f9 !important; }

.has-light-grey-2-border {
  border: solid 0.2rem #f8f9f9 !important; }

.has-light-grey-2-border-pulsate {
  border: solid 0.2rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-2-border-left {
  border-left: solid 0.2rem #f8f9f9 !important; }

.has-light-grey-2-border-right {
  border-right: solid 0.2rem #f8f9f9 !important; }

.has-light-grey-2-border-top {
  border-top: solid 0.2rem #f8f9f9 !important; }

.has-light-grey-2-border-bottom {
  border-bottom: solid 0.2rem #f8f9f9 !important; }

.has-light-grey-3-border {
  border: solid 0.3rem #f8f9f9 !important; }

.has-light-grey-3-border-pulsate {
  border: solid 0.3rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-3-border-left {
  border-left: solid 0.3rem #f8f9f9 !important; }

.has-light-grey-3-border-right {
  border-right: solid 0.3rem #f8f9f9 !important; }

.has-light-grey-3-border-top {
  border-top: solid 0.3rem #f8f9f9 !important; }

.has-light-grey-3-border-bottom {
  border-bottom: solid 0.3rem #f8f9f9 !important; }

.has-light-grey-4-border {
  border: solid 0.4rem #f8f9f9 !important; }

.has-light-grey-4-border-pulsate {
  border: solid 0.4rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-4-border-left {
  border-left: solid 0.4rem #f8f9f9 !important; }

.has-light-grey-4-border-right {
  border-right: solid 0.4rem #f8f9f9 !important; }

.has-light-grey-4-border-top {
  border-top: solid 0.4rem #f8f9f9 !important; }

.has-light-grey-4-border-bottom {
  border-bottom: solid 0.4rem #f8f9f9 !important; }

.has-light-grey-5-border {
  border: solid 0.5rem #f8f9f9 !important; }

.has-light-grey-5-border-pulsate {
  border: solid 0.5rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-5-border-left {
  border-left: solid 0.5rem #f8f9f9 !important; }

.has-light-grey-5-border-right {
  border-right: solid 0.5rem #f8f9f9 !important; }

.has-light-grey-5-border-top {
  border-top: solid 0.5rem #f8f9f9 !important; }

.has-light-grey-5-border-bottom {
  border-bottom: solid 0.5rem #f8f9f9 !important; }

.has-light-grey-6-border {
  border: solid 0.6rem #f8f9f9 !important; }

.has-light-grey-6-border-pulsate {
  border: solid 0.6rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-6-border-left {
  border-left: solid 0.6rem #f8f9f9 !important; }

.has-light-grey-6-border-right {
  border-right: solid 0.6rem #f8f9f9 !important; }

.has-light-grey-6-border-top {
  border-top: solid 0.6rem #f8f9f9 !important; }

.has-light-grey-6-border-bottom {
  border-bottom: solid 0.6rem #f8f9f9 !important; }

.has-light-grey-7-border {
  border: solid 0.7rem #f8f9f9 !important; }

.has-light-grey-7-border-pulsate {
  border: solid 0.7rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-7-border-left {
  border-left: solid 0.7rem #f8f9f9 !important; }

.has-light-grey-7-border-right {
  border-right: solid 0.7rem #f8f9f9 !important; }

.has-light-grey-7-border-top {
  border-top: solid 0.7rem #f8f9f9 !important; }

.has-light-grey-7-border-bottom {
  border-bottom: solid 0.7rem #f8f9f9 !important; }

.has-light-grey-8-border {
  border: solid 0.8rem #f8f9f9 !important; }

.has-light-grey-8-border-pulsate {
  border: solid 0.8rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-8-border-left {
  border-left: solid 0.8rem #f8f9f9 !important; }

.has-light-grey-8-border-right {
  border-right: solid 0.8rem #f8f9f9 !important; }

.has-light-grey-8-border-top {
  border-top: solid 0.8rem #f8f9f9 !important; }

.has-light-grey-8-border-bottom {
  border-bottom: solid 0.8rem #f8f9f9 !important; }

.has-light-grey-9-border {
  border: solid 0.9rem #f8f9f9 !important; }

.has-light-grey-9-border-pulsate {
  border: solid 0.9rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-9-border-left {
  border-left: solid 0.9rem #f8f9f9 !important; }

.has-light-grey-9-border-right {
  border-right: solid 0.9rem #f8f9f9 !important; }

.has-light-grey-9-border-top {
  border-top: solid 0.9rem #f8f9f9 !important; }

.has-light-grey-9-border-bottom {
  border-bottom: solid 0.9rem #f8f9f9 !important; }

.has-light-grey-10-border {
  border: solid 1rem #f8f9f9 !important; }

.has-light-grey-10-border-pulsate {
  border: solid 1rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-10-border-left {
  border-left: solid 1rem #f8f9f9 !important; }

.has-light-grey-10-border-right {
  border-right: solid 1rem #f8f9f9 !important; }

.has-light-grey-10-border-top {
  border-top: solid 1rem #f8f9f9 !important; }

.has-light-grey-10-border-bottom {
  border-bottom: solid 1rem #f8f9f9 !important; }

.has-light-grey-11-border {
  border: solid 1.1rem #f8f9f9 !important; }

.has-light-grey-11-border-pulsate {
  border: solid 1.1rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-11-border-left {
  border-left: solid 1.1rem #f8f9f9 !important; }

.has-light-grey-11-border-right {
  border-right: solid 1.1rem #f8f9f9 !important; }

.has-light-grey-11-border-top {
  border-top: solid 1.1rem #f8f9f9 !important; }

.has-light-grey-11-border-bottom {
  border-bottom: solid 1.1rem #f8f9f9 !important; }

.has-light-grey-12-border {
  border: solid 1.2rem #f8f9f9 !important; }

.has-light-grey-12-border-pulsate {
  border: solid 1.2rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-12-border-left {
  border-left: solid 1.2rem #f8f9f9 !important; }

.has-light-grey-12-border-right {
  border-right: solid 1.2rem #f8f9f9 !important; }

.has-light-grey-12-border-top {
  border-top: solid 1.2rem #f8f9f9 !important; }

.has-light-grey-12-border-bottom {
  border-bottom: solid 1.2rem #f8f9f9 !important; }

.has-light-grey-13-border {
  border: solid 1.3rem #f8f9f9 !important; }

.has-light-grey-13-border-pulsate {
  border: solid 1.3rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-13-border-left {
  border-left: solid 1.3rem #f8f9f9 !important; }

.has-light-grey-13-border-right {
  border-right: solid 1.3rem #f8f9f9 !important; }

.has-light-grey-13-border-top {
  border-top: solid 1.3rem #f8f9f9 !important; }

.has-light-grey-13-border-bottom {
  border-bottom: solid 1.3rem #f8f9f9 !important; }

.has-light-grey-14-border {
  border: solid 1.4rem #f8f9f9 !important; }

.has-light-grey-14-border-pulsate {
  border: solid 1.4rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-14-border-left {
  border-left: solid 1.4rem #f8f9f9 !important; }

.has-light-grey-14-border-right {
  border-right: solid 1.4rem #f8f9f9 !important; }

.has-light-grey-14-border-top {
  border-top: solid 1.4rem #f8f9f9 !important; }

.has-light-grey-14-border-bottom {
  border-bottom: solid 1.4rem #f8f9f9 !important; }

.has-light-grey-15-border {
  border: solid 1.5rem #f8f9f9 !important; }

.has-light-grey-15-border-pulsate {
  border: solid 1.5rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-15-border-left {
  border-left: solid 1.5rem #f8f9f9 !important; }

.has-light-grey-15-border-right {
  border-right: solid 1.5rem #f8f9f9 !important; }

.has-light-grey-15-border-top {
  border-top: solid 1.5rem #f8f9f9 !important; }

.has-light-grey-15-border-bottom {
  border-bottom: solid 1.5rem #f8f9f9 !important; }

.has-light-grey-16-border {
  border: solid 1.6rem #f8f9f9 !important; }

.has-light-grey-16-border-pulsate {
  border: solid 1.6rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-16-border-left {
  border-left: solid 1.6rem #f8f9f9 !important; }

.has-light-grey-16-border-right {
  border-right: solid 1.6rem #f8f9f9 !important; }

.has-light-grey-16-border-top {
  border-top: solid 1.6rem #f8f9f9 !important; }

.has-light-grey-16-border-bottom {
  border-bottom: solid 1.6rem #f8f9f9 !important; }

.has-light-grey-17-border {
  border: solid 1.7rem #f8f9f9 !important; }

.has-light-grey-17-border-pulsate {
  border: solid 1.7rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-17-border-left {
  border-left: solid 1.7rem #f8f9f9 !important; }

.has-light-grey-17-border-right {
  border-right: solid 1.7rem #f8f9f9 !important; }

.has-light-grey-17-border-top {
  border-top: solid 1.7rem #f8f9f9 !important; }

.has-light-grey-17-border-bottom {
  border-bottom: solid 1.7rem #f8f9f9 !important; }

.has-light-grey-18-border {
  border: solid 1.8rem #f8f9f9 !important; }

.has-light-grey-18-border-pulsate {
  border: solid 1.8rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-18-border-left {
  border-left: solid 1.8rem #f8f9f9 !important; }

.has-light-grey-18-border-right {
  border-right: solid 1.8rem #f8f9f9 !important; }

.has-light-grey-18-border-top {
  border-top: solid 1.8rem #f8f9f9 !important; }

.has-light-grey-18-border-bottom {
  border-bottom: solid 1.8rem #f8f9f9 !important; }

.has-light-grey-19-border {
  border: solid 1.9rem #f8f9f9 !important; }

.has-light-grey-19-border-pulsate {
  border: solid 1.9rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-19-border-left {
  border-left: solid 1.9rem #f8f9f9 !important; }

.has-light-grey-19-border-right {
  border-right: solid 1.9rem #f8f9f9 !important; }

.has-light-grey-19-border-top {
  border-top: solid 1.9rem #f8f9f9 !important; }

.has-light-grey-19-border-bottom {
  border-bottom: solid 1.9rem #f8f9f9 !important; }

.has-light-grey-20-border {
  border: solid 2rem #f8f9f9 !important; }

.has-light-grey-20-border-pulsate {
  border: solid 2rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-20-border-left {
  border-left: solid 2rem #f8f9f9 !important; }

.has-light-grey-20-border-right {
  border-right: solid 2rem #f8f9f9 !important; }

.has-light-grey-20-border-top {
  border-top: solid 2rem #f8f9f9 !important; }

.has-light-grey-20-border-bottom {
  border-bottom: solid 2rem #f8f9f9 !important; }

.has-light-grey-21-border {
  border: solid 2.1rem #f8f9f9 !important; }

.has-light-grey-21-border-pulsate {
  border: solid 2.1rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-21-border-left {
  border-left: solid 2.1rem #f8f9f9 !important; }

.has-light-grey-21-border-right {
  border-right: solid 2.1rem #f8f9f9 !important; }

.has-light-grey-21-border-top {
  border-top: solid 2.1rem #f8f9f9 !important; }

.has-light-grey-21-border-bottom {
  border-bottom: solid 2.1rem #f8f9f9 !important; }

.has-light-grey-24-border {
  border: solid 2.4rem #f8f9f9 !important; }

.has-light-grey-24-border-pulsate {
  border: solid 2.4rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-24-border-left {
  border-left: solid 2.4rem #f8f9f9 !important; }

.has-light-grey-24-border-right {
  border-right: solid 2.4rem #f8f9f9 !important; }

.has-light-grey-24-border-top {
  border-top: solid 2.4rem #f8f9f9 !important; }

.has-light-grey-24-border-bottom {
  border-bottom: solid 2.4rem #f8f9f9 !important; }

.has-light-grey-30-border {
  border: solid 3rem #f8f9f9 !important; }

.has-light-grey-30-border-pulsate {
  border: solid 3rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-30-border-left {
  border-left: solid 3rem #f8f9f9 !important; }

.has-light-grey-30-border-right {
  border-right: solid 3rem #f8f9f9 !important; }

.has-light-grey-30-border-top {
  border-top: solid 3rem #f8f9f9 !important; }

.has-light-grey-30-border-bottom {
  border-bottom: solid 3rem #f8f9f9 !important; }

.has-light-grey-40-border {
  border: solid 4rem #f8f9f9 !important; }

.has-light-grey-40-border-pulsate {
  border: solid 4rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-40-border-left {
  border-left: solid 4rem #f8f9f9 !important; }

.has-light-grey-40-border-right {
  border-right: solid 4rem #f8f9f9 !important; }

.has-light-grey-40-border-top {
  border-top: solid 4rem #f8f9f9 !important; }

.has-light-grey-40-border-bottom {
  border-bottom: solid 4rem #f8f9f9 !important; }

.has-light-grey-50-border {
  border: solid 5rem #f8f9f9 !important; }

.has-light-grey-50-border-pulsate {
  border: solid 5rem #f8f9f9 !important;
  animation: light-grey-border-pulsate 2s infinite; }

.has-light-grey-50-border-left {
  border-left: solid 5rem #f8f9f9 !important; }

.has-light-grey-50-border-right {
  border-right: solid 5rem #f8f9f9 !important; }

.has-light-grey-50-border-top {
  border-top: solid 5rem #f8f9f9 !important; }

.has-light-grey-50-border-bottom {
  border-bottom: solid 5rem #f8f9f9 !important; }

@keyframes dark-sky-blue-10-border-pulsate {
  0% {
    border-color: #1e88e5; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #1e88e5; } }

.has-dark-sky-blue-10-border-color {
  border-color: rgba(30, 136, 229, 0.1); }

.has-dark-sky-blue-10-border {
  border: solid 0.1rem rgba(30, 136, 229, 0.1); }

.has-dark-sky-blue-10-border-pulsate {
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-border-left {
  border-left: solid 0.1rem rgba(30, 136, 229, 0.1); }

.has-dark-sky-blue-10-border-right {
  border-right: solid 0.1rem rgba(30, 136, 229, 0.1); }

.has-dark-sky-blue-10-border-top {
  border-top: solid 0.1rem rgba(30, 136, 229, 0.1); }

.has-dark-sky-blue-10-border-bottom {
  border-bottom: solid 0.1rem rgba(30, 136, 229, 0.1); }

.has-dark-sky-blue-10-1-border {
  border: solid 0.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-1-border-pulsate {
  border: solid 0.1rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-1-border-left {
  border-left: solid 0.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-1-border-right {
  border-right: solid 0.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-1-border-top {
  border-top: solid 0.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-1-border-bottom {
  border-bottom: solid 0.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-2-border {
  border: solid 0.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-2-border-pulsate {
  border: solid 0.2rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-2-border-left {
  border-left: solid 0.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-2-border-right {
  border-right: solid 0.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-2-border-top {
  border-top: solid 0.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-2-border-bottom {
  border-bottom: solid 0.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-3-border {
  border: solid 0.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-3-border-pulsate {
  border: solid 0.3rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-3-border-left {
  border-left: solid 0.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-3-border-right {
  border-right: solid 0.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-3-border-top {
  border-top: solid 0.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-3-border-bottom {
  border-bottom: solid 0.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-4-border {
  border: solid 0.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-4-border-pulsate {
  border: solid 0.4rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-4-border-left {
  border-left: solid 0.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-4-border-right {
  border-right: solid 0.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-4-border-top {
  border-top: solid 0.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-4-border-bottom {
  border-bottom: solid 0.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-5-border {
  border: solid 0.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-5-border-pulsate {
  border: solid 0.5rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-5-border-left {
  border-left: solid 0.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-5-border-right {
  border-right: solid 0.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-5-border-top {
  border-top: solid 0.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-5-border-bottom {
  border-bottom: solid 0.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-6-border {
  border: solid 0.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-6-border-pulsate {
  border: solid 0.6rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-6-border-left {
  border-left: solid 0.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-6-border-right {
  border-right: solid 0.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-6-border-top {
  border-top: solid 0.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-6-border-bottom {
  border-bottom: solid 0.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-7-border {
  border: solid 0.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-7-border-pulsate {
  border: solid 0.7rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-7-border-left {
  border-left: solid 0.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-7-border-right {
  border-right: solid 0.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-7-border-top {
  border-top: solid 0.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-7-border-bottom {
  border-bottom: solid 0.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-8-border {
  border: solid 0.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-8-border-pulsate {
  border: solid 0.8rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-8-border-left {
  border-left: solid 0.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-8-border-right {
  border-right: solid 0.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-8-border-top {
  border-top: solid 0.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-8-border-bottom {
  border-bottom: solid 0.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-9-border {
  border: solid 0.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-9-border-pulsate {
  border: solid 0.9rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-9-border-left {
  border-left: solid 0.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-9-border-right {
  border-right: solid 0.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-9-border-top {
  border-top: solid 0.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-9-border-bottom {
  border-bottom: solid 0.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-10-border {
  border: solid 1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-10-border-pulsate {
  border: solid 1rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-10-border-left {
  border-left: solid 1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-10-border-right {
  border-right: solid 1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-10-border-top {
  border-top: solid 1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-10-border-bottom {
  border-bottom: solid 1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-11-border {
  border: solid 1.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-11-border-pulsate {
  border: solid 1.1rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-11-border-left {
  border-left: solid 1.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-11-border-right {
  border-right: solid 1.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-11-border-top {
  border-top: solid 1.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-11-border-bottom {
  border-bottom: solid 1.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-12-border {
  border: solid 1.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-12-border-pulsate {
  border: solid 1.2rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-12-border-left {
  border-left: solid 1.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-12-border-right {
  border-right: solid 1.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-12-border-top {
  border-top: solid 1.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-12-border-bottom {
  border-bottom: solid 1.2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-13-border {
  border: solid 1.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-13-border-pulsate {
  border: solid 1.3rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-13-border-left {
  border-left: solid 1.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-13-border-right {
  border-right: solid 1.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-13-border-top {
  border-top: solid 1.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-13-border-bottom {
  border-bottom: solid 1.3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-14-border {
  border: solid 1.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-14-border-pulsate {
  border: solid 1.4rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-14-border-left {
  border-left: solid 1.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-14-border-right {
  border-right: solid 1.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-14-border-top {
  border-top: solid 1.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-14-border-bottom {
  border-bottom: solid 1.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-15-border {
  border: solid 1.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-15-border-pulsate {
  border: solid 1.5rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-15-border-left {
  border-left: solid 1.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-15-border-right {
  border-right: solid 1.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-15-border-top {
  border-top: solid 1.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-15-border-bottom {
  border-bottom: solid 1.5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-16-border {
  border: solid 1.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-16-border-pulsate {
  border: solid 1.6rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-16-border-left {
  border-left: solid 1.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-16-border-right {
  border-right: solid 1.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-16-border-top {
  border-top: solid 1.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-16-border-bottom {
  border-bottom: solid 1.6rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-17-border {
  border: solid 1.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-17-border-pulsate {
  border: solid 1.7rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-17-border-left {
  border-left: solid 1.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-17-border-right {
  border-right: solid 1.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-17-border-top {
  border-top: solid 1.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-17-border-bottom {
  border-bottom: solid 1.7rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-18-border {
  border: solid 1.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-18-border-pulsate {
  border: solid 1.8rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-18-border-left {
  border-left: solid 1.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-18-border-right {
  border-right: solid 1.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-18-border-top {
  border-top: solid 1.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-18-border-bottom {
  border-bottom: solid 1.8rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-19-border {
  border: solid 1.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-19-border-pulsate {
  border: solid 1.9rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-19-border-left {
  border-left: solid 1.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-19-border-right {
  border-right: solid 1.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-19-border-top {
  border-top: solid 1.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-19-border-bottom {
  border-bottom: solid 1.9rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-20-border {
  border: solid 2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-20-border-pulsate {
  border: solid 2rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-20-border-left {
  border-left: solid 2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-20-border-right {
  border-right: solid 2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-20-border-top {
  border-top: solid 2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-20-border-bottom {
  border-bottom: solid 2rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-21-border {
  border: solid 2.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-21-border-pulsate {
  border: solid 2.1rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-21-border-left {
  border-left: solid 2.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-21-border-right {
  border-right: solid 2.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-21-border-top {
  border-top: solid 2.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-21-border-bottom {
  border-bottom: solid 2.1rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-24-border {
  border: solid 2.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-24-border-pulsate {
  border: solid 2.4rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-24-border-left {
  border-left: solid 2.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-24-border-right {
  border-right: solid 2.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-24-border-top {
  border-top: solid 2.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-24-border-bottom {
  border-bottom: solid 2.4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-30-border {
  border: solid 3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-30-border-pulsate {
  border: solid 3rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-30-border-left {
  border-left: solid 3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-30-border-right {
  border-right: solid 3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-30-border-top {
  border-top: solid 3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-30-border-bottom {
  border-bottom: solid 3rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-40-border {
  border: solid 4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-40-border-pulsate {
  border: solid 4rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-40-border-left {
  border-left: solid 4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-40-border-right {
  border-right: solid 4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-40-border-top {
  border-top: solid 4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-40-border-bottom {
  border-bottom: solid 4rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-50-border {
  border: solid 5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-50-border-pulsate {
  border: solid 5rem rgba(30, 136, 229, 0.1) !important;
  animation: dark-sky-blue-10-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-50-border-left {
  border-left: solid 5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-50-border-right {
  border-right: solid 5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-50-border-top {
  border-top: solid 5rem rgba(30, 136, 229, 0.1) !important; }

.has-dark-sky-blue-10-50-border-bottom {
  border-bottom: solid 5rem rgba(30, 136, 229, 0.1) !important; }

@keyframes light-blue-grey-two-border-pulsate {
  0% {
    border-color: #d8e5ef; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #d8e5ef; } }

.has-light-blue-grey-two-border-color {
  border-color: #d8e5ef; }

.has-light-blue-grey-two-border {
  border: solid 0.1rem #d8e5ef; }

.has-light-blue-grey-two-border-pulsate {
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-border-left {
  border-left: solid 0.1rem #d8e5ef; }

.has-light-blue-grey-two-border-right {
  border-right: solid 0.1rem #d8e5ef; }

.has-light-blue-grey-two-border-top {
  border-top: solid 0.1rem #d8e5ef; }

.has-light-blue-grey-two-border-bottom {
  border-bottom: solid 0.1rem #d8e5ef; }

.has-light-blue-grey-two-1-border {
  border: solid 0.1rem #d8e5ef !important; }

.has-light-blue-grey-two-1-border-pulsate {
  border: solid 0.1rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-1-border-left {
  border-left: solid 0.1rem #d8e5ef !important; }

.has-light-blue-grey-two-1-border-right {
  border-right: solid 0.1rem #d8e5ef !important; }

.has-light-blue-grey-two-1-border-top {
  border-top: solid 0.1rem #d8e5ef !important; }

.has-light-blue-grey-two-1-border-bottom {
  border-bottom: solid 0.1rem #d8e5ef !important; }

.has-light-blue-grey-two-2-border {
  border: solid 0.2rem #d8e5ef !important; }

.has-light-blue-grey-two-2-border-pulsate {
  border: solid 0.2rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-2-border-left {
  border-left: solid 0.2rem #d8e5ef !important; }

.has-light-blue-grey-two-2-border-right {
  border-right: solid 0.2rem #d8e5ef !important; }

.has-light-blue-grey-two-2-border-top {
  border-top: solid 0.2rem #d8e5ef !important; }

.has-light-blue-grey-two-2-border-bottom {
  border-bottom: solid 0.2rem #d8e5ef !important; }

.has-light-blue-grey-two-3-border {
  border: solid 0.3rem #d8e5ef !important; }

.has-light-blue-grey-two-3-border-pulsate {
  border: solid 0.3rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-3-border-left {
  border-left: solid 0.3rem #d8e5ef !important; }

.has-light-blue-grey-two-3-border-right {
  border-right: solid 0.3rem #d8e5ef !important; }

.has-light-blue-grey-two-3-border-top {
  border-top: solid 0.3rem #d8e5ef !important; }

.has-light-blue-grey-two-3-border-bottom {
  border-bottom: solid 0.3rem #d8e5ef !important; }

.has-light-blue-grey-two-4-border {
  border: solid 0.4rem #d8e5ef !important; }

.has-light-blue-grey-two-4-border-pulsate {
  border: solid 0.4rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-4-border-left {
  border-left: solid 0.4rem #d8e5ef !important; }

.has-light-blue-grey-two-4-border-right {
  border-right: solid 0.4rem #d8e5ef !important; }

.has-light-blue-grey-two-4-border-top {
  border-top: solid 0.4rem #d8e5ef !important; }

.has-light-blue-grey-two-4-border-bottom {
  border-bottom: solid 0.4rem #d8e5ef !important; }

.has-light-blue-grey-two-5-border {
  border: solid 0.5rem #d8e5ef !important; }

.has-light-blue-grey-two-5-border-pulsate {
  border: solid 0.5rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-5-border-left {
  border-left: solid 0.5rem #d8e5ef !important; }

.has-light-blue-grey-two-5-border-right {
  border-right: solid 0.5rem #d8e5ef !important; }

.has-light-blue-grey-two-5-border-top {
  border-top: solid 0.5rem #d8e5ef !important; }

.has-light-blue-grey-two-5-border-bottom {
  border-bottom: solid 0.5rem #d8e5ef !important; }

.has-light-blue-grey-two-6-border {
  border: solid 0.6rem #d8e5ef !important; }

.has-light-blue-grey-two-6-border-pulsate {
  border: solid 0.6rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-6-border-left {
  border-left: solid 0.6rem #d8e5ef !important; }

.has-light-blue-grey-two-6-border-right {
  border-right: solid 0.6rem #d8e5ef !important; }

.has-light-blue-grey-two-6-border-top {
  border-top: solid 0.6rem #d8e5ef !important; }

.has-light-blue-grey-two-6-border-bottom {
  border-bottom: solid 0.6rem #d8e5ef !important; }

.has-light-blue-grey-two-7-border {
  border: solid 0.7rem #d8e5ef !important; }

.has-light-blue-grey-two-7-border-pulsate {
  border: solid 0.7rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-7-border-left {
  border-left: solid 0.7rem #d8e5ef !important; }

.has-light-blue-grey-two-7-border-right {
  border-right: solid 0.7rem #d8e5ef !important; }

.has-light-blue-grey-two-7-border-top {
  border-top: solid 0.7rem #d8e5ef !important; }

.has-light-blue-grey-two-7-border-bottom {
  border-bottom: solid 0.7rem #d8e5ef !important; }

.has-light-blue-grey-two-8-border {
  border: solid 0.8rem #d8e5ef !important; }

.has-light-blue-grey-two-8-border-pulsate {
  border: solid 0.8rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-8-border-left {
  border-left: solid 0.8rem #d8e5ef !important; }

.has-light-blue-grey-two-8-border-right {
  border-right: solid 0.8rem #d8e5ef !important; }

.has-light-blue-grey-two-8-border-top {
  border-top: solid 0.8rem #d8e5ef !important; }

.has-light-blue-grey-two-8-border-bottom {
  border-bottom: solid 0.8rem #d8e5ef !important; }

.has-light-blue-grey-two-9-border {
  border: solid 0.9rem #d8e5ef !important; }

.has-light-blue-grey-two-9-border-pulsate {
  border: solid 0.9rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-9-border-left {
  border-left: solid 0.9rem #d8e5ef !important; }

.has-light-blue-grey-two-9-border-right {
  border-right: solid 0.9rem #d8e5ef !important; }

.has-light-blue-grey-two-9-border-top {
  border-top: solid 0.9rem #d8e5ef !important; }

.has-light-blue-grey-two-9-border-bottom {
  border-bottom: solid 0.9rem #d8e5ef !important; }

.has-light-blue-grey-two-10-border {
  border: solid 1rem #d8e5ef !important; }

.has-light-blue-grey-two-10-border-pulsate {
  border: solid 1rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-10-border-left {
  border-left: solid 1rem #d8e5ef !important; }

.has-light-blue-grey-two-10-border-right {
  border-right: solid 1rem #d8e5ef !important; }

.has-light-blue-grey-two-10-border-top {
  border-top: solid 1rem #d8e5ef !important; }

.has-light-blue-grey-two-10-border-bottom {
  border-bottom: solid 1rem #d8e5ef !important; }

.has-light-blue-grey-two-11-border {
  border: solid 1.1rem #d8e5ef !important; }

.has-light-blue-grey-two-11-border-pulsate {
  border: solid 1.1rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-11-border-left {
  border-left: solid 1.1rem #d8e5ef !important; }

.has-light-blue-grey-two-11-border-right {
  border-right: solid 1.1rem #d8e5ef !important; }

.has-light-blue-grey-two-11-border-top {
  border-top: solid 1.1rem #d8e5ef !important; }

.has-light-blue-grey-two-11-border-bottom {
  border-bottom: solid 1.1rem #d8e5ef !important; }

.has-light-blue-grey-two-12-border {
  border: solid 1.2rem #d8e5ef !important; }

.has-light-blue-grey-two-12-border-pulsate {
  border: solid 1.2rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-12-border-left {
  border-left: solid 1.2rem #d8e5ef !important; }

.has-light-blue-grey-two-12-border-right {
  border-right: solid 1.2rem #d8e5ef !important; }

.has-light-blue-grey-two-12-border-top {
  border-top: solid 1.2rem #d8e5ef !important; }

.has-light-blue-grey-two-12-border-bottom {
  border-bottom: solid 1.2rem #d8e5ef !important; }

.has-light-blue-grey-two-13-border {
  border: solid 1.3rem #d8e5ef !important; }

.has-light-blue-grey-two-13-border-pulsate {
  border: solid 1.3rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-13-border-left {
  border-left: solid 1.3rem #d8e5ef !important; }

.has-light-blue-grey-two-13-border-right {
  border-right: solid 1.3rem #d8e5ef !important; }

.has-light-blue-grey-two-13-border-top {
  border-top: solid 1.3rem #d8e5ef !important; }

.has-light-blue-grey-two-13-border-bottom {
  border-bottom: solid 1.3rem #d8e5ef !important; }

.has-light-blue-grey-two-14-border {
  border: solid 1.4rem #d8e5ef !important; }

.has-light-blue-grey-two-14-border-pulsate {
  border: solid 1.4rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-14-border-left {
  border-left: solid 1.4rem #d8e5ef !important; }

.has-light-blue-grey-two-14-border-right {
  border-right: solid 1.4rem #d8e5ef !important; }

.has-light-blue-grey-two-14-border-top {
  border-top: solid 1.4rem #d8e5ef !important; }

.has-light-blue-grey-two-14-border-bottom {
  border-bottom: solid 1.4rem #d8e5ef !important; }

.has-light-blue-grey-two-15-border {
  border: solid 1.5rem #d8e5ef !important; }

.has-light-blue-grey-two-15-border-pulsate {
  border: solid 1.5rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-15-border-left {
  border-left: solid 1.5rem #d8e5ef !important; }

.has-light-blue-grey-two-15-border-right {
  border-right: solid 1.5rem #d8e5ef !important; }

.has-light-blue-grey-two-15-border-top {
  border-top: solid 1.5rem #d8e5ef !important; }

.has-light-blue-grey-two-15-border-bottom {
  border-bottom: solid 1.5rem #d8e5ef !important; }

.has-light-blue-grey-two-16-border {
  border: solid 1.6rem #d8e5ef !important; }

.has-light-blue-grey-two-16-border-pulsate {
  border: solid 1.6rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-16-border-left {
  border-left: solid 1.6rem #d8e5ef !important; }

.has-light-blue-grey-two-16-border-right {
  border-right: solid 1.6rem #d8e5ef !important; }

.has-light-blue-grey-two-16-border-top {
  border-top: solid 1.6rem #d8e5ef !important; }

.has-light-blue-grey-two-16-border-bottom {
  border-bottom: solid 1.6rem #d8e5ef !important; }

.has-light-blue-grey-two-17-border {
  border: solid 1.7rem #d8e5ef !important; }

.has-light-blue-grey-two-17-border-pulsate {
  border: solid 1.7rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-17-border-left {
  border-left: solid 1.7rem #d8e5ef !important; }

.has-light-blue-grey-two-17-border-right {
  border-right: solid 1.7rem #d8e5ef !important; }

.has-light-blue-grey-two-17-border-top {
  border-top: solid 1.7rem #d8e5ef !important; }

.has-light-blue-grey-two-17-border-bottom {
  border-bottom: solid 1.7rem #d8e5ef !important; }

.has-light-blue-grey-two-18-border {
  border: solid 1.8rem #d8e5ef !important; }

.has-light-blue-grey-two-18-border-pulsate {
  border: solid 1.8rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-18-border-left {
  border-left: solid 1.8rem #d8e5ef !important; }

.has-light-blue-grey-two-18-border-right {
  border-right: solid 1.8rem #d8e5ef !important; }

.has-light-blue-grey-two-18-border-top {
  border-top: solid 1.8rem #d8e5ef !important; }

.has-light-blue-grey-two-18-border-bottom {
  border-bottom: solid 1.8rem #d8e5ef !important; }

.has-light-blue-grey-two-19-border {
  border: solid 1.9rem #d8e5ef !important; }

.has-light-blue-grey-two-19-border-pulsate {
  border: solid 1.9rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-19-border-left {
  border-left: solid 1.9rem #d8e5ef !important; }

.has-light-blue-grey-two-19-border-right {
  border-right: solid 1.9rem #d8e5ef !important; }

.has-light-blue-grey-two-19-border-top {
  border-top: solid 1.9rem #d8e5ef !important; }

.has-light-blue-grey-two-19-border-bottom {
  border-bottom: solid 1.9rem #d8e5ef !important; }

.has-light-blue-grey-two-20-border {
  border: solid 2rem #d8e5ef !important; }

.has-light-blue-grey-two-20-border-pulsate {
  border: solid 2rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-20-border-left {
  border-left: solid 2rem #d8e5ef !important; }

.has-light-blue-grey-two-20-border-right {
  border-right: solid 2rem #d8e5ef !important; }

.has-light-blue-grey-two-20-border-top {
  border-top: solid 2rem #d8e5ef !important; }

.has-light-blue-grey-two-20-border-bottom {
  border-bottom: solid 2rem #d8e5ef !important; }

.has-light-blue-grey-two-21-border {
  border: solid 2.1rem #d8e5ef !important; }

.has-light-blue-grey-two-21-border-pulsate {
  border: solid 2.1rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-21-border-left {
  border-left: solid 2.1rem #d8e5ef !important; }

.has-light-blue-grey-two-21-border-right {
  border-right: solid 2.1rem #d8e5ef !important; }

.has-light-blue-grey-two-21-border-top {
  border-top: solid 2.1rem #d8e5ef !important; }

.has-light-blue-grey-two-21-border-bottom {
  border-bottom: solid 2.1rem #d8e5ef !important; }

.has-light-blue-grey-two-24-border {
  border: solid 2.4rem #d8e5ef !important; }

.has-light-blue-grey-two-24-border-pulsate {
  border: solid 2.4rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-24-border-left {
  border-left: solid 2.4rem #d8e5ef !important; }

.has-light-blue-grey-two-24-border-right {
  border-right: solid 2.4rem #d8e5ef !important; }

.has-light-blue-grey-two-24-border-top {
  border-top: solid 2.4rem #d8e5ef !important; }

.has-light-blue-grey-two-24-border-bottom {
  border-bottom: solid 2.4rem #d8e5ef !important; }

.has-light-blue-grey-two-30-border {
  border: solid 3rem #d8e5ef !important; }

.has-light-blue-grey-two-30-border-pulsate {
  border: solid 3rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-30-border-left {
  border-left: solid 3rem #d8e5ef !important; }

.has-light-blue-grey-two-30-border-right {
  border-right: solid 3rem #d8e5ef !important; }

.has-light-blue-grey-two-30-border-top {
  border-top: solid 3rem #d8e5ef !important; }

.has-light-blue-grey-two-30-border-bottom {
  border-bottom: solid 3rem #d8e5ef !important; }

.has-light-blue-grey-two-40-border {
  border: solid 4rem #d8e5ef !important; }

.has-light-blue-grey-two-40-border-pulsate {
  border: solid 4rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-40-border-left {
  border-left: solid 4rem #d8e5ef !important; }

.has-light-blue-grey-two-40-border-right {
  border-right: solid 4rem #d8e5ef !important; }

.has-light-blue-grey-two-40-border-top {
  border-top: solid 4rem #d8e5ef !important; }

.has-light-blue-grey-two-40-border-bottom {
  border-bottom: solid 4rem #d8e5ef !important; }

.has-light-blue-grey-two-50-border {
  border: solid 5rem #d8e5ef !important; }

.has-light-blue-grey-two-50-border-pulsate {
  border: solid 5rem #d8e5ef !important;
  animation: light-blue-grey-two-border-pulsate 2s infinite; }

.has-light-blue-grey-two-50-border-left {
  border-left: solid 5rem #d8e5ef !important; }

.has-light-blue-grey-two-50-border-right {
  border-right: solid 5rem #d8e5ef !important; }

.has-light-blue-grey-two-50-border-top {
  border-top: solid 5rem #d8e5ef !important; }

.has-light-blue-grey-two-50-border-bottom {
  border-bottom: solid 5rem #d8e5ef !important; }

@keyframes dark-grey-blue-border-pulsate {
  0% {
    border-color: #2c4059; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #2c4059; } }

.has-dark-grey-blue-border-color {
  border-color: #2c4059; }

.has-dark-grey-blue-border {
  border: solid 0.1rem #2c4059; }

.has-dark-grey-blue-border-pulsate {
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-border-left {
  border-left: solid 0.1rem #2c4059; }

.has-dark-grey-blue-border-right {
  border-right: solid 0.1rem #2c4059; }

.has-dark-grey-blue-border-top {
  border-top: solid 0.1rem #2c4059; }

.has-dark-grey-blue-border-bottom {
  border-bottom: solid 0.1rem #2c4059; }

.has-dark-grey-blue-1-border {
  border: solid 0.1rem #2c4059 !important; }

.has-dark-grey-blue-1-border-pulsate {
  border: solid 0.1rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-1-border-left {
  border-left: solid 0.1rem #2c4059 !important; }

.has-dark-grey-blue-1-border-right {
  border-right: solid 0.1rem #2c4059 !important; }

.has-dark-grey-blue-1-border-top {
  border-top: solid 0.1rem #2c4059 !important; }

.has-dark-grey-blue-1-border-bottom {
  border-bottom: solid 0.1rem #2c4059 !important; }

.has-dark-grey-blue-2-border {
  border: solid 0.2rem #2c4059 !important; }

.has-dark-grey-blue-2-border-pulsate {
  border: solid 0.2rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-2-border-left {
  border-left: solid 0.2rem #2c4059 !important; }

.has-dark-grey-blue-2-border-right {
  border-right: solid 0.2rem #2c4059 !important; }

.has-dark-grey-blue-2-border-top {
  border-top: solid 0.2rem #2c4059 !important; }

.has-dark-grey-blue-2-border-bottom {
  border-bottom: solid 0.2rem #2c4059 !important; }

.has-dark-grey-blue-3-border {
  border: solid 0.3rem #2c4059 !important; }

.has-dark-grey-blue-3-border-pulsate {
  border: solid 0.3rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-3-border-left {
  border-left: solid 0.3rem #2c4059 !important; }

.has-dark-grey-blue-3-border-right {
  border-right: solid 0.3rem #2c4059 !important; }

.has-dark-grey-blue-3-border-top {
  border-top: solid 0.3rem #2c4059 !important; }

.has-dark-grey-blue-3-border-bottom {
  border-bottom: solid 0.3rem #2c4059 !important; }

.has-dark-grey-blue-4-border {
  border: solid 0.4rem #2c4059 !important; }

.has-dark-grey-blue-4-border-pulsate {
  border: solid 0.4rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-4-border-left {
  border-left: solid 0.4rem #2c4059 !important; }

.has-dark-grey-blue-4-border-right {
  border-right: solid 0.4rem #2c4059 !important; }

.has-dark-grey-blue-4-border-top {
  border-top: solid 0.4rem #2c4059 !important; }

.has-dark-grey-blue-4-border-bottom {
  border-bottom: solid 0.4rem #2c4059 !important; }

.has-dark-grey-blue-5-border {
  border: solid 0.5rem #2c4059 !important; }

.has-dark-grey-blue-5-border-pulsate {
  border: solid 0.5rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-5-border-left {
  border-left: solid 0.5rem #2c4059 !important; }

.has-dark-grey-blue-5-border-right {
  border-right: solid 0.5rem #2c4059 !important; }

.has-dark-grey-blue-5-border-top {
  border-top: solid 0.5rem #2c4059 !important; }

.has-dark-grey-blue-5-border-bottom {
  border-bottom: solid 0.5rem #2c4059 !important; }

.has-dark-grey-blue-6-border {
  border: solid 0.6rem #2c4059 !important; }

.has-dark-grey-blue-6-border-pulsate {
  border: solid 0.6rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-6-border-left {
  border-left: solid 0.6rem #2c4059 !important; }

.has-dark-grey-blue-6-border-right {
  border-right: solid 0.6rem #2c4059 !important; }

.has-dark-grey-blue-6-border-top {
  border-top: solid 0.6rem #2c4059 !important; }

.has-dark-grey-blue-6-border-bottom {
  border-bottom: solid 0.6rem #2c4059 !important; }

.has-dark-grey-blue-7-border {
  border: solid 0.7rem #2c4059 !important; }

.has-dark-grey-blue-7-border-pulsate {
  border: solid 0.7rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-7-border-left {
  border-left: solid 0.7rem #2c4059 !important; }

.has-dark-grey-blue-7-border-right {
  border-right: solid 0.7rem #2c4059 !important; }

.has-dark-grey-blue-7-border-top {
  border-top: solid 0.7rem #2c4059 !important; }

.has-dark-grey-blue-7-border-bottom {
  border-bottom: solid 0.7rem #2c4059 !important; }

.has-dark-grey-blue-8-border {
  border: solid 0.8rem #2c4059 !important; }

.has-dark-grey-blue-8-border-pulsate {
  border: solid 0.8rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-8-border-left {
  border-left: solid 0.8rem #2c4059 !important; }

.has-dark-grey-blue-8-border-right {
  border-right: solid 0.8rem #2c4059 !important; }

.has-dark-grey-blue-8-border-top {
  border-top: solid 0.8rem #2c4059 !important; }

.has-dark-grey-blue-8-border-bottom {
  border-bottom: solid 0.8rem #2c4059 !important; }

.has-dark-grey-blue-9-border {
  border: solid 0.9rem #2c4059 !important; }

.has-dark-grey-blue-9-border-pulsate {
  border: solid 0.9rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-9-border-left {
  border-left: solid 0.9rem #2c4059 !important; }

.has-dark-grey-blue-9-border-right {
  border-right: solid 0.9rem #2c4059 !important; }

.has-dark-grey-blue-9-border-top {
  border-top: solid 0.9rem #2c4059 !important; }

.has-dark-grey-blue-9-border-bottom {
  border-bottom: solid 0.9rem #2c4059 !important; }

.has-dark-grey-blue-10-border {
  border: solid 1rem #2c4059 !important; }

.has-dark-grey-blue-10-border-pulsate {
  border: solid 1rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-10-border-left {
  border-left: solid 1rem #2c4059 !important; }

.has-dark-grey-blue-10-border-right {
  border-right: solid 1rem #2c4059 !important; }

.has-dark-grey-blue-10-border-top {
  border-top: solid 1rem #2c4059 !important; }

.has-dark-grey-blue-10-border-bottom {
  border-bottom: solid 1rem #2c4059 !important; }

.has-dark-grey-blue-11-border {
  border: solid 1.1rem #2c4059 !important; }

.has-dark-grey-blue-11-border-pulsate {
  border: solid 1.1rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-11-border-left {
  border-left: solid 1.1rem #2c4059 !important; }

.has-dark-grey-blue-11-border-right {
  border-right: solid 1.1rem #2c4059 !important; }

.has-dark-grey-blue-11-border-top {
  border-top: solid 1.1rem #2c4059 !important; }

.has-dark-grey-blue-11-border-bottom {
  border-bottom: solid 1.1rem #2c4059 !important; }

.has-dark-grey-blue-12-border {
  border: solid 1.2rem #2c4059 !important; }

.has-dark-grey-blue-12-border-pulsate {
  border: solid 1.2rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-12-border-left {
  border-left: solid 1.2rem #2c4059 !important; }

.has-dark-grey-blue-12-border-right {
  border-right: solid 1.2rem #2c4059 !important; }

.has-dark-grey-blue-12-border-top {
  border-top: solid 1.2rem #2c4059 !important; }

.has-dark-grey-blue-12-border-bottom {
  border-bottom: solid 1.2rem #2c4059 !important; }

.has-dark-grey-blue-13-border {
  border: solid 1.3rem #2c4059 !important; }

.has-dark-grey-blue-13-border-pulsate {
  border: solid 1.3rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-13-border-left {
  border-left: solid 1.3rem #2c4059 !important; }

.has-dark-grey-blue-13-border-right {
  border-right: solid 1.3rem #2c4059 !important; }

.has-dark-grey-blue-13-border-top {
  border-top: solid 1.3rem #2c4059 !important; }

.has-dark-grey-blue-13-border-bottom {
  border-bottom: solid 1.3rem #2c4059 !important; }

.has-dark-grey-blue-14-border {
  border: solid 1.4rem #2c4059 !important; }

.has-dark-grey-blue-14-border-pulsate {
  border: solid 1.4rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-14-border-left {
  border-left: solid 1.4rem #2c4059 !important; }

.has-dark-grey-blue-14-border-right {
  border-right: solid 1.4rem #2c4059 !important; }

.has-dark-grey-blue-14-border-top {
  border-top: solid 1.4rem #2c4059 !important; }

.has-dark-grey-blue-14-border-bottom {
  border-bottom: solid 1.4rem #2c4059 !important; }

.has-dark-grey-blue-15-border {
  border: solid 1.5rem #2c4059 !important; }

.has-dark-grey-blue-15-border-pulsate {
  border: solid 1.5rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-15-border-left {
  border-left: solid 1.5rem #2c4059 !important; }

.has-dark-grey-blue-15-border-right {
  border-right: solid 1.5rem #2c4059 !important; }

.has-dark-grey-blue-15-border-top {
  border-top: solid 1.5rem #2c4059 !important; }

.has-dark-grey-blue-15-border-bottom {
  border-bottom: solid 1.5rem #2c4059 !important; }

.has-dark-grey-blue-16-border {
  border: solid 1.6rem #2c4059 !important; }

.has-dark-grey-blue-16-border-pulsate {
  border: solid 1.6rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-border-left {
  border-left: solid 1.6rem #2c4059 !important; }

.has-dark-grey-blue-16-border-right {
  border-right: solid 1.6rem #2c4059 !important; }

.has-dark-grey-blue-16-border-top {
  border-top: solid 1.6rem #2c4059 !important; }

.has-dark-grey-blue-16-border-bottom {
  border-bottom: solid 1.6rem #2c4059 !important; }

.has-dark-grey-blue-17-border {
  border: solid 1.7rem #2c4059 !important; }

.has-dark-grey-blue-17-border-pulsate {
  border: solid 1.7rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-17-border-left {
  border-left: solid 1.7rem #2c4059 !important; }

.has-dark-grey-blue-17-border-right {
  border-right: solid 1.7rem #2c4059 !important; }

.has-dark-grey-blue-17-border-top {
  border-top: solid 1.7rem #2c4059 !important; }

.has-dark-grey-blue-17-border-bottom {
  border-bottom: solid 1.7rem #2c4059 !important; }

.has-dark-grey-blue-18-border {
  border: solid 1.8rem #2c4059 !important; }

.has-dark-grey-blue-18-border-pulsate {
  border: solid 1.8rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-18-border-left {
  border-left: solid 1.8rem #2c4059 !important; }

.has-dark-grey-blue-18-border-right {
  border-right: solid 1.8rem #2c4059 !important; }

.has-dark-grey-blue-18-border-top {
  border-top: solid 1.8rem #2c4059 !important; }

.has-dark-grey-blue-18-border-bottom {
  border-bottom: solid 1.8rem #2c4059 !important; }

.has-dark-grey-blue-19-border {
  border: solid 1.9rem #2c4059 !important; }

.has-dark-grey-blue-19-border-pulsate {
  border: solid 1.9rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-19-border-left {
  border-left: solid 1.9rem #2c4059 !important; }

.has-dark-grey-blue-19-border-right {
  border-right: solid 1.9rem #2c4059 !important; }

.has-dark-grey-blue-19-border-top {
  border-top: solid 1.9rem #2c4059 !important; }

.has-dark-grey-blue-19-border-bottom {
  border-bottom: solid 1.9rem #2c4059 !important; }

.has-dark-grey-blue-20-border {
  border: solid 2rem #2c4059 !important; }

.has-dark-grey-blue-20-border-pulsate {
  border: solid 2rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-20-border-left {
  border-left: solid 2rem #2c4059 !important; }

.has-dark-grey-blue-20-border-right {
  border-right: solid 2rem #2c4059 !important; }

.has-dark-grey-blue-20-border-top {
  border-top: solid 2rem #2c4059 !important; }

.has-dark-grey-blue-20-border-bottom {
  border-bottom: solid 2rem #2c4059 !important; }

.has-dark-grey-blue-21-border {
  border: solid 2.1rem #2c4059 !important; }

.has-dark-grey-blue-21-border-pulsate {
  border: solid 2.1rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-21-border-left {
  border-left: solid 2.1rem #2c4059 !important; }

.has-dark-grey-blue-21-border-right {
  border-right: solid 2.1rem #2c4059 !important; }

.has-dark-grey-blue-21-border-top {
  border-top: solid 2.1rem #2c4059 !important; }

.has-dark-grey-blue-21-border-bottom {
  border-bottom: solid 2.1rem #2c4059 !important; }

.has-dark-grey-blue-24-border {
  border: solid 2.4rem #2c4059 !important; }

.has-dark-grey-blue-24-border-pulsate {
  border: solid 2.4rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-24-border-left {
  border-left: solid 2.4rem #2c4059 !important; }

.has-dark-grey-blue-24-border-right {
  border-right: solid 2.4rem #2c4059 !important; }

.has-dark-grey-blue-24-border-top {
  border-top: solid 2.4rem #2c4059 !important; }

.has-dark-grey-blue-24-border-bottom {
  border-bottom: solid 2.4rem #2c4059 !important; }

.has-dark-grey-blue-30-border {
  border: solid 3rem #2c4059 !important; }

.has-dark-grey-blue-30-border-pulsate {
  border: solid 3rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-30-border-left {
  border-left: solid 3rem #2c4059 !important; }

.has-dark-grey-blue-30-border-right {
  border-right: solid 3rem #2c4059 !important; }

.has-dark-grey-blue-30-border-top {
  border-top: solid 3rem #2c4059 !important; }

.has-dark-grey-blue-30-border-bottom {
  border-bottom: solid 3rem #2c4059 !important; }

.has-dark-grey-blue-40-border {
  border: solid 4rem #2c4059 !important; }

.has-dark-grey-blue-40-border-pulsate {
  border: solid 4rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-40-border-left {
  border-left: solid 4rem #2c4059 !important; }

.has-dark-grey-blue-40-border-right {
  border-right: solid 4rem #2c4059 !important; }

.has-dark-grey-blue-40-border-top {
  border-top: solid 4rem #2c4059 !important; }

.has-dark-grey-blue-40-border-bottom {
  border-bottom: solid 4rem #2c4059 !important; }

.has-dark-grey-blue-50-border {
  border: solid 5rem #2c4059 !important; }

.has-dark-grey-blue-50-border-pulsate {
  border: solid 5rem #2c4059 !important;
  animation: dark-grey-blue-border-pulsate 2s infinite; }

.has-dark-grey-blue-50-border-left {
  border-left: solid 5rem #2c4059 !important; }

.has-dark-grey-blue-50-border-right {
  border-right: solid 5rem #2c4059 !important; }

.has-dark-grey-blue-50-border-top {
  border-top: solid 5rem #2c4059 !important; }

.has-dark-grey-blue-50-border-bottom {
  border-bottom: solid 5rem #2c4059 !important; }

@keyframes dark-grey-blue-16-border-pulsate {
  0% {
    border-color: #2c405a; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #2c405a; } }

.has-dark-grey-blue-16-border-color {
  border-color: rgba(44, 64, 90, 0.16); }

.has-dark-grey-blue-16-border {
  border: solid 0.1rem rgba(44, 64, 90, 0.16); }

.has-dark-grey-blue-16-border-pulsate {
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-border-left {
  border-left: solid 0.1rem rgba(44, 64, 90, 0.16); }

.has-dark-grey-blue-16-border-right {
  border-right: solid 0.1rem rgba(44, 64, 90, 0.16); }

.has-dark-grey-blue-16-border-top {
  border-top: solid 0.1rem rgba(44, 64, 90, 0.16); }

.has-dark-grey-blue-16-border-bottom {
  border-bottom: solid 0.1rem rgba(44, 64, 90, 0.16); }

.has-dark-grey-blue-16-1-border {
  border: solid 0.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-1-border-pulsate {
  border: solid 0.1rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-1-border-left {
  border-left: solid 0.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-1-border-right {
  border-right: solid 0.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-1-border-top {
  border-top: solid 0.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-1-border-bottom {
  border-bottom: solid 0.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-2-border {
  border: solid 0.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-2-border-pulsate {
  border: solid 0.2rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-2-border-left {
  border-left: solid 0.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-2-border-right {
  border-right: solid 0.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-2-border-top {
  border-top: solid 0.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-2-border-bottom {
  border-bottom: solid 0.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-3-border {
  border: solid 0.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-3-border-pulsate {
  border: solid 0.3rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-3-border-left {
  border-left: solid 0.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-3-border-right {
  border-right: solid 0.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-3-border-top {
  border-top: solid 0.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-3-border-bottom {
  border-bottom: solid 0.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-4-border {
  border: solid 0.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-4-border-pulsate {
  border: solid 0.4rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-4-border-left {
  border-left: solid 0.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-4-border-right {
  border-right: solid 0.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-4-border-top {
  border-top: solid 0.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-4-border-bottom {
  border-bottom: solid 0.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-5-border {
  border: solid 0.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-5-border-pulsate {
  border: solid 0.5rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-5-border-left {
  border-left: solid 0.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-5-border-right {
  border-right: solid 0.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-5-border-top {
  border-top: solid 0.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-5-border-bottom {
  border-bottom: solid 0.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-6-border {
  border: solid 0.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-6-border-pulsate {
  border: solid 0.6rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-6-border-left {
  border-left: solid 0.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-6-border-right {
  border-right: solid 0.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-6-border-top {
  border-top: solid 0.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-6-border-bottom {
  border-bottom: solid 0.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-7-border {
  border: solid 0.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-7-border-pulsate {
  border: solid 0.7rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-7-border-left {
  border-left: solid 0.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-7-border-right {
  border-right: solid 0.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-7-border-top {
  border-top: solid 0.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-7-border-bottom {
  border-bottom: solid 0.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-8-border {
  border: solid 0.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-8-border-pulsate {
  border: solid 0.8rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-8-border-left {
  border-left: solid 0.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-8-border-right {
  border-right: solid 0.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-8-border-top {
  border-top: solid 0.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-8-border-bottom {
  border-bottom: solid 0.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-9-border {
  border: solid 0.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-9-border-pulsate {
  border: solid 0.9rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-9-border-left {
  border-left: solid 0.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-9-border-right {
  border-right: solid 0.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-9-border-top {
  border-top: solid 0.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-9-border-bottom {
  border-bottom: solid 0.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-10-border {
  border: solid 1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-10-border-pulsate {
  border: solid 1rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-10-border-left {
  border-left: solid 1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-10-border-right {
  border-right: solid 1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-10-border-top {
  border-top: solid 1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-10-border-bottom {
  border-bottom: solid 1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-11-border {
  border: solid 1.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-11-border-pulsate {
  border: solid 1.1rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-11-border-left {
  border-left: solid 1.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-11-border-right {
  border-right: solid 1.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-11-border-top {
  border-top: solid 1.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-11-border-bottom {
  border-bottom: solid 1.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-12-border {
  border: solid 1.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-12-border-pulsate {
  border: solid 1.2rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-12-border-left {
  border-left: solid 1.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-12-border-right {
  border-right: solid 1.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-12-border-top {
  border-top: solid 1.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-12-border-bottom {
  border-bottom: solid 1.2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-13-border {
  border: solid 1.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-13-border-pulsate {
  border: solid 1.3rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-13-border-left {
  border-left: solid 1.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-13-border-right {
  border-right: solid 1.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-13-border-top {
  border-top: solid 1.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-13-border-bottom {
  border-bottom: solid 1.3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-14-border {
  border: solid 1.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-14-border-pulsate {
  border: solid 1.4rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-14-border-left {
  border-left: solid 1.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-14-border-right {
  border-right: solid 1.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-14-border-top {
  border-top: solid 1.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-14-border-bottom {
  border-bottom: solid 1.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-15-border {
  border: solid 1.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-15-border-pulsate {
  border: solid 1.5rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-15-border-left {
  border-left: solid 1.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-15-border-right {
  border-right: solid 1.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-15-border-top {
  border-top: solid 1.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-15-border-bottom {
  border-bottom: solid 1.5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-16-border {
  border: solid 1.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-16-border-pulsate {
  border: solid 1.6rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-16-border-left {
  border-left: solid 1.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-16-border-right {
  border-right: solid 1.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-16-border-top {
  border-top: solid 1.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-16-border-bottom {
  border-bottom: solid 1.6rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-17-border {
  border: solid 1.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-17-border-pulsate {
  border: solid 1.7rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-17-border-left {
  border-left: solid 1.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-17-border-right {
  border-right: solid 1.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-17-border-top {
  border-top: solid 1.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-17-border-bottom {
  border-bottom: solid 1.7rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-18-border {
  border: solid 1.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-18-border-pulsate {
  border: solid 1.8rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-18-border-left {
  border-left: solid 1.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-18-border-right {
  border-right: solid 1.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-18-border-top {
  border-top: solid 1.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-18-border-bottom {
  border-bottom: solid 1.8rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-19-border {
  border: solid 1.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-19-border-pulsate {
  border: solid 1.9rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-19-border-left {
  border-left: solid 1.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-19-border-right {
  border-right: solid 1.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-19-border-top {
  border-top: solid 1.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-19-border-bottom {
  border-bottom: solid 1.9rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-20-border {
  border: solid 2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-20-border-pulsate {
  border: solid 2rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-20-border-left {
  border-left: solid 2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-20-border-right {
  border-right: solid 2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-20-border-top {
  border-top: solid 2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-20-border-bottom {
  border-bottom: solid 2rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-21-border {
  border: solid 2.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-21-border-pulsate {
  border: solid 2.1rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-21-border-left {
  border-left: solid 2.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-21-border-right {
  border-right: solid 2.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-21-border-top {
  border-top: solid 2.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-21-border-bottom {
  border-bottom: solid 2.1rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-24-border {
  border: solid 2.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-24-border-pulsate {
  border: solid 2.4rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-24-border-left {
  border-left: solid 2.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-24-border-right {
  border-right: solid 2.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-24-border-top {
  border-top: solid 2.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-24-border-bottom {
  border-bottom: solid 2.4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-30-border {
  border: solid 3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-30-border-pulsate {
  border: solid 3rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-30-border-left {
  border-left: solid 3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-30-border-right {
  border-right: solid 3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-30-border-top {
  border-top: solid 3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-30-border-bottom {
  border-bottom: solid 3rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-40-border {
  border: solid 4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-40-border-pulsate {
  border: solid 4rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-40-border-left {
  border-left: solid 4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-40-border-right {
  border-right: solid 4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-40-border-top {
  border-top: solid 4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-40-border-bottom {
  border-bottom: solid 4rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-50-border {
  border: solid 5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-50-border-pulsate {
  border: solid 5rem rgba(44, 64, 90, 0.16) !important;
  animation: dark-grey-blue-16-border-pulsate 2s infinite; }

.has-dark-grey-blue-16-50-border-left {
  border-left: solid 5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-50-border-right {
  border-right: solid 5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-50-border-top {
  border-top: solid 5rem rgba(44, 64, 90, 0.16) !important; }

.has-dark-grey-blue-16-50-border-bottom {
  border-bottom: solid 5rem rgba(44, 64, 90, 0.16) !important; }

@keyframes dark-2-border-pulsate {
  0% {
    border-color: #263238; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #263238; } }

.has-dark-2-border-color {
  border-color: rgba(38, 50, 56, 0.02); }

.has-dark-2-border {
  border: solid 0.1rem rgba(38, 50, 56, 0.02); }

.has-dark-2-border-pulsate {
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-border-left {
  border-left: solid 0.1rem rgba(38, 50, 56, 0.02); }

.has-dark-2-border-right {
  border-right: solid 0.1rem rgba(38, 50, 56, 0.02); }

.has-dark-2-border-top {
  border-top: solid 0.1rem rgba(38, 50, 56, 0.02); }

.has-dark-2-border-bottom {
  border-bottom: solid 0.1rem rgba(38, 50, 56, 0.02); }

.has-dark-2-1-border {
  border: solid 0.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-1-border-pulsate {
  border: solid 0.1rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-1-border-left {
  border-left: solid 0.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-1-border-right {
  border-right: solid 0.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-1-border-top {
  border-top: solid 0.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-1-border-bottom {
  border-bottom: solid 0.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-2-border {
  border: solid 0.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-2-border-pulsate {
  border: solid 0.2rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-2-border-left {
  border-left: solid 0.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-2-border-right {
  border-right: solid 0.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-2-border-top {
  border-top: solid 0.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-2-border-bottom {
  border-bottom: solid 0.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-3-border {
  border: solid 0.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-3-border-pulsate {
  border: solid 0.3rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-3-border-left {
  border-left: solid 0.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-3-border-right {
  border-right: solid 0.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-3-border-top {
  border-top: solid 0.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-3-border-bottom {
  border-bottom: solid 0.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-4-border {
  border: solid 0.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-4-border-pulsate {
  border: solid 0.4rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-4-border-left {
  border-left: solid 0.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-4-border-right {
  border-right: solid 0.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-4-border-top {
  border-top: solid 0.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-4-border-bottom {
  border-bottom: solid 0.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-5-border {
  border: solid 0.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-5-border-pulsate {
  border: solid 0.5rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-5-border-left {
  border-left: solid 0.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-5-border-right {
  border-right: solid 0.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-5-border-top {
  border-top: solid 0.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-5-border-bottom {
  border-bottom: solid 0.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-6-border {
  border: solid 0.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-6-border-pulsate {
  border: solid 0.6rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-6-border-left {
  border-left: solid 0.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-6-border-right {
  border-right: solid 0.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-6-border-top {
  border-top: solid 0.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-6-border-bottom {
  border-bottom: solid 0.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-7-border {
  border: solid 0.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-7-border-pulsate {
  border: solid 0.7rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-7-border-left {
  border-left: solid 0.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-7-border-right {
  border-right: solid 0.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-7-border-top {
  border-top: solid 0.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-7-border-bottom {
  border-bottom: solid 0.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-8-border {
  border: solid 0.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-8-border-pulsate {
  border: solid 0.8rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-8-border-left {
  border-left: solid 0.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-8-border-right {
  border-right: solid 0.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-8-border-top {
  border-top: solid 0.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-8-border-bottom {
  border-bottom: solid 0.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-9-border {
  border: solid 0.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-9-border-pulsate {
  border: solid 0.9rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-9-border-left {
  border-left: solid 0.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-9-border-right {
  border-right: solid 0.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-9-border-top {
  border-top: solid 0.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-9-border-bottom {
  border-bottom: solid 0.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-10-border {
  border: solid 1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-10-border-pulsate {
  border: solid 1rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-10-border-left {
  border-left: solid 1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-10-border-right {
  border-right: solid 1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-10-border-top {
  border-top: solid 1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-10-border-bottom {
  border-bottom: solid 1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-11-border {
  border: solid 1.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-11-border-pulsate {
  border: solid 1.1rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-11-border-left {
  border-left: solid 1.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-11-border-right {
  border-right: solid 1.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-11-border-top {
  border-top: solid 1.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-11-border-bottom {
  border-bottom: solid 1.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-12-border {
  border: solid 1.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-12-border-pulsate {
  border: solid 1.2rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-12-border-left {
  border-left: solid 1.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-12-border-right {
  border-right: solid 1.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-12-border-top {
  border-top: solid 1.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-12-border-bottom {
  border-bottom: solid 1.2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-13-border {
  border: solid 1.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-13-border-pulsate {
  border: solid 1.3rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-13-border-left {
  border-left: solid 1.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-13-border-right {
  border-right: solid 1.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-13-border-top {
  border-top: solid 1.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-13-border-bottom {
  border-bottom: solid 1.3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-14-border {
  border: solid 1.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-14-border-pulsate {
  border: solid 1.4rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-14-border-left {
  border-left: solid 1.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-14-border-right {
  border-right: solid 1.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-14-border-top {
  border-top: solid 1.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-14-border-bottom {
  border-bottom: solid 1.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-15-border {
  border: solid 1.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-15-border-pulsate {
  border: solid 1.5rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-15-border-left {
  border-left: solid 1.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-15-border-right {
  border-right: solid 1.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-15-border-top {
  border-top: solid 1.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-15-border-bottom {
  border-bottom: solid 1.5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-16-border {
  border: solid 1.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-16-border-pulsate {
  border: solid 1.6rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-16-border-left {
  border-left: solid 1.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-16-border-right {
  border-right: solid 1.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-16-border-top {
  border-top: solid 1.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-16-border-bottom {
  border-bottom: solid 1.6rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-17-border {
  border: solid 1.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-17-border-pulsate {
  border: solid 1.7rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-17-border-left {
  border-left: solid 1.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-17-border-right {
  border-right: solid 1.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-17-border-top {
  border-top: solid 1.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-17-border-bottom {
  border-bottom: solid 1.7rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-18-border {
  border: solid 1.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-18-border-pulsate {
  border: solid 1.8rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-18-border-left {
  border-left: solid 1.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-18-border-right {
  border-right: solid 1.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-18-border-top {
  border-top: solid 1.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-18-border-bottom {
  border-bottom: solid 1.8rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-19-border {
  border: solid 1.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-19-border-pulsate {
  border: solid 1.9rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-19-border-left {
  border-left: solid 1.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-19-border-right {
  border-right: solid 1.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-19-border-top {
  border-top: solid 1.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-19-border-bottom {
  border-bottom: solid 1.9rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-20-border {
  border: solid 2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-20-border-pulsate {
  border: solid 2rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-20-border-left {
  border-left: solid 2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-20-border-right {
  border-right: solid 2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-20-border-top {
  border-top: solid 2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-20-border-bottom {
  border-bottom: solid 2rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-21-border {
  border: solid 2.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-21-border-pulsate {
  border: solid 2.1rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-21-border-left {
  border-left: solid 2.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-21-border-right {
  border-right: solid 2.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-21-border-top {
  border-top: solid 2.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-21-border-bottom {
  border-bottom: solid 2.1rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-24-border {
  border: solid 2.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-24-border-pulsate {
  border: solid 2.4rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-24-border-left {
  border-left: solid 2.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-24-border-right {
  border-right: solid 2.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-24-border-top {
  border-top: solid 2.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-24-border-bottom {
  border-bottom: solid 2.4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-30-border {
  border: solid 3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-30-border-pulsate {
  border: solid 3rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-30-border-left {
  border-left: solid 3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-30-border-right {
  border-right: solid 3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-30-border-top {
  border-top: solid 3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-30-border-bottom {
  border-bottom: solid 3rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-40-border {
  border: solid 4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-40-border-pulsate {
  border: solid 4rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-40-border-left {
  border-left: solid 4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-40-border-right {
  border-right: solid 4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-40-border-top {
  border-top: solid 4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-40-border-bottom {
  border-bottom: solid 4rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-50-border {
  border: solid 5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-50-border-pulsate {
  border: solid 5rem rgba(38, 50, 56, 0.02) !important;
  animation: dark-2-border-pulsate 2s infinite; }

.has-dark-2-50-border-left {
  border-left: solid 5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-50-border-right {
  border-right: solid 5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-50-border-top {
  border-top: solid 5rem rgba(38, 50, 56, 0.02) !important; }

.has-dark-2-50-border-bottom {
  border-bottom: solid 5rem rgba(38, 50, 56, 0.02) !important; }

@keyframes dark-3-border-pulsate {
  0% {
    border-color: #263238; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #263238; } }

.has-dark-3-border-color {
  border-color: rgba(38, 50, 56, 0.03); }

.has-dark-3-border {
  border: solid 0.1rem rgba(38, 50, 56, 0.03); }

.has-dark-3-border-pulsate {
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-border-left {
  border-left: solid 0.1rem rgba(38, 50, 56, 0.03); }

.has-dark-3-border-right {
  border-right: solid 0.1rem rgba(38, 50, 56, 0.03); }

.has-dark-3-border-top {
  border-top: solid 0.1rem rgba(38, 50, 56, 0.03); }

.has-dark-3-border-bottom {
  border-bottom: solid 0.1rem rgba(38, 50, 56, 0.03); }

.has-dark-3-1-border {
  border: solid 0.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-1-border-pulsate {
  border: solid 0.1rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-1-border-left {
  border-left: solid 0.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-1-border-right {
  border-right: solid 0.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-1-border-top {
  border-top: solid 0.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-1-border-bottom {
  border-bottom: solid 0.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-2-border {
  border: solid 0.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-2-border-pulsate {
  border: solid 0.2rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-2-border-left {
  border-left: solid 0.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-2-border-right {
  border-right: solid 0.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-2-border-top {
  border-top: solid 0.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-2-border-bottom {
  border-bottom: solid 0.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-3-border {
  border: solid 0.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-3-border-pulsate {
  border: solid 0.3rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-3-border-left {
  border-left: solid 0.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-3-border-right {
  border-right: solid 0.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-3-border-top {
  border-top: solid 0.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-3-border-bottom {
  border-bottom: solid 0.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-4-border {
  border: solid 0.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-4-border-pulsate {
  border: solid 0.4rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-4-border-left {
  border-left: solid 0.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-4-border-right {
  border-right: solid 0.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-4-border-top {
  border-top: solid 0.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-4-border-bottom {
  border-bottom: solid 0.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-5-border {
  border: solid 0.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-5-border-pulsate {
  border: solid 0.5rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-5-border-left {
  border-left: solid 0.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-5-border-right {
  border-right: solid 0.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-5-border-top {
  border-top: solid 0.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-5-border-bottom {
  border-bottom: solid 0.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-6-border {
  border: solid 0.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-6-border-pulsate {
  border: solid 0.6rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-6-border-left {
  border-left: solid 0.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-6-border-right {
  border-right: solid 0.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-6-border-top {
  border-top: solid 0.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-6-border-bottom {
  border-bottom: solid 0.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-7-border {
  border: solid 0.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-7-border-pulsate {
  border: solid 0.7rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-7-border-left {
  border-left: solid 0.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-7-border-right {
  border-right: solid 0.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-7-border-top {
  border-top: solid 0.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-7-border-bottom {
  border-bottom: solid 0.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-8-border {
  border: solid 0.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-8-border-pulsate {
  border: solid 0.8rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-8-border-left {
  border-left: solid 0.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-8-border-right {
  border-right: solid 0.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-8-border-top {
  border-top: solid 0.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-8-border-bottom {
  border-bottom: solid 0.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-9-border {
  border: solid 0.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-9-border-pulsate {
  border: solid 0.9rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-9-border-left {
  border-left: solid 0.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-9-border-right {
  border-right: solid 0.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-9-border-top {
  border-top: solid 0.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-9-border-bottom {
  border-bottom: solid 0.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-10-border {
  border: solid 1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-10-border-pulsate {
  border: solid 1rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-10-border-left {
  border-left: solid 1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-10-border-right {
  border-right: solid 1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-10-border-top {
  border-top: solid 1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-10-border-bottom {
  border-bottom: solid 1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-11-border {
  border: solid 1.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-11-border-pulsate {
  border: solid 1.1rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-11-border-left {
  border-left: solid 1.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-11-border-right {
  border-right: solid 1.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-11-border-top {
  border-top: solid 1.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-11-border-bottom {
  border-bottom: solid 1.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-12-border {
  border: solid 1.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-12-border-pulsate {
  border: solid 1.2rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-12-border-left {
  border-left: solid 1.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-12-border-right {
  border-right: solid 1.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-12-border-top {
  border-top: solid 1.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-12-border-bottom {
  border-bottom: solid 1.2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-13-border {
  border: solid 1.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-13-border-pulsate {
  border: solid 1.3rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-13-border-left {
  border-left: solid 1.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-13-border-right {
  border-right: solid 1.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-13-border-top {
  border-top: solid 1.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-13-border-bottom {
  border-bottom: solid 1.3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-14-border {
  border: solid 1.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-14-border-pulsate {
  border: solid 1.4rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-14-border-left {
  border-left: solid 1.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-14-border-right {
  border-right: solid 1.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-14-border-top {
  border-top: solid 1.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-14-border-bottom {
  border-bottom: solid 1.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-15-border {
  border: solid 1.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-15-border-pulsate {
  border: solid 1.5rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-15-border-left {
  border-left: solid 1.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-15-border-right {
  border-right: solid 1.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-15-border-top {
  border-top: solid 1.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-15-border-bottom {
  border-bottom: solid 1.5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-16-border {
  border: solid 1.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-16-border-pulsate {
  border: solid 1.6rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-16-border-left {
  border-left: solid 1.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-16-border-right {
  border-right: solid 1.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-16-border-top {
  border-top: solid 1.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-16-border-bottom {
  border-bottom: solid 1.6rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-17-border {
  border: solid 1.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-17-border-pulsate {
  border: solid 1.7rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-17-border-left {
  border-left: solid 1.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-17-border-right {
  border-right: solid 1.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-17-border-top {
  border-top: solid 1.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-17-border-bottom {
  border-bottom: solid 1.7rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-18-border {
  border: solid 1.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-18-border-pulsate {
  border: solid 1.8rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-18-border-left {
  border-left: solid 1.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-18-border-right {
  border-right: solid 1.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-18-border-top {
  border-top: solid 1.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-18-border-bottom {
  border-bottom: solid 1.8rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-19-border {
  border: solid 1.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-19-border-pulsate {
  border: solid 1.9rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-19-border-left {
  border-left: solid 1.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-19-border-right {
  border-right: solid 1.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-19-border-top {
  border-top: solid 1.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-19-border-bottom {
  border-bottom: solid 1.9rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-20-border {
  border: solid 2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-20-border-pulsate {
  border: solid 2rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-20-border-left {
  border-left: solid 2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-20-border-right {
  border-right: solid 2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-20-border-top {
  border-top: solid 2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-20-border-bottom {
  border-bottom: solid 2rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-21-border {
  border: solid 2.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-21-border-pulsate {
  border: solid 2.1rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-21-border-left {
  border-left: solid 2.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-21-border-right {
  border-right: solid 2.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-21-border-top {
  border-top: solid 2.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-21-border-bottom {
  border-bottom: solid 2.1rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-24-border {
  border: solid 2.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-24-border-pulsate {
  border: solid 2.4rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-24-border-left {
  border-left: solid 2.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-24-border-right {
  border-right: solid 2.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-24-border-top {
  border-top: solid 2.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-24-border-bottom {
  border-bottom: solid 2.4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-30-border {
  border: solid 3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-30-border-pulsate {
  border: solid 3rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-30-border-left {
  border-left: solid 3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-30-border-right {
  border-right: solid 3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-30-border-top {
  border-top: solid 3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-30-border-bottom {
  border-bottom: solid 3rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-40-border {
  border: solid 4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-40-border-pulsate {
  border: solid 4rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-40-border-left {
  border-left: solid 4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-40-border-right {
  border-right: solid 4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-40-border-top {
  border-top: solid 4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-40-border-bottom {
  border-bottom: solid 4rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-50-border {
  border: solid 5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-50-border-pulsate {
  border: solid 5rem rgba(38, 50, 56, 0.03) !important;
  animation: dark-3-border-pulsate 2s infinite; }

.has-dark-3-50-border-left {
  border-left: solid 5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-50-border-right {
  border-right: solid 5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-50-border-top {
  border-top: solid 5rem rgba(38, 50, 56, 0.03) !important; }

.has-dark-3-50-border-bottom {
  border-bottom: solid 5rem rgba(38, 50, 56, 0.03) !important; }

@keyframes charcoal-grey-border-pulsate {
  0% {
    border-color: #37474f; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #37474f; } }

.has-charcoal-grey-border-color {
  border-color: #37474f; }

.has-charcoal-grey-border {
  border: solid 0.1rem #37474f; }

.has-charcoal-grey-border-pulsate {
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-border-left {
  border-left: solid 0.1rem #37474f; }

.has-charcoal-grey-border-right {
  border-right: solid 0.1rem #37474f; }

.has-charcoal-grey-border-top {
  border-top: solid 0.1rem #37474f; }

.has-charcoal-grey-border-bottom {
  border-bottom: solid 0.1rem #37474f; }

.has-charcoal-grey-1-border {
  border: solid 0.1rem #37474f !important; }

.has-charcoal-grey-1-border-pulsate {
  border: solid 0.1rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-1-border-left {
  border-left: solid 0.1rem #37474f !important; }

.has-charcoal-grey-1-border-right {
  border-right: solid 0.1rem #37474f !important; }

.has-charcoal-grey-1-border-top {
  border-top: solid 0.1rem #37474f !important; }

.has-charcoal-grey-1-border-bottom {
  border-bottom: solid 0.1rem #37474f !important; }

.has-charcoal-grey-2-border {
  border: solid 0.2rem #37474f !important; }

.has-charcoal-grey-2-border-pulsate {
  border: solid 0.2rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-2-border-left {
  border-left: solid 0.2rem #37474f !important; }

.has-charcoal-grey-2-border-right {
  border-right: solid 0.2rem #37474f !important; }

.has-charcoal-grey-2-border-top {
  border-top: solid 0.2rem #37474f !important; }

.has-charcoal-grey-2-border-bottom {
  border-bottom: solid 0.2rem #37474f !important; }

.has-charcoal-grey-3-border {
  border: solid 0.3rem #37474f !important; }

.has-charcoal-grey-3-border-pulsate {
  border: solid 0.3rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-3-border-left {
  border-left: solid 0.3rem #37474f !important; }

.has-charcoal-grey-3-border-right {
  border-right: solid 0.3rem #37474f !important; }

.has-charcoal-grey-3-border-top {
  border-top: solid 0.3rem #37474f !important; }

.has-charcoal-grey-3-border-bottom {
  border-bottom: solid 0.3rem #37474f !important; }

.has-charcoal-grey-4-border {
  border: solid 0.4rem #37474f !important; }

.has-charcoal-grey-4-border-pulsate {
  border: solid 0.4rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-4-border-left {
  border-left: solid 0.4rem #37474f !important; }

.has-charcoal-grey-4-border-right {
  border-right: solid 0.4rem #37474f !important; }

.has-charcoal-grey-4-border-top {
  border-top: solid 0.4rem #37474f !important; }

.has-charcoal-grey-4-border-bottom {
  border-bottom: solid 0.4rem #37474f !important; }

.has-charcoal-grey-5-border {
  border: solid 0.5rem #37474f !important; }

.has-charcoal-grey-5-border-pulsate {
  border: solid 0.5rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-5-border-left {
  border-left: solid 0.5rem #37474f !important; }

.has-charcoal-grey-5-border-right {
  border-right: solid 0.5rem #37474f !important; }

.has-charcoal-grey-5-border-top {
  border-top: solid 0.5rem #37474f !important; }

.has-charcoal-grey-5-border-bottom {
  border-bottom: solid 0.5rem #37474f !important; }

.has-charcoal-grey-6-border {
  border: solid 0.6rem #37474f !important; }

.has-charcoal-grey-6-border-pulsate {
  border: solid 0.6rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-6-border-left {
  border-left: solid 0.6rem #37474f !important; }

.has-charcoal-grey-6-border-right {
  border-right: solid 0.6rem #37474f !important; }

.has-charcoal-grey-6-border-top {
  border-top: solid 0.6rem #37474f !important; }

.has-charcoal-grey-6-border-bottom {
  border-bottom: solid 0.6rem #37474f !important; }

.has-charcoal-grey-7-border {
  border: solid 0.7rem #37474f !important; }

.has-charcoal-grey-7-border-pulsate {
  border: solid 0.7rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-7-border-left {
  border-left: solid 0.7rem #37474f !important; }

.has-charcoal-grey-7-border-right {
  border-right: solid 0.7rem #37474f !important; }

.has-charcoal-grey-7-border-top {
  border-top: solid 0.7rem #37474f !important; }

.has-charcoal-grey-7-border-bottom {
  border-bottom: solid 0.7rem #37474f !important; }

.has-charcoal-grey-8-border {
  border: solid 0.8rem #37474f !important; }

.has-charcoal-grey-8-border-pulsate {
  border: solid 0.8rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-8-border-left {
  border-left: solid 0.8rem #37474f !important; }

.has-charcoal-grey-8-border-right {
  border-right: solid 0.8rem #37474f !important; }

.has-charcoal-grey-8-border-top {
  border-top: solid 0.8rem #37474f !important; }

.has-charcoal-grey-8-border-bottom {
  border-bottom: solid 0.8rem #37474f !important; }

.has-charcoal-grey-9-border {
  border: solid 0.9rem #37474f !important; }

.has-charcoal-grey-9-border-pulsate {
  border: solid 0.9rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-9-border-left {
  border-left: solid 0.9rem #37474f !important; }

.has-charcoal-grey-9-border-right {
  border-right: solid 0.9rem #37474f !important; }

.has-charcoal-grey-9-border-top {
  border-top: solid 0.9rem #37474f !important; }

.has-charcoal-grey-9-border-bottom {
  border-bottom: solid 0.9rem #37474f !important; }

.has-charcoal-grey-10-border {
  border: solid 1rem #37474f !important; }

.has-charcoal-grey-10-border-pulsate {
  border: solid 1rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-10-border-left {
  border-left: solid 1rem #37474f !important; }

.has-charcoal-grey-10-border-right {
  border-right: solid 1rem #37474f !important; }

.has-charcoal-grey-10-border-top {
  border-top: solid 1rem #37474f !important; }

.has-charcoal-grey-10-border-bottom {
  border-bottom: solid 1rem #37474f !important; }

.has-charcoal-grey-11-border {
  border: solid 1.1rem #37474f !important; }

.has-charcoal-grey-11-border-pulsate {
  border: solid 1.1rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-11-border-left {
  border-left: solid 1.1rem #37474f !important; }

.has-charcoal-grey-11-border-right {
  border-right: solid 1.1rem #37474f !important; }

.has-charcoal-grey-11-border-top {
  border-top: solid 1.1rem #37474f !important; }

.has-charcoal-grey-11-border-bottom {
  border-bottom: solid 1.1rem #37474f !important; }

.has-charcoal-grey-12-border {
  border: solid 1.2rem #37474f !important; }

.has-charcoal-grey-12-border-pulsate {
  border: solid 1.2rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-12-border-left {
  border-left: solid 1.2rem #37474f !important; }

.has-charcoal-grey-12-border-right {
  border-right: solid 1.2rem #37474f !important; }

.has-charcoal-grey-12-border-top {
  border-top: solid 1.2rem #37474f !important; }

.has-charcoal-grey-12-border-bottom {
  border-bottom: solid 1.2rem #37474f !important; }

.has-charcoal-grey-13-border {
  border: solid 1.3rem #37474f !important; }

.has-charcoal-grey-13-border-pulsate {
  border: solid 1.3rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-13-border-left {
  border-left: solid 1.3rem #37474f !important; }

.has-charcoal-grey-13-border-right {
  border-right: solid 1.3rem #37474f !important; }

.has-charcoal-grey-13-border-top {
  border-top: solid 1.3rem #37474f !important; }

.has-charcoal-grey-13-border-bottom {
  border-bottom: solid 1.3rem #37474f !important; }

.has-charcoal-grey-14-border {
  border: solid 1.4rem #37474f !important; }

.has-charcoal-grey-14-border-pulsate {
  border: solid 1.4rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-14-border-left {
  border-left: solid 1.4rem #37474f !important; }

.has-charcoal-grey-14-border-right {
  border-right: solid 1.4rem #37474f !important; }

.has-charcoal-grey-14-border-top {
  border-top: solid 1.4rem #37474f !important; }

.has-charcoal-grey-14-border-bottom {
  border-bottom: solid 1.4rem #37474f !important; }

.has-charcoal-grey-15-border {
  border: solid 1.5rem #37474f !important; }

.has-charcoal-grey-15-border-pulsate {
  border: solid 1.5rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-15-border-left {
  border-left: solid 1.5rem #37474f !important; }

.has-charcoal-grey-15-border-right {
  border-right: solid 1.5rem #37474f !important; }

.has-charcoal-grey-15-border-top {
  border-top: solid 1.5rem #37474f !important; }

.has-charcoal-grey-15-border-bottom {
  border-bottom: solid 1.5rem #37474f !important; }

.has-charcoal-grey-16-border {
  border: solid 1.6rem #37474f !important; }

.has-charcoal-grey-16-border-pulsate {
  border: solid 1.6rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-16-border-left {
  border-left: solid 1.6rem #37474f !important; }

.has-charcoal-grey-16-border-right {
  border-right: solid 1.6rem #37474f !important; }

.has-charcoal-grey-16-border-top {
  border-top: solid 1.6rem #37474f !important; }

.has-charcoal-grey-16-border-bottom {
  border-bottom: solid 1.6rem #37474f !important; }

.has-charcoal-grey-17-border {
  border: solid 1.7rem #37474f !important; }

.has-charcoal-grey-17-border-pulsate {
  border: solid 1.7rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-17-border-left {
  border-left: solid 1.7rem #37474f !important; }

.has-charcoal-grey-17-border-right {
  border-right: solid 1.7rem #37474f !important; }

.has-charcoal-grey-17-border-top {
  border-top: solid 1.7rem #37474f !important; }

.has-charcoal-grey-17-border-bottom {
  border-bottom: solid 1.7rem #37474f !important; }

.has-charcoal-grey-18-border {
  border: solid 1.8rem #37474f !important; }

.has-charcoal-grey-18-border-pulsate {
  border: solid 1.8rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-18-border-left {
  border-left: solid 1.8rem #37474f !important; }

.has-charcoal-grey-18-border-right {
  border-right: solid 1.8rem #37474f !important; }

.has-charcoal-grey-18-border-top {
  border-top: solid 1.8rem #37474f !important; }

.has-charcoal-grey-18-border-bottom {
  border-bottom: solid 1.8rem #37474f !important; }

.has-charcoal-grey-19-border {
  border: solid 1.9rem #37474f !important; }

.has-charcoal-grey-19-border-pulsate {
  border: solid 1.9rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-19-border-left {
  border-left: solid 1.9rem #37474f !important; }

.has-charcoal-grey-19-border-right {
  border-right: solid 1.9rem #37474f !important; }

.has-charcoal-grey-19-border-top {
  border-top: solid 1.9rem #37474f !important; }

.has-charcoal-grey-19-border-bottom {
  border-bottom: solid 1.9rem #37474f !important; }

.has-charcoal-grey-20-border {
  border: solid 2rem #37474f !important; }

.has-charcoal-grey-20-border-pulsate {
  border: solid 2rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-20-border-left {
  border-left: solid 2rem #37474f !important; }

.has-charcoal-grey-20-border-right {
  border-right: solid 2rem #37474f !important; }

.has-charcoal-grey-20-border-top {
  border-top: solid 2rem #37474f !important; }

.has-charcoal-grey-20-border-bottom {
  border-bottom: solid 2rem #37474f !important; }

.has-charcoal-grey-21-border {
  border: solid 2.1rem #37474f !important; }

.has-charcoal-grey-21-border-pulsate {
  border: solid 2.1rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-21-border-left {
  border-left: solid 2.1rem #37474f !important; }

.has-charcoal-grey-21-border-right {
  border-right: solid 2.1rem #37474f !important; }

.has-charcoal-grey-21-border-top {
  border-top: solid 2.1rem #37474f !important; }

.has-charcoal-grey-21-border-bottom {
  border-bottom: solid 2.1rem #37474f !important; }

.has-charcoal-grey-24-border {
  border: solid 2.4rem #37474f !important; }

.has-charcoal-grey-24-border-pulsate {
  border: solid 2.4rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-24-border-left {
  border-left: solid 2.4rem #37474f !important; }

.has-charcoal-grey-24-border-right {
  border-right: solid 2.4rem #37474f !important; }

.has-charcoal-grey-24-border-top {
  border-top: solid 2.4rem #37474f !important; }

.has-charcoal-grey-24-border-bottom {
  border-bottom: solid 2.4rem #37474f !important; }

.has-charcoal-grey-30-border {
  border: solid 3rem #37474f !important; }

.has-charcoal-grey-30-border-pulsate {
  border: solid 3rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-30-border-left {
  border-left: solid 3rem #37474f !important; }

.has-charcoal-grey-30-border-right {
  border-right: solid 3rem #37474f !important; }

.has-charcoal-grey-30-border-top {
  border-top: solid 3rem #37474f !important; }

.has-charcoal-grey-30-border-bottom {
  border-bottom: solid 3rem #37474f !important; }

.has-charcoal-grey-40-border {
  border: solid 4rem #37474f !important; }

.has-charcoal-grey-40-border-pulsate {
  border: solid 4rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-40-border-left {
  border-left: solid 4rem #37474f !important; }

.has-charcoal-grey-40-border-right {
  border-right: solid 4rem #37474f !important; }

.has-charcoal-grey-40-border-top {
  border-top: solid 4rem #37474f !important; }

.has-charcoal-grey-40-border-bottom {
  border-bottom: solid 4rem #37474f !important; }

.has-charcoal-grey-50-border {
  border: solid 5rem #37474f !important; }

.has-charcoal-grey-50-border-pulsate {
  border: solid 5rem #37474f !important;
  animation: charcoal-grey-border-pulsate 2s infinite; }

.has-charcoal-grey-50-border-left {
  border-left: solid 5rem #37474f !important; }

.has-charcoal-grey-50-border-right {
  border-right: solid 5rem #37474f !important; }

.has-charcoal-grey-50-border-top {
  border-top: solid 5rem #37474f !important; }

.has-charcoal-grey-50-border-bottom {
  border-bottom: solid 5rem #37474f !important; }

@keyframes pale-grey-border-pulsate {
  0% {
    border-color: #fafbfc; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #fafbfc; } }

.has-pale-grey-border-color {
  border-color: #fafbfc; }

.has-pale-grey-border {
  border: solid 0.1rem #fafbfc; }

.has-pale-grey-border-pulsate {
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-border-left {
  border-left: solid 0.1rem #fafbfc; }

.has-pale-grey-border-right {
  border-right: solid 0.1rem #fafbfc; }

.has-pale-grey-border-top {
  border-top: solid 0.1rem #fafbfc; }

.has-pale-grey-border-bottom {
  border-bottom: solid 0.1rem #fafbfc; }

.has-pale-grey-1-border {
  border: solid 0.1rem #fafbfc !important; }

.has-pale-grey-1-border-pulsate {
  border: solid 0.1rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-1-border-left {
  border-left: solid 0.1rem #fafbfc !important; }

.has-pale-grey-1-border-right {
  border-right: solid 0.1rem #fafbfc !important; }

.has-pale-grey-1-border-top {
  border-top: solid 0.1rem #fafbfc !important; }

.has-pale-grey-1-border-bottom {
  border-bottom: solid 0.1rem #fafbfc !important; }

.has-pale-grey-2-border {
  border: solid 0.2rem #fafbfc !important; }

.has-pale-grey-2-border-pulsate {
  border: solid 0.2rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-2-border-left {
  border-left: solid 0.2rem #fafbfc !important; }

.has-pale-grey-2-border-right {
  border-right: solid 0.2rem #fafbfc !important; }

.has-pale-grey-2-border-top {
  border-top: solid 0.2rem #fafbfc !important; }

.has-pale-grey-2-border-bottom {
  border-bottom: solid 0.2rem #fafbfc !important; }

.has-pale-grey-3-border {
  border: solid 0.3rem #fafbfc !important; }

.has-pale-grey-3-border-pulsate {
  border: solid 0.3rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-3-border-left {
  border-left: solid 0.3rem #fafbfc !important; }

.has-pale-grey-3-border-right {
  border-right: solid 0.3rem #fafbfc !important; }

.has-pale-grey-3-border-top {
  border-top: solid 0.3rem #fafbfc !important; }

.has-pale-grey-3-border-bottom {
  border-bottom: solid 0.3rem #fafbfc !important; }

.has-pale-grey-4-border {
  border: solid 0.4rem #fafbfc !important; }

.has-pale-grey-4-border-pulsate {
  border: solid 0.4rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-4-border-left {
  border-left: solid 0.4rem #fafbfc !important; }

.has-pale-grey-4-border-right {
  border-right: solid 0.4rem #fafbfc !important; }

.has-pale-grey-4-border-top {
  border-top: solid 0.4rem #fafbfc !important; }

.has-pale-grey-4-border-bottom {
  border-bottom: solid 0.4rem #fafbfc !important; }

.has-pale-grey-5-border {
  border: solid 0.5rem #fafbfc !important; }

.has-pale-grey-5-border-pulsate {
  border: solid 0.5rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-5-border-left {
  border-left: solid 0.5rem #fafbfc !important; }

.has-pale-grey-5-border-right {
  border-right: solid 0.5rem #fafbfc !important; }

.has-pale-grey-5-border-top {
  border-top: solid 0.5rem #fafbfc !important; }

.has-pale-grey-5-border-bottom {
  border-bottom: solid 0.5rem #fafbfc !important; }

.has-pale-grey-6-border {
  border: solid 0.6rem #fafbfc !important; }

.has-pale-grey-6-border-pulsate {
  border: solid 0.6rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-6-border-left {
  border-left: solid 0.6rem #fafbfc !important; }

.has-pale-grey-6-border-right {
  border-right: solid 0.6rem #fafbfc !important; }

.has-pale-grey-6-border-top {
  border-top: solid 0.6rem #fafbfc !important; }

.has-pale-grey-6-border-bottom {
  border-bottom: solid 0.6rem #fafbfc !important; }

.has-pale-grey-7-border {
  border: solid 0.7rem #fafbfc !important; }

.has-pale-grey-7-border-pulsate {
  border: solid 0.7rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-7-border-left {
  border-left: solid 0.7rem #fafbfc !important; }

.has-pale-grey-7-border-right {
  border-right: solid 0.7rem #fafbfc !important; }

.has-pale-grey-7-border-top {
  border-top: solid 0.7rem #fafbfc !important; }

.has-pale-grey-7-border-bottom {
  border-bottom: solid 0.7rem #fafbfc !important; }

.has-pale-grey-8-border {
  border: solid 0.8rem #fafbfc !important; }

.has-pale-grey-8-border-pulsate {
  border: solid 0.8rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-8-border-left {
  border-left: solid 0.8rem #fafbfc !important; }

.has-pale-grey-8-border-right {
  border-right: solid 0.8rem #fafbfc !important; }

.has-pale-grey-8-border-top {
  border-top: solid 0.8rem #fafbfc !important; }

.has-pale-grey-8-border-bottom {
  border-bottom: solid 0.8rem #fafbfc !important; }

.has-pale-grey-9-border {
  border: solid 0.9rem #fafbfc !important; }

.has-pale-grey-9-border-pulsate {
  border: solid 0.9rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-9-border-left {
  border-left: solid 0.9rem #fafbfc !important; }

.has-pale-grey-9-border-right {
  border-right: solid 0.9rem #fafbfc !important; }

.has-pale-grey-9-border-top {
  border-top: solid 0.9rem #fafbfc !important; }

.has-pale-grey-9-border-bottom {
  border-bottom: solid 0.9rem #fafbfc !important; }

.has-pale-grey-10-border {
  border: solid 1rem #fafbfc !important; }

.has-pale-grey-10-border-pulsate {
  border: solid 1rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-10-border-left {
  border-left: solid 1rem #fafbfc !important; }

.has-pale-grey-10-border-right {
  border-right: solid 1rem #fafbfc !important; }

.has-pale-grey-10-border-top {
  border-top: solid 1rem #fafbfc !important; }

.has-pale-grey-10-border-bottom {
  border-bottom: solid 1rem #fafbfc !important; }

.has-pale-grey-11-border {
  border: solid 1.1rem #fafbfc !important; }

.has-pale-grey-11-border-pulsate {
  border: solid 1.1rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-11-border-left {
  border-left: solid 1.1rem #fafbfc !important; }

.has-pale-grey-11-border-right {
  border-right: solid 1.1rem #fafbfc !important; }

.has-pale-grey-11-border-top {
  border-top: solid 1.1rem #fafbfc !important; }

.has-pale-grey-11-border-bottom {
  border-bottom: solid 1.1rem #fafbfc !important; }

.has-pale-grey-12-border {
  border: solid 1.2rem #fafbfc !important; }

.has-pale-grey-12-border-pulsate {
  border: solid 1.2rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-12-border-left {
  border-left: solid 1.2rem #fafbfc !important; }

.has-pale-grey-12-border-right {
  border-right: solid 1.2rem #fafbfc !important; }

.has-pale-grey-12-border-top {
  border-top: solid 1.2rem #fafbfc !important; }

.has-pale-grey-12-border-bottom {
  border-bottom: solid 1.2rem #fafbfc !important; }

.has-pale-grey-13-border {
  border: solid 1.3rem #fafbfc !important; }

.has-pale-grey-13-border-pulsate {
  border: solid 1.3rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-13-border-left {
  border-left: solid 1.3rem #fafbfc !important; }

.has-pale-grey-13-border-right {
  border-right: solid 1.3rem #fafbfc !important; }

.has-pale-grey-13-border-top {
  border-top: solid 1.3rem #fafbfc !important; }

.has-pale-grey-13-border-bottom {
  border-bottom: solid 1.3rem #fafbfc !important; }

.has-pale-grey-14-border {
  border: solid 1.4rem #fafbfc !important; }

.has-pale-grey-14-border-pulsate {
  border: solid 1.4rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-14-border-left {
  border-left: solid 1.4rem #fafbfc !important; }

.has-pale-grey-14-border-right {
  border-right: solid 1.4rem #fafbfc !important; }

.has-pale-grey-14-border-top {
  border-top: solid 1.4rem #fafbfc !important; }

.has-pale-grey-14-border-bottom {
  border-bottom: solid 1.4rem #fafbfc !important; }

.has-pale-grey-15-border {
  border: solid 1.5rem #fafbfc !important; }

.has-pale-grey-15-border-pulsate {
  border: solid 1.5rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-15-border-left {
  border-left: solid 1.5rem #fafbfc !important; }

.has-pale-grey-15-border-right {
  border-right: solid 1.5rem #fafbfc !important; }

.has-pale-grey-15-border-top {
  border-top: solid 1.5rem #fafbfc !important; }

.has-pale-grey-15-border-bottom {
  border-bottom: solid 1.5rem #fafbfc !important; }

.has-pale-grey-16-border {
  border: solid 1.6rem #fafbfc !important; }

.has-pale-grey-16-border-pulsate {
  border: solid 1.6rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-16-border-left {
  border-left: solid 1.6rem #fafbfc !important; }

.has-pale-grey-16-border-right {
  border-right: solid 1.6rem #fafbfc !important; }

.has-pale-grey-16-border-top {
  border-top: solid 1.6rem #fafbfc !important; }

.has-pale-grey-16-border-bottom {
  border-bottom: solid 1.6rem #fafbfc !important; }

.has-pale-grey-17-border {
  border: solid 1.7rem #fafbfc !important; }

.has-pale-grey-17-border-pulsate {
  border: solid 1.7rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-17-border-left {
  border-left: solid 1.7rem #fafbfc !important; }

.has-pale-grey-17-border-right {
  border-right: solid 1.7rem #fafbfc !important; }

.has-pale-grey-17-border-top {
  border-top: solid 1.7rem #fafbfc !important; }

.has-pale-grey-17-border-bottom {
  border-bottom: solid 1.7rem #fafbfc !important; }

.has-pale-grey-18-border {
  border: solid 1.8rem #fafbfc !important; }

.has-pale-grey-18-border-pulsate {
  border: solid 1.8rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-18-border-left {
  border-left: solid 1.8rem #fafbfc !important; }

.has-pale-grey-18-border-right {
  border-right: solid 1.8rem #fafbfc !important; }

.has-pale-grey-18-border-top {
  border-top: solid 1.8rem #fafbfc !important; }

.has-pale-grey-18-border-bottom {
  border-bottom: solid 1.8rem #fafbfc !important; }

.has-pale-grey-19-border {
  border: solid 1.9rem #fafbfc !important; }

.has-pale-grey-19-border-pulsate {
  border: solid 1.9rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-19-border-left {
  border-left: solid 1.9rem #fafbfc !important; }

.has-pale-grey-19-border-right {
  border-right: solid 1.9rem #fafbfc !important; }

.has-pale-grey-19-border-top {
  border-top: solid 1.9rem #fafbfc !important; }

.has-pale-grey-19-border-bottom {
  border-bottom: solid 1.9rem #fafbfc !important; }

.has-pale-grey-20-border {
  border: solid 2rem #fafbfc !important; }

.has-pale-grey-20-border-pulsate {
  border: solid 2rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-20-border-left {
  border-left: solid 2rem #fafbfc !important; }

.has-pale-grey-20-border-right {
  border-right: solid 2rem #fafbfc !important; }

.has-pale-grey-20-border-top {
  border-top: solid 2rem #fafbfc !important; }

.has-pale-grey-20-border-bottom {
  border-bottom: solid 2rem #fafbfc !important; }

.has-pale-grey-21-border {
  border: solid 2.1rem #fafbfc !important; }

.has-pale-grey-21-border-pulsate {
  border: solid 2.1rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-21-border-left {
  border-left: solid 2.1rem #fafbfc !important; }

.has-pale-grey-21-border-right {
  border-right: solid 2.1rem #fafbfc !important; }

.has-pale-grey-21-border-top {
  border-top: solid 2.1rem #fafbfc !important; }

.has-pale-grey-21-border-bottom {
  border-bottom: solid 2.1rem #fafbfc !important; }

.has-pale-grey-24-border {
  border: solid 2.4rem #fafbfc !important; }

.has-pale-grey-24-border-pulsate {
  border: solid 2.4rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-24-border-left {
  border-left: solid 2.4rem #fafbfc !important; }

.has-pale-grey-24-border-right {
  border-right: solid 2.4rem #fafbfc !important; }

.has-pale-grey-24-border-top {
  border-top: solid 2.4rem #fafbfc !important; }

.has-pale-grey-24-border-bottom {
  border-bottom: solid 2.4rem #fafbfc !important; }

.has-pale-grey-30-border {
  border: solid 3rem #fafbfc !important; }

.has-pale-grey-30-border-pulsate {
  border: solid 3rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-30-border-left {
  border-left: solid 3rem #fafbfc !important; }

.has-pale-grey-30-border-right {
  border-right: solid 3rem #fafbfc !important; }

.has-pale-grey-30-border-top {
  border-top: solid 3rem #fafbfc !important; }

.has-pale-grey-30-border-bottom {
  border-bottom: solid 3rem #fafbfc !important; }

.has-pale-grey-40-border {
  border: solid 4rem #fafbfc !important; }

.has-pale-grey-40-border-pulsate {
  border: solid 4rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-40-border-left {
  border-left: solid 4rem #fafbfc !important; }

.has-pale-grey-40-border-right {
  border-right: solid 4rem #fafbfc !important; }

.has-pale-grey-40-border-top {
  border-top: solid 4rem #fafbfc !important; }

.has-pale-grey-40-border-bottom {
  border-bottom: solid 4rem #fafbfc !important; }

.has-pale-grey-50-border {
  border: solid 5rem #fafbfc !important; }

.has-pale-grey-50-border-pulsate {
  border: solid 5rem #fafbfc !important;
  animation: pale-grey-border-pulsate 2s infinite; }

.has-pale-grey-50-border-left {
  border-left: solid 5rem #fafbfc !important; }

.has-pale-grey-50-border-right {
  border-right: solid 5rem #fafbfc !important; }

.has-pale-grey-50-border-top {
  border-top: solid 5rem #fafbfc !important; }

.has-pale-grey-50-border-bottom {
  border-bottom: solid 5rem #fafbfc !important; }

@keyframes greyblue-border-pulsate {
  0% {
    border-color: #8dabc4; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #8dabc4; } }

.has-greyblue-border-color {
  border-color: #8dabc4; }

.has-greyblue-border {
  border: solid 0.1rem #8dabc4; }

.has-greyblue-border-pulsate {
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-border-left {
  border-left: solid 0.1rem #8dabc4; }

.has-greyblue-border-right {
  border-right: solid 0.1rem #8dabc4; }

.has-greyblue-border-top {
  border-top: solid 0.1rem #8dabc4; }

.has-greyblue-border-bottom {
  border-bottom: solid 0.1rem #8dabc4; }

.has-greyblue-1-border {
  border: solid 0.1rem #8dabc4 !important; }

.has-greyblue-1-border-pulsate {
  border: solid 0.1rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-1-border-left {
  border-left: solid 0.1rem #8dabc4 !important; }

.has-greyblue-1-border-right {
  border-right: solid 0.1rem #8dabc4 !important; }

.has-greyblue-1-border-top {
  border-top: solid 0.1rem #8dabc4 !important; }

.has-greyblue-1-border-bottom {
  border-bottom: solid 0.1rem #8dabc4 !important; }

.has-greyblue-2-border {
  border: solid 0.2rem #8dabc4 !important; }

.has-greyblue-2-border-pulsate {
  border: solid 0.2rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-2-border-left {
  border-left: solid 0.2rem #8dabc4 !important; }

.has-greyblue-2-border-right {
  border-right: solid 0.2rem #8dabc4 !important; }

.has-greyblue-2-border-top {
  border-top: solid 0.2rem #8dabc4 !important; }

.has-greyblue-2-border-bottom {
  border-bottom: solid 0.2rem #8dabc4 !important; }

.has-greyblue-3-border {
  border: solid 0.3rem #8dabc4 !important; }

.has-greyblue-3-border-pulsate {
  border: solid 0.3rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-3-border-left {
  border-left: solid 0.3rem #8dabc4 !important; }

.has-greyblue-3-border-right {
  border-right: solid 0.3rem #8dabc4 !important; }

.has-greyblue-3-border-top {
  border-top: solid 0.3rem #8dabc4 !important; }

.has-greyblue-3-border-bottom {
  border-bottom: solid 0.3rem #8dabc4 !important; }

.has-greyblue-4-border {
  border: solid 0.4rem #8dabc4 !important; }

.has-greyblue-4-border-pulsate {
  border: solid 0.4rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-4-border-left {
  border-left: solid 0.4rem #8dabc4 !important; }

.has-greyblue-4-border-right {
  border-right: solid 0.4rem #8dabc4 !important; }

.has-greyblue-4-border-top {
  border-top: solid 0.4rem #8dabc4 !important; }

.has-greyblue-4-border-bottom {
  border-bottom: solid 0.4rem #8dabc4 !important; }

.has-greyblue-5-border {
  border: solid 0.5rem #8dabc4 !important; }

.has-greyblue-5-border-pulsate {
  border: solid 0.5rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-5-border-left {
  border-left: solid 0.5rem #8dabc4 !important; }

.has-greyblue-5-border-right {
  border-right: solid 0.5rem #8dabc4 !important; }

.has-greyblue-5-border-top {
  border-top: solid 0.5rem #8dabc4 !important; }

.has-greyblue-5-border-bottom {
  border-bottom: solid 0.5rem #8dabc4 !important; }

.has-greyblue-6-border {
  border: solid 0.6rem #8dabc4 !important; }

.has-greyblue-6-border-pulsate {
  border: solid 0.6rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-6-border-left {
  border-left: solid 0.6rem #8dabc4 !important; }

.has-greyblue-6-border-right {
  border-right: solid 0.6rem #8dabc4 !important; }

.has-greyblue-6-border-top {
  border-top: solid 0.6rem #8dabc4 !important; }

.has-greyblue-6-border-bottom {
  border-bottom: solid 0.6rem #8dabc4 !important; }

.has-greyblue-7-border {
  border: solid 0.7rem #8dabc4 !important; }

.has-greyblue-7-border-pulsate {
  border: solid 0.7rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-7-border-left {
  border-left: solid 0.7rem #8dabc4 !important; }

.has-greyblue-7-border-right {
  border-right: solid 0.7rem #8dabc4 !important; }

.has-greyblue-7-border-top {
  border-top: solid 0.7rem #8dabc4 !important; }

.has-greyblue-7-border-bottom {
  border-bottom: solid 0.7rem #8dabc4 !important; }

.has-greyblue-8-border {
  border: solid 0.8rem #8dabc4 !important; }

.has-greyblue-8-border-pulsate {
  border: solid 0.8rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-8-border-left {
  border-left: solid 0.8rem #8dabc4 !important; }

.has-greyblue-8-border-right {
  border-right: solid 0.8rem #8dabc4 !important; }

.has-greyblue-8-border-top {
  border-top: solid 0.8rem #8dabc4 !important; }

.has-greyblue-8-border-bottom {
  border-bottom: solid 0.8rem #8dabc4 !important; }

.has-greyblue-9-border {
  border: solid 0.9rem #8dabc4 !important; }

.has-greyblue-9-border-pulsate {
  border: solid 0.9rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-9-border-left {
  border-left: solid 0.9rem #8dabc4 !important; }

.has-greyblue-9-border-right {
  border-right: solid 0.9rem #8dabc4 !important; }

.has-greyblue-9-border-top {
  border-top: solid 0.9rem #8dabc4 !important; }

.has-greyblue-9-border-bottom {
  border-bottom: solid 0.9rem #8dabc4 !important; }

.has-greyblue-10-border {
  border: solid 1rem #8dabc4 !important; }

.has-greyblue-10-border-pulsate {
  border: solid 1rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-10-border-left {
  border-left: solid 1rem #8dabc4 !important; }

.has-greyblue-10-border-right {
  border-right: solid 1rem #8dabc4 !important; }

.has-greyblue-10-border-top {
  border-top: solid 1rem #8dabc4 !important; }

.has-greyblue-10-border-bottom {
  border-bottom: solid 1rem #8dabc4 !important; }

.has-greyblue-11-border {
  border: solid 1.1rem #8dabc4 !important; }

.has-greyblue-11-border-pulsate {
  border: solid 1.1rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-11-border-left {
  border-left: solid 1.1rem #8dabc4 !important; }

.has-greyblue-11-border-right {
  border-right: solid 1.1rem #8dabc4 !important; }

.has-greyblue-11-border-top {
  border-top: solid 1.1rem #8dabc4 !important; }

.has-greyblue-11-border-bottom {
  border-bottom: solid 1.1rem #8dabc4 !important; }

.has-greyblue-12-border {
  border: solid 1.2rem #8dabc4 !important; }

.has-greyblue-12-border-pulsate {
  border: solid 1.2rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-12-border-left {
  border-left: solid 1.2rem #8dabc4 !important; }

.has-greyblue-12-border-right {
  border-right: solid 1.2rem #8dabc4 !important; }

.has-greyblue-12-border-top {
  border-top: solid 1.2rem #8dabc4 !important; }

.has-greyblue-12-border-bottom {
  border-bottom: solid 1.2rem #8dabc4 !important; }

.has-greyblue-13-border {
  border: solid 1.3rem #8dabc4 !important; }

.has-greyblue-13-border-pulsate {
  border: solid 1.3rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-13-border-left {
  border-left: solid 1.3rem #8dabc4 !important; }

.has-greyblue-13-border-right {
  border-right: solid 1.3rem #8dabc4 !important; }

.has-greyblue-13-border-top {
  border-top: solid 1.3rem #8dabc4 !important; }

.has-greyblue-13-border-bottom {
  border-bottom: solid 1.3rem #8dabc4 !important; }

.has-greyblue-14-border {
  border: solid 1.4rem #8dabc4 !important; }

.has-greyblue-14-border-pulsate {
  border: solid 1.4rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-14-border-left {
  border-left: solid 1.4rem #8dabc4 !important; }

.has-greyblue-14-border-right {
  border-right: solid 1.4rem #8dabc4 !important; }

.has-greyblue-14-border-top {
  border-top: solid 1.4rem #8dabc4 !important; }

.has-greyblue-14-border-bottom {
  border-bottom: solid 1.4rem #8dabc4 !important; }

.has-greyblue-15-border {
  border: solid 1.5rem #8dabc4 !important; }

.has-greyblue-15-border-pulsate {
  border: solid 1.5rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-15-border-left {
  border-left: solid 1.5rem #8dabc4 !important; }

.has-greyblue-15-border-right {
  border-right: solid 1.5rem #8dabc4 !important; }

.has-greyblue-15-border-top {
  border-top: solid 1.5rem #8dabc4 !important; }

.has-greyblue-15-border-bottom {
  border-bottom: solid 1.5rem #8dabc4 !important; }

.has-greyblue-16-border {
  border: solid 1.6rem #8dabc4 !important; }

.has-greyblue-16-border-pulsate {
  border: solid 1.6rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-16-border-left {
  border-left: solid 1.6rem #8dabc4 !important; }

.has-greyblue-16-border-right {
  border-right: solid 1.6rem #8dabc4 !important; }

.has-greyblue-16-border-top {
  border-top: solid 1.6rem #8dabc4 !important; }

.has-greyblue-16-border-bottom {
  border-bottom: solid 1.6rem #8dabc4 !important; }

.has-greyblue-17-border {
  border: solid 1.7rem #8dabc4 !important; }

.has-greyblue-17-border-pulsate {
  border: solid 1.7rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-17-border-left {
  border-left: solid 1.7rem #8dabc4 !important; }

.has-greyblue-17-border-right {
  border-right: solid 1.7rem #8dabc4 !important; }

.has-greyblue-17-border-top {
  border-top: solid 1.7rem #8dabc4 !important; }

.has-greyblue-17-border-bottom {
  border-bottom: solid 1.7rem #8dabc4 !important; }

.has-greyblue-18-border {
  border: solid 1.8rem #8dabc4 !important; }

.has-greyblue-18-border-pulsate {
  border: solid 1.8rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-18-border-left {
  border-left: solid 1.8rem #8dabc4 !important; }

.has-greyblue-18-border-right {
  border-right: solid 1.8rem #8dabc4 !important; }

.has-greyblue-18-border-top {
  border-top: solid 1.8rem #8dabc4 !important; }

.has-greyblue-18-border-bottom {
  border-bottom: solid 1.8rem #8dabc4 !important; }

.has-greyblue-19-border {
  border: solid 1.9rem #8dabc4 !important; }

.has-greyblue-19-border-pulsate {
  border: solid 1.9rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-19-border-left {
  border-left: solid 1.9rem #8dabc4 !important; }

.has-greyblue-19-border-right {
  border-right: solid 1.9rem #8dabc4 !important; }

.has-greyblue-19-border-top {
  border-top: solid 1.9rem #8dabc4 !important; }

.has-greyblue-19-border-bottom {
  border-bottom: solid 1.9rem #8dabc4 !important; }

.has-greyblue-20-border {
  border: solid 2rem #8dabc4 !important; }

.has-greyblue-20-border-pulsate {
  border: solid 2rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-20-border-left {
  border-left: solid 2rem #8dabc4 !important; }

.has-greyblue-20-border-right {
  border-right: solid 2rem #8dabc4 !important; }

.has-greyblue-20-border-top {
  border-top: solid 2rem #8dabc4 !important; }

.has-greyblue-20-border-bottom {
  border-bottom: solid 2rem #8dabc4 !important; }

.has-greyblue-21-border {
  border: solid 2.1rem #8dabc4 !important; }

.has-greyblue-21-border-pulsate {
  border: solid 2.1rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-21-border-left {
  border-left: solid 2.1rem #8dabc4 !important; }

.has-greyblue-21-border-right {
  border-right: solid 2.1rem #8dabc4 !important; }

.has-greyblue-21-border-top {
  border-top: solid 2.1rem #8dabc4 !important; }

.has-greyblue-21-border-bottom {
  border-bottom: solid 2.1rem #8dabc4 !important; }

.has-greyblue-24-border {
  border: solid 2.4rem #8dabc4 !important; }

.has-greyblue-24-border-pulsate {
  border: solid 2.4rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-24-border-left {
  border-left: solid 2.4rem #8dabc4 !important; }

.has-greyblue-24-border-right {
  border-right: solid 2.4rem #8dabc4 !important; }

.has-greyblue-24-border-top {
  border-top: solid 2.4rem #8dabc4 !important; }

.has-greyblue-24-border-bottom {
  border-bottom: solid 2.4rem #8dabc4 !important; }

.has-greyblue-30-border {
  border: solid 3rem #8dabc4 !important; }

.has-greyblue-30-border-pulsate {
  border: solid 3rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-30-border-left {
  border-left: solid 3rem #8dabc4 !important; }

.has-greyblue-30-border-right {
  border-right: solid 3rem #8dabc4 !important; }

.has-greyblue-30-border-top {
  border-top: solid 3rem #8dabc4 !important; }

.has-greyblue-30-border-bottom {
  border-bottom: solid 3rem #8dabc4 !important; }

.has-greyblue-40-border {
  border: solid 4rem #8dabc4 !important; }

.has-greyblue-40-border-pulsate {
  border: solid 4rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-40-border-left {
  border-left: solid 4rem #8dabc4 !important; }

.has-greyblue-40-border-right {
  border-right: solid 4rem #8dabc4 !important; }

.has-greyblue-40-border-top {
  border-top: solid 4rem #8dabc4 !important; }

.has-greyblue-40-border-bottom {
  border-bottom: solid 4rem #8dabc4 !important; }

.has-greyblue-50-border {
  border: solid 5rem #8dabc4 !important; }

.has-greyblue-50-border-pulsate {
  border: solid 5rem #8dabc4 !important;
  animation: greyblue-border-pulsate 2s infinite; }

.has-greyblue-50-border-left {
  border-left: solid 5rem #8dabc4 !important; }

.has-greyblue-50-border-right {
  border-right: solid 5rem #8dabc4 !important; }

.has-greyblue-50-border-top {
  border-top: solid 5rem #8dabc4 !important; }

.has-greyblue-50-border-bottom {
  border-bottom: solid 5rem #8dabc4 !important; }

@keyframes bluey-grey-border-pulsate {
  0% {
    border-color: #90a4ae; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #90a4ae; } }

.has-bluey-grey-border-color {
  border-color: #90a4ae; }

.has-bluey-grey-border {
  border: solid 0.1rem #90a4ae; }

.has-bluey-grey-border-pulsate {
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-border-left {
  border-left: solid 0.1rem #90a4ae; }

.has-bluey-grey-border-right {
  border-right: solid 0.1rem #90a4ae; }

.has-bluey-grey-border-top {
  border-top: solid 0.1rem #90a4ae; }

.has-bluey-grey-border-bottom {
  border-bottom: solid 0.1rem #90a4ae; }

.has-bluey-grey-1-border {
  border: solid 0.1rem #90a4ae !important; }

.has-bluey-grey-1-border-pulsate {
  border: solid 0.1rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-1-border-left {
  border-left: solid 0.1rem #90a4ae !important; }

.has-bluey-grey-1-border-right {
  border-right: solid 0.1rem #90a4ae !important; }

.has-bluey-grey-1-border-top {
  border-top: solid 0.1rem #90a4ae !important; }

.has-bluey-grey-1-border-bottom {
  border-bottom: solid 0.1rem #90a4ae !important; }

.has-bluey-grey-2-border {
  border: solid 0.2rem #90a4ae !important; }

.has-bluey-grey-2-border-pulsate {
  border: solid 0.2rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-2-border-left {
  border-left: solid 0.2rem #90a4ae !important; }

.has-bluey-grey-2-border-right {
  border-right: solid 0.2rem #90a4ae !important; }

.has-bluey-grey-2-border-top {
  border-top: solid 0.2rem #90a4ae !important; }

.has-bluey-grey-2-border-bottom {
  border-bottom: solid 0.2rem #90a4ae !important; }

.has-bluey-grey-3-border {
  border: solid 0.3rem #90a4ae !important; }

.has-bluey-grey-3-border-pulsate {
  border: solid 0.3rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-3-border-left {
  border-left: solid 0.3rem #90a4ae !important; }

.has-bluey-grey-3-border-right {
  border-right: solid 0.3rem #90a4ae !important; }

.has-bluey-grey-3-border-top {
  border-top: solid 0.3rem #90a4ae !important; }

.has-bluey-grey-3-border-bottom {
  border-bottom: solid 0.3rem #90a4ae !important; }

.has-bluey-grey-4-border {
  border: solid 0.4rem #90a4ae !important; }

.has-bluey-grey-4-border-pulsate {
  border: solid 0.4rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-4-border-left {
  border-left: solid 0.4rem #90a4ae !important; }

.has-bluey-grey-4-border-right {
  border-right: solid 0.4rem #90a4ae !important; }

.has-bluey-grey-4-border-top {
  border-top: solid 0.4rem #90a4ae !important; }

.has-bluey-grey-4-border-bottom {
  border-bottom: solid 0.4rem #90a4ae !important; }

.has-bluey-grey-5-border {
  border: solid 0.5rem #90a4ae !important; }

.has-bluey-grey-5-border-pulsate {
  border: solid 0.5rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-5-border-left {
  border-left: solid 0.5rem #90a4ae !important; }

.has-bluey-grey-5-border-right {
  border-right: solid 0.5rem #90a4ae !important; }

.has-bluey-grey-5-border-top {
  border-top: solid 0.5rem #90a4ae !important; }

.has-bluey-grey-5-border-bottom {
  border-bottom: solid 0.5rem #90a4ae !important; }

.has-bluey-grey-6-border {
  border: solid 0.6rem #90a4ae !important; }

.has-bluey-grey-6-border-pulsate {
  border: solid 0.6rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-6-border-left {
  border-left: solid 0.6rem #90a4ae !important; }

.has-bluey-grey-6-border-right {
  border-right: solid 0.6rem #90a4ae !important; }

.has-bluey-grey-6-border-top {
  border-top: solid 0.6rem #90a4ae !important; }

.has-bluey-grey-6-border-bottom {
  border-bottom: solid 0.6rem #90a4ae !important; }

.has-bluey-grey-7-border {
  border: solid 0.7rem #90a4ae !important; }

.has-bluey-grey-7-border-pulsate {
  border: solid 0.7rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-7-border-left {
  border-left: solid 0.7rem #90a4ae !important; }

.has-bluey-grey-7-border-right {
  border-right: solid 0.7rem #90a4ae !important; }

.has-bluey-grey-7-border-top {
  border-top: solid 0.7rem #90a4ae !important; }

.has-bluey-grey-7-border-bottom {
  border-bottom: solid 0.7rem #90a4ae !important; }

.has-bluey-grey-8-border {
  border: solid 0.8rem #90a4ae !important; }

.has-bluey-grey-8-border-pulsate {
  border: solid 0.8rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-8-border-left {
  border-left: solid 0.8rem #90a4ae !important; }

.has-bluey-grey-8-border-right {
  border-right: solid 0.8rem #90a4ae !important; }

.has-bluey-grey-8-border-top {
  border-top: solid 0.8rem #90a4ae !important; }

.has-bluey-grey-8-border-bottom {
  border-bottom: solid 0.8rem #90a4ae !important; }

.has-bluey-grey-9-border {
  border: solid 0.9rem #90a4ae !important; }

.has-bluey-grey-9-border-pulsate {
  border: solid 0.9rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-9-border-left {
  border-left: solid 0.9rem #90a4ae !important; }

.has-bluey-grey-9-border-right {
  border-right: solid 0.9rem #90a4ae !important; }

.has-bluey-grey-9-border-top {
  border-top: solid 0.9rem #90a4ae !important; }

.has-bluey-grey-9-border-bottom {
  border-bottom: solid 0.9rem #90a4ae !important; }

.has-bluey-grey-10-border {
  border: solid 1rem #90a4ae !important; }

.has-bluey-grey-10-border-pulsate {
  border: solid 1rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-10-border-left {
  border-left: solid 1rem #90a4ae !important; }

.has-bluey-grey-10-border-right {
  border-right: solid 1rem #90a4ae !important; }

.has-bluey-grey-10-border-top {
  border-top: solid 1rem #90a4ae !important; }

.has-bluey-grey-10-border-bottom {
  border-bottom: solid 1rem #90a4ae !important; }

.has-bluey-grey-11-border {
  border: solid 1.1rem #90a4ae !important; }

.has-bluey-grey-11-border-pulsate {
  border: solid 1.1rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-11-border-left {
  border-left: solid 1.1rem #90a4ae !important; }

.has-bluey-grey-11-border-right {
  border-right: solid 1.1rem #90a4ae !important; }

.has-bluey-grey-11-border-top {
  border-top: solid 1.1rem #90a4ae !important; }

.has-bluey-grey-11-border-bottom {
  border-bottom: solid 1.1rem #90a4ae !important; }

.has-bluey-grey-12-border {
  border: solid 1.2rem #90a4ae !important; }

.has-bluey-grey-12-border-pulsate {
  border: solid 1.2rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-12-border-left {
  border-left: solid 1.2rem #90a4ae !important; }

.has-bluey-grey-12-border-right {
  border-right: solid 1.2rem #90a4ae !important; }

.has-bluey-grey-12-border-top {
  border-top: solid 1.2rem #90a4ae !important; }

.has-bluey-grey-12-border-bottom {
  border-bottom: solid 1.2rem #90a4ae !important; }

.has-bluey-grey-13-border {
  border: solid 1.3rem #90a4ae !important; }

.has-bluey-grey-13-border-pulsate {
  border: solid 1.3rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-13-border-left {
  border-left: solid 1.3rem #90a4ae !important; }

.has-bluey-grey-13-border-right {
  border-right: solid 1.3rem #90a4ae !important; }

.has-bluey-grey-13-border-top {
  border-top: solid 1.3rem #90a4ae !important; }

.has-bluey-grey-13-border-bottom {
  border-bottom: solid 1.3rem #90a4ae !important; }

.has-bluey-grey-14-border {
  border: solid 1.4rem #90a4ae !important; }

.has-bluey-grey-14-border-pulsate {
  border: solid 1.4rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-14-border-left {
  border-left: solid 1.4rem #90a4ae !important; }

.has-bluey-grey-14-border-right {
  border-right: solid 1.4rem #90a4ae !important; }

.has-bluey-grey-14-border-top {
  border-top: solid 1.4rem #90a4ae !important; }

.has-bluey-grey-14-border-bottom {
  border-bottom: solid 1.4rem #90a4ae !important; }

.has-bluey-grey-15-border {
  border: solid 1.5rem #90a4ae !important; }

.has-bluey-grey-15-border-pulsate {
  border: solid 1.5rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-15-border-left {
  border-left: solid 1.5rem #90a4ae !important; }

.has-bluey-grey-15-border-right {
  border-right: solid 1.5rem #90a4ae !important; }

.has-bluey-grey-15-border-top {
  border-top: solid 1.5rem #90a4ae !important; }

.has-bluey-grey-15-border-bottom {
  border-bottom: solid 1.5rem #90a4ae !important; }

.has-bluey-grey-16-border {
  border: solid 1.6rem #90a4ae !important; }

.has-bluey-grey-16-border-pulsate {
  border: solid 1.6rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-16-border-left {
  border-left: solid 1.6rem #90a4ae !important; }

.has-bluey-grey-16-border-right {
  border-right: solid 1.6rem #90a4ae !important; }

.has-bluey-grey-16-border-top {
  border-top: solid 1.6rem #90a4ae !important; }

.has-bluey-grey-16-border-bottom {
  border-bottom: solid 1.6rem #90a4ae !important; }

.has-bluey-grey-17-border {
  border: solid 1.7rem #90a4ae !important; }

.has-bluey-grey-17-border-pulsate {
  border: solid 1.7rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-17-border-left {
  border-left: solid 1.7rem #90a4ae !important; }

.has-bluey-grey-17-border-right {
  border-right: solid 1.7rem #90a4ae !important; }

.has-bluey-grey-17-border-top {
  border-top: solid 1.7rem #90a4ae !important; }

.has-bluey-grey-17-border-bottom {
  border-bottom: solid 1.7rem #90a4ae !important; }

.has-bluey-grey-18-border {
  border: solid 1.8rem #90a4ae !important; }

.has-bluey-grey-18-border-pulsate {
  border: solid 1.8rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-18-border-left {
  border-left: solid 1.8rem #90a4ae !important; }

.has-bluey-grey-18-border-right {
  border-right: solid 1.8rem #90a4ae !important; }

.has-bluey-grey-18-border-top {
  border-top: solid 1.8rem #90a4ae !important; }

.has-bluey-grey-18-border-bottom {
  border-bottom: solid 1.8rem #90a4ae !important; }

.has-bluey-grey-19-border {
  border: solid 1.9rem #90a4ae !important; }

.has-bluey-grey-19-border-pulsate {
  border: solid 1.9rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-19-border-left {
  border-left: solid 1.9rem #90a4ae !important; }

.has-bluey-grey-19-border-right {
  border-right: solid 1.9rem #90a4ae !important; }

.has-bluey-grey-19-border-top {
  border-top: solid 1.9rem #90a4ae !important; }

.has-bluey-grey-19-border-bottom {
  border-bottom: solid 1.9rem #90a4ae !important; }

.has-bluey-grey-20-border {
  border: solid 2rem #90a4ae !important; }

.has-bluey-grey-20-border-pulsate {
  border: solid 2rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-20-border-left {
  border-left: solid 2rem #90a4ae !important; }

.has-bluey-grey-20-border-right {
  border-right: solid 2rem #90a4ae !important; }

.has-bluey-grey-20-border-top {
  border-top: solid 2rem #90a4ae !important; }

.has-bluey-grey-20-border-bottom {
  border-bottom: solid 2rem #90a4ae !important; }

.has-bluey-grey-21-border {
  border: solid 2.1rem #90a4ae !important; }

.has-bluey-grey-21-border-pulsate {
  border: solid 2.1rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-21-border-left {
  border-left: solid 2.1rem #90a4ae !important; }

.has-bluey-grey-21-border-right {
  border-right: solid 2.1rem #90a4ae !important; }

.has-bluey-grey-21-border-top {
  border-top: solid 2.1rem #90a4ae !important; }

.has-bluey-grey-21-border-bottom {
  border-bottom: solid 2.1rem #90a4ae !important; }

.has-bluey-grey-24-border {
  border: solid 2.4rem #90a4ae !important; }

.has-bluey-grey-24-border-pulsate {
  border: solid 2.4rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-24-border-left {
  border-left: solid 2.4rem #90a4ae !important; }

.has-bluey-grey-24-border-right {
  border-right: solid 2.4rem #90a4ae !important; }

.has-bluey-grey-24-border-top {
  border-top: solid 2.4rem #90a4ae !important; }

.has-bluey-grey-24-border-bottom {
  border-bottom: solid 2.4rem #90a4ae !important; }

.has-bluey-grey-30-border {
  border: solid 3rem #90a4ae !important; }

.has-bluey-grey-30-border-pulsate {
  border: solid 3rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-30-border-left {
  border-left: solid 3rem #90a4ae !important; }

.has-bluey-grey-30-border-right {
  border-right: solid 3rem #90a4ae !important; }

.has-bluey-grey-30-border-top {
  border-top: solid 3rem #90a4ae !important; }

.has-bluey-grey-30-border-bottom {
  border-bottom: solid 3rem #90a4ae !important; }

.has-bluey-grey-40-border {
  border: solid 4rem #90a4ae !important; }

.has-bluey-grey-40-border-pulsate {
  border: solid 4rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-40-border-left {
  border-left: solid 4rem #90a4ae !important; }

.has-bluey-grey-40-border-right {
  border-right: solid 4rem #90a4ae !important; }

.has-bluey-grey-40-border-top {
  border-top: solid 4rem #90a4ae !important; }

.has-bluey-grey-40-border-bottom {
  border-bottom: solid 4rem #90a4ae !important; }

.has-bluey-grey-50-border {
  border: solid 5rem #90a4ae !important; }

.has-bluey-grey-50-border-pulsate {
  border: solid 5rem #90a4ae !important;
  animation: bluey-grey-border-pulsate 2s infinite; }

.has-bluey-grey-50-border-left {
  border-left: solid 5rem #90a4ae !important; }

.has-bluey-grey-50-border-right {
  border-right: solid 5rem #90a4ae !important; }

.has-bluey-grey-50-border-top {
  border-top: solid 5rem #90a4ae !important; }

.has-bluey-grey-50-border-bottom {
  border-bottom: solid 5rem #90a4ae !important; }

@keyframes pale-grey-two-border-pulsate {
  0% {
    border-color: #f2f6f9; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #f2f6f9; } }

.has-pale-grey-two-border-color {
  border-color: #f2f6f9; }

.has-pale-grey-two-border {
  border: solid 0.1rem #f2f6f9; }

.has-pale-grey-two-border-pulsate {
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-border-left {
  border-left: solid 0.1rem #f2f6f9; }

.has-pale-grey-two-border-right {
  border-right: solid 0.1rem #f2f6f9; }

.has-pale-grey-two-border-top {
  border-top: solid 0.1rem #f2f6f9; }

.has-pale-grey-two-border-bottom {
  border-bottom: solid 0.1rem #f2f6f9; }

.has-pale-grey-two-1-border {
  border: solid 0.1rem #f2f6f9 !important; }

.has-pale-grey-two-1-border-pulsate {
  border: solid 0.1rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-1-border-left {
  border-left: solid 0.1rem #f2f6f9 !important; }

.has-pale-grey-two-1-border-right {
  border-right: solid 0.1rem #f2f6f9 !important; }

.has-pale-grey-two-1-border-top {
  border-top: solid 0.1rem #f2f6f9 !important; }

.has-pale-grey-two-1-border-bottom {
  border-bottom: solid 0.1rem #f2f6f9 !important; }

.has-pale-grey-two-2-border {
  border: solid 0.2rem #f2f6f9 !important; }

.has-pale-grey-two-2-border-pulsate {
  border: solid 0.2rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-2-border-left {
  border-left: solid 0.2rem #f2f6f9 !important; }

.has-pale-grey-two-2-border-right {
  border-right: solid 0.2rem #f2f6f9 !important; }

.has-pale-grey-two-2-border-top {
  border-top: solid 0.2rem #f2f6f9 !important; }

.has-pale-grey-two-2-border-bottom {
  border-bottom: solid 0.2rem #f2f6f9 !important; }

.has-pale-grey-two-3-border {
  border: solid 0.3rem #f2f6f9 !important; }

.has-pale-grey-two-3-border-pulsate {
  border: solid 0.3rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-3-border-left {
  border-left: solid 0.3rem #f2f6f9 !important; }

.has-pale-grey-two-3-border-right {
  border-right: solid 0.3rem #f2f6f9 !important; }

.has-pale-grey-two-3-border-top {
  border-top: solid 0.3rem #f2f6f9 !important; }

.has-pale-grey-two-3-border-bottom {
  border-bottom: solid 0.3rem #f2f6f9 !important; }

.has-pale-grey-two-4-border {
  border: solid 0.4rem #f2f6f9 !important; }

.has-pale-grey-two-4-border-pulsate {
  border: solid 0.4rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-4-border-left {
  border-left: solid 0.4rem #f2f6f9 !important; }

.has-pale-grey-two-4-border-right {
  border-right: solid 0.4rem #f2f6f9 !important; }

.has-pale-grey-two-4-border-top {
  border-top: solid 0.4rem #f2f6f9 !important; }

.has-pale-grey-two-4-border-bottom {
  border-bottom: solid 0.4rem #f2f6f9 !important; }

.has-pale-grey-two-5-border {
  border: solid 0.5rem #f2f6f9 !important; }

.has-pale-grey-two-5-border-pulsate {
  border: solid 0.5rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-5-border-left {
  border-left: solid 0.5rem #f2f6f9 !important; }

.has-pale-grey-two-5-border-right {
  border-right: solid 0.5rem #f2f6f9 !important; }

.has-pale-grey-two-5-border-top {
  border-top: solid 0.5rem #f2f6f9 !important; }

.has-pale-grey-two-5-border-bottom {
  border-bottom: solid 0.5rem #f2f6f9 !important; }

.has-pale-grey-two-6-border {
  border: solid 0.6rem #f2f6f9 !important; }

.has-pale-grey-two-6-border-pulsate {
  border: solid 0.6rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-6-border-left {
  border-left: solid 0.6rem #f2f6f9 !important; }

.has-pale-grey-two-6-border-right {
  border-right: solid 0.6rem #f2f6f9 !important; }

.has-pale-grey-two-6-border-top {
  border-top: solid 0.6rem #f2f6f9 !important; }

.has-pale-grey-two-6-border-bottom {
  border-bottom: solid 0.6rem #f2f6f9 !important; }

.has-pale-grey-two-7-border {
  border: solid 0.7rem #f2f6f9 !important; }

.has-pale-grey-two-7-border-pulsate {
  border: solid 0.7rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-7-border-left {
  border-left: solid 0.7rem #f2f6f9 !important; }

.has-pale-grey-two-7-border-right {
  border-right: solid 0.7rem #f2f6f9 !important; }

.has-pale-grey-two-7-border-top {
  border-top: solid 0.7rem #f2f6f9 !important; }

.has-pale-grey-two-7-border-bottom {
  border-bottom: solid 0.7rem #f2f6f9 !important; }

.has-pale-grey-two-8-border {
  border: solid 0.8rem #f2f6f9 !important; }

.has-pale-grey-two-8-border-pulsate {
  border: solid 0.8rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-8-border-left {
  border-left: solid 0.8rem #f2f6f9 !important; }

.has-pale-grey-two-8-border-right {
  border-right: solid 0.8rem #f2f6f9 !important; }

.has-pale-grey-two-8-border-top {
  border-top: solid 0.8rem #f2f6f9 !important; }

.has-pale-grey-two-8-border-bottom {
  border-bottom: solid 0.8rem #f2f6f9 !important; }

.has-pale-grey-two-9-border {
  border: solid 0.9rem #f2f6f9 !important; }

.has-pale-grey-two-9-border-pulsate {
  border: solid 0.9rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-9-border-left {
  border-left: solid 0.9rem #f2f6f9 !important; }

.has-pale-grey-two-9-border-right {
  border-right: solid 0.9rem #f2f6f9 !important; }

.has-pale-grey-two-9-border-top {
  border-top: solid 0.9rem #f2f6f9 !important; }

.has-pale-grey-two-9-border-bottom {
  border-bottom: solid 0.9rem #f2f6f9 !important; }

.has-pale-grey-two-10-border {
  border: solid 1rem #f2f6f9 !important; }

.has-pale-grey-two-10-border-pulsate {
  border: solid 1rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-10-border-left {
  border-left: solid 1rem #f2f6f9 !important; }

.has-pale-grey-two-10-border-right {
  border-right: solid 1rem #f2f6f9 !important; }

.has-pale-grey-two-10-border-top {
  border-top: solid 1rem #f2f6f9 !important; }

.has-pale-grey-two-10-border-bottom {
  border-bottom: solid 1rem #f2f6f9 !important; }

.has-pale-grey-two-11-border {
  border: solid 1.1rem #f2f6f9 !important; }

.has-pale-grey-two-11-border-pulsate {
  border: solid 1.1rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-11-border-left {
  border-left: solid 1.1rem #f2f6f9 !important; }

.has-pale-grey-two-11-border-right {
  border-right: solid 1.1rem #f2f6f9 !important; }

.has-pale-grey-two-11-border-top {
  border-top: solid 1.1rem #f2f6f9 !important; }

.has-pale-grey-two-11-border-bottom {
  border-bottom: solid 1.1rem #f2f6f9 !important; }

.has-pale-grey-two-12-border {
  border: solid 1.2rem #f2f6f9 !important; }

.has-pale-grey-two-12-border-pulsate {
  border: solid 1.2rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-12-border-left {
  border-left: solid 1.2rem #f2f6f9 !important; }

.has-pale-grey-two-12-border-right {
  border-right: solid 1.2rem #f2f6f9 !important; }

.has-pale-grey-two-12-border-top {
  border-top: solid 1.2rem #f2f6f9 !important; }

.has-pale-grey-two-12-border-bottom {
  border-bottom: solid 1.2rem #f2f6f9 !important; }

.has-pale-grey-two-13-border {
  border: solid 1.3rem #f2f6f9 !important; }

.has-pale-grey-two-13-border-pulsate {
  border: solid 1.3rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-13-border-left {
  border-left: solid 1.3rem #f2f6f9 !important; }

.has-pale-grey-two-13-border-right {
  border-right: solid 1.3rem #f2f6f9 !important; }

.has-pale-grey-two-13-border-top {
  border-top: solid 1.3rem #f2f6f9 !important; }

.has-pale-grey-two-13-border-bottom {
  border-bottom: solid 1.3rem #f2f6f9 !important; }

.has-pale-grey-two-14-border {
  border: solid 1.4rem #f2f6f9 !important; }

.has-pale-grey-two-14-border-pulsate {
  border: solid 1.4rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-14-border-left {
  border-left: solid 1.4rem #f2f6f9 !important; }

.has-pale-grey-two-14-border-right {
  border-right: solid 1.4rem #f2f6f9 !important; }

.has-pale-grey-two-14-border-top {
  border-top: solid 1.4rem #f2f6f9 !important; }

.has-pale-grey-two-14-border-bottom {
  border-bottom: solid 1.4rem #f2f6f9 !important; }

.has-pale-grey-two-15-border {
  border: solid 1.5rem #f2f6f9 !important; }

.has-pale-grey-two-15-border-pulsate {
  border: solid 1.5rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-15-border-left {
  border-left: solid 1.5rem #f2f6f9 !important; }

.has-pale-grey-two-15-border-right {
  border-right: solid 1.5rem #f2f6f9 !important; }

.has-pale-grey-two-15-border-top {
  border-top: solid 1.5rem #f2f6f9 !important; }

.has-pale-grey-two-15-border-bottom {
  border-bottom: solid 1.5rem #f2f6f9 !important; }

.has-pale-grey-two-16-border {
  border: solid 1.6rem #f2f6f9 !important; }

.has-pale-grey-two-16-border-pulsate {
  border: solid 1.6rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-16-border-left {
  border-left: solid 1.6rem #f2f6f9 !important; }

.has-pale-grey-two-16-border-right {
  border-right: solid 1.6rem #f2f6f9 !important; }

.has-pale-grey-two-16-border-top {
  border-top: solid 1.6rem #f2f6f9 !important; }

.has-pale-grey-two-16-border-bottom {
  border-bottom: solid 1.6rem #f2f6f9 !important; }

.has-pale-grey-two-17-border {
  border: solid 1.7rem #f2f6f9 !important; }

.has-pale-grey-two-17-border-pulsate {
  border: solid 1.7rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-17-border-left {
  border-left: solid 1.7rem #f2f6f9 !important; }

.has-pale-grey-two-17-border-right {
  border-right: solid 1.7rem #f2f6f9 !important; }

.has-pale-grey-two-17-border-top {
  border-top: solid 1.7rem #f2f6f9 !important; }

.has-pale-grey-two-17-border-bottom {
  border-bottom: solid 1.7rem #f2f6f9 !important; }

.has-pale-grey-two-18-border {
  border: solid 1.8rem #f2f6f9 !important; }

.has-pale-grey-two-18-border-pulsate {
  border: solid 1.8rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-18-border-left {
  border-left: solid 1.8rem #f2f6f9 !important; }

.has-pale-grey-two-18-border-right {
  border-right: solid 1.8rem #f2f6f9 !important; }

.has-pale-grey-two-18-border-top {
  border-top: solid 1.8rem #f2f6f9 !important; }

.has-pale-grey-two-18-border-bottom {
  border-bottom: solid 1.8rem #f2f6f9 !important; }

.has-pale-grey-two-19-border {
  border: solid 1.9rem #f2f6f9 !important; }

.has-pale-grey-two-19-border-pulsate {
  border: solid 1.9rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-19-border-left {
  border-left: solid 1.9rem #f2f6f9 !important; }

.has-pale-grey-two-19-border-right {
  border-right: solid 1.9rem #f2f6f9 !important; }

.has-pale-grey-two-19-border-top {
  border-top: solid 1.9rem #f2f6f9 !important; }

.has-pale-grey-two-19-border-bottom {
  border-bottom: solid 1.9rem #f2f6f9 !important; }

.has-pale-grey-two-20-border {
  border: solid 2rem #f2f6f9 !important; }

.has-pale-grey-two-20-border-pulsate {
  border: solid 2rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-20-border-left {
  border-left: solid 2rem #f2f6f9 !important; }

.has-pale-grey-two-20-border-right {
  border-right: solid 2rem #f2f6f9 !important; }

.has-pale-grey-two-20-border-top {
  border-top: solid 2rem #f2f6f9 !important; }

.has-pale-grey-two-20-border-bottom {
  border-bottom: solid 2rem #f2f6f9 !important; }

.has-pale-grey-two-21-border {
  border: solid 2.1rem #f2f6f9 !important; }

.has-pale-grey-two-21-border-pulsate {
  border: solid 2.1rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-21-border-left {
  border-left: solid 2.1rem #f2f6f9 !important; }

.has-pale-grey-two-21-border-right {
  border-right: solid 2.1rem #f2f6f9 !important; }

.has-pale-grey-two-21-border-top {
  border-top: solid 2.1rem #f2f6f9 !important; }

.has-pale-grey-two-21-border-bottom {
  border-bottom: solid 2.1rem #f2f6f9 !important; }

.has-pale-grey-two-24-border {
  border: solid 2.4rem #f2f6f9 !important; }

.has-pale-grey-two-24-border-pulsate {
  border: solid 2.4rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-24-border-left {
  border-left: solid 2.4rem #f2f6f9 !important; }

.has-pale-grey-two-24-border-right {
  border-right: solid 2.4rem #f2f6f9 !important; }

.has-pale-grey-two-24-border-top {
  border-top: solid 2.4rem #f2f6f9 !important; }

.has-pale-grey-two-24-border-bottom {
  border-bottom: solid 2.4rem #f2f6f9 !important; }

.has-pale-grey-two-30-border {
  border: solid 3rem #f2f6f9 !important; }

.has-pale-grey-two-30-border-pulsate {
  border: solid 3rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-30-border-left {
  border-left: solid 3rem #f2f6f9 !important; }

.has-pale-grey-two-30-border-right {
  border-right: solid 3rem #f2f6f9 !important; }

.has-pale-grey-two-30-border-top {
  border-top: solid 3rem #f2f6f9 !important; }

.has-pale-grey-two-30-border-bottom {
  border-bottom: solid 3rem #f2f6f9 !important; }

.has-pale-grey-two-40-border {
  border: solid 4rem #f2f6f9 !important; }

.has-pale-grey-two-40-border-pulsate {
  border: solid 4rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-40-border-left {
  border-left: solid 4rem #f2f6f9 !important; }

.has-pale-grey-two-40-border-right {
  border-right: solid 4rem #f2f6f9 !important; }

.has-pale-grey-two-40-border-top {
  border-top: solid 4rem #f2f6f9 !important; }

.has-pale-grey-two-40-border-bottom {
  border-bottom: solid 4rem #f2f6f9 !important; }

.has-pale-grey-two-50-border {
  border: solid 5rem #f2f6f9 !important; }

.has-pale-grey-two-50-border-pulsate {
  border: solid 5rem #f2f6f9 !important;
  animation: pale-grey-two-border-pulsate 2s infinite; }

.has-pale-grey-two-50-border-left {
  border-left: solid 5rem #f2f6f9 !important; }

.has-pale-grey-two-50-border-right {
  border-right: solid 5rem #f2f6f9 !important; }

.has-pale-grey-two-50-border-top {
  border-top: solid 5rem #f2f6f9 !important; }

.has-pale-grey-two-50-border-bottom {
  border-bottom: solid 5rem #f2f6f9 !important; }

@keyframes pale-grey-three-border-pulsate {
  0% {
    border-color: #e5eef5; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #e5eef5; } }

.has-pale-grey-three-border-color {
  border-color: #e5eef5; }

.has-pale-grey-three-border {
  border: solid 0.1rem #e5eef5; }

.has-pale-grey-three-border-pulsate {
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-border-left {
  border-left: solid 0.1rem #e5eef5; }

.has-pale-grey-three-border-right {
  border-right: solid 0.1rem #e5eef5; }

.has-pale-grey-three-border-top {
  border-top: solid 0.1rem #e5eef5; }

.has-pale-grey-three-border-bottom {
  border-bottom: solid 0.1rem #e5eef5; }

.has-pale-grey-three-1-border {
  border: solid 0.1rem #e5eef5 !important; }

.has-pale-grey-three-1-border-pulsate {
  border: solid 0.1rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-1-border-left {
  border-left: solid 0.1rem #e5eef5 !important; }

.has-pale-grey-three-1-border-right {
  border-right: solid 0.1rem #e5eef5 !important; }

.has-pale-grey-three-1-border-top {
  border-top: solid 0.1rem #e5eef5 !important; }

.has-pale-grey-three-1-border-bottom {
  border-bottom: solid 0.1rem #e5eef5 !important; }

.has-pale-grey-three-2-border {
  border: solid 0.2rem #e5eef5 !important; }

.has-pale-grey-three-2-border-pulsate {
  border: solid 0.2rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-2-border-left {
  border-left: solid 0.2rem #e5eef5 !important; }

.has-pale-grey-three-2-border-right {
  border-right: solid 0.2rem #e5eef5 !important; }

.has-pale-grey-three-2-border-top {
  border-top: solid 0.2rem #e5eef5 !important; }

.has-pale-grey-three-2-border-bottom {
  border-bottom: solid 0.2rem #e5eef5 !important; }

.has-pale-grey-three-3-border {
  border: solid 0.3rem #e5eef5 !important; }

.has-pale-grey-three-3-border-pulsate {
  border: solid 0.3rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-3-border-left {
  border-left: solid 0.3rem #e5eef5 !important; }

.has-pale-grey-three-3-border-right {
  border-right: solid 0.3rem #e5eef5 !important; }

.has-pale-grey-three-3-border-top {
  border-top: solid 0.3rem #e5eef5 !important; }

.has-pale-grey-three-3-border-bottom {
  border-bottom: solid 0.3rem #e5eef5 !important; }

.has-pale-grey-three-4-border {
  border: solid 0.4rem #e5eef5 !important; }

.has-pale-grey-three-4-border-pulsate {
  border: solid 0.4rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-4-border-left {
  border-left: solid 0.4rem #e5eef5 !important; }

.has-pale-grey-three-4-border-right {
  border-right: solid 0.4rem #e5eef5 !important; }

.has-pale-grey-three-4-border-top {
  border-top: solid 0.4rem #e5eef5 !important; }

.has-pale-grey-three-4-border-bottom {
  border-bottom: solid 0.4rem #e5eef5 !important; }

.has-pale-grey-three-5-border {
  border: solid 0.5rem #e5eef5 !important; }

.has-pale-grey-three-5-border-pulsate {
  border: solid 0.5rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-5-border-left {
  border-left: solid 0.5rem #e5eef5 !important; }

.has-pale-grey-three-5-border-right {
  border-right: solid 0.5rem #e5eef5 !important; }

.has-pale-grey-three-5-border-top {
  border-top: solid 0.5rem #e5eef5 !important; }

.has-pale-grey-three-5-border-bottom {
  border-bottom: solid 0.5rem #e5eef5 !important; }

.has-pale-grey-three-6-border {
  border: solid 0.6rem #e5eef5 !important; }

.has-pale-grey-three-6-border-pulsate {
  border: solid 0.6rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-6-border-left {
  border-left: solid 0.6rem #e5eef5 !important; }

.has-pale-grey-three-6-border-right {
  border-right: solid 0.6rem #e5eef5 !important; }

.has-pale-grey-three-6-border-top {
  border-top: solid 0.6rem #e5eef5 !important; }

.has-pale-grey-three-6-border-bottom {
  border-bottom: solid 0.6rem #e5eef5 !important; }

.has-pale-grey-three-7-border {
  border: solid 0.7rem #e5eef5 !important; }

.has-pale-grey-three-7-border-pulsate {
  border: solid 0.7rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-7-border-left {
  border-left: solid 0.7rem #e5eef5 !important; }

.has-pale-grey-three-7-border-right {
  border-right: solid 0.7rem #e5eef5 !important; }

.has-pale-grey-three-7-border-top {
  border-top: solid 0.7rem #e5eef5 !important; }

.has-pale-grey-three-7-border-bottom {
  border-bottom: solid 0.7rem #e5eef5 !important; }

.has-pale-grey-three-8-border {
  border: solid 0.8rem #e5eef5 !important; }

.has-pale-grey-three-8-border-pulsate {
  border: solid 0.8rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-8-border-left {
  border-left: solid 0.8rem #e5eef5 !important; }

.has-pale-grey-three-8-border-right {
  border-right: solid 0.8rem #e5eef5 !important; }

.has-pale-grey-three-8-border-top {
  border-top: solid 0.8rem #e5eef5 !important; }

.has-pale-grey-three-8-border-bottom {
  border-bottom: solid 0.8rem #e5eef5 !important; }

.has-pale-grey-three-9-border {
  border: solid 0.9rem #e5eef5 !important; }

.has-pale-grey-three-9-border-pulsate {
  border: solid 0.9rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-9-border-left {
  border-left: solid 0.9rem #e5eef5 !important; }

.has-pale-grey-three-9-border-right {
  border-right: solid 0.9rem #e5eef5 !important; }

.has-pale-grey-three-9-border-top {
  border-top: solid 0.9rem #e5eef5 !important; }

.has-pale-grey-three-9-border-bottom {
  border-bottom: solid 0.9rem #e5eef5 !important; }

.has-pale-grey-three-10-border {
  border: solid 1rem #e5eef5 !important; }

.has-pale-grey-three-10-border-pulsate {
  border: solid 1rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-10-border-left {
  border-left: solid 1rem #e5eef5 !important; }

.has-pale-grey-three-10-border-right {
  border-right: solid 1rem #e5eef5 !important; }

.has-pale-grey-three-10-border-top {
  border-top: solid 1rem #e5eef5 !important; }

.has-pale-grey-three-10-border-bottom {
  border-bottom: solid 1rem #e5eef5 !important; }

.has-pale-grey-three-11-border {
  border: solid 1.1rem #e5eef5 !important; }

.has-pale-grey-three-11-border-pulsate {
  border: solid 1.1rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-11-border-left {
  border-left: solid 1.1rem #e5eef5 !important; }

.has-pale-grey-three-11-border-right {
  border-right: solid 1.1rem #e5eef5 !important; }

.has-pale-grey-three-11-border-top {
  border-top: solid 1.1rem #e5eef5 !important; }

.has-pale-grey-three-11-border-bottom {
  border-bottom: solid 1.1rem #e5eef5 !important; }

.has-pale-grey-three-12-border {
  border: solid 1.2rem #e5eef5 !important; }

.has-pale-grey-three-12-border-pulsate {
  border: solid 1.2rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-12-border-left {
  border-left: solid 1.2rem #e5eef5 !important; }

.has-pale-grey-three-12-border-right {
  border-right: solid 1.2rem #e5eef5 !important; }

.has-pale-grey-three-12-border-top {
  border-top: solid 1.2rem #e5eef5 !important; }

.has-pale-grey-three-12-border-bottom {
  border-bottom: solid 1.2rem #e5eef5 !important; }

.has-pale-grey-three-13-border {
  border: solid 1.3rem #e5eef5 !important; }

.has-pale-grey-three-13-border-pulsate {
  border: solid 1.3rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-13-border-left {
  border-left: solid 1.3rem #e5eef5 !important; }

.has-pale-grey-three-13-border-right {
  border-right: solid 1.3rem #e5eef5 !important; }

.has-pale-grey-three-13-border-top {
  border-top: solid 1.3rem #e5eef5 !important; }

.has-pale-grey-three-13-border-bottom {
  border-bottom: solid 1.3rem #e5eef5 !important; }

.has-pale-grey-three-14-border {
  border: solid 1.4rem #e5eef5 !important; }

.has-pale-grey-three-14-border-pulsate {
  border: solid 1.4rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-14-border-left {
  border-left: solid 1.4rem #e5eef5 !important; }

.has-pale-grey-three-14-border-right {
  border-right: solid 1.4rem #e5eef5 !important; }

.has-pale-grey-three-14-border-top {
  border-top: solid 1.4rem #e5eef5 !important; }

.has-pale-grey-three-14-border-bottom {
  border-bottom: solid 1.4rem #e5eef5 !important; }

.has-pale-grey-three-15-border {
  border: solid 1.5rem #e5eef5 !important; }

.has-pale-grey-three-15-border-pulsate {
  border: solid 1.5rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-15-border-left {
  border-left: solid 1.5rem #e5eef5 !important; }

.has-pale-grey-three-15-border-right {
  border-right: solid 1.5rem #e5eef5 !important; }

.has-pale-grey-three-15-border-top {
  border-top: solid 1.5rem #e5eef5 !important; }

.has-pale-grey-three-15-border-bottom {
  border-bottom: solid 1.5rem #e5eef5 !important; }

.has-pale-grey-three-16-border {
  border: solid 1.6rem #e5eef5 !important; }

.has-pale-grey-three-16-border-pulsate {
  border: solid 1.6rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-16-border-left {
  border-left: solid 1.6rem #e5eef5 !important; }

.has-pale-grey-three-16-border-right {
  border-right: solid 1.6rem #e5eef5 !important; }

.has-pale-grey-three-16-border-top {
  border-top: solid 1.6rem #e5eef5 !important; }

.has-pale-grey-three-16-border-bottom {
  border-bottom: solid 1.6rem #e5eef5 !important; }

.has-pale-grey-three-17-border {
  border: solid 1.7rem #e5eef5 !important; }

.has-pale-grey-three-17-border-pulsate {
  border: solid 1.7rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-17-border-left {
  border-left: solid 1.7rem #e5eef5 !important; }

.has-pale-grey-three-17-border-right {
  border-right: solid 1.7rem #e5eef5 !important; }

.has-pale-grey-three-17-border-top {
  border-top: solid 1.7rem #e5eef5 !important; }

.has-pale-grey-three-17-border-bottom {
  border-bottom: solid 1.7rem #e5eef5 !important; }

.has-pale-grey-three-18-border {
  border: solid 1.8rem #e5eef5 !important; }

.has-pale-grey-three-18-border-pulsate {
  border: solid 1.8rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-18-border-left {
  border-left: solid 1.8rem #e5eef5 !important; }

.has-pale-grey-three-18-border-right {
  border-right: solid 1.8rem #e5eef5 !important; }

.has-pale-grey-three-18-border-top {
  border-top: solid 1.8rem #e5eef5 !important; }

.has-pale-grey-three-18-border-bottom {
  border-bottom: solid 1.8rem #e5eef5 !important; }

.has-pale-grey-three-19-border {
  border: solid 1.9rem #e5eef5 !important; }

.has-pale-grey-three-19-border-pulsate {
  border: solid 1.9rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-19-border-left {
  border-left: solid 1.9rem #e5eef5 !important; }

.has-pale-grey-three-19-border-right {
  border-right: solid 1.9rem #e5eef5 !important; }

.has-pale-grey-three-19-border-top {
  border-top: solid 1.9rem #e5eef5 !important; }

.has-pale-grey-three-19-border-bottom {
  border-bottom: solid 1.9rem #e5eef5 !important; }

.has-pale-grey-three-20-border {
  border: solid 2rem #e5eef5 !important; }

.has-pale-grey-three-20-border-pulsate {
  border: solid 2rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-20-border-left {
  border-left: solid 2rem #e5eef5 !important; }

.has-pale-grey-three-20-border-right {
  border-right: solid 2rem #e5eef5 !important; }

.has-pale-grey-three-20-border-top {
  border-top: solid 2rem #e5eef5 !important; }

.has-pale-grey-three-20-border-bottom {
  border-bottom: solid 2rem #e5eef5 !important; }

.has-pale-grey-three-21-border {
  border: solid 2.1rem #e5eef5 !important; }

.has-pale-grey-three-21-border-pulsate {
  border: solid 2.1rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-21-border-left {
  border-left: solid 2.1rem #e5eef5 !important; }

.has-pale-grey-three-21-border-right {
  border-right: solid 2.1rem #e5eef5 !important; }

.has-pale-grey-three-21-border-top {
  border-top: solid 2.1rem #e5eef5 !important; }

.has-pale-grey-three-21-border-bottom {
  border-bottom: solid 2.1rem #e5eef5 !important; }

.has-pale-grey-three-24-border {
  border: solid 2.4rem #e5eef5 !important; }

.has-pale-grey-three-24-border-pulsate {
  border: solid 2.4rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-24-border-left {
  border-left: solid 2.4rem #e5eef5 !important; }

.has-pale-grey-three-24-border-right {
  border-right: solid 2.4rem #e5eef5 !important; }

.has-pale-grey-three-24-border-top {
  border-top: solid 2.4rem #e5eef5 !important; }

.has-pale-grey-three-24-border-bottom {
  border-bottom: solid 2.4rem #e5eef5 !important; }

.has-pale-grey-three-30-border {
  border: solid 3rem #e5eef5 !important; }

.has-pale-grey-three-30-border-pulsate {
  border: solid 3rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-30-border-left {
  border-left: solid 3rem #e5eef5 !important; }

.has-pale-grey-three-30-border-right {
  border-right: solid 3rem #e5eef5 !important; }

.has-pale-grey-three-30-border-top {
  border-top: solid 3rem #e5eef5 !important; }

.has-pale-grey-three-30-border-bottom {
  border-bottom: solid 3rem #e5eef5 !important; }

.has-pale-grey-three-40-border {
  border: solid 4rem #e5eef5 !important; }

.has-pale-grey-three-40-border-pulsate {
  border: solid 4rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-40-border-left {
  border-left: solid 4rem #e5eef5 !important; }

.has-pale-grey-three-40-border-right {
  border-right: solid 4rem #e5eef5 !important; }

.has-pale-grey-three-40-border-top {
  border-top: solid 4rem #e5eef5 !important; }

.has-pale-grey-three-40-border-bottom {
  border-bottom: solid 4rem #e5eef5 !important; }

.has-pale-grey-three-50-border {
  border: solid 5rem #e5eef5 !important; }

.has-pale-grey-three-50-border-pulsate {
  border: solid 5rem #e5eef5 !important;
  animation: pale-grey-three-border-pulsate 2s infinite; }

.has-pale-grey-three-50-border-left {
  border-left: solid 5rem #e5eef5 !important; }

.has-pale-grey-three-50-border-right {
  border-right: solid 5rem #e5eef5 !important; }

.has-pale-grey-three-50-border-top {
  border-top: solid 5rem #e5eef5 !important; }

.has-pale-grey-three-50-border-bottom {
  border-bottom: solid 5rem #e5eef5 !important; }

@keyframes light-blue-grey-three-border-pulsate {
  0% {
    border-color: #c5d9e8; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #c5d9e8; } }

.has-light-blue-grey-three-border-color {
  border-color: #c5d9e8; }

.has-light-blue-grey-three-border {
  border: solid 0.1rem #c5d9e8; }

.has-light-blue-grey-three-border-pulsate {
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-border-left {
  border-left: solid 0.1rem #c5d9e8; }

.has-light-blue-grey-three-border-right {
  border-right: solid 0.1rem #c5d9e8; }

.has-light-blue-grey-three-border-top {
  border-top: solid 0.1rem #c5d9e8; }

.has-light-blue-grey-three-border-bottom {
  border-bottom: solid 0.1rem #c5d9e8; }

.has-light-blue-grey-three-1-border {
  border: solid 0.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-1-border-pulsate {
  border: solid 0.1rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-1-border-left {
  border-left: solid 0.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-1-border-right {
  border-right: solid 0.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-1-border-top {
  border-top: solid 0.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-1-border-bottom {
  border-bottom: solid 0.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-2-border {
  border: solid 0.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-2-border-pulsate {
  border: solid 0.2rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-2-border-left {
  border-left: solid 0.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-2-border-right {
  border-right: solid 0.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-2-border-top {
  border-top: solid 0.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-2-border-bottom {
  border-bottom: solid 0.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-3-border {
  border: solid 0.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-3-border-pulsate {
  border: solid 0.3rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-3-border-left {
  border-left: solid 0.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-3-border-right {
  border-right: solid 0.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-3-border-top {
  border-top: solid 0.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-3-border-bottom {
  border-bottom: solid 0.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-4-border {
  border: solid 0.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-4-border-pulsate {
  border: solid 0.4rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-4-border-left {
  border-left: solid 0.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-4-border-right {
  border-right: solid 0.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-4-border-top {
  border-top: solid 0.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-4-border-bottom {
  border-bottom: solid 0.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-5-border {
  border: solid 0.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-5-border-pulsate {
  border: solid 0.5rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-5-border-left {
  border-left: solid 0.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-5-border-right {
  border-right: solid 0.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-5-border-top {
  border-top: solid 0.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-5-border-bottom {
  border-bottom: solid 0.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-6-border {
  border: solid 0.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-6-border-pulsate {
  border: solid 0.6rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-6-border-left {
  border-left: solid 0.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-6-border-right {
  border-right: solid 0.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-6-border-top {
  border-top: solid 0.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-6-border-bottom {
  border-bottom: solid 0.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-7-border {
  border: solid 0.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-7-border-pulsate {
  border: solid 0.7rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-7-border-left {
  border-left: solid 0.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-7-border-right {
  border-right: solid 0.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-7-border-top {
  border-top: solid 0.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-7-border-bottom {
  border-bottom: solid 0.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-8-border {
  border: solid 0.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-8-border-pulsate {
  border: solid 0.8rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-8-border-left {
  border-left: solid 0.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-8-border-right {
  border-right: solid 0.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-8-border-top {
  border-top: solid 0.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-8-border-bottom {
  border-bottom: solid 0.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-9-border {
  border: solid 0.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-9-border-pulsate {
  border: solid 0.9rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-9-border-left {
  border-left: solid 0.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-9-border-right {
  border-right: solid 0.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-9-border-top {
  border-top: solid 0.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-9-border-bottom {
  border-bottom: solid 0.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-10-border {
  border: solid 1rem #c5d9e8 !important; }

.has-light-blue-grey-three-10-border-pulsate {
  border: solid 1rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-10-border-left {
  border-left: solid 1rem #c5d9e8 !important; }

.has-light-blue-grey-three-10-border-right {
  border-right: solid 1rem #c5d9e8 !important; }

.has-light-blue-grey-three-10-border-top {
  border-top: solid 1rem #c5d9e8 !important; }

.has-light-blue-grey-three-10-border-bottom {
  border-bottom: solid 1rem #c5d9e8 !important; }

.has-light-blue-grey-three-11-border {
  border: solid 1.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-11-border-pulsate {
  border: solid 1.1rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-11-border-left {
  border-left: solid 1.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-11-border-right {
  border-right: solid 1.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-11-border-top {
  border-top: solid 1.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-11-border-bottom {
  border-bottom: solid 1.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-12-border {
  border: solid 1.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-12-border-pulsate {
  border: solid 1.2rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-12-border-left {
  border-left: solid 1.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-12-border-right {
  border-right: solid 1.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-12-border-top {
  border-top: solid 1.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-12-border-bottom {
  border-bottom: solid 1.2rem #c5d9e8 !important; }

.has-light-blue-grey-three-13-border {
  border: solid 1.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-13-border-pulsate {
  border: solid 1.3rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-13-border-left {
  border-left: solid 1.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-13-border-right {
  border-right: solid 1.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-13-border-top {
  border-top: solid 1.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-13-border-bottom {
  border-bottom: solid 1.3rem #c5d9e8 !important; }

.has-light-blue-grey-three-14-border {
  border: solid 1.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-14-border-pulsate {
  border: solid 1.4rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-14-border-left {
  border-left: solid 1.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-14-border-right {
  border-right: solid 1.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-14-border-top {
  border-top: solid 1.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-14-border-bottom {
  border-bottom: solid 1.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-15-border {
  border: solid 1.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-15-border-pulsate {
  border: solid 1.5rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-15-border-left {
  border-left: solid 1.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-15-border-right {
  border-right: solid 1.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-15-border-top {
  border-top: solid 1.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-15-border-bottom {
  border-bottom: solid 1.5rem #c5d9e8 !important; }

.has-light-blue-grey-three-16-border {
  border: solid 1.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-16-border-pulsate {
  border: solid 1.6rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-16-border-left {
  border-left: solid 1.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-16-border-right {
  border-right: solid 1.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-16-border-top {
  border-top: solid 1.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-16-border-bottom {
  border-bottom: solid 1.6rem #c5d9e8 !important; }

.has-light-blue-grey-three-17-border {
  border: solid 1.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-17-border-pulsate {
  border: solid 1.7rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-17-border-left {
  border-left: solid 1.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-17-border-right {
  border-right: solid 1.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-17-border-top {
  border-top: solid 1.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-17-border-bottom {
  border-bottom: solid 1.7rem #c5d9e8 !important; }

.has-light-blue-grey-three-18-border {
  border: solid 1.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-18-border-pulsate {
  border: solid 1.8rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-18-border-left {
  border-left: solid 1.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-18-border-right {
  border-right: solid 1.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-18-border-top {
  border-top: solid 1.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-18-border-bottom {
  border-bottom: solid 1.8rem #c5d9e8 !important; }

.has-light-blue-grey-three-19-border {
  border: solid 1.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-19-border-pulsate {
  border: solid 1.9rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-19-border-left {
  border-left: solid 1.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-19-border-right {
  border-right: solid 1.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-19-border-top {
  border-top: solid 1.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-19-border-bottom {
  border-bottom: solid 1.9rem #c5d9e8 !important; }

.has-light-blue-grey-three-20-border {
  border: solid 2rem #c5d9e8 !important; }

.has-light-blue-grey-three-20-border-pulsate {
  border: solid 2rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-20-border-left {
  border-left: solid 2rem #c5d9e8 !important; }

.has-light-blue-grey-three-20-border-right {
  border-right: solid 2rem #c5d9e8 !important; }

.has-light-blue-grey-three-20-border-top {
  border-top: solid 2rem #c5d9e8 !important; }

.has-light-blue-grey-three-20-border-bottom {
  border-bottom: solid 2rem #c5d9e8 !important; }

.has-light-blue-grey-three-21-border {
  border: solid 2.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-21-border-pulsate {
  border: solid 2.1rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-21-border-left {
  border-left: solid 2.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-21-border-right {
  border-right: solid 2.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-21-border-top {
  border-top: solid 2.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-21-border-bottom {
  border-bottom: solid 2.1rem #c5d9e8 !important; }

.has-light-blue-grey-three-24-border {
  border: solid 2.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-24-border-pulsate {
  border: solid 2.4rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-24-border-left {
  border-left: solid 2.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-24-border-right {
  border-right: solid 2.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-24-border-top {
  border-top: solid 2.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-24-border-bottom {
  border-bottom: solid 2.4rem #c5d9e8 !important; }

.has-light-blue-grey-three-30-border {
  border: solid 3rem #c5d9e8 !important; }

.has-light-blue-grey-three-30-border-pulsate {
  border: solid 3rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-30-border-left {
  border-left: solid 3rem #c5d9e8 !important; }

.has-light-blue-grey-three-30-border-right {
  border-right: solid 3rem #c5d9e8 !important; }

.has-light-blue-grey-three-30-border-top {
  border-top: solid 3rem #c5d9e8 !important; }

.has-light-blue-grey-three-30-border-bottom {
  border-bottom: solid 3rem #c5d9e8 !important; }

.has-light-blue-grey-three-40-border {
  border: solid 4rem #c5d9e8 !important; }

.has-light-blue-grey-three-40-border-pulsate {
  border: solid 4rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-40-border-left {
  border-left: solid 4rem #c5d9e8 !important; }

.has-light-blue-grey-three-40-border-right {
  border-right: solid 4rem #c5d9e8 !important; }

.has-light-blue-grey-three-40-border-top {
  border-top: solid 4rem #c5d9e8 !important; }

.has-light-blue-grey-three-40-border-bottom {
  border-bottom: solid 4rem #c5d9e8 !important; }

.has-light-blue-grey-three-50-border {
  border: solid 5rem #c5d9e8 !important; }

.has-light-blue-grey-three-50-border-pulsate {
  border: solid 5rem #c5d9e8 !important;
  animation: light-blue-grey-three-border-pulsate 2s infinite; }

.has-light-blue-grey-three-50-border-left {
  border-left: solid 5rem #c5d9e8 !important; }

.has-light-blue-grey-three-50-border-right {
  border-right: solid 5rem #c5d9e8 !important; }

.has-light-blue-grey-three-50-border-top {
  border-top: solid 5rem #c5d9e8 !important; }

.has-light-blue-grey-three-50-border-bottom {
  border-bottom: solid 5rem #c5d9e8 !important; }

@keyframes charcoal-grey-60-border-pulsate {
  0% {
    border-color: #37474f; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #37474f; } }

.has-charcoal-grey-60-border-color {
  border-color: rgba(55, 71, 79, 0.6); }

.has-charcoal-grey-60-border {
  border: solid 0.1rem rgba(55, 71, 79, 0.6); }

.has-charcoal-grey-60-border-pulsate {
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-border-left {
  border-left: solid 0.1rem rgba(55, 71, 79, 0.6); }

.has-charcoal-grey-60-border-right {
  border-right: solid 0.1rem rgba(55, 71, 79, 0.6); }

.has-charcoal-grey-60-border-top {
  border-top: solid 0.1rem rgba(55, 71, 79, 0.6); }

.has-charcoal-grey-60-border-bottom {
  border-bottom: solid 0.1rem rgba(55, 71, 79, 0.6); }

.has-charcoal-grey-60-1-border {
  border: solid 0.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-1-border-pulsate {
  border: solid 0.1rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-1-border-left {
  border-left: solid 0.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-1-border-right {
  border-right: solid 0.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-1-border-top {
  border-top: solid 0.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-1-border-bottom {
  border-bottom: solid 0.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-2-border {
  border: solid 0.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-2-border-pulsate {
  border: solid 0.2rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-2-border-left {
  border-left: solid 0.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-2-border-right {
  border-right: solid 0.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-2-border-top {
  border-top: solid 0.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-2-border-bottom {
  border-bottom: solid 0.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-3-border {
  border: solid 0.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-3-border-pulsate {
  border: solid 0.3rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-3-border-left {
  border-left: solid 0.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-3-border-right {
  border-right: solid 0.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-3-border-top {
  border-top: solid 0.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-3-border-bottom {
  border-bottom: solid 0.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-4-border {
  border: solid 0.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-4-border-pulsate {
  border: solid 0.4rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-4-border-left {
  border-left: solid 0.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-4-border-right {
  border-right: solid 0.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-4-border-top {
  border-top: solid 0.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-4-border-bottom {
  border-bottom: solid 0.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-5-border {
  border: solid 0.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-5-border-pulsate {
  border: solid 0.5rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-5-border-left {
  border-left: solid 0.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-5-border-right {
  border-right: solid 0.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-5-border-top {
  border-top: solid 0.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-5-border-bottom {
  border-bottom: solid 0.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-6-border {
  border: solid 0.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-6-border-pulsate {
  border: solid 0.6rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-6-border-left {
  border-left: solid 0.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-6-border-right {
  border-right: solid 0.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-6-border-top {
  border-top: solid 0.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-6-border-bottom {
  border-bottom: solid 0.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-7-border {
  border: solid 0.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-7-border-pulsate {
  border: solid 0.7rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-7-border-left {
  border-left: solid 0.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-7-border-right {
  border-right: solid 0.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-7-border-top {
  border-top: solid 0.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-7-border-bottom {
  border-bottom: solid 0.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-8-border {
  border: solid 0.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-8-border-pulsate {
  border: solid 0.8rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-8-border-left {
  border-left: solid 0.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-8-border-right {
  border-right: solid 0.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-8-border-top {
  border-top: solid 0.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-8-border-bottom {
  border-bottom: solid 0.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-9-border {
  border: solid 0.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-9-border-pulsate {
  border: solid 0.9rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-9-border-left {
  border-left: solid 0.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-9-border-right {
  border-right: solid 0.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-9-border-top {
  border-top: solid 0.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-9-border-bottom {
  border-bottom: solid 0.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-10-border {
  border: solid 1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-10-border-pulsate {
  border: solid 1rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-10-border-left {
  border-left: solid 1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-10-border-right {
  border-right: solid 1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-10-border-top {
  border-top: solid 1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-10-border-bottom {
  border-bottom: solid 1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-11-border {
  border: solid 1.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-11-border-pulsate {
  border: solid 1.1rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-11-border-left {
  border-left: solid 1.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-11-border-right {
  border-right: solid 1.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-11-border-top {
  border-top: solid 1.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-11-border-bottom {
  border-bottom: solid 1.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-12-border {
  border: solid 1.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-12-border-pulsate {
  border: solid 1.2rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-12-border-left {
  border-left: solid 1.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-12-border-right {
  border-right: solid 1.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-12-border-top {
  border-top: solid 1.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-12-border-bottom {
  border-bottom: solid 1.2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-13-border {
  border: solid 1.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-13-border-pulsate {
  border: solid 1.3rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-13-border-left {
  border-left: solid 1.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-13-border-right {
  border-right: solid 1.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-13-border-top {
  border-top: solid 1.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-13-border-bottom {
  border-bottom: solid 1.3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-14-border {
  border: solid 1.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-14-border-pulsate {
  border: solid 1.4rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-14-border-left {
  border-left: solid 1.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-14-border-right {
  border-right: solid 1.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-14-border-top {
  border-top: solid 1.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-14-border-bottom {
  border-bottom: solid 1.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-15-border {
  border: solid 1.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-15-border-pulsate {
  border: solid 1.5rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-15-border-left {
  border-left: solid 1.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-15-border-right {
  border-right: solid 1.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-15-border-top {
  border-top: solid 1.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-15-border-bottom {
  border-bottom: solid 1.5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-16-border {
  border: solid 1.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-16-border-pulsate {
  border: solid 1.6rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-16-border-left {
  border-left: solid 1.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-16-border-right {
  border-right: solid 1.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-16-border-top {
  border-top: solid 1.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-16-border-bottom {
  border-bottom: solid 1.6rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-17-border {
  border: solid 1.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-17-border-pulsate {
  border: solid 1.7rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-17-border-left {
  border-left: solid 1.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-17-border-right {
  border-right: solid 1.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-17-border-top {
  border-top: solid 1.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-17-border-bottom {
  border-bottom: solid 1.7rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-18-border {
  border: solid 1.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-18-border-pulsate {
  border: solid 1.8rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-18-border-left {
  border-left: solid 1.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-18-border-right {
  border-right: solid 1.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-18-border-top {
  border-top: solid 1.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-18-border-bottom {
  border-bottom: solid 1.8rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-19-border {
  border: solid 1.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-19-border-pulsate {
  border: solid 1.9rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-19-border-left {
  border-left: solid 1.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-19-border-right {
  border-right: solid 1.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-19-border-top {
  border-top: solid 1.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-19-border-bottom {
  border-bottom: solid 1.9rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-20-border {
  border: solid 2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-20-border-pulsate {
  border: solid 2rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-20-border-left {
  border-left: solid 2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-20-border-right {
  border-right: solid 2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-20-border-top {
  border-top: solid 2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-20-border-bottom {
  border-bottom: solid 2rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-21-border {
  border: solid 2.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-21-border-pulsate {
  border: solid 2.1rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-21-border-left {
  border-left: solid 2.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-21-border-right {
  border-right: solid 2.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-21-border-top {
  border-top: solid 2.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-21-border-bottom {
  border-bottom: solid 2.1rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-24-border {
  border: solid 2.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-24-border-pulsate {
  border: solid 2.4rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-24-border-left {
  border-left: solid 2.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-24-border-right {
  border-right: solid 2.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-24-border-top {
  border-top: solid 2.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-24-border-bottom {
  border-bottom: solid 2.4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-30-border {
  border: solid 3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-30-border-pulsate {
  border: solid 3rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-30-border-left {
  border-left: solid 3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-30-border-right {
  border-right: solid 3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-30-border-top {
  border-top: solid 3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-30-border-bottom {
  border-bottom: solid 3rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-40-border {
  border: solid 4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-40-border-pulsate {
  border: solid 4rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-40-border-left {
  border-left: solid 4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-40-border-right {
  border-right: solid 4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-40-border-top {
  border-top: solid 4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-40-border-bottom {
  border-bottom: solid 4rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-50-border {
  border: solid 5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-50-border-pulsate {
  border: solid 5rem rgba(55, 71, 79, 0.6) !important;
  animation: charcoal-grey-60-border-pulsate 2s infinite; }

.has-charcoal-grey-60-50-border-left {
  border-left: solid 5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-50-border-right {
  border-right: solid 5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-50-border-top {
  border-top: solid 5rem rgba(55, 71, 79, 0.6) !important; }

.has-charcoal-grey-60-50-border-bottom {
  border-bottom: solid 5rem rgba(55, 71, 79, 0.6) !important; }

@keyframes charcoal-grey-80-border-pulsate {
  0% {
    border-color: #37474f; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #37474f; } }

.has-charcoal-grey-80-border-color {
  border-color: rgba(55, 71, 79, 0.8); }

.has-charcoal-grey-80-border {
  border: solid 0.1rem rgba(55, 71, 79, 0.8); }

.has-charcoal-grey-80-border-pulsate {
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-border-left {
  border-left: solid 0.1rem rgba(55, 71, 79, 0.8); }

.has-charcoal-grey-80-border-right {
  border-right: solid 0.1rem rgba(55, 71, 79, 0.8); }

.has-charcoal-grey-80-border-top {
  border-top: solid 0.1rem rgba(55, 71, 79, 0.8); }

.has-charcoal-grey-80-border-bottom {
  border-bottom: solid 0.1rem rgba(55, 71, 79, 0.8); }

.has-charcoal-grey-80-1-border {
  border: solid 0.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-1-border-pulsate {
  border: solid 0.1rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-1-border-left {
  border-left: solid 0.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-1-border-right {
  border-right: solid 0.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-1-border-top {
  border-top: solid 0.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-1-border-bottom {
  border-bottom: solid 0.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-2-border {
  border: solid 0.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-2-border-pulsate {
  border: solid 0.2rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-2-border-left {
  border-left: solid 0.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-2-border-right {
  border-right: solid 0.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-2-border-top {
  border-top: solid 0.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-2-border-bottom {
  border-bottom: solid 0.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-3-border {
  border: solid 0.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-3-border-pulsate {
  border: solid 0.3rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-3-border-left {
  border-left: solid 0.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-3-border-right {
  border-right: solid 0.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-3-border-top {
  border-top: solid 0.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-3-border-bottom {
  border-bottom: solid 0.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-4-border {
  border: solid 0.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-4-border-pulsate {
  border: solid 0.4rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-4-border-left {
  border-left: solid 0.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-4-border-right {
  border-right: solid 0.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-4-border-top {
  border-top: solid 0.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-4-border-bottom {
  border-bottom: solid 0.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-5-border {
  border: solid 0.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-5-border-pulsate {
  border: solid 0.5rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-5-border-left {
  border-left: solid 0.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-5-border-right {
  border-right: solid 0.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-5-border-top {
  border-top: solid 0.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-5-border-bottom {
  border-bottom: solid 0.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-6-border {
  border: solid 0.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-6-border-pulsate {
  border: solid 0.6rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-6-border-left {
  border-left: solid 0.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-6-border-right {
  border-right: solid 0.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-6-border-top {
  border-top: solid 0.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-6-border-bottom {
  border-bottom: solid 0.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-7-border {
  border: solid 0.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-7-border-pulsate {
  border: solid 0.7rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-7-border-left {
  border-left: solid 0.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-7-border-right {
  border-right: solid 0.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-7-border-top {
  border-top: solid 0.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-7-border-bottom {
  border-bottom: solid 0.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-8-border {
  border: solid 0.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-8-border-pulsate {
  border: solid 0.8rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-8-border-left {
  border-left: solid 0.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-8-border-right {
  border-right: solid 0.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-8-border-top {
  border-top: solid 0.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-8-border-bottom {
  border-bottom: solid 0.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-9-border {
  border: solid 0.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-9-border-pulsate {
  border: solid 0.9rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-9-border-left {
  border-left: solid 0.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-9-border-right {
  border-right: solid 0.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-9-border-top {
  border-top: solid 0.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-9-border-bottom {
  border-bottom: solid 0.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-10-border {
  border: solid 1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-10-border-pulsate {
  border: solid 1rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-10-border-left {
  border-left: solid 1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-10-border-right {
  border-right: solid 1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-10-border-top {
  border-top: solid 1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-10-border-bottom {
  border-bottom: solid 1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-11-border {
  border: solid 1.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-11-border-pulsate {
  border: solid 1.1rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-11-border-left {
  border-left: solid 1.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-11-border-right {
  border-right: solid 1.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-11-border-top {
  border-top: solid 1.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-11-border-bottom {
  border-bottom: solid 1.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-12-border {
  border: solid 1.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-12-border-pulsate {
  border: solid 1.2rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-12-border-left {
  border-left: solid 1.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-12-border-right {
  border-right: solid 1.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-12-border-top {
  border-top: solid 1.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-12-border-bottom {
  border-bottom: solid 1.2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-13-border {
  border: solid 1.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-13-border-pulsate {
  border: solid 1.3rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-13-border-left {
  border-left: solid 1.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-13-border-right {
  border-right: solid 1.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-13-border-top {
  border-top: solid 1.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-13-border-bottom {
  border-bottom: solid 1.3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-14-border {
  border: solid 1.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-14-border-pulsate {
  border: solid 1.4rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-14-border-left {
  border-left: solid 1.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-14-border-right {
  border-right: solid 1.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-14-border-top {
  border-top: solid 1.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-14-border-bottom {
  border-bottom: solid 1.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-15-border {
  border: solid 1.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-15-border-pulsate {
  border: solid 1.5rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-15-border-left {
  border-left: solid 1.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-15-border-right {
  border-right: solid 1.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-15-border-top {
  border-top: solid 1.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-15-border-bottom {
  border-bottom: solid 1.5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-16-border {
  border: solid 1.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-16-border-pulsate {
  border: solid 1.6rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-16-border-left {
  border-left: solid 1.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-16-border-right {
  border-right: solid 1.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-16-border-top {
  border-top: solid 1.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-16-border-bottom {
  border-bottom: solid 1.6rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-17-border {
  border: solid 1.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-17-border-pulsate {
  border: solid 1.7rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-17-border-left {
  border-left: solid 1.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-17-border-right {
  border-right: solid 1.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-17-border-top {
  border-top: solid 1.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-17-border-bottom {
  border-bottom: solid 1.7rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-18-border {
  border: solid 1.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-18-border-pulsate {
  border: solid 1.8rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-18-border-left {
  border-left: solid 1.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-18-border-right {
  border-right: solid 1.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-18-border-top {
  border-top: solid 1.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-18-border-bottom {
  border-bottom: solid 1.8rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-19-border {
  border: solid 1.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-19-border-pulsate {
  border: solid 1.9rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-19-border-left {
  border-left: solid 1.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-19-border-right {
  border-right: solid 1.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-19-border-top {
  border-top: solid 1.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-19-border-bottom {
  border-bottom: solid 1.9rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-20-border {
  border: solid 2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-20-border-pulsate {
  border: solid 2rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-20-border-left {
  border-left: solid 2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-20-border-right {
  border-right: solid 2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-20-border-top {
  border-top: solid 2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-20-border-bottom {
  border-bottom: solid 2rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-21-border {
  border: solid 2.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-21-border-pulsate {
  border: solid 2.1rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-21-border-left {
  border-left: solid 2.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-21-border-right {
  border-right: solid 2.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-21-border-top {
  border-top: solid 2.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-21-border-bottom {
  border-bottom: solid 2.1rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-24-border {
  border: solid 2.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-24-border-pulsate {
  border: solid 2.4rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-24-border-left {
  border-left: solid 2.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-24-border-right {
  border-right: solid 2.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-24-border-top {
  border-top: solid 2.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-24-border-bottom {
  border-bottom: solid 2.4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-30-border {
  border: solid 3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-30-border-pulsate {
  border: solid 3rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-30-border-left {
  border-left: solid 3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-30-border-right {
  border-right: solid 3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-30-border-top {
  border-top: solid 3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-30-border-bottom {
  border-bottom: solid 3rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-40-border {
  border: solid 4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-40-border-pulsate {
  border: solid 4rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-40-border-left {
  border-left: solid 4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-40-border-right {
  border-right: solid 4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-40-border-top {
  border-top: solid 4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-40-border-bottom {
  border-bottom: solid 4rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-50-border {
  border: solid 5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-50-border-pulsate {
  border: solid 5rem rgba(55, 71, 79, 0.8) !important;
  animation: charcoal-grey-80-border-pulsate 2s infinite; }

.has-charcoal-grey-80-50-border-left {
  border-left: solid 5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-50-border-right {
  border-right: solid 5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-50-border-top {
  border-top: solid 5rem rgba(55, 71, 79, 0.8) !important; }

.has-charcoal-grey-80-50-border-bottom {
  border-bottom: solid 5rem rgba(55, 71, 79, 0.8) !important; }

@keyframes azure-border-pulsate {
  0% {
    border-color: #0093ee; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #0093ee; } }

.has-azure-border-color {
  border-color: #0093ee; }

.has-azure-border {
  border: solid 0.1rem #0093ee; }

.has-azure-border-pulsate {
  animation: azure-border-pulsate 2s infinite; }

.has-azure-border-left {
  border-left: solid 0.1rem #0093ee; }

.has-azure-border-right {
  border-right: solid 0.1rem #0093ee; }

.has-azure-border-top {
  border-top: solid 0.1rem #0093ee; }

.has-azure-border-bottom {
  border-bottom: solid 0.1rem #0093ee; }

.has-azure-1-border {
  border: solid 0.1rem #0093ee !important; }

.has-azure-1-border-pulsate {
  border: solid 0.1rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-1-border-left {
  border-left: solid 0.1rem #0093ee !important; }

.has-azure-1-border-right {
  border-right: solid 0.1rem #0093ee !important; }

.has-azure-1-border-top {
  border-top: solid 0.1rem #0093ee !important; }

.has-azure-1-border-bottom {
  border-bottom: solid 0.1rem #0093ee !important; }

.has-azure-2-border {
  border: solid 0.2rem #0093ee !important; }

.has-azure-2-border-pulsate {
  border: solid 0.2rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-2-border-left {
  border-left: solid 0.2rem #0093ee !important; }

.has-azure-2-border-right {
  border-right: solid 0.2rem #0093ee !important; }

.has-azure-2-border-top {
  border-top: solid 0.2rem #0093ee !important; }

.has-azure-2-border-bottom {
  border-bottom: solid 0.2rem #0093ee !important; }

.has-azure-3-border {
  border: solid 0.3rem #0093ee !important; }

.has-azure-3-border-pulsate {
  border: solid 0.3rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-3-border-left {
  border-left: solid 0.3rem #0093ee !important; }

.has-azure-3-border-right {
  border-right: solid 0.3rem #0093ee !important; }

.has-azure-3-border-top {
  border-top: solid 0.3rem #0093ee !important; }

.has-azure-3-border-bottom {
  border-bottom: solid 0.3rem #0093ee !important; }

.has-azure-4-border {
  border: solid 0.4rem #0093ee !important; }

.has-azure-4-border-pulsate {
  border: solid 0.4rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-4-border-left {
  border-left: solid 0.4rem #0093ee !important; }

.has-azure-4-border-right {
  border-right: solid 0.4rem #0093ee !important; }

.has-azure-4-border-top {
  border-top: solid 0.4rem #0093ee !important; }

.has-azure-4-border-bottom {
  border-bottom: solid 0.4rem #0093ee !important; }

.has-azure-5-border {
  border: solid 0.5rem #0093ee !important; }

.has-azure-5-border-pulsate {
  border: solid 0.5rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-5-border-left {
  border-left: solid 0.5rem #0093ee !important; }

.has-azure-5-border-right {
  border-right: solid 0.5rem #0093ee !important; }

.has-azure-5-border-top {
  border-top: solid 0.5rem #0093ee !important; }

.has-azure-5-border-bottom {
  border-bottom: solid 0.5rem #0093ee !important; }

.has-azure-6-border {
  border: solid 0.6rem #0093ee !important; }

.has-azure-6-border-pulsate {
  border: solid 0.6rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-6-border-left {
  border-left: solid 0.6rem #0093ee !important; }

.has-azure-6-border-right {
  border-right: solid 0.6rem #0093ee !important; }

.has-azure-6-border-top {
  border-top: solid 0.6rem #0093ee !important; }

.has-azure-6-border-bottom {
  border-bottom: solid 0.6rem #0093ee !important; }

.has-azure-7-border {
  border: solid 0.7rem #0093ee !important; }

.has-azure-7-border-pulsate {
  border: solid 0.7rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-7-border-left {
  border-left: solid 0.7rem #0093ee !important; }

.has-azure-7-border-right {
  border-right: solid 0.7rem #0093ee !important; }

.has-azure-7-border-top {
  border-top: solid 0.7rem #0093ee !important; }

.has-azure-7-border-bottom {
  border-bottom: solid 0.7rem #0093ee !important; }

.has-azure-8-border {
  border: solid 0.8rem #0093ee !important; }

.has-azure-8-border-pulsate {
  border: solid 0.8rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-8-border-left {
  border-left: solid 0.8rem #0093ee !important; }

.has-azure-8-border-right {
  border-right: solid 0.8rem #0093ee !important; }

.has-azure-8-border-top {
  border-top: solid 0.8rem #0093ee !important; }

.has-azure-8-border-bottom {
  border-bottom: solid 0.8rem #0093ee !important; }

.has-azure-9-border {
  border: solid 0.9rem #0093ee !important; }

.has-azure-9-border-pulsate {
  border: solid 0.9rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-9-border-left {
  border-left: solid 0.9rem #0093ee !important; }

.has-azure-9-border-right {
  border-right: solid 0.9rem #0093ee !important; }

.has-azure-9-border-top {
  border-top: solid 0.9rem #0093ee !important; }

.has-azure-9-border-bottom {
  border-bottom: solid 0.9rem #0093ee !important; }

.has-azure-10-border {
  border: solid 1rem #0093ee !important; }

.has-azure-10-border-pulsate {
  border: solid 1rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-10-border-left {
  border-left: solid 1rem #0093ee !important; }

.has-azure-10-border-right {
  border-right: solid 1rem #0093ee !important; }

.has-azure-10-border-top {
  border-top: solid 1rem #0093ee !important; }

.has-azure-10-border-bottom {
  border-bottom: solid 1rem #0093ee !important; }

.has-azure-11-border {
  border: solid 1.1rem #0093ee !important; }

.has-azure-11-border-pulsate {
  border: solid 1.1rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-11-border-left {
  border-left: solid 1.1rem #0093ee !important; }

.has-azure-11-border-right {
  border-right: solid 1.1rem #0093ee !important; }

.has-azure-11-border-top {
  border-top: solid 1.1rem #0093ee !important; }

.has-azure-11-border-bottom {
  border-bottom: solid 1.1rem #0093ee !important; }

.has-azure-12-border {
  border: solid 1.2rem #0093ee !important; }

.has-azure-12-border-pulsate {
  border: solid 1.2rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-12-border-left {
  border-left: solid 1.2rem #0093ee !important; }

.has-azure-12-border-right {
  border-right: solid 1.2rem #0093ee !important; }

.has-azure-12-border-top {
  border-top: solid 1.2rem #0093ee !important; }

.has-azure-12-border-bottom {
  border-bottom: solid 1.2rem #0093ee !important; }

.has-azure-13-border {
  border: solid 1.3rem #0093ee !important; }

.has-azure-13-border-pulsate {
  border: solid 1.3rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-13-border-left {
  border-left: solid 1.3rem #0093ee !important; }

.has-azure-13-border-right {
  border-right: solid 1.3rem #0093ee !important; }

.has-azure-13-border-top {
  border-top: solid 1.3rem #0093ee !important; }

.has-azure-13-border-bottom {
  border-bottom: solid 1.3rem #0093ee !important; }

.has-azure-14-border {
  border: solid 1.4rem #0093ee !important; }

.has-azure-14-border-pulsate {
  border: solid 1.4rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-14-border-left {
  border-left: solid 1.4rem #0093ee !important; }

.has-azure-14-border-right {
  border-right: solid 1.4rem #0093ee !important; }

.has-azure-14-border-top {
  border-top: solid 1.4rem #0093ee !important; }

.has-azure-14-border-bottom {
  border-bottom: solid 1.4rem #0093ee !important; }

.has-azure-15-border {
  border: solid 1.5rem #0093ee !important; }

.has-azure-15-border-pulsate {
  border: solid 1.5rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-15-border-left {
  border-left: solid 1.5rem #0093ee !important; }

.has-azure-15-border-right {
  border-right: solid 1.5rem #0093ee !important; }

.has-azure-15-border-top {
  border-top: solid 1.5rem #0093ee !important; }

.has-azure-15-border-bottom {
  border-bottom: solid 1.5rem #0093ee !important; }

.has-azure-16-border {
  border: solid 1.6rem #0093ee !important; }

.has-azure-16-border-pulsate {
  border: solid 1.6rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-16-border-left {
  border-left: solid 1.6rem #0093ee !important; }

.has-azure-16-border-right {
  border-right: solid 1.6rem #0093ee !important; }

.has-azure-16-border-top {
  border-top: solid 1.6rem #0093ee !important; }

.has-azure-16-border-bottom {
  border-bottom: solid 1.6rem #0093ee !important; }

.has-azure-17-border {
  border: solid 1.7rem #0093ee !important; }

.has-azure-17-border-pulsate {
  border: solid 1.7rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-17-border-left {
  border-left: solid 1.7rem #0093ee !important; }

.has-azure-17-border-right {
  border-right: solid 1.7rem #0093ee !important; }

.has-azure-17-border-top {
  border-top: solid 1.7rem #0093ee !important; }

.has-azure-17-border-bottom {
  border-bottom: solid 1.7rem #0093ee !important; }

.has-azure-18-border {
  border: solid 1.8rem #0093ee !important; }

.has-azure-18-border-pulsate {
  border: solid 1.8rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-18-border-left {
  border-left: solid 1.8rem #0093ee !important; }

.has-azure-18-border-right {
  border-right: solid 1.8rem #0093ee !important; }

.has-azure-18-border-top {
  border-top: solid 1.8rem #0093ee !important; }

.has-azure-18-border-bottom {
  border-bottom: solid 1.8rem #0093ee !important; }

.has-azure-19-border {
  border: solid 1.9rem #0093ee !important; }

.has-azure-19-border-pulsate {
  border: solid 1.9rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-19-border-left {
  border-left: solid 1.9rem #0093ee !important; }

.has-azure-19-border-right {
  border-right: solid 1.9rem #0093ee !important; }

.has-azure-19-border-top {
  border-top: solid 1.9rem #0093ee !important; }

.has-azure-19-border-bottom {
  border-bottom: solid 1.9rem #0093ee !important; }

.has-azure-20-border {
  border: solid 2rem #0093ee !important; }

.has-azure-20-border-pulsate {
  border: solid 2rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-20-border-left {
  border-left: solid 2rem #0093ee !important; }

.has-azure-20-border-right {
  border-right: solid 2rem #0093ee !important; }

.has-azure-20-border-top {
  border-top: solid 2rem #0093ee !important; }

.has-azure-20-border-bottom {
  border-bottom: solid 2rem #0093ee !important; }

.has-azure-21-border {
  border: solid 2.1rem #0093ee !important; }

.has-azure-21-border-pulsate {
  border: solid 2.1rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-21-border-left {
  border-left: solid 2.1rem #0093ee !important; }

.has-azure-21-border-right {
  border-right: solid 2.1rem #0093ee !important; }

.has-azure-21-border-top {
  border-top: solid 2.1rem #0093ee !important; }

.has-azure-21-border-bottom {
  border-bottom: solid 2.1rem #0093ee !important; }

.has-azure-24-border {
  border: solid 2.4rem #0093ee !important; }

.has-azure-24-border-pulsate {
  border: solid 2.4rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-24-border-left {
  border-left: solid 2.4rem #0093ee !important; }

.has-azure-24-border-right {
  border-right: solid 2.4rem #0093ee !important; }

.has-azure-24-border-top {
  border-top: solid 2.4rem #0093ee !important; }

.has-azure-24-border-bottom {
  border-bottom: solid 2.4rem #0093ee !important; }

.has-azure-30-border {
  border: solid 3rem #0093ee !important; }

.has-azure-30-border-pulsate {
  border: solid 3rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-30-border-left {
  border-left: solid 3rem #0093ee !important; }

.has-azure-30-border-right {
  border-right: solid 3rem #0093ee !important; }

.has-azure-30-border-top {
  border-top: solid 3rem #0093ee !important; }

.has-azure-30-border-bottom {
  border-bottom: solid 3rem #0093ee !important; }

.has-azure-40-border {
  border: solid 4rem #0093ee !important; }

.has-azure-40-border-pulsate {
  border: solid 4rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-40-border-left {
  border-left: solid 4rem #0093ee !important; }

.has-azure-40-border-right {
  border-right: solid 4rem #0093ee !important; }

.has-azure-40-border-top {
  border-top: solid 4rem #0093ee !important; }

.has-azure-40-border-bottom {
  border-bottom: solid 4rem #0093ee !important; }

.has-azure-50-border {
  border: solid 5rem #0093ee !important; }

.has-azure-50-border-pulsate {
  border: solid 5rem #0093ee !important;
  animation: azure-border-pulsate 2s infinite; }

.has-azure-50-border-left {
  border-left: solid 5rem #0093ee !important; }

.has-azure-50-border-right {
  border-right: solid 5rem #0093ee !important; }

.has-azure-50-border-top {
  border-top: solid 5rem #0093ee !important; }

.has-azure-50-border-bottom {
  border-bottom: solid 5rem #0093ee !important; }

@keyframes dark-sky-blue-border-pulsate {
  0% {
    border-color: #1e88e5; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #1e88e5; } }

.has-dark-sky-blue-border-color {
  border-color: #1e88e5; }

.has-dark-sky-blue-border {
  border: solid 0.1rem #1e88e5; }

.has-dark-sky-blue-border-pulsate {
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-border-left {
  border-left: solid 0.1rem #1e88e5; }

.has-dark-sky-blue-border-right {
  border-right: solid 0.1rem #1e88e5; }

.has-dark-sky-blue-border-top {
  border-top: solid 0.1rem #1e88e5; }

.has-dark-sky-blue-border-bottom {
  border-bottom: solid 0.1rem #1e88e5; }

.has-dark-sky-blue-1-border {
  border: solid 0.1rem #1e88e5 !important; }

.has-dark-sky-blue-1-border-pulsate {
  border: solid 0.1rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-1-border-left {
  border-left: solid 0.1rem #1e88e5 !important; }

.has-dark-sky-blue-1-border-right {
  border-right: solid 0.1rem #1e88e5 !important; }

.has-dark-sky-blue-1-border-top {
  border-top: solid 0.1rem #1e88e5 !important; }

.has-dark-sky-blue-1-border-bottom {
  border-bottom: solid 0.1rem #1e88e5 !important; }

.has-dark-sky-blue-2-border {
  border: solid 0.2rem #1e88e5 !important; }

.has-dark-sky-blue-2-border-pulsate {
  border: solid 0.2rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-2-border-left {
  border-left: solid 0.2rem #1e88e5 !important; }

.has-dark-sky-blue-2-border-right {
  border-right: solid 0.2rem #1e88e5 !important; }

.has-dark-sky-blue-2-border-top {
  border-top: solid 0.2rem #1e88e5 !important; }

.has-dark-sky-blue-2-border-bottom {
  border-bottom: solid 0.2rem #1e88e5 !important; }

.has-dark-sky-blue-3-border {
  border: solid 0.3rem #1e88e5 !important; }

.has-dark-sky-blue-3-border-pulsate {
  border: solid 0.3rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-3-border-left {
  border-left: solid 0.3rem #1e88e5 !important; }

.has-dark-sky-blue-3-border-right {
  border-right: solid 0.3rem #1e88e5 !important; }

.has-dark-sky-blue-3-border-top {
  border-top: solid 0.3rem #1e88e5 !important; }

.has-dark-sky-blue-3-border-bottom {
  border-bottom: solid 0.3rem #1e88e5 !important; }

.has-dark-sky-blue-4-border {
  border: solid 0.4rem #1e88e5 !important; }

.has-dark-sky-blue-4-border-pulsate {
  border: solid 0.4rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-4-border-left {
  border-left: solid 0.4rem #1e88e5 !important; }

.has-dark-sky-blue-4-border-right {
  border-right: solid 0.4rem #1e88e5 !important; }

.has-dark-sky-blue-4-border-top {
  border-top: solid 0.4rem #1e88e5 !important; }

.has-dark-sky-blue-4-border-bottom {
  border-bottom: solid 0.4rem #1e88e5 !important; }

.has-dark-sky-blue-5-border {
  border: solid 0.5rem #1e88e5 !important; }

.has-dark-sky-blue-5-border-pulsate {
  border: solid 0.5rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-5-border-left {
  border-left: solid 0.5rem #1e88e5 !important; }

.has-dark-sky-blue-5-border-right {
  border-right: solid 0.5rem #1e88e5 !important; }

.has-dark-sky-blue-5-border-top {
  border-top: solid 0.5rem #1e88e5 !important; }

.has-dark-sky-blue-5-border-bottom {
  border-bottom: solid 0.5rem #1e88e5 !important; }

.has-dark-sky-blue-6-border {
  border: solid 0.6rem #1e88e5 !important; }

.has-dark-sky-blue-6-border-pulsate {
  border: solid 0.6rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-6-border-left {
  border-left: solid 0.6rem #1e88e5 !important; }

.has-dark-sky-blue-6-border-right {
  border-right: solid 0.6rem #1e88e5 !important; }

.has-dark-sky-blue-6-border-top {
  border-top: solid 0.6rem #1e88e5 !important; }

.has-dark-sky-blue-6-border-bottom {
  border-bottom: solid 0.6rem #1e88e5 !important; }

.has-dark-sky-blue-7-border {
  border: solid 0.7rem #1e88e5 !important; }

.has-dark-sky-blue-7-border-pulsate {
  border: solid 0.7rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-7-border-left {
  border-left: solid 0.7rem #1e88e5 !important; }

.has-dark-sky-blue-7-border-right {
  border-right: solid 0.7rem #1e88e5 !important; }

.has-dark-sky-blue-7-border-top {
  border-top: solid 0.7rem #1e88e5 !important; }

.has-dark-sky-blue-7-border-bottom {
  border-bottom: solid 0.7rem #1e88e5 !important; }

.has-dark-sky-blue-8-border {
  border: solid 0.8rem #1e88e5 !important; }

.has-dark-sky-blue-8-border-pulsate {
  border: solid 0.8rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-8-border-left {
  border-left: solid 0.8rem #1e88e5 !important; }

.has-dark-sky-blue-8-border-right {
  border-right: solid 0.8rem #1e88e5 !important; }

.has-dark-sky-blue-8-border-top {
  border-top: solid 0.8rem #1e88e5 !important; }

.has-dark-sky-blue-8-border-bottom {
  border-bottom: solid 0.8rem #1e88e5 !important; }

.has-dark-sky-blue-9-border {
  border: solid 0.9rem #1e88e5 !important; }

.has-dark-sky-blue-9-border-pulsate {
  border: solid 0.9rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-9-border-left {
  border-left: solid 0.9rem #1e88e5 !important; }

.has-dark-sky-blue-9-border-right {
  border-right: solid 0.9rem #1e88e5 !important; }

.has-dark-sky-blue-9-border-top {
  border-top: solid 0.9rem #1e88e5 !important; }

.has-dark-sky-blue-9-border-bottom {
  border-bottom: solid 0.9rem #1e88e5 !important; }

.has-dark-sky-blue-10-border {
  border: solid 1rem #1e88e5 !important; }

.has-dark-sky-blue-10-border-pulsate {
  border: solid 1rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-10-border-left {
  border-left: solid 1rem #1e88e5 !important; }

.has-dark-sky-blue-10-border-right {
  border-right: solid 1rem #1e88e5 !important; }

.has-dark-sky-blue-10-border-top {
  border-top: solid 1rem #1e88e5 !important; }

.has-dark-sky-blue-10-border-bottom {
  border-bottom: solid 1rem #1e88e5 !important; }

.has-dark-sky-blue-11-border {
  border: solid 1.1rem #1e88e5 !important; }

.has-dark-sky-blue-11-border-pulsate {
  border: solid 1.1rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-11-border-left {
  border-left: solid 1.1rem #1e88e5 !important; }

.has-dark-sky-blue-11-border-right {
  border-right: solid 1.1rem #1e88e5 !important; }

.has-dark-sky-blue-11-border-top {
  border-top: solid 1.1rem #1e88e5 !important; }

.has-dark-sky-blue-11-border-bottom {
  border-bottom: solid 1.1rem #1e88e5 !important; }

.has-dark-sky-blue-12-border {
  border: solid 1.2rem #1e88e5 !important; }

.has-dark-sky-blue-12-border-pulsate {
  border: solid 1.2rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-12-border-left {
  border-left: solid 1.2rem #1e88e5 !important; }

.has-dark-sky-blue-12-border-right {
  border-right: solid 1.2rem #1e88e5 !important; }

.has-dark-sky-blue-12-border-top {
  border-top: solid 1.2rem #1e88e5 !important; }

.has-dark-sky-blue-12-border-bottom {
  border-bottom: solid 1.2rem #1e88e5 !important; }

.has-dark-sky-blue-13-border {
  border: solid 1.3rem #1e88e5 !important; }

.has-dark-sky-blue-13-border-pulsate {
  border: solid 1.3rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-13-border-left {
  border-left: solid 1.3rem #1e88e5 !important; }

.has-dark-sky-blue-13-border-right {
  border-right: solid 1.3rem #1e88e5 !important; }

.has-dark-sky-blue-13-border-top {
  border-top: solid 1.3rem #1e88e5 !important; }

.has-dark-sky-blue-13-border-bottom {
  border-bottom: solid 1.3rem #1e88e5 !important; }

.has-dark-sky-blue-14-border {
  border: solid 1.4rem #1e88e5 !important; }

.has-dark-sky-blue-14-border-pulsate {
  border: solid 1.4rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-14-border-left {
  border-left: solid 1.4rem #1e88e5 !important; }

.has-dark-sky-blue-14-border-right {
  border-right: solid 1.4rem #1e88e5 !important; }

.has-dark-sky-blue-14-border-top {
  border-top: solid 1.4rem #1e88e5 !important; }

.has-dark-sky-blue-14-border-bottom {
  border-bottom: solid 1.4rem #1e88e5 !important; }

.has-dark-sky-blue-15-border {
  border: solid 1.5rem #1e88e5 !important; }

.has-dark-sky-blue-15-border-pulsate {
  border: solid 1.5rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-15-border-left {
  border-left: solid 1.5rem #1e88e5 !important; }

.has-dark-sky-blue-15-border-right {
  border-right: solid 1.5rem #1e88e5 !important; }

.has-dark-sky-blue-15-border-top {
  border-top: solid 1.5rem #1e88e5 !important; }

.has-dark-sky-blue-15-border-bottom {
  border-bottom: solid 1.5rem #1e88e5 !important; }

.has-dark-sky-blue-16-border {
  border: solid 1.6rem #1e88e5 !important; }

.has-dark-sky-blue-16-border-pulsate {
  border: solid 1.6rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-16-border-left {
  border-left: solid 1.6rem #1e88e5 !important; }

.has-dark-sky-blue-16-border-right {
  border-right: solid 1.6rem #1e88e5 !important; }

.has-dark-sky-blue-16-border-top {
  border-top: solid 1.6rem #1e88e5 !important; }

.has-dark-sky-blue-16-border-bottom {
  border-bottom: solid 1.6rem #1e88e5 !important; }

.has-dark-sky-blue-17-border {
  border: solid 1.7rem #1e88e5 !important; }

.has-dark-sky-blue-17-border-pulsate {
  border: solid 1.7rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-17-border-left {
  border-left: solid 1.7rem #1e88e5 !important; }

.has-dark-sky-blue-17-border-right {
  border-right: solid 1.7rem #1e88e5 !important; }

.has-dark-sky-blue-17-border-top {
  border-top: solid 1.7rem #1e88e5 !important; }

.has-dark-sky-blue-17-border-bottom {
  border-bottom: solid 1.7rem #1e88e5 !important; }

.has-dark-sky-blue-18-border {
  border: solid 1.8rem #1e88e5 !important; }

.has-dark-sky-blue-18-border-pulsate {
  border: solid 1.8rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-18-border-left {
  border-left: solid 1.8rem #1e88e5 !important; }

.has-dark-sky-blue-18-border-right {
  border-right: solid 1.8rem #1e88e5 !important; }

.has-dark-sky-blue-18-border-top {
  border-top: solid 1.8rem #1e88e5 !important; }

.has-dark-sky-blue-18-border-bottom {
  border-bottom: solid 1.8rem #1e88e5 !important; }

.has-dark-sky-blue-19-border {
  border: solid 1.9rem #1e88e5 !important; }

.has-dark-sky-blue-19-border-pulsate {
  border: solid 1.9rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-19-border-left {
  border-left: solid 1.9rem #1e88e5 !important; }

.has-dark-sky-blue-19-border-right {
  border-right: solid 1.9rem #1e88e5 !important; }

.has-dark-sky-blue-19-border-top {
  border-top: solid 1.9rem #1e88e5 !important; }

.has-dark-sky-blue-19-border-bottom {
  border-bottom: solid 1.9rem #1e88e5 !important; }

.has-dark-sky-blue-20-border {
  border: solid 2rem #1e88e5 !important; }

.has-dark-sky-blue-20-border-pulsate {
  border: solid 2rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-20-border-left {
  border-left: solid 2rem #1e88e5 !important; }

.has-dark-sky-blue-20-border-right {
  border-right: solid 2rem #1e88e5 !important; }

.has-dark-sky-blue-20-border-top {
  border-top: solid 2rem #1e88e5 !important; }

.has-dark-sky-blue-20-border-bottom {
  border-bottom: solid 2rem #1e88e5 !important; }

.has-dark-sky-blue-21-border {
  border: solid 2.1rem #1e88e5 !important; }

.has-dark-sky-blue-21-border-pulsate {
  border: solid 2.1rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-21-border-left {
  border-left: solid 2.1rem #1e88e5 !important; }

.has-dark-sky-blue-21-border-right {
  border-right: solid 2.1rem #1e88e5 !important; }

.has-dark-sky-blue-21-border-top {
  border-top: solid 2.1rem #1e88e5 !important; }

.has-dark-sky-blue-21-border-bottom {
  border-bottom: solid 2.1rem #1e88e5 !important; }

.has-dark-sky-blue-24-border {
  border: solid 2.4rem #1e88e5 !important; }

.has-dark-sky-blue-24-border-pulsate {
  border: solid 2.4rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-24-border-left {
  border-left: solid 2.4rem #1e88e5 !important; }

.has-dark-sky-blue-24-border-right {
  border-right: solid 2.4rem #1e88e5 !important; }

.has-dark-sky-blue-24-border-top {
  border-top: solid 2.4rem #1e88e5 !important; }

.has-dark-sky-blue-24-border-bottom {
  border-bottom: solid 2.4rem #1e88e5 !important; }

.has-dark-sky-blue-30-border {
  border: solid 3rem #1e88e5 !important; }

.has-dark-sky-blue-30-border-pulsate {
  border: solid 3rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-30-border-left {
  border-left: solid 3rem #1e88e5 !important; }

.has-dark-sky-blue-30-border-right {
  border-right: solid 3rem #1e88e5 !important; }

.has-dark-sky-blue-30-border-top {
  border-top: solid 3rem #1e88e5 !important; }

.has-dark-sky-blue-30-border-bottom {
  border-bottom: solid 3rem #1e88e5 !important; }

.has-dark-sky-blue-40-border {
  border: solid 4rem #1e88e5 !important; }

.has-dark-sky-blue-40-border-pulsate {
  border: solid 4rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-40-border-left {
  border-left: solid 4rem #1e88e5 !important; }

.has-dark-sky-blue-40-border-right {
  border-right: solid 4rem #1e88e5 !important; }

.has-dark-sky-blue-40-border-top {
  border-top: solid 4rem #1e88e5 !important; }

.has-dark-sky-blue-40-border-bottom {
  border-bottom: solid 4rem #1e88e5 !important; }

.has-dark-sky-blue-50-border {
  border: solid 5rem #1e88e5 !important; }

.has-dark-sky-blue-50-border-pulsate {
  border: solid 5rem #1e88e5 !important;
  animation: dark-sky-blue-border-pulsate 2s infinite; }

.has-dark-sky-blue-50-border-left {
  border-left: solid 5rem #1e88e5 !important; }

.has-dark-sky-blue-50-border-right {
  border-right: solid 5rem #1e88e5 !important; }

.has-dark-sky-blue-50-border-top {
  border-top: solid 5rem #1e88e5 !important; }

.has-dark-sky-blue-50-border-bottom {
  border-bottom: solid 5rem #1e88e5 !important; }

@keyframes apple-green-border-pulsate {
  0% {
    border-color: #81c926; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #81c926; } }

.has-apple-green-border-color {
  border-color: #81c926; }

.has-apple-green-border {
  border: solid 0.1rem #81c926; }

.has-apple-green-border-pulsate {
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-border-left {
  border-left: solid 0.1rem #81c926; }

.has-apple-green-border-right {
  border-right: solid 0.1rem #81c926; }

.has-apple-green-border-top {
  border-top: solid 0.1rem #81c926; }

.has-apple-green-border-bottom {
  border-bottom: solid 0.1rem #81c926; }

.has-apple-green-1-border {
  border: solid 0.1rem #81c926 !important; }

.has-apple-green-1-border-pulsate {
  border: solid 0.1rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-1-border-left {
  border-left: solid 0.1rem #81c926 !important; }

.has-apple-green-1-border-right {
  border-right: solid 0.1rem #81c926 !important; }

.has-apple-green-1-border-top {
  border-top: solid 0.1rem #81c926 !important; }

.has-apple-green-1-border-bottom {
  border-bottom: solid 0.1rem #81c926 !important; }

.has-apple-green-2-border {
  border: solid 0.2rem #81c926 !important; }

.has-apple-green-2-border-pulsate {
  border: solid 0.2rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-2-border-left {
  border-left: solid 0.2rem #81c926 !important; }

.has-apple-green-2-border-right {
  border-right: solid 0.2rem #81c926 !important; }

.has-apple-green-2-border-top {
  border-top: solid 0.2rem #81c926 !important; }

.has-apple-green-2-border-bottom {
  border-bottom: solid 0.2rem #81c926 !important; }

.has-apple-green-3-border {
  border: solid 0.3rem #81c926 !important; }

.has-apple-green-3-border-pulsate {
  border: solid 0.3rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-3-border-left {
  border-left: solid 0.3rem #81c926 !important; }

.has-apple-green-3-border-right {
  border-right: solid 0.3rem #81c926 !important; }

.has-apple-green-3-border-top {
  border-top: solid 0.3rem #81c926 !important; }

.has-apple-green-3-border-bottom {
  border-bottom: solid 0.3rem #81c926 !important; }

.has-apple-green-4-border {
  border: solid 0.4rem #81c926 !important; }

.has-apple-green-4-border-pulsate {
  border: solid 0.4rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-4-border-left {
  border-left: solid 0.4rem #81c926 !important; }

.has-apple-green-4-border-right {
  border-right: solid 0.4rem #81c926 !important; }

.has-apple-green-4-border-top {
  border-top: solid 0.4rem #81c926 !important; }

.has-apple-green-4-border-bottom {
  border-bottom: solid 0.4rem #81c926 !important; }

.has-apple-green-5-border {
  border: solid 0.5rem #81c926 !important; }

.has-apple-green-5-border-pulsate {
  border: solid 0.5rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-5-border-left {
  border-left: solid 0.5rem #81c926 !important; }

.has-apple-green-5-border-right {
  border-right: solid 0.5rem #81c926 !important; }

.has-apple-green-5-border-top {
  border-top: solid 0.5rem #81c926 !important; }

.has-apple-green-5-border-bottom {
  border-bottom: solid 0.5rem #81c926 !important; }

.has-apple-green-6-border {
  border: solid 0.6rem #81c926 !important; }

.has-apple-green-6-border-pulsate {
  border: solid 0.6rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-6-border-left {
  border-left: solid 0.6rem #81c926 !important; }

.has-apple-green-6-border-right {
  border-right: solid 0.6rem #81c926 !important; }

.has-apple-green-6-border-top {
  border-top: solid 0.6rem #81c926 !important; }

.has-apple-green-6-border-bottom {
  border-bottom: solid 0.6rem #81c926 !important; }

.has-apple-green-7-border {
  border: solid 0.7rem #81c926 !important; }

.has-apple-green-7-border-pulsate {
  border: solid 0.7rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-7-border-left {
  border-left: solid 0.7rem #81c926 !important; }

.has-apple-green-7-border-right {
  border-right: solid 0.7rem #81c926 !important; }

.has-apple-green-7-border-top {
  border-top: solid 0.7rem #81c926 !important; }

.has-apple-green-7-border-bottom {
  border-bottom: solid 0.7rem #81c926 !important; }

.has-apple-green-8-border {
  border: solid 0.8rem #81c926 !important; }

.has-apple-green-8-border-pulsate {
  border: solid 0.8rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-8-border-left {
  border-left: solid 0.8rem #81c926 !important; }

.has-apple-green-8-border-right {
  border-right: solid 0.8rem #81c926 !important; }

.has-apple-green-8-border-top {
  border-top: solid 0.8rem #81c926 !important; }

.has-apple-green-8-border-bottom {
  border-bottom: solid 0.8rem #81c926 !important; }

.has-apple-green-9-border {
  border: solid 0.9rem #81c926 !important; }

.has-apple-green-9-border-pulsate {
  border: solid 0.9rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-9-border-left {
  border-left: solid 0.9rem #81c926 !important; }

.has-apple-green-9-border-right {
  border-right: solid 0.9rem #81c926 !important; }

.has-apple-green-9-border-top {
  border-top: solid 0.9rem #81c926 !important; }

.has-apple-green-9-border-bottom {
  border-bottom: solid 0.9rem #81c926 !important; }

.has-apple-green-10-border {
  border: solid 1rem #81c926 !important; }

.has-apple-green-10-border-pulsate {
  border: solid 1rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-10-border-left {
  border-left: solid 1rem #81c926 !important; }

.has-apple-green-10-border-right {
  border-right: solid 1rem #81c926 !important; }

.has-apple-green-10-border-top {
  border-top: solid 1rem #81c926 !important; }

.has-apple-green-10-border-bottom {
  border-bottom: solid 1rem #81c926 !important; }

.has-apple-green-11-border {
  border: solid 1.1rem #81c926 !important; }

.has-apple-green-11-border-pulsate {
  border: solid 1.1rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-11-border-left {
  border-left: solid 1.1rem #81c926 !important; }

.has-apple-green-11-border-right {
  border-right: solid 1.1rem #81c926 !important; }

.has-apple-green-11-border-top {
  border-top: solid 1.1rem #81c926 !important; }

.has-apple-green-11-border-bottom {
  border-bottom: solid 1.1rem #81c926 !important; }

.has-apple-green-12-border {
  border: solid 1.2rem #81c926 !important; }

.has-apple-green-12-border-pulsate {
  border: solid 1.2rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-12-border-left {
  border-left: solid 1.2rem #81c926 !important; }

.has-apple-green-12-border-right {
  border-right: solid 1.2rem #81c926 !important; }

.has-apple-green-12-border-top {
  border-top: solid 1.2rem #81c926 !important; }

.has-apple-green-12-border-bottom {
  border-bottom: solid 1.2rem #81c926 !important; }

.has-apple-green-13-border {
  border: solid 1.3rem #81c926 !important; }

.has-apple-green-13-border-pulsate {
  border: solid 1.3rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-13-border-left {
  border-left: solid 1.3rem #81c926 !important; }

.has-apple-green-13-border-right {
  border-right: solid 1.3rem #81c926 !important; }

.has-apple-green-13-border-top {
  border-top: solid 1.3rem #81c926 !important; }

.has-apple-green-13-border-bottom {
  border-bottom: solid 1.3rem #81c926 !important; }

.has-apple-green-14-border {
  border: solid 1.4rem #81c926 !important; }

.has-apple-green-14-border-pulsate {
  border: solid 1.4rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-14-border-left {
  border-left: solid 1.4rem #81c926 !important; }

.has-apple-green-14-border-right {
  border-right: solid 1.4rem #81c926 !important; }

.has-apple-green-14-border-top {
  border-top: solid 1.4rem #81c926 !important; }

.has-apple-green-14-border-bottom {
  border-bottom: solid 1.4rem #81c926 !important; }

.has-apple-green-15-border {
  border: solid 1.5rem #81c926 !important; }

.has-apple-green-15-border-pulsate {
  border: solid 1.5rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-15-border-left {
  border-left: solid 1.5rem #81c926 !important; }

.has-apple-green-15-border-right {
  border-right: solid 1.5rem #81c926 !important; }

.has-apple-green-15-border-top {
  border-top: solid 1.5rem #81c926 !important; }

.has-apple-green-15-border-bottom {
  border-bottom: solid 1.5rem #81c926 !important; }

.has-apple-green-16-border {
  border: solid 1.6rem #81c926 !important; }

.has-apple-green-16-border-pulsate {
  border: solid 1.6rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-16-border-left {
  border-left: solid 1.6rem #81c926 !important; }

.has-apple-green-16-border-right {
  border-right: solid 1.6rem #81c926 !important; }

.has-apple-green-16-border-top {
  border-top: solid 1.6rem #81c926 !important; }

.has-apple-green-16-border-bottom {
  border-bottom: solid 1.6rem #81c926 !important; }

.has-apple-green-17-border {
  border: solid 1.7rem #81c926 !important; }

.has-apple-green-17-border-pulsate {
  border: solid 1.7rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-17-border-left {
  border-left: solid 1.7rem #81c926 !important; }

.has-apple-green-17-border-right {
  border-right: solid 1.7rem #81c926 !important; }

.has-apple-green-17-border-top {
  border-top: solid 1.7rem #81c926 !important; }

.has-apple-green-17-border-bottom {
  border-bottom: solid 1.7rem #81c926 !important; }

.has-apple-green-18-border {
  border: solid 1.8rem #81c926 !important; }

.has-apple-green-18-border-pulsate {
  border: solid 1.8rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-18-border-left {
  border-left: solid 1.8rem #81c926 !important; }

.has-apple-green-18-border-right {
  border-right: solid 1.8rem #81c926 !important; }

.has-apple-green-18-border-top {
  border-top: solid 1.8rem #81c926 !important; }

.has-apple-green-18-border-bottom {
  border-bottom: solid 1.8rem #81c926 !important; }

.has-apple-green-19-border {
  border: solid 1.9rem #81c926 !important; }

.has-apple-green-19-border-pulsate {
  border: solid 1.9rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-19-border-left {
  border-left: solid 1.9rem #81c926 !important; }

.has-apple-green-19-border-right {
  border-right: solid 1.9rem #81c926 !important; }

.has-apple-green-19-border-top {
  border-top: solid 1.9rem #81c926 !important; }

.has-apple-green-19-border-bottom {
  border-bottom: solid 1.9rem #81c926 !important; }

.has-apple-green-20-border {
  border: solid 2rem #81c926 !important; }

.has-apple-green-20-border-pulsate {
  border: solid 2rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-20-border-left {
  border-left: solid 2rem #81c926 !important; }

.has-apple-green-20-border-right {
  border-right: solid 2rem #81c926 !important; }

.has-apple-green-20-border-top {
  border-top: solid 2rem #81c926 !important; }

.has-apple-green-20-border-bottom {
  border-bottom: solid 2rem #81c926 !important; }

.has-apple-green-21-border {
  border: solid 2.1rem #81c926 !important; }

.has-apple-green-21-border-pulsate {
  border: solid 2.1rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-21-border-left {
  border-left: solid 2.1rem #81c926 !important; }

.has-apple-green-21-border-right {
  border-right: solid 2.1rem #81c926 !important; }

.has-apple-green-21-border-top {
  border-top: solid 2.1rem #81c926 !important; }

.has-apple-green-21-border-bottom {
  border-bottom: solid 2.1rem #81c926 !important; }

.has-apple-green-24-border {
  border: solid 2.4rem #81c926 !important; }

.has-apple-green-24-border-pulsate {
  border: solid 2.4rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-24-border-left {
  border-left: solid 2.4rem #81c926 !important; }

.has-apple-green-24-border-right {
  border-right: solid 2.4rem #81c926 !important; }

.has-apple-green-24-border-top {
  border-top: solid 2.4rem #81c926 !important; }

.has-apple-green-24-border-bottom {
  border-bottom: solid 2.4rem #81c926 !important; }

.has-apple-green-30-border {
  border: solid 3rem #81c926 !important; }

.has-apple-green-30-border-pulsate {
  border: solid 3rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-30-border-left {
  border-left: solid 3rem #81c926 !important; }

.has-apple-green-30-border-right {
  border-right: solid 3rem #81c926 !important; }

.has-apple-green-30-border-top {
  border-top: solid 3rem #81c926 !important; }

.has-apple-green-30-border-bottom {
  border-bottom: solid 3rem #81c926 !important; }

.has-apple-green-40-border {
  border: solid 4rem #81c926 !important; }

.has-apple-green-40-border-pulsate {
  border: solid 4rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-40-border-left {
  border-left: solid 4rem #81c926 !important; }

.has-apple-green-40-border-right {
  border-right: solid 4rem #81c926 !important; }

.has-apple-green-40-border-top {
  border-top: solid 4rem #81c926 !important; }

.has-apple-green-40-border-bottom {
  border-bottom: solid 4rem #81c926 !important; }

.has-apple-green-50-border {
  border: solid 5rem #81c926 !important; }

.has-apple-green-50-border-pulsate {
  border: solid 5rem #81c926 !important;
  animation: apple-green-border-pulsate 2s infinite; }

.has-apple-green-50-border-left {
  border-left: solid 5rem #81c926 !important; }

.has-apple-green-50-border-right {
  border-right: solid 5rem #81c926 !important; }

.has-apple-green-50-border-top {
  border-top: solid 5rem #81c926 !important; }

.has-apple-green-50-border-bottom {
  border-bottom: solid 5rem #81c926 !important; }

@keyframes pause-azure-border-pulsate {
  0% {
    border-color: #03a9f4; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #03a9f4; } }

.has-pause-azure-border-color {
  border-color: #03a9f4; }

.has-pause-azure-border {
  border: solid 0.1rem #03a9f4; }

.has-pause-azure-border-pulsate {
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-border-left {
  border-left: solid 0.1rem #03a9f4; }

.has-pause-azure-border-right {
  border-right: solid 0.1rem #03a9f4; }

.has-pause-azure-border-top {
  border-top: solid 0.1rem #03a9f4; }

.has-pause-azure-border-bottom {
  border-bottom: solid 0.1rem #03a9f4; }

.has-pause-azure-1-border {
  border: solid 0.1rem #03a9f4 !important; }

.has-pause-azure-1-border-pulsate {
  border: solid 0.1rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-1-border-left {
  border-left: solid 0.1rem #03a9f4 !important; }

.has-pause-azure-1-border-right {
  border-right: solid 0.1rem #03a9f4 !important; }

.has-pause-azure-1-border-top {
  border-top: solid 0.1rem #03a9f4 !important; }

.has-pause-azure-1-border-bottom {
  border-bottom: solid 0.1rem #03a9f4 !important; }

.has-pause-azure-2-border {
  border: solid 0.2rem #03a9f4 !important; }

.has-pause-azure-2-border-pulsate {
  border: solid 0.2rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-2-border-left {
  border-left: solid 0.2rem #03a9f4 !important; }

.has-pause-azure-2-border-right {
  border-right: solid 0.2rem #03a9f4 !important; }

.has-pause-azure-2-border-top {
  border-top: solid 0.2rem #03a9f4 !important; }

.has-pause-azure-2-border-bottom {
  border-bottom: solid 0.2rem #03a9f4 !important; }

.has-pause-azure-3-border {
  border: solid 0.3rem #03a9f4 !important; }

.has-pause-azure-3-border-pulsate {
  border: solid 0.3rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-3-border-left {
  border-left: solid 0.3rem #03a9f4 !important; }

.has-pause-azure-3-border-right {
  border-right: solid 0.3rem #03a9f4 !important; }

.has-pause-azure-3-border-top {
  border-top: solid 0.3rem #03a9f4 !important; }

.has-pause-azure-3-border-bottom {
  border-bottom: solid 0.3rem #03a9f4 !important; }

.has-pause-azure-4-border {
  border: solid 0.4rem #03a9f4 !important; }

.has-pause-azure-4-border-pulsate {
  border: solid 0.4rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-4-border-left {
  border-left: solid 0.4rem #03a9f4 !important; }

.has-pause-azure-4-border-right {
  border-right: solid 0.4rem #03a9f4 !important; }

.has-pause-azure-4-border-top {
  border-top: solid 0.4rem #03a9f4 !important; }

.has-pause-azure-4-border-bottom {
  border-bottom: solid 0.4rem #03a9f4 !important; }

.has-pause-azure-5-border {
  border: solid 0.5rem #03a9f4 !important; }

.has-pause-azure-5-border-pulsate {
  border: solid 0.5rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-5-border-left {
  border-left: solid 0.5rem #03a9f4 !important; }

.has-pause-azure-5-border-right {
  border-right: solid 0.5rem #03a9f4 !important; }

.has-pause-azure-5-border-top {
  border-top: solid 0.5rem #03a9f4 !important; }

.has-pause-azure-5-border-bottom {
  border-bottom: solid 0.5rem #03a9f4 !important; }

.has-pause-azure-6-border {
  border: solid 0.6rem #03a9f4 !important; }

.has-pause-azure-6-border-pulsate {
  border: solid 0.6rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-6-border-left {
  border-left: solid 0.6rem #03a9f4 !important; }

.has-pause-azure-6-border-right {
  border-right: solid 0.6rem #03a9f4 !important; }

.has-pause-azure-6-border-top {
  border-top: solid 0.6rem #03a9f4 !important; }

.has-pause-azure-6-border-bottom {
  border-bottom: solid 0.6rem #03a9f4 !important; }

.has-pause-azure-7-border {
  border: solid 0.7rem #03a9f4 !important; }

.has-pause-azure-7-border-pulsate {
  border: solid 0.7rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-7-border-left {
  border-left: solid 0.7rem #03a9f4 !important; }

.has-pause-azure-7-border-right {
  border-right: solid 0.7rem #03a9f4 !important; }

.has-pause-azure-7-border-top {
  border-top: solid 0.7rem #03a9f4 !important; }

.has-pause-azure-7-border-bottom {
  border-bottom: solid 0.7rem #03a9f4 !important; }

.has-pause-azure-8-border {
  border: solid 0.8rem #03a9f4 !important; }

.has-pause-azure-8-border-pulsate {
  border: solid 0.8rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-8-border-left {
  border-left: solid 0.8rem #03a9f4 !important; }

.has-pause-azure-8-border-right {
  border-right: solid 0.8rem #03a9f4 !important; }

.has-pause-azure-8-border-top {
  border-top: solid 0.8rem #03a9f4 !important; }

.has-pause-azure-8-border-bottom {
  border-bottom: solid 0.8rem #03a9f4 !important; }

.has-pause-azure-9-border {
  border: solid 0.9rem #03a9f4 !important; }

.has-pause-azure-9-border-pulsate {
  border: solid 0.9rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-9-border-left {
  border-left: solid 0.9rem #03a9f4 !important; }

.has-pause-azure-9-border-right {
  border-right: solid 0.9rem #03a9f4 !important; }

.has-pause-azure-9-border-top {
  border-top: solid 0.9rem #03a9f4 !important; }

.has-pause-azure-9-border-bottom {
  border-bottom: solid 0.9rem #03a9f4 !important; }

.has-pause-azure-10-border {
  border: solid 1rem #03a9f4 !important; }

.has-pause-azure-10-border-pulsate {
  border: solid 1rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-10-border-left {
  border-left: solid 1rem #03a9f4 !important; }

.has-pause-azure-10-border-right {
  border-right: solid 1rem #03a9f4 !important; }

.has-pause-azure-10-border-top {
  border-top: solid 1rem #03a9f4 !important; }

.has-pause-azure-10-border-bottom {
  border-bottom: solid 1rem #03a9f4 !important; }

.has-pause-azure-11-border {
  border: solid 1.1rem #03a9f4 !important; }

.has-pause-azure-11-border-pulsate {
  border: solid 1.1rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-11-border-left {
  border-left: solid 1.1rem #03a9f4 !important; }

.has-pause-azure-11-border-right {
  border-right: solid 1.1rem #03a9f4 !important; }

.has-pause-azure-11-border-top {
  border-top: solid 1.1rem #03a9f4 !important; }

.has-pause-azure-11-border-bottom {
  border-bottom: solid 1.1rem #03a9f4 !important; }

.has-pause-azure-12-border {
  border: solid 1.2rem #03a9f4 !important; }

.has-pause-azure-12-border-pulsate {
  border: solid 1.2rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-12-border-left {
  border-left: solid 1.2rem #03a9f4 !important; }

.has-pause-azure-12-border-right {
  border-right: solid 1.2rem #03a9f4 !important; }

.has-pause-azure-12-border-top {
  border-top: solid 1.2rem #03a9f4 !important; }

.has-pause-azure-12-border-bottom {
  border-bottom: solid 1.2rem #03a9f4 !important; }

.has-pause-azure-13-border {
  border: solid 1.3rem #03a9f4 !important; }

.has-pause-azure-13-border-pulsate {
  border: solid 1.3rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-13-border-left {
  border-left: solid 1.3rem #03a9f4 !important; }

.has-pause-azure-13-border-right {
  border-right: solid 1.3rem #03a9f4 !important; }

.has-pause-azure-13-border-top {
  border-top: solid 1.3rem #03a9f4 !important; }

.has-pause-azure-13-border-bottom {
  border-bottom: solid 1.3rem #03a9f4 !important; }

.has-pause-azure-14-border {
  border: solid 1.4rem #03a9f4 !important; }

.has-pause-azure-14-border-pulsate {
  border: solid 1.4rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-14-border-left {
  border-left: solid 1.4rem #03a9f4 !important; }

.has-pause-azure-14-border-right {
  border-right: solid 1.4rem #03a9f4 !important; }

.has-pause-azure-14-border-top {
  border-top: solid 1.4rem #03a9f4 !important; }

.has-pause-azure-14-border-bottom {
  border-bottom: solid 1.4rem #03a9f4 !important; }

.has-pause-azure-15-border {
  border: solid 1.5rem #03a9f4 !important; }

.has-pause-azure-15-border-pulsate {
  border: solid 1.5rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-15-border-left {
  border-left: solid 1.5rem #03a9f4 !important; }

.has-pause-azure-15-border-right {
  border-right: solid 1.5rem #03a9f4 !important; }

.has-pause-azure-15-border-top {
  border-top: solid 1.5rem #03a9f4 !important; }

.has-pause-azure-15-border-bottom {
  border-bottom: solid 1.5rem #03a9f4 !important; }

.has-pause-azure-16-border {
  border: solid 1.6rem #03a9f4 !important; }

.has-pause-azure-16-border-pulsate {
  border: solid 1.6rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-16-border-left {
  border-left: solid 1.6rem #03a9f4 !important; }

.has-pause-azure-16-border-right {
  border-right: solid 1.6rem #03a9f4 !important; }

.has-pause-azure-16-border-top {
  border-top: solid 1.6rem #03a9f4 !important; }

.has-pause-azure-16-border-bottom {
  border-bottom: solid 1.6rem #03a9f4 !important; }

.has-pause-azure-17-border {
  border: solid 1.7rem #03a9f4 !important; }

.has-pause-azure-17-border-pulsate {
  border: solid 1.7rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-17-border-left {
  border-left: solid 1.7rem #03a9f4 !important; }

.has-pause-azure-17-border-right {
  border-right: solid 1.7rem #03a9f4 !important; }

.has-pause-azure-17-border-top {
  border-top: solid 1.7rem #03a9f4 !important; }

.has-pause-azure-17-border-bottom {
  border-bottom: solid 1.7rem #03a9f4 !important; }

.has-pause-azure-18-border {
  border: solid 1.8rem #03a9f4 !important; }

.has-pause-azure-18-border-pulsate {
  border: solid 1.8rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-18-border-left {
  border-left: solid 1.8rem #03a9f4 !important; }

.has-pause-azure-18-border-right {
  border-right: solid 1.8rem #03a9f4 !important; }

.has-pause-azure-18-border-top {
  border-top: solid 1.8rem #03a9f4 !important; }

.has-pause-azure-18-border-bottom {
  border-bottom: solid 1.8rem #03a9f4 !important; }

.has-pause-azure-19-border {
  border: solid 1.9rem #03a9f4 !important; }

.has-pause-azure-19-border-pulsate {
  border: solid 1.9rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-19-border-left {
  border-left: solid 1.9rem #03a9f4 !important; }

.has-pause-azure-19-border-right {
  border-right: solid 1.9rem #03a9f4 !important; }

.has-pause-azure-19-border-top {
  border-top: solid 1.9rem #03a9f4 !important; }

.has-pause-azure-19-border-bottom {
  border-bottom: solid 1.9rem #03a9f4 !important; }

.has-pause-azure-20-border {
  border: solid 2rem #03a9f4 !important; }

.has-pause-azure-20-border-pulsate {
  border: solid 2rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-20-border-left {
  border-left: solid 2rem #03a9f4 !important; }

.has-pause-azure-20-border-right {
  border-right: solid 2rem #03a9f4 !important; }

.has-pause-azure-20-border-top {
  border-top: solid 2rem #03a9f4 !important; }

.has-pause-azure-20-border-bottom {
  border-bottom: solid 2rem #03a9f4 !important; }

.has-pause-azure-21-border {
  border: solid 2.1rem #03a9f4 !important; }

.has-pause-azure-21-border-pulsate {
  border: solid 2.1rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-21-border-left {
  border-left: solid 2.1rem #03a9f4 !important; }

.has-pause-azure-21-border-right {
  border-right: solid 2.1rem #03a9f4 !important; }

.has-pause-azure-21-border-top {
  border-top: solid 2.1rem #03a9f4 !important; }

.has-pause-azure-21-border-bottom {
  border-bottom: solid 2.1rem #03a9f4 !important; }

.has-pause-azure-24-border {
  border: solid 2.4rem #03a9f4 !important; }

.has-pause-azure-24-border-pulsate {
  border: solid 2.4rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-24-border-left {
  border-left: solid 2.4rem #03a9f4 !important; }

.has-pause-azure-24-border-right {
  border-right: solid 2.4rem #03a9f4 !important; }

.has-pause-azure-24-border-top {
  border-top: solid 2.4rem #03a9f4 !important; }

.has-pause-azure-24-border-bottom {
  border-bottom: solid 2.4rem #03a9f4 !important; }

.has-pause-azure-30-border {
  border: solid 3rem #03a9f4 !important; }

.has-pause-azure-30-border-pulsate {
  border: solid 3rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-30-border-left {
  border-left: solid 3rem #03a9f4 !important; }

.has-pause-azure-30-border-right {
  border-right: solid 3rem #03a9f4 !important; }

.has-pause-azure-30-border-top {
  border-top: solid 3rem #03a9f4 !important; }

.has-pause-azure-30-border-bottom {
  border-bottom: solid 3rem #03a9f4 !important; }

.has-pause-azure-40-border {
  border: solid 4rem #03a9f4 !important; }

.has-pause-azure-40-border-pulsate {
  border: solid 4rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-40-border-left {
  border-left: solid 4rem #03a9f4 !important; }

.has-pause-azure-40-border-right {
  border-right: solid 4rem #03a9f4 !important; }

.has-pause-azure-40-border-top {
  border-top: solid 4rem #03a9f4 !important; }

.has-pause-azure-40-border-bottom {
  border-bottom: solid 4rem #03a9f4 !important; }

.has-pause-azure-50-border {
  border: solid 5rem #03a9f4 !important; }

.has-pause-azure-50-border-pulsate {
  border: solid 5rem #03a9f4 !important;
  animation: pause-azure-border-pulsate 2s infinite; }

.has-pause-azure-50-border-left {
  border-left: solid 5rem #03a9f4 !important; }

.has-pause-azure-50-border-right {
  border-right: solid 5rem #03a9f4 !important; }

.has-pause-azure-50-border-top {
  border-top: solid 5rem #03a9f4 !important; }

.has-pause-azure-50-border-bottom {
  border-bottom: solid 5rem #03a9f4 !important; }

@keyframes production-blue-border-pulsate {
  0% {
    border-color: #2979ff; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #2979ff; } }

.has-production-blue-border-color {
  border-color: #2979ff; }

.has-production-blue-border {
  border: solid 0.1rem #2979ff; }

.has-production-blue-border-pulsate {
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-border-left {
  border-left: solid 0.1rem #2979ff; }

.has-production-blue-border-right {
  border-right: solid 0.1rem #2979ff; }

.has-production-blue-border-top {
  border-top: solid 0.1rem #2979ff; }

.has-production-blue-border-bottom {
  border-bottom: solid 0.1rem #2979ff; }

.has-production-blue-1-border {
  border: solid 0.1rem #2979ff !important; }

.has-production-blue-1-border-pulsate {
  border: solid 0.1rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-1-border-left {
  border-left: solid 0.1rem #2979ff !important; }

.has-production-blue-1-border-right {
  border-right: solid 0.1rem #2979ff !important; }

.has-production-blue-1-border-top {
  border-top: solid 0.1rem #2979ff !important; }

.has-production-blue-1-border-bottom {
  border-bottom: solid 0.1rem #2979ff !important; }

.has-production-blue-2-border {
  border: solid 0.2rem #2979ff !important; }

.has-production-blue-2-border-pulsate {
  border: solid 0.2rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-2-border-left {
  border-left: solid 0.2rem #2979ff !important; }

.has-production-blue-2-border-right {
  border-right: solid 0.2rem #2979ff !important; }

.has-production-blue-2-border-top {
  border-top: solid 0.2rem #2979ff !important; }

.has-production-blue-2-border-bottom {
  border-bottom: solid 0.2rem #2979ff !important; }

.has-production-blue-3-border {
  border: solid 0.3rem #2979ff !important; }

.has-production-blue-3-border-pulsate {
  border: solid 0.3rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-3-border-left {
  border-left: solid 0.3rem #2979ff !important; }

.has-production-blue-3-border-right {
  border-right: solid 0.3rem #2979ff !important; }

.has-production-blue-3-border-top {
  border-top: solid 0.3rem #2979ff !important; }

.has-production-blue-3-border-bottom {
  border-bottom: solid 0.3rem #2979ff !important; }

.has-production-blue-4-border {
  border: solid 0.4rem #2979ff !important; }

.has-production-blue-4-border-pulsate {
  border: solid 0.4rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-4-border-left {
  border-left: solid 0.4rem #2979ff !important; }

.has-production-blue-4-border-right {
  border-right: solid 0.4rem #2979ff !important; }

.has-production-blue-4-border-top {
  border-top: solid 0.4rem #2979ff !important; }

.has-production-blue-4-border-bottom {
  border-bottom: solid 0.4rem #2979ff !important; }

.has-production-blue-5-border {
  border: solid 0.5rem #2979ff !important; }

.has-production-blue-5-border-pulsate {
  border: solid 0.5rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-5-border-left {
  border-left: solid 0.5rem #2979ff !important; }

.has-production-blue-5-border-right {
  border-right: solid 0.5rem #2979ff !important; }

.has-production-blue-5-border-top {
  border-top: solid 0.5rem #2979ff !important; }

.has-production-blue-5-border-bottom {
  border-bottom: solid 0.5rem #2979ff !important; }

.has-production-blue-6-border {
  border: solid 0.6rem #2979ff !important; }

.has-production-blue-6-border-pulsate {
  border: solid 0.6rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-6-border-left {
  border-left: solid 0.6rem #2979ff !important; }

.has-production-blue-6-border-right {
  border-right: solid 0.6rem #2979ff !important; }

.has-production-blue-6-border-top {
  border-top: solid 0.6rem #2979ff !important; }

.has-production-blue-6-border-bottom {
  border-bottom: solid 0.6rem #2979ff !important; }

.has-production-blue-7-border {
  border: solid 0.7rem #2979ff !important; }

.has-production-blue-7-border-pulsate {
  border: solid 0.7rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-7-border-left {
  border-left: solid 0.7rem #2979ff !important; }

.has-production-blue-7-border-right {
  border-right: solid 0.7rem #2979ff !important; }

.has-production-blue-7-border-top {
  border-top: solid 0.7rem #2979ff !important; }

.has-production-blue-7-border-bottom {
  border-bottom: solid 0.7rem #2979ff !important; }

.has-production-blue-8-border {
  border: solid 0.8rem #2979ff !important; }

.has-production-blue-8-border-pulsate {
  border: solid 0.8rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-8-border-left {
  border-left: solid 0.8rem #2979ff !important; }

.has-production-blue-8-border-right {
  border-right: solid 0.8rem #2979ff !important; }

.has-production-blue-8-border-top {
  border-top: solid 0.8rem #2979ff !important; }

.has-production-blue-8-border-bottom {
  border-bottom: solid 0.8rem #2979ff !important; }

.has-production-blue-9-border {
  border: solid 0.9rem #2979ff !important; }

.has-production-blue-9-border-pulsate {
  border: solid 0.9rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-9-border-left {
  border-left: solid 0.9rem #2979ff !important; }

.has-production-blue-9-border-right {
  border-right: solid 0.9rem #2979ff !important; }

.has-production-blue-9-border-top {
  border-top: solid 0.9rem #2979ff !important; }

.has-production-blue-9-border-bottom {
  border-bottom: solid 0.9rem #2979ff !important; }

.has-production-blue-10-border {
  border: solid 1rem #2979ff !important; }

.has-production-blue-10-border-pulsate {
  border: solid 1rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-10-border-left {
  border-left: solid 1rem #2979ff !important; }

.has-production-blue-10-border-right {
  border-right: solid 1rem #2979ff !important; }

.has-production-blue-10-border-top {
  border-top: solid 1rem #2979ff !important; }

.has-production-blue-10-border-bottom {
  border-bottom: solid 1rem #2979ff !important; }

.has-production-blue-11-border {
  border: solid 1.1rem #2979ff !important; }

.has-production-blue-11-border-pulsate {
  border: solid 1.1rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-11-border-left {
  border-left: solid 1.1rem #2979ff !important; }

.has-production-blue-11-border-right {
  border-right: solid 1.1rem #2979ff !important; }

.has-production-blue-11-border-top {
  border-top: solid 1.1rem #2979ff !important; }

.has-production-blue-11-border-bottom {
  border-bottom: solid 1.1rem #2979ff !important; }

.has-production-blue-12-border {
  border: solid 1.2rem #2979ff !important; }

.has-production-blue-12-border-pulsate {
  border: solid 1.2rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-12-border-left {
  border-left: solid 1.2rem #2979ff !important; }

.has-production-blue-12-border-right {
  border-right: solid 1.2rem #2979ff !important; }

.has-production-blue-12-border-top {
  border-top: solid 1.2rem #2979ff !important; }

.has-production-blue-12-border-bottom {
  border-bottom: solid 1.2rem #2979ff !important; }

.has-production-blue-13-border {
  border: solid 1.3rem #2979ff !important; }

.has-production-blue-13-border-pulsate {
  border: solid 1.3rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-13-border-left {
  border-left: solid 1.3rem #2979ff !important; }

.has-production-blue-13-border-right {
  border-right: solid 1.3rem #2979ff !important; }

.has-production-blue-13-border-top {
  border-top: solid 1.3rem #2979ff !important; }

.has-production-blue-13-border-bottom {
  border-bottom: solid 1.3rem #2979ff !important; }

.has-production-blue-14-border {
  border: solid 1.4rem #2979ff !important; }

.has-production-blue-14-border-pulsate {
  border: solid 1.4rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-14-border-left {
  border-left: solid 1.4rem #2979ff !important; }

.has-production-blue-14-border-right {
  border-right: solid 1.4rem #2979ff !important; }

.has-production-blue-14-border-top {
  border-top: solid 1.4rem #2979ff !important; }

.has-production-blue-14-border-bottom {
  border-bottom: solid 1.4rem #2979ff !important; }

.has-production-blue-15-border {
  border: solid 1.5rem #2979ff !important; }

.has-production-blue-15-border-pulsate {
  border: solid 1.5rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-15-border-left {
  border-left: solid 1.5rem #2979ff !important; }

.has-production-blue-15-border-right {
  border-right: solid 1.5rem #2979ff !important; }

.has-production-blue-15-border-top {
  border-top: solid 1.5rem #2979ff !important; }

.has-production-blue-15-border-bottom {
  border-bottom: solid 1.5rem #2979ff !important; }

.has-production-blue-16-border {
  border: solid 1.6rem #2979ff !important; }

.has-production-blue-16-border-pulsate {
  border: solid 1.6rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-16-border-left {
  border-left: solid 1.6rem #2979ff !important; }

.has-production-blue-16-border-right {
  border-right: solid 1.6rem #2979ff !important; }

.has-production-blue-16-border-top {
  border-top: solid 1.6rem #2979ff !important; }

.has-production-blue-16-border-bottom {
  border-bottom: solid 1.6rem #2979ff !important; }

.has-production-blue-17-border {
  border: solid 1.7rem #2979ff !important; }

.has-production-blue-17-border-pulsate {
  border: solid 1.7rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-17-border-left {
  border-left: solid 1.7rem #2979ff !important; }

.has-production-blue-17-border-right {
  border-right: solid 1.7rem #2979ff !important; }

.has-production-blue-17-border-top {
  border-top: solid 1.7rem #2979ff !important; }

.has-production-blue-17-border-bottom {
  border-bottom: solid 1.7rem #2979ff !important; }

.has-production-blue-18-border {
  border: solid 1.8rem #2979ff !important; }

.has-production-blue-18-border-pulsate {
  border: solid 1.8rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-18-border-left {
  border-left: solid 1.8rem #2979ff !important; }

.has-production-blue-18-border-right {
  border-right: solid 1.8rem #2979ff !important; }

.has-production-blue-18-border-top {
  border-top: solid 1.8rem #2979ff !important; }

.has-production-blue-18-border-bottom {
  border-bottom: solid 1.8rem #2979ff !important; }

.has-production-blue-19-border {
  border: solid 1.9rem #2979ff !important; }

.has-production-blue-19-border-pulsate {
  border: solid 1.9rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-19-border-left {
  border-left: solid 1.9rem #2979ff !important; }

.has-production-blue-19-border-right {
  border-right: solid 1.9rem #2979ff !important; }

.has-production-blue-19-border-top {
  border-top: solid 1.9rem #2979ff !important; }

.has-production-blue-19-border-bottom {
  border-bottom: solid 1.9rem #2979ff !important; }

.has-production-blue-20-border {
  border: solid 2rem #2979ff !important; }

.has-production-blue-20-border-pulsate {
  border: solid 2rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-20-border-left {
  border-left: solid 2rem #2979ff !important; }

.has-production-blue-20-border-right {
  border-right: solid 2rem #2979ff !important; }

.has-production-blue-20-border-top {
  border-top: solid 2rem #2979ff !important; }

.has-production-blue-20-border-bottom {
  border-bottom: solid 2rem #2979ff !important; }

.has-production-blue-21-border {
  border: solid 2.1rem #2979ff !important; }

.has-production-blue-21-border-pulsate {
  border: solid 2.1rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-21-border-left {
  border-left: solid 2.1rem #2979ff !important; }

.has-production-blue-21-border-right {
  border-right: solid 2.1rem #2979ff !important; }

.has-production-blue-21-border-top {
  border-top: solid 2.1rem #2979ff !important; }

.has-production-blue-21-border-bottom {
  border-bottom: solid 2.1rem #2979ff !important; }

.has-production-blue-24-border {
  border: solid 2.4rem #2979ff !important; }

.has-production-blue-24-border-pulsate {
  border: solid 2.4rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-24-border-left {
  border-left: solid 2.4rem #2979ff !important; }

.has-production-blue-24-border-right {
  border-right: solid 2.4rem #2979ff !important; }

.has-production-blue-24-border-top {
  border-top: solid 2.4rem #2979ff !important; }

.has-production-blue-24-border-bottom {
  border-bottom: solid 2.4rem #2979ff !important; }

.has-production-blue-30-border {
  border: solid 3rem #2979ff !important; }

.has-production-blue-30-border-pulsate {
  border: solid 3rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-30-border-left {
  border-left: solid 3rem #2979ff !important; }

.has-production-blue-30-border-right {
  border-right: solid 3rem #2979ff !important; }

.has-production-blue-30-border-top {
  border-top: solid 3rem #2979ff !important; }

.has-production-blue-30-border-bottom {
  border-bottom: solid 3rem #2979ff !important; }

.has-production-blue-40-border {
  border: solid 4rem #2979ff !important; }

.has-production-blue-40-border-pulsate {
  border: solid 4rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-40-border-left {
  border-left: solid 4rem #2979ff !important; }

.has-production-blue-40-border-right {
  border-right: solid 4rem #2979ff !important; }

.has-production-blue-40-border-top {
  border-top: solid 4rem #2979ff !important; }

.has-production-blue-40-border-bottom {
  border-bottom: solid 4rem #2979ff !important; }

.has-production-blue-50-border {
  border: solid 5rem #2979ff !important; }

.has-production-blue-50-border-pulsate {
  border: solid 5rem #2979ff !important;
  animation: production-blue-border-pulsate 2s infinite; }

.has-production-blue-50-border-left {
  border-left: solid 5rem #2979ff !important; }

.has-production-blue-50-border-right {
  border-right: solid 5rem #2979ff !important; }

.has-production-blue-50-border-top {
  border-top: solid 5rem #2979ff !important; }

.has-production-blue-50-border-bottom {
  border-bottom: solid 5rem #2979ff !important; }

@keyframes production-blue-20-border-pulsate {
  0% {
    border-color: #2979ff; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #2979ff; } }

.has-production-blue-20-border-color {
  border-color: rgba(41, 121, 255, 0.2); }

.has-production-blue-20-border {
  border: solid 0.1rem rgba(41, 121, 255, 0.2); }

.has-production-blue-20-border-pulsate {
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-border-left {
  border-left: solid 0.1rem rgba(41, 121, 255, 0.2); }

.has-production-blue-20-border-right {
  border-right: solid 0.1rem rgba(41, 121, 255, 0.2); }

.has-production-blue-20-border-top {
  border-top: solid 0.1rem rgba(41, 121, 255, 0.2); }

.has-production-blue-20-border-bottom {
  border-bottom: solid 0.1rem rgba(41, 121, 255, 0.2); }

.has-production-blue-20-1-border {
  border: solid 0.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-1-border-pulsate {
  border: solid 0.1rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-1-border-left {
  border-left: solid 0.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-1-border-right {
  border-right: solid 0.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-1-border-top {
  border-top: solid 0.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-1-border-bottom {
  border-bottom: solid 0.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-2-border {
  border: solid 0.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-2-border-pulsate {
  border: solid 0.2rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-2-border-left {
  border-left: solid 0.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-2-border-right {
  border-right: solid 0.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-2-border-top {
  border-top: solid 0.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-2-border-bottom {
  border-bottom: solid 0.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-3-border {
  border: solid 0.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-3-border-pulsate {
  border: solid 0.3rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-3-border-left {
  border-left: solid 0.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-3-border-right {
  border-right: solid 0.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-3-border-top {
  border-top: solid 0.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-3-border-bottom {
  border-bottom: solid 0.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-4-border {
  border: solid 0.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-4-border-pulsate {
  border: solid 0.4rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-4-border-left {
  border-left: solid 0.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-4-border-right {
  border-right: solid 0.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-4-border-top {
  border-top: solid 0.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-4-border-bottom {
  border-bottom: solid 0.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-5-border {
  border: solid 0.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-5-border-pulsate {
  border: solid 0.5rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-5-border-left {
  border-left: solid 0.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-5-border-right {
  border-right: solid 0.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-5-border-top {
  border-top: solid 0.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-5-border-bottom {
  border-bottom: solid 0.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-6-border {
  border: solid 0.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-6-border-pulsate {
  border: solid 0.6rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-6-border-left {
  border-left: solid 0.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-6-border-right {
  border-right: solid 0.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-6-border-top {
  border-top: solid 0.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-6-border-bottom {
  border-bottom: solid 0.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-7-border {
  border: solid 0.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-7-border-pulsate {
  border: solid 0.7rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-7-border-left {
  border-left: solid 0.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-7-border-right {
  border-right: solid 0.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-7-border-top {
  border-top: solid 0.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-7-border-bottom {
  border-bottom: solid 0.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-8-border {
  border: solid 0.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-8-border-pulsate {
  border: solid 0.8rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-8-border-left {
  border-left: solid 0.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-8-border-right {
  border-right: solid 0.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-8-border-top {
  border-top: solid 0.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-8-border-bottom {
  border-bottom: solid 0.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-9-border {
  border: solid 0.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-9-border-pulsate {
  border: solid 0.9rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-9-border-left {
  border-left: solid 0.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-9-border-right {
  border-right: solid 0.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-9-border-top {
  border-top: solid 0.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-9-border-bottom {
  border-bottom: solid 0.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-10-border {
  border: solid 1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-10-border-pulsate {
  border: solid 1rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-10-border-left {
  border-left: solid 1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-10-border-right {
  border-right: solid 1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-10-border-top {
  border-top: solid 1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-10-border-bottom {
  border-bottom: solid 1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-11-border {
  border: solid 1.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-11-border-pulsate {
  border: solid 1.1rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-11-border-left {
  border-left: solid 1.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-11-border-right {
  border-right: solid 1.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-11-border-top {
  border-top: solid 1.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-11-border-bottom {
  border-bottom: solid 1.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-12-border {
  border: solid 1.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-12-border-pulsate {
  border: solid 1.2rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-12-border-left {
  border-left: solid 1.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-12-border-right {
  border-right: solid 1.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-12-border-top {
  border-top: solid 1.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-12-border-bottom {
  border-bottom: solid 1.2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-13-border {
  border: solid 1.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-13-border-pulsate {
  border: solid 1.3rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-13-border-left {
  border-left: solid 1.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-13-border-right {
  border-right: solid 1.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-13-border-top {
  border-top: solid 1.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-13-border-bottom {
  border-bottom: solid 1.3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-14-border {
  border: solid 1.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-14-border-pulsate {
  border: solid 1.4rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-14-border-left {
  border-left: solid 1.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-14-border-right {
  border-right: solid 1.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-14-border-top {
  border-top: solid 1.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-14-border-bottom {
  border-bottom: solid 1.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-15-border {
  border: solid 1.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-15-border-pulsate {
  border: solid 1.5rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-15-border-left {
  border-left: solid 1.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-15-border-right {
  border-right: solid 1.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-15-border-top {
  border-top: solid 1.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-15-border-bottom {
  border-bottom: solid 1.5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-16-border {
  border: solid 1.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-16-border-pulsate {
  border: solid 1.6rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-16-border-left {
  border-left: solid 1.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-16-border-right {
  border-right: solid 1.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-16-border-top {
  border-top: solid 1.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-16-border-bottom {
  border-bottom: solid 1.6rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-17-border {
  border: solid 1.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-17-border-pulsate {
  border: solid 1.7rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-17-border-left {
  border-left: solid 1.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-17-border-right {
  border-right: solid 1.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-17-border-top {
  border-top: solid 1.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-17-border-bottom {
  border-bottom: solid 1.7rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-18-border {
  border: solid 1.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-18-border-pulsate {
  border: solid 1.8rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-18-border-left {
  border-left: solid 1.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-18-border-right {
  border-right: solid 1.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-18-border-top {
  border-top: solid 1.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-18-border-bottom {
  border-bottom: solid 1.8rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-19-border {
  border: solid 1.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-19-border-pulsate {
  border: solid 1.9rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-19-border-left {
  border-left: solid 1.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-19-border-right {
  border-right: solid 1.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-19-border-top {
  border-top: solid 1.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-19-border-bottom {
  border-bottom: solid 1.9rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-20-border {
  border: solid 2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-20-border-pulsate {
  border: solid 2rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-20-border-left {
  border-left: solid 2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-20-border-right {
  border-right: solid 2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-20-border-top {
  border-top: solid 2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-20-border-bottom {
  border-bottom: solid 2rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-21-border {
  border: solid 2.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-21-border-pulsate {
  border: solid 2.1rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-21-border-left {
  border-left: solid 2.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-21-border-right {
  border-right: solid 2.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-21-border-top {
  border-top: solid 2.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-21-border-bottom {
  border-bottom: solid 2.1rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-24-border {
  border: solid 2.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-24-border-pulsate {
  border: solid 2.4rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-24-border-left {
  border-left: solid 2.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-24-border-right {
  border-right: solid 2.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-24-border-top {
  border-top: solid 2.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-24-border-bottom {
  border-bottom: solid 2.4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-30-border {
  border: solid 3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-30-border-pulsate {
  border: solid 3rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-30-border-left {
  border-left: solid 3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-30-border-right {
  border-right: solid 3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-30-border-top {
  border-top: solid 3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-30-border-bottom {
  border-bottom: solid 3rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-40-border {
  border: solid 4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-40-border-pulsate {
  border: solid 4rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-40-border-left {
  border-left: solid 4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-40-border-right {
  border-right: solid 4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-40-border-top {
  border-top: solid 4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-40-border-bottom {
  border-bottom: solid 4rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-50-border {
  border: solid 5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-50-border-pulsate {
  border: solid 5rem rgba(41, 121, 255, 0.2) !important;
  animation: production-blue-20-border-pulsate 2s infinite; }

.has-production-blue-20-50-border-left {
  border-left: solid 5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-50-border-right {
  border-right: solid 5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-50-border-top {
  border-top: solid 5rem rgba(41, 121, 255, 0.2) !important; }

.has-production-blue-20-50-border-bottom {
  border-bottom: solid 5rem rgba(41, 121, 255, 0.2) !important; }

@keyframes material-violet-border-pulsate {
  0% {
    border-color: #aa00ff; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #aa00ff; } }

.has-material-violet-border-color {
  border-color: #aa00ff; }

.has-material-violet-border {
  border: solid 0.1rem #aa00ff; }

.has-material-violet-border-pulsate {
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-border-left {
  border-left: solid 0.1rem #aa00ff; }

.has-material-violet-border-right {
  border-right: solid 0.1rem #aa00ff; }

.has-material-violet-border-top {
  border-top: solid 0.1rem #aa00ff; }

.has-material-violet-border-bottom {
  border-bottom: solid 0.1rem #aa00ff; }

.has-material-violet-1-border {
  border: solid 0.1rem #aa00ff !important; }

.has-material-violet-1-border-pulsate {
  border: solid 0.1rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-1-border-left {
  border-left: solid 0.1rem #aa00ff !important; }

.has-material-violet-1-border-right {
  border-right: solid 0.1rem #aa00ff !important; }

.has-material-violet-1-border-top {
  border-top: solid 0.1rem #aa00ff !important; }

.has-material-violet-1-border-bottom {
  border-bottom: solid 0.1rem #aa00ff !important; }

.has-material-violet-2-border {
  border: solid 0.2rem #aa00ff !important; }

.has-material-violet-2-border-pulsate {
  border: solid 0.2rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-2-border-left {
  border-left: solid 0.2rem #aa00ff !important; }

.has-material-violet-2-border-right {
  border-right: solid 0.2rem #aa00ff !important; }

.has-material-violet-2-border-top {
  border-top: solid 0.2rem #aa00ff !important; }

.has-material-violet-2-border-bottom {
  border-bottom: solid 0.2rem #aa00ff !important; }

.has-material-violet-3-border {
  border: solid 0.3rem #aa00ff !important; }

.has-material-violet-3-border-pulsate {
  border: solid 0.3rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-3-border-left {
  border-left: solid 0.3rem #aa00ff !important; }

.has-material-violet-3-border-right {
  border-right: solid 0.3rem #aa00ff !important; }

.has-material-violet-3-border-top {
  border-top: solid 0.3rem #aa00ff !important; }

.has-material-violet-3-border-bottom {
  border-bottom: solid 0.3rem #aa00ff !important; }

.has-material-violet-4-border {
  border: solid 0.4rem #aa00ff !important; }

.has-material-violet-4-border-pulsate {
  border: solid 0.4rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-4-border-left {
  border-left: solid 0.4rem #aa00ff !important; }

.has-material-violet-4-border-right {
  border-right: solid 0.4rem #aa00ff !important; }

.has-material-violet-4-border-top {
  border-top: solid 0.4rem #aa00ff !important; }

.has-material-violet-4-border-bottom {
  border-bottom: solid 0.4rem #aa00ff !important; }

.has-material-violet-5-border {
  border: solid 0.5rem #aa00ff !important; }

.has-material-violet-5-border-pulsate {
  border: solid 0.5rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-5-border-left {
  border-left: solid 0.5rem #aa00ff !important; }

.has-material-violet-5-border-right {
  border-right: solid 0.5rem #aa00ff !important; }

.has-material-violet-5-border-top {
  border-top: solid 0.5rem #aa00ff !important; }

.has-material-violet-5-border-bottom {
  border-bottom: solid 0.5rem #aa00ff !important; }

.has-material-violet-6-border {
  border: solid 0.6rem #aa00ff !important; }

.has-material-violet-6-border-pulsate {
  border: solid 0.6rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-6-border-left {
  border-left: solid 0.6rem #aa00ff !important; }

.has-material-violet-6-border-right {
  border-right: solid 0.6rem #aa00ff !important; }

.has-material-violet-6-border-top {
  border-top: solid 0.6rem #aa00ff !important; }

.has-material-violet-6-border-bottom {
  border-bottom: solid 0.6rem #aa00ff !important; }

.has-material-violet-7-border {
  border: solid 0.7rem #aa00ff !important; }

.has-material-violet-7-border-pulsate {
  border: solid 0.7rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-7-border-left {
  border-left: solid 0.7rem #aa00ff !important; }

.has-material-violet-7-border-right {
  border-right: solid 0.7rem #aa00ff !important; }

.has-material-violet-7-border-top {
  border-top: solid 0.7rem #aa00ff !important; }

.has-material-violet-7-border-bottom {
  border-bottom: solid 0.7rem #aa00ff !important; }

.has-material-violet-8-border {
  border: solid 0.8rem #aa00ff !important; }

.has-material-violet-8-border-pulsate {
  border: solid 0.8rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-8-border-left {
  border-left: solid 0.8rem #aa00ff !important; }

.has-material-violet-8-border-right {
  border-right: solid 0.8rem #aa00ff !important; }

.has-material-violet-8-border-top {
  border-top: solid 0.8rem #aa00ff !important; }

.has-material-violet-8-border-bottom {
  border-bottom: solid 0.8rem #aa00ff !important; }

.has-material-violet-9-border {
  border: solid 0.9rem #aa00ff !important; }

.has-material-violet-9-border-pulsate {
  border: solid 0.9rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-9-border-left {
  border-left: solid 0.9rem #aa00ff !important; }

.has-material-violet-9-border-right {
  border-right: solid 0.9rem #aa00ff !important; }

.has-material-violet-9-border-top {
  border-top: solid 0.9rem #aa00ff !important; }

.has-material-violet-9-border-bottom {
  border-bottom: solid 0.9rem #aa00ff !important; }

.has-material-violet-10-border {
  border: solid 1rem #aa00ff !important; }

.has-material-violet-10-border-pulsate {
  border: solid 1rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-10-border-left {
  border-left: solid 1rem #aa00ff !important; }

.has-material-violet-10-border-right {
  border-right: solid 1rem #aa00ff !important; }

.has-material-violet-10-border-top {
  border-top: solid 1rem #aa00ff !important; }

.has-material-violet-10-border-bottom {
  border-bottom: solid 1rem #aa00ff !important; }

.has-material-violet-11-border {
  border: solid 1.1rem #aa00ff !important; }

.has-material-violet-11-border-pulsate {
  border: solid 1.1rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-11-border-left {
  border-left: solid 1.1rem #aa00ff !important; }

.has-material-violet-11-border-right {
  border-right: solid 1.1rem #aa00ff !important; }

.has-material-violet-11-border-top {
  border-top: solid 1.1rem #aa00ff !important; }

.has-material-violet-11-border-bottom {
  border-bottom: solid 1.1rem #aa00ff !important; }

.has-material-violet-12-border {
  border: solid 1.2rem #aa00ff !important; }

.has-material-violet-12-border-pulsate {
  border: solid 1.2rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-12-border-left {
  border-left: solid 1.2rem #aa00ff !important; }

.has-material-violet-12-border-right {
  border-right: solid 1.2rem #aa00ff !important; }

.has-material-violet-12-border-top {
  border-top: solid 1.2rem #aa00ff !important; }

.has-material-violet-12-border-bottom {
  border-bottom: solid 1.2rem #aa00ff !important; }

.has-material-violet-13-border {
  border: solid 1.3rem #aa00ff !important; }

.has-material-violet-13-border-pulsate {
  border: solid 1.3rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-13-border-left {
  border-left: solid 1.3rem #aa00ff !important; }

.has-material-violet-13-border-right {
  border-right: solid 1.3rem #aa00ff !important; }

.has-material-violet-13-border-top {
  border-top: solid 1.3rem #aa00ff !important; }

.has-material-violet-13-border-bottom {
  border-bottom: solid 1.3rem #aa00ff !important; }

.has-material-violet-14-border {
  border: solid 1.4rem #aa00ff !important; }

.has-material-violet-14-border-pulsate {
  border: solid 1.4rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-14-border-left {
  border-left: solid 1.4rem #aa00ff !important; }

.has-material-violet-14-border-right {
  border-right: solid 1.4rem #aa00ff !important; }

.has-material-violet-14-border-top {
  border-top: solid 1.4rem #aa00ff !important; }

.has-material-violet-14-border-bottom {
  border-bottom: solid 1.4rem #aa00ff !important; }

.has-material-violet-15-border {
  border: solid 1.5rem #aa00ff !important; }

.has-material-violet-15-border-pulsate {
  border: solid 1.5rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-15-border-left {
  border-left: solid 1.5rem #aa00ff !important; }

.has-material-violet-15-border-right {
  border-right: solid 1.5rem #aa00ff !important; }

.has-material-violet-15-border-top {
  border-top: solid 1.5rem #aa00ff !important; }

.has-material-violet-15-border-bottom {
  border-bottom: solid 1.5rem #aa00ff !important; }

.has-material-violet-16-border {
  border: solid 1.6rem #aa00ff !important; }

.has-material-violet-16-border-pulsate {
  border: solid 1.6rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-16-border-left {
  border-left: solid 1.6rem #aa00ff !important; }

.has-material-violet-16-border-right {
  border-right: solid 1.6rem #aa00ff !important; }

.has-material-violet-16-border-top {
  border-top: solid 1.6rem #aa00ff !important; }

.has-material-violet-16-border-bottom {
  border-bottom: solid 1.6rem #aa00ff !important; }

.has-material-violet-17-border {
  border: solid 1.7rem #aa00ff !important; }

.has-material-violet-17-border-pulsate {
  border: solid 1.7rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-17-border-left {
  border-left: solid 1.7rem #aa00ff !important; }

.has-material-violet-17-border-right {
  border-right: solid 1.7rem #aa00ff !important; }

.has-material-violet-17-border-top {
  border-top: solid 1.7rem #aa00ff !important; }

.has-material-violet-17-border-bottom {
  border-bottom: solid 1.7rem #aa00ff !important; }

.has-material-violet-18-border {
  border: solid 1.8rem #aa00ff !important; }

.has-material-violet-18-border-pulsate {
  border: solid 1.8rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-18-border-left {
  border-left: solid 1.8rem #aa00ff !important; }

.has-material-violet-18-border-right {
  border-right: solid 1.8rem #aa00ff !important; }

.has-material-violet-18-border-top {
  border-top: solid 1.8rem #aa00ff !important; }

.has-material-violet-18-border-bottom {
  border-bottom: solid 1.8rem #aa00ff !important; }

.has-material-violet-19-border {
  border: solid 1.9rem #aa00ff !important; }

.has-material-violet-19-border-pulsate {
  border: solid 1.9rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-19-border-left {
  border-left: solid 1.9rem #aa00ff !important; }

.has-material-violet-19-border-right {
  border-right: solid 1.9rem #aa00ff !important; }

.has-material-violet-19-border-top {
  border-top: solid 1.9rem #aa00ff !important; }

.has-material-violet-19-border-bottom {
  border-bottom: solid 1.9rem #aa00ff !important; }

.has-material-violet-20-border {
  border: solid 2rem #aa00ff !important; }

.has-material-violet-20-border-pulsate {
  border: solid 2rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-20-border-left {
  border-left: solid 2rem #aa00ff !important; }

.has-material-violet-20-border-right {
  border-right: solid 2rem #aa00ff !important; }

.has-material-violet-20-border-top {
  border-top: solid 2rem #aa00ff !important; }

.has-material-violet-20-border-bottom {
  border-bottom: solid 2rem #aa00ff !important; }

.has-material-violet-21-border {
  border: solid 2.1rem #aa00ff !important; }

.has-material-violet-21-border-pulsate {
  border: solid 2.1rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-21-border-left {
  border-left: solid 2.1rem #aa00ff !important; }

.has-material-violet-21-border-right {
  border-right: solid 2.1rem #aa00ff !important; }

.has-material-violet-21-border-top {
  border-top: solid 2.1rem #aa00ff !important; }

.has-material-violet-21-border-bottom {
  border-bottom: solid 2.1rem #aa00ff !important; }

.has-material-violet-24-border {
  border: solid 2.4rem #aa00ff !important; }

.has-material-violet-24-border-pulsate {
  border: solid 2.4rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-24-border-left {
  border-left: solid 2.4rem #aa00ff !important; }

.has-material-violet-24-border-right {
  border-right: solid 2.4rem #aa00ff !important; }

.has-material-violet-24-border-top {
  border-top: solid 2.4rem #aa00ff !important; }

.has-material-violet-24-border-bottom {
  border-bottom: solid 2.4rem #aa00ff !important; }

.has-material-violet-30-border {
  border: solid 3rem #aa00ff !important; }

.has-material-violet-30-border-pulsate {
  border: solid 3rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-30-border-left {
  border-left: solid 3rem #aa00ff !important; }

.has-material-violet-30-border-right {
  border-right: solid 3rem #aa00ff !important; }

.has-material-violet-30-border-top {
  border-top: solid 3rem #aa00ff !important; }

.has-material-violet-30-border-bottom {
  border-bottom: solid 3rem #aa00ff !important; }

.has-material-violet-40-border {
  border: solid 4rem #aa00ff !important; }

.has-material-violet-40-border-pulsate {
  border: solid 4rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-40-border-left {
  border-left: solid 4rem #aa00ff !important; }

.has-material-violet-40-border-right {
  border-right: solid 4rem #aa00ff !important; }

.has-material-violet-40-border-top {
  border-top: solid 4rem #aa00ff !important; }

.has-material-violet-40-border-bottom {
  border-bottom: solid 4rem #aa00ff !important; }

.has-material-violet-50-border {
  border: solid 5rem #aa00ff !important; }

.has-material-violet-50-border-pulsate {
  border: solid 5rem #aa00ff !important;
  animation: material-violet-border-pulsate 2s infinite; }

.has-material-violet-50-border-left {
  border-left: solid 5rem #aa00ff !important; }

.has-material-violet-50-border-right {
  border-right: solid 5rem #aa00ff !important; }

.has-material-violet-50-border-top {
  border-top: solid 5rem #aa00ff !important; }

.has-material-violet-50-border-bottom {
  border-bottom: solid 5rem #aa00ff !important; }

@keyframes setup-yellow-border-pulsate {
  0% {
    border-color: #ffab00; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #ffab00; } }

.has-setup-yellow-border-color {
  border-color: #ffab00; }

.has-setup-yellow-border {
  border: solid 0.1rem #ffab00; }

.has-setup-yellow-border-pulsate {
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-border-left {
  border-left: solid 0.1rem #ffab00; }

.has-setup-yellow-border-right {
  border-right: solid 0.1rem #ffab00; }

.has-setup-yellow-border-top {
  border-top: solid 0.1rem #ffab00; }

.has-setup-yellow-border-bottom {
  border-bottom: solid 0.1rem #ffab00; }

.has-setup-yellow-1-border {
  border: solid 0.1rem #ffab00 !important; }

.has-setup-yellow-1-border-pulsate {
  border: solid 0.1rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-1-border-left {
  border-left: solid 0.1rem #ffab00 !important; }

.has-setup-yellow-1-border-right {
  border-right: solid 0.1rem #ffab00 !important; }

.has-setup-yellow-1-border-top {
  border-top: solid 0.1rem #ffab00 !important; }

.has-setup-yellow-1-border-bottom {
  border-bottom: solid 0.1rem #ffab00 !important; }

.has-setup-yellow-2-border {
  border: solid 0.2rem #ffab00 !important; }

.has-setup-yellow-2-border-pulsate {
  border: solid 0.2rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-2-border-left {
  border-left: solid 0.2rem #ffab00 !important; }

.has-setup-yellow-2-border-right {
  border-right: solid 0.2rem #ffab00 !important; }

.has-setup-yellow-2-border-top {
  border-top: solid 0.2rem #ffab00 !important; }

.has-setup-yellow-2-border-bottom {
  border-bottom: solid 0.2rem #ffab00 !important; }

.has-setup-yellow-3-border {
  border: solid 0.3rem #ffab00 !important; }

.has-setup-yellow-3-border-pulsate {
  border: solid 0.3rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-3-border-left {
  border-left: solid 0.3rem #ffab00 !important; }

.has-setup-yellow-3-border-right {
  border-right: solid 0.3rem #ffab00 !important; }

.has-setup-yellow-3-border-top {
  border-top: solid 0.3rem #ffab00 !important; }

.has-setup-yellow-3-border-bottom {
  border-bottom: solid 0.3rem #ffab00 !important; }

.has-setup-yellow-4-border {
  border: solid 0.4rem #ffab00 !important; }

.has-setup-yellow-4-border-pulsate {
  border: solid 0.4rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-4-border-left {
  border-left: solid 0.4rem #ffab00 !important; }

.has-setup-yellow-4-border-right {
  border-right: solid 0.4rem #ffab00 !important; }

.has-setup-yellow-4-border-top {
  border-top: solid 0.4rem #ffab00 !important; }

.has-setup-yellow-4-border-bottom {
  border-bottom: solid 0.4rem #ffab00 !important; }

.has-setup-yellow-5-border {
  border: solid 0.5rem #ffab00 !important; }

.has-setup-yellow-5-border-pulsate {
  border: solid 0.5rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-5-border-left {
  border-left: solid 0.5rem #ffab00 !important; }

.has-setup-yellow-5-border-right {
  border-right: solid 0.5rem #ffab00 !important; }

.has-setup-yellow-5-border-top {
  border-top: solid 0.5rem #ffab00 !important; }

.has-setup-yellow-5-border-bottom {
  border-bottom: solid 0.5rem #ffab00 !important; }

.has-setup-yellow-6-border {
  border: solid 0.6rem #ffab00 !important; }

.has-setup-yellow-6-border-pulsate {
  border: solid 0.6rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-6-border-left {
  border-left: solid 0.6rem #ffab00 !important; }

.has-setup-yellow-6-border-right {
  border-right: solid 0.6rem #ffab00 !important; }

.has-setup-yellow-6-border-top {
  border-top: solid 0.6rem #ffab00 !important; }

.has-setup-yellow-6-border-bottom {
  border-bottom: solid 0.6rem #ffab00 !important; }

.has-setup-yellow-7-border {
  border: solid 0.7rem #ffab00 !important; }

.has-setup-yellow-7-border-pulsate {
  border: solid 0.7rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-7-border-left {
  border-left: solid 0.7rem #ffab00 !important; }

.has-setup-yellow-7-border-right {
  border-right: solid 0.7rem #ffab00 !important; }

.has-setup-yellow-7-border-top {
  border-top: solid 0.7rem #ffab00 !important; }

.has-setup-yellow-7-border-bottom {
  border-bottom: solid 0.7rem #ffab00 !important; }

.has-setup-yellow-8-border {
  border: solid 0.8rem #ffab00 !important; }

.has-setup-yellow-8-border-pulsate {
  border: solid 0.8rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-8-border-left {
  border-left: solid 0.8rem #ffab00 !important; }

.has-setup-yellow-8-border-right {
  border-right: solid 0.8rem #ffab00 !important; }

.has-setup-yellow-8-border-top {
  border-top: solid 0.8rem #ffab00 !important; }

.has-setup-yellow-8-border-bottom {
  border-bottom: solid 0.8rem #ffab00 !important; }

.has-setup-yellow-9-border {
  border: solid 0.9rem #ffab00 !important; }

.has-setup-yellow-9-border-pulsate {
  border: solid 0.9rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-9-border-left {
  border-left: solid 0.9rem #ffab00 !important; }

.has-setup-yellow-9-border-right {
  border-right: solid 0.9rem #ffab00 !important; }

.has-setup-yellow-9-border-top {
  border-top: solid 0.9rem #ffab00 !important; }

.has-setup-yellow-9-border-bottom {
  border-bottom: solid 0.9rem #ffab00 !important; }

.has-setup-yellow-10-border {
  border: solid 1rem #ffab00 !important; }

.has-setup-yellow-10-border-pulsate {
  border: solid 1rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-10-border-left {
  border-left: solid 1rem #ffab00 !important; }

.has-setup-yellow-10-border-right {
  border-right: solid 1rem #ffab00 !important; }

.has-setup-yellow-10-border-top {
  border-top: solid 1rem #ffab00 !important; }

.has-setup-yellow-10-border-bottom {
  border-bottom: solid 1rem #ffab00 !important; }

.has-setup-yellow-11-border {
  border: solid 1.1rem #ffab00 !important; }

.has-setup-yellow-11-border-pulsate {
  border: solid 1.1rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-11-border-left {
  border-left: solid 1.1rem #ffab00 !important; }

.has-setup-yellow-11-border-right {
  border-right: solid 1.1rem #ffab00 !important; }

.has-setup-yellow-11-border-top {
  border-top: solid 1.1rem #ffab00 !important; }

.has-setup-yellow-11-border-bottom {
  border-bottom: solid 1.1rem #ffab00 !important; }

.has-setup-yellow-12-border {
  border: solid 1.2rem #ffab00 !important; }

.has-setup-yellow-12-border-pulsate {
  border: solid 1.2rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-12-border-left {
  border-left: solid 1.2rem #ffab00 !important; }

.has-setup-yellow-12-border-right {
  border-right: solid 1.2rem #ffab00 !important; }

.has-setup-yellow-12-border-top {
  border-top: solid 1.2rem #ffab00 !important; }

.has-setup-yellow-12-border-bottom {
  border-bottom: solid 1.2rem #ffab00 !important; }

.has-setup-yellow-13-border {
  border: solid 1.3rem #ffab00 !important; }

.has-setup-yellow-13-border-pulsate {
  border: solid 1.3rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-13-border-left {
  border-left: solid 1.3rem #ffab00 !important; }

.has-setup-yellow-13-border-right {
  border-right: solid 1.3rem #ffab00 !important; }

.has-setup-yellow-13-border-top {
  border-top: solid 1.3rem #ffab00 !important; }

.has-setup-yellow-13-border-bottom {
  border-bottom: solid 1.3rem #ffab00 !important; }

.has-setup-yellow-14-border {
  border: solid 1.4rem #ffab00 !important; }

.has-setup-yellow-14-border-pulsate {
  border: solid 1.4rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-14-border-left {
  border-left: solid 1.4rem #ffab00 !important; }

.has-setup-yellow-14-border-right {
  border-right: solid 1.4rem #ffab00 !important; }

.has-setup-yellow-14-border-top {
  border-top: solid 1.4rem #ffab00 !important; }

.has-setup-yellow-14-border-bottom {
  border-bottom: solid 1.4rem #ffab00 !important; }

.has-setup-yellow-15-border {
  border: solid 1.5rem #ffab00 !important; }

.has-setup-yellow-15-border-pulsate {
  border: solid 1.5rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-15-border-left {
  border-left: solid 1.5rem #ffab00 !important; }

.has-setup-yellow-15-border-right {
  border-right: solid 1.5rem #ffab00 !important; }

.has-setup-yellow-15-border-top {
  border-top: solid 1.5rem #ffab00 !important; }

.has-setup-yellow-15-border-bottom {
  border-bottom: solid 1.5rem #ffab00 !important; }

.has-setup-yellow-16-border {
  border: solid 1.6rem #ffab00 !important; }

.has-setup-yellow-16-border-pulsate {
  border: solid 1.6rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-16-border-left {
  border-left: solid 1.6rem #ffab00 !important; }

.has-setup-yellow-16-border-right {
  border-right: solid 1.6rem #ffab00 !important; }

.has-setup-yellow-16-border-top {
  border-top: solid 1.6rem #ffab00 !important; }

.has-setup-yellow-16-border-bottom {
  border-bottom: solid 1.6rem #ffab00 !important; }

.has-setup-yellow-17-border {
  border: solid 1.7rem #ffab00 !important; }

.has-setup-yellow-17-border-pulsate {
  border: solid 1.7rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-17-border-left {
  border-left: solid 1.7rem #ffab00 !important; }

.has-setup-yellow-17-border-right {
  border-right: solid 1.7rem #ffab00 !important; }

.has-setup-yellow-17-border-top {
  border-top: solid 1.7rem #ffab00 !important; }

.has-setup-yellow-17-border-bottom {
  border-bottom: solid 1.7rem #ffab00 !important; }

.has-setup-yellow-18-border {
  border: solid 1.8rem #ffab00 !important; }

.has-setup-yellow-18-border-pulsate {
  border: solid 1.8rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-18-border-left {
  border-left: solid 1.8rem #ffab00 !important; }

.has-setup-yellow-18-border-right {
  border-right: solid 1.8rem #ffab00 !important; }

.has-setup-yellow-18-border-top {
  border-top: solid 1.8rem #ffab00 !important; }

.has-setup-yellow-18-border-bottom {
  border-bottom: solid 1.8rem #ffab00 !important; }

.has-setup-yellow-19-border {
  border: solid 1.9rem #ffab00 !important; }

.has-setup-yellow-19-border-pulsate {
  border: solid 1.9rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-19-border-left {
  border-left: solid 1.9rem #ffab00 !important; }

.has-setup-yellow-19-border-right {
  border-right: solid 1.9rem #ffab00 !important; }

.has-setup-yellow-19-border-top {
  border-top: solid 1.9rem #ffab00 !important; }

.has-setup-yellow-19-border-bottom {
  border-bottom: solid 1.9rem #ffab00 !important; }

.has-setup-yellow-20-border {
  border: solid 2rem #ffab00 !important; }

.has-setup-yellow-20-border-pulsate {
  border: solid 2rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-20-border-left {
  border-left: solid 2rem #ffab00 !important; }

.has-setup-yellow-20-border-right {
  border-right: solid 2rem #ffab00 !important; }

.has-setup-yellow-20-border-top {
  border-top: solid 2rem #ffab00 !important; }

.has-setup-yellow-20-border-bottom {
  border-bottom: solid 2rem #ffab00 !important; }

.has-setup-yellow-21-border {
  border: solid 2.1rem #ffab00 !important; }

.has-setup-yellow-21-border-pulsate {
  border: solid 2.1rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-21-border-left {
  border-left: solid 2.1rem #ffab00 !important; }

.has-setup-yellow-21-border-right {
  border-right: solid 2.1rem #ffab00 !important; }

.has-setup-yellow-21-border-top {
  border-top: solid 2.1rem #ffab00 !important; }

.has-setup-yellow-21-border-bottom {
  border-bottom: solid 2.1rem #ffab00 !important; }

.has-setup-yellow-24-border {
  border: solid 2.4rem #ffab00 !important; }

.has-setup-yellow-24-border-pulsate {
  border: solid 2.4rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-24-border-left {
  border-left: solid 2.4rem #ffab00 !important; }

.has-setup-yellow-24-border-right {
  border-right: solid 2.4rem #ffab00 !important; }

.has-setup-yellow-24-border-top {
  border-top: solid 2.4rem #ffab00 !important; }

.has-setup-yellow-24-border-bottom {
  border-bottom: solid 2.4rem #ffab00 !important; }

.has-setup-yellow-30-border {
  border: solid 3rem #ffab00 !important; }

.has-setup-yellow-30-border-pulsate {
  border: solid 3rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-30-border-left {
  border-left: solid 3rem #ffab00 !important; }

.has-setup-yellow-30-border-right {
  border-right: solid 3rem #ffab00 !important; }

.has-setup-yellow-30-border-top {
  border-top: solid 3rem #ffab00 !important; }

.has-setup-yellow-30-border-bottom {
  border-bottom: solid 3rem #ffab00 !important; }

.has-setup-yellow-40-border {
  border: solid 4rem #ffab00 !important; }

.has-setup-yellow-40-border-pulsate {
  border: solid 4rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-40-border-left {
  border-left: solid 4rem #ffab00 !important; }

.has-setup-yellow-40-border-right {
  border-right: solid 4rem #ffab00 !important; }

.has-setup-yellow-40-border-top {
  border-top: solid 4rem #ffab00 !important; }

.has-setup-yellow-40-border-bottom {
  border-bottom: solid 4rem #ffab00 !important; }

.has-setup-yellow-50-border {
  border: solid 5rem #ffab00 !important; }

.has-setup-yellow-50-border-pulsate {
  border: solid 5rem #ffab00 !important;
  animation: setup-yellow-border-pulsate 2s infinite; }

.has-setup-yellow-50-border-left {
  border-left: solid 5rem #ffab00 !important; }

.has-setup-yellow-50-border-right {
  border-right: solid 5rem #ffab00 !important; }

.has-setup-yellow-50-border-top {
  border-top: solid 5rem #ffab00 !important; }

.has-setup-yellow-50-border-bottom {
  border-bottom: solid 5rem #ffab00 !important; }

@keyframes check-green-border-pulsate {
  0% {
    border-color: #00c853; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #00c853; } }

.has-check-green-border-color {
  border-color: #00c853; }

.has-check-green-border {
  border: solid 0.1rem #00c853; }

.has-check-green-border-pulsate {
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-border-left {
  border-left: solid 0.1rem #00c853; }

.has-check-green-border-right {
  border-right: solid 0.1rem #00c853; }

.has-check-green-border-top {
  border-top: solid 0.1rem #00c853; }

.has-check-green-border-bottom {
  border-bottom: solid 0.1rem #00c853; }

.has-check-green-1-border {
  border: solid 0.1rem #00c853 !important; }

.has-check-green-1-border-pulsate {
  border: solid 0.1rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-1-border-left {
  border-left: solid 0.1rem #00c853 !important; }

.has-check-green-1-border-right {
  border-right: solid 0.1rem #00c853 !important; }

.has-check-green-1-border-top {
  border-top: solid 0.1rem #00c853 !important; }

.has-check-green-1-border-bottom {
  border-bottom: solid 0.1rem #00c853 !important; }

.has-check-green-2-border {
  border: solid 0.2rem #00c853 !important; }

.has-check-green-2-border-pulsate {
  border: solid 0.2rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-2-border-left {
  border-left: solid 0.2rem #00c853 !important; }

.has-check-green-2-border-right {
  border-right: solid 0.2rem #00c853 !important; }

.has-check-green-2-border-top {
  border-top: solid 0.2rem #00c853 !important; }

.has-check-green-2-border-bottom {
  border-bottom: solid 0.2rem #00c853 !important; }

.has-check-green-3-border {
  border: solid 0.3rem #00c853 !important; }

.has-check-green-3-border-pulsate {
  border: solid 0.3rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-3-border-left {
  border-left: solid 0.3rem #00c853 !important; }

.has-check-green-3-border-right {
  border-right: solid 0.3rem #00c853 !important; }

.has-check-green-3-border-top {
  border-top: solid 0.3rem #00c853 !important; }

.has-check-green-3-border-bottom {
  border-bottom: solid 0.3rem #00c853 !important; }

.has-check-green-4-border {
  border: solid 0.4rem #00c853 !important; }

.has-check-green-4-border-pulsate {
  border: solid 0.4rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-4-border-left {
  border-left: solid 0.4rem #00c853 !important; }

.has-check-green-4-border-right {
  border-right: solid 0.4rem #00c853 !important; }

.has-check-green-4-border-top {
  border-top: solid 0.4rem #00c853 !important; }

.has-check-green-4-border-bottom {
  border-bottom: solid 0.4rem #00c853 !important; }

.has-check-green-5-border {
  border: solid 0.5rem #00c853 !important; }

.has-check-green-5-border-pulsate {
  border: solid 0.5rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-5-border-left {
  border-left: solid 0.5rem #00c853 !important; }

.has-check-green-5-border-right {
  border-right: solid 0.5rem #00c853 !important; }

.has-check-green-5-border-top {
  border-top: solid 0.5rem #00c853 !important; }

.has-check-green-5-border-bottom {
  border-bottom: solid 0.5rem #00c853 !important; }

.has-check-green-6-border {
  border: solid 0.6rem #00c853 !important; }

.has-check-green-6-border-pulsate {
  border: solid 0.6rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-6-border-left {
  border-left: solid 0.6rem #00c853 !important; }

.has-check-green-6-border-right {
  border-right: solid 0.6rem #00c853 !important; }

.has-check-green-6-border-top {
  border-top: solid 0.6rem #00c853 !important; }

.has-check-green-6-border-bottom {
  border-bottom: solid 0.6rem #00c853 !important; }

.has-check-green-7-border {
  border: solid 0.7rem #00c853 !important; }

.has-check-green-7-border-pulsate {
  border: solid 0.7rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-7-border-left {
  border-left: solid 0.7rem #00c853 !important; }

.has-check-green-7-border-right {
  border-right: solid 0.7rem #00c853 !important; }

.has-check-green-7-border-top {
  border-top: solid 0.7rem #00c853 !important; }

.has-check-green-7-border-bottom {
  border-bottom: solid 0.7rem #00c853 !important; }

.has-check-green-8-border {
  border: solid 0.8rem #00c853 !important; }

.has-check-green-8-border-pulsate {
  border: solid 0.8rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-8-border-left {
  border-left: solid 0.8rem #00c853 !important; }

.has-check-green-8-border-right {
  border-right: solid 0.8rem #00c853 !important; }

.has-check-green-8-border-top {
  border-top: solid 0.8rem #00c853 !important; }

.has-check-green-8-border-bottom {
  border-bottom: solid 0.8rem #00c853 !important; }

.has-check-green-9-border {
  border: solid 0.9rem #00c853 !important; }

.has-check-green-9-border-pulsate {
  border: solid 0.9rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-9-border-left {
  border-left: solid 0.9rem #00c853 !important; }

.has-check-green-9-border-right {
  border-right: solid 0.9rem #00c853 !important; }

.has-check-green-9-border-top {
  border-top: solid 0.9rem #00c853 !important; }

.has-check-green-9-border-bottom {
  border-bottom: solid 0.9rem #00c853 !important; }

.has-check-green-10-border {
  border: solid 1rem #00c853 !important; }

.has-check-green-10-border-pulsate {
  border: solid 1rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-10-border-left {
  border-left: solid 1rem #00c853 !important; }

.has-check-green-10-border-right {
  border-right: solid 1rem #00c853 !important; }

.has-check-green-10-border-top {
  border-top: solid 1rem #00c853 !important; }

.has-check-green-10-border-bottom {
  border-bottom: solid 1rem #00c853 !important; }

.has-check-green-11-border {
  border: solid 1.1rem #00c853 !important; }

.has-check-green-11-border-pulsate {
  border: solid 1.1rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-11-border-left {
  border-left: solid 1.1rem #00c853 !important; }

.has-check-green-11-border-right {
  border-right: solid 1.1rem #00c853 !important; }

.has-check-green-11-border-top {
  border-top: solid 1.1rem #00c853 !important; }

.has-check-green-11-border-bottom {
  border-bottom: solid 1.1rem #00c853 !important; }

.has-check-green-12-border {
  border: solid 1.2rem #00c853 !important; }

.has-check-green-12-border-pulsate {
  border: solid 1.2rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-12-border-left {
  border-left: solid 1.2rem #00c853 !important; }

.has-check-green-12-border-right {
  border-right: solid 1.2rem #00c853 !important; }

.has-check-green-12-border-top {
  border-top: solid 1.2rem #00c853 !important; }

.has-check-green-12-border-bottom {
  border-bottom: solid 1.2rem #00c853 !important; }

.has-check-green-13-border {
  border: solid 1.3rem #00c853 !important; }

.has-check-green-13-border-pulsate {
  border: solid 1.3rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-13-border-left {
  border-left: solid 1.3rem #00c853 !important; }

.has-check-green-13-border-right {
  border-right: solid 1.3rem #00c853 !important; }

.has-check-green-13-border-top {
  border-top: solid 1.3rem #00c853 !important; }

.has-check-green-13-border-bottom {
  border-bottom: solid 1.3rem #00c853 !important; }

.has-check-green-14-border {
  border: solid 1.4rem #00c853 !important; }

.has-check-green-14-border-pulsate {
  border: solid 1.4rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-14-border-left {
  border-left: solid 1.4rem #00c853 !important; }

.has-check-green-14-border-right {
  border-right: solid 1.4rem #00c853 !important; }

.has-check-green-14-border-top {
  border-top: solid 1.4rem #00c853 !important; }

.has-check-green-14-border-bottom {
  border-bottom: solid 1.4rem #00c853 !important; }

.has-check-green-15-border {
  border: solid 1.5rem #00c853 !important; }

.has-check-green-15-border-pulsate {
  border: solid 1.5rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-15-border-left {
  border-left: solid 1.5rem #00c853 !important; }

.has-check-green-15-border-right {
  border-right: solid 1.5rem #00c853 !important; }

.has-check-green-15-border-top {
  border-top: solid 1.5rem #00c853 !important; }

.has-check-green-15-border-bottom {
  border-bottom: solid 1.5rem #00c853 !important; }

.has-check-green-16-border {
  border: solid 1.6rem #00c853 !important; }

.has-check-green-16-border-pulsate {
  border: solid 1.6rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-16-border-left {
  border-left: solid 1.6rem #00c853 !important; }

.has-check-green-16-border-right {
  border-right: solid 1.6rem #00c853 !important; }

.has-check-green-16-border-top {
  border-top: solid 1.6rem #00c853 !important; }

.has-check-green-16-border-bottom {
  border-bottom: solid 1.6rem #00c853 !important; }

.has-check-green-17-border {
  border: solid 1.7rem #00c853 !important; }

.has-check-green-17-border-pulsate {
  border: solid 1.7rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-17-border-left {
  border-left: solid 1.7rem #00c853 !important; }

.has-check-green-17-border-right {
  border-right: solid 1.7rem #00c853 !important; }

.has-check-green-17-border-top {
  border-top: solid 1.7rem #00c853 !important; }

.has-check-green-17-border-bottom {
  border-bottom: solid 1.7rem #00c853 !important; }

.has-check-green-18-border {
  border: solid 1.8rem #00c853 !important; }

.has-check-green-18-border-pulsate {
  border: solid 1.8rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-18-border-left {
  border-left: solid 1.8rem #00c853 !important; }

.has-check-green-18-border-right {
  border-right: solid 1.8rem #00c853 !important; }

.has-check-green-18-border-top {
  border-top: solid 1.8rem #00c853 !important; }

.has-check-green-18-border-bottom {
  border-bottom: solid 1.8rem #00c853 !important; }

.has-check-green-19-border {
  border: solid 1.9rem #00c853 !important; }

.has-check-green-19-border-pulsate {
  border: solid 1.9rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-19-border-left {
  border-left: solid 1.9rem #00c853 !important; }

.has-check-green-19-border-right {
  border-right: solid 1.9rem #00c853 !important; }

.has-check-green-19-border-top {
  border-top: solid 1.9rem #00c853 !important; }

.has-check-green-19-border-bottom {
  border-bottom: solid 1.9rem #00c853 !important; }

.has-check-green-20-border {
  border: solid 2rem #00c853 !important; }

.has-check-green-20-border-pulsate {
  border: solid 2rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-20-border-left {
  border-left: solid 2rem #00c853 !important; }

.has-check-green-20-border-right {
  border-right: solid 2rem #00c853 !important; }

.has-check-green-20-border-top {
  border-top: solid 2rem #00c853 !important; }

.has-check-green-20-border-bottom {
  border-bottom: solid 2rem #00c853 !important; }

.has-check-green-21-border {
  border: solid 2.1rem #00c853 !important; }

.has-check-green-21-border-pulsate {
  border: solid 2.1rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-21-border-left {
  border-left: solid 2.1rem #00c853 !important; }

.has-check-green-21-border-right {
  border-right: solid 2.1rem #00c853 !important; }

.has-check-green-21-border-top {
  border-top: solid 2.1rem #00c853 !important; }

.has-check-green-21-border-bottom {
  border-bottom: solid 2.1rem #00c853 !important; }

.has-check-green-24-border {
  border: solid 2.4rem #00c853 !important; }

.has-check-green-24-border-pulsate {
  border: solid 2.4rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-24-border-left {
  border-left: solid 2.4rem #00c853 !important; }

.has-check-green-24-border-right {
  border-right: solid 2.4rem #00c853 !important; }

.has-check-green-24-border-top {
  border-top: solid 2.4rem #00c853 !important; }

.has-check-green-24-border-bottom {
  border-bottom: solid 2.4rem #00c853 !important; }

.has-check-green-30-border {
  border: solid 3rem #00c853 !important; }

.has-check-green-30-border-pulsate {
  border: solid 3rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-30-border-left {
  border-left: solid 3rem #00c853 !important; }

.has-check-green-30-border-right {
  border-right: solid 3rem #00c853 !important; }

.has-check-green-30-border-top {
  border-top: solid 3rem #00c853 !important; }

.has-check-green-30-border-bottom {
  border-bottom: solid 3rem #00c853 !important; }

.has-check-green-40-border {
  border: solid 4rem #00c853 !important; }

.has-check-green-40-border-pulsate {
  border: solid 4rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-40-border-left {
  border-left: solid 4rem #00c853 !important; }

.has-check-green-40-border-right {
  border-right: solid 4rem #00c853 !important; }

.has-check-green-40-border-top {
  border-top: solid 4rem #00c853 !important; }

.has-check-green-40-border-bottom {
  border-bottom: solid 4rem #00c853 !important; }

.has-check-green-50-border {
  border: solid 5rem #00c853 !important; }

.has-check-green-50-border-pulsate {
  border: solid 5rem #00c853 !important;
  animation: check-green-border-pulsate 2s infinite; }

.has-check-green-50-border-left {
  border-left: solid 5rem #00c853 !important; }

.has-check-green-50-border-right {
  border-right: solid 5rem #00c853 !important; }

.has-check-green-50-border-top {
  border-top: solid 5rem #00c853 !important; }

.has-check-green-50-border-bottom {
  border-bottom: solid 5rem #00c853 !important; }

@keyframes neutral-grey-border-pulsate {
  0% {
    border-color: #b0bec5; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #b0bec5; } }

.has-neutral-grey-border-color {
  border-color: #b0bec5; }

.has-neutral-grey-border {
  border: solid 0.1rem #b0bec5; }

.has-neutral-grey-border-pulsate {
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-border-left {
  border-left: solid 0.1rem #b0bec5; }

.has-neutral-grey-border-right {
  border-right: solid 0.1rem #b0bec5; }

.has-neutral-grey-border-top {
  border-top: solid 0.1rem #b0bec5; }

.has-neutral-grey-border-bottom {
  border-bottom: solid 0.1rem #b0bec5; }

.has-neutral-grey-1-border {
  border: solid 0.1rem #b0bec5 !important; }

.has-neutral-grey-1-border-pulsate {
  border: solid 0.1rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-1-border-left {
  border-left: solid 0.1rem #b0bec5 !important; }

.has-neutral-grey-1-border-right {
  border-right: solid 0.1rem #b0bec5 !important; }

.has-neutral-grey-1-border-top {
  border-top: solid 0.1rem #b0bec5 !important; }

.has-neutral-grey-1-border-bottom {
  border-bottom: solid 0.1rem #b0bec5 !important; }

.has-neutral-grey-2-border {
  border: solid 0.2rem #b0bec5 !important; }

.has-neutral-grey-2-border-pulsate {
  border: solid 0.2rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-2-border-left {
  border-left: solid 0.2rem #b0bec5 !important; }

.has-neutral-grey-2-border-right {
  border-right: solid 0.2rem #b0bec5 !important; }

.has-neutral-grey-2-border-top {
  border-top: solid 0.2rem #b0bec5 !important; }

.has-neutral-grey-2-border-bottom {
  border-bottom: solid 0.2rem #b0bec5 !important; }

.has-neutral-grey-3-border {
  border: solid 0.3rem #b0bec5 !important; }

.has-neutral-grey-3-border-pulsate {
  border: solid 0.3rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-3-border-left {
  border-left: solid 0.3rem #b0bec5 !important; }

.has-neutral-grey-3-border-right {
  border-right: solid 0.3rem #b0bec5 !important; }

.has-neutral-grey-3-border-top {
  border-top: solid 0.3rem #b0bec5 !important; }

.has-neutral-grey-3-border-bottom {
  border-bottom: solid 0.3rem #b0bec5 !important; }

.has-neutral-grey-4-border {
  border: solid 0.4rem #b0bec5 !important; }

.has-neutral-grey-4-border-pulsate {
  border: solid 0.4rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-4-border-left {
  border-left: solid 0.4rem #b0bec5 !important; }

.has-neutral-grey-4-border-right {
  border-right: solid 0.4rem #b0bec5 !important; }

.has-neutral-grey-4-border-top {
  border-top: solid 0.4rem #b0bec5 !important; }

.has-neutral-grey-4-border-bottom {
  border-bottom: solid 0.4rem #b0bec5 !important; }

.has-neutral-grey-5-border {
  border: solid 0.5rem #b0bec5 !important; }

.has-neutral-grey-5-border-pulsate {
  border: solid 0.5rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-5-border-left {
  border-left: solid 0.5rem #b0bec5 !important; }

.has-neutral-grey-5-border-right {
  border-right: solid 0.5rem #b0bec5 !important; }

.has-neutral-grey-5-border-top {
  border-top: solid 0.5rem #b0bec5 !important; }

.has-neutral-grey-5-border-bottom {
  border-bottom: solid 0.5rem #b0bec5 !important; }

.has-neutral-grey-6-border {
  border: solid 0.6rem #b0bec5 !important; }

.has-neutral-grey-6-border-pulsate {
  border: solid 0.6rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-6-border-left {
  border-left: solid 0.6rem #b0bec5 !important; }

.has-neutral-grey-6-border-right {
  border-right: solid 0.6rem #b0bec5 !important; }

.has-neutral-grey-6-border-top {
  border-top: solid 0.6rem #b0bec5 !important; }

.has-neutral-grey-6-border-bottom {
  border-bottom: solid 0.6rem #b0bec5 !important; }

.has-neutral-grey-7-border {
  border: solid 0.7rem #b0bec5 !important; }

.has-neutral-grey-7-border-pulsate {
  border: solid 0.7rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-7-border-left {
  border-left: solid 0.7rem #b0bec5 !important; }

.has-neutral-grey-7-border-right {
  border-right: solid 0.7rem #b0bec5 !important; }

.has-neutral-grey-7-border-top {
  border-top: solid 0.7rem #b0bec5 !important; }

.has-neutral-grey-7-border-bottom {
  border-bottom: solid 0.7rem #b0bec5 !important; }

.has-neutral-grey-8-border {
  border: solid 0.8rem #b0bec5 !important; }

.has-neutral-grey-8-border-pulsate {
  border: solid 0.8rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-8-border-left {
  border-left: solid 0.8rem #b0bec5 !important; }

.has-neutral-grey-8-border-right {
  border-right: solid 0.8rem #b0bec5 !important; }

.has-neutral-grey-8-border-top {
  border-top: solid 0.8rem #b0bec5 !important; }

.has-neutral-grey-8-border-bottom {
  border-bottom: solid 0.8rem #b0bec5 !important; }

.has-neutral-grey-9-border {
  border: solid 0.9rem #b0bec5 !important; }

.has-neutral-grey-9-border-pulsate {
  border: solid 0.9rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-9-border-left {
  border-left: solid 0.9rem #b0bec5 !important; }

.has-neutral-grey-9-border-right {
  border-right: solid 0.9rem #b0bec5 !important; }

.has-neutral-grey-9-border-top {
  border-top: solid 0.9rem #b0bec5 !important; }

.has-neutral-grey-9-border-bottom {
  border-bottom: solid 0.9rem #b0bec5 !important; }

.has-neutral-grey-10-border {
  border: solid 1rem #b0bec5 !important; }

.has-neutral-grey-10-border-pulsate {
  border: solid 1rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-10-border-left {
  border-left: solid 1rem #b0bec5 !important; }

.has-neutral-grey-10-border-right {
  border-right: solid 1rem #b0bec5 !important; }

.has-neutral-grey-10-border-top {
  border-top: solid 1rem #b0bec5 !important; }

.has-neutral-grey-10-border-bottom {
  border-bottom: solid 1rem #b0bec5 !important; }

.has-neutral-grey-11-border {
  border: solid 1.1rem #b0bec5 !important; }

.has-neutral-grey-11-border-pulsate {
  border: solid 1.1rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-11-border-left {
  border-left: solid 1.1rem #b0bec5 !important; }

.has-neutral-grey-11-border-right {
  border-right: solid 1.1rem #b0bec5 !important; }

.has-neutral-grey-11-border-top {
  border-top: solid 1.1rem #b0bec5 !important; }

.has-neutral-grey-11-border-bottom {
  border-bottom: solid 1.1rem #b0bec5 !important; }

.has-neutral-grey-12-border {
  border: solid 1.2rem #b0bec5 !important; }

.has-neutral-grey-12-border-pulsate {
  border: solid 1.2rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-12-border-left {
  border-left: solid 1.2rem #b0bec5 !important; }

.has-neutral-grey-12-border-right {
  border-right: solid 1.2rem #b0bec5 !important; }

.has-neutral-grey-12-border-top {
  border-top: solid 1.2rem #b0bec5 !important; }

.has-neutral-grey-12-border-bottom {
  border-bottom: solid 1.2rem #b0bec5 !important; }

.has-neutral-grey-13-border {
  border: solid 1.3rem #b0bec5 !important; }

.has-neutral-grey-13-border-pulsate {
  border: solid 1.3rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-13-border-left {
  border-left: solid 1.3rem #b0bec5 !important; }

.has-neutral-grey-13-border-right {
  border-right: solid 1.3rem #b0bec5 !important; }

.has-neutral-grey-13-border-top {
  border-top: solid 1.3rem #b0bec5 !important; }

.has-neutral-grey-13-border-bottom {
  border-bottom: solid 1.3rem #b0bec5 !important; }

.has-neutral-grey-14-border {
  border: solid 1.4rem #b0bec5 !important; }

.has-neutral-grey-14-border-pulsate {
  border: solid 1.4rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-14-border-left {
  border-left: solid 1.4rem #b0bec5 !important; }

.has-neutral-grey-14-border-right {
  border-right: solid 1.4rem #b0bec5 !important; }

.has-neutral-grey-14-border-top {
  border-top: solid 1.4rem #b0bec5 !important; }

.has-neutral-grey-14-border-bottom {
  border-bottom: solid 1.4rem #b0bec5 !important; }

.has-neutral-grey-15-border {
  border: solid 1.5rem #b0bec5 !important; }

.has-neutral-grey-15-border-pulsate {
  border: solid 1.5rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-15-border-left {
  border-left: solid 1.5rem #b0bec5 !important; }

.has-neutral-grey-15-border-right {
  border-right: solid 1.5rem #b0bec5 !important; }

.has-neutral-grey-15-border-top {
  border-top: solid 1.5rem #b0bec5 !important; }

.has-neutral-grey-15-border-bottom {
  border-bottom: solid 1.5rem #b0bec5 !important; }

.has-neutral-grey-16-border {
  border: solid 1.6rem #b0bec5 !important; }

.has-neutral-grey-16-border-pulsate {
  border: solid 1.6rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-16-border-left {
  border-left: solid 1.6rem #b0bec5 !important; }

.has-neutral-grey-16-border-right {
  border-right: solid 1.6rem #b0bec5 !important; }

.has-neutral-grey-16-border-top {
  border-top: solid 1.6rem #b0bec5 !important; }

.has-neutral-grey-16-border-bottom {
  border-bottom: solid 1.6rem #b0bec5 !important; }

.has-neutral-grey-17-border {
  border: solid 1.7rem #b0bec5 !important; }

.has-neutral-grey-17-border-pulsate {
  border: solid 1.7rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-17-border-left {
  border-left: solid 1.7rem #b0bec5 !important; }

.has-neutral-grey-17-border-right {
  border-right: solid 1.7rem #b0bec5 !important; }

.has-neutral-grey-17-border-top {
  border-top: solid 1.7rem #b0bec5 !important; }

.has-neutral-grey-17-border-bottom {
  border-bottom: solid 1.7rem #b0bec5 !important; }

.has-neutral-grey-18-border {
  border: solid 1.8rem #b0bec5 !important; }

.has-neutral-grey-18-border-pulsate {
  border: solid 1.8rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-18-border-left {
  border-left: solid 1.8rem #b0bec5 !important; }

.has-neutral-grey-18-border-right {
  border-right: solid 1.8rem #b0bec5 !important; }

.has-neutral-grey-18-border-top {
  border-top: solid 1.8rem #b0bec5 !important; }

.has-neutral-grey-18-border-bottom {
  border-bottom: solid 1.8rem #b0bec5 !important; }

.has-neutral-grey-19-border {
  border: solid 1.9rem #b0bec5 !important; }

.has-neutral-grey-19-border-pulsate {
  border: solid 1.9rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-19-border-left {
  border-left: solid 1.9rem #b0bec5 !important; }

.has-neutral-grey-19-border-right {
  border-right: solid 1.9rem #b0bec5 !important; }

.has-neutral-grey-19-border-top {
  border-top: solid 1.9rem #b0bec5 !important; }

.has-neutral-grey-19-border-bottom {
  border-bottom: solid 1.9rem #b0bec5 !important; }

.has-neutral-grey-20-border {
  border: solid 2rem #b0bec5 !important; }

.has-neutral-grey-20-border-pulsate {
  border: solid 2rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-20-border-left {
  border-left: solid 2rem #b0bec5 !important; }

.has-neutral-grey-20-border-right {
  border-right: solid 2rem #b0bec5 !important; }

.has-neutral-grey-20-border-top {
  border-top: solid 2rem #b0bec5 !important; }

.has-neutral-grey-20-border-bottom {
  border-bottom: solid 2rem #b0bec5 !important; }

.has-neutral-grey-21-border {
  border: solid 2.1rem #b0bec5 !important; }

.has-neutral-grey-21-border-pulsate {
  border: solid 2.1rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-21-border-left {
  border-left: solid 2.1rem #b0bec5 !important; }

.has-neutral-grey-21-border-right {
  border-right: solid 2.1rem #b0bec5 !important; }

.has-neutral-grey-21-border-top {
  border-top: solid 2.1rem #b0bec5 !important; }

.has-neutral-grey-21-border-bottom {
  border-bottom: solid 2.1rem #b0bec5 !important; }

.has-neutral-grey-24-border {
  border: solid 2.4rem #b0bec5 !important; }

.has-neutral-grey-24-border-pulsate {
  border: solid 2.4rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-24-border-left {
  border-left: solid 2.4rem #b0bec5 !important; }

.has-neutral-grey-24-border-right {
  border-right: solid 2.4rem #b0bec5 !important; }

.has-neutral-grey-24-border-top {
  border-top: solid 2.4rem #b0bec5 !important; }

.has-neutral-grey-24-border-bottom {
  border-bottom: solid 2.4rem #b0bec5 !important; }

.has-neutral-grey-30-border {
  border: solid 3rem #b0bec5 !important; }

.has-neutral-grey-30-border-pulsate {
  border: solid 3rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-30-border-left {
  border-left: solid 3rem #b0bec5 !important; }

.has-neutral-grey-30-border-right {
  border-right: solid 3rem #b0bec5 !important; }

.has-neutral-grey-30-border-top {
  border-top: solid 3rem #b0bec5 !important; }

.has-neutral-grey-30-border-bottom {
  border-bottom: solid 3rem #b0bec5 !important; }

.has-neutral-grey-40-border {
  border: solid 4rem #b0bec5 !important; }

.has-neutral-grey-40-border-pulsate {
  border: solid 4rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-40-border-left {
  border-left: solid 4rem #b0bec5 !important; }

.has-neutral-grey-40-border-right {
  border-right: solid 4rem #b0bec5 !important; }

.has-neutral-grey-40-border-top {
  border-top: solid 4rem #b0bec5 !important; }

.has-neutral-grey-40-border-bottom {
  border-bottom: solid 4rem #b0bec5 !important; }

.has-neutral-grey-50-border {
  border: solid 5rem #b0bec5 !important; }

.has-neutral-grey-50-border-pulsate {
  border: solid 5rem #b0bec5 !important;
  animation: neutral-grey-border-pulsate 2s infinite; }

.has-neutral-grey-50-border-left {
  border-left: solid 5rem #b0bec5 !important; }

.has-neutral-grey-50-border-right {
  border-right: solid 5rem #b0bec5 !important; }

.has-neutral-grey-50-border-top {
  border-top: solid 5rem #b0bec5 !important; }

.has-neutral-grey-50-border-bottom {
  border-bottom: solid 5rem #b0bec5 !important; }

@keyframes done-grey-border-pulsate {
  0% {
    border-color: #ebeef0; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #ebeef0; } }

.has-done-grey-border-color {
  border-color: #ebeef0; }

.has-done-grey-border {
  border: solid 0.1rem #ebeef0; }

.has-done-grey-border-pulsate {
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-border-left {
  border-left: solid 0.1rem #ebeef0; }

.has-done-grey-border-right {
  border-right: solid 0.1rem #ebeef0; }

.has-done-grey-border-top {
  border-top: solid 0.1rem #ebeef0; }

.has-done-grey-border-bottom {
  border-bottom: solid 0.1rem #ebeef0; }

.has-done-grey-1-border {
  border: solid 0.1rem #ebeef0 !important; }

.has-done-grey-1-border-pulsate {
  border: solid 0.1rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-1-border-left {
  border-left: solid 0.1rem #ebeef0 !important; }

.has-done-grey-1-border-right {
  border-right: solid 0.1rem #ebeef0 !important; }

.has-done-grey-1-border-top {
  border-top: solid 0.1rem #ebeef0 !important; }

.has-done-grey-1-border-bottom {
  border-bottom: solid 0.1rem #ebeef0 !important; }

.has-done-grey-2-border {
  border: solid 0.2rem #ebeef0 !important; }

.has-done-grey-2-border-pulsate {
  border: solid 0.2rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-2-border-left {
  border-left: solid 0.2rem #ebeef0 !important; }

.has-done-grey-2-border-right {
  border-right: solid 0.2rem #ebeef0 !important; }

.has-done-grey-2-border-top {
  border-top: solid 0.2rem #ebeef0 !important; }

.has-done-grey-2-border-bottom {
  border-bottom: solid 0.2rem #ebeef0 !important; }

.has-done-grey-3-border {
  border: solid 0.3rem #ebeef0 !important; }

.has-done-grey-3-border-pulsate {
  border: solid 0.3rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-3-border-left {
  border-left: solid 0.3rem #ebeef0 !important; }

.has-done-grey-3-border-right {
  border-right: solid 0.3rem #ebeef0 !important; }

.has-done-grey-3-border-top {
  border-top: solid 0.3rem #ebeef0 !important; }

.has-done-grey-3-border-bottom {
  border-bottom: solid 0.3rem #ebeef0 !important; }

.has-done-grey-4-border {
  border: solid 0.4rem #ebeef0 !important; }

.has-done-grey-4-border-pulsate {
  border: solid 0.4rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-4-border-left {
  border-left: solid 0.4rem #ebeef0 !important; }

.has-done-grey-4-border-right {
  border-right: solid 0.4rem #ebeef0 !important; }

.has-done-grey-4-border-top {
  border-top: solid 0.4rem #ebeef0 !important; }

.has-done-grey-4-border-bottom {
  border-bottom: solid 0.4rem #ebeef0 !important; }

.has-done-grey-5-border {
  border: solid 0.5rem #ebeef0 !important; }

.has-done-grey-5-border-pulsate {
  border: solid 0.5rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-5-border-left {
  border-left: solid 0.5rem #ebeef0 !important; }

.has-done-grey-5-border-right {
  border-right: solid 0.5rem #ebeef0 !important; }

.has-done-grey-5-border-top {
  border-top: solid 0.5rem #ebeef0 !important; }

.has-done-grey-5-border-bottom {
  border-bottom: solid 0.5rem #ebeef0 !important; }

.has-done-grey-6-border {
  border: solid 0.6rem #ebeef0 !important; }

.has-done-grey-6-border-pulsate {
  border: solid 0.6rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-6-border-left {
  border-left: solid 0.6rem #ebeef0 !important; }

.has-done-grey-6-border-right {
  border-right: solid 0.6rem #ebeef0 !important; }

.has-done-grey-6-border-top {
  border-top: solid 0.6rem #ebeef0 !important; }

.has-done-grey-6-border-bottom {
  border-bottom: solid 0.6rem #ebeef0 !important; }

.has-done-grey-7-border {
  border: solid 0.7rem #ebeef0 !important; }

.has-done-grey-7-border-pulsate {
  border: solid 0.7rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-7-border-left {
  border-left: solid 0.7rem #ebeef0 !important; }

.has-done-grey-7-border-right {
  border-right: solid 0.7rem #ebeef0 !important; }

.has-done-grey-7-border-top {
  border-top: solid 0.7rem #ebeef0 !important; }

.has-done-grey-7-border-bottom {
  border-bottom: solid 0.7rem #ebeef0 !important; }

.has-done-grey-8-border {
  border: solid 0.8rem #ebeef0 !important; }

.has-done-grey-8-border-pulsate {
  border: solid 0.8rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-8-border-left {
  border-left: solid 0.8rem #ebeef0 !important; }

.has-done-grey-8-border-right {
  border-right: solid 0.8rem #ebeef0 !important; }

.has-done-grey-8-border-top {
  border-top: solid 0.8rem #ebeef0 !important; }

.has-done-grey-8-border-bottom {
  border-bottom: solid 0.8rem #ebeef0 !important; }

.has-done-grey-9-border {
  border: solid 0.9rem #ebeef0 !important; }

.has-done-grey-9-border-pulsate {
  border: solid 0.9rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-9-border-left {
  border-left: solid 0.9rem #ebeef0 !important; }

.has-done-grey-9-border-right {
  border-right: solid 0.9rem #ebeef0 !important; }

.has-done-grey-9-border-top {
  border-top: solid 0.9rem #ebeef0 !important; }

.has-done-grey-9-border-bottom {
  border-bottom: solid 0.9rem #ebeef0 !important; }

.has-done-grey-10-border {
  border: solid 1rem #ebeef0 !important; }

.has-done-grey-10-border-pulsate {
  border: solid 1rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-10-border-left {
  border-left: solid 1rem #ebeef0 !important; }

.has-done-grey-10-border-right {
  border-right: solid 1rem #ebeef0 !important; }

.has-done-grey-10-border-top {
  border-top: solid 1rem #ebeef0 !important; }

.has-done-grey-10-border-bottom {
  border-bottom: solid 1rem #ebeef0 !important; }

.has-done-grey-11-border {
  border: solid 1.1rem #ebeef0 !important; }

.has-done-grey-11-border-pulsate {
  border: solid 1.1rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-11-border-left {
  border-left: solid 1.1rem #ebeef0 !important; }

.has-done-grey-11-border-right {
  border-right: solid 1.1rem #ebeef0 !important; }

.has-done-grey-11-border-top {
  border-top: solid 1.1rem #ebeef0 !important; }

.has-done-grey-11-border-bottom {
  border-bottom: solid 1.1rem #ebeef0 !important; }

.has-done-grey-12-border {
  border: solid 1.2rem #ebeef0 !important; }

.has-done-grey-12-border-pulsate {
  border: solid 1.2rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-12-border-left {
  border-left: solid 1.2rem #ebeef0 !important; }

.has-done-grey-12-border-right {
  border-right: solid 1.2rem #ebeef0 !important; }

.has-done-grey-12-border-top {
  border-top: solid 1.2rem #ebeef0 !important; }

.has-done-grey-12-border-bottom {
  border-bottom: solid 1.2rem #ebeef0 !important; }

.has-done-grey-13-border {
  border: solid 1.3rem #ebeef0 !important; }

.has-done-grey-13-border-pulsate {
  border: solid 1.3rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-13-border-left {
  border-left: solid 1.3rem #ebeef0 !important; }

.has-done-grey-13-border-right {
  border-right: solid 1.3rem #ebeef0 !important; }

.has-done-grey-13-border-top {
  border-top: solid 1.3rem #ebeef0 !important; }

.has-done-grey-13-border-bottom {
  border-bottom: solid 1.3rem #ebeef0 !important; }

.has-done-grey-14-border {
  border: solid 1.4rem #ebeef0 !important; }

.has-done-grey-14-border-pulsate {
  border: solid 1.4rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-14-border-left {
  border-left: solid 1.4rem #ebeef0 !important; }

.has-done-grey-14-border-right {
  border-right: solid 1.4rem #ebeef0 !important; }

.has-done-grey-14-border-top {
  border-top: solid 1.4rem #ebeef0 !important; }

.has-done-grey-14-border-bottom {
  border-bottom: solid 1.4rem #ebeef0 !important; }

.has-done-grey-15-border {
  border: solid 1.5rem #ebeef0 !important; }

.has-done-grey-15-border-pulsate {
  border: solid 1.5rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-15-border-left {
  border-left: solid 1.5rem #ebeef0 !important; }

.has-done-grey-15-border-right {
  border-right: solid 1.5rem #ebeef0 !important; }

.has-done-grey-15-border-top {
  border-top: solid 1.5rem #ebeef0 !important; }

.has-done-grey-15-border-bottom {
  border-bottom: solid 1.5rem #ebeef0 !important; }

.has-done-grey-16-border {
  border: solid 1.6rem #ebeef0 !important; }

.has-done-grey-16-border-pulsate {
  border: solid 1.6rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-16-border-left {
  border-left: solid 1.6rem #ebeef0 !important; }

.has-done-grey-16-border-right {
  border-right: solid 1.6rem #ebeef0 !important; }

.has-done-grey-16-border-top {
  border-top: solid 1.6rem #ebeef0 !important; }

.has-done-grey-16-border-bottom {
  border-bottom: solid 1.6rem #ebeef0 !important; }

.has-done-grey-17-border {
  border: solid 1.7rem #ebeef0 !important; }

.has-done-grey-17-border-pulsate {
  border: solid 1.7rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-17-border-left {
  border-left: solid 1.7rem #ebeef0 !important; }

.has-done-grey-17-border-right {
  border-right: solid 1.7rem #ebeef0 !important; }

.has-done-grey-17-border-top {
  border-top: solid 1.7rem #ebeef0 !important; }

.has-done-grey-17-border-bottom {
  border-bottom: solid 1.7rem #ebeef0 !important; }

.has-done-grey-18-border {
  border: solid 1.8rem #ebeef0 !important; }

.has-done-grey-18-border-pulsate {
  border: solid 1.8rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-18-border-left {
  border-left: solid 1.8rem #ebeef0 !important; }

.has-done-grey-18-border-right {
  border-right: solid 1.8rem #ebeef0 !important; }

.has-done-grey-18-border-top {
  border-top: solid 1.8rem #ebeef0 !important; }

.has-done-grey-18-border-bottom {
  border-bottom: solid 1.8rem #ebeef0 !important; }

.has-done-grey-19-border {
  border: solid 1.9rem #ebeef0 !important; }

.has-done-grey-19-border-pulsate {
  border: solid 1.9rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-19-border-left {
  border-left: solid 1.9rem #ebeef0 !important; }

.has-done-grey-19-border-right {
  border-right: solid 1.9rem #ebeef0 !important; }

.has-done-grey-19-border-top {
  border-top: solid 1.9rem #ebeef0 !important; }

.has-done-grey-19-border-bottom {
  border-bottom: solid 1.9rem #ebeef0 !important; }

.has-done-grey-20-border {
  border: solid 2rem #ebeef0 !important; }

.has-done-grey-20-border-pulsate {
  border: solid 2rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-20-border-left {
  border-left: solid 2rem #ebeef0 !important; }

.has-done-grey-20-border-right {
  border-right: solid 2rem #ebeef0 !important; }

.has-done-grey-20-border-top {
  border-top: solid 2rem #ebeef0 !important; }

.has-done-grey-20-border-bottom {
  border-bottom: solid 2rem #ebeef0 !important; }

.has-done-grey-21-border {
  border: solid 2.1rem #ebeef0 !important; }

.has-done-grey-21-border-pulsate {
  border: solid 2.1rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-21-border-left {
  border-left: solid 2.1rem #ebeef0 !important; }

.has-done-grey-21-border-right {
  border-right: solid 2.1rem #ebeef0 !important; }

.has-done-grey-21-border-top {
  border-top: solid 2.1rem #ebeef0 !important; }

.has-done-grey-21-border-bottom {
  border-bottom: solid 2.1rem #ebeef0 !important; }

.has-done-grey-24-border {
  border: solid 2.4rem #ebeef0 !important; }

.has-done-grey-24-border-pulsate {
  border: solid 2.4rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-24-border-left {
  border-left: solid 2.4rem #ebeef0 !important; }

.has-done-grey-24-border-right {
  border-right: solid 2.4rem #ebeef0 !important; }

.has-done-grey-24-border-top {
  border-top: solid 2.4rem #ebeef0 !important; }

.has-done-grey-24-border-bottom {
  border-bottom: solid 2.4rem #ebeef0 !important; }

.has-done-grey-30-border {
  border: solid 3rem #ebeef0 !important; }

.has-done-grey-30-border-pulsate {
  border: solid 3rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-30-border-left {
  border-left: solid 3rem #ebeef0 !important; }

.has-done-grey-30-border-right {
  border-right: solid 3rem #ebeef0 !important; }

.has-done-grey-30-border-top {
  border-top: solid 3rem #ebeef0 !important; }

.has-done-grey-30-border-bottom {
  border-bottom: solid 3rem #ebeef0 !important; }

.has-done-grey-40-border {
  border: solid 4rem #ebeef0 !important; }

.has-done-grey-40-border-pulsate {
  border: solid 4rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-40-border-left {
  border-left: solid 4rem #ebeef0 !important; }

.has-done-grey-40-border-right {
  border-right: solid 4rem #ebeef0 !important; }

.has-done-grey-40-border-top {
  border-top: solid 4rem #ebeef0 !important; }

.has-done-grey-40-border-bottom {
  border-bottom: solid 4rem #ebeef0 !important; }

.has-done-grey-50-border {
  border: solid 5rem #ebeef0 !important; }

.has-done-grey-50-border-pulsate {
  border: solid 5rem #ebeef0 !important;
  animation: done-grey-border-pulsate 2s infinite; }

.has-done-grey-50-border-left {
  border-left: solid 5rem #ebeef0 !important; }

.has-done-grey-50-border-right {
  border-right: solid 5rem #ebeef0 !important; }

.has-done-grey-50-border-top {
  border-top: solid 5rem #ebeef0 !important; }

.has-done-grey-50-border-bottom {
  border-bottom: solid 5rem #ebeef0 !important; }

@keyframes stressed-border-pulsate {
  0% {
    border-color: #aa00ff; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #aa00ff; } }

.has-stressed-border-color {
  border-color: #aa00ff; }

.has-stressed-border {
  border: solid 0.1rem #aa00ff; }

.has-stressed-border-pulsate {
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-border-left {
  border-left: solid 0.1rem #aa00ff; }

.has-stressed-border-right {
  border-right: solid 0.1rem #aa00ff; }

.has-stressed-border-top {
  border-top: solid 0.1rem #aa00ff; }

.has-stressed-border-bottom {
  border-bottom: solid 0.1rem #aa00ff; }

.has-stressed-1-border {
  border: solid 0.1rem #aa00ff !important; }

.has-stressed-1-border-pulsate {
  border: solid 0.1rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-1-border-left {
  border-left: solid 0.1rem #aa00ff !important; }

.has-stressed-1-border-right {
  border-right: solid 0.1rem #aa00ff !important; }

.has-stressed-1-border-top {
  border-top: solid 0.1rem #aa00ff !important; }

.has-stressed-1-border-bottom {
  border-bottom: solid 0.1rem #aa00ff !important; }

.has-stressed-2-border {
  border: solid 0.2rem #aa00ff !important; }

.has-stressed-2-border-pulsate {
  border: solid 0.2rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-2-border-left {
  border-left: solid 0.2rem #aa00ff !important; }

.has-stressed-2-border-right {
  border-right: solid 0.2rem #aa00ff !important; }

.has-stressed-2-border-top {
  border-top: solid 0.2rem #aa00ff !important; }

.has-stressed-2-border-bottom {
  border-bottom: solid 0.2rem #aa00ff !important; }

.has-stressed-3-border {
  border: solid 0.3rem #aa00ff !important; }

.has-stressed-3-border-pulsate {
  border: solid 0.3rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-3-border-left {
  border-left: solid 0.3rem #aa00ff !important; }

.has-stressed-3-border-right {
  border-right: solid 0.3rem #aa00ff !important; }

.has-stressed-3-border-top {
  border-top: solid 0.3rem #aa00ff !important; }

.has-stressed-3-border-bottom {
  border-bottom: solid 0.3rem #aa00ff !important; }

.has-stressed-4-border {
  border: solid 0.4rem #aa00ff !important; }

.has-stressed-4-border-pulsate {
  border: solid 0.4rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-4-border-left {
  border-left: solid 0.4rem #aa00ff !important; }

.has-stressed-4-border-right {
  border-right: solid 0.4rem #aa00ff !important; }

.has-stressed-4-border-top {
  border-top: solid 0.4rem #aa00ff !important; }

.has-stressed-4-border-bottom {
  border-bottom: solid 0.4rem #aa00ff !important; }

.has-stressed-5-border {
  border: solid 0.5rem #aa00ff !important; }

.has-stressed-5-border-pulsate {
  border: solid 0.5rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-5-border-left {
  border-left: solid 0.5rem #aa00ff !important; }

.has-stressed-5-border-right {
  border-right: solid 0.5rem #aa00ff !important; }

.has-stressed-5-border-top {
  border-top: solid 0.5rem #aa00ff !important; }

.has-stressed-5-border-bottom {
  border-bottom: solid 0.5rem #aa00ff !important; }

.has-stressed-6-border {
  border: solid 0.6rem #aa00ff !important; }

.has-stressed-6-border-pulsate {
  border: solid 0.6rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-6-border-left {
  border-left: solid 0.6rem #aa00ff !important; }

.has-stressed-6-border-right {
  border-right: solid 0.6rem #aa00ff !important; }

.has-stressed-6-border-top {
  border-top: solid 0.6rem #aa00ff !important; }

.has-stressed-6-border-bottom {
  border-bottom: solid 0.6rem #aa00ff !important; }

.has-stressed-7-border {
  border: solid 0.7rem #aa00ff !important; }

.has-stressed-7-border-pulsate {
  border: solid 0.7rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-7-border-left {
  border-left: solid 0.7rem #aa00ff !important; }

.has-stressed-7-border-right {
  border-right: solid 0.7rem #aa00ff !important; }

.has-stressed-7-border-top {
  border-top: solid 0.7rem #aa00ff !important; }

.has-stressed-7-border-bottom {
  border-bottom: solid 0.7rem #aa00ff !important; }

.has-stressed-8-border {
  border: solid 0.8rem #aa00ff !important; }

.has-stressed-8-border-pulsate {
  border: solid 0.8rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-8-border-left {
  border-left: solid 0.8rem #aa00ff !important; }

.has-stressed-8-border-right {
  border-right: solid 0.8rem #aa00ff !important; }

.has-stressed-8-border-top {
  border-top: solid 0.8rem #aa00ff !important; }

.has-stressed-8-border-bottom {
  border-bottom: solid 0.8rem #aa00ff !important; }

.has-stressed-9-border {
  border: solid 0.9rem #aa00ff !important; }

.has-stressed-9-border-pulsate {
  border: solid 0.9rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-9-border-left {
  border-left: solid 0.9rem #aa00ff !important; }

.has-stressed-9-border-right {
  border-right: solid 0.9rem #aa00ff !important; }

.has-stressed-9-border-top {
  border-top: solid 0.9rem #aa00ff !important; }

.has-stressed-9-border-bottom {
  border-bottom: solid 0.9rem #aa00ff !important; }

.has-stressed-10-border {
  border: solid 1rem #aa00ff !important; }

.has-stressed-10-border-pulsate {
  border: solid 1rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-10-border-left {
  border-left: solid 1rem #aa00ff !important; }

.has-stressed-10-border-right {
  border-right: solid 1rem #aa00ff !important; }

.has-stressed-10-border-top {
  border-top: solid 1rem #aa00ff !important; }

.has-stressed-10-border-bottom {
  border-bottom: solid 1rem #aa00ff !important; }

.has-stressed-11-border {
  border: solid 1.1rem #aa00ff !important; }

.has-stressed-11-border-pulsate {
  border: solid 1.1rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-11-border-left {
  border-left: solid 1.1rem #aa00ff !important; }

.has-stressed-11-border-right {
  border-right: solid 1.1rem #aa00ff !important; }

.has-stressed-11-border-top {
  border-top: solid 1.1rem #aa00ff !important; }

.has-stressed-11-border-bottom {
  border-bottom: solid 1.1rem #aa00ff !important; }

.has-stressed-12-border {
  border: solid 1.2rem #aa00ff !important; }

.has-stressed-12-border-pulsate {
  border: solid 1.2rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-12-border-left {
  border-left: solid 1.2rem #aa00ff !important; }

.has-stressed-12-border-right {
  border-right: solid 1.2rem #aa00ff !important; }

.has-stressed-12-border-top {
  border-top: solid 1.2rem #aa00ff !important; }

.has-stressed-12-border-bottom {
  border-bottom: solid 1.2rem #aa00ff !important; }

.has-stressed-13-border {
  border: solid 1.3rem #aa00ff !important; }

.has-stressed-13-border-pulsate {
  border: solid 1.3rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-13-border-left {
  border-left: solid 1.3rem #aa00ff !important; }

.has-stressed-13-border-right {
  border-right: solid 1.3rem #aa00ff !important; }

.has-stressed-13-border-top {
  border-top: solid 1.3rem #aa00ff !important; }

.has-stressed-13-border-bottom {
  border-bottom: solid 1.3rem #aa00ff !important; }

.has-stressed-14-border {
  border: solid 1.4rem #aa00ff !important; }

.has-stressed-14-border-pulsate {
  border: solid 1.4rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-14-border-left {
  border-left: solid 1.4rem #aa00ff !important; }

.has-stressed-14-border-right {
  border-right: solid 1.4rem #aa00ff !important; }

.has-stressed-14-border-top {
  border-top: solid 1.4rem #aa00ff !important; }

.has-stressed-14-border-bottom {
  border-bottom: solid 1.4rem #aa00ff !important; }

.has-stressed-15-border {
  border: solid 1.5rem #aa00ff !important; }

.has-stressed-15-border-pulsate {
  border: solid 1.5rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-15-border-left {
  border-left: solid 1.5rem #aa00ff !important; }

.has-stressed-15-border-right {
  border-right: solid 1.5rem #aa00ff !important; }

.has-stressed-15-border-top {
  border-top: solid 1.5rem #aa00ff !important; }

.has-stressed-15-border-bottom {
  border-bottom: solid 1.5rem #aa00ff !important; }

.has-stressed-16-border {
  border: solid 1.6rem #aa00ff !important; }

.has-stressed-16-border-pulsate {
  border: solid 1.6rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-16-border-left {
  border-left: solid 1.6rem #aa00ff !important; }

.has-stressed-16-border-right {
  border-right: solid 1.6rem #aa00ff !important; }

.has-stressed-16-border-top {
  border-top: solid 1.6rem #aa00ff !important; }

.has-stressed-16-border-bottom {
  border-bottom: solid 1.6rem #aa00ff !important; }

.has-stressed-17-border {
  border: solid 1.7rem #aa00ff !important; }

.has-stressed-17-border-pulsate {
  border: solid 1.7rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-17-border-left {
  border-left: solid 1.7rem #aa00ff !important; }

.has-stressed-17-border-right {
  border-right: solid 1.7rem #aa00ff !important; }

.has-stressed-17-border-top {
  border-top: solid 1.7rem #aa00ff !important; }

.has-stressed-17-border-bottom {
  border-bottom: solid 1.7rem #aa00ff !important; }

.has-stressed-18-border {
  border: solid 1.8rem #aa00ff !important; }

.has-stressed-18-border-pulsate {
  border: solid 1.8rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-18-border-left {
  border-left: solid 1.8rem #aa00ff !important; }

.has-stressed-18-border-right {
  border-right: solid 1.8rem #aa00ff !important; }

.has-stressed-18-border-top {
  border-top: solid 1.8rem #aa00ff !important; }

.has-stressed-18-border-bottom {
  border-bottom: solid 1.8rem #aa00ff !important; }

.has-stressed-19-border {
  border: solid 1.9rem #aa00ff !important; }

.has-stressed-19-border-pulsate {
  border: solid 1.9rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-19-border-left {
  border-left: solid 1.9rem #aa00ff !important; }

.has-stressed-19-border-right {
  border-right: solid 1.9rem #aa00ff !important; }

.has-stressed-19-border-top {
  border-top: solid 1.9rem #aa00ff !important; }

.has-stressed-19-border-bottom {
  border-bottom: solid 1.9rem #aa00ff !important; }

.has-stressed-20-border {
  border: solid 2rem #aa00ff !important; }

.has-stressed-20-border-pulsate {
  border: solid 2rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-20-border-left {
  border-left: solid 2rem #aa00ff !important; }

.has-stressed-20-border-right {
  border-right: solid 2rem #aa00ff !important; }

.has-stressed-20-border-top {
  border-top: solid 2rem #aa00ff !important; }

.has-stressed-20-border-bottom {
  border-bottom: solid 2rem #aa00ff !important; }

.has-stressed-21-border {
  border: solid 2.1rem #aa00ff !important; }

.has-stressed-21-border-pulsate {
  border: solid 2.1rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-21-border-left {
  border-left: solid 2.1rem #aa00ff !important; }

.has-stressed-21-border-right {
  border-right: solid 2.1rem #aa00ff !important; }

.has-stressed-21-border-top {
  border-top: solid 2.1rem #aa00ff !important; }

.has-stressed-21-border-bottom {
  border-bottom: solid 2.1rem #aa00ff !important; }

.has-stressed-24-border {
  border: solid 2.4rem #aa00ff !important; }

.has-stressed-24-border-pulsate {
  border: solid 2.4rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-24-border-left {
  border-left: solid 2.4rem #aa00ff !important; }

.has-stressed-24-border-right {
  border-right: solid 2.4rem #aa00ff !important; }

.has-stressed-24-border-top {
  border-top: solid 2.4rem #aa00ff !important; }

.has-stressed-24-border-bottom {
  border-bottom: solid 2.4rem #aa00ff !important; }

.has-stressed-30-border {
  border: solid 3rem #aa00ff !important; }

.has-stressed-30-border-pulsate {
  border: solid 3rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-30-border-left {
  border-left: solid 3rem #aa00ff !important; }

.has-stressed-30-border-right {
  border-right: solid 3rem #aa00ff !important; }

.has-stressed-30-border-top {
  border-top: solid 3rem #aa00ff !important; }

.has-stressed-30-border-bottom {
  border-bottom: solid 3rem #aa00ff !important; }

.has-stressed-40-border {
  border: solid 4rem #aa00ff !important; }

.has-stressed-40-border-pulsate {
  border: solid 4rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-40-border-left {
  border-left: solid 4rem #aa00ff !important; }

.has-stressed-40-border-right {
  border-right: solid 4rem #aa00ff !important; }

.has-stressed-40-border-top {
  border-top: solid 4rem #aa00ff !important; }

.has-stressed-40-border-bottom {
  border-bottom: solid 4rem #aa00ff !important; }

.has-stressed-50-border {
  border: solid 5rem #aa00ff !important; }

.has-stressed-50-border-pulsate {
  border: solid 5rem #aa00ff !important;
  animation: stressed-border-pulsate 2s infinite; }

.has-stressed-50-border-left {
  border-left: solid 5rem #aa00ff !important; }

.has-stressed-50-border-right {
  border-right: solid 5rem #aa00ff !important; }

.has-stressed-50-border-top {
  border-top: solid 5rem #aa00ff !important; }

.has-stressed-50-border-bottom {
  border-bottom: solid 5rem #aa00ff !important; }

@keyframes error-border-pulsate {
  0% {
    border-color: #ff1744; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #ff1744; } }

.has-error-border-color {
  border-color: #ff1744; }

.has-error-border {
  border: solid 0.1rem #ff1744; }

.has-error-border-pulsate {
  animation: error-border-pulsate 2s infinite; }

.has-error-border-left {
  border-left: solid 0.1rem #ff1744; }

.has-error-border-right {
  border-right: solid 0.1rem #ff1744; }

.has-error-border-top {
  border-top: solid 0.1rem #ff1744; }

.has-error-border-bottom {
  border-bottom: solid 0.1rem #ff1744; }

.has-error-1-border {
  border: solid 0.1rem #ff1744 !important; }

.has-error-1-border-pulsate {
  border: solid 0.1rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-1-border-left {
  border-left: solid 0.1rem #ff1744 !important; }

.has-error-1-border-right {
  border-right: solid 0.1rem #ff1744 !important; }

.has-error-1-border-top {
  border-top: solid 0.1rem #ff1744 !important; }

.has-error-1-border-bottom {
  border-bottom: solid 0.1rem #ff1744 !important; }

.has-error-2-border {
  border: solid 0.2rem #ff1744 !important; }

.has-error-2-border-pulsate {
  border: solid 0.2rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-2-border-left {
  border-left: solid 0.2rem #ff1744 !important; }

.has-error-2-border-right {
  border-right: solid 0.2rem #ff1744 !important; }

.has-error-2-border-top {
  border-top: solid 0.2rem #ff1744 !important; }

.has-error-2-border-bottom {
  border-bottom: solid 0.2rem #ff1744 !important; }

.has-error-3-border {
  border: solid 0.3rem #ff1744 !important; }

.has-error-3-border-pulsate {
  border: solid 0.3rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-3-border-left {
  border-left: solid 0.3rem #ff1744 !important; }

.has-error-3-border-right {
  border-right: solid 0.3rem #ff1744 !important; }

.has-error-3-border-top {
  border-top: solid 0.3rem #ff1744 !important; }

.has-error-3-border-bottom {
  border-bottom: solid 0.3rem #ff1744 !important; }

.has-error-4-border {
  border: solid 0.4rem #ff1744 !important; }

.has-error-4-border-pulsate {
  border: solid 0.4rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-4-border-left {
  border-left: solid 0.4rem #ff1744 !important; }

.has-error-4-border-right {
  border-right: solid 0.4rem #ff1744 !important; }

.has-error-4-border-top {
  border-top: solid 0.4rem #ff1744 !important; }

.has-error-4-border-bottom {
  border-bottom: solid 0.4rem #ff1744 !important; }

.has-error-5-border {
  border: solid 0.5rem #ff1744 !important; }

.has-error-5-border-pulsate {
  border: solid 0.5rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-5-border-left {
  border-left: solid 0.5rem #ff1744 !important; }

.has-error-5-border-right {
  border-right: solid 0.5rem #ff1744 !important; }

.has-error-5-border-top {
  border-top: solid 0.5rem #ff1744 !important; }

.has-error-5-border-bottom {
  border-bottom: solid 0.5rem #ff1744 !important; }

.has-error-6-border {
  border: solid 0.6rem #ff1744 !important; }

.has-error-6-border-pulsate {
  border: solid 0.6rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-6-border-left {
  border-left: solid 0.6rem #ff1744 !important; }

.has-error-6-border-right {
  border-right: solid 0.6rem #ff1744 !important; }

.has-error-6-border-top {
  border-top: solid 0.6rem #ff1744 !important; }

.has-error-6-border-bottom {
  border-bottom: solid 0.6rem #ff1744 !important; }

.has-error-7-border {
  border: solid 0.7rem #ff1744 !important; }

.has-error-7-border-pulsate {
  border: solid 0.7rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-7-border-left {
  border-left: solid 0.7rem #ff1744 !important; }

.has-error-7-border-right {
  border-right: solid 0.7rem #ff1744 !important; }

.has-error-7-border-top {
  border-top: solid 0.7rem #ff1744 !important; }

.has-error-7-border-bottom {
  border-bottom: solid 0.7rem #ff1744 !important; }

.has-error-8-border {
  border: solid 0.8rem #ff1744 !important; }

.has-error-8-border-pulsate {
  border: solid 0.8rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-8-border-left {
  border-left: solid 0.8rem #ff1744 !important; }

.has-error-8-border-right {
  border-right: solid 0.8rem #ff1744 !important; }

.has-error-8-border-top {
  border-top: solid 0.8rem #ff1744 !important; }

.has-error-8-border-bottom {
  border-bottom: solid 0.8rem #ff1744 !important; }

.has-error-9-border {
  border: solid 0.9rem #ff1744 !important; }

.has-error-9-border-pulsate {
  border: solid 0.9rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-9-border-left {
  border-left: solid 0.9rem #ff1744 !important; }

.has-error-9-border-right {
  border-right: solid 0.9rem #ff1744 !important; }

.has-error-9-border-top {
  border-top: solid 0.9rem #ff1744 !important; }

.has-error-9-border-bottom {
  border-bottom: solid 0.9rem #ff1744 !important; }

.has-error-10-border {
  border: solid 1rem #ff1744 !important; }

.has-error-10-border-pulsate {
  border: solid 1rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-10-border-left {
  border-left: solid 1rem #ff1744 !important; }

.has-error-10-border-right {
  border-right: solid 1rem #ff1744 !important; }

.has-error-10-border-top {
  border-top: solid 1rem #ff1744 !important; }

.has-error-10-border-bottom {
  border-bottom: solid 1rem #ff1744 !important; }

.has-error-11-border {
  border: solid 1.1rem #ff1744 !important; }

.has-error-11-border-pulsate {
  border: solid 1.1rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-11-border-left {
  border-left: solid 1.1rem #ff1744 !important; }

.has-error-11-border-right {
  border-right: solid 1.1rem #ff1744 !important; }

.has-error-11-border-top {
  border-top: solid 1.1rem #ff1744 !important; }

.has-error-11-border-bottom {
  border-bottom: solid 1.1rem #ff1744 !important; }

.has-error-12-border {
  border: solid 1.2rem #ff1744 !important; }

.has-error-12-border-pulsate {
  border: solid 1.2rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-12-border-left {
  border-left: solid 1.2rem #ff1744 !important; }

.has-error-12-border-right {
  border-right: solid 1.2rem #ff1744 !important; }

.has-error-12-border-top {
  border-top: solid 1.2rem #ff1744 !important; }

.has-error-12-border-bottom {
  border-bottom: solid 1.2rem #ff1744 !important; }

.has-error-13-border {
  border: solid 1.3rem #ff1744 !important; }

.has-error-13-border-pulsate {
  border: solid 1.3rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-13-border-left {
  border-left: solid 1.3rem #ff1744 !important; }

.has-error-13-border-right {
  border-right: solid 1.3rem #ff1744 !important; }

.has-error-13-border-top {
  border-top: solid 1.3rem #ff1744 !important; }

.has-error-13-border-bottom {
  border-bottom: solid 1.3rem #ff1744 !important; }

.has-error-14-border {
  border: solid 1.4rem #ff1744 !important; }

.has-error-14-border-pulsate {
  border: solid 1.4rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-14-border-left {
  border-left: solid 1.4rem #ff1744 !important; }

.has-error-14-border-right {
  border-right: solid 1.4rem #ff1744 !important; }

.has-error-14-border-top {
  border-top: solid 1.4rem #ff1744 !important; }

.has-error-14-border-bottom {
  border-bottom: solid 1.4rem #ff1744 !important; }

.has-error-15-border {
  border: solid 1.5rem #ff1744 !important; }

.has-error-15-border-pulsate {
  border: solid 1.5rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-15-border-left {
  border-left: solid 1.5rem #ff1744 !important; }

.has-error-15-border-right {
  border-right: solid 1.5rem #ff1744 !important; }

.has-error-15-border-top {
  border-top: solid 1.5rem #ff1744 !important; }

.has-error-15-border-bottom {
  border-bottom: solid 1.5rem #ff1744 !important; }

.has-error-16-border {
  border: solid 1.6rem #ff1744 !important; }

.has-error-16-border-pulsate {
  border: solid 1.6rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-16-border-left {
  border-left: solid 1.6rem #ff1744 !important; }

.has-error-16-border-right {
  border-right: solid 1.6rem #ff1744 !important; }

.has-error-16-border-top {
  border-top: solid 1.6rem #ff1744 !important; }

.has-error-16-border-bottom {
  border-bottom: solid 1.6rem #ff1744 !important; }

.has-error-17-border {
  border: solid 1.7rem #ff1744 !important; }

.has-error-17-border-pulsate {
  border: solid 1.7rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-17-border-left {
  border-left: solid 1.7rem #ff1744 !important; }

.has-error-17-border-right {
  border-right: solid 1.7rem #ff1744 !important; }

.has-error-17-border-top {
  border-top: solid 1.7rem #ff1744 !important; }

.has-error-17-border-bottom {
  border-bottom: solid 1.7rem #ff1744 !important; }

.has-error-18-border {
  border: solid 1.8rem #ff1744 !important; }

.has-error-18-border-pulsate {
  border: solid 1.8rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-18-border-left {
  border-left: solid 1.8rem #ff1744 !important; }

.has-error-18-border-right {
  border-right: solid 1.8rem #ff1744 !important; }

.has-error-18-border-top {
  border-top: solid 1.8rem #ff1744 !important; }

.has-error-18-border-bottom {
  border-bottom: solid 1.8rem #ff1744 !important; }

.has-error-19-border {
  border: solid 1.9rem #ff1744 !important; }

.has-error-19-border-pulsate {
  border: solid 1.9rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-19-border-left {
  border-left: solid 1.9rem #ff1744 !important; }

.has-error-19-border-right {
  border-right: solid 1.9rem #ff1744 !important; }

.has-error-19-border-top {
  border-top: solid 1.9rem #ff1744 !important; }

.has-error-19-border-bottom {
  border-bottom: solid 1.9rem #ff1744 !important; }

.has-error-20-border {
  border: solid 2rem #ff1744 !important; }

.has-error-20-border-pulsate {
  border: solid 2rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-20-border-left {
  border-left: solid 2rem #ff1744 !important; }

.has-error-20-border-right {
  border-right: solid 2rem #ff1744 !important; }

.has-error-20-border-top {
  border-top: solid 2rem #ff1744 !important; }

.has-error-20-border-bottom {
  border-bottom: solid 2rem #ff1744 !important; }

.has-error-21-border {
  border: solid 2.1rem #ff1744 !important; }

.has-error-21-border-pulsate {
  border: solid 2.1rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-21-border-left {
  border-left: solid 2.1rem #ff1744 !important; }

.has-error-21-border-right {
  border-right: solid 2.1rem #ff1744 !important; }

.has-error-21-border-top {
  border-top: solid 2.1rem #ff1744 !important; }

.has-error-21-border-bottom {
  border-bottom: solid 2.1rem #ff1744 !important; }

.has-error-24-border {
  border: solid 2.4rem #ff1744 !important; }

.has-error-24-border-pulsate {
  border: solid 2.4rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-24-border-left {
  border-left: solid 2.4rem #ff1744 !important; }

.has-error-24-border-right {
  border-right: solid 2.4rem #ff1744 !important; }

.has-error-24-border-top {
  border-top: solid 2.4rem #ff1744 !important; }

.has-error-24-border-bottom {
  border-bottom: solid 2.4rem #ff1744 !important; }

.has-error-30-border {
  border: solid 3rem #ff1744 !important; }

.has-error-30-border-pulsate {
  border: solid 3rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-30-border-left {
  border-left: solid 3rem #ff1744 !important; }

.has-error-30-border-right {
  border-right: solid 3rem #ff1744 !important; }

.has-error-30-border-top {
  border-top: solid 3rem #ff1744 !important; }

.has-error-30-border-bottom {
  border-bottom: solid 3rem #ff1744 !important; }

.has-error-40-border {
  border: solid 4rem #ff1744 !important; }

.has-error-40-border-pulsate {
  border: solid 4rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-40-border-left {
  border-left: solid 4rem #ff1744 !important; }

.has-error-40-border-right {
  border-right: solid 4rem #ff1744 !important; }

.has-error-40-border-top {
  border-top: solid 4rem #ff1744 !important; }

.has-error-40-border-bottom {
  border-bottom: solid 4rem #ff1744 !important; }

.has-error-50-border {
  border: solid 5rem #ff1744 !important; }

.has-error-50-border-pulsate {
  border: solid 5rem #ff1744 !important;
  animation: error-border-pulsate 2s infinite; }

.has-error-50-border-left {
  border-left: solid 5rem #ff1744 !important; }

.has-error-50-border-right {
  border-right: solid 5rem #ff1744 !important; }

.has-error-50-border-top {
  border-top: solid 5rem #ff1744 !important; }

.has-error-50-border-bottom {
  border-bottom: solid 5rem #ff1744 !important; }

@keyframes production-border-pulsate {
  0% {
    border-color: #2979ff; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #2979ff; } }

.has-production-border-color {
  border-color: #2979ff; }

.has-production-border {
  border: solid 0.1rem #2979ff; }

.has-production-border-pulsate {
  animation: production-border-pulsate 2s infinite; }

.has-production-border-left {
  border-left: solid 0.1rem #2979ff; }

.has-production-border-right {
  border-right: solid 0.1rem #2979ff; }

.has-production-border-top {
  border-top: solid 0.1rem #2979ff; }

.has-production-border-bottom {
  border-bottom: solid 0.1rem #2979ff; }

.has-production-1-border {
  border: solid 0.1rem #2979ff !important; }

.has-production-1-border-pulsate {
  border: solid 0.1rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-1-border-left {
  border-left: solid 0.1rem #2979ff !important; }

.has-production-1-border-right {
  border-right: solid 0.1rem #2979ff !important; }

.has-production-1-border-top {
  border-top: solid 0.1rem #2979ff !important; }

.has-production-1-border-bottom {
  border-bottom: solid 0.1rem #2979ff !important; }

.has-production-2-border {
  border: solid 0.2rem #2979ff !important; }

.has-production-2-border-pulsate {
  border: solid 0.2rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-2-border-left {
  border-left: solid 0.2rem #2979ff !important; }

.has-production-2-border-right {
  border-right: solid 0.2rem #2979ff !important; }

.has-production-2-border-top {
  border-top: solid 0.2rem #2979ff !important; }

.has-production-2-border-bottom {
  border-bottom: solid 0.2rem #2979ff !important; }

.has-production-3-border {
  border: solid 0.3rem #2979ff !important; }

.has-production-3-border-pulsate {
  border: solid 0.3rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-3-border-left {
  border-left: solid 0.3rem #2979ff !important; }

.has-production-3-border-right {
  border-right: solid 0.3rem #2979ff !important; }

.has-production-3-border-top {
  border-top: solid 0.3rem #2979ff !important; }

.has-production-3-border-bottom {
  border-bottom: solid 0.3rem #2979ff !important; }

.has-production-4-border {
  border: solid 0.4rem #2979ff !important; }

.has-production-4-border-pulsate {
  border: solid 0.4rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-4-border-left {
  border-left: solid 0.4rem #2979ff !important; }

.has-production-4-border-right {
  border-right: solid 0.4rem #2979ff !important; }

.has-production-4-border-top {
  border-top: solid 0.4rem #2979ff !important; }

.has-production-4-border-bottom {
  border-bottom: solid 0.4rem #2979ff !important; }

.has-production-5-border {
  border: solid 0.5rem #2979ff !important; }

.has-production-5-border-pulsate {
  border: solid 0.5rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-5-border-left {
  border-left: solid 0.5rem #2979ff !important; }

.has-production-5-border-right {
  border-right: solid 0.5rem #2979ff !important; }

.has-production-5-border-top {
  border-top: solid 0.5rem #2979ff !important; }

.has-production-5-border-bottom {
  border-bottom: solid 0.5rem #2979ff !important; }

.has-production-6-border {
  border: solid 0.6rem #2979ff !important; }

.has-production-6-border-pulsate {
  border: solid 0.6rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-6-border-left {
  border-left: solid 0.6rem #2979ff !important; }

.has-production-6-border-right {
  border-right: solid 0.6rem #2979ff !important; }

.has-production-6-border-top {
  border-top: solid 0.6rem #2979ff !important; }

.has-production-6-border-bottom {
  border-bottom: solid 0.6rem #2979ff !important; }

.has-production-7-border {
  border: solid 0.7rem #2979ff !important; }

.has-production-7-border-pulsate {
  border: solid 0.7rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-7-border-left {
  border-left: solid 0.7rem #2979ff !important; }

.has-production-7-border-right {
  border-right: solid 0.7rem #2979ff !important; }

.has-production-7-border-top {
  border-top: solid 0.7rem #2979ff !important; }

.has-production-7-border-bottom {
  border-bottom: solid 0.7rem #2979ff !important; }

.has-production-8-border {
  border: solid 0.8rem #2979ff !important; }

.has-production-8-border-pulsate {
  border: solid 0.8rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-8-border-left {
  border-left: solid 0.8rem #2979ff !important; }

.has-production-8-border-right {
  border-right: solid 0.8rem #2979ff !important; }

.has-production-8-border-top {
  border-top: solid 0.8rem #2979ff !important; }

.has-production-8-border-bottom {
  border-bottom: solid 0.8rem #2979ff !important; }

.has-production-9-border {
  border: solid 0.9rem #2979ff !important; }

.has-production-9-border-pulsate {
  border: solid 0.9rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-9-border-left {
  border-left: solid 0.9rem #2979ff !important; }

.has-production-9-border-right {
  border-right: solid 0.9rem #2979ff !important; }

.has-production-9-border-top {
  border-top: solid 0.9rem #2979ff !important; }

.has-production-9-border-bottom {
  border-bottom: solid 0.9rem #2979ff !important; }

.has-production-10-border {
  border: solid 1rem #2979ff !important; }

.has-production-10-border-pulsate {
  border: solid 1rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-10-border-left {
  border-left: solid 1rem #2979ff !important; }

.has-production-10-border-right {
  border-right: solid 1rem #2979ff !important; }

.has-production-10-border-top {
  border-top: solid 1rem #2979ff !important; }

.has-production-10-border-bottom {
  border-bottom: solid 1rem #2979ff !important; }

.has-production-11-border {
  border: solid 1.1rem #2979ff !important; }

.has-production-11-border-pulsate {
  border: solid 1.1rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-11-border-left {
  border-left: solid 1.1rem #2979ff !important; }

.has-production-11-border-right {
  border-right: solid 1.1rem #2979ff !important; }

.has-production-11-border-top {
  border-top: solid 1.1rem #2979ff !important; }

.has-production-11-border-bottom {
  border-bottom: solid 1.1rem #2979ff !important; }

.has-production-12-border {
  border: solid 1.2rem #2979ff !important; }

.has-production-12-border-pulsate {
  border: solid 1.2rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-12-border-left {
  border-left: solid 1.2rem #2979ff !important; }

.has-production-12-border-right {
  border-right: solid 1.2rem #2979ff !important; }

.has-production-12-border-top {
  border-top: solid 1.2rem #2979ff !important; }

.has-production-12-border-bottom {
  border-bottom: solid 1.2rem #2979ff !important; }

.has-production-13-border {
  border: solid 1.3rem #2979ff !important; }

.has-production-13-border-pulsate {
  border: solid 1.3rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-13-border-left {
  border-left: solid 1.3rem #2979ff !important; }

.has-production-13-border-right {
  border-right: solid 1.3rem #2979ff !important; }

.has-production-13-border-top {
  border-top: solid 1.3rem #2979ff !important; }

.has-production-13-border-bottom {
  border-bottom: solid 1.3rem #2979ff !important; }

.has-production-14-border {
  border: solid 1.4rem #2979ff !important; }

.has-production-14-border-pulsate {
  border: solid 1.4rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-14-border-left {
  border-left: solid 1.4rem #2979ff !important; }

.has-production-14-border-right {
  border-right: solid 1.4rem #2979ff !important; }

.has-production-14-border-top {
  border-top: solid 1.4rem #2979ff !important; }

.has-production-14-border-bottom {
  border-bottom: solid 1.4rem #2979ff !important; }

.has-production-15-border {
  border: solid 1.5rem #2979ff !important; }

.has-production-15-border-pulsate {
  border: solid 1.5rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-15-border-left {
  border-left: solid 1.5rem #2979ff !important; }

.has-production-15-border-right {
  border-right: solid 1.5rem #2979ff !important; }

.has-production-15-border-top {
  border-top: solid 1.5rem #2979ff !important; }

.has-production-15-border-bottom {
  border-bottom: solid 1.5rem #2979ff !important; }

.has-production-16-border {
  border: solid 1.6rem #2979ff !important; }

.has-production-16-border-pulsate {
  border: solid 1.6rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-16-border-left {
  border-left: solid 1.6rem #2979ff !important; }

.has-production-16-border-right {
  border-right: solid 1.6rem #2979ff !important; }

.has-production-16-border-top {
  border-top: solid 1.6rem #2979ff !important; }

.has-production-16-border-bottom {
  border-bottom: solid 1.6rem #2979ff !important; }

.has-production-17-border {
  border: solid 1.7rem #2979ff !important; }

.has-production-17-border-pulsate {
  border: solid 1.7rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-17-border-left {
  border-left: solid 1.7rem #2979ff !important; }

.has-production-17-border-right {
  border-right: solid 1.7rem #2979ff !important; }

.has-production-17-border-top {
  border-top: solid 1.7rem #2979ff !important; }

.has-production-17-border-bottom {
  border-bottom: solid 1.7rem #2979ff !important; }

.has-production-18-border {
  border: solid 1.8rem #2979ff !important; }

.has-production-18-border-pulsate {
  border: solid 1.8rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-18-border-left {
  border-left: solid 1.8rem #2979ff !important; }

.has-production-18-border-right {
  border-right: solid 1.8rem #2979ff !important; }

.has-production-18-border-top {
  border-top: solid 1.8rem #2979ff !important; }

.has-production-18-border-bottom {
  border-bottom: solid 1.8rem #2979ff !important; }

.has-production-19-border {
  border: solid 1.9rem #2979ff !important; }

.has-production-19-border-pulsate {
  border: solid 1.9rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-19-border-left {
  border-left: solid 1.9rem #2979ff !important; }

.has-production-19-border-right {
  border-right: solid 1.9rem #2979ff !important; }

.has-production-19-border-top {
  border-top: solid 1.9rem #2979ff !important; }

.has-production-19-border-bottom {
  border-bottom: solid 1.9rem #2979ff !important; }

.has-production-20-border {
  border: solid 2rem #2979ff !important; }

.has-production-20-border-pulsate {
  border: solid 2rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-20-border-left {
  border-left: solid 2rem #2979ff !important; }

.has-production-20-border-right {
  border-right: solid 2rem #2979ff !important; }

.has-production-20-border-top {
  border-top: solid 2rem #2979ff !important; }

.has-production-20-border-bottom {
  border-bottom: solid 2rem #2979ff !important; }

.has-production-21-border {
  border: solid 2.1rem #2979ff !important; }

.has-production-21-border-pulsate {
  border: solid 2.1rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-21-border-left {
  border-left: solid 2.1rem #2979ff !important; }

.has-production-21-border-right {
  border-right: solid 2.1rem #2979ff !important; }

.has-production-21-border-top {
  border-top: solid 2.1rem #2979ff !important; }

.has-production-21-border-bottom {
  border-bottom: solid 2.1rem #2979ff !important; }

.has-production-24-border {
  border: solid 2.4rem #2979ff !important; }

.has-production-24-border-pulsate {
  border: solid 2.4rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-24-border-left {
  border-left: solid 2.4rem #2979ff !important; }

.has-production-24-border-right {
  border-right: solid 2.4rem #2979ff !important; }

.has-production-24-border-top {
  border-top: solid 2.4rem #2979ff !important; }

.has-production-24-border-bottom {
  border-bottom: solid 2.4rem #2979ff !important; }

.has-production-30-border {
  border: solid 3rem #2979ff !important; }

.has-production-30-border-pulsate {
  border: solid 3rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-30-border-left {
  border-left: solid 3rem #2979ff !important; }

.has-production-30-border-right {
  border-right: solid 3rem #2979ff !important; }

.has-production-30-border-top {
  border-top: solid 3rem #2979ff !important; }

.has-production-30-border-bottom {
  border-bottom: solid 3rem #2979ff !important; }

.has-production-40-border {
  border: solid 4rem #2979ff !important; }

.has-production-40-border-pulsate {
  border: solid 4rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-40-border-left {
  border-left: solid 4rem #2979ff !important; }

.has-production-40-border-right {
  border-right: solid 4rem #2979ff !important; }

.has-production-40-border-top {
  border-top: solid 4rem #2979ff !important; }

.has-production-40-border-bottom {
  border-bottom: solid 4rem #2979ff !important; }

.has-production-50-border {
  border: solid 5rem #2979ff !important; }

.has-production-50-border-pulsate {
  border: solid 5rem #2979ff !important;
  animation: production-border-pulsate 2s infinite; }

.has-production-50-border-left {
  border-left: solid 5rem #2979ff !important; }

.has-production-50-border-right {
  border-right: solid 5rem #2979ff !important; }

.has-production-50-border-top {
  border-top: solid 5rem #2979ff !important; }

.has-production-50-border-bottom {
  border-bottom: solid 5rem #2979ff !important; }

@keyframes interrupted-border-pulsate {
  0% {
    border-color: #b0bec5; }
  50% {
    border-color: #b0bec5; }
  100% {
    border-color: #b0bec5; } }

.has-interrupted-border-color {
  border-color: #b0bec5; }

.has-interrupted-border {
  border: solid 0.1rem #b0bec5; }

.has-interrupted-border-pulsate {
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-border-left {
  border-left: solid 0.1rem #b0bec5; }

.has-interrupted-border-right {
  border-right: solid 0.1rem #b0bec5; }

.has-interrupted-border-top {
  border-top: solid 0.1rem #b0bec5; }

.has-interrupted-border-bottom {
  border-bottom: solid 0.1rem #b0bec5; }

.has-interrupted-1-border {
  border: solid 0.1rem #b0bec5 !important; }

.has-interrupted-1-border-pulsate {
  border: solid 0.1rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-1-border-left {
  border-left: solid 0.1rem #b0bec5 !important; }

.has-interrupted-1-border-right {
  border-right: solid 0.1rem #b0bec5 !important; }

.has-interrupted-1-border-top {
  border-top: solid 0.1rem #b0bec5 !important; }

.has-interrupted-1-border-bottom {
  border-bottom: solid 0.1rem #b0bec5 !important; }

.has-interrupted-2-border {
  border: solid 0.2rem #b0bec5 !important; }

.has-interrupted-2-border-pulsate {
  border: solid 0.2rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-2-border-left {
  border-left: solid 0.2rem #b0bec5 !important; }

.has-interrupted-2-border-right {
  border-right: solid 0.2rem #b0bec5 !important; }

.has-interrupted-2-border-top {
  border-top: solid 0.2rem #b0bec5 !important; }

.has-interrupted-2-border-bottom {
  border-bottom: solid 0.2rem #b0bec5 !important; }

.has-interrupted-3-border {
  border: solid 0.3rem #b0bec5 !important; }

.has-interrupted-3-border-pulsate {
  border: solid 0.3rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-3-border-left {
  border-left: solid 0.3rem #b0bec5 !important; }

.has-interrupted-3-border-right {
  border-right: solid 0.3rem #b0bec5 !important; }

.has-interrupted-3-border-top {
  border-top: solid 0.3rem #b0bec5 !important; }

.has-interrupted-3-border-bottom {
  border-bottom: solid 0.3rem #b0bec5 !important; }

.has-interrupted-4-border {
  border: solid 0.4rem #b0bec5 !important; }

.has-interrupted-4-border-pulsate {
  border: solid 0.4rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-4-border-left {
  border-left: solid 0.4rem #b0bec5 !important; }

.has-interrupted-4-border-right {
  border-right: solid 0.4rem #b0bec5 !important; }

.has-interrupted-4-border-top {
  border-top: solid 0.4rem #b0bec5 !important; }

.has-interrupted-4-border-bottom {
  border-bottom: solid 0.4rem #b0bec5 !important; }

.has-interrupted-5-border {
  border: solid 0.5rem #b0bec5 !important; }

.has-interrupted-5-border-pulsate {
  border: solid 0.5rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-5-border-left {
  border-left: solid 0.5rem #b0bec5 !important; }

.has-interrupted-5-border-right {
  border-right: solid 0.5rem #b0bec5 !important; }

.has-interrupted-5-border-top {
  border-top: solid 0.5rem #b0bec5 !important; }

.has-interrupted-5-border-bottom {
  border-bottom: solid 0.5rem #b0bec5 !important; }

.has-interrupted-6-border {
  border: solid 0.6rem #b0bec5 !important; }

.has-interrupted-6-border-pulsate {
  border: solid 0.6rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-6-border-left {
  border-left: solid 0.6rem #b0bec5 !important; }

.has-interrupted-6-border-right {
  border-right: solid 0.6rem #b0bec5 !important; }

.has-interrupted-6-border-top {
  border-top: solid 0.6rem #b0bec5 !important; }

.has-interrupted-6-border-bottom {
  border-bottom: solid 0.6rem #b0bec5 !important; }

.has-interrupted-7-border {
  border: solid 0.7rem #b0bec5 !important; }

.has-interrupted-7-border-pulsate {
  border: solid 0.7rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-7-border-left {
  border-left: solid 0.7rem #b0bec5 !important; }

.has-interrupted-7-border-right {
  border-right: solid 0.7rem #b0bec5 !important; }

.has-interrupted-7-border-top {
  border-top: solid 0.7rem #b0bec5 !important; }

.has-interrupted-7-border-bottom {
  border-bottom: solid 0.7rem #b0bec5 !important; }

.has-interrupted-8-border {
  border: solid 0.8rem #b0bec5 !important; }

.has-interrupted-8-border-pulsate {
  border: solid 0.8rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-8-border-left {
  border-left: solid 0.8rem #b0bec5 !important; }

.has-interrupted-8-border-right {
  border-right: solid 0.8rem #b0bec5 !important; }

.has-interrupted-8-border-top {
  border-top: solid 0.8rem #b0bec5 !important; }

.has-interrupted-8-border-bottom {
  border-bottom: solid 0.8rem #b0bec5 !important; }

.has-interrupted-9-border {
  border: solid 0.9rem #b0bec5 !important; }

.has-interrupted-9-border-pulsate {
  border: solid 0.9rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-9-border-left {
  border-left: solid 0.9rem #b0bec5 !important; }

.has-interrupted-9-border-right {
  border-right: solid 0.9rem #b0bec5 !important; }

.has-interrupted-9-border-top {
  border-top: solid 0.9rem #b0bec5 !important; }

.has-interrupted-9-border-bottom {
  border-bottom: solid 0.9rem #b0bec5 !important; }

.has-interrupted-10-border {
  border: solid 1rem #b0bec5 !important; }

.has-interrupted-10-border-pulsate {
  border: solid 1rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-10-border-left {
  border-left: solid 1rem #b0bec5 !important; }

.has-interrupted-10-border-right {
  border-right: solid 1rem #b0bec5 !important; }

.has-interrupted-10-border-top {
  border-top: solid 1rem #b0bec5 !important; }

.has-interrupted-10-border-bottom {
  border-bottom: solid 1rem #b0bec5 !important; }

.has-interrupted-11-border {
  border: solid 1.1rem #b0bec5 !important; }

.has-interrupted-11-border-pulsate {
  border: solid 1.1rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-11-border-left {
  border-left: solid 1.1rem #b0bec5 !important; }

.has-interrupted-11-border-right {
  border-right: solid 1.1rem #b0bec5 !important; }

.has-interrupted-11-border-top {
  border-top: solid 1.1rem #b0bec5 !important; }

.has-interrupted-11-border-bottom {
  border-bottom: solid 1.1rem #b0bec5 !important; }

.has-interrupted-12-border {
  border: solid 1.2rem #b0bec5 !important; }

.has-interrupted-12-border-pulsate {
  border: solid 1.2rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-12-border-left {
  border-left: solid 1.2rem #b0bec5 !important; }

.has-interrupted-12-border-right {
  border-right: solid 1.2rem #b0bec5 !important; }

.has-interrupted-12-border-top {
  border-top: solid 1.2rem #b0bec5 !important; }

.has-interrupted-12-border-bottom {
  border-bottom: solid 1.2rem #b0bec5 !important; }

.has-interrupted-13-border {
  border: solid 1.3rem #b0bec5 !important; }

.has-interrupted-13-border-pulsate {
  border: solid 1.3rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-13-border-left {
  border-left: solid 1.3rem #b0bec5 !important; }

.has-interrupted-13-border-right {
  border-right: solid 1.3rem #b0bec5 !important; }

.has-interrupted-13-border-top {
  border-top: solid 1.3rem #b0bec5 !important; }

.has-interrupted-13-border-bottom {
  border-bottom: solid 1.3rem #b0bec5 !important; }

.has-interrupted-14-border {
  border: solid 1.4rem #b0bec5 !important; }

.has-interrupted-14-border-pulsate {
  border: solid 1.4rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-14-border-left {
  border-left: solid 1.4rem #b0bec5 !important; }

.has-interrupted-14-border-right {
  border-right: solid 1.4rem #b0bec5 !important; }

.has-interrupted-14-border-top {
  border-top: solid 1.4rem #b0bec5 !important; }

.has-interrupted-14-border-bottom {
  border-bottom: solid 1.4rem #b0bec5 !important; }

.has-interrupted-15-border {
  border: solid 1.5rem #b0bec5 !important; }

.has-interrupted-15-border-pulsate {
  border: solid 1.5rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-15-border-left {
  border-left: solid 1.5rem #b0bec5 !important; }

.has-interrupted-15-border-right {
  border-right: solid 1.5rem #b0bec5 !important; }

.has-interrupted-15-border-top {
  border-top: solid 1.5rem #b0bec5 !important; }

.has-interrupted-15-border-bottom {
  border-bottom: solid 1.5rem #b0bec5 !important; }

.has-interrupted-16-border {
  border: solid 1.6rem #b0bec5 !important; }

.has-interrupted-16-border-pulsate {
  border: solid 1.6rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-16-border-left {
  border-left: solid 1.6rem #b0bec5 !important; }

.has-interrupted-16-border-right {
  border-right: solid 1.6rem #b0bec5 !important; }

.has-interrupted-16-border-top {
  border-top: solid 1.6rem #b0bec5 !important; }

.has-interrupted-16-border-bottom {
  border-bottom: solid 1.6rem #b0bec5 !important; }

.has-interrupted-17-border {
  border: solid 1.7rem #b0bec5 !important; }

.has-interrupted-17-border-pulsate {
  border: solid 1.7rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-17-border-left {
  border-left: solid 1.7rem #b0bec5 !important; }

.has-interrupted-17-border-right {
  border-right: solid 1.7rem #b0bec5 !important; }

.has-interrupted-17-border-top {
  border-top: solid 1.7rem #b0bec5 !important; }

.has-interrupted-17-border-bottom {
  border-bottom: solid 1.7rem #b0bec5 !important; }

.has-interrupted-18-border {
  border: solid 1.8rem #b0bec5 !important; }

.has-interrupted-18-border-pulsate {
  border: solid 1.8rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-18-border-left {
  border-left: solid 1.8rem #b0bec5 !important; }

.has-interrupted-18-border-right {
  border-right: solid 1.8rem #b0bec5 !important; }

.has-interrupted-18-border-top {
  border-top: solid 1.8rem #b0bec5 !important; }

.has-interrupted-18-border-bottom {
  border-bottom: solid 1.8rem #b0bec5 !important; }

.has-interrupted-19-border {
  border: solid 1.9rem #b0bec5 !important; }

.has-interrupted-19-border-pulsate {
  border: solid 1.9rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-19-border-left {
  border-left: solid 1.9rem #b0bec5 !important; }

.has-interrupted-19-border-right {
  border-right: solid 1.9rem #b0bec5 !important; }

.has-interrupted-19-border-top {
  border-top: solid 1.9rem #b0bec5 !important; }

.has-interrupted-19-border-bottom {
  border-bottom: solid 1.9rem #b0bec5 !important; }

.has-interrupted-20-border {
  border: solid 2rem #b0bec5 !important; }

.has-interrupted-20-border-pulsate {
  border: solid 2rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-20-border-left {
  border-left: solid 2rem #b0bec5 !important; }

.has-interrupted-20-border-right {
  border-right: solid 2rem #b0bec5 !important; }

.has-interrupted-20-border-top {
  border-top: solid 2rem #b0bec5 !important; }

.has-interrupted-20-border-bottom {
  border-bottom: solid 2rem #b0bec5 !important; }

.has-interrupted-21-border {
  border: solid 2.1rem #b0bec5 !important; }

.has-interrupted-21-border-pulsate {
  border: solid 2.1rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-21-border-left {
  border-left: solid 2.1rem #b0bec5 !important; }

.has-interrupted-21-border-right {
  border-right: solid 2.1rem #b0bec5 !important; }

.has-interrupted-21-border-top {
  border-top: solid 2.1rem #b0bec5 !important; }

.has-interrupted-21-border-bottom {
  border-bottom: solid 2.1rem #b0bec5 !important; }

.has-interrupted-24-border {
  border: solid 2.4rem #b0bec5 !important; }

.has-interrupted-24-border-pulsate {
  border: solid 2.4rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-24-border-left {
  border-left: solid 2.4rem #b0bec5 !important; }

.has-interrupted-24-border-right {
  border-right: solid 2.4rem #b0bec5 !important; }

.has-interrupted-24-border-top {
  border-top: solid 2.4rem #b0bec5 !important; }

.has-interrupted-24-border-bottom {
  border-bottom: solid 2.4rem #b0bec5 !important; }

.has-interrupted-30-border {
  border: solid 3rem #b0bec5 !important; }

.has-interrupted-30-border-pulsate {
  border: solid 3rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-30-border-left {
  border-left: solid 3rem #b0bec5 !important; }

.has-interrupted-30-border-right {
  border-right: solid 3rem #b0bec5 !important; }

.has-interrupted-30-border-top {
  border-top: solid 3rem #b0bec5 !important; }

.has-interrupted-30-border-bottom {
  border-bottom: solid 3rem #b0bec5 !important; }

.has-interrupted-40-border {
  border: solid 4rem #b0bec5 !important; }

.has-interrupted-40-border-pulsate {
  border: solid 4rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-40-border-left {
  border-left: solid 4rem #b0bec5 !important; }

.has-interrupted-40-border-right {
  border-right: solid 4rem #b0bec5 !important; }

.has-interrupted-40-border-top {
  border-top: solid 4rem #b0bec5 !important; }

.has-interrupted-40-border-bottom {
  border-bottom: solid 4rem #b0bec5 !important; }

.has-interrupted-50-border {
  border: solid 5rem #b0bec5 !important; }

.has-interrupted-50-border-pulsate {
  border: solid 5rem #b0bec5 !important;
  animation: interrupted-border-pulsate 2s infinite; }

.has-interrupted-50-border-left {
  border-left: solid 5rem #b0bec5 !important; }

.has-interrupted-50-border-right {
  border-right: solid 5rem #b0bec5 !important; }

.has-interrupted-50-border-top {
  border-top: solid 5rem #b0bec5 !important; }

.has-interrupted-50-border-bottom {
  border-bottom: solid 5rem #b0bec5 !important; }

.truncate {
  width: auto;
  overflow: hidden;
  position: relative; }
  .truncate > * {
    white-space: nowrap;
    text-overflow: ellipsis; }

tr.is-active,
.columns.is-active {
  position: relative;
  background: linear-gradient(to right, #f2f6f9 70%, transparent); }

.bg-transparent.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(0, 0, 0, 0)); }

.bg-default.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #263238); }

.bg-white.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #fff); }

.bg-black.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #263238); }

.bg-dark.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #263238); }

.bg-slate.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #455a64); }

.bg-body-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #f2f6f9); }

.bg-light-blue-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #a8c6df); }

.bg-light-blue-grey-20.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(168, 198, 223, 0.2)); }

.bg-light-blue-grey-80.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(168, 198, 223, 0.8)); }

.bg-light-blue-grey-90.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(183, 210, 229, 0.9)); }

.bg-bluish-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #78909c); }

.bg-light-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #f8f9f9); }

.bg-dark-sky-blue-10.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(30, 136, 229, 0.1)); }

.bg-light-blue-grey-two.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #d8e5ef); }

.bg-dark-grey-blue.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #2c4059); }

.bg-dark-grey-blue-16.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(44, 64, 90, 0.16)); }

.bg-dark-2.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(38, 50, 56, 0.02)); }

.bg-dark-3.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(38, 50, 56, 0.03)); }

.bg-charcoal-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #37474f); }

.bg-pale-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #fafbfc); }

.bg-greyblue.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #8dabc4); }

.bg-bluey-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #90a4ae); }

.bg-pale-grey-two.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #f2f6f9); }

.bg-pale-grey-three.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #e5eef5); }

.bg-light-blue-grey-three.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #c5d9e8); }

.bg-charcoal-grey-60.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(55, 71, 79, 0.6)); }

.bg-charcoal-grey-80.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(55, 71, 79, 0.8)); }

.bg-azure.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #0093ee); }

.bg-dark-sky-blue.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #1e88e5); }

.bg-apple-green.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #81c926); }

.bg-pause-azure.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #03a9f4); }

.bg-production-blue.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #2979ff); }

.bg-production-blue-20.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, rgba(41, 121, 255, 0.2)); }

.bg-material-violet.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #aa00ff); }

.bg-setup-yellow.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #ffab00); }

.bg-check-green.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #00c853); }

.bg-neutral-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #b0bec5); }

.bg-done-grey.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #ebeef0); }

.bg-stressed.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #aa00ff); }

.bg-error.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #ff1744); }

.bg-production.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #2979ff); }

.bg-interrupted.column.truncate > *:before {
  background: linear-gradient(to right, transparent 80%, #b0bec5); }

.blinking {
  animation: blinker 1.5s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0; } }

html.home-layout .hero.is-primary.is-bold {
  background-image: -webkit-linear-gradient(309deg, #1e88e5 0%, #187bd1 71%, #1360a4 100%);
  background-image: -o-linear-gradient(309deg, #1e88e5 0%, #187bd1 71%, #1360a4 100%);
  background-image: linear-gradient(141deg, #1e88e5 0%, #187bd1 71%, #1360a4 100%); }

html.home-layout .hero.is-primary a.navbar-item:hover, html.home-layout .hero.is-primary a.navbar-item.is-active, html.home-layout .hero.is-primary .navbar-link:hover, html.home-layout .hero.is-primary .navbar-link.is-active {
  background-color: transparent;
  font-weight: initial; }

html.default-layout {
  /* otherwise overflow-x will appear :( */ }
  html.default-layout {
    overflow-y: hidden;
    padding-top: 60px;
    font-size: 62.5%;
    /* 1rem = 10px */
    background-color: #f2f6f9;
    color: #263238; }
  html.default-layout body {
    font-size: 1.5rem;
    height: calc(100vh - 6rem); }
  html.default-layout .main {
    display: flex;
    padding-left: 6rem; }
    html.default-layout .main > * {
      width: 100%;
      height: calc(100vh - 6rem); }
  html.default-layout .hero.is-fullheight {
    min-height: calc(100vh - 6rem); }
  html.default-layout .subtitle:not(:last-child) {
    margin-bottom: 0.8rem; }
  html.default-layout .level-left > .level-item > .level-image {
    margin-left: 1.3rem; }
  html.default-layout .side-info-box .column {
    padding-top: 1rem; }
  html.default-layout .side-info-box h4 {
    color: #263238;
    margin-bottom: 0; }
  html.default-layout .side-info-box p:not(.subtitle) {
    color: #2c4059;
    font-size: 3.5rem;
    line-height: 1.29;
    margin-top: 2rem;
    font-weight: 500;
    text-align: center; }
    html.default-layout .side-info-box p:not(.subtitle):last-child {
      margin-bottom: 1.7rem; }
  html.default-layout .side-info-box .is-footer-info .columns {
    margin-bottom: 0; }
    html.default-layout .side-info-box .is-footer-info .columns .column {
      padding-bottom: 0;
      padding-right: 0; }
  html.default-layout .side-info-box .is-footer-info p.subtitle {
    color: #78909c;
    text-align: right; }
  html.default-layout .column-list-item {
    margin-right: -2.2rem;
    padding: 0 2.2rem 0rem 0; }
    html.default-layout .column-list-item:not(:last-child) {
      border-bottom: 0.1rem solid #d8e5ef; }
  html.default-layout .Select-control,
  html.default-layout .Select.is-focused:not(.is-open) > .Select-control,
  html.default-layout .input:not(.is-rounded),
  html.default-layout .textarea,
  html.default-layout select {
    box-shadow: none;
    border-radius: 0.6rem;
    border-color: #c5d9e8;
    outline: none; }
  html.default-layout .Select-control,
  html.default-layout .input,
  html.default-layout .select,
  html.default-layout .radio,
  html.default-layout .textarea {
    font-size: 1.7rem; }
    html.default-layout .Select-control.is-small,
    html.default-layout .input.is-small,
    html.default-layout .select.is-small,
    html.default-layout .radio.is-small,
    html.default-layout .textarea.is-small {
      font-size: 1.45rem; }
  html.default-layout .Select-arrow {
    border: 1px solid #a8c6df;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
    z-index: 1; }
  html.default-layout .textarea.is-large[rows='3'] {
    min-height: 12.2rem; }
  html.default-layout .textarea.is-large,
  html.default-layout .input.is-large {
    font-size: 3.2rem;
    height: 7rem; }
  html.default-layout .select.is-large,
  html.default-layout .select.is-large select {
    height: 5.1rem; }
  html.default-layout .Select-arrow-zone {
    padding-left: 3rem; }
  html.default-layout .Select-input,
  html.default-layout .Select-value,
  html.default-layout .Select-placeholder {
    padding: 0.6rem 2rem 0.6rem 1.5rem !important;
    font-size: 1.7rem; }
  html.default-layout .Select-value-label {
    padding: 0.5rem !important;
    font-size: 1.7rem; }
  html.default-layout .Select-option {
    padding: 1rem 2rem;
    font-size: 1.3rem; }
  html.default-layout .Select-control,
  html.default-layout .select.is-medium,
  html.default-layout .textarea.is-medium,
  html.default-layout .input.is-medium {
    font-size: 1.7rem;
    height: 4.8rem; }
  html.default-layout input.is-large[type='checkbox'] {
    width: 2rem;
    height: 1.8rem; }
  html.default-layout .input.is-active,
  html.default-layout .input.is-focused,
  html.default-layout .input.is-hovered,
  html.default-layout .input:active,
  html.default-layout .input:focus,
  html.default-layout .input:hover,
  html.default-layout .textarea.is-active,
  html.default-layout .textarea.is-focused,
  html.default-layout .textarea.is-hovered,
  html.default-layout .textarea:active,
  html.default-layout .textarea:focus,
  html.default-layout .textarea:hover {
    border-color: #c5d9e8; }
  html.default-layout .input.is-rounded {
    font-size: 1.5rem; }
  html.default-layout .has-icons-left .input.is-rounded {
    padding-left: 2em; }
  html.default-layout .has-icons-left .icon.is-left {
    margin-top: 0.6em;
    margin-left: 0.9em; }
  html.default-layout .has-icons-left .icon.is-right {
    margin-top: 0.6em;
    margin-right: 0.9em; }
  html.default-layout .box.has-input {
    margin-bottom: 0; }
  html.default-layout .box.has-input .is-bottom select {
    padding: 0 2rem;
    border-top: 1px solid #d8e5ef;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem; }
  html.default-layout .select:not(.is-multiple)::after {
    border: 1px solid #a8c6df;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
    z-index: 1; }
  html.default-layout .help {
    padding: 0.6rem;
    font-size: 1.3rem; }
  html.default-layout .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  html.default-layout .inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
    cursor: pointer;
    /* "hand" cursor */ }
  html.default-layout .inputfile:focus + label,
  html.default-layout .inputfile + label:hover {
    background-color: red; }
  html.default-layout .inputfile + label * {
    pointer-events: none; }
  html.default-layout .CalendarDay__selected_span {
    background: rgba(0, 147, 238, 0.6);
    border: 1px solid white; }
  html.default-layout input[type='checkbox']:not(.is-standard) {
    position: relative;
    appearance: none;
    outline: none;
    width: 5rem !important;
    height: 3rem !important;
    background-color: #ffffff;
    border: 0.1rem solid #d9dadc;
    border-radius: 50px;
    box-shadow: inset -2rem 0 0 0 #ffffff;
    transition-duration: 200ms; }
    html.default-layout input[type='checkbox']:not(.is-standard):after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 2.6rem;
      height: 2.6rem;
      background-color: transparent;
      border-radius: 50%;
      box-shadow: 0.2rem 0.4rem 0.6rem rgba(0, 0, 0, 0.2); }
    html.default-layout input[type='checkbox']:not(.is-standard):checked {
      border-color: #2979ff;
      box-shadow: inset 2rem 0 0 0 #2979ff; }
      html.default-layout input[type='checkbox']:not(.is-standard):checked:after {
        left: 2rem;
        box-shadow: -0.2rem 0.4rem 0.3rem rgba(0, 0, 0, 0.05); }
  html.default-layout .img-rounded,
  html.default-layout .img-rounded > div,
  html.default-layout .img-rounded > img {
    border-radius: .8rem !important; }
  html.default-layout .img-centered {
    margin: 0 auto; }
  html.default-layout .box nav.table-actions {
    margin: 0;
    border-bottom: 1px solid #d8e5ef; }
    html.default-layout .box nav.table-actions + .table th {
      color: #263238;
      background-color: #f2f6f9; }
  html.default-layout .box .table.main-table:first-child {
    margin-top: 0.2rem;
    margin-bottom: 0; }
    html.default-layout .box .table.main-table:first-child th {
      padding: 0.5rem 2rem 0.7rem; }
    html.default-layout .box .table.main-table:first-child tr.active {
      font-weight: bold; }
  html.default-layout .table th {
    text-transform: uppercase;
    font-weight: 500;
    padding: 1.6rem 2rem; }
  html.default-layout .table td {
    color: #455a64;
    padding: 1.6rem 2rem; }
  html.default-layout .table tr.is-not-hoverable:hover {
    background-color: transparent !important; }
  html.default-layout .tabs {
    font-size: 1.25rem; }
  html.default-layout .tabs.is-medium {
    font-size: 1.5rem; }
  html.default-layout .tabs.is-large {
    font-size: 2rem; }
  html.default-layout .tabs a.navbar-item:hover, html.default-layout .tabs a.navbar-item.is-active, html.default-layout .tabs .navbar-link:hover, html.default-layout .tabs .navbar-link.is-active {
    background-color: transparent; }
  html.default-layout .CalendarDay__selected,
  html.default-layout .CalendarDay__selected:hover,
  html.default-layout .CalendarDay__selected:active {
    background: #2979ff;
    color: #fff; }
  html.default-layout .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid #2979ff; }
  html.default-layout .CalendarDay {
    line-height: 35px; }
  html.default-layout .DateInput_input {
    font-size: 1.7rem;
    box-shadow: none;
    border-radius: .6rem !important;
    outline: none;
    align-items: center;
    border: 0.1rem solid #c5d9e8 !important;
    display: inline-flex;
    height: 2.25em;
    justify-content: flex-start;
    position: relative;
    vertical-align: top;
    max-width: 100%; }
  html.default-layout .DateInput {
    width: 100%; }
  html.default-layout .DateInput_input__focused {
    border-bottom: none; }
  html.default-layout .arrow-steps {
    position: relative;
    width: 100%;
    background: #fff; }
    html.default-layout .arrow-steps .step {
      font-size: 1.5rem;
      line-height: 1.6rem;
      text-align: center;
      margin: 0.5rem 0.3rem;
      padding: 1.5rem 1rem 1.5rem 2.8rem;
      width: 9rem;
      float: left;
      position: relative;
      user-select: none;
      transition: background-color 150ms ease-in-out;
      color: #fff;
      background-color: #b0bec5; }
      html.default-layout .arrow-steps .step.is-small {
        width: 5rem;
        padding: 1.5rem 0rem 1.5rem 1.8rem; }
      html.default-layout .arrow-steps .step.is-1 {
        width: calc(8.33333% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-2 {
        width: calc(16.66667% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-3 {
        width: calc(25% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-4 {
        width: calc(33.33333% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-5 {
        width: calc(41.66667% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-6 {
        width: calc(50% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-7 {
        width: calc(58.33333% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-8 {
        width: calc(66.66667% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-9 {
        width: calc(75% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-10 {
        width: calc(83.33333% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-11 {
        width: calc(91.66667% - 0.8rem); }
      html.default-layout .arrow-steps .step.is-12 {
        width: calc(100% - 0.8rem); }
    html.default-layout .arrow-steps .step:after,
    html.default-layout .arrow-steps .step:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: -1.7rem;
      width: 0;
      height: 0;
      border-top: 2.4rem solid transparent;
      border-bottom: 2.2rem solid transparent;
      z-index: 2;
      transition: border-color 150ms ease-in-out;
      border-left: 1.7rem solid #b0bec5; }
    html.default-layout .arrow-steps .step:before {
      right: auto;
      left: 0;
      border-left: 1.7rem solid #fff;
      z-index: 0; }
    html.default-layout .arrow-steps .step:first-child:before {
      border: none; }
    html.default-layout .arrow-steps .step:first-child {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem; }
    html.default-layout .arrow-steps .step .step-icon {
      position: absolute;
      right: 0.2rem;
      bottom: 1.5rem;
      z-index: 2; }
  html.default-layout .arrow-steps .step.is-transparent {
    color: #263238;
    background-color: rgba(0, 0, 0, 0); }
    html.default-layout .arrow-steps .step.is-transparent:after, html.default-layout .arrow-steps .step.is-transparent:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(0, 0, 0, 0); }
  html.default-layout .arrow-steps.bg-transparent .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(0, 0, 0, 0) !important; }
  html.default-layout .arrow-steps .step.is-default {
    color: #fff;
    background-color: #263238; }
    html.default-layout .arrow-steps .step.is-default:after, html.default-layout .arrow-steps .step.is-default:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #263238; }
  html.default-layout .arrow-steps.bg-default .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #263238 !important; }
  html.default-layout .arrow-steps .step.is-white {
    color: #263238;
    background-color: #fff; }
    html.default-layout .arrow-steps .step.is-white:after, html.default-layout .arrow-steps .step.is-white:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #fff; }
  html.default-layout .arrow-steps.bg-white .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #fff !important; }
  html.default-layout .arrow-steps .step.is-black {
    color: #fff;
    background-color: #263238; }
    html.default-layout .arrow-steps .step.is-black:after, html.default-layout .arrow-steps .step.is-black:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #263238; }
  html.default-layout .arrow-steps.bg-black .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #263238 !important; }
  html.default-layout .arrow-steps .step.is-dark {
    color: #fff;
    background-color: #263238; }
    html.default-layout .arrow-steps .step.is-dark:after, html.default-layout .arrow-steps .step.is-dark:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #263238; }
  html.default-layout .arrow-steps.bg-dark .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #263238 !important; }
  html.default-layout .arrow-steps .step.is-slate {
    color: #fff;
    background-color: #455a64; }
    html.default-layout .arrow-steps .step.is-slate:after, html.default-layout .arrow-steps .step.is-slate:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #455a64; }
  html.default-layout .arrow-steps.bg-slate .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #455a64 !important; }
  html.default-layout .arrow-steps .step.is-body-grey {
    color: #263238;
    background-color: #f2f6f9; }
    html.default-layout .arrow-steps .step.is-body-grey:after, html.default-layout .arrow-steps .step.is-body-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #f2f6f9; }
  html.default-layout .arrow-steps.bg-body-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #f2f6f9 !important; }
  html.default-layout .arrow-steps .step.is-light-blue-grey {
    color: #263238;
    background-color: #a8c6df; }
    html.default-layout .arrow-steps .step.is-light-blue-grey:after, html.default-layout .arrow-steps .step.is-light-blue-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #a8c6df; }
  html.default-layout .arrow-steps.bg-light-blue-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #a8c6df !important; }
  html.default-layout .arrow-steps .step.is-light-blue-grey-20 {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.2); }
    html.default-layout .arrow-steps .step.is-light-blue-grey-20:after, html.default-layout .arrow-steps .step.is-light-blue-grey-20:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(168, 198, 223, 0.2); }
  html.default-layout .arrow-steps.bg-light-blue-grey-20 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(168, 198, 223, 0.2) !important; }
  html.default-layout .arrow-steps .step.is-light-blue-grey-80 {
    color: #263238;
    background-color: rgba(168, 198, 223, 0.8); }
    html.default-layout .arrow-steps .step.is-light-blue-grey-80:after, html.default-layout .arrow-steps .step.is-light-blue-grey-80:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(168, 198, 223, 0.8); }
  html.default-layout .arrow-steps.bg-light-blue-grey-80 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(168, 198, 223, 0.8) !important; }
  html.default-layout .arrow-steps .step.is-light-blue-grey-90 {
    color: #263238;
    background-color: rgba(183, 210, 229, 0.9); }
    html.default-layout .arrow-steps .step.is-light-blue-grey-90:after, html.default-layout .arrow-steps .step.is-light-blue-grey-90:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(183, 210, 229, 0.9); }
  html.default-layout .arrow-steps.bg-light-blue-grey-90 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(183, 210, 229, 0.9) !important; }
  html.default-layout .arrow-steps .step.is-bluish-grey {
    color: #fff;
    background-color: #78909c; }
    html.default-layout .arrow-steps .step.is-bluish-grey:after, html.default-layout .arrow-steps .step.is-bluish-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #78909c; }
  html.default-layout .arrow-steps.bg-bluish-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #78909c !important; }
  html.default-layout .arrow-steps .step.is-light-grey {
    color: #263238;
    background-color: #f8f9f9; }
    html.default-layout .arrow-steps .step.is-light-grey:after, html.default-layout .arrow-steps .step.is-light-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #f8f9f9; }
  html.default-layout .arrow-steps.bg-light-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #f8f9f9 !important; }
  html.default-layout .arrow-steps .step.is-dark-sky-blue-10 {
    color: #455a64;
    background-color: rgba(30, 136, 229, 0.1); }
    html.default-layout .arrow-steps .step.is-dark-sky-blue-10:after, html.default-layout .arrow-steps .step.is-dark-sky-blue-10:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(30, 136, 229, 0.1); }
  html.default-layout .arrow-steps.bg-dark-sky-blue-10 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(30, 136, 229, 0.1) !important; }
  html.default-layout .arrow-steps .step.is-light-blue-grey-two {
    color: #263238;
    background-color: #d8e5ef; }
    html.default-layout .arrow-steps .step.is-light-blue-grey-two:after, html.default-layout .arrow-steps .step.is-light-blue-grey-two:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #d8e5ef; }
  html.default-layout .arrow-steps.bg-light-blue-grey-two .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #d8e5ef !important; }
  html.default-layout .arrow-steps .step.is-dark-grey-blue {
    color: #fff;
    background-color: #2c4059; }
    html.default-layout .arrow-steps .step.is-dark-grey-blue:after, html.default-layout .arrow-steps .step.is-dark-grey-blue:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #2c4059; }
  html.default-layout .arrow-steps.bg-dark-grey-blue .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #2c4059 !important; }
  html.default-layout .arrow-steps .step.is-dark-grey-blue-16 {
    color: #263238;
    background-color: rgba(44, 64, 90, 0.16); }
    html.default-layout .arrow-steps .step.is-dark-grey-blue-16:after, html.default-layout .arrow-steps .step.is-dark-grey-blue-16:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(44, 64, 90, 0.16); }
  html.default-layout .arrow-steps.bg-dark-grey-blue-16 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(44, 64, 90, 0.16) !important; }
  html.default-layout .arrow-steps .step.is-dark-2 {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.02); }
    html.default-layout .arrow-steps .step.is-dark-2:after, html.default-layout .arrow-steps .step.is-dark-2:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(38, 50, 56, 0.02); }
  html.default-layout .arrow-steps.bg-dark-2 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(38, 50, 56, 0.02) !important; }
  html.default-layout .arrow-steps .step.is-dark-3 {
    color: #263238;
    background-color: rgba(38, 50, 56, 0.03); }
    html.default-layout .arrow-steps .step.is-dark-3:after, html.default-layout .arrow-steps .step.is-dark-3:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(38, 50, 56, 0.03); }
  html.default-layout .arrow-steps.bg-dark-3 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(38, 50, 56, 0.03) !important; }
  html.default-layout .arrow-steps .step.is-charcoal-grey {
    color: #fff;
    background-color: #37474f; }
    html.default-layout .arrow-steps .step.is-charcoal-grey:after, html.default-layout .arrow-steps .step.is-charcoal-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #37474f; }
  html.default-layout .arrow-steps.bg-charcoal-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #37474f !important; }
  html.default-layout .arrow-steps .step.is-pale-grey {
    color: #263238;
    background-color: #fafbfc; }
    html.default-layout .arrow-steps .step.is-pale-grey:after, html.default-layout .arrow-steps .step.is-pale-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #fafbfc; }
  html.default-layout .arrow-steps.bg-pale-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #fafbfc !important; }
  html.default-layout .arrow-steps .step.is-greyblue {
    color: #fff;
    background-color: #8dabc4; }
    html.default-layout .arrow-steps .step.is-greyblue:after, html.default-layout .arrow-steps .step.is-greyblue:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #8dabc4; }
  html.default-layout .arrow-steps.bg-greyblue .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #8dabc4 !important; }
  html.default-layout .arrow-steps .step.is-bluey-grey {
    color: #fff;
    background-color: #90a4ae; }
    html.default-layout .arrow-steps .step.is-bluey-grey:after, html.default-layout .arrow-steps .step.is-bluey-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #90a4ae; }
  html.default-layout .arrow-steps.bg-bluey-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #90a4ae !important; }
  html.default-layout .arrow-steps .step.is-pale-grey-two {
    color: #263238;
    background-color: #f2f6f9; }
    html.default-layout .arrow-steps .step.is-pale-grey-two:after, html.default-layout .arrow-steps .step.is-pale-grey-two:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #f2f6f9; }
  html.default-layout .arrow-steps.bg-pale-grey-two .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #f2f6f9 !important; }
  html.default-layout .arrow-steps .step.is-pale-grey-three {
    color: #263238;
    background-color: #e5eef5; }
    html.default-layout .arrow-steps .step.is-pale-grey-three:after, html.default-layout .arrow-steps .step.is-pale-grey-three:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #e5eef5; }
  html.default-layout .arrow-steps.bg-pale-grey-three .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #e5eef5 !important; }
  html.default-layout .arrow-steps .step.is-light-blue-grey-three {
    color: #263238;
    background-color: #c5d9e8; }
    html.default-layout .arrow-steps .step.is-light-blue-grey-three:after, html.default-layout .arrow-steps .step.is-light-blue-grey-three:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #c5d9e8; }
  html.default-layout .arrow-steps.bg-light-blue-grey-three .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #c5d9e8 !important; }
  html.default-layout .arrow-steps .step.is-charcoal-grey-60 {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.6); }
    html.default-layout .arrow-steps .step.is-charcoal-grey-60:after, html.default-layout .arrow-steps .step.is-charcoal-grey-60:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(55, 71, 79, 0.6); }
  html.default-layout .arrow-steps.bg-charcoal-grey-60 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(55, 71, 79, 0.6) !important; }
  html.default-layout .arrow-steps .step.is-charcoal-grey-80 {
    color: #fff;
    background-color: rgba(55, 71, 79, 0.8); }
    html.default-layout .arrow-steps .step.is-charcoal-grey-80:after, html.default-layout .arrow-steps .step.is-charcoal-grey-80:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(55, 71, 79, 0.8); }
  html.default-layout .arrow-steps.bg-charcoal-grey-80 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(55, 71, 79, 0.8) !important; }
  html.default-layout .arrow-steps .step.is-azure {
    color: #fff;
    background-color: #0093ee; }
    html.default-layout .arrow-steps .step.is-azure:after, html.default-layout .arrow-steps .step.is-azure:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #0093ee; }
  html.default-layout .arrow-steps.bg-azure .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #0093ee !important; }
  html.default-layout .arrow-steps .step.is-dark-sky-blue {
    color: #fff;
    background-color: #1e88e5; }
    html.default-layout .arrow-steps .step.is-dark-sky-blue:after, html.default-layout .arrow-steps .step.is-dark-sky-blue:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #1e88e5; }
  html.default-layout .arrow-steps.bg-dark-sky-blue .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #1e88e5 !important; }
  html.default-layout .arrow-steps .step.is-apple-green {
    color: #fff;
    background-color: #81c926; }
    html.default-layout .arrow-steps .step.is-apple-green:after, html.default-layout .arrow-steps .step.is-apple-green:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #81c926; }
  html.default-layout .arrow-steps.bg-apple-green .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #81c926 !important; }
  html.default-layout .arrow-steps .step.is-pause-azure {
    color: #fff;
    background-color: #03a9f4; }
    html.default-layout .arrow-steps .step.is-pause-azure:after, html.default-layout .arrow-steps .step.is-pause-azure:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #03a9f4; }
  html.default-layout .arrow-steps.bg-pause-azure .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #03a9f4 !important; }
  html.default-layout .arrow-steps .step.is-production-blue {
    color: #fff;
    background-color: #2979ff; }
    html.default-layout .arrow-steps .step.is-production-blue:after, html.default-layout .arrow-steps .step.is-production-blue:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #2979ff; }
  html.default-layout .arrow-steps.bg-production-blue .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #2979ff !important; }
  html.default-layout .arrow-steps .step.is-production-blue-20 {
    color: #1e88e5;
    background-color: rgba(41, 121, 255, 0.2); }
    html.default-layout .arrow-steps .step.is-production-blue-20:after, html.default-layout .arrow-steps .step.is-production-blue-20:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid rgba(41, 121, 255, 0.2); }
  html.default-layout .arrow-steps.bg-production-blue-20 .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid rgba(41, 121, 255, 0.2) !important; }
  html.default-layout .arrow-steps .step.is-material-violet {
    color: #fff;
    background-color: #aa00ff; }
    html.default-layout .arrow-steps .step.is-material-violet:after, html.default-layout .arrow-steps .step.is-material-violet:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #aa00ff; }
  html.default-layout .arrow-steps.bg-material-violet .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #aa00ff !important; }
  html.default-layout .arrow-steps .step.is-setup-yellow {
    color: #fff;
    background-color: #ffab00; }
    html.default-layout .arrow-steps .step.is-setup-yellow:after, html.default-layout .arrow-steps .step.is-setup-yellow:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #ffab00; }
  html.default-layout .arrow-steps.bg-setup-yellow .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #ffab00 !important; }
  html.default-layout .arrow-steps .step.is-check-green {
    color: #fff;
    background-color: #00c853; }
    html.default-layout .arrow-steps .step.is-check-green:after, html.default-layout .arrow-steps .step.is-check-green:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #00c853; }
  html.default-layout .arrow-steps.bg-check-green .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #00c853 !important; }
  html.default-layout .arrow-steps .step.is-neutral-grey {
    color: #fff;
    background-color: #b0bec5; }
    html.default-layout .arrow-steps .step.is-neutral-grey:after, html.default-layout .arrow-steps .step.is-neutral-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #b0bec5; }
  html.default-layout .arrow-steps.bg-neutral-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #b0bec5 !important; }
  html.default-layout .arrow-steps .step.is-done-grey {
    color: #a8c6df;
    background-color: #ebeef0; }
    html.default-layout .arrow-steps .step.is-done-grey:after, html.default-layout .arrow-steps .step.is-done-grey:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #ebeef0; }
  html.default-layout .arrow-steps.bg-done-grey .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #ebeef0 !important; }
  html.default-layout .arrow-steps .step.is-stressed {
    color: #fff;
    background-color: #aa00ff; }
    html.default-layout .arrow-steps .step.is-stressed:after, html.default-layout .arrow-steps .step.is-stressed:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #aa00ff; }
  html.default-layout .arrow-steps.bg-stressed .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #aa00ff !important; }
  html.default-layout .arrow-steps .step.is-error {
    color: #fff;
    background-color: #ff1744; }
    html.default-layout .arrow-steps .step.is-error:after, html.default-layout .arrow-steps .step.is-error:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #ff1744; }
  html.default-layout .arrow-steps.bg-error .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #ff1744 !important; }
  html.default-layout .arrow-steps .step.is-production {
    color: #fff;
    background-color: #2979ff; }
    html.default-layout .arrow-steps .step.is-production:after, html.default-layout .arrow-steps .step.is-production:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #2979ff; }
  html.default-layout .arrow-steps.bg-production .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #2979ff !important; }
  html.default-layout .arrow-steps .step.is-interrupted {
    color: #fff;
    background-color: #b0bec5; }
    html.default-layout .arrow-steps .step.is-interrupted:after, html.default-layout .arrow-steps .step.is-interrupted:before {
      transition: border-left 150ms ease-in-out;
      border-left: 1.7rem solid #b0bec5; }
  html.default-layout .arrow-steps.bg-interrupted .step:before {
    transition: border-left 150ms ease-in-out;
    border-left: 1.7rem solid #b0bec5 !important; }
  html.default-layout .column.is-list {
    box-shadow: 0.3rem 0 0 0 rgba(168, 198, 223, 0.2), inset -0.1rem 0 0 0 rgba(168, 198, 223, 0.8); }
  html.default-layout .column.is-list .column-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 6rem);
    padding: 3.6rem 2.7rem 2rem; }
    html.default-layout .column.is-list .column-content .column-divider {
      height: 0.1rem;
      background-color: #d8e5ef;
      margin: 0.9rem 0 0.8rem; }
      html.default-layout .column.is-list .column-content .column-divider.is-fullwidth {
        margin: 0.9rem -3rem 0.8rem; }
  html.default-layout .column.is-paddingless .section-header,
  html.default-layout .column.is-paddingless .column-list-element {
    margin: 0 -3.2rem 0 -3.5rem; }
  html.default-layout .column .section-header {
    min-height: 4rem;
    padding: 1.3rem 1.6rem;
    border-top: 0.1rem solid #d8e5ef;
    text-transform: uppercase;
    font-size: 1.1rem;
    letter-spacing: 0.17rem;
    color: #8dabc4;
    background-color: rgba(38, 50, 56, 0.03); }
  html.default-layout .column .column-list-element {
    min-height: 7.4rem;
    padding: 1.6rem 3rem; }
    html.default-layout .column .column-list-element:not(:first-child) {
      border-top: 0.1rem solid #e5eef5; }
    html.default-layout .column .column-list-element:hover, html.default-layout .column .column-list-element.selected {
      background-color: rgba(30, 136, 229, 0.1); }
  html.default-layout .column-group {
    border-top: 1px solid #d8e5ef; }
  html.default-layout .column-group-head {
    margin: 0 -2.3rem 0 -2.4rem !important;
    padding: 0 3rem;
    min-height: 5.5rem; }
  html.default-layout .column .column-head {
    height: 8rem; }
    html.default-layout .column .column-head h1 .icon {
      min-width: 4rem;
      justify-content: flex-start; }
    html.default-layout .column .column-head h1:last-child {
      line-height: 4.5rem; }
  html.default-layout .column .column-body {
    flex-grow: 2; }
    html.default-layout .column .column-body .section:first-child .title:first-child {
      margin-top: 2rem; }
  html.default-layout .column .column-body .scrollable.has-header {
    height: calc(calc(100vh - 6rem) - 8rem) !important; }
  html.default-layout .column .column-body .scrollable.has-footer {
    height: calc(calc(100vh - 6rem) - 8rem) !important; }
  html.default-layout .column .column-body .scrollable.has-header.has-footer {
    height: calc(calc(100vh - 6rem) - 8rem - 8rem) !important; }
  html.default-layout .column .column-body .scrollable.is-settings-main-table {
    height: calc(calc(100vh - 6rem) - 9rem) !important; }
    html.default-layout .column .column-body .scrollable.is-settings-main-table.has-menu {
      height: calc(calc(100vh - 6rem) - 9rem - 3.8rem) !important; }
  html.default-layout .column .column-body .scrollable.has-tabs.is-settings {
    height: calc(calc(100vh - 6rem) - 3.8rem - 13rem) !important; }
  html.default-layout .column .column-footer {
    height: 8rem;
    border-top: 0.1rem solid #d8e5ef; }
  html.default-layout .column section {
    padding: 1.6rem 2.4rem 1.6rem;
    margin-bottom: 0; }
    html.default-layout .column section:not(:last-child) {
      border-bottom: 0.1rem solid #d8e5ef; }
  html.default-layout .default-layout .navbar {
    background-color: #1e88e5;
    color: #fff;
    height: 6rem;
    z-index: 1000; }
    html.default-layout .default-layout .navbar .navbar-brand {
      height: 6rem; }
    html.default-layout .default-layout .navbar a.brand {
      font-size: 2.4rem;
      font-weight: 500;
      color: #fff;
      padding-left: 1.6rem; }
      html.default-layout .default-layout .navbar a.brand img {
        margin-right: 1.6rem;
        max-height: 3.2rem; }
      html.default-layout .default-layout .navbar a.brand:hover {
        background-color: transparent; }
    html.default-layout .default-layout .navbar .navbar-item:hover:not(.brand) {
      background-color: #166dba !important;
      transition: width 0.5s ease, background-color 0.5s ease; }
    html.default-layout .default-layout .navbar .navbar-item.active::after {
      position: absolute;
      content: '';
      bottom: 0;
      right: 1.4rem;
      left: 1.4rem;
      border-bottom: 0.4rem solid rgba(255, 255, 255, 0.7); }
    html.default-layout .default-layout .navbar .navbar-item img {
      border: 0.1rem solid transparent;
      padding: 0.3rem;
      border-radius: 0.4rem;
      max-height: initial; }
    html.default-layout .default-layout .navbar .navbar-item .icon {
      margin-left: 0.8rem;
      margin-right: 0.8rem; }
  html.default-layout .aside {
    display: flex;
    position: fixed;
    background-color: #37474f;
    width: 6rem;
    height: calc(100vh - 6rem);
    padding: 1rem; }
    html.default-layout .aside .sidebar-nav {
      margin-top: 2rem; }
      html.default-layout .aside .sidebar-nav li a {
        display: block;
        margin: 0 auto;
        margin-bottom: 2rem;
        padding: 1rem;
        width: 4rem;
        height: 4rem;
        border-radius: 1rem;
        background-color: rgba(255, 255, 255, 0.2); }
        html.default-layout .aside .sidebar-nav li a.active {
          background-color: #1e88e5; }
      html.default-layout .aside .sidebar-nav.sidebar-bottom li a {
        width: 3.2rem;
        height: 3.2rem;
        padding: 0;
        background-color: transparent;
        color: #78909c; }
        html.default-layout .aside .sidebar-nav.sidebar-bottom li a.active {
          color: #fff; }
  html.default-layout .sb-dashboard-icn {
    width: 20px;
    height: 18px;
    object-fit: contain;
    border-radius: 1px;
    background-color: #fff; }
  html.default-layout .sb-job-board-icn {
    width: 18px;
    height: 20px;
    object-fit: contain;
    background-color: #fff; }
  html.default-layout .sb-factory-icn {
    width: 20px;
    height: 16px;
    object-fit: contain;
    background-color: #fff; }
  html.default-layout .sb-accounting-icn {
    width: 16px;
    height: 24px;
    object-fit: contain;
    background-color: #fff; }
  html.default-layout .sb-default-avatar-icn {
    width: 32px;
    height: 32px;
    object-fit: contain; }
  html.default-layout .sb-settings-icn {
    width: 24px;
    height: 24px;
    object-fit: contain;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    background-color: #607d8b; }
  html.default-layout .level {
    overflow: hidden; }
  html.default-layout .level-body {
    margin-left: 1.3rem; }
  html.default-layout .box {
    color: #90a4ae;
    transition: box-shadow 150ms ease-in-out, height 150ms ease;
    position: relative;
    background-color: #fff;
    box-shadow: none;
    border: solid 1px rgba(168, 198, 223, 0.8);
    border-radius: 6px;
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 1rem 1.6rem; }
    html.default-layout .box > .divider:not(.is-vertical),
    html.default-layout .box .divider:not(.is-vertical) {
      height: 0.1rem;
      background-color: #d8e5ef;
      margin: 0.9rem 0 0.8rem; }
      html.default-layout .box > .divider:not(.is-vertical).is-fullwidth,
      html.default-layout .box .divider:not(.is-vertical).is-fullwidth {
        margin: 0.9rem -1.6rem 0rem; }
    html.default-layout .box.is-active {
      box-shadow: 0 0 1.1rem 0.9rem rgba(168, 198, 223, 0.8); }
    html.default-layout .box .divider.is-vertical {
      width: 0.1rem;
      display: inline-block;
      background-color: #d8e5ef;
      margin: -1rem 0rem; }
    html.default-layout .box.is-notes {
      white-space: pre-line;
      min-height: 8rem;
      font-size: 1.8rem;
      line-height: 2.1rem;
      overflow-wrap: break-word; }
    html.default-layout .box .box-footer {
      margin: 0;
      padding: 0 0.9rem;
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      align-items: stretch;
      display: flex; }
      html.default-layout .box .box-footer .box-footer-item {
        align-items: center;
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0.75rem; }
    html.default-layout .box .level-body {
      flex-grow: 1;
      margin-left: 1.3rem;
      justify-content: flex-start; }
  html.default-layout .is-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 2rem 2rem; }
  html.default-layout .box.main-box {
    min-height: calc(100vh - 6rem - 3.6rem);
    margin: 3.6rem 3.6rem 0;
    border-radius: 0.4rem;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fff;
    padding: 1.2rem 2rem 2rem 2.2rem; }
    html.default-layout .box.main-box.has-actions {
      min-height: calc(100vh - 6rem - 3.6rem - 4.5rem); }
    html.default-layout .box.main-box.has-tabs {
      min-height: calc(100vh - 6rem - 3.6rem - 3.8rem); }
    html.default-layout .box.main-box > .divider.is-fullwidth {
      margin: 1.2rem -2rem 3.2rem -2.2rem; }
    html.default-layout .box.main-box .main-box-footer {
      color: #263238;
      background-color: #f1f1f1;
      padding: 2rem 2rem 2.2rem;
      border-bottom: 1px solid #d8e5ef;
      border-top: 1px solid #d8e5ef;
      margin-bottom: auto; }
  html.default-layout .settings .box.main-box {
    min-height: calc(100vh - 6rem - 3.6rem - 5.1rem); }
  html.default-layout .box .head-actions {
    margin: -1.2rem 0 -1.2rem -2rem;
    padding: 1rem; }
    html.default-layout .box .head-actions .is-main-button,
    html.default-layout .box .head-actions .is-main-button:active,
    html.default-layout .box .head-actions .is-main-button:hover {
      margin: -1.2rem;
      height: 6rem;
      min-width: 6rem;
      margin-right: 10px;
      border-right: 1px solid #d8e5ef !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: transparent;
      box-shadow: none; }
  html.default-layout .box.main-box,
  html.default-layout .box.is-opened {
    display: block; }
  html.default-layout .box.is-small {
    padding: 0.6rem 0.7rem 0.7rem 0.9rem; }
  html.default-layout .box.has-status {
    border-left-width: 0.6rem !important;
    padding: 1rem 1.6rem 1rem 1rem !important; }
  html.default-layout .box.has-transparent-border {
    border: solid 1px rgba(0, 0, 0, 0.5); }
  html.default-layout .box.is-transparent {
    background-color: rgba(0, 0, 0, 0); }
  html.default-layout .box.main-box .info-box.is-transparent {
    background-color: rgba(0, 0, 0, 0);
    color: #263238; }
  html.default-layout .box.has-default-border {
    border: solid 1px rgba(38, 50, 56, 0.5); }
  html.default-layout .box.is-default {
    background-color: #263238; }
  html.default-layout .box.main-box .info-box.is-default {
    background-color: #263238;
    color: #fff; }
  html.default-layout .box.has-white-border {
    border: solid 1px rgba(255, 255, 255, 0.5); }
  html.default-layout .box.is-white {
    background-color: #fff; }
  html.default-layout .box.main-box .info-box.is-white {
    background-color: #fff;
    color: #263238; }
  html.default-layout .box.has-black-border {
    border: solid 1px rgba(38, 50, 56, 0.5); }
  html.default-layout .box.is-black {
    background-color: #263238; }
  html.default-layout .box.main-box .info-box.is-black {
    background-color: #263238;
    color: #fff; }
  html.default-layout .box.has-dark-border {
    border: solid 1px rgba(38, 50, 56, 0.5); }
  html.default-layout .box.is-dark {
    background-color: #263238; }
  html.default-layout .box.main-box .info-box.is-dark {
    background-color: #263238;
    color: #fff; }
  html.default-layout .box.has-slate-border {
    border: solid 1px rgba(69, 90, 100, 0.5); }
  html.default-layout .box.is-slate {
    background-color: #455a64; }
  html.default-layout .box.main-box .info-box.is-slate {
    background-color: #455a64;
    color: #fff; }
  html.default-layout .box.has-body-grey-border {
    border: solid 1px rgba(242, 246, 249, 0.5); }
  html.default-layout .box.is-body-grey {
    background-color: #f2f6f9; }
  html.default-layout .box.main-box .info-box.is-body-grey {
    background-color: #f2f6f9;
    color: #263238; }
  html.default-layout .box.has-light-blue-grey-border {
    border: solid 1px rgba(168, 198, 223, 0.5); }
  html.default-layout .box.is-light-blue-grey {
    background-color: #a8c6df; }
  html.default-layout .box.main-box .info-box.is-light-blue-grey {
    background-color: #a8c6df;
    color: #263238; }
  html.default-layout .box.has-light-blue-grey-20-border {
    border: solid 1px rgba(168, 198, 223, 0.5); }
  html.default-layout .box.is-light-blue-grey-20 {
    background-color: rgba(168, 198, 223, 0.2); }
  html.default-layout .box.main-box .info-box.is-light-blue-grey-20 {
    background-color: rgba(168, 198, 223, 0.2);
    color: #263238; }
  html.default-layout .box.has-light-blue-grey-80-border {
    border: solid 1px rgba(168, 198, 223, 0.5); }
  html.default-layout .box.is-light-blue-grey-80 {
    background-color: rgba(168, 198, 223, 0.8); }
  html.default-layout .box.main-box .info-box.is-light-blue-grey-80 {
    background-color: rgba(168, 198, 223, 0.8);
    color: #263238; }
  html.default-layout .box.has-light-blue-grey-90-border {
    border: solid 1px rgba(183, 210, 229, 0.5); }
  html.default-layout .box.is-light-blue-grey-90 {
    background-color: rgba(183, 210, 229, 0.9); }
  html.default-layout .box.main-box .info-box.is-light-blue-grey-90 {
    background-color: rgba(183, 210, 229, 0.9);
    color: #263238; }
  html.default-layout .box.has-bluish-grey-border {
    border: solid 1px rgba(120, 144, 156, 0.5); }
  html.default-layout .box.is-bluish-grey {
    background-color: #78909c; }
  html.default-layout .box.main-box .info-box.is-bluish-grey {
    background-color: #78909c;
    color: #fff; }
  html.default-layout .box.has-light-grey-border {
    border: solid 1px rgba(248, 249, 249, 0.5); }
  html.default-layout .box.is-light-grey {
    background-color: #f8f9f9; }
  html.default-layout .box.main-box .info-box.is-light-grey {
    background-color: #f8f9f9;
    color: #263238; }
  html.default-layout .box.has-dark-sky-blue-10-border {
    border: solid 1px rgba(30, 136, 229, 0.5); }
  html.default-layout .box.is-dark-sky-blue-10 {
    background-color: rgba(30, 136, 229, 0.1); }
  html.default-layout .box.main-box .info-box.is-dark-sky-blue-10 {
    background-color: rgba(30, 136, 229, 0.1);
    color: #455a64; }
  html.default-layout .box.has-light-blue-grey-two-border {
    border: solid 1px rgba(216, 229, 239, 0.5); }
  html.default-layout .box.is-light-blue-grey-two {
    background-color: #d8e5ef; }
  html.default-layout .box.main-box .info-box.is-light-blue-grey-two {
    background-color: #d8e5ef;
    color: #263238; }
  html.default-layout .box.has-dark-grey-blue-border {
    border: solid 1px rgba(44, 64, 89, 0.5); }
  html.default-layout .box.is-dark-grey-blue {
    background-color: #2c4059; }
  html.default-layout .box.main-box .info-box.is-dark-grey-blue {
    background-color: #2c4059;
    color: #fff; }
  html.default-layout .box.has-dark-grey-blue-16-border {
    border: solid 1px rgba(44, 64, 90, 0.5); }
  html.default-layout .box.is-dark-grey-blue-16 {
    background-color: rgba(44, 64, 90, 0.16); }
  html.default-layout .box.main-box .info-box.is-dark-grey-blue-16 {
    background-color: rgba(44, 64, 90, 0.16);
    color: #263238; }
  html.default-layout .box.has-dark-2-border {
    border: solid 1px rgba(38, 50, 56, 0.5); }
  html.default-layout .box.is-dark-2 {
    background-color: rgba(38, 50, 56, 0.02); }
  html.default-layout .box.main-box .info-box.is-dark-2 {
    background-color: rgba(38, 50, 56, 0.02);
    color: #263238; }
  html.default-layout .box.has-dark-3-border {
    border: solid 1px rgba(38, 50, 56, 0.5); }
  html.default-layout .box.is-dark-3 {
    background-color: rgba(38, 50, 56, 0.03); }
  html.default-layout .box.main-box .info-box.is-dark-3 {
    background-color: rgba(38, 50, 56, 0.03);
    color: #263238; }
  html.default-layout .box.has-charcoal-grey-border {
    border: solid 1px rgba(55, 71, 79, 0.5); }
  html.default-layout .box.is-charcoal-grey {
    background-color: #37474f; }
  html.default-layout .box.main-box .info-box.is-charcoal-grey {
    background-color: #37474f;
    color: #fff; }
  html.default-layout .box.has-pale-grey-border {
    border: solid 1px rgba(250, 251, 252, 0.5); }
  html.default-layout .box.is-pale-grey {
    background-color: #fafbfc; }
  html.default-layout .box.main-box .info-box.is-pale-grey {
    background-color: #fafbfc;
    color: #263238; }
  html.default-layout .box.has-greyblue-border {
    border: solid 1px rgba(141, 171, 196, 0.5); }
  html.default-layout .box.is-greyblue {
    background-color: #8dabc4; }
  html.default-layout .box.main-box .info-box.is-greyblue {
    background-color: #8dabc4;
    color: #fff; }
  html.default-layout .box.has-bluey-grey-border {
    border: solid 1px rgba(144, 164, 174, 0.5); }
  html.default-layout .box.is-bluey-grey {
    background-color: #90a4ae; }
  html.default-layout .box.main-box .info-box.is-bluey-grey {
    background-color: #90a4ae;
    color: #fff; }
  html.default-layout .box.has-pale-grey-two-border {
    border: solid 1px rgba(242, 246, 249, 0.5); }
  html.default-layout .box.is-pale-grey-two {
    background-color: #f2f6f9; }
  html.default-layout .box.main-box .info-box.is-pale-grey-two {
    background-color: #f2f6f9;
    color: #263238; }
  html.default-layout .box.has-pale-grey-three-border {
    border: solid 1px rgba(229, 238, 245, 0.5); }
  html.default-layout .box.is-pale-grey-three {
    background-color: #e5eef5; }
  html.default-layout .box.main-box .info-box.is-pale-grey-three {
    background-color: #e5eef5;
    color: #263238; }
  html.default-layout .box.has-light-blue-grey-three-border {
    border: solid 1px rgba(197, 217, 232, 0.5); }
  html.default-layout .box.is-light-blue-grey-three {
    background-color: #c5d9e8; }
  html.default-layout .box.main-box .info-box.is-light-blue-grey-three {
    background-color: #c5d9e8;
    color: #263238; }
  html.default-layout .box.has-charcoal-grey-60-border {
    border: solid 1px rgba(55, 71, 79, 0.5); }
  html.default-layout .box.is-charcoal-grey-60 {
    background-color: rgba(55, 71, 79, 0.6); }
  html.default-layout .box.main-box .info-box.is-charcoal-grey-60 {
    background-color: rgba(55, 71, 79, 0.6);
    color: #fff; }
  html.default-layout .box.has-charcoal-grey-80-border {
    border: solid 1px rgba(55, 71, 79, 0.5); }
  html.default-layout .box.is-charcoal-grey-80 {
    background-color: rgba(55, 71, 79, 0.8); }
  html.default-layout .box.main-box .info-box.is-charcoal-grey-80 {
    background-color: rgba(55, 71, 79, 0.8);
    color: #fff; }
  html.default-layout .box.has-azure-border {
    border: solid 1px rgba(0, 147, 238, 0.5); }
  html.default-layout .box.is-azure {
    background-color: #0093ee; }
  html.default-layout .box.main-box .info-box.is-azure {
    background-color: #0093ee;
    color: #fff; }
  html.default-layout .box.has-dark-sky-blue-border {
    border: solid 1px rgba(30, 136, 229, 0.5); }
  html.default-layout .box.is-dark-sky-blue {
    background-color: #1e88e5; }
  html.default-layout .box.main-box .info-box.is-dark-sky-blue {
    background-color: #1e88e5;
    color: #fff; }
  html.default-layout .box.has-apple-green-border {
    border: solid 1px rgba(129, 201, 38, 0.5); }
  html.default-layout .box.is-apple-green {
    background-color: #81c926; }
  html.default-layout .box.main-box .info-box.is-apple-green {
    background-color: #81c926;
    color: #fff; }
  html.default-layout .box.has-pause-azure-border {
    border: solid 1px rgba(3, 169, 244, 0.5); }
  html.default-layout .box.is-pause-azure {
    background-color: #03a9f4; }
  html.default-layout .box.main-box .info-box.is-pause-azure {
    background-color: #03a9f4;
    color: #fff; }
  html.default-layout .box.has-production-blue-border {
    border: solid 1px rgba(41, 121, 255, 0.5); }
  html.default-layout .box.is-production-blue {
    background-color: #2979ff; }
  html.default-layout .box.main-box .info-box.is-production-blue {
    background-color: #2979ff;
    color: #fff; }
  html.default-layout .box.has-production-blue-20-border {
    border: solid 1px rgba(41, 121, 255, 0.5); }
  html.default-layout .box.is-production-blue-20 {
    background-color: rgba(41, 121, 255, 0.2); }
  html.default-layout .box.main-box .info-box.is-production-blue-20 {
    background-color: rgba(41, 121, 255, 0.2);
    color: #1e88e5; }
  html.default-layout .box.has-material-violet-border {
    border: solid 1px rgba(170, 0, 255, 0.5); }
  html.default-layout .box.is-material-violet {
    background-color: #aa00ff; }
  html.default-layout .box.main-box .info-box.is-material-violet {
    background-color: #aa00ff;
    color: #fff; }
  html.default-layout .box.has-setup-yellow-border {
    border: solid 1px rgba(255, 171, 0, 0.5); }
  html.default-layout .box.is-setup-yellow {
    background-color: #ffab00; }
  html.default-layout .box.main-box .info-box.is-setup-yellow {
    background-color: #ffab00;
    color: #fff; }
  html.default-layout .box.has-check-green-border {
    border: solid 1px rgba(0, 200, 83, 0.5); }
  html.default-layout .box.is-check-green {
    background-color: #00c853; }
  html.default-layout .box.main-box .info-box.is-check-green {
    background-color: #00c853;
    color: #fff; }
  html.default-layout .box.has-neutral-grey-border {
    border: solid 1px rgba(176, 190, 197, 0.5); }
  html.default-layout .box.is-neutral-grey {
    background-color: #b0bec5; }
  html.default-layout .box.main-box .info-box.is-neutral-grey {
    background-color: #b0bec5;
    color: #fff; }
  html.default-layout .box.has-done-grey-border {
    border: solid 1px rgba(235, 238, 240, 0.5); }
  html.default-layout .box.is-done-grey {
    background-color: #ebeef0; }
  html.default-layout .box.main-box .info-box.is-done-grey {
    background-color: #ebeef0;
    color: #a8c6df; }
  html.default-layout .box.has-stressed-border {
    border: solid 1px rgba(170, 0, 255, 0.5); }
  html.default-layout .box.is-stressed {
    background-color: #aa00ff; }
  html.default-layout .box.main-box .info-box.is-stressed {
    background-color: #aa00ff;
    color: #fff; }
  html.default-layout .box.has-error-border {
    border: solid 1px rgba(255, 23, 68, 0.5); }
  html.default-layout .box.is-error {
    background-color: #ff1744; }
  html.default-layout .box.main-box .info-box.is-error {
    background-color: #ff1744;
    color: #fff; }
  html.default-layout .box.has-production-border {
    border: solid 1px rgba(41, 121, 255, 0.5); }
  html.default-layout .box.is-production {
    background-color: #2979ff; }
  html.default-layout .box.main-box .info-box.is-production {
    background-color: #2979ff;
    color: #fff; }
  html.default-layout .box.has-interrupted-border {
    border: solid 1px rgba(176, 190, 197, 0.5); }
  html.default-layout .box.is-interrupted {
    background-color: #b0bec5; }
  html.default-layout .box.main-box .info-box.is-interrupted {
    background-color: #b0bec5;
    color: #fff; }
  html.default-layout .tag {
    height: 1.7rem;
    font-size: 1rem;
    letter-spacing: 0.15rem;
    border-radius: 1.4rem; }
  html.default-layout .tag.is-large {
    font-size: 1.7rem;
    border-radius: 2rem;
    min-height: 4rem;
    min-width: 4rem;
    font-weight: 700; }
  html.default-layout .tag.is-medium {
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 1.5rem;
    min-height: 3rem;
    min-width: 3rem; }
  html.default-layout .tag.phase-status {
    margin-bottom: 1.6rem; }
  html.default-layout .tag.is-transparent:not(.is-outlined) {
    background-color: rgba(0, 0, 0, 0);
    color: #263238; }
  html.default-layout .tag.is-transparent.is-outlined {
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: transparent;
    color: rgba(0, 0, 0, 0); }
  html.default-layout .tag.is-default:not(.is-outlined) {
    background-color: #263238;
    color: #fff; }
  html.default-layout .tag.is-default.is-outlined {
    border: 1px solid #263238;
    background-color: transparent;
    color: #263238; }
  html.default-layout .tag.is-white:not(.is-outlined) {
    background-color: #fff;
    color: #263238; }
  html.default-layout .tag.is-white.is-outlined {
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff; }
  html.default-layout .tag.is-black:not(.is-outlined) {
    background-color: #263238;
    color: #fff; }
  html.default-layout .tag.is-black.is-outlined {
    border: 1px solid #263238;
    background-color: transparent;
    color: #263238; }
  html.default-layout .tag.is-dark:not(.is-outlined) {
    background-color: #263238;
    color: #fff; }
  html.default-layout .tag.is-dark.is-outlined {
    border: 1px solid #263238;
    background-color: transparent;
    color: #263238; }
  html.default-layout .tag.is-slate:not(.is-outlined) {
    background-color: #455a64;
    color: #fff; }
  html.default-layout .tag.is-slate.is-outlined {
    border: 1px solid #455a64;
    background-color: transparent;
    color: #455a64; }
  html.default-layout .tag.is-body-grey:not(.is-outlined) {
    background-color: #f2f6f9;
    color: #263238; }
  html.default-layout .tag.is-body-grey.is-outlined {
    border: 1px solid #f2f6f9;
    background-color: transparent;
    color: #f2f6f9; }
  html.default-layout .tag.is-light-blue-grey:not(.is-outlined) {
    background-color: #a8c6df;
    color: #263238; }
  html.default-layout .tag.is-light-blue-grey.is-outlined {
    border: 1px solid #a8c6df;
    background-color: transparent;
    color: #a8c6df; }
  html.default-layout .tag.is-light-blue-grey-20:not(.is-outlined) {
    background-color: rgba(168, 198, 223, 0.2);
    color: #263238; }
  html.default-layout .tag.is-light-blue-grey-20.is-outlined {
    border: 1px solid rgba(168, 198, 223, 0.2);
    background-color: transparent;
    color: rgba(168, 198, 223, 0.2); }
  html.default-layout .tag.is-light-blue-grey-80:not(.is-outlined) {
    background-color: rgba(168, 198, 223, 0.8);
    color: #263238; }
  html.default-layout .tag.is-light-blue-grey-80.is-outlined {
    border: 1px solid rgba(168, 198, 223, 0.8);
    background-color: transparent;
    color: rgba(168, 198, 223, 0.8); }
  html.default-layout .tag.is-light-blue-grey-90:not(.is-outlined) {
    background-color: rgba(183, 210, 229, 0.9);
    color: #263238; }
  html.default-layout .tag.is-light-blue-grey-90.is-outlined {
    border: 1px solid rgba(183, 210, 229, 0.9);
    background-color: transparent;
    color: rgba(183, 210, 229, 0.9); }
  html.default-layout .tag.is-bluish-grey:not(.is-outlined) {
    background-color: #78909c;
    color: #fff; }
  html.default-layout .tag.is-bluish-grey.is-outlined {
    border: 1px solid #78909c;
    background-color: transparent;
    color: #78909c; }
  html.default-layout .tag.is-light-grey:not(.is-outlined) {
    background-color: #f8f9f9;
    color: #263238; }
  html.default-layout .tag.is-light-grey.is-outlined {
    border: 1px solid #f8f9f9;
    background-color: transparent;
    color: #f8f9f9; }
  html.default-layout .tag.is-dark-sky-blue-10:not(.is-outlined) {
    background-color: rgba(30, 136, 229, 0.1);
    color: #455a64; }
  html.default-layout .tag.is-dark-sky-blue-10.is-outlined {
    border: 1px solid rgba(30, 136, 229, 0.1);
    background-color: transparent;
    color: rgba(30, 136, 229, 0.1); }
  html.default-layout .tag.is-light-blue-grey-two:not(.is-outlined) {
    background-color: #d8e5ef;
    color: #263238; }
  html.default-layout .tag.is-light-blue-grey-two.is-outlined {
    border: 1px solid #d8e5ef;
    background-color: transparent;
    color: #d8e5ef; }
  html.default-layout .tag.is-dark-grey-blue:not(.is-outlined) {
    background-color: #2c4059;
    color: #fff; }
  html.default-layout .tag.is-dark-grey-blue.is-outlined {
    border: 1px solid #2c4059;
    background-color: transparent;
    color: #2c4059; }
  html.default-layout .tag.is-dark-grey-blue-16:not(.is-outlined) {
    background-color: rgba(44, 64, 90, 0.16);
    color: #263238; }
  html.default-layout .tag.is-dark-grey-blue-16.is-outlined {
    border: 1px solid rgba(44, 64, 90, 0.16);
    background-color: transparent;
    color: rgba(44, 64, 90, 0.16); }
  html.default-layout .tag.is-dark-2:not(.is-outlined) {
    background-color: rgba(38, 50, 56, 0.02);
    color: #263238; }
  html.default-layout .tag.is-dark-2.is-outlined {
    border: 1px solid rgba(38, 50, 56, 0.02);
    background-color: transparent;
    color: rgba(38, 50, 56, 0.02); }
  html.default-layout .tag.is-dark-3:not(.is-outlined) {
    background-color: rgba(38, 50, 56, 0.03);
    color: #263238; }
  html.default-layout .tag.is-dark-3.is-outlined {
    border: 1px solid rgba(38, 50, 56, 0.03);
    background-color: transparent;
    color: rgba(38, 50, 56, 0.03); }
  html.default-layout .tag.is-charcoal-grey:not(.is-outlined) {
    background-color: #37474f;
    color: #fff; }
  html.default-layout .tag.is-charcoal-grey.is-outlined {
    border: 1px solid #37474f;
    background-color: transparent;
    color: #37474f; }
  html.default-layout .tag.is-pale-grey:not(.is-outlined) {
    background-color: #fafbfc;
    color: #263238; }
  html.default-layout .tag.is-pale-grey.is-outlined {
    border: 1px solid #fafbfc;
    background-color: transparent;
    color: #fafbfc; }
  html.default-layout .tag.is-greyblue:not(.is-outlined) {
    background-color: #8dabc4;
    color: #fff; }
  html.default-layout .tag.is-greyblue.is-outlined {
    border: 1px solid #8dabc4;
    background-color: transparent;
    color: #8dabc4; }
  html.default-layout .tag.is-bluey-grey:not(.is-outlined) {
    background-color: #90a4ae;
    color: #fff; }
  html.default-layout .tag.is-bluey-grey.is-outlined {
    border: 1px solid #90a4ae;
    background-color: transparent;
    color: #90a4ae; }
  html.default-layout .tag.is-pale-grey-two:not(.is-outlined) {
    background-color: #f2f6f9;
    color: #263238; }
  html.default-layout .tag.is-pale-grey-two.is-outlined {
    border: 1px solid #f2f6f9;
    background-color: transparent;
    color: #f2f6f9; }
  html.default-layout .tag.is-pale-grey-three:not(.is-outlined) {
    background-color: #e5eef5;
    color: #263238; }
  html.default-layout .tag.is-pale-grey-three.is-outlined {
    border: 1px solid #e5eef5;
    background-color: transparent;
    color: #e5eef5; }
  html.default-layout .tag.is-light-blue-grey-three:not(.is-outlined) {
    background-color: #c5d9e8;
    color: #263238; }
  html.default-layout .tag.is-light-blue-grey-three.is-outlined {
    border: 1px solid #c5d9e8;
    background-color: transparent;
    color: #c5d9e8; }
  html.default-layout .tag.is-charcoal-grey-60:not(.is-outlined) {
    background-color: rgba(55, 71, 79, 0.6);
    color: #fff; }
  html.default-layout .tag.is-charcoal-grey-60.is-outlined {
    border: 1px solid rgba(55, 71, 79, 0.6);
    background-color: transparent;
    color: rgba(55, 71, 79, 0.6); }
  html.default-layout .tag.is-charcoal-grey-80:not(.is-outlined) {
    background-color: rgba(55, 71, 79, 0.8);
    color: #fff; }
  html.default-layout .tag.is-charcoal-grey-80.is-outlined {
    border: 1px solid rgba(55, 71, 79, 0.8);
    background-color: transparent;
    color: rgba(55, 71, 79, 0.8); }
  html.default-layout .tag.is-azure:not(.is-outlined) {
    background-color: #0093ee;
    color: #fff; }
  html.default-layout .tag.is-azure.is-outlined {
    border: 1px solid #0093ee;
    background-color: transparent;
    color: #0093ee; }
  html.default-layout .tag.is-dark-sky-blue:not(.is-outlined) {
    background-color: #1e88e5;
    color: #fff; }
  html.default-layout .tag.is-dark-sky-blue.is-outlined {
    border: 1px solid #1e88e5;
    background-color: transparent;
    color: #1e88e5; }
  html.default-layout .tag.is-apple-green:not(.is-outlined) {
    background-color: #81c926;
    color: #fff; }
  html.default-layout .tag.is-apple-green.is-outlined {
    border: 1px solid #81c926;
    background-color: transparent;
    color: #81c926; }
  html.default-layout .tag.is-pause-azure:not(.is-outlined) {
    background-color: #03a9f4;
    color: #fff; }
  html.default-layout .tag.is-pause-azure.is-outlined {
    border: 1px solid #03a9f4;
    background-color: transparent;
    color: #03a9f4; }
  html.default-layout .tag.is-production-blue:not(.is-outlined) {
    background-color: #2979ff;
    color: #fff; }
  html.default-layout .tag.is-production-blue.is-outlined {
    border: 1px solid #2979ff;
    background-color: transparent;
    color: #2979ff; }
  html.default-layout .tag.is-production-blue-20:not(.is-outlined) {
    background-color: rgba(41, 121, 255, 0.2);
    color: #1e88e5; }
  html.default-layout .tag.is-production-blue-20.is-outlined {
    border: 1px solid rgba(41, 121, 255, 0.2);
    background-color: transparent;
    color: rgba(41, 121, 255, 0.2); }
  html.default-layout .tag.is-material-violet:not(.is-outlined) {
    background-color: #aa00ff;
    color: #fff; }
  html.default-layout .tag.is-material-violet.is-outlined {
    border: 1px solid #aa00ff;
    background-color: transparent;
    color: #aa00ff; }
  html.default-layout .tag.is-setup-yellow:not(.is-outlined) {
    background-color: #ffab00;
    color: #fff; }
  html.default-layout .tag.is-setup-yellow.is-outlined {
    border: 1px solid #ffab00;
    background-color: transparent;
    color: #ffab00; }
  html.default-layout .tag.is-check-green:not(.is-outlined) {
    background-color: #00c853;
    color: #fff; }
  html.default-layout .tag.is-check-green.is-outlined {
    border: 1px solid #00c853;
    background-color: transparent;
    color: #00c853; }
  html.default-layout .tag.is-neutral-grey:not(.is-outlined) {
    background-color: #b0bec5;
    color: #fff; }
  html.default-layout .tag.is-neutral-grey.is-outlined {
    border: 1px solid #b0bec5;
    background-color: transparent;
    color: #b0bec5; }
  html.default-layout .tag.is-done-grey:not(.is-outlined) {
    background-color: #ebeef0;
    color: #a8c6df; }
  html.default-layout .tag.is-done-grey.is-outlined {
    border: 1px solid #ebeef0;
    background-color: transparent;
    color: #ebeef0; }
  html.default-layout .tag.is-stressed:not(.is-outlined) {
    background-color: #aa00ff;
    color: #fff; }
  html.default-layout .tag.is-stressed.is-outlined {
    border: 1px solid #aa00ff;
    background-color: transparent;
    color: #aa00ff; }
  html.default-layout .tag.is-error:not(.is-outlined) {
    background-color: #ff1744;
    color: #fff; }
  html.default-layout .tag.is-error.is-outlined {
    border: 1px solid #ff1744;
    background-color: transparent;
    color: #ff1744; }
  html.default-layout .tag.is-production:not(.is-outlined) {
    background-color: #2979ff;
    color: #fff; }
  html.default-layout .tag.is-production.is-outlined {
    border: 1px solid #2979ff;
    background-color: transparent;
    color: #2979ff; }
  html.default-layout .tag.is-interrupted:not(.is-outlined) {
    background-color: #b0bec5;
    color: #fff; }
  html.default-layout .tag.is-interrupted.is-outlined {
    border: 1px solid #b0bec5;
    background-color: transparent;
    color: #b0bec5; }
  html.default-layout .default-layout .title {
    color: #263238;
    font-weight: 500; }
  html.default-layout .default-layout .title.is-head {
    font-size: 3.2rem;
    color: #2c4059;
    line-height: 1.38em; }
  html.default-layout .default-layout .subtitle.is-1,
  html.default-layout .default-layout .title.is-1 {
    font-size: 2.2rem;
    color: #263238;
    line-height: 3rem; }
    html.default-layout .default-layout .subtitle.is-1 .tag,
    html.default-layout .default-layout .title.is-1 .tag {
      margin-top: -0.5rem; }
  html.default-layout .default-layout .subtitle.is-2,
  html.default-layout .default-layout .title.is-2 {
    font-size: 1.7rem;
    line-height: 1.47em; }
  html.default-layout .default-layout .subtitle.is-3,
  html.default-layout .default-layout .title.is-3 {
    font-size: 1.5rem;
    line-height: 1.33em; }
  html.default-layout .default-layout .subtitle.is-4 * {
    line-height: 1.3em; }
  html.default-layout .progress {
    height: 0.6rem;
    -webkit-appearance: none; }
    html.default-layout .progress::-webkit-progress-bar {
      background-color: rgba(41, 121, 255, 0.15); }
    html.default-layout .progress::-webkit-progress-value {
      background-color: #2979ff; }
    html.default-layout .progress::-moz-progress-bar {
      background-color: #2979ff; }
  html.default-layout .has-phase-status-error .progress {
    -webkit-appearance: none; }
    html.default-layout .has-phase-status-error .progress::-webkit-progress-bar {
      background-color: rgba(255, 23, 68, 0.15); }
    html.default-layout .has-phase-status-error .progress::-webkit-progress-value {
      background-color: #ff1744; }
    html.default-layout .has-phase-status-error .progress::-moz-progress-bar {
      background-color: #ff1744; }
  html.default-layout .has-phase-status-production .progress {
    -webkit-appearance: none; }
    html.default-layout .has-phase-status-production .progress::-webkit-progress-bar {
      background-color: rgba(41, 121, 255, 0.15); }
    html.default-layout .has-phase-status-production .progress::-webkit-progress-value {
      background-color: #2979ff; }
    html.default-layout .has-phase-status-production .progress::-moz-progress-bar {
      background-color: #2979ff; }
  html.default-layout progress.is-transparent {
    -webkit-appearance: none; }
    html.default-layout progress.is-transparent::-webkit-progress-bar {
      background-color: rgba(0, 0, 0, 0.15); }
    html.default-layout progress.is-transparent::-webkit-progress-value {
      background-color: rgba(0, 0, 0, 0); }
    html.default-layout progress.is-transparent::-moz-progress-bar {
      background-color: rgba(0, 0, 0, 0); }
  html.default-layout progress.is-default {
    -webkit-appearance: none; }
    html.default-layout progress.is-default::-webkit-progress-bar {
      background-color: rgba(38, 50, 56, 0.15); }
    html.default-layout progress.is-default::-webkit-progress-value {
      background-color: #263238; }
    html.default-layout progress.is-default::-moz-progress-bar {
      background-color: #263238; }
  html.default-layout progress.is-white {
    -webkit-appearance: none; }
    html.default-layout progress.is-white::-webkit-progress-bar {
      background-color: rgba(255, 255, 255, 0.15); }
    html.default-layout progress.is-white::-webkit-progress-value {
      background-color: #fff; }
    html.default-layout progress.is-white::-moz-progress-bar {
      background-color: #fff; }
  html.default-layout progress.is-black {
    -webkit-appearance: none; }
    html.default-layout progress.is-black::-webkit-progress-bar {
      background-color: rgba(38, 50, 56, 0.15); }
    html.default-layout progress.is-black::-webkit-progress-value {
      background-color: #263238; }
    html.default-layout progress.is-black::-moz-progress-bar {
      background-color: #263238; }
  html.default-layout progress.is-dark {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark::-webkit-progress-bar {
      background-color: rgba(38, 50, 56, 0.15); }
    html.default-layout progress.is-dark::-webkit-progress-value {
      background-color: #263238; }
    html.default-layout progress.is-dark::-moz-progress-bar {
      background-color: #263238; }
  html.default-layout progress.is-slate {
    -webkit-appearance: none; }
    html.default-layout progress.is-slate::-webkit-progress-bar {
      background-color: rgba(69, 90, 100, 0.15); }
    html.default-layout progress.is-slate::-webkit-progress-value {
      background-color: #455a64; }
    html.default-layout progress.is-slate::-moz-progress-bar {
      background-color: #455a64; }
  html.default-layout progress.is-body-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-body-grey::-webkit-progress-bar {
      background-color: rgba(242, 246, 249, 0.15); }
    html.default-layout progress.is-body-grey::-webkit-progress-value {
      background-color: #f2f6f9; }
    html.default-layout progress.is-body-grey::-moz-progress-bar {
      background-color: #f2f6f9; }
  html.default-layout progress.is-light-blue-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-blue-grey::-webkit-progress-bar {
      background-color: rgba(168, 198, 223, 0.15); }
    html.default-layout progress.is-light-blue-grey::-webkit-progress-value {
      background-color: #a8c6df; }
    html.default-layout progress.is-light-blue-grey::-moz-progress-bar {
      background-color: #a8c6df; }
  html.default-layout progress.is-light-blue-grey-20 {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-blue-grey-20::-webkit-progress-bar {
      background-color: rgba(168, 198, 223, 0.15); }
    html.default-layout progress.is-light-blue-grey-20::-webkit-progress-value {
      background-color: rgba(168, 198, 223, 0.2); }
    html.default-layout progress.is-light-blue-grey-20::-moz-progress-bar {
      background-color: rgba(168, 198, 223, 0.2); }
  html.default-layout progress.is-light-blue-grey-80 {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-blue-grey-80::-webkit-progress-bar {
      background-color: rgba(168, 198, 223, 0.15); }
    html.default-layout progress.is-light-blue-grey-80::-webkit-progress-value {
      background-color: rgba(168, 198, 223, 0.8); }
    html.default-layout progress.is-light-blue-grey-80::-moz-progress-bar {
      background-color: rgba(168, 198, 223, 0.8); }
  html.default-layout progress.is-light-blue-grey-90 {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-blue-grey-90::-webkit-progress-bar {
      background-color: rgba(183, 210, 229, 0.15); }
    html.default-layout progress.is-light-blue-grey-90::-webkit-progress-value {
      background-color: rgba(183, 210, 229, 0.9); }
    html.default-layout progress.is-light-blue-grey-90::-moz-progress-bar {
      background-color: rgba(183, 210, 229, 0.9); }
  html.default-layout progress.is-bluish-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-bluish-grey::-webkit-progress-bar {
      background-color: rgba(120, 144, 156, 0.15); }
    html.default-layout progress.is-bluish-grey::-webkit-progress-value {
      background-color: #78909c; }
    html.default-layout progress.is-bluish-grey::-moz-progress-bar {
      background-color: #78909c; }
  html.default-layout progress.is-light-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-grey::-webkit-progress-bar {
      background-color: rgba(248, 249, 249, 0.15); }
    html.default-layout progress.is-light-grey::-webkit-progress-value {
      background-color: #f8f9f9; }
    html.default-layout progress.is-light-grey::-moz-progress-bar {
      background-color: #f8f9f9; }
  html.default-layout progress.is-dark-sky-blue-10 {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark-sky-blue-10::-webkit-progress-bar {
      background-color: rgba(30, 136, 229, 0.15); }
    html.default-layout progress.is-dark-sky-blue-10::-webkit-progress-value {
      background-color: rgba(30, 136, 229, 0.1); }
    html.default-layout progress.is-dark-sky-blue-10::-moz-progress-bar {
      background-color: rgba(30, 136, 229, 0.1); }
  html.default-layout progress.is-light-blue-grey-two {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-blue-grey-two::-webkit-progress-bar {
      background-color: rgba(216, 229, 239, 0.15); }
    html.default-layout progress.is-light-blue-grey-two::-webkit-progress-value {
      background-color: #d8e5ef; }
    html.default-layout progress.is-light-blue-grey-two::-moz-progress-bar {
      background-color: #d8e5ef; }
  html.default-layout progress.is-dark-grey-blue {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark-grey-blue::-webkit-progress-bar {
      background-color: rgba(44, 64, 89, 0.15); }
    html.default-layout progress.is-dark-grey-blue::-webkit-progress-value {
      background-color: #2c4059; }
    html.default-layout progress.is-dark-grey-blue::-moz-progress-bar {
      background-color: #2c4059; }
  html.default-layout progress.is-dark-grey-blue-16 {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark-grey-blue-16::-webkit-progress-bar {
      background-color: rgba(44, 64, 90, 0.15); }
    html.default-layout progress.is-dark-grey-blue-16::-webkit-progress-value {
      background-color: rgba(44, 64, 90, 0.16); }
    html.default-layout progress.is-dark-grey-blue-16::-moz-progress-bar {
      background-color: rgba(44, 64, 90, 0.16); }
  html.default-layout progress.is-dark-2 {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark-2::-webkit-progress-bar {
      background-color: rgba(38, 50, 56, 0.15); }
    html.default-layout progress.is-dark-2::-webkit-progress-value {
      background-color: rgba(38, 50, 56, 0.02); }
    html.default-layout progress.is-dark-2::-moz-progress-bar {
      background-color: rgba(38, 50, 56, 0.02); }
  html.default-layout progress.is-dark-3 {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark-3::-webkit-progress-bar {
      background-color: rgba(38, 50, 56, 0.15); }
    html.default-layout progress.is-dark-3::-webkit-progress-value {
      background-color: rgba(38, 50, 56, 0.03); }
    html.default-layout progress.is-dark-3::-moz-progress-bar {
      background-color: rgba(38, 50, 56, 0.03); }
  html.default-layout progress.is-charcoal-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-charcoal-grey::-webkit-progress-bar {
      background-color: rgba(55, 71, 79, 0.15); }
    html.default-layout progress.is-charcoal-grey::-webkit-progress-value {
      background-color: #37474f; }
    html.default-layout progress.is-charcoal-grey::-moz-progress-bar {
      background-color: #37474f; }
  html.default-layout progress.is-pale-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-pale-grey::-webkit-progress-bar {
      background-color: rgba(250, 251, 252, 0.15); }
    html.default-layout progress.is-pale-grey::-webkit-progress-value {
      background-color: #fafbfc; }
    html.default-layout progress.is-pale-grey::-moz-progress-bar {
      background-color: #fafbfc; }
  html.default-layout progress.is-greyblue {
    -webkit-appearance: none; }
    html.default-layout progress.is-greyblue::-webkit-progress-bar {
      background-color: rgba(141, 171, 196, 0.15); }
    html.default-layout progress.is-greyblue::-webkit-progress-value {
      background-color: #8dabc4; }
    html.default-layout progress.is-greyblue::-moz-progress-bar {
      background-color: #8dabc4; }
  html.default-layout progress.is-bluey-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-bluey-grey::-webkit-progress-bar {
      background-color: rgba(144, 164, 174, 0.15); }
    html.default-layout progress.is-bluey-grey::-webkit-progress-value {
      background-color: #90a4ae; }
    html.default-layout progress.is-bluey-grey::-moz-progress-bar {
      background-color: #90a4ae; }
  html.default-layout progress.is-pale-grey-two {
    -webkit-appearance: none; }
    html.default-layout progress.is-pale-grey-two::-webkit-progress-bar {
      background-color: rgba(242, 246, 249, 0.15); }
    html.default-layout progress.is-pale-grey-two::-webkit-progress-value {
      background-color: #f2f6f9; }
    html.default-layout progress.is-pale-grey-two::-moz-progress-bar {
      background-color: #f2f6f9; }
  html.default-layout progress.is-pale-grey-three {
    -webkit-appearance: none; }
    html.default-layout progress.is-pale-grey-three::-webkit-progress-bar {
      background-color: rgba(229, 238, 245, 0.15); }
    html.default-layout progress.is-pale-grey-three::-webkit-progress-value {
      background-color: #e5eef5; }
    html.default-layout progress.is-pale-grey-three::-moz-progress-bar {
      background-color: #e5eef5; }
  html.default-layout progress.is-light-blue-grey-three {
    -webkit-appearance: none; }
    html.default-layout progress.is-light-blue-grey-three::-webkit-progress-bar {
      background-color: rgba(197, 217, 232, 0.15); }
    html.default-layout progress.is-light-blue-grey-three::-webkit-progress-value {
      background-color: #c5d9e8; }
    html.default-layout progress.is-light-blue-grey-three::-moz-progress-bar {
      background-color: #c5d9e8; }
  html.default-layout progress.is-charcoal-grey-60 {
    -webkit-appearance: none; }
    html.default-layout progress.is-charcoal-grey-60::-webkit-progress-bar {
      background-color: rgba(55, 71, 79, 0.15); }
    html.default-layout progress.is-charcoal-grey-60::-webkit-progress-value {
      background-color: rgba(55, 71, 79, 0.6); }
    html.default-layout progress.is-charcoal-grey-60::-moz-progress-bar {
      background-color: rgba(55, 71, 79, 0.6); }
  html.default-layout progress.is-charcoal-grey-80 {
    -webkit-appearance: none; }
    html.default-layout progress.is-charcoal-grey-80::-webkit-progress-bar {
      background-color: rgba(55, 71, 79, 0.15); }
    html.default-layout progress.is-charcoal-grey-80::-webkit-progress-value {
      background-color: rgba(55, 71, 79, 0.8); }
    html.default-layout progress.is-charcoal-grey-80::-moz-progress-bar {
      background-color: rgba(55, 71, 79, 0.8); }
  html.default-layout progress.is-azure {
    -webkit-appearance: none; }
    html.default-layout progress.is-azure::-webkit-progress-bar {
      background-color: rgba(0, 147, 238, 0.15); }
    html.default-layout progress.is-azure::-webkit-progress-value {
      background-color: #0093ee; }
    html.default-layout progress.is-azure::-moz-progress-bar {
      background-color: #0093ee; }
  html.default-layout progress.is-dark-sky-blue {
    -webkit-appearance: none; }
    html.default-layout progress.is-dark-sky-blue::-webkit-progress-bar {
      background-color: rgba(30, 136, 229, 0.15); }
    html.default-layout progress.is-dark-sky-blue::-webkit-progress-value {
      background-color: #1e88e5; }
    html.default-layout progress.is-dark-sky-blue::-moz-progress-bar {
      background-color: #1e88e5; }
  html.default-layout progress.is-apple-green {
    -webkit-appearance: none; }
    html.default-layout progress.is-apple-green::-webkit-progress-bar {
      background-color: rgba(129, 201, 38, 0.15); }
    html.default-layout progress.is-apple-green::-webkit-progress-value {
      background-color: #81c926; }
    html.default-layout progress.is-apple-green::-moz-progress-bar {
      background-color: #81c926; }
  html.default-layout progress.is-pause-azure {
    -webkit-appearance: none; }
    html.default-layout progress.is-pause-azure::-webkit-progress-bar {
      background-color: rgba(3, 169, 244, 0.15); }
    html.default-layout progress.is-pause-azure::-webkit-progress-value {
      background-color: #03a9f4; }
    html.default-layout progress.is-pause-azure::-moz-progress-bar {
      background-color: #03a9f4; }
  html.default-layout progress.is-production-blue {
    -webkit-appearance: none; }
    html.default-layout progress.is-production-blue::-webkit-progress-bar {
      background-color: rgba(41, 121, 255, 0.15); }
    html.default-layout progress.is-production-blue::-webkit-progress-value {
      background-color: #2979ff; }
    html.default-layout progress.is-production-blue::-moz-progress-bar {
      background-color: #2979ff; }
  html.default-layout progress.is-production-blue-20 {
    -webkit-appearance: none; }
    html.default-layout progress.is-production-blue-20::-webkit-progress-bar {
      background-color: rgba(41, 121, 255, 0.15); }
    html.default-layout progress.is-production-blue-20::-webkit-progress-value {
      background-color: rgba(41, 121, 255, 0.2); }
    html.default-layout progress.is-production-blue-20::-moz-progress-bar {
      background-color: rgba(41, 121, 255, 0.2); }
  html.default-layout progress.is-material-violet {
    -webkit-appearance: none; }
    html.default-layout progress.is-material-violet::-webkit-progress-bar {
      background-color: rgba(170, 0, 255, 0.15); }
    html.default-layout progress.is-material-violet::-webkit-progress-value {
      background-color: #aa00ff; }
    html.default-layout progress.is-material-violet::-moz-progress-bar {
      background-color: #aa00ff; }
  html.default-layout progress.is-setup-yellow {
    -webkit-appearance: none; }
    html.default-layout progress.is-setup-yellow::-webkit-progress-bar {
      background-color: rgba(255, 171, 0, 0.15); }
    html.default-layout progress.is-setup-yellow::-webkit-progress-value {
      background-color: #ffab00; }
    html.default-layout progress.is-setup-yellow::-moz-progress-bar {
      background-color: #ffab00; }
  html.default-layout progress.is-check-green {
    -webkit-appearance: none; }
    html.default-layout progress.is-check-green::-webkit-progress-bar {
      background-color: rgba(0, 200, 83, 0.15); }
    html.default-layout progress.is-check-green::-webkit-progress-value {
      background-color: #00c853; }
    html.default-layout progress.is-check-green::-moz-progress-bar {
      background-color: #00c853; }
  html.default-layout progress.is-neutral-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-neutral-grey::-webkit-progress-bar {
      background-color: rgba(176, 190, 197, 0.15); }
    html.default-layout progress.is-neutral-grey::-webkit-progress-value {
      background-color: #b0bec5; }
    html.default-layout progress.is-neutral-grey::-moz-progress-bar {
      background-color: #b0bec5; }
  html.default-layout progress.is-done-grey {
    -webkit-appearance: none; }
    html.default-layout progress.is-done-grey::-webkit-progress-bar {
      background-color: rgba(235, 238, 240, 0.15); }
    html.default-layout progress.is-done-grey::-webkit-progress-value {
      background-color: #ebeef0; }
    html.default-layout progress.is-done-grey::-moz-progress-bar {
      background-color: #ebeef0; }
  html.default-layout progress.is-stressed {
    -webkit-appearance: none; }
    html.default-layout progress.is-stressed::-webkit-progress-bar {
      background-color: rgba(170, 0, 255, 0.15); }
    html.default-layout progress.is-stressed::-webkit-progress-value {
      background-color: #aa00ff; }
    html.default-layout progress.is-stressed::-moz-progress-bar {
      background-color: #aa00ff; }
  html.default-layout progress.is-error {
    -webkit-appearance: none; }
    html.default-layout progress.is-error::-webkit-progress-bar {
      background-color: rgba(255, 23, 68, 0.15); }
    html.default-layout progress.is-error::-webkit-progress-value {
      background-color: #ff1744; }
    html.default-layout progress.is-error::-moz-progress-bar {
      background-color: #ff1744; }
  html.default-layout progress.is-production {
    -webkit-appearance: none; }
    html.default-layout progress.is-production::-webkit-progress-bar {
      background-color: rgba(41, 121, 255, 0.15); }
    html.default-layout progress.is-production::-webkit-progress-value {
      background-color: #2979ff; }
    html.default-layout progress.is-production::-moz-progress-bar {
      background-color: #2979ff; }
  html.default-layout progress.is-interrupted {
    -webkit-appearance: none; }
    html.default-layout progress.is-interrupted::-webkit-progress-bar {
      background-color: rgba(176, 190, 197, 0.15); }
    html.default-layout progress.is-interrupted::-webkit-progress-value {
      background-color: #b0bec5; }
    html.default-layout progress.is-interrupted::-moz-progress-bar {
      background-color: #b0bec5; }
  html.default-layout .panel-block {
    font-size: 1.5rem;
    width: 22rem;
    padding: 0; }
    html.default-layout .panel-block.is-not-clickable {
      cursor: initial; }
      html.default-layout .panel-block.is-not-clickable:hover {
        background-color: white;
        font-weight: initial; }
    html.default-layout .panel-block .panel-item {
      padding: 0.7em 0.75em;
      width: 100%;
      height: 100%;
      border: none; }
    html.default-layout .panel-block:first-child {
      border-top-right-radius: 0.6rem;
      border-top-left-radius: 0.6rem; }
      html.default-layout .panel-block:first-child:not(:last-child) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; }
    html.default-layout .panel-block:last-child {
      border-bottom-right-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem; }
      html.default-layout .panel-block:last-child:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: 0; }
    html.default-layout .panel-block:hover {
      color: #455a64;
      background-color: rgba(30, 136, 229, 0.1);
      font-weight: bold; }
    html.default-layout .panel-block:not(:last-child) {
      border-bottom: 1px solid #e5eef5; }
  html.default-layout .panel-dropdown {
    z-index: 20;
    box-shadow: 0 6px 12px 0 rgba(44, 64, 90, 0.16);
    border-radius: 0.6rem;
    background-color: white; }
    html.default-layout .panel-dropdown .panel-block {
      width: 22rem; }
    html.default-layout .panel-dropdown .menu-background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 5; }
  html.default-layout .depends-on-container .panel-block {
    width: 40rem; }
  html.default-layout .timeline .timeline-item {
    position: relative;
    padding: 0 0.6rem; }
    html.default-layout .timeline .timeline-item:not(:last-child) .timeline-content {
      border-left: 2px solid #c5d9e8; }
    html.default-layout .timeline .timeline-item:last-child .timeline-content {
      padding-bottom: 1rem !important; }
    html.default-layout .timeline .timeline-item .timeline-image {
      position: absolute;
      top: -0.4rem;
      left: 0.5rem; }
      html.default-layout .timeline .timeline-item .timeline-image.is-16x16 {
        height: 1.6rem;
        width: 1.6rem;
        outline: 0.3rem solid #fff; }
      html.default-layout .timeline .timeline-item .timeline-image.is-24x24 {
        height: 2.4rem;
        width: 2.4rem;
        outline: 0.3rem solid #fff; }
      html.default-layout .timeline .timeline-item .timeline-image.is-32x32 {
        height: 3.2rem;
        width: 3.2rem;
        outline: 0.3rem solid #fff; }
      html.default-layout .timeline .timeline-item .timeline-image.is-48x48 {
        height: 4.8rem;
        width: 4.8rem;
        outline: 0.3rem solid #fff; }
      html.default-layout .timeline .timeline-item .timeline-image.is-64x64 {
        height: 6.4rem;
        width: 6.4rem;
        outline: 0.3rem solid #fff; }
      html.default-layout .timeline .timeline-item .timeline-image.is-96x96 {
        height: 9.6rem;
        width: 9.6rem;
        outline: 0.3rem solid #fff; }
      html.default-layout .timeline .timeline-item .timeline-image.is-128x128 {
        height: 12.8rem;
        width: 12.8rem;
        outline: 0.3rem solid #fff; }
    html.default-layout .timeline .timeline-item .timeline-content {
      margin-left: 1rem;
      padding-left: 2.4rem;
      padding-bottom: 3.5rem; }
      html.default-layout .timeline .timeline-item .timeline-content .level-right .level-item {
        justify-content: flex-end; }
        html.default-layout .timeline .timeline-item .timeline-content .level-right .level-item.has-popover {
          cursor: text; }
  html.default-layout .box.job {
    min-height: 6.4rem;
    padding: 1rem 1.2rem 1.6rem 1.6rem;
    margin-bottom: 0.6rem; }
    html.default-layout .box.job .title {
      margin-bottom: 2rem; }
    html.default-layout .box.job .title {
      color: #263238; }
    html.default-layout .box.job .subtitle {
      color: #78909c; }
    html.default-layout .box.job.is-opened {
      padding: 1rem 1.2rem 0 1.6rem; }
    html.default-layout .box.job .divider.is-fullwidth {
      margin: 0 -1.2rem 0 -1.6rem !important; }
  html.default-layout .job a {
    color: #a8c6df; }
  html.default-layout .job .meta {
    margin-top: 0.7rem; }
    html.default-layout .job .meta > * {
      color: #90a4ae;
      font-size: 1.3rem;
      line-height: 1.1em; }
  html.default-layout .has-job-status-deleted.is-closed,
  html.default-layout .has-job-status-error.is-closed {
    border-color: #ff1744; }
    html.default-layout .has-job-status-deleted.is-closed:focus, html.default-layout .has-job-status-deleted.is-closed:hover,
    html.default-layout .has-job-status-error.is-closed:focus,
    html.default-layout .has-job-status-error.is-closed:hover {
      box-shadow: 0 0 1.1rem 0.9rem #ff1744; }
  html.default-layout .box.phase {
    box-sizing: border-box;
    padding: 1rem 1.2rem 1rem 1.6rem;
    margin-bottom: 1.2rem;
    min-height: 10rem; }
    html.default-layout .box.phase .title {
      margin-bottom: 2rem; }
    html.default-layout .box.phase .meta {
      display: inline-flex; }
    html.default-layout .box.phase .title {
      color: #263238; }
    html.default-layout .box.phase .subtitle {
      color: #78909c; }
    html.default-layout .box.phase.is-temporary {
      border: 1px solid #d8e5ef; }
    html.default-layout .box.phase.is-detailed {
      padding: 1rem 1.2rem 1rem 1.6rem;
      height: 12rem;
      border: 0.1rem solid rgba(168, 198, 223, 0.8);
      border-top: 0.8rem solid rgba(168, 198, 223, 0.8);
      box-shadow: 0 0 1.1rem 0.9rem transparent;
      transition: box-shadow 150ms ease-in-out, border 150ms ease-in-out, border-top 150ms ease-in-out;
      background-color: #f8f9f9; }
      html.default-layout .box.phase.is-detailed > div {
        height: calc(12rem - 2rem); }
      html.default-layout .box.phase.is-detailed:not([class^='has-phase-status-']) {
        border-top: 0.8rem solid rgba(168, 198, 223, 0.8) !important; }
      html.default-layout .box.phase.is-detailed.has-phase-status-production {
        border-top: 0.8rem solid #2979ff !important; }
      html.default-layout .box.phase.is-detailed.has-phase-status-material {
        border-top: 0.8rem solid #aa00ff !important; }
      html.default-layout .box.phase.is-detailed.has-phase-status-setup {
        border-top: 0.8rem solid #ffab00 !important; }
      html.default-layout .box.phase.is-detailed.has-phase-status-error {
        border-top: 0.8rem solid #ff1744 !important; }
      html.default-layout .box.phase.is-detailed.has-phase-status-check {
        border-top: 0.8rem solid #00c853 !important; }
      html.default-layout .box.phase.is-detailed.has-phase-status-pause {
        border-top: 0.8rem solid #03a9f4 !important; }
      html.default-layout .box.phase.is-detailed:focus, html.default-layout .box.phase.is-detailed:hover, html.default-layout .box.phase.is-detailed.is-active {
        box-shadow: 0 0 1.1rem 0.9rem rgba(168, 198, 223, 0.8); }
        html.default-layout .box.phase.is-detailed:focus.has-phase-status-production, html.default-layout .box.phase.is-detailed:hover.has-phase-status-production, html.default-layout .box.phase.is-detailed.is-active.has-phase-status-production {
          border-color: #2979ff;
          box-shadow: 0 0 1.1rem 0.9rem #2979ff; }
        html.default-layout .box.phase.is-detailed:focus.has-phase-status-material, html.default-layout .box.phase.is-detailed:hover.has-phase-status-material, html.default-layout .box.phase.is-detailed.is-active.has-phase-status-material {
          border-color: #aa00ff;
          box-shadow: 0 0 1.1rem 0.9rem #aa00ff; }
        html.default-layout .box.phase.is-detailed:focus.has-phase-status-setup, html.default-layout .box.phase.is-detailed:hover.has-phase-status-setup, html.default-layout .box.phase.is-detailed.is-active.has-phase-status-setup {
          border-color: #ffab00;
          box-shadow: 0 0 1.1rem 0.9rem #ffab00; }
        html.default-layout .box.phase.is-detailed:focus.has-phase-status-error, html.default-layout .box.phase.is-detailed:hover.has-phase-status-error, html.default-layout .box.phase.is-detailed.is-active.has-phase-status-error {
          border-color: #ff1744;
          box-shadow: 0 0 1.1rem 0.9rem #ff1744; }
        html.default-layout .box.phase.is-detailed:focus.has-phase-status-check, html.default-layout .box.phase.is-detailed:hover.has-phase-status-check, html.default-layout .box.phase.is-detailed.is-active.has-phase-status-check {
          border-color: #00c853;
          box-shadow: 0 0 1.1rem 0.9rem #00c853; }
        html.default-layout .box.phase.is-detailed:focus.has-phase-status-pause, html.default-layout .box.phase.is-detailed:hover.has-phase-status-pause, html.default-layout .box.phase.is-detailed.is-active.has-phase-status-pause {
          border-color: #03a9f4;
          box-shadow: 0 0 1.1rem 0.9rem #03a9f4; }
  html.default-layout .phase-status {
    padding: 0.6rem 2.4rem;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.54em;
    color: white; }
    html.default-layout .phase-status [class^='icon-'] {
      margin-right: 0.6rem; }
  html.default-layout .phase-log .operators .image {
    display: inline-block;
    margin-left: 0.6rem; }
  html.default-layout .phase-container {
    padding-right: 1rem; }
    html.default-layout .phase-container .phase.phase-short {
      color: #455a64;
      min-height: 5.5rem;
      display: flex; }
      html.default-layout .phase-container .phase.phase-short .meta {
        margin-top: 0.5rem;
        color: #90a4ae; }
        html.default-layout .phase-container .phase.phase-short .meta .level-item {
          flex-grow: 0;
          justify-content: flex-start; }
        html.default-layout .phase-container .phase.phase-short .meta .progress-container {
          min-width: 12.8rem;
          max-width: 18rem; }
    html.default-layout .phase-container .phase:not(:last-child) {
      border-bottom: 0.1rem solid #d8e5ef; }
    html.default-layout .phase-container p small {
      font-size: 1.3rem;
      color: #90a4ae; }
  html.default-layout .phase.is-detailed {
    min-height: 12rem; }
    html.default-layout .phase.is-detailed .level-item.time-container {
      min-width: 10rem;
      justify-content: flex-start; }
    html.default-layout .phase.is-detailed .level-item.progress-container {
      justify-content: flex-start; }
    html.default-layout .phase.is-detailed .level-item.percentage-container {
      min-width: 6rem;
      justify-content: flex-start; }
  html.default-layout .has-phase-status-done .phase-status {
    background-color: #2979ff; }
  html.default-layout .has-phase-status-error .phase-status {
    background-color: #ff1744; }
  html.default-layout .has-phase-status-new .phase-status {
    background-color: #90a4ae; }
  html.default-layout .box.is-doc {
    padding: 0.8rem 0.4rem 0.4rem;
    position: relative;
    max-width: 12rem;
    min-height: 8.8rem;
    display: flex;
    flex-direction: column;
    border: none;
    color: #455a64;
    font-size: 1.3rem;
    justify-content: center; }
    html.default-layout .box.is-doc.is-editable {
      border: solid 1px rgba(183, 210, 229, 0.9); }
      html.default-layout .box.is-doc.is-editable .remove {
        position: absolute;
        top: -1rem;
        right: -0.6rem;
        padding: 0.1rem;
        width: 2rem;
        height: 2rem;
        border-radius: 1rem;
        color: white; }
        html.default-layout .box.is-doc.is-editable .remove .delete {
          background-color: #ff1744; }
          html.default-layout .box.is-doc.is-editable .remove .delete:hover {
            background-color: #ff4a6d; }
    html.default-layout .box.is-doc img {
      margin: 0 auto 0.4rem; }
    html.default-layout .box.is-doc .fa {
      font-size: 1.6rem; }
  html.default-layout .level.is-doc {
    margin-bottom: 0;
    padding: 1.5rem 0; }
    html.default-layout .level.is-doc:not(:last-child) {
      border-bottom: 0.1rem solid #d8e5ef; }

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body,
button,
input,
select,
textarea {
  font-family: LLCircularWeb, "Helvetica Neue", Helvetica, sans-serif; }

.doc-modal .modal-content {
  width: calc(100vw - 10%);
  height: calc(100vh - 10%); }

.swal2-popup {
  font-size: 1.6rem !important;
  font-family: LLCircularWeb, "Helvetica Neue", Helvetica, sans-serif !important; }

//.CalendarDay__highlighted_calendar,
.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected:active {
  background: get-color(production-blue);
  color: get-color-contrast(production-blue);
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid get-color(production-blue);
}
.CalendarDay {
  line-height: 35px;
}
.DateInput_input {
  font-size:1.7rem;

  box-shadow: none;
  border-radius: .6rem !important;
  outline: none;

  align-items: center;
  border: .1rem solid get-color(light-blue-grey-three) !important;
  display: inline-flex;
  height: 2.25em;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;
  max-width: 100%;

  //box-shadow: none;
  //border-radius: .6rem;
  //
  //
  //border-radius: .6rem;
  //border-color: #c5d9e8;
  //outline: none;
  //border: 1px solid transparent;
  //border-bottom: none;
  //border-color: get-color(light-blue-grey-three);
  //outline: none;
  //
  //align-items: center;
  //display: -ms-inline-flexbox;
  //display: inline-flex;
  //height: 2.25em;
  //justify-content: flex-start;
  //line-height: 1.5;
  //padding-bottom: calc(0.375em - .1rem);
  //padding-left: calc(0.625em - .1rem);
  //padding-right: calc(0.625em - .1rem);
  //padding-top: calc(0.375em - .1rem);
  //position: relative;
  //vertical-align: top;
  //background-color: white;
  //color: #363636;
  //max-width: 100%;
  //width: 100%;
}
.DateInput {
  width: 100%;
}
.DateInput_input__focused {
  //border-bottom: 2px solid get-color(production-blue);
  border-bottom: none;
  //border-color: get-color(light-blue-grey-three);
}
//.CalendarDay__highlighted_calendar:hover {
//  background: darken(get-color(production-blue), 5);
//  color: darken(get-color-contrast(production-blue), 5);
//}
//
//.CalendarDay__highlighted_calendar:active {
//  background: darken(get-color(production-blue), 5);
//  color: darken(get-color-contrast(production-blue), 5);
//}
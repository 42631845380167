$dimensions: 16 24 32 48 64 96 128 !default;

.timeline {
  .timeline-item {
    position: relative;
    padding: 0 0.6rem;
    //.timeline-content {
    //  padding-bottom: 2rem;
    //}
    &:not(:last-child) .timeline-content {
      border-left: 2px solid get-color(light-blue-grey-three);
    }
    &:last-child .timeline-content {
      padding-bottom: 1rem !important;
    }
    .timeline-image {
      position: absolute;
      top: -0.4rem;
      left: 0.5rem;
      @each $dimension in $dimensions {
        &.is-#{$dimension}x#{$dimension} {
          height: $dimension * 0.1rem;
          width: $dimension * 0.1rem;
          outline: 0.3rem solid get-color('white');
        }
      }
    }
    .timeline-content {
      margin-left: 1rem;
      padding-left: 2.4rem;
      padding-bottom: 3.5rem;
      .level-right .level-item {
        justify-content: flex-end;
        &.has-popover {
          cursor: text;
        }
      }
    }
  }
}

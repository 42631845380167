@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: rgba($background, 0.85);
    border-color: rgba($border, 0.85);
  }
  &:hover {
    color: $color;
    background-color: rgba($background, 0.85);
    border-color: rgba($border, 0.85);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: rgba($background, 0.85);
    border-color: rgba($border, 0.85);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: rgba($background, 0.75);
      border-color: rgba($border, 0.75);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
@each $name, $colors in $app-colors {
  .button.is-#{$name}:not(.is-outlined) {
    @include button-variant(map-get($colors, font), map-get($colors, base), map-get($colors, base));
  }
  .button.is-inverted.is-#{$name}:not(.is-outlined) {
    @include button-variant(map-get($colors, base), map-get($colors, font), map-get($colors, font));
  }

  .button.is-outlined.is-#{$name} {
    border-color: map-get($colors, base);
    color: map-get($colors, base);
    background: none;

    &:hover,
    &:active,
    &:active:hover,
    &:active:focus,
    &:focus,
    &.active {
      border-color: map-get($colors, base);
      color: map-get($colors, font);
      background-color: map-get($colors, base);
    }
  }
  .button.is-#{$name}.btn-no-border:not(.active) {
    border-color: transparent;
  }
}

.button {
  font-weight: 500;
  border-radius: 4px;
  color: get-color(bluish-grey);
}
.button.is-large {
  height: 3.2rem;
  min-width: 4rem;
  font-size: 1.4rem;
}
.button.is-huge {
  height: 4.8rem;
  font-size: 1.7rem;
}
.button.is-link {
  text-decoration: none;
  &:active,
  &.is-active {
    box-shadow: none;
  }
  &[disabled] {
    background-color: transparent;
  }
}
.button.is-link,
.button.is-link:hover,
.button.is-link.is-hovered,
.button.is-link:focus,
.button.is-link.is-focused,
.button.is-link:active,
.button.is-link.is-active {
  background-color: transparent;
}
.button.is-link:focus:not(:active),
.button.is-link.is-focused:not(:active) {
  box-shadow: unset;
}

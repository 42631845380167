.table td, .table th {
  //border: 1px solid get-color('light-blue-grey-80');
}
.box nav.table-actions {
  margin: 0;
  border-bottom: 1px solid #d8e5ef;
  & + .table {
    th {
      color: get-color('dark');
      background-color: get-color(pale-grey-two);
    }
  }
}
.box .table.main-table {
  &:first-child {
    margin-top: 0.2rem;
    margin-bottom: 0;
    th {
      //padding: 1.4rem 2rem 1.6rem;
      padding: 0.5rem 2rem 0.7rem;
    }
    tr.active {
      font-weight: bold;
    }
  }
}
.table {
  th {
    text-transform: uppercase;
    font-weight: 500;
    padding: 1.6rem 2rem;
  }
  td {
    color: get-color(slate);
    padding: 1.6rem 2rem;
  }
  tr.is-not-hoverable:hover {
    background-color: transparent !important;
  }
}

.icon.is-small .fa,
.fa {
  font-size: 1.5rem;
}

.image.is-15x15 {
  height: 15px;
  width: 15px;
}
.image.is-20x20 {
  height: 20px;
  width: 20px;
}
.image.is-26x26 {
  height: 26px;
  width: 26px;
}
.image.is-36x36 {
  height: 36px;
  width: 36px;
}

a {
  color: get-color-with-alpha('black', 0.9);
}

a[disabled],
button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

.title em,
.title span,
.subtitle em,
.subtitle span {
  font-weight: inherit;
}

[class^='icon-'] {
  font-size: 1.6rem;
}

.icon.is-small,
.icon.is-small [class^='icon-'] {
  font-size: 2rem;
}

.icon.is-medium,
.icon.is-medium [class^='icon-'] {
  font-size: 2.5rem;
}

.icon.is-large,
.icon.is-large [class^='icon-'] {
  font-size: 3rem;
}

.select select:focus,
.select select.is-focused,
.select select:active,
.select select.is-active {
  /* border-color: #00d1b2; */
  box-shadow: none;
}

.tabs li a {
  color: get-color(slate);
}
.tabs li a.is-active {
  border-bottom-color: get-color(production-blue);
  color: get-color(production-blue);
}

.swal2-container {
  z-index: 1000000;
}

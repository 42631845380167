$white: #fff;
$black: #263238;

$app-colors: (
        "transparent":            (base:rgba(0,0,0,0),            font:$black),
        // Base colors
        "default":	              (base:$black,                   font:$white),
        "white":	              (base:$white,                   font:$black),
        "black":                  (base:$black,                   font:$white),
        "dark":                   (base:$black,                   font:$white),
        "slate":                  (base:#455a64,                  font:$white),
        // Generic colors
        // Gray
        "body-grey":              (base:rgb(242, 246, 249),       font:$black),
        "light-blue-grey":        (base:#a8c6df,                  font:$black),
        "light-blue-grey-20":     (base:rgba(#a8c6df, .2),        font:$black),
        "light-blue-grey-80":     (base:rgba(#a8c6df, .8),        font:$black),
        "light-blue-grey-90":     (base:rgba(183, 210, 229, .9),  font:$black),
        "bluish-grey":            (base:#78909c,                  font:$white),
        "light-grey":             (base:#f8f9f9,                  font:$black),
        "dark-sky-blue-10":       (base:rgba(30, 136, 229, .1),   font:#455a64),
        "light-blue-grey-two":    (base:#d8e5ef,                  font:$black),
        "dark-grey-blue":         (base:#2c4059,                  font:$white),
        "dark-grey-blue-16":      (base:rgba(44, 64, 90, .16),    font:$black),
        "dark-2":                 (base:rgba($black, .02),        font:$black),
        //dark-2-no-alpha":        (base:#90a4ae,                  font:$black),
        "dark-3":                 (base:rgba($black, .03),        font:$black),
        "charcoal-grey":          (base:#37474f,                  font:$white),
        "pale-grey":              (base:#fafbfc,                  font:$black),
        "greyblue":               (base:#8dabc4,                  font:$white),
        "bluey-grey":             (base:#90a4ae,                  font:$white),
        "pale-grey-two":          (base:#f2f6f9,                  font:$black),
        "pale-grey-three":        (base:#e5eef5,                  font:$black),
        "light-blue-grey-three":  (base:#c5d9e8,                  font:$black),
        "charcoal-grey-60":       (base:rgba(55, 71, 79, 0.6),    font:$white),
        "charcoal-grey-80":       (base:rgba(55, 71, 79, 0.8),    font:$white),
        // Blue
        "azure":                  (base:#0093ee,                  font:$white),
        "dark-sky-blue":          (base:#1e88e5,                  font:$white),
        // Green
        "apple-green":            (base:#81c926,                  font:$white),
        // Application colors
        "pause-azure":            (base:#03a9f4,                  font:$white),
        "production-blue":        (base:#2979ff,                  font:$white),
        "production-blue-20":     (base:rgba(41, 121, 255, .2),   font:rgb(30, 136, 229)),
        "material-violet":        (base:#aa00ff,                  font:$white),
        "setup-yellow":           (base:#ffab00,                  font:$white),
        "check-green":            (base:#00c853,                  font:$white),
        "neutral-grey":           (base:#b0bec5,                  font:$white),
        "done-grey":              (base:#ebeef0,                  font:#a8c6df),

        "stressed":               (base:#aa00ff,                  font:$white),
        "error":                  (base:#ff1744,                  font:$white),
        "production":             (base:#2979ff,                  font:$white),
        "interrupted":            (base:#b0bec5,                  font:$white),
);
